import {useLayoutEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const SCROLL_POSITION = "scroll_position";

const useScrollPosition = (setCondition: boolean): void => {
  const { pathname } = useLocation();
  const scrollPathname = `${SCROLL_POSITION}_${pathname.replace("/", "")}`;
  const scrollPosition = sessionStorage.getItem(scrollPathname);
  const [yOffset, _] = useState<number>(scrollPosition ? +scrollPosition : 0);

  useLayoutEffect(() => {
    if (setCondition) {
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }

    sessionStorage.removeItem(scrollPathname);
  }, [setCondition, yOffset]);

  useLayoutEffect(() => {
    return () => {
      sessionStorage.setItem(scrollPathname, String(window.scrollY));
      window.scrollTo(0, 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useScrollPosition;
