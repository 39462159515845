import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useState, useEffect, useRef} from 'react';
import Icon from '../../components/Icon';
import Modal from '../../components/Modals/Modal';
import {TextA18, TextG10, TextG12, TextG16} from '../../components/typography';
import {useInfiniteGlobalSearchWithParams} from '../../hooks/useFetchGlobalSearch';
import useGetUserLocation from '../../hooks/useUserLocation';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';

const EventSelectModal = ({selectedEvent, setSelectedEvent, placeHolderText = true}) => {
  const [show, setShow] = useState(false);
  const [term, setTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const userLocation = useGetUserLocation();
  const theme = useTheme();

  // Handle debouncing for search term
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedTerm(term);
    }, 500);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [term]);

  const {data, isLoading} = useInfiniteGlobalSearchWithParams({
    term: debouncedTerm.length >= 3 ? debouncedTerm : '',
    type: 'event',
    // lat: userLocation.data?.latitude,
    // long: userLocation.data?.longitude,
    sort: {
      by: 'start_date_time',
      direction: 'asc',
    },
  });

  const searchRes = data?.pages[0]?.data?.data || [];

  // Format event date in a more readable way
  const formatEventDate = (dateString) => {
    if (!dateString) return '';
    const date = dayjs.utc(dateString).local();
    return date.format('MMM D, YYYY · h:mm A');
  };

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        height="auto"
        alignItems="flex-end"
        w="100%"
        px="16px"
        py="12px"
        justifyContent="left"
        background="transparent"
        border="1px solid #E5E5E5"
        borderRadius="30px">
        <Flex gap="5px" align="center">
          {selectedEvent && (
            <Image
              objectFit="cover"
              minW="30px"
              minH="30px"
              maxH="30px"
              maxW="30px"
              borderRadius="full"
              src={selectedEvent?.image_url}
            />
          )}
          <TextG12
            fontWeight="500"
            color={selectedEvent ? theme.colors.lightBlack : theme.colors.lightestGrey}>
            {selectedEvent?.title || selectedEvent?.name || 'Choose an event'}
          </TextG12>
        </Flex>
      </Button>
      <Modal
        closeButton
        parentCallback={() => setShow(false)}
        header={
          <TextA18 fontWeight="700" mt={2}>
            {placeHolderText ? 'Where are you meeting up?' : ''}
          </TextA18>
        }
        children={
          <VStack w="100%">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon iconName="fi-rr-search" color="gray.300" />
              </InputLeftElement>

              <Input
                borderRadius="40px"
                mb={5}
                onChange={(value) => setTerm(value.target.value)}
                type="search"
                placeholder={placeHolderText ? 'Search for an event' : ''}></Input>
            </InputGroup>
            {isLoading ? (
              <Center h="400px">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor={theme.colors.lightGrey}
                  color={theme.colors.lightBlue}
                  size="lg"
                />
              </Center>
            ) : (
              <VStack w="100%" maxHeight="400px" overflowY="scroll">
                {searchRes.map((event, i) => {
                  const isSelected = selectedEvent?.id == event.id;

                  return (
                    <Box key={i} w="100%">
                      <HStack
                        boxShadow={isSelected ? 'md' : 'none'}
                        transition="all 0.5s"
                        py={2}
                        borderRadius="12px"
                        border={
                          isSelected ? `1px solid ${theme.colors.blue}` : '1px solid transparent'
                        }
                        w="100%"
                        onClick={() => {
                          setShow(false);
                          setSelectedEvent(event);
                        }}>
                        {event.image_url && (
                          <Image
                            objectFit="cover"
                            minW="75px"
                            minH="75px"
                            maxH="75px"
                            maxW="75px"
                            borderRadius="full"
                            src={event.image_url}
                          />
                        )}
                        <VStack w="100%" alignItems={'flex-start'}>
                          <TextG16
                            transition="all 0.5s"
                            //textAlign="center"
                            isTruncated="&hellip;"
                            noOfLines={2}
                            fontWeight="500"
                            color={isSelected ? theme.colors.blue : theme.colors.lightBlack}>
                            {event.title || event.name}
                          </TextG16>
                          {userLocation.data?.latitude &&
                            userLocation.data.longitude &&
                            event?.latitude &&
                            event?.longitude && (
                              <TextG10 color={theme.colors.lightBlack}>
                                {`${getDistanceString(
                                  calculateAndGetDistance(
                                    {
                                      latitude: Number(userLocation.data?.latitude),
                                      longitude: Number(userLocation.data?.longitude),
                                    },
                                    {
                                      latitude: Number(event.latitude),
                                      longitude: Number(event.longitude),
                                    }
                                  )
                                )} away`}
                              </TextG10>
                            )}
                          <TextG10 color={theme.colors.grey}>
                            {formatEventDate(event.start_date_time)}
                          </TextG10>
                          {event.location && (
                            <TextG10 color={theme.colors.grey}>{event.location}</TextG10>
                          )}
                        </VStack>
                      </HStack>
                      <Divider />
                    </Box>
                  );
                })}
              </VStack>
            )}
          </VStack>
        }
        show={show}
      />
    </>
  );
};

export default EventSelectModal;
