import {Center, Container, Divider, Flex, HStack, Select, Spacer, useTheme} from '@chakra-ui/react';
import React from 'react';
import ActiveRedemptionCards from '../../components/ActiveRedemptionCards';
import {H3} from '../../components/typography';
import useAuth from '../../hooks/useAuth';

const Wallet = () => {
  const theme = useTheme();
  const {user} = useAuth();

  return (
    <Container pt={1} px={'0 !important'} pb={'initial'} h="auto">
      <Flex my={3}>
        <Center>
          <H3 py="1px" color={theme.colors.darkGrey}>
            {'Perks Ready To Use'}
          </H3>
        </Center>
      </Flex>
      <Divider mb={3} />
      <ActiveRedemptionCards hideNoCountdown={false} />
    </Container>
  );
};

export default Wallet;
