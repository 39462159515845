import {Button, ButtonGroup, useEditableControls} from '@chakra-ui/react';
import React from 'react';
import {TextG12} from '../typography';

function EditableControls({top}: {top?: number | string}) {
  const {isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps} =
    useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
      <Button {...getSubmitButtonProps()}>
        <TextG12>Save</TextG12>
      </Button>
      <Button {...getCancelButtonProps()}>
        <TextG12>Cancel</TextG12>
      </Button>
    </ButtonGroup>
  ) : (
    <Button px={4} size="xs" pos="absolute" top={top || 0} right={0} {...getEditButtonProps()}>
      <TextG12>Edit</TextG12>
    </Button>
  );
}

export default EditableControls;
