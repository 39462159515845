import React from 'react';
import {map, orderBy} from 'lodash';
import {VStack} from '@chakra-ui/react';
import {Post} from '../../../types/article';
import PostCard from '../../../components/Articles/PostCard';
import {getArticleTopItems} from '../../../utils/articleTopItems';

const PlacePosts = ({
  posts,
  setSignUpRequiredOpen,
  preview = false,
}: {
  posts: Post[];
  setSignUpRequiredOpen?: any;
  preview?: boolean;
}) => {
  return (
    <VStack gap="10px" textAlign="initial">
      {map(orderBy(posts, 'created_at', 'desc'), (post: Post) => {
        return (
          <PostCard
            onClick={
              preview
                ? () => {
                    setSignUpRequiredOpen(true);
                  }
                : undefined
            }
            key={post.id}
            post={post}
            {...(preview ? {} : getArticleTopItems(post))}
          />
        );
      })}
    </VStack>
  );
};

export default PlacePosts;
