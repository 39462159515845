import {Box, Flex, useTheme} from '@chakra-ui/react';
import React, {useEffect, useMemo, useState} from 'react';
import RoundedBoxItems from '../../../../components/RoundedBoxItems';
import {TextG14, TextG18} from '../../../../components/typography';
import useAuth from '../../../../hooks/useAuth';
import {LifestyleGroup} from '../LifestyleGoals';
import {fetchLifestyleGroups, setLifestyle, removeLifestyle} from '../../../../endpoints/api';
import {find, without} from 'lodash';

const Goals = ({selectedLifestyles, setSelectedLifestyles}) => {
  const theme = useTheme();
  const [isLoading, setLoading] = useState(true);
  const {reFetchUserData, user} = useAuth();
  const [lifestyleGroups, setLifestyleGroups] = useState<Array<LifestyleGroup>>([]);
  const [experienceMoreGoal, setExperienceMoreGoal] = useState<any>();

  const handleGoalClicked = (id: number) => {
    if (selectedLifestyles.includes(id)) {
      removeLifestyle(id).then(() => {
        reFetchUserData();
      });
      setSelectedLifestyles(without(selectedLifestyles, id));
      return;
    }

    setLifestyle(id).then(() => {
      reFetchUserData();
    });
    setSelectedLifestyles([...selectedLifestyles, id]);
  };

  const lifestyleIds = user?.lifestyleGroups.length
    ? user?.lifestyleGroups.map((l) => parseInt(l.id))
    : [];
  const uniqueLifestylesId = [...new Set(lifestyleIds)];

  useEffect(() => {
    fetchLifestyleGroups().then(({data}) => {
      setLifestyleGroups(data);
      setLoading(false);
      const experienceMoreGoal = find(data, {name: 'Experience More'});

      if (experienceMoreGoal && experienceMoreGoal.id) {
        setExperienceMoreGoal(experienceMoreGoal);
        setLifestyle(experienceMoreGoal.id).then(() => reFetchUserData());
        setSelectedLifestyles((prev) => [...prev, experienceMoreGoal.id]);
      }
    });
    setSelectedLifestyles(uniqueLifestylesId);
    reFetchUserData();
  }, []);

  const initialSelected = useMemo(
    () => (experienceMoreGoal ? [experienceMoreGoal?.id] : undefined),
    [experienceMoreGoal]
  );

  return (
    <Flex flexDir="column" my={6} id="goal">
      <TextG18 color={theme.colors.lightBlack} fontWeight="700" mb={2}>
        Lifestyle Goals
      </TextG18>
      <TextG14 color={theme.colors.grey}>Share a few of your Lifestyle Goals with us</TextG14>

      <Box pt={5}>
        <RoundedBoxItems
          showOnlyNum={10}
          showHashtag={false}
          elements={lifestyleGroups}
          selected={
            user?.lifestyleGroups.length ? user?.lifestyleGroups.map((t) => parseInt(t.id)) : []
          }
          onSelect={handleGoalClicked}
          initialSelected={initialSelected}
        />
      </Box>
    </Flex>
  );
};

export default Goals;
