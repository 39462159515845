import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
  addChatMedia,
  createLifestyleComment,
  deleteChatMedia,
  deleteLifestyleComment,
  fetchChatMedia,
  fetchLifestyleComment,
  fetchLifestyleComments,
  fetchLifestyleReplyMentionComments,
  fetchFeaturedLifestyleComments,
  helpfulComment,
  helpfulLifestyleComment,
  reportComment,
  reportLifestyleComment,
  updateLifestyleComment,
  fetchLifestyleChatUsers,
} from '../endpoints/api';
import {User} from './useAuth';
import {tail} from 'lodash';
import {ToastMessage} from '../components/toast';
import {AxiosError} from 'axios';

const INTERVAL_MS = 5000; // 5 seconds

export const useInfiniteFeaturedLifestyleComments = (enabled: boolean = true) => {
  return useInfiniteQuery(
    ['infinite_featured_lifestyle_comments'],
    ({pageParam = 1}) => fetchFeaturedLifestyleComments({page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled,
    }
  );
};

export const useInfiniteLifestyleComments = (enabled: boolean = true) => {
  return useInfiniteQuery(
    ['infinite_lifestyle_comments'],
    ({pageParam = 1}) => fetchLifestyleComments({page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled,
    }
  );
};

export const useCreateLifestyleComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      content,
      parent_id,
      is_featured,
      user,
      hasImage = false,
    }: {
      content: string;
      parent_id?: string;
      is_featured?: boolean;
      user?: User;
      hasImage?: boolean;
    }) => createLifestyleComment({content, parent_id, is_featured}),
    {
      onMutate: async (newComment) => {
        if (newComment.parent_id) return;
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey: ['infinite_lifestyle_comments'],
        });

        // Snapshot the previous value
        const previousComments: any = queryClient.getQueryData(['infinite_lifestyle_comments']);

        const newData = {
          ...previousComments,
          pages: [
            {
              ...previousComments.pages[0],
              data: {
                ...previousComments.pages[0].data,
                data: [{hasImage: true, id: '1', ...newComment}, ...previousComments.pages[0].data.data],
              },
            },
            ...tail(previousComments.pages),
          ],
        };

        if (previousComments && previousComments.pages[0]) {
          // Optimistically update to the new value
          queryClient.setQueryData(['infinite_lifestyle_comments'], newData);
        }

        // Return a context with the previous and new comment
        return {previousComments, newComment};
      },
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        //queryClient.invalidateQueries(['infinite_lifestyle_comments']);
        //queryClient.invalidateQueries(['lifestyle_comments']);
      },
    }
  );
};

export const useUpdateLifestyleComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({commentId, content}: {commentId: string; content: string}) =>
      updateLifestyleComment({commentId, content}),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_lifestyle_comments']);
        queryClient.invalidateQueries(['lifestyle_comments']);
      },
    }
  );
};

export const useDeleteLifestyleComment = () => {
  const queryClient = useQueryClient();
  return useMutation(({commentId}: {commentId: string}) => deleteLifestyleComment({commentId}), {
    onSettled: (newComment, error, variables, context) => {
      if (error && error instanceof AxiosError) {
        ToastMessage({
          status: 'error',
          text: error.message,
        });
      }
      queryClient.invalidateQueries(['infinite_lifestyle_comments']);
      queryClient.invalidateQueries(['lifestyle_comments']);
    },
  });
};

export const useReportLifestyleComment = () => {
  const queryClient = useQueryClient();
  return useMutation(({commentId}: {commentId: string}) => reportLifestyleComment({commentId}), {
    onSettled: (newComment, error, variables, context) => {
      queryClient.invalidateQueries(['infinite_lifestyle_comments']);
      queryClient.invalidateQueries(['lifestyle_comments']);
    },
  });
};

export const useHelpfulLifestyleComment = () => {
  const queryClient = useQueryClient();
  return useMutation(({commentId}: {commentId: string}) => helpfulLifestyleComment({commentId}), {
    onSettled: (newComment, error, variables, context) => {
      queryClient.invalidateQueries(['infinite_lifestyle_comments']);
      queryClient.invalidateQueries(['lifestyle_comments']);
    },
  });
};

export const useFetchLifestyleChatUsers = () => {
  return useInfiniteQuery(
    ['infinite_lifestyle_users'],
    ({pageParam = 1}) => fetchLifestyleChatUsers(pageParam),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export function useFetchRepliesAndMentions(enabled: boolean = true) {
  return useQuery(['lifestyle_replies'], () => fetchLifestyleReplyMentionComments(), {
    enabled: enabled,
    // Refetch the data every INTERVAL_MS
    refetchInterval: INTERVAL_MS,
  });
}

// export function useFetchLifestyleUserNum() {
//   return useQuery(['lifestyle_num'], () => fetchLifestyleUserNum(), {
//   });
// }

export function useFetchLifestyleComment({commentId, enabled}) {
  return useQuery(['lifestyle_comment', commentId], () => fetchLifestyleComment({commentId}), {
    enabled: enabled,
    // Refetch the data every INTERVAL_MS
    refetchInterval: INTERVAL_MS,
    retry: 1,
  });
}

// export const useLastChatVisit = () => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     ({communityId}: {communityId: number}) => lastCommunityChatVisit({communityId}),
//     {
//       onSettled: (newComment, error, variables, context) => {
//         queryClient.invalidateQueries(['new_community_messages']);
//       },
//     }
//   );
// };

export const useAddCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      formData,
      //target_id,
      comment_id,
    }: {
      formData: FormData;
      //target_id: string;
      comment_id: string;
    }) =>
      addChatMedia({
        formData,
        target_type: 'lifestyle_plus_chat',
        target_id: '1',
        comment_type: 'lifestyle_plus_comment',
        comment_id,
      }),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_lifestyle_comments']);
        queryClient.invalidateQueries(['lifestyle_comments']);
      },
    }
  );
};

export const useDeleteCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(({mediaId}: {mediaId: string}) => deleteChatMedia({mediaId}), {
    onSettled: (newComment, error, variables, context) => {
      if (error && error instanceof AxiosError) {
        ToastMessage({
          status: 'error',
          text: error.message,
        });
      }
      queryClient.invalidateQueries(['infinite_lifestyle_comments']);
      queryClient.invalidateQueries(['lifestyle_comments']);
    },
  });
};

export const useFetchCommentMedia = ({mediaId}) => {
  return useQuery(['lifestyle_comment_media', mediaId], () => fetchChatMedia({mediaId}), {
    enabled: typeof mediaId === 'number',
  });
};
