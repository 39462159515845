import React from "react";
import OpenHour from "./OpenHour";
import { TextA14, TextG12, TextG14 } from "../typography";
import {
  Accordion as ChkrAccordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
  Box,
} from "@chakra-ui/react";
import { PLACE_STATUS } from "../../constants/openingHours";
import {
  PlaceStatus,
} from "../../utils/openingHours";
import {capitalize} from 'lodash';

export type OpenHour = {
    day: "Today" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    hours: string;
    current: boolean;
}

export const getAvailabilityStatus = (businessStatus?: string, openStatus?: string) => {
  if (businessStatus === 'closed_temporarily') {
    return 'Temporarily Closed';
  }

  if(!openStatus || openStatus === PLACE_STATUS.UNKNOWN) {
    return 'Business Hours Unknown';
  }

  const capitalizedStatus = capitalize(openStatus).replace('_', ' ');

  if (openStatus === PLACE_STATUS.OPEN || openStatus === PLACE_STATUS.CLOSED) {
    return `${capitalizedStatus} Now`;
  }
  return capitalizedStatus;
};

export const getAvailabilityStatusColor = (businessStatus?: string, openStatus?: string) => {
  if (businessStatus === 'closed_temporarily') {
    return '#F9AC39';
  }

  if (openStatus === PLACE_STATUS.OPEN || openStatus === PLACE_STATUS.OPENING_SOON) {
    return '#329241';
  }

  return 'red';
};

export function OpenHours({ openHours, openStatus, businessStatus }: { openHours: OpenHour[], openStatus: PlaceStatus | string | undefined, businessStatus: string | undefined }) {
  const openHoursList = openHours.map((openHour) => (
    <OpenHour
      key={openHour.day}
      day={openHour.day}
      hours={openHour.hours}
      current={openHour.current}
    />
  ));  

  return (
    <ChkrAccordion allowToggle defaultIndex={[0]}>
      <AccordionItem>
      <AccordionButton position="relative" paddingLeft={0} paddingRight={"8px"}>
          <Flex alignItems="center" h="100%" w="100%" position={"relative"}>
            <Box width={"17%"} textAlign="left"><TextG12 fontWeight="bold">Hours: </TextG12></Box>
            <Box width={"66%"} textAlign="left"><TextG12 
                fontWeight={openStatus === PLACE_STATUS.OPEN && businessStatus && businessStatus === 'operational' && "bold"} 
                color={getAvailabilityStatusColor(businessStatus, openStatus)}
            >{getAvailabilityStatus(businessStatus, openStatus)} </TextG12></Box>
            <Box width={"17%"} textAlign="right"><AccordionIcon /></Box>
          </Flex>
        </AccordionButton>
        <AccordionPanel paddingStart={0} paddingRight={0}>
          <GridItem width={"100%"} margin="auto">{openHoursList}</GridItem>
        </AccordionPanel>
      </AccordionItem>
    </ChkrAccordion>
  );
}
