import React from 'react';
import {useTheme} from '@chakra-ui/react';
import {TextA18, TextG12} from '../typography';
import {Container, Flex, HStack} from '@chakra-ui/react';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  max-width: 100% !important;
  display: flex;
  justify-content: center;
`;

const ErrorUI = () => {
  const theme = useTheme();

  return (
    <StyledContainer>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        maxWidth="80%"
        gap="4"
        textAlign={'center'}>
        <HStack mb="20px">
          <i
            className="fi fi-rr-comment-user"
            style={{
              color: `${theme.colors.lighterGrey}`,
              fontSize: '100px',
              width: '100px',
              height: '100px',
            }}></i>
        </HStack>
        <TextA18 fontSize="24px" color={theme.colors.darkGrey}>
          Oops! Something went wrong.
        </TextA18>
        <TextG12 color={theme.colors.grey} fontWeight="500">
          Help us troubleshoot this by contacting Support. Or just return Home and start over.
        </TextG12>
      </Flex>
    </StyledContainer>
  );
};

export default ErrorUI;
