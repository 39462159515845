import { lowerCase } from 'lodash';
import React, {ReactElement, useEffect, useState} from 'react';
import Select, {components, MultiValue} from 'react-select';
import {usePlaceTypes, placeTypeData} from '../../hooks/usePlaceTypes';
import Icon from '../Icon';

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '15px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: '10px',
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#276ACD',
    fontWeight: 700,
  }),
  option: (provided, state) => ({
    ...provided,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '15px',
    backgroundColor: 'white',
    fontWeight: 700,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    color: state.isSelected ? '#276ACD' : '#757D84',
  }),
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'white',
      fontWeight: 700,
      color: '#276ACD',
      display: 'flex',
      alignItems: 'center',
    };
  },
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#276ACD',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  }),
  container: (provided, state) => ({
    ...provided,
    margin: '0 0 15px 0',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99,
  }),
};

const { MultiValue, Option } = components;

export type MultiValueSelect = MultiValue<{
  value: number,
  category: string,
  icon: string,
  color: string
}>


const SingleOption = (props) => {
  return (
    <Option {...props}>
      <Icon iconName={props.data.icon} style={{color: props.data.color, fontSize: '18px'}} />
      <span style={{marginLeft: '5px'}}>{props.data.category}</span>
    </Option>
  );
};

const MultiValueOption = (props) => {
  return (
    <MultiValue {...props}>
      <Icon iconName={props.data.icon} style={{color: props.data.color, fontSize: '18px'}} />
      <span style={{marginLeft: '5px'}}>{props.data.category}</span>
    </MultiValue>
  );
};

const  SelectOptions = (props) => {

  return (
    <Select
      placeholder={props.placeholder ? props.placeholder : 'Filter by...'}
      value={props.selected}
      defaultValue={props.selected}
      styles={customStyles}
      isMulti
      isSearchable={false}
      onChange={props.changeHandler}
      options={placeTypeData}
      components={{
        IndicatorSeparator: () => null,
        Option: SingleOption,
        MultiValue: MultiValueOption,
      }}
      hideSelectedOptions={false}
    />)
}

export default SelectOptions;