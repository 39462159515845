import {FilterOption} from "./types";

export const HAPPENING_FILTER_OPTIONS: FilterOption[] = [
  { label: 'Today', value: 'today' },
  { label: 'Tomorrow', value: 'tomorrow' },
  { label: 'This Week', value: 'this-week' },
  { label: 'Next Week', value: 'next-week' },
  { label: 'This Month', value: 'this-month' },
  { label: 'Next Month', value: 'next-month' },
  { label: 'Custom', value: 'date-range' },
];