import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ToastMessage} from '../components/toast';
import {
  createMeetup,
  fetchMeetup,
  fetchMeetups,
  reactToMeetup,
  updateMeetup,
} from '../endpoints/api';
import useGlobalSearch from './useGlobalSearch';

export const useInfiniteMeetups = () => {
  const {params} = useGlobalSearch(); // to prevent different number of hooks being called when switching search tabs

  return useInfiniteQuery(
    ['infinite_meetups', {open}],
    ({pageParam = 1}) => fetchMeetups({page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export function useFetchMeetup(meetupId: number | undefined) {
  return useQuery(['meetup', meetupId], () => fetchMeetup({id: Number(meetupId)}), {
    enabled: !!meetupId && typeof meetupId === 'number',
  });
}

export const useCreateMeetup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      title,
      description,
      reason,
      start_date_time,
      end_date_time,
      is_private,
      place_id,
    }: {
      title: string;
      description: string;
      reason: string;
      start_date_time: string;
      end_date_time: string;
      is_private: boolean;
      place_id?: number;
    }) =>
      createMeetup({
        title,
        description,
        reason,
        start_date_time,
        end_date_time,
        is_private,
        place_id,
      }),
    {
      // onMutate: async (newMeetup) => {
      //   // Cancel any outgoing refetches
      //   // (so they don't overwrite our optimistic update)
      //   await queryClient.cancelQueries({
      //     queryKey: ['infinite_event_comments', newMeetup.eventId],
      //   });
      //   // Snapshot the previous value
      //   const previousComments: any = queryClient.getQueryData([
      //     'infinite_event_comments',
      //     newMeetup.eventId,
      //   ]);
      //   if (previousComments && previousComments.pages[0]) {
      //     const newData = {
      //       ...previousComments,
      //       pages: [
      //         {
      //           ...previousComments.pages[0],
      //           data: {
      //             ...previousComments.pages[0].data,
      //             data: [{id: '1', ...newMeetup}, ...previousComments.pages[0].data.data],
      //           },
      //         },
      //         ...tail(previousComments.pages),
      //       ],
      //     };
      //     // Optimistically update to the new value
      //     queryClient.setQueryData(['infinite_event_comments', newMeetup.eventId], newData);
      //   }
      //   // Return a context with the previous and new comment
      //   return {previousComments, newComment: newMeetup};
      // },
      // onSettled: (newComment, error, variables, context) => {
      //   if (error && error instanceof AxiosError) {
      //     ToastMessage({
      //       status: 'error',
      //       text: error.message,
      //     });
      //   }
      //   queryClient.invalidateQueries(['infinite_event_comments', variables.eventId]);
      //   queryClient.invalidateQueries(['event_comments', variables.eventId]);
      // },
    }
  );
};

export const useUpdateMeetUp = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      title,
      description = '',
      reason = '',
      start_date_time,
      end_date_time,
      is_private = false,
      place_id,
      eventId,
    }: {
      title: string;
      description?: string;
      reason?: string;
      start_date_time: string;
      end_date_time: string;
      is_private?: boolean;
      place_id?: number;
      eventId: number;
    }) =>
      updateMeetup({
        title,
        description,
        reason,
        start_date_time,
        end_date_time,
        is_private,
        place_id,
        eventId,
      }),
    {
      onSettled: (newMeetup, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_meetups']);
        queryClient.invalidateQueries(['meetup', variables.eventId]);
      },
    }
  );
};

export const useReactToMeetup = () => {
  const queryClient = useQueryClient();
  
  return useMutation(
    (meetupId: number) => reactToMeetup(meetupId),
    {
      onSettled: (data, error, variables) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        // Invalidate relevant queries
        queryClient.invalidateQueries(['infinite_meetups']);
        queryClient.invalidateQueries(['meetup', variables]);
      },
    }
  );
};
