export const enum AddressTypes {
  Home = 'HOME',
  Billing = 'BILLING',
  Mailing = 'MAILING',
}

export interface Address {
  id?: number;
  type_id: number;
  street_address: string;
  unit?: string;
  city?: string | '';
  city_id?: number;
  state_id: number;
  zip_code?: string | '';
}

export interface State {
  id: number;
  name: string;
  abbreviation: string;
  value?: string;
}

export const addressesMatch = (firstAddress: Address, secondAddress: Address) => {
  return (
    firstAddress.city_id === secondAddress.city_id &&
    firstAddress.state_id === secondAddress.state_id &&
    firstAddress.street_address === secondAddress.street_address &&
    firstAddress.unit === secondAddress.unit &&
    firstAddress.zip_code === secondAddress.zip_code
  );
};
