import {Center, Spinner, theme, useTheme} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ToastMessage} from '../../components/toast';
import {fetchMembershipStripeLink} from '../../endpoints/api';
import useAuth from '../../hooks/useAuth';

const MemberInvite = () => {
  const {user} = useAuth();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!searchParams.get('p')) {
      const promoCode = searchParams.get('p') || '';
      if (user) {
        fetchMembershipStripeLink({
          membershipId: 2,
          promoCode: promoCode,
          cLink: `${location.origin}/home`,
        })
          .then((ret) => {
            if (ret.data.link) {
              window.location.href = ret.data.link;
            }
          })
          .catch((error) => {
            const errString = error.response.data.error;

            ToastMessage({
              status: 'error',
              text:
                errString ||
                'Something went wrong while generating your membership link. Please contact support.',
            });
            navigate('/home');
          });
      } else {
        navigate(`/register?p=${promoCode}`, {
          state: {},
        });
      }
    } else {
      navigate('/home');
    }
  }, [searchParams]);

  return (
    <Center style={{minHeight: '100vh'}}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor={theme.colors.lightGrey}
        color={theme.colors.lightBlue}
        size="lg"
      />
    </Center>
  );
};

export default MemberInvite;
