import {Box, Center, Container, Divider, Flex, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {BlueButtonFixedWidth} from '../../../../components/button';
import useAuth from '../../../../hooks/useAuth';
import * as pushService from '../../../../pushService';
import Header from '../Header';
import Goals from './Goals';
import Interests from './Interests';
import {isIphone, deviceDetected} from '../../../../utils/deviceCheck';
import {updateUser} from '../../../../endpoints/api';
import {TextG12} from '../../../../components/typography';
import ScrollToHashElement from '../../../../components/ScrollToHashElement';
import SkipInterestsModal from './SkipInterestsModal';

const interestLink = (
  <Link to="#interest">
    <span style={{fontWeight: '700', textDecoration: 'underline'}}>Interest</span>
  </Link>
);

const goalLink = (
  <Link to="#goal">
    <span style={{fontWeight: '700', textDecoration: 'underline'}}>Goal</span>
  </Link>
);

const getDisableMessage = (selectedLifestyles, numOfSelectedTopics) => {
  if (numOfSelectedTopics < 1 && selectedLifestyles >= 1) {
    return <TextG12>You must select at least one {interestLink} before continuing.</TextG12>;
  } else if (selectedLifestyles < 1 && numOfSelectedTopics >= 1) {
    return <TextG12>You must select at least one {goalLink} before continuing.</TextG12>;
  } else if (numOfSelectedTopics < 1 && selectedLifestyles < 1) {
    return (
      <TextG12>
        You must select at least one {goalLink} and one {interestLink} before continuing.
      </TextG12>
    );
  }
};

const GoalsAndInterests = () => {
  const theme = useTheme();
  const {user} = useAuth();
  const [numOfSelectedTopics, setNumOfSelectedTopics] = useState<number>(user?.topics.length || 0);
  const [selectedLifestyles, setSelectedLifestyles] = useState<number[]>(
    user?.lifestyleGroups ? user?.lifestyleGroups.map((lifestyle) => Number(lifestyle.id)) : []
  );
  const isDisabled = numOfSelectedTopics < 1 || selectedLifestyles.length < 1;
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get('membershipPreview');

  const handleClickBlueButton = async () => {
    if (window['ReactNativeWebView']) {
      pushService.pushPermissionRequest();
      navigate('/register/username');
    } else {
      if (!isIphone) {
        pushService
          .askNotificationPermission()
          .then((token) => {
            updateUser({
              device_token: token,
              device_platform: deviceDetected(),
            });
            navigate(
              isPreview ? '/register/username?membershipPreview=true' : '/register/username'
            );
          })
          .catch(() => {
            navigate(
              isPreview ? '/register/username?membershipPreview=true' : '/register/username'
            );
          });
      } else {
        if (
          !pushService.pushPermissionRequest(() => {
            navigate(
              isPreview ? '/register/username?membershipPreview=true' : '/register/username'
            );
          })
        ) {
          navigate(isPreview ? '/register/username?membershipPreview=true' : '/register/username');
        }
      }
    }
  };

  return (
    <Container px={6}>
      <SkipInterestsModal handleClickBlueButton={handleClickBlueButton} />
      <ScrollToHashElement />
      <Header showBack={false} />
      <Goals
        selectedLifestyles={selectedLifestyles}
        setSelectedLifestyles={setSelectedLifestyles}
      />
      <Divider color={theme.colors.grey2} />
      <Interests
        numOfSelectedTopics={numOfSelectedTopics}
        setNumOfSelectedTopics={setNumOfSelectedTopics}
      />

      <Flex flexDir="column" gap={4} pb={4} maxW="90%">
        {isDisabled && (
          <Box textAlign="center" color={theme.colors.rose}>
            {getDisableMessage(selectedLifestyles.length, numOfSelectedTopics)}
          </Box>
        )}
        <Center>
          <BlueButtonFixedWidth isDisabled={isDisabled} onClick={handleClickBlueButton}>
            Continue
          </BlueButtonFixedWidth>
        </Center>
      </Flex>
    </Container>
  );
};

export default GoalsAndInterests;
