import {AbsoluteCenter, Box, Divider, useTheme} from '@chakra-ui/react';
import React, {useEffect, useRef} from 'react';
import Icon from '../Icon';
import styled, {css} from 'styled-components';
import {TextG10} from '../typography';

const PulseAnimation = styled(Box)`
  transform: scale(1);

  ${(props) =>
    props.$isAnimating &&
    css`
      animation: pulse 2s infinite;
    `};

  ${(props) =>
    !props.$isAnimating &&
    css`
      animation: null;
    `};

  @keyframes pulse {
    0%,
    100% {
      transform: scale(1) translateY(0);
    }

    50% {
      transform: scale(1.1) translateY(4px);
    }
  }
`;

const DiscoverMoreArrow = ({isAnimating = true}: {isAnimating?: boolean}) => {
  const theme = useTheme();
  const arrowRef = useRef<null | HTMLDivElement>(null);

  // arrowRef.current?.getBoundingClientRect().y &&
  // arrowRef.current?.getBoundingClientRect().y - 250 >= 0;

  return (
    <Box
      position="relative"
      onClick={() =>
        window.scrollBy({
          top:
            (arrowRef.current?.getBoundingClientRect().y &&
              arrowRef.current?.getBoundingClientRect().y - 120) ||
            0,
          behavior: 'smooth',
        })
      }>
      <Box w="100%" position="relative" py="15px" height="30px">
        <Divider opacity={1} borderColor={theme.colors.grey2} />
        <AbsoluteCenter bg="white" px="4">
          <TextG10 userSelect="none" fontWeight="700" color={theme.colors.rose}>
            DISCOVER MORE
          </TextG10>
        </AbsoluteCenter>
      </Box>
      <Box ref={arrowRef} w="100%" position="relative" mb="15px" mt="10px">
        <AbsoluteCenter>
          <PulseAnimation $isAnimating={isAnimating}>
            <Icon iconName="fi-sr-angle-down" style={{color: theme.colors.rose}} />
          </PulseAnimation>
        </AbsoluteCenter>
      </Box>
    </Box>
  );
};

export default DiscoverMoreArrow;
