import {useState, useMemo} from 'react';
import Select from 'react-select';
import {Checkbox, Input, VStack, Button, HStack, useTheme} from '@chakra-ui/react';
import React from 'react';
import {Place, Partner, Event} from '../../types/article';
import {TextG10} from '../../components/typography';
import {BlueButton} from '../../components/button';
import { capitalize } from 'lodash';

interface QRCodeURLGeneratorProps {
  attachedEntities: {
    place?: Place;
    event?: Event;
    partner?: Partner;
  };
  setGeneratedUrl: (url: string) => void;
}

const QRCodeURLGenerator: React.FC<QRCodeURLGeneratorProps> = ({
  attachedEntities,
  setGeneratedUrl,
}) => {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
    entity: Place | Event | Partner;
  } | null>(null); // Track the selected option
  const [checkIn, setCheckIn] = useState(true);
  const [skipDistance, setSkipDistance] = useState(true);
  const [preview, setPreview] = useState(true);

  const options = useMemo(() => {
    return Object.entries(attachedEntities)
      .filter(([_, entity]) => entity !== undefined)
      .map(([type, entity]) => ({
        value: type,
        label: `${type === 'event' ? (entity as Event).title : entity.name} (${capitalize(type)})`,
        entity,
      }));
  }, [attachedEntities]);

  const handleEntityChange = (selectedOption: any) => {
    if (selectedOption) {
      setSelectedOption(selectedOption); // Set the entire selected option
    } else {
      setSelectedOption(null); // Clear the selection
    }
  };

  const generateURL = () => {
    if (!selectedOption) return;

    const {value: selectedType, entity: selectedEntity} = selectedOption;

    let baseUrl = `${window.location.origin}`;
    let generatedUrl;

    if (preview) {
      generatedUrl =
        selectedType === 'event'
          ? baseUrl + `/${selectedType.toLowerCase()}/${selectedEntity.id}/preview`
          : baseUrl + `/${selectedType.toLowerCase()}/${selectedEntity.uuid}/preview`;
    } else {
      generatedUrl = baseUrl + `/${selectedType.toLowerCase()}/${selectedEntity.id}`;
    }

    if (checkIn || skipDistance) {
      const params = new URLSearchParams();
      if (checkIn) params.append('action', 'check-in');
      if (skipDistance) params.append('sdv', 'true');

      if (params.toString()) {
        generatedUrl += `?${params.toString()}`;
      }
    }

    setGeneratedUrl(generatedUrl);
  };

  return (
    <VStack spacing={4} align="stretch">
      <Select
        value={selectedOption} // Set the selected option
        options={options}
        onChange={handleEntityChange}
        placeholder="Select entity"
        isClearable
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: '22px',
            borderColor: theme.colors.lightestGrey,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: theme.colors.lightestGrey,
          }),
        }}
      />

      <HStack justifyContent="center">
        <Checkbox
          isChecked={preview}
          onChange={(e) => setPreview(e.target.checked)}
          colorScheme="green">
          <TextG10>Preview</TextG10>
        </Checkbox>
        <Checkbox
          isChecked={checkIn}
          onChange={(e) => setCheckIn(e.target.checked)}
          colorScheme="green">
          <TextG10>Check-in</TextG10>
        </Checkbox>
        <Checkbox
          isChecked={skipDistance}
          onChange={(e) => setSkipDistance(e.target.checked)}
          colorScheme="green">
          <TextG10>Skip Distance Verification</TextG10>
        </Checkbox>
      </HStack>
      <BlueButton onClick={generateURL} isDisabled={!selectedOption}>
        Generate URL
      </BlueButton>
    </VStack>
  );
};

export default QRCodeURLGenerator;
