import React from 'react';
import {Button, useTheme} from '@chakra-ui/react';
import Icon from '../../Icon';
import useGlobalSearch from '../../../hooks/useGlobalSearch';
import {FilterOption} from '../../../utils/GlobalSearch/types';
import {FILTER_TYPES} from '../../../utils/GlobalSearch/enums';
import {omit, snakeCase} from 'lodash';
import dayjs from 'dayjs';
import {useQueryClient} from '@tanstack/react-query';
import {getAppliedFiltersForContentType} from '../../../utils/GlobalSearch/helpers';
import {FILTERS} from '../FilterConfig';
import {TextG10, TextG9} from '../../typography';

const AppliedFilters = ({type}) => {
  const theme = useTheme();
  const {filters, setFilters} = useGlobalSearch();

  const queryClient = useQueryClient();
  const contentTypeFilters = getAppliedFiltersForContentType(type, filters);
  const contentTypeFiltersConfig = FILTERS[type];

  if (!contentTypeFilters) return <></>;

  const renderButton = ({label, onClick}) => (
    <Button
      key={snakeCase(label)}
      borderRadius="10px"
      borderColor={theme.colors.blueShade1}
      borderWidth={'1px'}
      backgroundColor={`${theme.colors.blueShade2} !important`}
      color={`${theme.colors.blue} !important`}
      onClick={onClick}
      w="fit-content"
      h="30px"
      p={2}
      fontSize={'10px'}
      alignItems={'center'}>
      {
        <Icon
          style={{margin: '4px', alignSelf: 'flex-start', fontSize: '6px'}}
          iconName={'fi fi-rr-cross'}
        />
      }
      <TextG9 fontWeight="400">{label}</TextG9>
    </Button>
  );

  const getLabel = (key: string, val: string) => {
    const filterComponentKey = contentTypeFiltersConfig[key].key;

    if (contentTypeFiltersConfig[key].options) {
      return contentTypeFiltersConfig[key].options?.find((item) => item.value == val)?.label;
    }

    const options = queryClient.getQueryData([filterComponentKey]) as FilterOption[];
    return options?.find((item) => {
      if (contentTypeFiltersConfig[key].valueType) {
        const valType = contentTypeFiltersConfig[key].valueType;
        return item.type === valType && item.value == val;
      }

      return item.value == val;
    })?.label;
  };

  const renderAppliedFilter = (key: string) => {
    const filter = contentTypeFilters[key];
    if (!filter.quick) {
      switch (filter.type) {
        case FILTER_TYPES.TOGGLE:
          return renderButton({
            label: filter.label,
            onClick: () =>
              setFilters({
                ...filters,
                [type]: omit(filters[type], key),
              }),
          });
        case FILTER_TYPES.MULTISELECT:
          const values = filters[type][key];
          return values?.map((val) =>
            renderButton({
              label: getLabel(key, val),
              onClick: () =>
                setFilters({
                  ...filters,
                  [type]: {
                    ...filters[type],
                    [key]: filters[type][key].filter((f) => f !== val),
                  },
                }),
            })
          );
        case FILTER_TYPES.SINGLE_SELECT:
          return renderButton({
            label: getLabel(key, filters[type][key]),
            onClick: () =>
              setFilters({
                ...filters,
                [type]: omit(filters[type], key),
              }),
          });
        case FILTER_TYPES.DATEPICKER:
          return renderButton({
            label: `${filter.label}: ${dayjs(filters[type][key]).format('YYYY-MM-DD')}`,
            onClick: () =>
              setFilters({
                ...filters,
                [type]: omit(filters[type], key),
              }),
          });
      }
    }
  };

  return <>{Object.keys(contentTypeFilters).map((filterKey) => renderAppliedFilter(filterKey))}</>;
};

export default AppliedFilters;
