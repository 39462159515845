import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {Place} from '../../types/article';
import {H2, TextG10, TextG12} from '../../components/typography';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';
import {PLACE_STATUS} from '../../constants/openingHours';
import useGetUserLocation from '../../hooks/useUserLocation';
import {getAvailabilityStatus, getAvailabilityStatusColor} from '../../components/OpenHours';
import CardBadge from '../../components/CardBadge';
import PointBoostBadge from '../../components/Articles/PlaceCard/PointBoostBadge';

const Header: React.FC<{place: Place}> = (props) => {
  const theme = useTheme();
  const isPartner = props.place.partner || props.place.partner_id;
  const userLocation = useGetUserLocation();

  return (
    <Flex
      position="relative"
      top="0"
      width="90%"
      marginLeft="5%"
      transform="auto"
      translateY="-50%"
      marginBottom="-3.5rem"
      backgroundColor="white"
      boxShadow={`0 0 12px ${theme.colors.grey}`}
      borderRadius=".5rem"
      p="0.75rem"
      flexDirection={'column'}>
      {isPartner ? (
        <CardBadge position="absolute" label="WALKABOUT PARTNER" top="-6px" center="true" />
      ) : null}
      <Flex width="100%" justify="space-between" mt={isPartner ? '6px' : 0}>
        <Flex direction="column" gap="0.25rem">
          <TextG10 color={theme.colors.grey} maxW="150px" isTruncated="&hellip;" noOfLines={1}>
            {props.place.street_address}
          </TextG10>
          <H2>{props.place.name}</H2>
          <PointBoostBadge article={props.place} />
          <Flex gap="0.5rem" align="flex-end">
            <TextG10 color={theme.colors.grey}>
              {props.place.checkins_counted > 10 ? `${props.place.checkins_counted} Check-Ins` : ''}
            </TextG10>
          </Flex>
        </Flex>
        <Flex direction="column" justify="space-between" align="flex-end">
          <TextG10 color={theme.colors.grey} width="max-content">
            {userLocation.data &&
              getDistanceString(
                calculateAndGetDistance(
                  {
                    latitude: userLocation.data?.latitude,
                    longitude: userLocation.data?.longitude,
                  },
                  {latitude: Number(props.place.latitude), longitude: Number(props.place.longitude)}
                )
              )}
            {/* {userLocation && props.place.distance && getDistanceToPlace(props.place)} */}
          </TextG10>
          <TextG10 color={theme.colors.grey}>{props.place.price}</TextG10>
        </Flex>
      </Flex>
      {props.place?.open_status && (
        <Flex paddingTop={'5px'}>
          <TextG12
            fontWeight={
              props.place?.open_status === PLACE_STATUS.OPEN &&
              props.place?.business_status === 'operational' &&
              'bold'
            }
            color={getAvailabilityStatusColor(
              props.place?.business_status,
              props.place?.open_status
            )}>
            {getAvailabilityStatus(props.place?.business_status, props.place?.open_status)}
          </TextG12>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
