import {Flex, Link, useTheme} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import Icon from '../../../components/Icon';
import {TextG10, TextG12} from '../../../components/typography';
import useBookmark from '../../../hooks/useBookmark';
import {getDirectionsLinkFromPlace} from '../../../utils/address';
import {Place} from '../../../types/article';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ExternalLink from '../../../components/ExternalLink';
import useAuth from '../../../hooks/useAuth';
import {ToastMessage} from '../../../components/toast';

const IconsHeader = ({
  place,
  preview = false,
  setSignUpRequiredOpen,
}: {
  place: Place;
  preview?: boolean;
  setSignUpRequiredOpen?: any;
}) => {
  const theme = useTheme();
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(place, 'place');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {user} = useAuth();

  useEffect(() => {
    if (!!searchParams.get('bookmark') && !isBookmarked) {
      handleOnBookmarkClick(null);
    }
  }, [searchParams]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  const handleInviteClicked = async () => {
    const shareData = {
      title: place.title,
      text: `Hey! I found this event on Walkabout called '${place.title}' that I want to invite you to! Check it out - `,
      url: location.origin + `/place/${place.uuid}/preview?invite-code=${user?.referralCode}`,
    };
    await navigator.share(shareData);
    // const placeLink =
    //   location.origin + `/place/${place.id}/preview?invite-code=${user?.referralCode}`;
    // navigator.clipboard.writeText(placeLink);
    // ToastMessage({
    //   status: 'success',
    //   text: 'Invite link copied to your clipboard',
    // });
  };

  return (
    <Flex justify="space-evenly" gap=".5rem" whiteSpace="nowrap" flexWrap="wrap">
      {place.phone && (
        <Link href={`tel: +${place.phone}`} style={{textDecoration: 'none'}}>
          <Flex minW="60px" direction="column" align="center">
            <Icon iconName="fi-rr-phone-call" style={iconStyle} />
            <TextG12 color={theme.colors.lightBlue}>Call</TextG12>
          </Flex>
        </Link>
      )}
      <ExternalLink
        href={getDirectionsLinkFromPlace(place)}
        isExternal
        style={{textDecoration: 'none'}}>
        <Flex minW="60px" direction="column" align="center">
          <Icon iconName="fi-rr-map-marker" style={iconStyle} />
          <TextG12 color={theme.colors.lightBlue}>Directions</TextG12>
        </Flex>
      </ExternalLink>
      <Flex minW="60px" direction="column" align="center" onClick={handleInviteClicked}>
        <Icon iconName="fi-rr-user-add" style={iconStyle} />
        <TextG12 color={theme.colors.lightBlue}>Share</TextG12>
      </Flex>
      <Flex
        minW="60px"
        direction="column"
        align="center"
        onClick={
          preview
            ? () => {
                setSignUpRequiredOpen(true);
              }
            : handleOnBookmarkClick
        }>
        {isBookmarked ? (
          <Icon
            iconName="fi-sr-bookmark"
            style={preview ? {...iconStyle, color: theme.colors.grey} : iconStyle}
          />
        ) : (
          <Icon
            iconName="fi-rr-bookmark"
            style={preview ? {...iconStyle, color: theme.colors.grey} : iconStyle}
          />
        )}
        <TextG12 color={preview ? theme.colors.grey : theme.colors.lightBlue}>Bookmark</TextG12>
      </Flex>
      <Flex
        minW="60px"
        color={theme.colors.lightBlue}
        direction="column"
        align="center"
        onClick={() => navigate(`/place/${place.id}/chat`)}>
        <Icon iconName="fi-rr-comment" style={{fontSize: '1.5rem'}} />
        <TextG12>Place Chat</TextG12>
      </Flex>
      {place.website && (
        <ExternalLink href={place.website} isExternal style={{textDecoration: 'none'}}>
          <Flex minW="60px" direction="column" align="center">
            <Icon iconName="fi-rr-globe" style={iconStyle} />
            <TextG12 color={theme.colors.lightBlue}>Website</TextG12>
          </Flex>
        </ExternalLink>
      )}
    </Flex>
  );
};

export default IconsHeader;
