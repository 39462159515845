import React, {useMemo, useState} from 'react';
import {Center, Flex, Box, Divider, Container, Spacer, VStack, Button} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import {H3, TextA18, TextG10, TextG14} from '../../components/typography';
import NotificationCard, {NotificationCardProps} from '../../components/NotificationCard';
import {useGetNotifications, useReadAllNotification} from '../../hooks/useNotifications';
import {ToastMessage} from '../../components/toast';

const Notifications = () => {
  const navigate = useNavigate();
  const {data, hasNextPage, fetchNextPage} = useGetNotifications();
  const {mutateAsync} = useReadAllNotification();
  const notificationsPages = data?.pages;

  const body = useMemo(
    () =>
      notificationsPages ? (
        <>
          {notificationsPages.map((pages, i) => (
            <React.Fragment key={i}>
              {pages.data.data.map((notification: NotificationCardProps) => (
                <VStack key={notification.id}>
                  <NotificationCard {...notification} />
                  <Divider />
                </VStack>
              ))}
            </React.Fragment>
          ))}
        </>
      ) : (
        <TextA18>You have no notications yet</TextA18>
      ),
    [notificationsPages]
  );

  return (
    <Container h="fit-content" p={0}>
      <Box m={4}>
        <Flex align="center">
          <BackButton pos="relative" onClick={() => navigate(-1)} />
          <Box flex="1">
            <Center>
              <H3 mr="35px">Notifications</H3>
            </Center>
          </Box>
          {notificationsPages && (
            <TextG10
              position="absolute"
              right={4}
              onClick={async () => {
                const {data} = await mutateAsync();
                data?.message &&
                  ToastMessage({
                    status: 'success',
                    showStatusIcon: true,
                    text: data?.message,
                  });
              }}
              fontWeight="400"
              as="u">
              Mark all as read
            </TextG10>
          )}
        </Flex>
      </Box>
      <Divider />
      {notificationsPages && notificationsPages[0].data.data.length === 0 ? (
        <Flex mt={4} height="70vh" justifyContent="center" alignItems="center">
          <TextA18 fontWeight="700">You Don't Have Any Notifications Yet!</TextA18>
        </Flex>
      ) : (
        <Box mt={0}>{body}</Box>
      )}
      {hasNextPage && (
        <Center mt={4}>
          <Button w="100%" onClick={() => fetchNextPage()}>
            Show More
          </Button>
        </Center>
      )}
    </Container>
  );
};

export default Notifications;
