import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Center,
  Flex,
  HStack,
  Box,
  Text,
  Divider,
  Container,
  useTheme,
  Link,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  VStack,
} from '@chakra-ui/react';
import BackButton from '../../components/BackButton';
import {H3, TextG10, TextG12, TextG14} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import UserAvatar from '../../components/UserAvatar';
import ChatbotLauncher from '../../components/ChatbotLauncher';
import StarIconBlack from '../../assets/images/star-icon-black.png';
import ReferBackground from '../../assets/images/refer-background.jpg';
import EditProfile from './Edit';
import UserHandle from './Edit/UserHandle';
import LifestyleGoalsAndInterests from './LifestyleGoalsAndInterests';
import PointsAndRewards, {PointsAndRewardsTopBar} from './PointsAndRewards';
import {Wrapper} from '../../routes/AuthorizedRoutes';
import {BlueButton} from '../../components/button';
import {ToastMessage} from '../../components/toast';
import ExternalLink from '../../components/ExternalLink';

//const REFER_URL = 'www.walkabout.app/refer-a-friend?referrer=';

function Profile() {
  const navigate = useNavigate();
  const theme = useTheme();
  const {user, logout} = useAuth();
  const [drawerType, setDrawerType] = useState<string>();
  //const [drawerBody, setDrawerBody] = useState<JSX.Element>();

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [margin, setMargin] = useState('0');

  const userReferLink = location.origin + '/refer-a-friend?invite-code=' + user?.referralCode;

  const appVersion = APP_VERSION;

  useEffect(() => {
    if (user && (user.lifestyleGroups.length < 1 || user.topics.length < 1)) {
      navigate('/profile/lifestyle-goals-and-interests');
    }
  }, [location.href]);

  const drawerBody = useMemo(() => {
    switch (drawerType) {
      case 'profileEdit':
        return <EditProfile onClose={onClose} />;
      case 'lifestyle':
        return <LifestyleGoalsAndInterests onClose={onClose} />;
      case 'points':
        return (
          <Wrapper
            onClose={onClose}
            Element={PointsAndRewards}
            CustomTopBar={PointsAndRewardsTopBar}
            progressBarProps={{hideHeader: true}}
            hideNavigationBar
          />
        );
      default:
        return null;
    }
  }, [drawerType]);

  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight - window.innerHeight,
        behavior: 'smooth',
      });
    }, 300);
  };

  useEffect(() => {
    if (!isOpen) {
      setDrawerType('');
      setMargin('0');
    }
  }, [isOpen]);

  return (
    <Container pos="absolute" left={margin} style={{transition: 'all 0.33s ease-out'}}>
      <Flex flexDir={'column'} h="100vh" pb={4} justifyContent="space-between">
        <Box>
          <Box pt={6}>
            <Flex alignItems="center">
              <BackButton pos={'relative'} onClick={() => navigate('/home')} />
              <Box flex="1">
                <Center ml="-35px">
                  <H3>My Account</H3>
                </Center>
              </Box>
            </Flex>
          </Box>
          <Box mt="24px" py={5}>
            <Flex px="4px" alignItems="center">
              <UserAvatar user={user} size="lg" />
              <Flex mx="24px" flexDir="column" gap={1}>
                <TextG14 fontWeight="bold">{`${user?.name} ${user?.surname}`}</TextG14>
                <UserHandle text={user?.handle} />
              </Flex>
            </Flex>
          </Box>
          <Divider />
          <ChatbotLauncher
            customLauncher={
              <HStack spacing={4} px={2} py={5} w="100%">
                <i style={{marginTop: '6px'}} className="fi-rr-comment-user"></i>
                <TextG14>Help & Support</TextG14>
              </HStack>
            }
          />
          <Divider />
          <HStack
            px={2}
            spacing={4}
            py={5}
            w="100%"
            // onClick={() => navigate('/profile/points-and-rewards')}
            onClick={() => {
              setDrawerType('points');
              setMargin('-33%');
              onOpen();
            }}>
            <Image src={StarIconBlack} alt={'Star Icon'} w={'16px'} h={'16px'} />
            <TextG14>Points & Rewards</TextG14>
          </HStack>
          <Divider />
          <HStack
            spacing={4}
            px={2}
            py={5}
            w="100%"
            onClick={() => navigate('/my-stuff?tab=bookmarks')}>
            <i className="fi fi-rr-bookmark"></i>
            <TextG14>Bookmarks</TextG14>
          </HStack>
          <Divider />
          {/*<HStack spacing={4} px={2} py={5} w="100%">*/}
          {/*  <i className="fi fi-rr-chart-histogram"></i>*/}
          {/*  <TextG14>Activities</TextG14>*/}
          {/*</HStack>*/}
          {/*<Divider />*/}
          <HStack
            spacing={4}
            px={2}
            py={5}
            w="100%"
            onClick={() => {
              setDrawerType('lifestyle');
              setMargin('-33%');
              onOpen();
            }}
            // onClick={() => navigate('/profile/lifestyle-goals-and-interests')}
          >
            <i className="fi fi-rr-user"></i>
            <TextG14>Lifestyle Goals & Interests</TextG14>
          </HStack>
          <Divider />
          <HStack
            spacing={4}
            px={2}
            py={5}
            w="100%"
            onClick={() => {
              setDrawerType('profileEdit');
              setMargin('-33%');
              onOpen();
            }}
            //onClick={() => navigate(`/profile/edit/${user?.id}`)}
          >
            <i className="fi fi-rr-settings"></i>
            <TextG14>Account Settings</TextG14>
          </HStack>
          <Divider />
          {/* <HStack spacing={4} px={2} py={5} w="100%" onClick={() => navigate('/help')}>
        <i className="fi fi-rr-info"></i>
        <TextG14>Help</TextG14>
      </HStack> 
      <Divider /> */}
          <Accordion allowMultiple>
            <AccordionItem borderTopWidth={0} borderColor={theme.colors.lighterGrey + '99'}>
              <AccordionButton _hover={{}} px={2} py={5} onClick={() => scrollToBottom()}>
                <Flex alignItems="center" flex="1" textAlign="left">
                  <i className="fi fi-rr-megaphone"></i>
                  <TextG14 ml={4}>Refer a Friend</TextG14>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pt={0} px={0} pb={4}>
                <Flex flexDirection="column">
                  <Box position="relative">
                    <Image
                      w="100%"
                      src={ReferBackground}
                      borderRadius="10px"
                      alt={'Refer a Friend'}
                    />
                    <TextG14
                      textAlign="center"
                      lineHeight="22.54px"
                      px="25px"
                      fontWeight="700"
                      position="absolute"
                      color="white"
                      bottom="8px">
                      Get a 25k Points Bonus when you refer a friend who joins Walkabout.
                    </TextG14>
                  </Box>
                  <Flex
                    mt="8px"
                    alignItems="center"
                    flexDirection="row"
                    pl="16px"
                    pr="4px"
                    py="4px"
                    border={`1px solid ${theme.colors.lighterGrey}`}
                    borderRadius="10px">
                    <TextG10
                      mr="8px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      color={theme.colors.lightBlack}>
                      {userReferLink}
                    </TextG10>
                    <BlueButton
                      onClick={() => {
                        navigator.clipboard.writeText(userReferLink);
                        ToastMessage({
                          status: 'success',
                          text: 'Link copied to clipboard',
                        });
                      }}
                      fontSize="10px"
                      maxH="40px"
                      borderRadius="10px">
                      Copy Link
                    </BlueButton>
                  </Flex>
                  <TextG10 textAlign="center" mt="10px" color={theme.colors.grey}>
                    Copy and share your referral link with friends.
                  </TextG10>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <HStack spacing={4} px={2} py={5} w="100%" onClick={() => logout()}>
            <i className="fi fi-rr-power"></i>
            <TextG14>Logout</TextG14>
          </HStack>
        </Box>
        <VStack spacing={6} pb={6}>
          <Center
            textAlign="center"
            w="100%"
            pos="relative"
            left={margin}
            //bottom="7%"
            color={theme.colors.lighterBlue}>
            <TextG12>
              <ExternalLink
                _hover={{textDecoration: 'none'}}
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/privacy-policy/`}>
                Privacy
              </ExternalLink>
              {' & '}
              <ExternalLink
                _hover={{textDecoration: 'none'}}
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/terms-of-use/`}>
                Terms
              </ExternalLink>
            </TextG12>
          </Center>

          <Center textAlign="center" w="100%" pos="relative" left={margin}>
            <TextG12>Version: {appVersion}</TextG12>
          </Center>
        </VStack>
        <Drawer onClose={onClose} isOpen={isOpen} size="full">
          <DrawerContent
            motionProps={{
              variants: {
                enter: {
                  x: '0%',
                  transition: {duration: 0.2},
                },
                exit: {
                  x: '100%',
                  transition: {duration: 0.1},
                },
              },
            }}
            padding={0}
            boxShadow="dark-lg">
            <DrawerBody padding={0}>{drawerBody}</DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Container>
  );
}

export default Profile;
