import React, {useEffect, useState} from 'react';
import {Offer, OfferType, Place} from '../../../types/acrticle';
import OfferCard from '../../../components/Articles/Offers/OfferCard';
import {filter, map, uniqBy} from 'lodash';
import useActiveRedemptions from '../../../hooks/useActiveRedemptions';
import ActiveRedemptionCard from '../../../components/Articles/Offers/ActiveRedemptionCard';
import {Box, VStack} from '@chakra-ui/react';

const PlaceOffers = ({
  place,
  placeOffers,
  preview = false,
  setSignUpRequiredOpen,
}: {
  place: Place;
  placeOffers: Offer[];
  preview?: boolean;
  setSignUpRequiredOpen?: any;
}) => {
  const [offers, setOffers] = useState<Array<any>>([]);

  const {activeRedemptions, getActiveRedemption, isActiveOffer} = useActiveRedemptions();

  useEffect(() => {
    const activeOffers = map(
      filter(
        activeRedemptions,
        (redemption) =>
          redemption.offer?.place_id == place.id && !!redemption.offer.countdown_required
      ),
      (redemption) => redemption.offer
    );
    setOffers((prev) => uniqBy([...placeOffers, ...activeOffers], 'id'));
  }, [placeOffers]);

  return (
    <VStack gap="15px">
      {map(offers, (offer) => (
        <Box mt="0 !important" w="100%" textAlign={'start'} key={offer.id}>
          {/* <ActiveRedemptionCard
            offer={offer as Offer}
            activeRedemption={getActiveRedemption(offer as Offer)}
            hideCard={!isActiveOffer(offer as Offer)}
          /> */}
          <OfferCard
            showBookmark={!preview}
            onClick={
              preview
                ? () => {
                    setSignUpRequiredOpen(true);
                  }
                : undefined
            }
            place={place}
            offer={offer as Offer}
            // hideActive={(offer as Offer).countdown_required}
          />
        </Box>
      ))}
    </VStack>
  );
};

export default PlaceOffers;
