import {TabList, Tab, theme, useTheme} from '@chakra-ui/react';
import React from 'react';
import {TextG12} from '../../../typography';

export const CHAT_TABS = ['Thread', 'Replies & Mentions', 'Announcements'];

const ChatTabs = ({tabIndex}) => {
  const theme = useTheme();
  return (
    <TabList w="100vw" justifyContent="center">
      {CHAT_TABS.map((tab, index) => (
        <Tab key={index}>
          <TextG12 color={tabIndex == index ? theme.colors.darkGrey : theme.colors.lightestGrey}>
            {tab}
          </TextG12>
        </Tab>
      ))}
    </TabList>
  );
};

export default ChatTabs;
