import {
  Divider,
  Editable,
  EditablePreview,
  EditableTextarea,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import React, {useRef, useState} from 'react';
import EditableControls from '../../components/EditableControls';
import IceBreakerSelect from '../../components/IceBreakerSelect';
import {TextG14} from '../../components/typography';
import {useUpdateMeetUp} from '../../hooks/useMeetUps';
import {Event} from '../../types/article';

const About = ({event, isHost}: {event: Event; isHost: boolean}) => {
  const {mutateAsync} = useUpdateMeetUp();
  const [editingIceBreaker, setEditingIceBreaker] = useState(false);
  const [iceBreaker, setIceBreaker] = useState<string>(event.reason || '');
  const [customIceBreaker, setCustomIceBreaker] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <>
      <VStack mt={0} mb={4} px={4} spacing={4} alignItems={'start'}>
        <VStack alignItems={'start'} pos="relative" w="100%" mt={isHost ? '40px' : 'initial'}>
          <TextG14 fontWeight="700">Meet-Up Details:</TextG14>
          {isHost ? (
            <Editable
              w="100%"
              fontSize="14px"
              lineHeight="20px"
              fontFamily="Gordita"
              onSubmit={(value) => {
                mutateAsync({
                  ...event,
                  description: value,
                  place_id: event.place?.id,
                  eventId: event.id,
                });
              }}
              onEdit={() => {
                textareaRef?.current?.setSelectionRange(
                  textareaRef.current?.textContent?.length || 0,
                  textareaRef.current?.textContent?.length || 0
                );
              }}
              defaultValue={event.description}
              isPreviewFocusable={false}
              selectAllOnFocus={false}>
              <EditablePreview />
              <EditableTextarea ref={textareaRef} resize="none" as={Textarea} />
              <EditableControls top="-4px" />
            </Editable>
          ) : (
            <TextG14>{event.description}</TextG14>
          )}
        </VStack>
        <Divider color="#EFEFEF" />
        <VStack alignItems={'start'} pos="relative" w="100%">
          {isHost ? (
            <Editable
              value={event.reason}
              w="100%"
              fontSize="14px"
              lineHeight="20px"
              fontFamily="Gordita"
              onCancel={() => setEditingIceBreaker(false)}
              onSubmit={(value) => {
                setEditingIceBreaker(false);
                mutateAsync({
                  ...event,
                  reason: iceBreaker == 'custom' ? customIceBreaker : iceBreaker,
                  place_id: event.place?.id,
                  eventId: event.id,
                });
              }}
              onEdit={() => {
                setEditingIceBreaker(true);
              }}
              defaultValue={event.reason}
              isPreviewFocusable={false}
              selectAllOnFocus={false}>
              <EditablePreview />
              {editingIceBreaker ? (
                <IceBreakerSelect
                  iceBreaker={iceBreaker}
                  setIceBreaker={setIceBreaker}
                  customIceBreaker={customIceBreaker}
                  setCustomIceBreaker={setCustomIceBreaker}
                />
              ) : null}
              <EditableControls />
            </Editable>
          ) : (
            <>
              <TextG14 fontWeight="700">Ice Breaker:</TextG14>
              <TextG14>{event.reason}</TextG14>
            </>
          )}
        </VStack>
      </VStack>
    </>
  );
};

export default About;
