import {Flex} from '@chakra-ui/react';
import React from 'react';
import MembershipHeaderCard from './MembershipHeaderCard';

const Header = ({memberships, selectedMembershipId, setSelectedMembershipId}) => {
  // const lowestPrice: number = (
  //   minBy(memberships, (membership) => parseFloat(membership.monthly_cost)) as any
  // )?.monthly_cost;

  return (
    <>
      <Flex
        gap="10px"
        position="relative"
        // top="-65px"
        // width="90%"
        // marginLeft="5%"
        mt="60px"
        transform="auto"
        // marginBottom="-6.5rem"
        flexDirection="row"
        justifyContent="center">
        {memberships.map((membership, i) => (
          <MembershipHeaderCard
            key={i}
            membership={membership}
            selectedMembershipId={selectedMembershipId}
            setSelectedMembershipId={setSelectedMembershipId}
          />
        ))}
        {/* <TextA18 fontWeight="700">Walkabout Membership</TextA18>
        <HStack justifyContent="space-between">
          <TextG12>A short subtitle comes here</TextG12>
          {lowestPrice && lowestPrice > 0 && (
            <TextG12>{`From ${numberToCurrency(lowestPrice)}/mo`}</TextG12>
          )}
        </HStack> */}
      </Flex>
    </>
  );
};

export default Header;
