import {TextG14} from '../../../typography'
import {Flex} from '@chakra-ui/react'
import React from 'react'
import Select, {Props} from 'react-select'
import {FilterOption} from '../../../../utils/GlobalSearch/types'

interface MultiSelectFilterProps {
  id?: string;
  label: string;
  value?: FilterOption[];
  options: FilterOption[];
  onChange: Props['onChange'];
  isMulti?: boolean;
}



const sortSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: '#276ACD',
    border: '1px solid #BCD7FF',
    background: '#E7F4FF',
    flexDirection: 'row-reverse',
    borderRadius: '6px',
    paddingRight: '6px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#276ACD',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#276ACD',
    '&:hover': {
      background: '#BCD7FF',
      color: '#276ACD',
    },
  }),
}

const SelectFilter = ({id, label, value, options, onChange, isMulti = true}: MultiSelectFilterProps) => {
  return (
    <Flex alignItems={'center'} pl={5} pr={4} py={5} gap={8}>
      <TextG14 sx={{wordWrap: 'normal'}}>{label}</TextG14>
      <Select
        key={id}
        value={value}
        onChange={onChange}
        styles={sortSelectStyles}
        maxMenuHeight={220}
        menuPlacement="auto"
        isMulti={isMulti}
        isClearable={!isMulti}
        isSearchable={false}
        // @ts-ignore
        options={options}
      />
    </Flex>
  )
}

export default SelectFilter
