import React from 'react';
import {usePointHistory} from '../../../../hooks/usePointsHistory';
import {SpinnerLoader} from '../../../../components/Loader';
import {Box, Flex, SimpleGrid, Spacer, useTheme} from '@chakra-ui/react';
import {TextG12} from '../../../../components/typography';
import dayjs from 'dayjs';
import {DarkWhiteButton} from '../../../../components/button';
import he from 'he';

const PointHistory = () => {
  const theme = useTheme();
  const {setPage, isLoading, hasMore, totalHistory} = usePointHistory();

  if (isLoading) return <SpinnerLoader />;

  return (
    <SimpleGrid columns={1} pb={1}>
      {totalHistory.length <= 0 && (
        <Box key={0} px={1} py={4} borderBottom={`1px solid ${theme.colors.lightestGrey}`}>
          No recent activity.
        </Box>
      )}
      {totalHistory.map((historyItem) =>
        historyItem.data?.message == 'Voucher Redemption' ? null : (
          <Box
            key={historyItem.id}
            px={1}
            py={4}
            borderBottom={`1px solid ${theme.colors.lightestGrey}`}>
            <Flex>
              <Box>
                <TextG12
                  fontWeight={700}
                  color={
                    historyItem.points_given <= 0 || historyItem.no_points
                      ? theme.colors.red
                      : theme.colors.grey
                  }>
                  {historyItem.points_given <= 0 ? '' : historyItem.no_points ? '-' : '+'}
                  {historyItem.points_given?.toLocaleString() ||
                    historyItem.no_points?.toLocaleString()}
                </TextG12>
              </Box>
              <Spacer />
              <TextG12 whiteSpace="nowrap" fontWeight={500} color={theme.colors.lightestGrey}>
                {dayjs(historyItem.created_at).calendar(null, {
                  sameDay: '[Today]',
                  nextDay: '[Tomorrow]',
                  nextWeek: 'dddd',
                  lastDay: '[Yesterday]',
                  lastWeek: '[Last] dddd',
                  sameElse: 'MM/DD/YYYY',
                })}
              </TextG12>
            </Flex>
            <Flex>
              <Box pr={4}>
                {historyItem.data?.message ? (
                  <TextG12 color={theme.colors.grey}>{he.decode(historyItem.data.message)}</TextG12>
                ) : historyItem.offer ? (
                  <TextG12 color={theme.colors.grey}>
                    {he.decode(`Redemption for ${historyItem.offer.title}`)}
                  </TextG12>
                ) : historyItem?.place?.name || historyItem?.event?.name ? (
                  <TextG12 color={theme.colors.grey}>
                    {he.decode(
                      `Check-in at ${historyItem?.place?.name || historyItem?.event?.name}`
                    )}
                  </TextG12>
                ) : null}
              </Box>
              <Spacer />
              <TextG12 whiteSpace="nowrap" fontWeight={500} color={theme.colors.lightestGrey}>
                {dayjs(historyItem?.created_at).format('hh:mm a')}
              </TextG12>
            </Flex>
          </Box>
        )
      )}
      {hasMore && (
        <DarkWhiteButton my={2} onClick={() => setPage((prevPageNumber) => prevPageNumber + 1)}>
          Load more
        </DarkWhiteButton>
      )}
    </SimpleGrid>
  );
};

export default PointHistory;
