import {useQuery} from '@tanstack/react-query';
import {fetchQRCodeDetails} from '../endpoints/api';

export const useFetchQRDetails = (slug) => {
  return useQuery(
    ['qr-code-details', slug],
    () => {
      if (slug) return fetchQRCodeDetails(slug);
    },
    {
      enabled: typeof slug === 'string',
    }
  );
};
