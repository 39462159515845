import {
  Flex,
  Container,
  useTheme,
  Box,
  Center,
  Divider,
  Spacer,
  Stack,
  HStack,
  Image,
  VStack,
} from '@chakra-ui/react';
import React, {useMemo, useState} from 'react';
import Select from 'react-select';
import Icon from '../../components/Icon';
import {H3, TextG12, TextG14} from '../../components/typography';
import {fetchBookmarks} from '../../endpoints/api';
import Articles from '../../components/Articles';
import HomePage from '../../components/Articles/HomePage';
import PostCard from '../../components/Articles/PostCard';
import EventCard from '../../components/Articles/EventCard';
import PlaceCard from '../../components/Articles/PlaceCard';
import {useNavigate} from 'react-router-dom';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import IconCustom from '../../components/Icon';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import {useInfiniteBookmarks} from '../../hooks/useFetchBookmarks';

type BookmarkTypes = 'article' | 'post' | 'event' | 'place' | 'offer';
export type BookmarksInterface = {
  [index in BookmarkTypes]: {
    tabName: string;
    tabElement: JSX.Element;
    endpoint?: (...params) => Promise<any>;
    hook?: any;
    endpointParams?: Record<string, number | string | boolean>;
    renderArticleType?: boolean;
  };
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    ':focus-visible': {
      border: 'none',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 100,
    width: '180px',
    position: 'absolute',
    right: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: '190px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    marginLeft: '5px',
    '> div': {
      padding: 0,
      '> svg': {
        width: '14px',
        height: '14px',
      },
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#272929',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 0,
    boxShadow: 'none',
    minHeight: 'unset',
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#272929',
    fontWeight: 700,
    fontSize: '12px',
    fontFamily: 'Gordita',
    margin: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    paddingTop: '9px',
    paddingBottom: '9px',
    paddingLeft: '20px',
    backgroundColor: 'white',
    fontWeight: state.isSelected ? 700 : 400,
    fontFamily: 'Gordita',
    fontSize: '12px',
    color: '#272929',
  }),
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  padding: 5px;
  color: white;
  background-color: white;
`;

export const BookmarkIcon = ({isBookmarked, ...props}) => {
  const theme = useTheme();
  const iconStyleBookmark: React.CSSProperties = React.useMemo(
    () => ({
      fontSize: '0.9rem',
      padding: '.5rem',
      marginTop: '1px',
      color: theme.colors.blue,
    }),
    [theme, isBookmarked]
  );

  return (
    <StyledBox {...props}>
      <IconCustom
        iconName={isBookmarked ? 'fi-sr-bookmark' : 'fi-rr-bookmark'}
        style={iconStyleBookmark}
      />
    </StyledBox>
  );
};

const Bookmarks = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState({label: 'All Bookmarks', value: 'article'});
  const {user} = useAuth();

  const lifestyleDataParams: string[] | [] = useMemo(() => {
    if (!user?.lifestyleGroups) return [];
    return user?.lifestyleGroups.map((group, index) => {
      return `${index === 0 ? '?' : '&'}lifeTag=${encodeURIComponent(group.name)}&lifeTagId=${
        group.id
      }`;
    });
  }, [user?.lifestyleGroups]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  const bookmarksConfig: BookmarksInterface = {
    article: {
      tabName: 'All Bookmarks',
      tabElement: <HomePage />,
      hook: useInfiniteBookmarks,
    },
    post: {
      tabName: 'Posts',
      tabElement: <PostCard />,
      renderArticleType: false,
      hook: useInfiniteBookmarks,
    },
    event: {
      tabName: 'Events',
      tabElement: <EventCard />,
      endpoint: fetchBookmarks,
      hook: useInfiniteBookmarks,
      endpointParams: {type: 'event'},
      renderArticleType: false,
    },
    place: {
      tabName: 'Places',
      tabElement: <PlaceCard />,
      endpoint: fetchBookmarks,
      endpointParams: {type: 'place'},
      renderArticleType: false,
      hook: useInfiniteBookmarks,
    },
    offer: {
      tabName: 'Offers',
      tabElement: <OfferCard />,
      endpoint: fetchBookmarks,
      endpointParams: {type: 'offer'},
      renderArticleType: false,
      hook: useInfiniteBookmarks,
    },
  };

  const content = useMemo(
    () => (
      <>
        {/* {(selectedOption.value == 'offer' || selectedOption.value == 'article') && (
          <ActiveRedemptionCards hideNoCountdown={false} />
        )} */}
        <Articles
          type={selectedOption.value}
          //endpoint={bookmarksConfig[selectedOption.value].endpoint}
          hook={bookmarksConfig[selectedOption.value].hook}
          endpointParams={bookmarksConfig[selectedOption.value]?.endpointParams || {}}
          noItems={
            <Box px={12}>
              <Center py={5}>
                <Icon iconName="fi-rr-bookmark" style={iconStyle} />
              </Center>
              <TextG14 textAlign={'center'} color={theme.colors.grey}>
                This list is empty.
                <br /> Let’s
                <Box
                  as={'span'}
                  color={theme.colors.blue}
                  onClick={() =>
                    navigate({
                      pathname: '/search/all',
                      search: `${[...lifestyleDataParams].join('')}`,
                    })
                  }>
                  {` find something `}
                </Box>
                to add.
              </TextG14>
            </Box>
          }>
          {bookmarksConfig[selectedOption.value].tabElement}
        </Articles>
      </>
    ),
    [selectedOption.value]
  );

  const options = Object.keys(bookmarksConfig).map((key) => ({
    value: key,
    label: bookmarksConfig[key].tabName,
  }));

  return (
    <Container pt={1} px={'0 !important'} pb={'initial'} h="auto">
      <Flex my={3}>
        <Center>
          <H3 color={theme.colors.darkGrey}>My Bookmarks</H3>
        </Center>
        <Spacer />
        <HStack>
          <TextG12>Filter:</TextG12>
          <Select
            styles={customStyles}
            defaultValue={selectedOption}
            isSearchable={false}
            onChange={(e) => setSelectedOption({value: `${e?.value}`, label: `${e?.label}`})}
            options={options}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </HStack>
      </Flex>
      <Divider mb={3} />
      {content}
    </Container>
  );
};

export default Bookmarks;
