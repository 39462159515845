import React, {useState, useEffect} from 'react';
import {find, orderBy, some} from 'lodash';
import {Offer} from '../types/acrticle';

export interface ActiveRedemption {
  id: number;
  timeRemaining: number;
  voucherCode: string;
  offer: Offer;
  redemptionDateTime?: string;
}

export const isActiveRedemption = (item) => {
  if (item.voucherCode) return true;

  return false;
};

interface ActiveRedemptionsAPI {
  activeRedemptions: ActiveRedemption[];
  setActiveRedemptions: React.Dispatch<React.SetStateAction<ActiveRedemption[]>>;
  addActiveRedemption: (redemption: ActiveRedemption) => void;
  removeActiveRedemption: (redemption: ActiveRedemption) => void;
  clearVisibleArticles: () => void;
  isActiveOffer: (offer: Offer) => boolean;
  getActiveRedemption: (offer: Offer) => ActiveRedemption | undefined;
}

const ActiveRedemptionsContext = React.createContext<ActiveRedemptionsAPI>(
  {} as ActiveRedemptionsAPI
);

export const ActiveRedemptionsContextProvider = ({children}) => {
  const [activeRedemptions, setActiveRedemptions] = useState<Array<ActiveRedemption>>([]);

  useEffect(() => {
    setActiveRedemptions(
      orderBy(
        JSON.parse(localStorage.getItem('active_redemptions') || '[]'),
        'redemptionDateTime',
        'desc'
      )
    );
  }, []);

  useEffect(() => {
    localStorage.setItem('active_redemptions', JSON.stringify(activeRedemptions));
  }, [activeRedemptions]);

  const clearVisibleArticles = () => {
    setActiveRedemptions([]);
  };

  const addActiveRedemption = (redemption: ActiveRedemption) => {
    //if (!find(activeRedemptions, {id: visibleArticle.id}))
    setActiveRedemptions((redemptions) => [redemption, ...redemptions]);
  };

  const removeActiveRedemption = (redemption: ActiveRedemption) => {
    setActiveRedemptions((redemptions) => [
      ...redemptions.filter(
        (r) => !(r.offer.id === redemption.offer.id && r.timeRemaining === redemption.timeRemaining)
      ),
    ]);
  };

  const isActiveOffer = (offer: Offer) => {
    return some(activeRedemptions, (redemption) => redemption.offer.id === offer.id);
  };

  const getActiveRedemption = (offer: Offer) => {
    return find(activeRedemptions, (redemption) => redemption.offer.id === offer.id);
  };

  const activeRedemptionsAPI: ActiveRedemptionsAPI = {
    activeRedemptions,
    setActiveRedemptions,
    addActiveRedemption,
    removeActiveRedemption,
    clearVisibleArticles,
    isActiveOffer,
    getActiveRedemption,
  };

  return (
    <ActiveRedemptionsContext.Provider value={activeRedemptionsAPI}>
      {children}
    </ActiveRedemptionsContext.Provider>
  );
};

export default function useActiveRedemptions() {
  return React.useContext(ActiveRedemptionsContext);
}
