import {Box, Textarea, useTheme} from '@chakra-ui/react';
import React from 'react';
import Select from 'react-select';
import {TextG10} from '../typography';

const ICE_BREAKER_OPTIONS = [
  {value: 'Describe yourself in 3 words', label: 'Describe yourself in 3 words'},
  {value: 'custom', label: 'Write Your Own!'},
];
const MAX_ICE_BREAKER_LENGTH = 100;

const IceBreakerSelect = ({iceBreaker, setIceBreaker, customIceBreaker, setCustomIceBreaker}) => {
  const theme = useTheme();

  return (
    <>
      <Select
        onChange={(newValue) => (newValue ? setIceBreaker(newValue.value) : setIceBreaker(''))}
        placeholder="Choose a fun ice breaker"
        isSearchable={false}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: 'auto',
            width: '100%',
            padding: '2px 14px',
            fontFamily: 'Gordita',
            fontSize: '12px',
            fontWeight: '500',
            borderRadius: '22.5px',
            background: 'transparent',
            border: '1px solid #E5E5E5',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: theme.colors.lightestGrey,
          }),
        }}
        className="basic-single"
        classNamePrefix="select"
        name="Ice Breaker"
        options={ICE_BREAKER_OPTIONS}
      />
      {iceBreaker == 'custom' && (
        <Box w="100%" mt={2}>
          <Textarea
            placeholder="Write your own ice breaker!"
            value={customIceBreaker}
            onChange={(e) =>
              (e.target.value.length || 0) <= MAX_ICE_BREAKER_LENGTH &&
              setCustomIceBreaker(e.target.value)
            }
            resize="none"
            w="100%"
            h="80px"
          />
          <TextG10 w="100%" textAlign="right" mt="6px" pr="4px" color={theme.colors.lighterGrey}>
            {customIceBreaker.length || 0}/{MAX_ICE_BREAKER_LENGTH}
          </TextG10>
        </Box>
      )}
    </>
  );
};

export default IceBreakerSelect;
