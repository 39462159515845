import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

interface UserEvent {
  uuid?: string
  type: string;
  event_name: string;
  event_time?: string;
  data?: any;
}

const useTrackEvent = () => {
  const addUserEvent = (userEvent: UserEvent) => {
    userEvent = {...userEvent, event_time: dayjs().utc().format(), uuid: uuidv4()};
    const events = JSON.parse(sessionStorage.getItem('user_events') || '[]');
    events.push(userEvent);
    sessionStorage.setItem('user_events', JSON.stringify(events));
  };

  return {addUserEvent};
};

export default useTrackEvent;
