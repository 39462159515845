import {fetchCalendars} from '../endpoints/api';
import {useQuery} from '@tanstack/react-query';

export function useFetchCalendars(enabled = true) {
  return useQuery(
    ['calendars'],
    () => fetchCalendars().then((response) => response.data?.data),
    {enabled: enabled}
  );
}
