import React from 'react';
import styled from 'styled-components';
import {Box, Image, useTheme} from '@chakra-ui/react';

import angleLeft from '../../assets/images/angle-left.png';

const Circle = styled(Box)`
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  display: inline-block;
`;

const StyledImage = styled(Image)`
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

function BackButton({onClick, type = 'white', ...props}) {
  const theme = useTheme();

  const background =
    type === 'grey' ? theme.colors.lightestGrey : type === 'transparent' ? 'transparent' : 'white';

  const border = type === 'transparent' ? 'unset' : `1px solid ${theme.colors.lightestGrey}`;

  return (
    <Circle
      zIndex="1"
      position="relative"
      {...props}
      background={background}
      border={border}
      onClick={onClick}>
      <StyledImage src={angleLeft} alt="Arrow left" w={'1rem'} h={'1rem'} />
    </Circle>
  );
}

export default BackButton;
