import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {Box, Button, Flex, ModalCloseButton, Spacer, useTheme} from '@chakra-ui/react';
import {H2, TextG12, TextG14} from '../../typography';
import TooltipIcon from '../../../assets/images/TooltipIcon.png';
import Logo from '../../../assets/logos/logo_48x48.png'; //Dock_icon

const StyledTooltip = styled(ReactTooltip)`
  transition: opacity 0.33s ease-out, transform 0.33s ease-out;
  z-index: 1600;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};

  border-radius: 7px;
  background: white;
  color: black;
  border: 1px solid grey;
  pointer-events: auto;
  transform: ${(props) => (props.isOpen ? 'scale(1)' : 'scale(0.8)')};
  width: 90%;
  max-width: 90%;
  padding: 14px 14px;
  ${(props) => (props.anchorId !== 'none' ? '' : 'top: 40%; left: 5%;')}

  .arrow-style {
    ${(props) =>
      props.hideArrow
        ? 'visibility: hidden;'
        : `
                z-index: -1;
                background-color: white !important;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                top: ${props.place == 'bottom' ? '-9px !important' : 'initial'};
                bottom: ${props.place == 'top' ? '-9px !important' : 'initial'};
                left: ${props.arrowLeft ? `${props.arrowLeft} !important` : 'initial'};
                border-left: ${props.place == 'bottom' ? '1px solid grey' : 'none'};
                border-top: ${props.place == 'bottom' ? '1px solid grey' : 'none'};
                border-bottom: ${props.place == 'top' ? '1px solid grey' : 'none'};
                border-right: ${props.place == 'top' ? '1px solid grey' : 'none'};
              `}
  }
`;

const PulseStyle = createGlobalStyle`
.pulse {
	transform: scale(1);
  filter: brightness(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0%, 100% {
		transform: scale(1);
      filter: brightness(1);
      -webkit-filter: brightness(1);
	}

	50% {
		transform: scale(1.08);
    filter: brightness(1.5);
    -webkit-filter: brightness(1.5);
	}
}
`;

export interface ButtonStyle {
  color?: string;
  background?: string;
}

export interface TooltipProps {
  anchorId: string | 'none';
  step?: number;
  totalSteps?: number;
  title?: string;
  message: string;
  buttonCta?: string;
  buttonPrev?: string;
  highlightId?: string;
  pulseId?: string;
  isOpen?: boolean;
  color?: string;
  highlight?: boolean;
  place?: 'top' | 'bottom' | 'left' | 'right';
  hideArrow?: boolean;
  arrowLeft?: string;
  offset?: number;
  showDots?: boolean;
  onCtaClick?: () => void;
  onPrevClick?: () => void;
  prevButtonStyle?: ButtonStyle;
  skip?: boolean;
  showClose?: boolean;
  onCloseClick?: (closePath?: string) => void;
  onShow?: () => void;
  closePath?: string;
  hideButtons?: boolean;
}

const Tooltip = ({
  anchorId,
  step,
  totalSteps,
  title,
  message,
  highlightId = anchorId,
  pulseId,
  isOpen,
  color,
  highlight = highlightId ? true : false,
  place = 'bottom',
  hideArrow = anchorId === 'none' ? true : false,
  arrowLeft,
  buttonCta = 'Next',
  buttonPrev = 'Previous',
  offset,
  showDots = true,
  onCtaClick,
  onPrevClick,
  prevButtonStyle = {color: 'white'},
  showClose = true,
  onCloseClick,
  onShow,
  closePath,
  hideButtons = false,
}: TooltipProps) => {
  const theme = useTheme();
  const [initialColor, setInitialColor] = useState<string>('');
  const [initialZIndex, setInitialZIndex] = useState<string>('');
  const elementReference = document.querySelector(`[id='${highlightId}']`);
  const ref = elementReference as HTMLElement;

  useEffect(() => {
    if (ref) {
      setInitialZIndex(ref.style.zIndex || '0');
      color ? setInitialColor(ref.style.color) : null;
    }
  }, [ref]);

  useEffect(() => {
    if (isOpen) {
      onShow && onShow();
      if (ref) {
        ref?.scrollIntoView({block: 'center'});
      }
      if (anchorId == 'none') window.scrollTo(0, 0);
    }
    if (ref && initialZIndex) {
      if (isOpen) {
        // use setTimeout so color & z-index changes correctly when going to previous
        setTimeout(() => {
          color ? (ref.style.color = color) : null;
          ref.style.zIndex = '1500';
        }, 1);
      } else {
        color ? (ref.style.color = initialColor) : null;
        ref.style.zIndex = initialZIndex;
      }
    }
  }, [isOpen, initialZIndex]);

  useEffect(() => {
    if (pulseId) {
      const elementReference = document.querySelector(`[id='${pulseId}']`);
      const ref = elementReference as HTMLElement;

      if (ref) {
        if (isOpen) {
          ref.className = ref.className + ' pulse';
        } else {
          ref.className = ref.className.replace(' pulse', '');
        }
      }
    }
  }, [isOpen, pulseId]);

  return (
    <StyledTooltip
      offset={offset}
      place={place}
      hideArrow={hideArrow}
      arrowLeft={arrowLeft}
      classNameArrow="arrow-style"
      border
      isOpen={isOpen}
      anchorId={anchorId}>
      <PulseStyle />
      <Flex mb={2} align="center" justify="space-between">
        {title && (
          <Flex align="center" gap={2}>
            <img width="28px" height="28px" src={Logo} />
            <H2 color={theme.colors.rose} fontWeight="700">
              {title}
            </H2>
          </Flex>
        )}
        {showClose && (
          <ModalCloseButton
            position="relative"
            height="26px"
            width="26px"
            top={0}
            right={0}
            onClick={() => {
              if (onCloseClick) {
                closePath ? onCloseClick(closePath) : onCloseClick();
                if (ref && ref.style) {
                  color ? (ref.style.color = initialColor) : null;
                  ref.style.zIndex = initialZIndex;
                }
              }
            }}
          />
        )}
      </Flex>
      <TextG14 color={theme.colors.darkGrey} fontWeight="400">
        {message}
      </TextG14>
      <Flex mt={4} justifyContent="space-between" align="center" gap={3}>
        {showDots && (
          <Flex gap="4px">
            {[...Array(totalSteps)].map((e, i) => {
              const background = step === i ? theme.colors.blue : theme.colors.grey2;
              return (
                <Box key={i} w="6px" h="6px" borderRadius="full" background={background}></Box>
              );
            })}
          </Flex>
        )}

        <Spacer />
        {onPrevClick && !hideButtons && (
          <Button
            w="70px"
            h="25px"
            borderRadius="12.5px"
            background={prevButtonStyle.background || theme.colors.blue}
            color={prevButtonStyle.color}
            onClick={() => {
              if (onPrevClick == onCloseClick) closePath ? onCloseClick(closePath) : onCloseClick();
              else onPrevClick();
            }}>
            <TextG12 fontWeight="500">{buttonPrev}</TextG12>
          </Button>
        )}
        {!hideButtons && (
          <Button
            w="70px"
            h="25px"
            borderRadius="12.5px"
            background={theme.colors.blue}
            onClick={() => {
              if (onCtaClick) {
                if (onCtaClick == onCloseClick)
                  closePath ? onCloseClick(closePath) : onCloseClick();
                else onCtaClick();
              }
            }}>
            <TextG12 color={'white'} fontWeight="500">
              {buttonCta}
            </TextG12>
          </Button>
        )}
      </Flex>
    </StyledTooltip>
  );
};

export default Tooltip;
