export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      navigator.serviceWorker
        .register("/serviceWorker.js")
        .then((reg) => {
          console.log("service worker registered");

          // detect Service Worker update available and wait for it to become installed
          reg.addEventListener("updatefound", () => {
            if (reg.installing) {
              // wait until the new Service worker is actually installed (ready to take over)
              reg.installing.addEventListener("statechange", () => {
                if (reg.installed) {
                  // if there's an existing controller (previous Service Worker), show the prompt
                  if (navigator.serviceWorker.controller) {
                    window.location.replace(window.location.href);
                  } else {
                    // otherwise it's the first install, nothing to do
                    console.log(
                      "Service Worker initialized for the first time"
                    );
                  }
                }
              });
            }
          });
        })
        .catch((err) => console.log("service worker not registered", err));
    });
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((reg) => reg.unregister())
      .catch((err) => console.error(err.message));
  }
}
