import React, {useEffect, useState} from 'react';
import {TextG10, TextG12} from '../../typography';
import {Box, Center, Divider, Flex, HStack, VStack} from '@chakra-ui/react';
import {components} from 'react-select';
import useGlobalSearch from '../../../hooks/useGlobalSearch';
import {ArticleSortOptions, useSortingOptions} from '../../../hooks/useSortingOptions';
import BottomModalSheet, {BottomModalSheetRefProps} from '../../Modals/BottomModalSheet';
import Icon from '../../Icon';
import QuickDateFilter from '../QuickDateFilter';
import {CONTENT_TYPE} from '../../../utils/GlobalSearch/enums';
import {mapSortOptions} from '../../../utils/GlobalSearch/helpers';
import {startCase} from 'lodash';

export const Control = (props) => {
  return (
    <Flex alignItems={'center'} gap={2}>
      <TextG10>Sort By: </TextG10>
      <components.Control {...props}>{props.children}</components.Control>
    </Flex>
  );
};

type SortProps = {
  type: CONTENT_TYPE;
  sheetRef: React.MutableRefObject<BottomModalSheetRefProps | undefined>;
};

const Sort = ({type = CONTENT_TYPE.ALL, sheetRef}: SortProps) => {
  const [sortingOptions, setSortingOptions] = useState<ArticleSortOptions[]>([]);
  const [selectedValue, setSelectedValue] = useState<ArticleSortOptions | null>();

  const {setTypeSpecificSort, getTypeSpecificSort} = useGlobalSearch();

  const sortOptionsData = useSortingOptions();

  const [isDraggable, setIsDraggable] = useState(false);

  useEffect(() => {
    if (!sortOptionsData) return;
    const contentTypeSortOptions = sortOptionsData[type];
    const availableArticleSortOptions = mapSortOptions(contentTypeSortOptions);

    setSortingOptions(availableArticleSortOptions);

    setSelectedValue(
      availableArticleSortOptions.find((sortOption) => {
        const typeSpecificSort = getTypeSpecificSort(type as CONTENT_TYPE);
        return (
          sortOption.orderBy === typeSpecificSort?.by &&
          sortOption.sort === typeSpecificSort?.direction
        );
      })
    );
  }, [sortOptionsData, type]);

  if (!sortingOptions.length) return null;

  return (
    <>
      <BottomModalSheet
        disableDrag={isDraggable}
        dragHandler={setIsDraggable}
        customHeader={<></>}
        triggerElement={
          <Flex alignItems={'center'} gap={2}>
            <TextG10>
              Sort By: <strong>{startCase(selectedValue?.label)}</strong>
            </TextG10>
            <Icon style={{fontSize: '10px'}} iconName="fi-rr-angle-down" />
          </Flex>
        }>
        <>
          <Center py={3}>
            <Icon style={{fontSize: '16px'}} iconName="fi-rr-angle-down" />
          </Center>
          <HStack px={6} pb={6}>
            <i className="fi fi-rr-settings-sliders"></i>
            <TextG12 fontWeight={'bold'}>Sort By</TextG12>
          </HStack>
          <Divider />
          <VStack px={2} align="start">
            {sortingOptions.map((option) => (
              <Box w={'100%'} key={option.value}>
                <Flex
                  pr={5}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  onClick={() => {
                    setSelectedValue(option);
                    setTypeSpecificSort(type as CONTENT_TYPE, {
                      by: option?.orderBy,
                      direction: option?.sort,
                    });
                  }}>
                  <TextG12 p={5}>{startCase(option.label)}</TextG12>
                  {selectedValue?.value === option.value && <i className="fi fi-sr-check"></i>}
                </Flex>
                <Divider />
              </Box>
            ))}
          </VStack>
        </>
      </BottomModalSheet>
      {type === CONTENT_TYPE.EVENT && <QuickDateFilter sheetRef={sheetRef} />}
    </>
  );
};

export default Sort;
