import {List, ListItem, VStack} from '@chakra-ui/react';
import {find} from 'lodash';
import React from 'react';
import {H2, TextG12} from '../../../components/typography';
import useAuth from '../../../hooks/useAuth';
import {Community} from '../../../types/community';

const About: React.FC<{community: Community}> = ({community}) => {
  const {user} = useAuth();
  const isInCommunity = find(user?.communities, {id: community.id});

  return (
    <VStack mt="-60px" mb="40px">
      {isInCommunity && community.post_purchase_description ? (
        <TextG12 mx={4} dangerouslySetInnerHTML={{__html: community.post_purchase_description}}>
          {}
        </TextG12>
      ) : (
        <TextG12 mx={4} dangerouslySetInnerHTML={{__html: community.description}}>
          {}
        </TextG12>
      )}
    </VStack>
  );
};

export default About;
