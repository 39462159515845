import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, useTheme, Flex, Image, VStack} from '@chakra-ui/react';
import OfferImage from '../../../../assets/images/offerImage.png';
import {TextG10, TextG12, TextG14, TextG16} from '../../../typography';
import {Offer, OfferType, Place, Event} from '../../../../types/acrticle';
import Card from '../../../Card';
import {getOfferDate, isFutureOffer} from '../../../../utils/date';
import useBookmark from '../../../../hooks/useBookmark';
import {getImageURL} from '../../../../constants/imageStorage';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../../hooks/useVisible';
import {has} from 'lodash';
import dayjs from 'dayjs';
import {useSearchParams} from 'react-router-dom';
import CardInterestTags from '../../CardInterestTags';
import {BookmarkElement} from '../../index';
import useOfferModal from '../../../../hooks/useOfferModal';
import styled from 'styled-components';
import Icon from '../../../Icon';
import {useFetchEvent} from '../../../../hooks/useEvent';
import useAuth from '../../../../hooks/useAuth';
import ExclusiveCommunityLabel from '../../../ExclusiveCommunityLabel';
import PremiumOfferCard from './PremiumOfferCard';
import useTrackEvent from '../../../../hooks/useTrackEvent';

interface OfferCardProps {
  offer?: any;
  fullCard?: boolean;
  rightBottomItem?: any;
  place?: Place;
  event?: Event;
  hideActive?: boolean;
  hideCard?: boolean;
  interestsScrollable?: boolean;
  height?: string;
  isFeatured?: boolean;
  showBookmark?: boolean;
  onClick?: () => void;
}

export const getOfferCardColor = (offerType: OfferType) => {
  switch (offerType) {
    case OfferType.INDIVIDUAL_REDEMPTION:
      return '#329241';
    case OfferType.REDEMPTION:
      return '#F5737F';
    case OfferType.PREMIUM_REDEMPTION:
      return '#E02F84';
    case OfferType.POINTS_BOOST:
      return '#8A44E3';
    case OfferType.EVENT_REDEMPTION:
      return '#272929';
    default:
      return '#8A44E3';
  }
};

const OverflowDiv = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 125px;
  z-index: 99;
  border-radius: 12px;
  display: flex;
  cursor: pointer;
  -webkit-padding-start: var(--chakra-space-3);
  padding-inline-start: var(--chakra-space-3);
  -webkit-padding-end: var(--chakra-space-3);
  padding-inline-end: var(--chakra-space-3);
  padding-top: var(--chakra-space-3);
  padding-bottom: var(--chakra-space-3);
`;

const StyledTitle = styled(TextG14)`
  font-size: ${(props) => (props.$isFeatured ? '18px !important' : '14px')};
  font-weight: ${(props) => (props.$isFeatured ? '700' : '500')};
`;

export const checkIsOfferLocked = (offerId: number, event?: Event) => {
  if (event?.unlocks_offer_id !== offerId) {
    return false;
  }

  if (event?.unlocked_offer) {
    return !(event?.unlocked_offer.offer_id === offerId && event?.unlocks_offer_id === offerId);
  }

  return true;
};

const OfferCard = ({
  offer,
  fullCard = true,
  rightBottomItem,
  place,
  event,
  hideActive = false,
  hideCard = false,
  interestsScrollable = false,
  height,
  isFeatured = false,
  showBookmark = true,
  onClick,
}: OfferCardProps) => {
  if (!offer || offer.offer_type === undefined) return null;
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const title = useRef<HTMLDivElement>(null);
  const [hide, setHide] = useState(hideCard);
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(offer as Offer, 'offer');
  const {showOfferModal} = useOfferModal();
  const isExclusive = offer?.communities?.length > 0 && offer.is_private;
  const [offerEvent, setOfferEvent] = useState<Event | undefined>(event);
  const [isOfferLocked, setIsOfferLocked] = useState(checkIsOfferLocked(offer.id, offerEvent));

  const {addUserEvent} = useTrackEvent();

  const {data: lazyLoadadedEvent} =
    !onClick && offer?.event_id ? useFetchEvent(offer.event_id, !onClick) : {data: null};

  useEffect(() => {
    if (
      (!offerEvent ||
        (offerEvent && has(offerEvent, 'unlocks_offer_id') && has(offerEvent, 'unlocked_offer'))) &&
      lazyLoadadedEvent
    ) {
      const fetchedOffer = lazyLoadadedEvent?.data;
      setOfferEvent(fetchedOffer);
      setIsOfferLocked(checkIsOfferLocked(offer.id, fetchedOffer));
    }
  }, [lazyLoadadedEvent]);

  const datePassed = useMemo(() => dayjs().utc().isAfter(dayjs.utc(offer?.ends_at)), [offer]);
  const futureOffer = useMemo(() => isFutureOffer(offer), [offer]);

  const [ref, {wasEverVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();
  const {user} = useAuth();

  useEffect(() => {
    if (!!searchParams.get('offer') && searchParams.get('offer') == offer.id.toString()) {
      showOfferModal({offer});
      if (!!searchParams.get('bookmark') && !isBookmarked) {
        handleOnBookmarkClick(null);
      }
    }
  }, [searchParams]);

  // useEffect(() => {
  //   if (hideActive && offer.countdown_required)
  //     if (some(activeRedemptions, (redemption) => redemption.offer.id === offer?.id)) {
  //       setHide(true);
  //     } else {
  //       setHide(false);
  //     }
  // }, [activeRedemptions, hideActive]);

  useEffect(() => {
    if (wasEverVisible && offer?.id.toString()) {
      addVisibleArticle({id: offer.id.toString(), type: 'offer'});
    }
  }, [wasEverVisible]);

  if (!isFeatured) {
    if (offer.is_premium) return <PremiumOfferCard offer={offer} />;
  }

  const offerTypePoints = ((offer?.offer_type == OfferType.POINTS_BOOST &&
    offer?.points_earning !== null) ||
    offer?.offer_type !== OfferType.POINTS_BOOST) && (
    <Box
      py={1}
      px={1}
      pos="absolute"
      top={offer?.offer_type == OfferType.POINTS_BOOST ? 0 : ''}
      left={offer?.offer_type == OfferType.POINTS_BOOST ? 0 : ''}
      bottom={offer?.offer_type == OfferType.POINTS_BOOST ? '' : 0}
      right={offer?.offer_type == OfferType.POINTS_BOOST ? '' : 0}
      borderTopLeftRadius="10px"
      borderBottomRightRadius="10px"
      background={offer?.offer_type == OfferType.POINTS_BOOST ? 'green' : theme.colors.rose}>
      <Flex>
        <TextG10
          fontWeight="700"
          color={offer?.offer_type == OfferType.POINTS_BOOST ? 'white' : theme.colors.white}>
          {offer?.offer_type == OfferType.POINTS_BOOST
            ? `+${offer?.points_earning.toLocaleString()}`
            : offer?.points_redeem_amount.toLocaleString() == '0'
            ? 'Free'
            : offer?.points_redeem_amount.toLocaleString()}
        </TextG10>
      </Flex>
    </Box>
  );

  const offerCardContent = isFeatured ? (
    <Card
      article={offer}
      childTop={isFeatured ? `calc(77% - ${(title.current?.clientHeight || 24) / 2}px)` : ''}
      overlayBackgroundColor={isFeatured ? '#2729295C' : ''}
      overlayHeight={isFeatured ? '33%' : ''}
      borderRadius={isFeatured ? '0px' : '12px'}
      height={height}
      backgroundColor={offer?.offer_type && getOfferCardColor(offer.offer_type)}
      childPaddingX={!fullCard ? 3 : 4}>
      <VStack alignItems={'left'} ref={title}>
        <StyledTitle
          wordBreak="break-word"
          fontWeight="700"
          color={theme.colors.white}
          noOfLines={2}
          $isFeatured={isFeatured}>
          {offer?.title}
        </StyledTitle>
        {futureOffer ? (
          <TextG10 fontWeight="700" color={theme.colors.white} isTruncated="&hellip;" noOfLines={1}>
            Available {getOfferDate(offer?.starts_at)}
          </TextG10>
        ) : (
          <TextG10 fontWeight="700" color={theme.colors.white} isTruncated="&hellip;" noOfLines={1}>
            {`${datePassed ? 'Expired on' : 'Expires'} ${getOfferDate(offer?.ends_at)}`}
          </TextG10>
        )}
      </VStack>
    </Card>
  ) : (
    <>
      {!hide && (
        <>
          {isExclusive ? <ExclusiveCommunityLabel community={offer.communities[0]} /> : null}

          <Box
            position="relative"
            ref={ref}
            px={fullCard ? 3 : 2}
            py={fullCard ? 3 : 2}
            borderRadius="12px"
            background={offer?.offer_type && getOfferCardColor(offer.offer_type)}
            height={height ? height : fullCard ? '125px' : '45vw'}
            width="100%"
            onClick={() => {
              onClick ? onClick() : showOfferModal({offer});

              addUserEvent({
                type: 'click',
                event_name: 'card_click',
                event_time: dayjs().format(),
                data: {itemType: 'offer', itemId: offer.id},
              });
            }}>
            <Flex
              gap={fullCard ? 2 : 1}
              w="100%"
              h="100%"
              userSelect="none"
              flexDir={fullCard ? 'row' : 'column'}>
              <Box maxW={fullCard ? '100px' : '100%'} flex="none" pos="relative">
                <Image
                  fallbackStrategy="onError"
                  w={fullCard ? '100px' : '100%'}
                  borderRadius="10px"
                  objectFit="cover"
                  h={fullCard ? '-webkit-fill-available' : '60px'}
                  src={offer?.image_url ? getImageURL(offer.image_url) : OfferImage}
                />
                {offerTypePoints}
              </Box>
              <VStack
                align="flex-start"
                color={theme.colors.white}
                px={fullCard ? 2 : 0}
                position="relative"
                flex="1">
                {fullCard ? (
                  <TextG16
                    textAlign="left"
                    pr={'36px'}
                    wordBreak="break-word"
                    fontWeight="700"
                    color={theme.colors.white}
                    noOfLines={2}>
                    {offer?.title}
                  </TextG16>
                ) : futureOffer ? (
                  <TextG10
                    mt={'5px'}
                    fontWeight="500"
                    color={theme.colors.white}
                    isTruncated="&hellip;"
                    noOfLines={2}>
                    Available{' '}
                    {getOfferDate(offer?.starts_at).includes('Tomorrow')
                      ? 'tomorrow'
                      : getOfferDate(offer?.starts_at).includes('Today')
                      ? 'today'
                      : `${getOfferDate(offer?.starts_at)}`}
                  </TextG10>
                ) : (
                  <TextG10
                    mt={'5px'}
                    fontWeight="500"
                    color={theme.colors.white}
                    isTruncated="&hellip;"
                    noOfLines={2}>
                    {`${datePassed ? 'Expired on ' : 'Expires '} ${
                      getOfferDate(offer?.ends_at).includes('Tomorrow')
                        ? 'tomorrow'
                        : getOfferDate(offer?.ends_at).includes('Today')
                        ? 'today'
                        : `${getOfferDate(offer?.ends_at)}`
                    }`}
                  </TextG10>
                )}

                {!fullCard ? (
                  <TextG12
                    wordBreak="break-word"
                    fontWeight="700"
                    color={theme.colors.white}
                    noOfLines={2}>
                    {offer?.title}
                  </TextG12>
                ) : futureOffer ? (
                  <TextG10
                    fontWeight="700"
                    color={theme.colors.white}
                    isTruncated="&hellip;"
                    noOfLines={1}>
                    Available {getOfferDate(offer?.starts_at)}
                  </TextG10>
                ) : (
                  <TextG10
                    fontWeight="700"
                    color={theme.colors.white}
                    isTruncated="&hellip;"
                    noOfLines={1}>
                    {`${datePassed ? 'Expired on' : 'Expires'} ${getOfferDate(offer?.ends_at)}`}
                  </TextG10>
                )}

                {offerEvent && offerEvent.unlocks_offer_id !== null ? (
                  <TextG10 noOfLines={!fullCard ? 1 : 2} color={theme.colors.white}>
                    {offerEvent.title}
                  </TextG10>
                ) : offer?.place ? (
                  <TextG10 noOfLines={!fullCard ? 1 : 2} color={theme.colors.white}>
                    {offer.place.name}
                  </TextG10>
                ) : place?.name ? (
                  <TextG10 noOfLines={!fullCard ? 1 : 2} color={theme.colors.white}>
                    {place.name}
                  </TextG10>
                ) : null}
                {offer.topics_of_interest && (
                  <Box position="absolute" bottom={0} width="100%" margin="0 !important">
                    <CardInterestTags
                      color="white"
                      width="100%"
                      tagsVisible={true}
                      autoScroll={true}
                      onlyShowMatched={false}
                      topicsOfInterest={offer.topics_of_interest}
                    />
                  </Box>
                )}
              </VStack>
              {rightBottomItem && rightBottomItem}
              {showBookmark && <BookmarkElement article={{...offer, type: 'offer'}} />}
            </Flex>
          </Box>
        </>
      )}
    </>
  );

  return (
    <Box w={'100%'} pos={'relative'} zIndex={10}>
      {isOfferLocked && (
        <OverflowDiv
          onClick={() => {
            onClick ? onClick() : showOfferModal({offer});

            addUserEvent({
              type: 'click',
              event_name: 'card_click',
              event_time: dayjs().format(),
              data: {itemType: 'offer', itemId: offer.id},
            });
          }}>
          <VStack
            pl={6}
            display="flex"
            pos="relative"
            alignItems={'center'}
            justifyContent={'center'}>
            <Icon iconName="fi fi-sr-lock" style={{color: 'white', fontSize: '30px'}} />
            <TextG14 color={'white'} fontWeight={'700'} mt={0}>
              Locked
            </TextG14>
          </VStack>
        </OverflowDiv>
      )}
      {offerCardContent}
    </Box>
  );
};

export default OfferCard;
