import React, {useMemo, useRef, useState, useLayoutEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Tab, TabList, Tabs, useTheme} from '@chakra-ui/react';
import {isNumber} from 'lodash';
import useAuth from '../../../hooks/useAuth';
import {isStandaloneSearchPage} from '../../../utils/GlobalSearch/helpers';
import Icon from '../../Icon';

const StyledTabList = styled(TabList)`
  gap: 6px;
`;

const StyledTab = styled(Tab)`
  margin-right: 5px;
  height: 40px;
`;

// Container to position the scroll indicators
const ScrollableTabsContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

// Wrapper that is scrollable; native scrollbar is hidden.
const ScrollableTabsWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

// Styles for the arrow indicators
const Indicator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  background: white;
  z-index: 1;
`;

const LeftIndicator = styled(Indicator)`
  left: -10px;
`;

const RightIndicator = styled(Indicator)`
  right: -10px;
`;

type TabTypes = 'post' | 'event' | 'place' | 'offer' | 'partner' | 'meet-up';
export type TabsInterface = {
  [index in TabTypes]: {
    tabName: string;
    tabElement: JSX.Element;
    title?: string;
    subtitle?: string;
    endpoint?: (...params: any[]) => Promise<any>;
    endpointParams?: Record<string, number | string | number[] | string[]>;
    renderArticleType?: boolean;
    hook?: any;
  };
};

const ArticleSearchNavigation = ({
  articleTabs,
  selectedIndex,
}: {
  articleTabs: any;
  selectedIndex?: number;
}) => {
  const theme = useTheme();
  const {type} = useParams();
  const {user} = useAuth();

  if (isStandaloneSearchPage(location)) {
    return null;
  }

  // // Get the list of tab keys
  const tabs = useMemo(() => Object.keys(Object.assign(articleTabs)), [articleTabs]);
  // const tabs = useMemo(() => {
  //   const keys = Object.keys(articleTabs);
  //   return user?.hasEarlyAccess ? keys : keys.filter((tab) => tab !== 'meet-up');
  // }, [articleTabs, user?.hasEarlyAccess]);

  // Ref for the scrollable container and state for the indicators
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const updateIndicators = () => {
    const el = scrollRef.current;
    if (el) {
      const {scrollLeft, scrollWidth, clientWidth} = el;
      setShowLeft(scrollLeft > 0);
      setShowRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  // Use useLayoutEffect with requestAnimationFrame to ensure accurate measurement
  useLayoutEffect(() => {
    updateIndicators();
    const el = scrollRef.current;
    if (el) {
      el.addEventListener('scroll', updateIndicators);
    }
    window.addEventListener('resize', updateIndicators);
    const rafId = requestAnimationFrame(updateIndicators);
    return () => {
      if (el) {
        el.removeEventListener('scroll', updateIndicators);
      }
      window.removeEventListener('resize', updateIndicators);
      cancelAnimationFrame(rafId);
    };
  }, []);

  const scrollAmount = 100; // Adjust scroll amount as needed

  const handleScrollLeft = () => {
    const el = scrollRef.current;
    if (el) {
      el.scrollBy({left: -scrollAmount, behavior: 'smooth'});
    }
  };

  const handleScrollRight = () => {
    const el = scrollRef.current;
    if (el) {
      el.scrollBy({left: scrollAmount, behavior: 'smooth'});
    }
  };

  return (
    <Tabs
      isLazy={true}
      index={isNumber(selectedIndex) ? selectedIndex : type ? tabs.indexOf(type) : 0}
      isFitted
      size="sm"
      variant="solid-rounded"
      borderBottom={`1px solid ${theme.colors.lighterGrey}`}>
      <ScrollableTabsContainer>
        <ScrollableTabsWrapper ref={scrollRef}>
          <StyledTabList>
            {tabs.map((tab) => (
              <StyledTab
                theme={theme}
                px={1}
                key={tab}
                _selected={{
                  borderRadius: '0',
                  borderBottom: `3px solid ${theme.colors.blue}`,
                  color: theme.colors.blue,
                }}>
                <Link style={{whiteSpace: 'nowrap'}} to={`/search/${tab}`}>
                  {articleTabs[tab].tabName}
                </Link>
              </StyledTab>
            ))}
          </StyledTabList>
        </ScrollableTabsWrapper>
        {showLeft && (
          <LeftIndicator onClick={handleScrollLeft}>
            <Icon
              iconName="fi fi-rr-arrow-small-left"
              style={{
                fontSize: '24px',
                marginTop: '0px',
              }}
            />
          </LeftIndicator>
        )}
        {showRight && (
          <RightIndicator onClick={handleScrollRight}>
            <Icon
              iconName="fi fi-rr-arrow-small-right"
              style={{
                fontSize: '24px',
                marginTop: '0px',
              }}
            />
          </RightIndicator>
        )}
      </ScrollableTabsContainer>
    </Tabs>
  );
};

export default ArticleSearchNavigation;
