import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Auth from '../pages/Auth';
import Login from '../pages/Auth/Login';
import {ROUTES} from '../constants/routes';
import NotFound from '../pages/NotFound';
import ResetPassword from '../pages/Auth/ResetPassword';
import InviteForm from '../pages/Auth/Register/InviteForm';
import RegisterForm from '../pages/Auth/Register/RegisterForm';
import AppRedirect from '../pages/DownloadApp/Redirect';
import SoftCheck from '../pages/Auth/SoftCheck';
import EventPreview from '../pages/EventPreview';
import ReferralRedirect from '../pages/ReferralRedirect';
import PlacePreview from '../pages/Place/Preview';
import PublicEvents from '../pages/PublicEvents';
import PostPreview from '../pages/Post/Preview';
import PartnerPreview from '../pages/Partner/Preview';
import QRCodeHandler from '../pages/QRCodeHandler';
import Memberships from '../pages/Memberships';
import PublicEventsLandscape from '../pages/PublicEvents/PublicEventsLandscape';
import GiftSubscription from '../pages/GiftSubscription';
import MemberInvite from '../pages/MemberInvite';
import MeetupPreview from '../pages/MeetupPreview';

function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/invite-code" element={<InviteForm />} />
      <Route path="/soft-check" element={<SoftCheck />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/download-app" element={<AppRedirect />} />
      <Route path="/event/:eventId/preview" element={<EventPreview />} />
      <Route path="/meet-up/:eventId/preview" element={<MeetupPreview />} />
      <Route path="/meetup/:eventId/preview" element={<MeetupPreview />} />
      <Route path="/place/:uuid/preview" element={<PlacePreview />} />
      <Route path="/post/:postId/preview" element={<PostPreview />} />
      <Route path="/partner/:partnerId/preview" element={<PartnerPreview />} />
      <Route path="refer-a-friend" element={<ReferralRedirect />} />
      <Route path="/public-events" element={<PublicEvents />} />
      <Route path="/public-events-landscape" element={<PublicEventsLandscape />} />
      <Route path="/memberships" element={<Memberships />} />
      <Route path="/member-invite" element={<MemberInvite />} />
      <Route path="/qrcode/:slug" element={<QRCodeHandler />} />
      <Route path="/q/:slug" element={<QRCodeHandler />} />
      <Route path="/gift" element={<GiftSubscription />} />
      <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      <Route
        path="*"
        element={<Navigate to={`/login?redirectTo=${location.pathname}${location.search}`} />}
      />
    </Routes>
  );
}

export default PublicRoutes;
