import {Box, Flex, Image, useTheme} from '@chakra-ui/react';
import React from 'react';
import { Community } from "../../types/community";
import {TextG12} from '../typography';
import {
  CommunityActions,
  saveCommunityToLocalStorage,
} from '../../state/Communities/CommunitiesSlice';
import {useDispatch} from 'react-redux';

const CommunityOptionsList = ({
  communities,
  selectedCommunity,
  onClose,
}: {
  communities: any[];
  selectedCommunity: Community;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Flex flexDir="column" mt="18px" gap="12px">
      {communities.map((community) => {
        const isSelected = selectedCommunity.id == community.id;
        return (
          <Box
            key={community.name}
            background={isSelected ? '#FFEED2' : 'white'}
            borderRadius="18px"
            border={`1px solid ${theme.colors.grey}`}
            p="12px 21px"
            onClick={() => {
              dispatch(CommunityActions.selectCommunity({community}));
              saveCommunityToLocalStorage(community as Community);
              onClose();
            }}>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="12px">
                {community.logo && (
                  <Image
                    w="30px"
                    h="30px"
                    borderRadius="100%"
                    objectFit="scale-down"
                    src={community.logo}
                  />
                )}
                <TextG12 fontWeight="700">{community.name}</TextG12>
              </Flex>
              {isSelected && (
                <i className="fi fi-rr-check-circle" style={{width: '16px', height: '18px'}}></i>
              )}
            </Flex>
          </Box>
        );
      })}
    </Flex>
  );
};

export default CommunityOptionsList;
