import {Center, HStack, useTheme, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {BlueButton} from '../../components/button';
import {LabelledCheckboxGrey} from '../../components/checkbox';
import Modal from '../../components/Modals/Modal';
import {H2, TextG12} from '../../components/typography';
import {OPTIONS} from '../../constants/eventOptions';
import {updateUser} from '../../endpoints/api';
import useAuth from '../../hooks/useAuth';
import {
  EventRSVPStatus,
  useFetchEventRSVPStats,
  useSetAttendance,
  useUpdateNotificationPreference,
} from '../../hooks/useEvent';
import {RSVPModalPopupActions} from './RSVPModalPopupSlice';

export interface RSVPModalPopupProps {
  eventId: number;
  prevStatus: number;
  status: number;
  title?: string;
  subtitle?: string;
  description?: string;
  renderIcon?: (...props: any) => any;
  closeLabel?: string;
  ctaLabel?: (...props: any) => string;
  ctaLink?: string;
}

const RSVPModalPopup = ({
  eventId,
  prevStatus,
  status,
  title,
  subtitle,
  description,
  renderIcon,
  closeLabel = 'Close',
  ctaLabel,
  ctaLink,
}: RSVPModalPopupProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [notificationsChecked, setNotificationsChecked] = useState(true);
  const fetchRSVPStats = useFetchEventRSVPStats(eventId);
  const {mutateAsync} = useSetAttendance();
  const updateNotificationPreference = useUpdateNotificationPreference();
  const {user, reFetchUserData} = useAuth();
  const rsvpStats = fetchRSVPStats.data?.data.rsvp_stats;
  
  const eventChatUsers = rsvpStats?.INTERESTED + rsvpStats?.GOING + rsvpStats?.NOT_GOING - 1;
  const shouldShowNotificationCheck = !!!(
    user?.emailEnabled &&
    user.smsEnabled &&
    user.pushEnabled
  );

  useEffect(() => {
    return () => {
      dispatch(RSVPModalPopupActions.popRSVPStatus());
    };
  }, []);

  useEffect(() => {
    if (!show) {
      dispatch(RSVPModalPopupActions.popRSVPStatus());
    }
  }, [show]);

  const handleNotificationsCheck = async () => {
    if (notificationsChecked) {
      await updateUser({email_enabled: true, sms_enabled: true, push_enabled: true});
      reFetchUserData();
      updateNotificationPreference.mutateAsync({
        eventId,
        receiveNotifications: true,
      });
    } else {
      updateNotificationPreference.mutateAsync({
        eventId,
        receiveNotifications: false,
      });
    }
  };

  const handleModelClosed = async (e) => {
    const storageKey = 'do-not-show-again-rsvp-modal';
    const doNotShowAgainRSVPModalObj = JSON.parse(localStorage.getItem(storageKey) || '[]');
    const index = doNotShowAgainRSVPModalObj.findIndex((obj) => obj.statusId === status);
    if (index !== -1) {
      doNotShowAgainRSVPModalObj[index].count = doNotShowAgain
        ? 3
        : doNotShowAgainRSVPModalObj[index].count + 1;
    } else {
      doNotShowAgainRSVPModalObj.push({statusId: status, count: doNotShowAgain ? 3 : 1});
    }
    localStorage.setItem(storageKey, JSON.stringify(doNotShowAgainRSVPModalObj));

    handleNotificationsCheck();
  };

  if (fetchRSVPStats.isFetching) {
    return null;
  }

  return (
    <Modal
      isCentered={true}
      closeOnOverlayClick={false}
      show={show}
      closeButton={false}
      header={
        <>
          {renderIcon && <Center pb={12}>{renderIcon()}</Center>}
          <Center>
            <H2>{title}</H2>
          </Center>
        </>
      }>
      <VStack pb={4} spacing={4}>
        <HStack spacing={1}>{subtitle && <TextG12 fontWeight={500}>{subtitle}</TextG12>}</HStack>
        {description && (
          <Center>
            <TextG12 textAlign={'center'} fontWeight={500} color={theme.colors.grey}>
              {description}
            </TextG12>
          </Center>
        )}
        {ctaLabel ? (
          <Center w={'100%'}>
            <BlueButton
              onClick={(e) => {
                setShow(false);
                if (ctaLink) {
                  if (status === EventRSVPStatus.NOT_INTERESTED) {
                    mutateAsync({
                      eventId,
                      prevStatus: EventRSVPStatus.NOT_GOING,
                      status: EventRSVPStatus.NOT_GOING,
                    });
                    OPTIONS[status].message('Cool! You’ll stay in the chat.');
                  }
                  handleNotificationsCheck();
                  window.location.href = `/event/${eventId}/${ctaLink}`;
                }
              }}
              w={'100%'}>
              {ctaLabel(eventChatUsers)}
            </BlueButton>
          </Center>
        ) : null}
        {shouldShowNotificationCheck && (
          <LabelledCheckboxGrey
            isCircle={false}
            label="We’ll notify you about event updates and chat."
            checked={notificationsChecked}
            onChange={(e) => setNotificationsChecked((prev) => !prev)}
            disabled={false}
          />
        )}

        <LabelledCheckboxGrey
          isCircle={false}
          label="Don't show this again"
          checked={doNotShowAgain}
          onChange={(e) => setDoNotShowAgain(!doNotShowAgain)}
          disabled={false}
        />
        {closeLabel && (
          <TextG12
            onClick={() => {
              if (status === EventRSVPStatus.NOT_INTERESTED) {
                OPTIONS[status].message(
                  'Thanks for letting us know! We won’t remind you about this event anymore.'
                );
              }
              handleModelClosed(status);
              setShow(false);
            }}
            textAlign={'center'}
            color={theme.colors.grey}>
            {closeLabel}
          </TextG12>
        )}
      </VStack>
    </Modal>
  );
};

export default RSVPModalPopup;
