import React, { useEffect, useState } from 'react';
import { Center, Spinner, useTheme, Stack } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { filterUserTopics } from '../../endpoints/api';
import { Offer, Post } from '../../types/article';
import { TextA18 } from '../../components/typography';
import SwiperSlider from '../../components/SwiperSlider';
import PostCard from '../../components/Articles/PostCard';
import PlaceCard from '../../components/Articles/PlaceCard';
import EventCard from '../../components/Articles/EventCard';
import { BookmarkElement as FixedBookmarkElement } from '../../components/Articles';
import Header from './Header';
import Content from './Content';
import ArticleInfo from '../../components/Articles/ArticleInfo';
import { useOffers } from '../../hooks/useOffers';
import ItemCarousel from '../../components/ItemCarousel';
import ActiveRedemptionCard from '../../components/Articles/Offers/ActiveRedemptionCard';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import useActiveRedemptions from '../../hooks/useActiveRedemptions';
import { usePost } from '../../hooks/usePost';
import { filter, map, uniqBy } from 'lodash';
import { usePosts } from '../../hooks/usePosts';
import dayjs from 'dayjs';

const Post = ({ onClose }: { onClose?: () => void }) => {
  const theme = useTheme();
  const { postId } = useParams();
  const { posts, postsIsLoading } = usePosts({});
  const [otherPosts, setOtherPosts] = useState<Post[]>([]);
  const [searchParams] = useSearchParams();
  const { activeRedemptions, getActiveRedemption, isActiveOffer } = useActiveRedemptions();

  const { offers, offersIsLoading } = useOffers({
    articleType: 'postId',
    articleId: postId || searchParams.get('id'),
  });

  const [postOffers, setOffers] = useState<Array<any>>([]);

  useEffect(() => {
    if (offers) {
      const activeOffers = map(
        filter(
          activeRedemptions,
          (redemption) =>
            (redemption.offer?.article_id == postId ||
              redemption.offer?.article_id?.toString() == searchParams.get('id')) &&
            !!redemption.offer.countdown_required
        ),
        (redemption) => redemption.offer
      );

      setOffers((prev) => uniqBy([...offers, ...activeOffers], 'id'));
    }
  }, [offers]);

  const { post, postIsLoading } = usePost({ postId: Number(postId || searchParams.get('id')) });

  useEffect(() => {
    if ((postId || searchParams.get('id')) && posts?.length) {
      const filteredPosts: Post[] = posts.filter((post: Post & { published_at?: string; expires_at?: string }) => {
        if (postId) {
          if (post.id === +postId) return false;
        } else {
          if (post.id === Number(searchParams.get('id'))) return false;
        }

        const currentDate = dayjs();

        // Only released in last 90 days
        if (post.released_at) {
          const releasedDate = dayjs(post.released_at);
          if (
            releasedDate.isBefore(currentDate.subtract(90, 'days'))
          ) return false;
        }

        // Not expired
        if (post.expires_at) {
          const expiresDate = dayjs(post.expires_at);
          if (
            expiresDate.isBefore(currentDate)
          ) return false;
        }

        return true;
      });
      setOtherPosts(filteredPosts);
    }
  }, [postId, posts]);

  if (postIsLoading || offersIsLoading || post === undefined || postsIsLoading) {
    return (
      <Center style={centerCss}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Header defaultImage={post.image_url} onClose={onClose} />
      <ItemCarousel
        padding={4}
        swiperProps={{
          slidesPerGroup: 1,
          slidesPerView: 1,
          items: postOffers,
          render: (item) => {
            return (
              <>
                {/* <ActiveRedemptionCard
                  offer={item as Offer}
                  activeRedemption={getActiveRedemption(item as Offer)}
                  hideCard={!isActiveOffer(item as Offer)}
                /> */}
                <OfferCard
                  place={post.place}
                  offer={item as Offer}
                // hideActive={(item as Offer).countdown_required}
                />
              </>
            );
          },
        }}
      />
      <Content post={post} />
      <Stack gap=".5rem" p="1rem" textAlign="left">
        {post.event ? (
          <>
            <TextA18>Go To This Event</TextA18>
            <EventCard event={post.event} hideRSVP />
          </>
        ) : post.place ? (
          <>
            <TextA18>Visit This Place</TextA18>
            <PlaceCard place={post.place} />
          </>
        ) : null}
        {!!otherPosts.length && !post.place && !post.event && (
          <>
            <TextA18>You'll probably like</TextA18>
            <SwiperSlider
              items={otherPosts}
              render={(item) => (
                <PostCard
                  post={item}
                  leftTopItem={<ArticleInfo article={item} />}
                  rightTopItem={<FixedBookmarkElement article={item} />}
                />
              )}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default Post;

const centerCss: React.CSSProperties = {
  minHeight: '100vh',
};
