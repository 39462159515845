/*
WORKAROUND FOR BACKEND IMAGES NOT HAVING FULL S3 URL AND ONLY FILE NAME
*/

const S3_URL = 'https://ctbs-dev.s3.amazonaws.com/';

// if the image path isn't a valid url, prepend the S3 bucket url
export function getImageURL(path: string) {
  try {
    const url = new URL(path);
    if (url.protocol === 'http:' || url.protocol === 'https:') return path;
  } catch (err) {
    return S3_URL + path;
  }
}
