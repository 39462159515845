import React from "react";
import {Box, Center, Container, Image, useTheme, VStack} from "@chakra-ui/react";
import { H3, TextG14} from "../../../../components/typography";
import {BlueButtonFixedWidth} from "../../../../components/button";
import ResetSuccess from '../../../../assets/images/reset-success.png';
import Correct from '../../../../assets/images/correct.png';

const PasswordChanged = ({ nextStep }) => {
  const theme = useTheme();

  return (
    <Container px={5} py={8}>
      <Image pt={20} src={ResetSuccess} alt={'Reset success'} />
      <VStack spacing={2}>
        <Image py={7} src={Correct} alt={'Success'} />
        <H3 fontWeight={700}>Password Changed</H3>
        <TextG14 color={theme.colors.grey}>
          Login with your new password.
        </TextG14>
        <Box py={8} w={'100%'}>
          <Center>
            <BlueButtonFixedWidth onClick={() => nextStep()}>
              Login
            </BlueButtonFixedWidth>
          </Center>
        </Box>
      </VStack>
    </Container>
  )
}

export default PasswordChanged;