import React, {useCallback, useMemo, useState} from 'react';
import {EventAttendanceStatus, Offer, OfferType} from '../../../../../types/article';
import {Place, Event} from '../../../../../types/article';
import {calculateAndGetDistance, getDistanceString} from '../../../../../utils/distance';
import useGetUserLocation from '../../../../../hooks/useUserLocation';
import useAuth from '../../../../../hooks/useAuth';
import {
  buyGiftOffer,
  fetchMembershipStripeLink,
  fetchOffer,
  redeemVoucher,
} from '../../../../../endpoints/api';
import RedemptionStepOne from './Steps/RedemptionStepOne';
import RedemptionStepTwo from './Steps/RedemptionStepTwo';
import RedemptionStepThree from './Steps/RedemptionStepThree';
import {ToastMessage} from '../../../../toast';
import {Center, ScaleFade, Stack} from '@chakra-ui/react';
import styled, {css} from 'styled-components';
import useActiveRedemptions, {ActiveRedemption} from '../../../../../hooks/useActiveRedemptions';
import {omit} from 'lodash';
import {SpinnerLoader} from '../../../../Loader';
import dayjs from 'dayjs';
import {AxiosError} from 'axios';
import {EventRSVPStatus, useSetAttendance} from '../../../../../hooks/useEvent';

const TransitionBox = styled(Stack)`
  align-items: center;
  margin-top: 0px !important;
  transition: opacity 0.5s ease !important;
`;

const COUNTDOWN_TIME = 300000; // 5 minutes
const EVENT_OFFSET_TIME = 12 * 3600 * 1000; // 12 hours

export const enum OFFER_STEPS {
  Initial,
  Confirm,
  Countdown,
}

const RedemptionModalContent = ({
  offer,
  event,
  place,
  isBookmarked,
  bookmarkHandler,
  setShowModal,
  activeRedemption,
  isFutureOffer,
  datePassed,
  isPreview = false,
}: {
  offer: Offer;
  event?: Event;
  place: Place;
  isBookmarked: boolean;
  bookmarkHandler: (e: any) => Promise<string | number | undefined> | undefined;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  activeRedemption?: ActiveRedemption;
  isFutureOffer: boolean;
  datePassed: boolean;
  isPreview?: boolean;
}) => {
  const userLocation = useGetUserLocation(!isPreview);
  const [currentStep, setCurStep] = useState<OFFER_STEPS>(
    activeRedemption ? OFFER_STEPS.Countdown : OFFER_STEPS.Initial
  );
  const [voucherCode, setVoucherCode] = useState<string>(
    activeRedemption ? activeRedemption.voucherCode : ''
  );
  const [timeRemaining, setTimeRemaining] = useState(
    activeRedemption ? activeRedemption.timeRemaining : Date.now() + COUNTDOWN_TIME
  );
  const {reFetchUserData} = useAuth();
  const {addActiveRedemption, removeActiveRedemption} = useActiveRedemptions();
  const [isLoading, setIsLoading] = useState(false);
  const {mutateAsync} = useSetAttendance();
  const [voucher, setVoucher] = useState();
  const [redemptionLink, setRedemptionLink] = useState<string | undefined>(
    activeRedemption ? `${location.origin}/gift?p=${activeRedemption.promoCode}` : ''
  );

  const redeemClicked = useCallback(async () => {
    try {
      if (offer.offer_type == OfferType.MEMBERSHIP) {
        const voucher = (await buyGiftOffer({offerId: offer?.id}))?.data;
        setRedemptionLink(`${location.origin}/gift?p=${voucher.promo_code}`);
        setVoucher(voucher);
        setCurStep(OFFER_STEPS.Countdown);
        setVoucherCode(voucher.redemption_voucher_code);

        const countdownTime = Date.now() + 24 * 3600 * 1000 * 365 * 100; // 100 years

        addActiveRedemption({
          id: countdownTime,
          offer: {
            ...omit(offer, [
              'redemption_vouchers',
              'bookmarked_by',
              'billable_dollar_amount',
              'place.bookmarked_by',
            ]),
            is_bookmarked: true,
          } as Offer,
          promoCode: voucher.promo_code,
          voucherCode: voucher.redemption_voucher_code,
          timeRemaining: countdownTime,
          redemptionDateTime: Date.now().toString(),
        });
      } else {
        // ONLY GRABBING THE FIRST VOUCHER IN THE ARRAY
        const voucher = (await fetchOffer(offer?.id))?.data?.redemption_vouchers[0];

        if (voucher) {
          setVoucher(voucher);
          setIsLoading(true);
          redeemVoucher(voucher.id, offer?.id)
            .then((res) => {
              const countdownTime =
                offer?.event?.start_date_time && offer.offer_type == OfferType.EVENT_REDEMPTION
                  ? dayjs(offer.event.start_date_time).valueOf() + EVENT_OFFSET_TIME
                  : !offer?.countdown_required && offer?.ends_at
                  ? Date.now() + 24 * 3600 * 1000 * 365 * 100 // 100 years
                  : Date.now() + COUNTDOWN_TIME;
              setCurStep(OFFER_STEPS.Countdown);
              setTimeRemaining(countdownTime);
              setVoucherCode(voucher.code);
              //if (!isBookmarked) bookmarkHandler(null);
              if (offer.event && offer.offer_type == OfferType.EVENT_REDEMPTION)
                mutateAsync({
                  eventId: offer.event.id,
                  prevStatus: offer.event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
                  status: EventRSVPStatus.GOING,
                });
              addActiveRedemption({
                id: countdownTime,
                redemptionId: res.data.redemption_id,
                offer: {
                  ...omit(offer, [
                    'redemption_vouchers',
                    'bookmarked_by',
                    'billable_dollar_amount',
                    'place.bookmarked_by',
                  ]),
                  is_bookmarked: true,
                } as Offer,
                voucherCode: voucher.code,
                timeRemaining: countdownTime,
                redemptionDateTime: Date.now().toString(),
              });

              reFetchUserData();
              ToastMessage({
                status: 'success',
                text: 'Redemption complete and saved in your Stuff. Your points balance is updated.',
              });

              // if (offer?.countdown_required) {
              //   setTimeout(() => {
              //     ToastMessage({
              //       status: 'success',
              //       text: res.data.message,
              //     });
              //   }, COUNTDOWN_TIME);
              // } else {
              //   ToastMessage({
              //     status: 'success',
              //     text: res.data.message,
              //   });
              // }
              setIsLoading(false);
            })
            .catch((e) => {
              setIsLoading(false);
              ToastMessage({
                status: 'error',
                text: e.message,
              });
            });
        } else {
          setIsLoading(false);
          ToastMessage({
            status: 'error',
            text: 'Sorry, this Offer is no longer available. Contact us if you think there was an error.',
          });
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof AxiosError) {
        if (e.response?.status == 404) {
          ToastMessage({
            status: 'error',
            text: 'Sorry, this offer is no longer valid.',
          });
          return;
        }
      }
      ToastMessage({
        status: 'error',
        text: 'Sorry, something went wrong.',
      });
    }
  }, [addActiveRedemption, isBookmarked]);

  const distance = useMemo(() => {
    if (offer.place || place) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(offer?.place?.latitude || place.latitude),
          longitude: Number(offer?.place?.longitude || place.longitude),
        }
      );
    }
    return 0;
  }, [userLocation, place, offer.place]);

  if (isLoading)
    return (
      <Center w="100%" minH="180px">
        <SpinnerLoader />
      </Center>
    );

  return (
    <>
      <ScaleFade
        style={{width: '100%', display: currentStep !== OFFER_STEPS.Initial ? 'none' : 'block'}}
        initialScale={0.8}
        in={currentStep == OFFER_STEPS.Initial}>
        <TransitionBox
          gap={1}
          width={currentStep !== OFFER_STEPS.Initial ? '0px' : ''}
          height={currentStep !== OFFER_STEPS.Initial ? '0px' : ''}
          opacity={currentStep !== OFFER_STEPS.Initial ? 0 : 1}
          visibility={currentStep !== OFFER_STEPS.Initial ? 'hidden' : 'visible'}>
          <RedemptionStepOne
            offer={offer}
            {...(event && {event})}
            place={place}
            bookmarkHandler={bookmarkHandler}
            isBookmarked={isBookmarked}
            setShowModal={setShowModal}
            distance={distance}
            setCurStep={setCurStep}
            isFutureOffer={isFutureOffer}
            datePassed={datePassed}
            isPreview={isPreview}
          />
        </TransitionBox>
      </ScaleFade>
      <ScaleFade
        style={{width: '100%', display: currentStep !== OFFER_STEPS.Confirm ? 'none' : 'block'}}
        initialScale={0.8}
        in={currentStep == OFFER_STEPS.Confirm}>
        <TransitionBox
          gap={1}
          width={currentStep !== OFFER_STEPS.Confirm ? '0px' : ''}
          height={currentStep !== OFFER_STEPS.Confirm ? '0px' : ''}
          opacity={currentStep !== OFFER_STEPS.Confirm ? 0 : 1}
          visibility={currentStep !== OFFER_STEPS.Confirm ? 'hidden' : 'visible'}>
          <RedemptionStepTwo
            offer={offer}
            setCurStep={setCurStep}
            redeemClicked={redeemClicked}
            datePassed={datePassed}
          />
        </TransitionBox>
      </ScaleFade>
      <ScaleFade
        style={{width: '100%', display: currentStep !== OFFER_STEPS.Countdown ? 'none' : 'block'}}
        initialScale={0.8}
        in={currentStep == OFFER_STEPS.Countdown}>
        <TransitionBox
          gap={1}
          width={currentStep !== OFFER_STEPS.Countdown ? '0px' : ''}
          height={currentStep !== OFFER_STEPS.Countdown ? '0px' : ''}
          opacity={currentStep !== OFFER_STEPS.Countdown ? 0 : 1}
          visibility={currentStep !== OFFER_STEPS.Countdown ? 'hidden' : 'visible'}>
          <RedemptionStepThree
            offer={offer}
            setShowModal={setShowModal}
            timeRemaining={timeRemaining}
            voucherCode={voucherCode}
            removeActiveRedemption={removeActiveRedemption}
            activeRedemption={activeRedemption}
            voucher={voucher}
            redemptionLink={redemptionLink}
          />
        </TransitionBox>
      </ScaleFade>
    </>
  );
};

export default RedemptionModalContent;
