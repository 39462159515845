import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {EventRSVPStatus, useFetchEvent} from '../../../hooks/useEvent';
import {
  useAddCommentImage,
  useCreateEventComment,
  useDeleteCommentImage,
  useDeleteEventComment,
  useFetchEventComment,
  useHelpfulComment,
  useReportComment,
  useUpdateEventComment,
} from '../../../hooks/useEventComments';
import ChatComment, {ChatMedia} from '../ChatComment';
import {AbsoluteCenter, Center, Flex, Spinner, useTheme} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../typography';
import ChatHeader from '../ChatHeader/EventChatHeader';
import dayjs from 'dayjs';
import Icon from '../../Icon';
import useAuth from '../../../hooks/useAuth';
import {useFetchChatUsers} from '../../../hooks/useEvents';
import {EventChatIsClosed} from '../EventChat';
import {getImage} from '../../../pages/Profile/Edit/CropImage';

const CommentThread = () => {
  const {user} = useAuth();
  const [isErrored, setIsErrored] = useState(false);
  const {eventId, commentId} = useParams();
  const fetchComment = useFetchEventComment({
    eventId: Number(eventId),
    commentId,
    enabled: !isErrored,
  });
  const fetchEvent = useFetchEvent(Number(eventId));
  const event = fetchEvent.data?.data;
  const comment = fetchComment.data?.data;
  const navigate = useNavigate();
  const theme = useTheme();
  const [headerHeight, setHeaderHeight] = useState(0);
  const eventChatClosed = EventChatIsClosed(event);
  const isRSVPd = !!(
    event?.rsvp_status.status_value &&
    event?.rsvp_status.status_value != EventRSVPStatus.NOT_INTERESTED
  );

  const addEventComment = useCreateEventComment();
  const updateEventComment = useUpdateEventComment();
  const deleteEventComment = useDeleteEventComment();
  const reportEventComment = useReportComment();
  const helpfulEventComment = useHelpfulComment();
  const addCommentImage = useAddCommentImage();
  const deleteCommentImage = useDeleteCommentImage();

  const addComment = async (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    if (content.length > 0) {
      const comment = await addEventComment.mutateAsync({
        eventId: event.id,
        content,
        parent_id,
        user,
        rsvp_status: event.rsvp_status.status_value as EventRSVPStatus,
        hasImage: imageURLs && imageURLs.length > 0,
      });

      if (imageURLs && imageURLs.length > 0 && comment) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);

          if (eventId && croppedImage) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: eventId,
              comment_id: comment.data.id,
            });
          }
        });
      }
    }
  };

  const updateComment = async (
    commentId: string,
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => {
    if (content.length > 0) {
      await updateEventComment.mutateAsync({
        eventId: event.id,
        commentId,
        content,
      });

      if (imageURLs && imageURLs.length > 0) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);
          if (eventId && croppedImage && commentId) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: eventId,
              comment_id: commentId,
            });
          }
        });
      }

      if (mediaToDelete && mediaToDelete.length > 0) {
        mediaToDelete.map(async (media) => {
          if (media.id) {
            await deleteCommentImage.mutateAsync({mediaId: media.id});
          }
        });
      }
    }
  };

  const deleteComment = async (commentId: string) => {
    await deleteEventComment.mutateAsync({
      eventId: event.id,
      commentId,
    });
  };

  const reportComment = async (commentId: string) => {
    await reportEventComment.mutateAsync({
      eventId: event.id,
      commentId,
    });
  };

  const helpfulComment = async (commentId: string) => {
    await helpfulEventComment.mutateAsync({
      eventId: event.id,
      commentId,
    });
  };

  useEffect(() => {
    if (fetchComment.isError) setIsErrored(true);
  }, [fetchComment]);

  if (!event) return <></>;

  if (eventChatClosed)
    return (
      <>
        <ChatHeader
          addComment={addComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          reportComment={reportComment}
          helpfulComment={helpfulComment}
          event={event}
          setHeaderHeight={setHeaderHeight}
          showTabs={false}
        />
        <AbsoluteCenter
          w="95%"
          textAlign="center"
          color={theme.colors.grey}
          pt={`${headerHeight / 2}px`}>
          <Flex flexDir="column" gap={8}>
            <Icon iconName="fi-rr-comment" style={{fontSize: '48px'}} />
            <TextG14 fontWeight="500">This event has ended and the chat is closed.</TextG14>
          </Flex>
        </AbsoluteCenter>
      </>
    );

  return (
    <Flex flexDir="column" pt={`${headerHeight}px`} px={4} pb="77px">
      <ChatHeader
        addComment={addComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        reportComment={reportComment}
        helpfulComment={helpfulComment}
        event={event}
        setHeaderHeight={setHeaderHeight}
        showTabs={false}
      />
      {fetchComment.isLoading || fetchEvent.isLoading ? (
        <Center h={`calc(100vh - ${headerHeight}px)`}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </Center>
      ) : !comment || isErrored ? (
        <AbsoluteCenter
          w="95%"
          textAlign="center"
          color={theme.colors.grey}
          pt={`${headerHeight / 2}px`}>
          <Flex flexDir="column" gap={8}>
            <Icon iconName="fi-rr-comment" style={{fontSize: '48px'}} />
            <TextG14 fontWeight="500">Sorry, this Comment is no longer available.</TextG14>
          </Flex>
        </AbsoluteCenter>
      ) : (
        <>
          <Flex w="100%" justify="space-between" mt={2} color={theme.colors.blue}>
            {comment.parent_id && (
              <TextG10 onClick={() => navigate(`/event/${eventId}/chat/${comment.parent_id}`)}>
                See parent thread
              </TextG10>
            )}
            <TextG10 onClick={() => navigate(`/event/${eventId}/chat`)}>
              See full discussion
            </TextG10>
          </Flex>
          <ChatComment
            fetchUsersHook={useFetchChatUsers}
            addComment={addComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            reportComment={reportComment}
            helpfulComment={helpfulComment}
            isReply={comment.parent_id}
            comment={comment}
            articleId={event.id}
            name={event.title}
            isRSVPd={isRSVPd}
            repliesShowingDefault
            eventChatClosed={eventChatClosed}
          />
        </>
      )}
    </Flex>
  );
};

export default CommentThread;
