import React from 'react';
import {Box, Avatar, AvatarBadge, AvatarProps, useTheme, useImage} from '@chakra-ui/react';
import {User} from '../../hooks/useAuth';
import {get} from 'lodash';
interface UserAvatarProps extends AvatarProps {
  user?: User | undefined;
  showBadge?: boolean;
  avatar_image?: string;
}

const BACKGROUND_COLORS = [
  '#323863',
  '#4D4194',
  '#8D52BD',
  '#8B6EAF',
  '#92217B',
  '#ED82E0',
  '#EA9FD0',
  '#F9E6EC',
];

const UserAvatar = ({
  user,
  showBadge = false,
  size,
  fontSize,
  onClick,
  avatar_image,
  ...rest
}: UserAvatarProps) => {
  const profileImage = avatar_image || user?.profilePhotoUrl || get(user, 'profile_photo_url');
  const userName = user?.surname
    ? `${user?.name} ${user?.surname}`
    : user?.name
    ? `${user?.name}`
    : `${user?.handle}`;
  const status = useImage({src: profileImage});

  const backgroundColor = user?.id ? BACKGROUND_COLORS[user.id % 8] : BACKGROUND_COLORS[0];

  return (
    <Box
      h="inherit"
      w="inherit"
      p={0.5}
      bg={
        !profileImage
          ? `linear-gradient(180deg, ${backgroundColor} -9.26%, ${backgroundColor} 79.63%)`
          : 'none'
      }
      zIndex={1}
      borderRadius="full"
      onClick={onClick}
      {...rest}>
      <Avatar
        fontSize={fontSize}
        bg={
          profileImage ||
          `linear-gradient(180deg, ${backgroundColor} -9.26%, ${backgroundColor} 79.63%)`
        }
        ignoreFallback={status === 'pending' || status === 'loading' || status === 'loaded'}
        size={size}
        name={userName}
        src={profileImage}>
        {showBadge && (
          <AvatarBadge
            _before={{content: '".."', transform: 'translateY(-3px)'}}
            boxShadow={`0 5px 10px -4px rgb(0 0 0)`}
            boxSize="1em"
            bg="white"
            color="#1A202C"
          />
        )}
      </Avatar>
    </Box>
  );
};

export default UserAvatar;
