import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Center, Fade, SimpleGrid, Spinner, usePrevious, useTheme} from '@chakra-ui/react';
import {jsx} from '@emotion/react';
import JSX = jsx.JSX;
import {filter, map} from 'lodash';
import {useLocation} from 'react-router-dom';
import {handleScrollPositionFromSession, storeScrollPositionInSession} from '../../utils/scroll';

interface QueryInfiniteScrollParams {
  showItems: (item) => JSX.Element;
  noItems?: JSX.Element;
  hook: any;
  endpointParams: Record<string, number | string | null>;
  gridGap?: string;
}

const QueryInfiniteScroll = ({
  showItems,
  noItems,
  hook,
  endpointParams,
  gridGap = '15px',
}: QueryInfiniteScrollParams) => {
  const theme = useTheme();
  const {data, hasNextPage, fetchNextPage, isLoading, isFetching} = hook(endpointParams);
  const queryPages = data?.pages;
  const [queryData, setQueryData] = useState<Array<any>>();
  const previousQueryData = usePrevious(queryData);
  const previousHook = usePrevious(hook);

  // The hook has changed but the data hasn't changed yet. This is to prevent showing previous data on a new tab when a user switches between them.
  const switchingTabs = hook !== previousHook && previousQueryData == queryData;

  useEffect(() => {
    setQueryData([]);
    if (!isLoading && queryPages) {
      map(queryPages, (pages, i) => {
        map(pages.data.data, (res) => {
          if (res.readable_status !== 'Not available for this user')
            setQueryData((prev) => [...filter(prev, (item) => item.id !== res.id), res]);
        });
      });
    }
  }, [queryPages]);

  useEffect(() => {
    handleScrollPositionFromSession();
  }, [queryData]);

  const observer = useRef<any>();

  const lastEventElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage]
  );
  if (switchingTabs) return null;

  if (isLoading)
    return (
      <Center pt={16}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <>
      <SimpleGrid gap={gridGap} columns={1}>
        {(!queryData || queryData.length == 0) && !isLoading && noItems}
        {queryData &&
          queryData.map((item, index) => {
            if (queryData.length === index + 1 && queryData.length !== 1) {
              return (
                <div key={`${item.id.toString()}_${index}`} ref={lastEventElementRef}>
                  {showItems(item)}
                </div>
              );
            }
            return (
              <div style={{display: 'contents'}} key={`${item.id.toString()}_${index}`}>
                {showItems(item)}
              </div>
            );
          })}
        {/* {error && <div style={{color: 'red', paddingInline: '1rem'}}>{error}</div>} */}
      </SimpleGrid>

      {hasNextPage && (
        <Center pt="30px" h="175px">
          {(isFetching || isLoading) && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor={theme.colors.lightGrey}
              color={theme.colors.lightBlue}
              size="lg"
            />
          )}
        </Center>
      )}
    </>
  );
};

export default QueryInfiniteScroll;
