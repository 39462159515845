import {useQuery} from '@tanstack/react-query';
import {fetchPlace, fetchPublicPlace} from '../endpoints/api';

interface FetchPlaceOptions {
  id: number;
  lat?: number;
  long?: number;
  enabled?: boolean
}

export const useFetchPlace = ({id, lat, long, enabled = true}: FetchPlaceOptions) => {
  return useQuery(['place', {id}], () => fetchPlace({id, lat, long}), {
    enabled: enabled && typeof id === 'number' && !isNaN(id),
  });
};

export const useFetchPublicPlace = (uuid) => {
  return useQuery(['public_place', {uuid}], () => fetchPublicPlace(uuid), {
    enabled: typeof uuid === 'string',
  });
};
