import {Box, VStack, Progress, Flex, Skeleton, useTheme} from '@chakra-ui/react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useGuidedVideos} from '../../hooks/useGuidedVideos';
import {TextG14, TextG10, TextG9} from '../typography';
import {VideoTask} from './GuidedExperienceWidget';
import JourneyBadge from './JouryneyBadge';

const GuidedJourneyHeader = ({
  setBottomSheetOpen,
  hideBadge = true,
}: {
  setBottomSheetOpen?: Dispatch<SetStateAction<boolean>>;
  hideBadge?: boolean;
}) => {
  const {data, isLoading} = useGuidedVideos();
  const theme = useTheme();

  const [watched, setWatched] = useState<Array<VideoTask>>([]);
  //   const [unwatched, setUnwatched] = useState<Array<VideoTask>>([]);
  const [taskList, setTaskList] = useState<Array<VideoTask>>([]);
  //   const [listLoaded, setListLoaded] = useState(false);

  const numCompleted = watched.length || 0;
  const percCompleted = (numCompleted / taskList.length) * 100;

  const isCompleted = numCompleted == taskList.length;

  useEffect(() => {
    if (data?.data) {
      let watchedVideos: VideoTask[] = [];
      let unwatchedVideos: VideoTask[] = [];

      if (Array.isArray(data?.data?.watched)) {
        watchedVideos = data?.data?.watched;
      } else {
        watchedVideos = Object.values(data?.data.watched);
      }

      if (Array.isArray(data?.data?.unwatched)) {
        unwatchedVideos = data?.data?.unwatched;
      } else {
        unwatchedVideos = Object.values(data?.data.unwatched);
      }

      setWatched(watchedVideos);
      //   setUnwatched(sortBy(unwatchedVideos, 'position'));

      setTaskList([
        ...watchedVideos.map((item) => ({...item, completed: true})),
        ...unwatchedVideos.map((item) => ({...item, completed: false})),
      ]);

      //   setListLoaded(true);
    }
  }, [data?.data]);

  //   if (isLoading || !listLoaded) {
  //     return <Skeleton w="100%" h="320px" borderRadius="8px" my={6} />;
  //   }

  return (
    <Flex justifyContent="space-between" gap="8px" w="100%">
      <Box w="100%">
        <TextG14 fontWeight="700">
          {isCompleted ? `You're Plugged-In` : 'Get Plugged-In and Earn 25k Points'}
        </TextG14>
        <VStack w="100%" mt={2} mb={4} alignItems="flex-start">
          <Progress
            sx={{
              '& > div': {
                // Targeting the inner div (the actual progress bar)
                transition: 'width 0.5s ease-in-out', // Adding a transition to the width
              },
            }}
            colorScheme="purpleProgress"
            w="100%"
            value={numCompleted}
            max={taskList.length}
            borderRadius="10px"
            h="6px"
          />
          <TextG10>
            {`${percCompleted.toFixed(0)}% Progress | ${numCompleted}/${
              taskList.length
            } Videos Completed${setBottomSheetOpen ? ' | ' : ''}`}
            {setBottomSheetOpen && (
              <span
                style={{color: theme.colors.blue, userSelect: 'none'}}
                onClick={() => setBottomSheetOpen(true)}>
                See All
              </span>
            )}
          </TextG10>
        </VStack>
      </Box>
      {!hideBadge && <JourneyBadge w="38px" h="38px" />}
    </Flex>
  );
};

export default GuidedJourneyHeader;
