import React from 'react';
import {Flex, Image, useTheme} from '@chakra-ui/react';
import FallbackImg from '../../assets/images/fallback.jpg';
import BackButton from '../../components/BackButton';
import {useNavigate, useSearchParams} from 'react-router-dom';

const Header = ({
  defaultImage,
  onClose,
  hideBack = false,
}: {
  defaultImage?: string;
  onClose?: () => void;
  hideBack?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Flex position="relative" height="310px">
      {!hideBack && (
        <BackButton
          onClick={() => {
            if (searchParams.has('id') && onClose) {
              onClose();
              return;
            }
            navigate(-1);
          }}
          m={'.75rem'}
          pos="relative"
        />
      )}
      <Image
        fallbackStrategy="onError"
        fallbackSrc={FallbackImg}
        src={defaultImage}
        alt="cover image"
        position="absolute"
        width="100%"
        height="100%"
        objectFit="cover"
        zIndex="-1"
        borderBottomRadius="5px"
      />
    </Flex>
  );
};

export default Header;
