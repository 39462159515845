import React, {useEffect, useRef, useState} from 'react';
import {Flex, Image, useTheme, VStack, HStack, Box, Center} from '@chakra-ui/react';
import BottomModalSheet, {BottomModalSheetRefProps} from '../Modals/BottomModalSheet';
import {TextG12, TextG14, TextG8} from '../typography';
import IOSShare from '../../assets/images/ios-share.png';
import IOSAdd from '../../assets/images/ios-add.png';
import DockbarBackground from '../../assets/images/dockbar-background.png';
import {BlueButton, WhiteGreyButton} from '../button';
import {useNavigate, useLocation} from 'react-router-dom';
import IconCustom from '../../components/Icon';
import {useLocalStorage} from '@rehooks/local-storage';
import {IS_COOKIE_ACCEPTED} from '../../pages/Auth';
import {INSTALL_PROMPT} from '../../App';
import {IOS_PROMPT} from '../../App';
import {deviceDetected, isStandalone} from '../../utils/deviceCheck';
import NavBarContent from './NavBarContent';
import {SCROLL_POSITION} from '../../hooks/useScrollPosition';
import useAuth from '../../hooks/useAuth';
import ChatbotLauncher from '../ChatbotLauncher';
import useSuperFilters from '../../hooks/useSuperFilters';
import RoseLogo from '../../assets/logos/logo_rose2.png';
import useTrackEvent from '../../hooks/useTrackEvent';
import {useNewEventChatMessages} from '../../hooks/useEvents';
import {sumBy} from 'lodash';
import {motion} from 'framer-motion';
import DockbarButtonTooltip from '../DockbarButtonTooltip';

export const BUTTON_TOOLTIP_SHOWN = 'button-tooltip-shown';

const NavigationBar = ({placeName}: {placeName?: string}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isCookieAccepted, _] = useLocalStorage<string>(IS_COOKIE_ACCEPTED);
  const [installPromptVisits, setInstallPromptVisits] = useLocalStorage<string>(INSTALL_PROMPT);
  const [iOSInstallPrompt] = useLocalStorage<string>(IOS_PROMPT);
  const bottomModalSheetRef = useRef<BottomModalSheetRefProps>();
  const installBannerRef = useRef<BottomModalSheetRefProps>();
  const {user} = useAuth();
  const [isChatLauncher, setIsChatLauncher] = useState<boolean>(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const {superFiltersActive} = useSuperFilters();
  const {addUserEvent} = useTrackEvent();
  const {data} = useNewEventChatMessages();
  const numUnread = sumBy(data?.data, 'new_messages_count') || 0;
  const [buttonTooltipShown, setButtonTooltipShown] = useLocalStorage<string>(BUTTON_TOOLTIP_SHOWN);
  const [buttonTooltipOpen, setButtonTooltipOpen] = useState(false);

  useEffect(() => {
    if ((buttonTooltipOpen && location.pathname != '/home') || location.search.length != 0) {
      setButtonTooltipOpen(false);
    }
  }, [location]);

	useEffect(() => {
		if (user && user.createdAt) {
      const currentDate = new Date();
      const createdAt = new Date(user.createdAt);

      const differenceInMs = currentDate.getTime() - createdAt.getTime();
      const differenceInDays = differenceInMs / (1000 * 3600 * 24);

      if (differenceInDays < 1 && !buttonTooltipShown) {
        setButtonTooltipOpen(true);
      }
    }
	}, [user]);

  function goToHome() {
    sessionStorage.removeItem(`${SCROLL_POSITION}_home`);
    // if (installPromptVisits !== null) {
    //   let promptObj =
    //     typeof installPromptVisits == 'object'
    //       ? installPromptVisits
    //       : JSON.parse(installPromptVisits);
    //   promptObj = {...promptObj, ...{clicks_count: promptObj['clicks_count'] + 1}};
    //   setInstallPromptVisits(JSON.stringify(promptObj));
    //   if (isCookieAccepted && promptObj['clicks_count'] % promptObj['prompt_mod'] == 0) {
    //     promptObj = {
    //       ...promptObj,
    //       ...{clicks_count: 0},
    //       ...{prompt_mod: promptObj['prompt_mod'] * 2},
    //     };
    //     setInstallPromptVisits(JSON.stringify(promptObj));
    //     installBannerRef?.current && installBannerRef.current?.setState(true);
    //     return;
    //   }
    // }
    //location.pathname == '/home' ? navigate(0) : navigate('/home');
    navigate('/home');
  }

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      fontSize: '1.3rem',
      height: '28px',
      marginBottom: '1px',
      paddingTop: '3px',
    }),
    [theme]
  );

  const activeItem: React.ReactNode = React.useMemo(() => {
    return (
      <Box
        backgroundColor={theme.colors.rose}
        width={'34px'}
        height={'6px'}
        borderRadius={'7px'}
        position={'absolute'}
        top={'-3px'}
      />
    );
  }, [theme, isChatLauncher]);

  const device = deviceDetected();

  return (
    <Flex
      pb={device === 'iphone' || device === 'iphone-pwa' ? '14px' : 0}
      id="dockbar-tooltip-highlight"
      w="100%"
      h={device === 'iphone' || device === 'iphone-pwa' ? '70px' : '56px'}
      justifyContent="center"
      alignItems="center"
      position="fixed"
      bottom={0}
      left={0}
      //backgroundColor={theme.colors.veryLightBlue}
      backgroundSize="cover"
      backgroundImage={DockbarBackground}
      borderRadius={'18px 18px 0 0'}
      style={{zIndex: '1400'}}>
     
        <DockbarButtonTooltip isOpen={buttonTooltipOpen} />

      <Flex
        id="home-dockbar"
        w="20%"
        h="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}>
        {location.pathname == '/home' && !isChatLauncher && activeItem}
        <Flex
          alignItems="center"
          flexDirection={'column'}
          onClick={() => {
            if (buttonTooltipOpen) return;
            goToHome();
          }}>
          <IconCustom iconName={'fi-rr-home'} style={iconStyle} />
          <TextG8 fontWeight={location.pathname == '/home' && !isChatLauncher ? '700' : '400'}>
            Home
          </TextG8>
        </Flex>
      </Flex>
      <Flex
        id="help-tooltip"
        w="20%"
        h="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}>
        <ChatbotLauncher
          disabled={buttonTooltipOpen}
          customElement={activeItem}
          setChatItem={setIsChatLauncher}
        />
      </Flex>
      <Flex w="20%" h="100%" justifyContent="center" alignItems="center" id="dockbar-tooltip">
        <BottomModalSheet
          ref={bottomModalSheetRef}
          onOpenEnd={() => {
            setButtonTooltipShown('true');
            setButtonTooltipOpen(false);
            addUserEvent({type: 'click', event_name: 'dock_bar_click'});
          }}
          disableDrag={isDraggable}
          dragHandler={setIsDraggable}
          triggerElement={
            <Center
              borderRadius="full"
              position="absolute"
              top="-16px"
              mb="4px"
              //backgroundColor={theme.colors.veryLightBlue}
              w="50px"
              h="50px">
              {/* {superFiltersActive ? (
                <Image src={RoseLogo} zIndex="10" maxW="50" maxH="50" />
              ) : (
                <Image src={Logo} zIndex="10" maxW="50" maxH="50" />
              )} */}
              {buttonTooltipOpen ? (
                <motion.div
                  transition={{
                    y: {
                      duration: 0.8,
                      repeat: Infinity,
                      repeatType: 'reverse',
                      ease: 'easeOut',
                    },
                  }}
                  animate={{y: [10, -10]}}>
                  <Image
                    borderRadius="full"
                    boxShadow="0px 4.636363506317139px 4.636363506317139px 0px #00000040;"
                    src={RoseLogo}
                    zIndex="10"
                    minW="50px"
                    minH="50px"
                  />
                </motion.div>
              ) : (
                <Image
                  borderRadius="full"
                  boxShadow="0px 4.636363506317139px 4.636363506317139px 0px #00000040;"
                  src={RoseLogo}
                  zIndex="10"
                  minW="50px"
                  minH="50px"
                />
              )}
            </Center>
          }>
          <NavBarContent bottomModalSheetRef={bottomModalSheetRef} dragHandler={setIsDraggable} />
        </BottomModalSheet>
      </Flex>
      <Flex
        id="chat-tooltip"
        w="20%"
        h="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}>
        {location.pathname == '/chats' && !isChatLauncher && activeItem}
        <Flex
          pos="relative"
          alignItems="center"
          flexDirection={'column'}
          onClick={() => {
            if (buttonTooltipOpen) return;
            navigate('/chats');
          }}>
          {numUnread > 0 && (
            <Box
              textAlign="center"
              position="absolute"
              right="-3px"
              top="0px"
              borderRadius="full"
              backgroundColor={theme.colors.rose}
              w="12px"
              h="12px">
              {/* <TextG10 align="center" color="white">
                  {numUnread < 10 ? numUnread : '9+'}
                </TextG10> */}
            </Box>
          )}
          <IconCustom iconName={'fi-rr-comment'} style={iconStyle} />
          <TextG8 fontWeight={location.pathname == '/chats' && !isChatLauncher ? '700' : '400'}>
            Chats
          </TextG8>
        </Flex>
      </Flex>

      <Flex
        id="stuff-tooltip"
        w="20%"
        h="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}>
        {location.pathname == '/my-stuff' && !isChatLauncher && activeItem}
        <Flex
          alignItems="center"
          flexDirection={'column'}
          onClick={() => {
            if (buttonTooltipOpen) return;
            navigate('/my-stuff');
          }}>
          <IconCustom iconName={'fi-rr-vault'} style={iconStyle} />
          <TextG8 fontWeight={location.pathname == '/my-stuff' && !isChatLauncher ? '700' : '400'}>
            My Stuff
          </TextG8>
        </Flex>
      </Flex>

      {!isStandalone() && (
        <BottomModalSheet
          ref={installBannerRef}
          customHeader={
            <TextG14 alignItems={'start'} fontWeight={700} color={theme.colors.lightBlack} py={1}>
              Get quick access to Walkabout!
            </TextG14>
          }
          style={{padding: '24px'}}>
          <VStack spacing={0} alignItems="flex-start">
            {!iOSInstallPrompt ? (
              <>
                <TextG12 py={1} pb={4} color={theme.colors.darkerGrey}>
                  Add an app icon on your home screen.
                </TextG12>
                <HStack w={'100%'} justifyContent="space-between">
                  <WhiteGreyButton
                    w={'100%'}
                    borderRadius="22px"
                    onClick={() => {
                      installBannerRef?.current && installBannerRef.current?.setState(false);
                      location.pathname == '/home' ? navigate(0) : navigate('/home');
                    }}>
                    Later
                  </WhiteGreyButton>
                  <BlueButton
                    w={'100%'}
                    onClick={async () => {
                      installBannerRef?.current && installBannerRef.current?.setState(false);

                      window.deferredPrompt?.prompt(); // show every 10th time

                      // Find out whether the user confirmed the installation or not
                      const {outcome} = await window.deferredPrompt?.userChoice;
                      // Act on the user's choice
                      if (outcome === 'dismissed') {
                        location.pathname == '/home' ? navigate(0) : navigate('/home');
                      }
                    }}>
                    Add Now
                  </BlueButton>
                </HStack>
              </>
            ) : (
              <>
                <HStack py={3}>
                  <Image src={IOSShare} width="25px" alt="Share" />
                  <TextG12
                    color={
                      theme.colors.darkerGrey
                    }>{`1) Press the 'Share' button on the menu bar below`}</TextG12>
                </HStack>
                <HStack pb={3}>
                  <Image src={IOSAdd} width="25px" alt="Add" />
                  <TextG12
                    color={theme.colors.darkerGrey}>{`2) Press 'Add to Home Screen'`}</TextG12>
                </HStack>
                <HStack w={'100%'} justifyContent="flex-end">
                  <WhiteGreyButton
                    w={'50%'}
                    borderRadius="22px"
                    onClick={() => {
                      installBannerRef?.current && installBannerRef.current?.setState(false);
                      location.pathname == '/home' ? navigate(0) : navigate('/home');
                    }}>
                    Later
                  </WhiteGreyButton>
                </HStack>
              </>
            )}
          </VStack>
        </BottomModalSheet>
      )}
    </Flex>
  );
};
export default NavigationBar;
