import React, {useEffect, useRef, useState} from 'react';
import {Flex, useTheme} from '@chakra-ui/react';
import {TopicOfInterest} from '../../../types/article';
import {map, includes, orderBy, filter} from 'lodash';
import useAuth from '../../../hooks/useAuth';
import {TextG10} from '../../typography';
import styled, {keyframes} from 'styled-components';

// Define keyframes for infinite scrolling
const scrollAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`;

const ScrollerWrapper = styled(Flex)`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
`;

const ScrollerContent = styled(Flex)<{$speed: string}>`
  display: flex;
  min-width: max-content;
  animation: ${scrollAnimation} ${(props) => props.$speed} linear infinite;
`;

const CardInterestTags = ({
  topicsOfInterest,
  onlyShowMatched,
  autoScroll,
  width = '90%',
  tagsVisible,
  color,
}: {
  topicsOfInterest: TopicOfInterest[];
  onlyShowMatched?: boolean;
  autoScroll?: boolean;
  width?: string | number;
  tagsVisible?: boolean;
  color?: string;
}) => {
  const {user} = useAuth();
  const theme = useTheme();
  const [speed, setSpeed] = useState('20s');
  const [shouldScroll, setShouldScroll] = useState(autoScroll);

  // Process topics
  let topics = orderBy(
    map(
      filter(topicsOfInterest, (topic) => !topic.name.startsWith('Context:')),
      (topic) => ({
        ...topic,
        matched: includes(
          map(user?.topics, (t) => t.name),
          topic.name
        ),
      })
    ),
    ['matched', 'name'],
    ['desc', 'asc']
  );

  if (onlyShowMatched) {
    topics = filter(topics, 'matched');
  }

  useEffect(() => {
    setShouldScroll(autoScroll && topics.length > 2);
    setSpeed(`${topics.length * 3}s`);
  }, [topics, autoScroll]);

  if (!tagsVisible) {
    return null;
  }

  return (
    <ScrollerWrapper maxW={width} w={width}>
      <ScrollerContent $speed={shouldScroll ? speed : 0} color={color || theme.colors.grey}>
        {map([...topics, ...(shouldScroll ? topics : [])], (topic, index) => (
          <TextG10
            mr={2}
            key={`${topic.id}_${index}`}
            fontWeight={topic.matched ? 'bold' : 'normal'}>
            #{topic.name}
          </TextG10>
        ))}
      </ScrollerContent>
    </ScrollerWrapper>
  );
};

export default CardInterestTags;
