import React from 'react';
import {color, Flex, useTheme} from '@chakra-ui/react';
import {TextG10, TextG12} from '../../../components/typography';
import useAuth from '../../../hooks/useAuth';
import {includes, map} from 'lodash';
interface InterestTagProps {
  item: any;
  icon: string | undefined;
  name?: string;
}

const InterestTag = ({item, icon, name}: InterestTagProps) => {
  const theme = useTheme();
  const {user} = useAuth();

  const highlight = includes(
    map(user?.topics, (topic) => topic.name),
    item.name
  );

  return (
    <Flex
      gap={1}
      key={item.id}
      rounded="full"
      border={highlight ? '2px solid transparent' : `2px solid ${theme.colors.grey}`}
      fontWeight={highlight ? '700' : '400'}
      color={highlight ? 'black' : theme.colors.grey}
      opacity={highlight ? '1' : '0.5'}
      background={
        highlight
          ? 'linear-gradient(white, white) padding-box, linear-gradient(180deg, #276ACD 0%, #E02F84 100%) border-box;' //'linear-gradient(white, white) padding-box, linear-gradient(to right, darkblue, darkorchid) border-box;'
          : ''
      }
      p=".25rem 0.5rem"
      width={name ? 'fit-content' : '40px'}
      minH="25px"
      maxH={name ? 'auto' : '25px'}
      align="center"
      justifyContent="center">
      {/* {icon && <i style={{height: '18px'}} className={`${icon}`}></i>} */}
      {name && <TextG10>{`#${name} `}</TextG10>}
    </Flex>
  );
};

export default InterestTag;
