import {useCallback, useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {fetchPointsDistribution} from '../endpoints/api';
import {find} from 'lodash';

export enum PointsDistributionTypes {
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
  PLACE_FIRST_CHECKIN = 'PLACE_FIRST_CHECKIN',
  PLACE_SECOND_PLUS_CHECKIN = 'PLACE_SECOND_PLUS_CHECKIN',
  UPVOTE_APPROVED = 'UPVOTE_APPROVED',
}

export const useGetPointsDistribution = () => {
  return useQuery(['pointsDistribution'], fetchPointsDistribution, {staleTime: 60000});
};

export const usePointsDistribution = () => {
  const [pointsDistribution, setPointsDistribution] = useState<Array<any>>([]);
  const [pointsDistributionLoading, setPointsDistributionLoading] = useState(true);
  const getPointsDistribution = useGetPointsDistribution();

  useEffect(() => {
    if (getPointsDistribution.data?.data) {
      setPointsDistribution(getPointsDistribution.data.data);
      setPointsDistributionLoading(false);
    }
  }, [getPointsDistribution.data?.data]);

  const getPointAmount = useCallback(
    (type: PointsDistributionTypes) => {
      return find(pointsDistribution, {rule: type})?.points;
    },
    [pointsDistribution]
  );

  return {pointsDistribution, getPointAmount, pointsDistributionLoading};
};
