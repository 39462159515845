import React from "react";
import Modal from "../../components/Modals/Modal";
import { CloseButton, HStack, useTheme, VStack } from "@chakra-ui/react";
import { TextG14 } from "../../components/typography";
import { CustomButton } from "../../components/button";
import Logo from "../../assets/logos/logo_72x72.png";
import { isIphone } from "../../utils/deviceCheck";
import { APPLE_STORE_LINK, GOOGLE_STORE_LINK } from "../../constants/appStores";

 const InstallAppPrompt = ({
    setShowInstallPrompt,
    showInstallPrompt,
}) => {
    const theme = useTheme();

    const handleDeepLinkNavigation = () => {
        const currentUrl = window.location.href;
        const customUrl = currentUrl.replace(/^https?:\/\//, "walkabout://");
        window.location.href = customUrl;
    };

    const handleStoreNavigation = () => {
        window.location.href = isIphone ? APPLE_STORE_LINK : GOOGLE_STORE_LINK;
    };
    return (
        <Modal
            isCentered={true}
            closeOnOverlayClick={false}
            show={showInstallPrompt}
            closeButton={false}
            contentStyle={{
                width: "90%",
            }}
        >
            <VStack pb={4} spacing={4} textAlign={"center"}>
                <CloseButton
                    size="lg"
                    onClick={() => {
                        setShowInstallPrompt(false);
                    }}
                    style={{
                        position: "absolute",
                        top: 10,
                        left: 10,
                    }}
                />
                <img src={Logo} alt="Walkabout Logo" />
                <p
                    style={{
                        padding: 0,
                        marginBottom: -8,
                        fontSize: "24px",
                        textAlign: "center",
                        fontWeight: 800,
                        lineHeight: 1.1,
                        textTransform: "uppercase",
                    }}
                >
                    Get the best of Walkabout!
                </p>
                <TextG14>
                    Uncover hidden gems and catch events happening right now in
                    your neighborhood. Stay in the loop and never miss out on
                    the fun!
                </TextG14>
                <VStack width="full" justifyContent="space-between">
                    <CustomButton
                        background={theme.colors.blue}
                        color="white"
                        width="full"
                        onClick={handleDeepLinkNavigation}
                    >
                        Open Walkabout
                    </CustomButton>
                    <CustomButton
                        onClick={handleStoreNavigation}
                        background={theme.colors.white}
                        color={theme.colors.grey}
                        width="full"
                    >
                        Get the app
                    </CustomButton>
                </VStack>
            </VStack>
        </Modal>
    );
};

export default InstallAppPrompt;
