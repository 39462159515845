import React from 'react';
import {Box, Center, Image, Spinner, Text, useTheme} from "@chakra-ui/react";
import styled from "styled-components";
import {TextG12} from "../typography";
import Loader from '../../assets/images/loader.gif';

const StyledBox = styled(Box)`
  position: absolute !important;
  display: flex;
  height: 100vh;
  left: 0;
`;

const StyledText = styled(TextG12)`
    color: white;
    text-align: center;
    position: absolute;
    top: 15%;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
`;


export const PageLoader = () => {
  const theme = useTheme();

  return (
    <StyledBox background={theme.colors.lightBlue}>
      <Image zIndex={1} m="auto" src={Loader} alt="Processing" />
      <StyledText zIndex={2} color={theme.colors.darkerWhite}>Personalizing…</StyledText>
    </StyledBox>
  )
}