import React from "react"
import { Box, Image } from "@chakra-ui/react"
import { socialNetworkIconMapper } from "../../../types/article";

type ArticleSocialIconProps = {
  social: keyof typeof socialNetworkIconMapper;
  width?: string;
  height?: string
}

const ArticleSocialIcon = ({ social, width, height }: ArticleSocialIconProps) => {

    return (
      <Box bg={'white'} borderRadius="50%">
        <Image
          w={width}
          h={height}
          src={socialNetworkIconMapper[social] || socialNetworkIconMapper['rss']}
          alt={social}
        />
      </Box>
    );
}

ArticleSocialIcon.defaultProps = {
  width: '26px',
  height: '26px',
}

export default ArticleSocialIcon