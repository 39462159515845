import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {Box, Center, Flex, Image, Text, useTheme} from '@chakra-ui/react';
import {TextG12, TextG14} from '../typography';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import NotificationsLauncher from '../NotificationsLauncher';
import Logo from '../../assets/logos/Word_logo3.png';
import UserPointsProgress from '../../components/UserPointProgress';
import GlobalSearch from '../GlobalSearch';
import useSuperFilters from '../../hooks/useSuperFilters';
import WhiteLogo from '../../assets/logos/Word_logo-white.png';
import useCurScrollPosition from '../../hooks/useCurScrollPosition';
import UserAvatar from '../UserAvatar';
import CommunitiesSelect, {ALL_COMMUNITY} from '../CommunitiesSelect';
import {useDispatch, useSelector} from 'react-redux';
import {filter, find} from 'lodash';
import {
  CommunityActions,
  saveCommunityToLocalStorage,
} from '../../state/Communities/CommunitiesSlice';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import BackButton from '../BackButton';
import Icon from '../Icon';
import {CONTENT_TYPE} from '../../utils/GlobalSearch/enums';
import {getMembershipGradient} from '../../utils/membership';

const StyledText = styled(Text)`
  font-family: 'Aileron';
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #e02f84;
`;

const StyledBox = styled(Box)`
  height: 128px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  background-color: white;
  z-index: 100;
`;

type TopBarProps = {
  hidePoints?: boolean;
  onClose?: () => void;
};

function TopBar({hidePoints}: TopBarProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const {user} = useAuth();
  const {pathname} = useLocation();
  const {superFiltersActive} = useSuperFilters();
  const [searchParams] = useSearchParams();
  const isProduction = process.env.NODE_ENV === 'production';
  const allowedDevUsers = user?.id !== 188 && user?.id !== 192;
  const selectedCommunityColor = useSelector((state: any) => state.community.community).color;

  const selectedCommunity = useSelector((state: any) => state.community.community);
  const dispatch = useDispatch();

  const {namedFilter, resetTypeSpecificFilters} = useGlobalSearch();

  const membershipColor = getMembershipGradient(user?.activeMembershipPlan || null, true);

  const userCommunities = filter(
    user?.communities,
    (community) => community.is_visible_in_selector
  );

  useEffect(() => {
    const isInCommunity = find(userCommunities, {id: selectedCommunity.id});
    if (user && !isInCommunity) {
      dispatch(CommunityActions.selectCommunity({community: ALL_COMMUNITY}));
      saveCommunityToLocalStorage(ALL_COMMUNITY);
    }
  }, [selectedCommunity, user]);

  const scrollPosition = useCurScrollPosition();

  const namedFilterContent = (
    <Flex alignItems="center" bgColor={theme.colors.orange} p={3} mt={-4} mx={-3}>
      <BackButton
        type={'transparent'}
        pos={'relative'}
        onClick={() => {
          resetTypeSpecificFilters(CONTENT_TYPE.PLACE);
          if (searchParams.has('back')) {
            navigate(-1);
            return;
          } else if (searchParams.has('passType')) {
            navigate('/my-stuff?tab=Passes');
          } else if (pathname.indexOf('search')) {
            navigate('/home?openQuickLinks=true');
          } else {
            navigate(-1);
          }
        }}
      />
      <Box flex="1">
        <Center ml="-35px">
          {namedFilter?.icon && namedFilter?.color && (
            <Icon
              iconName={namedFilter.icon}
              style={{color: namedFilter.color, fontSize: '18px', paddingRight: '8px'}}
            />
          )}
          {namedFilter?.title && <TextG14 fontWeight={'bold'}>{namedFilter.title}</TextG14>}
        </Center>
      </Box>
    </Flex>
  );

  const topBarContent = useMemo(() => {
    return (
      <>
        <Box
          zIndex={-1}
          pos="absolute"
          top="0"
          w="100vw"
          height="235px"
          background={getMembershipGradient(user?.activeMembershipPlan || null, false)}
        />
        <Box
          id="topbar-tooltip-highlight"
          background={membershipColor}
          style={{
            width: '100vw',
            position: 'fixed',
            backgroundSize: 'cover',
            top: 0,
            right: 0,
            height: '125px',
            zIndex: '100',
          }}>
          <Flex px="12px" pt={hidePoints ? '0' : '16px'} flexDirection="column">
            {namedFilter
              ? namedFilterContent
              : !hidePoints && (
                  <Flex w={'100%'} justifyContent="space-between" alignItems="center">
                    <UserPointsProgress user={user} />
                    {/* <MembershipPill /> */}
                    {userCommunities && userCommunities.length > 0 ? (
                      <CommunitiesSelect />
                    ) : (
                      <Image
                        onClick={() => navigate('/home')}
                        boxSize="120px"
                        height="auto"
                        src={superFiltersActive ? WhiteLogo : Logo}
                        alt={'Logo'}
                      />
                    )}

                    {/* {!isProduction && allowedDevUsers && (
                      <TextG12 alignSelf={'start'} color={theme.colors.rose}>
                        dev
                      </TextG12>
                    )} */}
                    <Flex gap={2} flex="1" justifyContent="end">
                      <NotificationsLauncher />
                      <UserAvatar
                        id="profile-tooltip"
                        user={user}
                        size="sm"
                        onClick={() => navigate('/profile')}
                      />
                    </Flex>
                  </Flex>
                )}
            <GlobalSearch />
          </Flex>
        </Box>
      </>
    );
  }, [pathname, superFiltersActive, scrollPosition, selectedCommunityColor, namedFilter]);

  return topBarContent;
}
export default TopBar;
