import {Flex, Icon, useTheme, VStack, Image, Box} from '@chakra-ui/react';
import HTMLReactParser from 'html-react-parser';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {getImageURL} from '../../constants/imageStorage';
import {useGeneralSettings} from '../../hooks/useGeneralSettings';
import useGetUserLocation from '../../hooks/useUserLocation';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';
import {storeScrollPositionInSession} from '../../utils/scroll';
import {WhiteButton} from '../button';
import Modal from '../Modals/Modal';
import {TextA18, TextG12, H2, TextG10} from '../typography';

const DiscountModal = ({discount, showModal, setShowModal}) => {
  const theme = useTheme();
  const userLocation = useGetUserLocation();
  const place = discount.place;
  const {getSetting, isLoading} = useGeneralSettings();
  const navigate = useNavigate();

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
      position: 'absolute',
      top: '10px',
      right: '10px',
    }),
    [theme]
  );

  const distance = useMemo(() => {
    if (place) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(place?.latitude || discount.latitude),
          longitude: Number(place?.longitude || discount.longitude),
        }
      );
    }
    return 0;
  }, [userLocation, discount.place]);

  return (
    <Modal
      parentCallback={() => setShowModal(false)}
      closeButton={true}
      closeButtonPosition="left"
      contentStyle={{maxHeight: screen.height * 0.95, overflow: 'scroll'}}
      header={
        <VStack mt={4}>
          {place && place.name ? (
            <>
              <TextA18 align="center" fontWeight="700">
                {place.name}
              </TextA18>
              {!isNaN(distance) && (
                <TextG12 fontWeight="500" color={theme.colors.grey}>
                  <i
                    style={{marginRight: '5px', color: theme.colors.blue}}
                    className="fi fi-rr-marker"></i>
                  {getSetting('checkin_distance') && distance < getSetting('checkin_distance')
                    ? `You're Here!`
                    : `${getDistanceString(distance)} away`}
                </TextG12>
              )}
            </>
          ) : null}
        </VStack>
      }
      show={showModal}
      children={
        <VStack mt={-2} mb={2} gap={1}>
          {discount.title && (
            <H2
              mt="0px !important"
              color={theme.colors.rose}
              mb={3}
              align="center"
              maxW="240px"
              fontWeight="700">
              {HTMLReactParser(discount?.title)}
            </H2>
          )}
          <Box pos="relative">
            <Image
              fallbackStrategy="onError"
              mt={'0 !important'}
              borderRadius="14px"
              width="203px"
              height="140px"
              objectFit="cover"
              src={discount?.image_url ? getImageURL(discount.image_url) : ''}
              onClick={() => {
                storeScrollPositionInSession();
                place ? navigate(`/place/${discount.place?.id || place.id}`) : null;
                setShowModal(false);
              }}
            />
            <Box
              boxShadow="0px 4px 10px 0px #00000026"
              p={1}
              background="#E02F84"
              borderRadius="5px"
              pos="absolute"
              bottom="-9px"
              right="33.3%">
              <TextG10 color="white" fontWeight="700">
                IN STORE
              </TextG10>
            </Box>
          </Box>
          <TextG12 px={6} textAlign="center" mt="20px !important" color={theme.colors.lightBlack}>
            {HTMLReactParser(discount?.summary)}
          </TextG12>
          <WhiteButton
            mt="20px !important"
            w="226px"
            h="35px"
            border="1px solid #276ACD"
            onClick={() => setShowModal(false)}>
            Close
          </WhiteButton>
        </VStack>
      }
    />
  );
};

export default DiscountModal;
