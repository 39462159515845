import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import Sheet, {SheetRef} from 'react-modal-sheet';
import {Box, Center} from '@chakra-ui/react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {ToastMessage} from '../../../components/toast';
import styled from 'styled-components';

type BottomModalSheetProps = {
  children: JSX.Element;
  triggerElement?: JSX.Element;
  customHeader?: JSX.Element;
  openModal?: boolean;
  onModalClose?: () => void;
  closeOnOverlayClick?: boolean;
  style?: React.CSSProperties;
  disableDrag?: boolean;
  onOpenEnd?: () => void;
  triggerDisabled?: boolean;
  disabledMessage?: string;
  dragHandler?: React.Dispatch<React.SetStateAction<boolean>>;
  disableScrollLocking?: boolean;
};

export type BottomModalSheetRefProps = {
  getState: () => boolean;
  setState: (boolean) => void;
};

// visibility toggling fixes scrolling issues on Android
const StyledSheet = styled(Sheet)`
  .react-modal-sheet-container {
    height: unset !important;
  }
  visibility: ${(props) => (props.isOpen ? 'visible !important' : 'hidden !important')};
`;

const BottomModalSheet = React.forwardRef(
  (
    {
      children,
      triggerElement,
      customHeader,
      openModal,
      onModalClose,
      closeOnOverlayClick = true,
      style,
      disableDrag = false,
      onOpenEnd,
      triggerDisabled,
      disabledMessage,
      dragHandler,
      disableScrollLocking = false,
    }: BottomModalSheetProps,
    ref
  ) => {
    const [isOpen, setOpen] = useState(false);
    const {height, width} = useWindowDimensions();
    const sheetRef = useRef<SheetRef>();

    const containerRef = useRef<HTMLFormElement | null>(null);

    useImperativeHandle(
      ref,
      () => ({getState: () => isOpen, setState: (state) => setOpen(state)}),
      [isOpen]
    );

    const handleClose = (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      onModalClose && onModalClose();
      dragHandler && dragHandler(false);
      setTimeout(() => setOpen(false), 1);
    };

    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target) && isOpen) {
        event.preventDefault();
        event.stopPropagation();
        if (closeOnOverlayClick) {
          handleClose(event);
        }
      }
    };

    const snapTo = (i: number) => sheetRef.current?.snapTo(i);

    useEffect(() => {
      // if (!closeOnOverlayClick) return;
      // document.addEventListener('click', handleClickOutside, true);
      ['click', 'mousedown'].forEach((e) => document.addEventListener(e, handleClickOutside, true));
      return () => {
        // document.removeEventListener('click', handleClickOutside, true);
        ['click', 'mousedown'].forEach((e) =>
          document.removeEventListener(e, handleClickOutside, true)
        );
      };
    });

    useEffect(() => {
      openModal !== undefined && setOpen(openModal);
    }, [openModal]);

    useEffect(() => {
      if (isOpen && !disableScrollLocking) document.body.style.overflow = 'hidden';
      if (!disableScrollLocking) {
        return () => {
          document.body.style.overflow = 'unset';
        };
      } else {
        return () => {};
      }
    }, [isOpen]);

    return (
      <>
        {triggerElement &&
          React.cloneElement(triggerElement, {
            onClick: () => {
              if (triggerDisabled) {
                if (disabledMessage) {
                  ToastMessage({
                    status: 'error',
                    text: disabledMessage,
                  });
                }
              } else {
                setOpen(true);
              }
            },
          })}
        <StyledSheet
          onOpenEnd={onOpenEnd}
          disableDrag={disableDrag}
          ref={sheetRef}
          isOpen={isOpen}
          disableScrollLocking={disableScrollLocking}
          onClose={(e) => {
            handleClose(e);
          }}
          snapPoints={[0]}
          initialSnap={0}>
          <Sheet.Container
            style={{
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px',
              overflow: 'hidden',
              height: 'auto',
              ...style,
            }}
            ref={containerRef}>
            <Sheet.Header>
              {customHeader || (
                <>
                  <Box height={'6px'} bg={'linear-gradient(to right, #276ACD, #47DDFE)'} />
                  <Center py={2}>
                    <i onClick={(e) => handleClose(e)} className="fi fi-rr-angle-down"></i>
                  </Center>
                </>
              )}
            </Sheet.Header>
            <Sheet.Content>
              <Sheet.Scroller>{children}</Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </StyledSheet>
      </>
    );
  }
);

export default BottomModalSheet;
