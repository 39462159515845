import React, {useState} from "react";
import {Box, Center, Container, Link, VStack} from "@chakra-ui/react";
import {getPasswordResetCode} from "../../../../endpoints/api";
import BackButton from "../../../../components/BackButton";
import {H2, TextG12, TextG14} from "../../../../components/typography";
import Input from "../../../../components/Input";
import {BlueButtonFixedWidth} from "../../../../components/button";
import ExternalLink from "../../../../components/ExternalLink";

const SendCode = ({ previousStep, nextStep, setAdditionalData }) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleVerificationCodeRequest = () => {
    getPasswordResetCode(email).then(() => {
      setAdditionalData({ email });
      nextStep();
    }).catch((err) => {
      setError(err.message);
    });
  }

  return (
    <Container px={5} py={8}>
      <Box>
        <BackButton pos={'relative'} onClick={() => previousStep()} />
      </Box>
      <VStack pt={5} alignItems={'start'} spacing={2}>
        <H2 fontWeight={700} py={3}>
          Reset Password
        </H2>
        <TextG14 fontWeight={500} lineHeight={'176.5%'}>
          Enter the email address associated with your account and we’ll send instructions to reset
          your password.
        </TextG14>
        <Box w={'100%'} py={5}>
          <Input
            size="sm"
            id="email"
            isRequired
            label="Email Address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e?.target?.value)}
          />
        </Box>
        {error && (
          <Center>
            <TextG12 color={'red'}>
              {error} Try again or{' '}
              <ExternalLink
                isExternal
                href={'https://ctbs.tech/contact-us/'}
                textDecoration={'underline'}>
                contact us
              </ExternalLink>{' '}
              for help.
            </TextG12>
          </Center>
        )}
        <Box py={3} w={'100%'}>
          <BlueButtonFixedWidth isDisabled={!email} onClick={handleVerificationCodeRequest}>
            Continue
          </BlueButtonFixedWidth>
        </Box>
      </VStack>
    </Container>
  );
}

export default SendCode;