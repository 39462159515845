import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Box, Center, Divider } from '@chakra-ui/react';
import {TextG10} from '../../components/typography';
import {BlueButtonFixedWidth, WhiteButton, WhiteButtonFixedWidth} from '../../components/button';
import {fetchNumOfRegisteredUsers} from '../../endpoints/api';
import LandingWrapper from '../../components/LandingWrapper';
import {BottomSheetNotificationActions} from '../../state/BottomSheetNotification/BottomSheetNotificationSlice';
import {useLocalStorage} from '@rehooks/local-storage';

export const IS_COOKIE_ACCEPTED = 'is_cookie_accepted';

function Auth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [numOfRegisteredUsers, setNumOfRegisteredUsers] = useState(0);
  const [isCookieAccepted, _] = useLocalStorage<string>(IS_COOKIE_ACCEPTED);

  useEffect(() => {
    fetchNumOfRegisteredUsers().then(({data: {count}}) => setNumOfRegisteredUsers(count));

    // if (!isCookieAccepted) {
    //   dispatch(BottomSheetNotificationActions.pushNotification({title: 'cookie'}));
    // }
  }, []);

  return (
    <LandingWrapper
      title={'Get Out There!'}
      subtitle={'Experience more and love where you live.'}
      numOfRegistered={numOfRegisteredUsers}>
      <Box mt={8} w={'100%'}>
        <Center>
          <BlueButtonFixedWidth onClick={() => navigate('/login')}>Login</BlueButtonFixedWidth>
        </Center>
        <Center px={6} py={5}>
          <Divider />
          <TextG10 color="white" w="100%" textAlign="center">
            New member?
          </TextG10>
          <Divider />
        </Center>
        <Center flexDir="column" gap={5}>
          <WhiteButtonFixedWidth px={4} onClick={() => navigate('/soft-check')}>
            Sign up now
          </WhiteButtonFixedWidth>
        </Center>
      </Box>
    </LandingWrapper>
  );
}

export default Auth;
