import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';

export interface Location {
  latitude: number;
  longitude: number;
}

export default function useGetUserLocation(enabled = true) {
  const [error, setError] = useState<GeolocationPositionError>();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(['userLocation']);
  const [userLocation, setUserLocation] = useState<Location | null>(cachedData as Location);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (enabled) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLoading(false);
        },
        (err) => {
          setError(err);
          setLoading(false);
        },
        {enableHighAccuracy: true}
      );

      return () => {
        if (watchId) return navigator.geolocation.clearWatch(watchId);
      };
    }
  }, [enabled]);

  return useQuery<Location | null, Error>(
    ['userLocation'],
    () => {
      return new Promise<Location | null>(async (resolve, reject) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(userLocation || null);
      });
    },
    {retry: false, staleTime: 0, refetchInterval: 500, enabled: !loading && enabled}
  );
}
