import { Container, Divider } from '@chakra-ui/react';
import React from 'react';
import { browserDetected, deviceDetected } from '../../utils/deviceCheck';
import Offline from '../../pages/Offline';
import { TextG14 } from "../../components/typography";
import Icon from "../Icon";

function Page({ children }) {
  const browser = browserDetected();
  const device = deviceDetected();

  // if (browser !== 'safari'
  //   && (device === 'iphone' || device === 'iphone-pwa')
  // )
  //   return (
  //     <Offline text="Use Safari to access my.walkabout.app">
  //       <>
  //         <Icon style={{ fontSize: "32px" }} iconName="fi fi-rr-exclamation" />
  //         <Divider marginBottom={3} />
  //         <TextG14 fontWeight={"900"}>Sorry, this browser isn't supported</TextG14>
  //         <Divider marginBottom={3} />
  //       </>
  //     </Offline>
  //   )

  return (
    <Container px={0}>
      {children}
    </Container>
  );
}

export default Page;
