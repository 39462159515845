import {FilterConf} from "../../../../utils/GlobalSearch/types";
import {CONTENT_TYPE, FILTER_TYPES, TAG_TYPE} from "../../../../utils/GlobalSearch/enums";
import {fetchPlaceCategories, fetchTags} from "../../../../utils/GlobalSearch/fetchOptions";

const PLACE_CONFIG: {[CONTENT_TYPE.PLACE]: Record<string, FilterConf>} = {
  place: {
    featured: {
      key: 'featured',
      label: 'Featured',
      type: FILTER_TYPES.TOGGLE,
      quick: false,
    },
    open: {
      key: 'open',
      label: 'Open Now',
      type: FILTER_TYPES.TOGGLE,
      quick: true,
    },
    withActiveOffers: {
      key: 'withActiveOffers',
      label: 'Has Offers',
      type: FILTER_TYPES.TOGGLE,
      quick: true,
    },
    topics: {
      key: 'tags',
      label: 'Tags',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.TOPICS,
      quick: false,
      fetchOptions: fetchTags,
    },
    goals: {
      key: 'tags',
      label: 'Tags',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.GOALS,
      quick: false,
      fetchOptions: fetchTags,
    },
    categories: {
      key: 'categories',
      label: 'Categories',
      type: FILTER_TYPES.MULTISELECT,
      quick: false,
      fetchOptions: fetchPlaceCategories,
    },
  }
};

export default PLACE_CONFIG;