import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

/**
 * transform key strings to camelCase
 *
 * @param obj
 */
const camelize = (obj) => mapKeys(obj, (value, key) => camelCase(key));

export default camelize;
