import React, {useEffect, useRef, useState,} from 'react';
import AsyncSelect from 'react-select/async';
import {GroupBase} from 'react-select';
import useLocalStorage from '@rehooks/local-storage';
import {
  customStyles,
  GroupedOption,
  initialGroupOptions,
  RecentOption,
  SearchOptions,
} from './SelectParts';
import Group from './SelectParts/Group';
import {Option} from './SelectParts/Option';
import {RecentSearchMenu} from './SelectParts/Menu';
import {
  ClearIndicator,
  DropdownIndicator,
  IndicatorsContainer,
  MultiValueRemove,
  ValueContainer,
} from './SelectParts/ValueContainer';
import {useLocation} from 'react-router-dom';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import {usePlaceTypes} from '../../hooks/usePlaceTypes';
import {GlobalSearchParams} from "../../utils/GlobalSearch/types";

export const RECENT_SEARCH = 'recent_search';
export const FILTER_MENU_ID = 'filter-menu-title';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
    > {
    onSubmit?: (event: React.MouseEvent<HTMLElement>) => void;
    setParams?: (params: GlobalSearchParams, options: RecentOption) => void;
    onClear?: (event: React.MouseEvent<HTMLElement>) => void;
    toggleMenu?: React.Dispatch<React.SetStateAction<boolean>>;
    inputCleared?: boolean;
  }
}

const GlobalSearch = () => {
  const { searchTerm: globalSearchTerm, performSearch } = useGlobalSearch();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inputCleared, setInputCleared] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>(globalSearchTerm || '');
  const [recentSearch] =
    useLocalStorage<RecentOption[]>(RECENT_SEARCH);
  const [filterGroupedOptions, setFilterGroupedOptions] =
    useState<GroupedOption[]>(initialGroupOptions);
  const [recentGroupedOptions] = useState<
    GroupedOption[]
  >([{ options: recentSearch ?? [] }]);
  const { pathname } = useLocation();
    useState<RecentOption | null>();
  const selectRef = useRef<any>(null);
  const { categories, placeTypesLoading } = usePlaceTypes();
  const isFeaturedContent = pathname.includes('featured-content');

  useEffect(() => {
    setSearchTerm(globalSearchTerm || '');
  }, [globalSearchTerm]);

  useEffect(() => {
    if(isFeaturedContent) {
      setSearchTerm('');
      setFilterGroupedOptions(initialGroupOptions);
      setInputCleared(true);
    }
  }, [isFeaturedContent]);

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      setSearchTerm(inputValue);
    }
  };

  const handleFocus = (e) => {
    setMenuIsOpen(true);
  };

  const handleSearchSubmit = () => {
    performSearch(searchTerm);
  };

  const handleSearchInit = (e) => {
    e.preventDefault();
    setMenuIsOpen(false);
    handleSearchSubmit();
  };

  const handleClear = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const element = e.target as HTMLElement;
    setSearchTerm('');
    selectRef.current.inputRef.focus();
    setFilterGroupedOptions(initialGroupOptions);

    // setSelectedOption(initialSelectedOption);
    setInputCleared(true);
    if (
      element.parentElement?.parentElement?.id === 'home-dockbar' ||
      element.parentElement?.parentElement?.id === 'dockbar2-tooltip' ||
      element.parentElement?.parentElement?.id === 'wallet-tooltip' ||
      categories.includes(element.id)
    ) {
      selectRef.current.inputRef.blur();
      setMenuIsOpen(false);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      handleSearchInit(e);
    }
  };

  const handleRecentSearchInit = (params) => {
    setMenuIsOpen(false);
    setInputCleared(false);
    selectRef.current.inputRef.blur();

    setSearchTerm(params.term);
    performSearch(params.term);
  };

  return (
    <>
      <AsyncSelect<SearchOptions, true, GroupedOption>
        id='search-tooltip'
        styles={customStyles}
        placeholder={''}
        isMulti
        isSearchable
        menuIsOpen={menuIsOpen}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        components={{
          DropdownIndicator: (props) => (
            <DropdownIndicator {...props} onSubmit={handleSearchInit} />
          ),
          ValueContainer,
          Menu: RecentSearchMenu,
          MultiValueRemove,
          Group,
          Option,
          IndicatorsContainer,
          ClearIndicator,
        }}
        onInputChange={handleInputChange}
        onFocus={handleFocus}
        inputValue={searchTerm ?? ''}
        defaultOptions={
          (searchTerm === null || searchTerm === '') && inputCleared
            ? recentGroupedOptions
            : filterGroupedOptions
        }
        onSubmit={handleSearchInit}
        setParams={handleRecentSearchInit}
        onClear={handleClear}
        toggleMenu={setMenuIsOpen}
        ref={selectRef}
        onKeyDown={handleOnKeyDown}
        inputCleared={inputCleared}
      />
    </>
  );
};

export default GlobalSearch;
