import {APPLICATION_KEYS} from './constants/appKeys';
import {fetchToken} from './firebase';
import {urlBase64ToUint8Array} from './utils/push';
import {updateUser} from './endpoints/api';
import {isIphone, deviceDetected} from './utils/deviceCheck';
import {Alert} from '@chakra-ui/react';
import store from './state/store';
import {NotificationReminderPopupActions} from './state/NotificationReminderPopup/NotificationReminderPopupSlice';



export function sendCalendarEventNativeApp(calendar) {
	if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers['calendar-apple']
	)
		window.webkit.messageHandlers['calendar-apple'].postMessage({
			message: 'calendar-apple',
			calendar,
		});
}

export function initPush() {
	if (!('PushManager' in window)) {
		// Push isn't supported on this browser, disable or hide UI.
		return;
	} else askNotificationPermission();
}

export function askNotificationPermission() {
	if (Notification.permission === "granted") {
		return fetchToken();
	} else {
		return new Promise(function (resolve, reject) {
			const message = {
				type: 'locationRequest',
				user: {},
			};
			window['ReactNativeWebView']?.postMessage(JSON.stringify(message));

			const permissionResult = Notification.requestPermission().then(function (result) {
				resolve(result);
			});

			if (permissionResult) {
				permissionResult.then(resolve, reject);
			}
		}).then(function (permissionResult) {
			if (permissionResult !== 'granted') {
				throw new Error("We weren't granted permission.");
			} else {
				console.log('Notification permission granted!');
				// displayConfirmNotification();
				// subscribeUserToPush();
				// await navigator.serviceWorker.ready;
				return fetchToken();
			}
		});
	}
}

function subscribeUserToPush() {
	let serviceWorkerRegistration;
	return navigator.serviceWorker.ready
		.then((registration) => {
			serviceWorkerRegistration = registration;
			return registration.pushManager.getSubscription();
		})
		.then(function (subscription) {
			// if (subscription === null) {
			const subscribeOptions = {
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(APPLICATION_KEYS.publicKey),
			};

			return serviceWorkerRegistration.pushManager.subscribe(subscribeOptions);
			// }
		})
		.then(function (pushSubscription) {
			console.log('Successfully subscribed!.');
			console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
			return pushSubscription;
		});
}

function sendSubscriptionToBackEnd(subscription) {
	return fetch('/api/save-subscription/', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(subscription),
	})
		.then(function (response) {
			if (!response.ok) {
				throw new Error('Bad status code from server.');
			}

			return response.json();
		})
		.then(function (responseData) {
			if (!(responseData.data && responseData.data.success)) {
				throw new Error('Bad response from server.');
			}
		});
}

export function unsubscribeUserToPush() {
	return navigator.serviceWorker.ready
		.then((registration) => {
			return registration.pushManager.getSubscription();
		})
		.then((subscription) => {
			if (!subscription) {
				console.log('Not subscribed, nothing to do.');
				return;
			}
			return subscription.unsubscribe();
		})
		.then(() => console.log('Successfully unsubscribed!'))
		.catch((error) => console.error('Error thrown while unsubscribing from push messaging', error));
}

// iOS

if (
	window.webkit &&
	window.webkit.messageHandlers &&
	(window.webkit.messageHandlers['push-permission-request'] ||
		window.webkit.messageHandlers['push-permission-state'])
) {
	window.iOSPushCapability = true;
}

let requestCallback = null;
// Push permission request
export const pushPermissionRequest = function (callback) {
	if (window['ReactNativeWebView']) {
		const message = {
			type: 'locationRequest',
			user: {},
		};
		window['ReactNativeWebView'].postMessage(JSON.stringify(message));
	} else {
		if (window.iOSPushCapability) {
			requestCallback = callback;
			window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');
			return true;
		} else {
			console.log('Device not push capable');
			return false;
		}
	}
};

export function logToNative(data) {
	if (window?.webkit && window?.iOSPushCapability && window?.webkit?.messageHandlers?.["nativeLogger"]) {
		window?.webkit?.messageHandlers?.["nativeLogger"]?.postMessage(JSON.stringify(data));
	} 
}

window.addEventListener('push-permission-request', (message) => {
	console.log(message);
	if (message && message.detail) {
		if (requestCallback) {
			console.log('REQUEST CALLBACK');
			requestCallback(message.detail.state);
		}
		logToNative({token: message.detail.state});
		switch (message.detail.state) {

			case 'granted':
				// permission granted
				console.log(message.detail.token);
				logToNative({token: message.detail.token});
				// alert(message.detail.token);
				// alert(deviceDetected());
				updateUser({
					device_token: message.detail.token,
					device_platform: deviceDetected(),
					push_enabled: true,
				});
				break;
			default:
				// permission denied
				updateUser({
					push_enabled: false,
				})
				break;
		}
	}
});


let pushStateCallback = null;
// Push permission state
export const pushPermissionState = function ({callback}) {

	pushStateCallback = callback;
	if (window.iOSPushCapability)
		window.webkit.messageHandlers['push-permission-state'].postMessage('push-permission-state');
};

// iOS only at the moment
export const sendPushToken = function () {
	if (window.iOSPushCapability && window?.webkit?.messageHandlers?.['send-push-token']) {
		window?.webkit.messageHandlers?.['send-push-token']?.postMessage('send-push-token');
	}
}


export const openSettings = function () {
	if (window.iOSPushCapability && window?.webkit?.messageHandlers?.['open-settings']) {
		window?.webkit?.messageHandlers?.['open-settings']?.postMessage('open-settings');
	} else if (window?.['ReactNativeWebView']) {
		const message = {
			type: 'open-settings'
		}

		window?.['ReactNativeWebView']?.postMessage(JSON.stringify(message));
	}
}

window.addEventListener('push-permission-state', (message) => {
	if (message.detail.source === "expo-denied") {
		if (message.detail.showReminder) {
			store.dispatch(NotificationReminderPopupActions.setShow({show: true}));
		}

			updateUser({
				push_enabled: false,
			});
			return;
	}

	if (message && message.detail) {
		switch (message.detail) {
			case 'notDetermined':
				pushPermissionRequest();
				break;
			case 'denied':
				if (pushStateCallback) {
					pushStateCallback();
				} 

				//disabling push notifications when not granted
				updateUser({
					push_enabled: false,
				});
				break;
			case 'authorized':
			case 'ephemeral':
			case 'provisional':
				// permission granted
				pushPermissionRequest();
				break;
			case 'unknown':
			default:
				// something wrong
				break;
		}
	}
});
// Push notifications handle
// window.addEventListener("push-notification", (message) => {
//   if (message && message.detail) {
//     console.log(message.detail);
//     if (message.detail.aps && message.detail.aps.alert)
//       alert(
//         `${message.detail.aps.alert.title} ${message.detail.aps.alert.body}`
//       );
//   }
// });

// Push topic subscribe
export const mobilePushSubscribe = function (topic, eventValue, unsubscribe) {
	if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers['push-subscribe']
	) {
		window.iOSPushCapability = true;
	}
	if (window.iOSPushCapability) {
		window.webkit.messageHandlers['push-subscribe'].postMessage(
			JSON.stringify({
				topic: pushTopic, // topic name to subscribe/unsubscribe
				eventValue, // user object: name, email, id, etc.
				unsubscribe, // true/false
			})
		);
	}
};
