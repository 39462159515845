import {useCallback, useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import {fetchPlaceTypes} from '../endpoints/api';
import {map, filter, lowerCase, uniq, find} from 'lodash';

export const placeTypeData = [
  {value: 'places-to-eat', category: 'Places to eat', icon: 'fi fi-rr-hamburger', color: '#329241'},
  {value: 'shopping', category: 'Shopping', icon: 'fi fi-rr-shopping-bag', color: '#F28433'},
  {
    value: 'beauty-&-spas',
    category: 'Beauty & Spas',
    icon: 'fi fi-rr-barber-shop',
    color: '#F5737F',
  },
  {value: 'activities', category: 'Activities', icon: 'fi fi-rr-biking', color: '#813C77'},
  {value: 'bars', category: 'Bars', icon: 'fi fi-rr-beer', color: '#7D3EEE'},
  {
    value: 'coffee-and-deserts',
    category: 'Coffee & Desserts',
    icon: 'fi fi-rr-mug-hot-alt',
    color: '#81613C',
  },
  {value: 'entertainment', category: 'Entertainment', icon: 'fi fi-rr-guitar', color: '#E02F84'},
  {value: 'other', category: 'Other', icon: 'fi fi-rr-list', color: '#F28433'},
  {value: 'events', category: 'Events', icon: 'fi fi-rr-guitar', color: '#5976BC'},
  {value: 'offers', category: 'Perks', icon: 'fi fi-rr-star', color: '#F9AC39'},
];

export const useGetPlaceTypes = (enabled = true) => {
  return useQuery(['placeTypes'], fetchPlaceTypes, {enabled: enabled, staleTime: 300000});
};

export const usePlaceTypes = (enabled = true) => {
  const {data, isLoading} = useGetPlaceTypes(enabled);
  const placeTypes = data?.data.data;

  const categories = useMemo(
    () => [...uniq(map(placeTypes, (item) => item.category)), 'Events', 'Perks'],
    [placeTypes]
  );

  const getIdsForCategory = useCallback(
    (category) => {
      const ids = map(
        filter(placeTypes, (item) => lowerCase(item.category) == lowerCase(category)),
        (placeType) => placeType.id
      );

      return ids;
    },
    [placeTypes]
  );

  const getIconDataForCategory = useCallback(
    (category) => {
      return find(placeTypeData, {category: category});
    },
    [categories]
  );

  const getPlaceTypeFromId = useCallback(
    (placeTypeId) => find(placeTypes, {id: placeTypeId}),
    [placeTypes]
  );

  return {
    placeTypes,
    categories,
    getIconDataForCategory,
    getIdsForCategory,
    getPlaceTypeFromId,
    placeTypesLoading: isLoading,
  };
};
