import {Center, Flex, Spinner, useTheme, VStack} from '@chakra-ui/react';
import {filter, orderBy} from 'lodash';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {TextG10} from '../../../components/typography';
import {useFetchActivePasses} from '../../../hooks/useUserPasses';
import PassCard from '../PassCard';
import Header from './Header';

const PassPage = () => {
  const {passId} = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const {data, isLoading} = useFetchActivePasses();
  const activePasses = data?.data;

  const passes = orderBy(
    filter(activePasses, (pass) => pass.pass_id == passId),
    ['expires_at'],
    ['asc']
  );

  const loading = isLoading || !passes;

  if (loading) {
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }
  const passName = passes[0].pass.title;
  const passAmount = passes.length;

  return (
    <Flex flexDir="column">
      <Header passName={passName} passAmount={passAmount} />
      <Flex gap="20px" py={6} px={4} flexDir="column">
        {passes.map((pass) => {
          return (
            <PassCard
              onClick={() =>
                navigate(
                  `/standalone-search/event?filterTitle=${encodeURIComponent(
                    `${pass.pass.title} Pass Events`
                  )}&passTypes=${pass.pass.type.id}`
                )
              }
              key={pass.id}
              expires_at={pass.expires_at}
              pass={pass.pass}
              showGetMore={false}
              arrowCTA={
                <TextG10 fontWeight="500" color={theme.colors.blue}>
                  Find an event for this pass
                </TextG10>
              }
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default PassPage;
