import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {fetchOffer, fetchOffers} from '../endpoints/api';

interface FetchOffersOptions {
  page?: string;
  articleType?: 'partnerId' | 'placeId' | 'eventId' | 'postId';
  articleId?: string | null;
  enabled?: boolean;
  communityId?: string;
}

export const useFetchOffer = (articleId: string | null) => {
  return useQuery(['offer', {articleId}], () => fetchOffer(Number(articleId)), {
    enabled: !!articleId,
  });
};

export const useFetchOffers = ({
  page = '1',
  articleType,
  articleId,
  enabled = true,
  communityId,
}: FetchOffersOptions) => {
  return useQuery(
    [
      'offers',
      {
        page,
        ...(articleType && {articleType}),
        ...(articleId && {articleId}),
        ...(communityId && {communityId}),
      },
    ],
    () =>
      fetchOffers({
        page,
        ...(articleType && {articleType}),
        ...(articleId && {articleId}),
        ...(communityId && {communityId}),
      }),
    {enabled: enabled}
  );
};

export const useInfiniteOffers = ({articleType, articleId, communityId}) => {
  return useInfiniteQuery({
    queryKey: ['infinite-offers', {articleType, articleId, communityId}],
    queryFn: (params) =>
      fetchOffers({
        page: params.pageParam || 1,
        ...(articleType && {articleType}),
        ...(articleId && {articleId}),
        ...(communityId && {communityId}),
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.data.current_page !== lastPage.data.last_page) {
        return lastPage.data.current_page + 1;
      }
    },
    // keepPreviousData: true,
  });
};

export const useOffers = ({page, articleType, articleId, enabled, communityId}: FetchOffersOptions) => {
  const fetchOffers = useFetchOffers({page, articleType, articleId, enabled, communityId});

  return {offers: fetchOffers.data?.data.data, offersIsLoading: fetchOffers.isLoading};
};
