import {useInfiniteQuery} from '@tanstack/react-query';
import {globalSearch} from '../endpoints/api';
import useGlobalSearch from './useGlobalSearch';
import {loadFromLocalStorage} from '../state/Communities/CommunitiesSlice';
import {omit} from 'lodash';

export const useInfiniteGlobalSearch = () => {
  const {params} = useGlobalSearch();
  const community = loadFromLocalStorage();
  const communityId = community.id;

  return useInfiniteQuery(
    ['infinite-global-search', {...params, communityId}],
    ({pageParam = 1}) =>
      globalSearch({
        ...params,
        communityId,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export const useInfiniteGlobalSearchWithParams = (params) => {
  return useInfiniteQuery(
    ['infinite-global-search', {...omit(params, ['lat', 'long'])}],
    ({pageParam = 1}) =>
      globalSearch({
        ...params,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};
