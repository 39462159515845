export const dayMap = new Map<number, string>([
  [1, "monday"],
  [2, "tuesday"],
  [3, "wednesday"],
  [4, "thursday"],
  [5, "friday"],
  [6, "saturday"],
  [7, "sunday"],
]);

export enum PLACE_STATUS {
  OPEN = "open",
  CLOSED = "closed",
  OPENING_SOON = "opening_soon",
  CLOSING_SOON = "closing_soon",
  UNKNOWN = "unknown",
  TEMPORARILY_CLOSED = "Temporarily closed",
}
