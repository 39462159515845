import {Box, Center, Container, Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import DiscountCard from '../../components/DiscountCard';
import Icon from '../../components/Icon';
import QueryInfiniteScroll from '../../components/QueryInfiniteScroll';
import {TextG14} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import {useInfiniteUserDiscounts} from '../../hooks/useUserDiscounts';
import {OfferType} from '../../types/acrticle';

const MyDiscounts = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {user} = useAuth();

  return (
    <Container pt={6} px={'0 !important'} pb={'initial'} h="auto">
      {!user?.hasActiveMembership ? (
        <Box
          mt={3}
          onClick={() => navigate('/memberships')}
          flexDir="column"
          py={4}
          px={6}
          w="100%"
          boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.14)"
          border="1px solid #FFC0AE"
          borderRadius="12px">
          <Center>
            <Flex color={theme.colors.rose} align="center">
              <TextG14 fontWeight="700">Upgrade your membership to get local discounts!</TextG14>
              <Icon
                iconName="fi fi-rr-arrow-right"
                style={{
                  fontSize: '14px',
                  marginTop: '2px',
                }}
              />
            </Flex>
          </Center>
        </Box>
      ) : (
        <QueryInfiniteScroll
          showItems={(discount) =>
            discount.offer_type == OfferType.DISCOUNT ? (
              <DiscountCard discount={discount} />
            ) : (
              <OfferCard offer={discount} />
            )
          }
          hook={useInfiniteUserDiscounts}
          endpointParams={{}}
        />
      )}
    </Container>
  );
};

export default MyDiscounts;
