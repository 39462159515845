import React, {useEffect, useMemo} from 'react';
import {Flex, HStack, Spacer, useTheme} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../typography';
import {generatePath, Link, useParams} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Post} from '../../../types/article';

import CardInterestTags from '../CardInterestTags';

import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import PremiumCard from '../../Card/PremiumCard';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import {ConditionalWrapper} from '../../ConditionalWrapper';

const NoWrapText = styled(TextG10)`
  word-wrap: break-word !important;
`;

const PlaceCard = ({
  post,
  leftTopItem,
  rightTopItem,
  height = 'fit-content',
  modal,
  isSlide = false,
  onClick,
  isPreview = false,
}: {
  post?: Post;
  leftTopItem?: any;
  rightTopItem?: any;
  showEndorsements?: boolean;
  fullCard?: boolean;
  height?: string;
  modal?: boolean;
  hideCheckin?: boolean;
  isSlide?: boolean;
  onClick?: () => void;
  isPreview?: boolean;
}) => {
  if (!post) return null;
  const theme = useTheme();
  const {pathname} = useParams();
  const isProduction = process.env.NODE_ENV === 'production';
  const [ref, {wasEverVisible, isVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();

  useEffect(() => {
    if (wasEverVisible && post?.id.toString()) {
      addVisibleArticle({id: post.id.toString(), type: 'place'});
    }
  }, [wasEverVisible]);

  const image = useMemo(() => post?.image_url, [post]);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: function (number, withoutSuffix) {
        return withoutSuffix ? 'now' : 'a few seconds';
      },
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mth',
      MM: '%dmth',
      y: '1y',
      yy: '%dy',
    },
  });

  return (
    <ConditionalWrapper
      condition={!onClick}
      wrapper={(children) => (
        <Link
          style={{width: '100%'}}
          to={
            isPreview
              ? ''
              : modal
              ? {
                  pathname,
                  search: `?id=${post.id.toString()}&articleType=${
                    post?.type ? post.type.toString().toLowerCase() : 'post'
                  }`,
                }
              : generatePath(ROUTES.POST, {
                  postId: post.id.toString(),
                })
          }
          onClick={(e) => {
            storeScrollPositionInSession();
            e.stopPropagation();
          }}
          ref={ref}>
          {children}
        </Link>
      )}>
      <PremiumCard
        article={post}
        isSlide={isSlide}
        height={height}
        coverImage={image}
        leftTopItem={leftTopItem}
        rightTopItem={rightTopItem}
        childPaddingX={4}>
        <HStack justifyContent="space-between" align="center">
          <TextG14
            isTruncated="&hellip;"
            noOfLines={null}
            fontWeight={700}
            color={theme.colors.black}
            dangerouslySetInnerHTML={{__html: post.title || post.description}}
            children={undefined}
          />
          {(!post.topics_of_interest ||
            post.topics_of_interest.filter((topic) => !topic.name.includes('Context:')).length ==
              0) && <NoWrapText>{dayjs.utc(post?.released_at).fromNow(true)}</NoWrapText>}
        </HStack>
        <Flex mt={1} justifyContent="space-between" align="center" gap="5px">
          {post.topics_of_interest && post.topics_of_interest.length > 0 ? (
            <CardInterestTags
              autoScroll={true}
              tagsVisible={true}
              onlyShowMatched={false}
              topicsOfInterest={post.topics_of_interest}
            />
          ) : null}

          <Spacer />
          {post.topics_of_interest &&
            post.topics_of_interest.filter((topic) => !topic.name.includes('Context:')).length >
              0 && <NoWrapText>{dayjs.utc(post?.released_at).fromNow(true)}</NoWrapText>}
        </Flex>
      </PremiumCard>
    </ConditionalWrapper>
  );
};

PlaceCard.defaultProps = {
  showEndorsements: true,
};

export default PlaceCard;
