import {Box, Center, Divider, Flex, VStack} from "@chakra-ui/react";
import Icon from "../../Icon";
import {TextG10, TextG12} from "../../typography";
import BottomModalSheet, {BottomModalSheetRefProps} from "../../Modals/BottomModalSheet";
import React, {useEffect, useRef, useState} from "react";
import useGlobalSearch from "../../../hooks/useGlobalSearch";
import {FilterOption} from "../../../utils/GlobalSearch/types";
import {CONTENT_TYPE} from "../../../utils/GlobalSearch/enums";
import {HAPPENING_FILTER_OPTIONS} from "../../../utils/GlobalSearch/constants";

const TYPE = CONTENT_TYPE.EVENT;

type QuickDateFilterProps = {
  sheetRef?: React.MutableRefObject<BottomModalSheetRefProps | undefined>,
}

const QuickDateFilter = ({ sheetRef }: QuickDateFilterProps) => {
  const [isDraggable, setIsDraggable] = useState(false);

  const happeningSheetRef = useRef<BottomModalSheetRefProps>()!

  const {
    getTypeSpecificFilter,
    setTypeSpecificFilter
  } = useGlobalSearch();

  const isFilterApplied = (option: FilterOption) => getTypeSpecificFilter(TYPE, 'happening')?.find((val) => val === option.value);
  const appliedValues = getTypeSpecificFilter(TYPE, 'happening');
  const getLabelByValue = (val: string) => HAPPENING_FILTER_OPTIONS.find((item) => item.value === val)?.label;

  useEffect(() => {
    const element = document.getElementById('fromDate');
    if(element) {
      element.focus();
    }
  }, [sheetRef?.current]);

  return (
    <BottomModalSheet
      disableDrag={isDraggable}
      dragHandler={setIsDraggable}
      customHeader={<></>}
      ref={happeningSheetRef}
      triggerElement={
        <Flex alignItems={'center'} gap={2}>
          <Icon iconName="fi-rr-calendar" />
          <TextG10 style={{ fontWeight: 'bold' }}>{
            appliedValues?.length ? (
              appliedValues.length > 1 ? `${getLabelByValue(appliedValues[0])} + ${appliedValues.length - 1}` : getLabelByValue(appliedValues[0])
            ) : 'Happening'}</TextG10>
          <Icon style={{fontSize: '10px'}} iconName="fi-rr-angle-down" />
        </Flex>
      }>
      <>
        <Center py={2}>
          <Icon style={{fontSize: '16px'}} iconName="fi-rr-angle-down" />
        </Center>
        <VStack px={2} align="start">
          {HAPPENING_FILTER_OPTIONS.map((option) => (
            <Box w={'100%'} key={option.value}>
              <Flex
                pr={5}
                alignItems={'center'}
                justifyContent={'space-between'}
                onClick={() => {
                  if(option.value === 'date-range' && !isFilterApplied(option)) {
                    happeningSheetRef.current?.setState(false);
                    sheetRef?.current?.setState(true);
                  }

                  if(!appliedValues) {
                    setTypeSpecificFilter(TYPE, 'happening', [option.value]);
                    return;
                  }

                  setTypeSpecificFilter(TYPE, 'happening', isFilterApplied(option)
                    ? appliedValues.filter((val) => val !== option.value)
                    : [...appliedValues, option.value])
                }}
              >
                <TextG12 p={5}>{option.label}</TextG12>
                {appliedValues?.length &&
                  appliedValues?.find((val) => val === option.value) ?
                  (<i className="fi fi-sr-check"></i>) : null
                }
              </Flex>
              <Divider/>
            </Box>
          ))}
        </VStack>
      </>
    </BottomModalSheet>
  );
}

export default QuickDateFilter;
