import {Flex, Box} from '@chakra-ui/react';
import React from 'react';
import {TextG14} from '../../typography';
import {OpenHour} from '../index';

function OpenHour({day, hours, current}: OpenHour) {
  const hoursArray = hours.split(', ');
  const openHoursList = (
    <>
      <TextG14 style={{minWidth: '85px'}}>{current ? <b>{day}</b> : day}</TextG14>
      <Box style={{textAlign: 'right'}}>
        {hoursArray.map((hour) => (
          <TextG14 key={hour}>{current ? <b>{hour}</b> : hour}</TextG14>
        ))}
      </Box>
    </>
  );

  return (
    <Flex width={'100%'} justifyContent={'space-between'} gap={'1rem'}>
      {openHoursList}
    </Flex>
  );
}

export default OpenHour;
