import { createSlice } from '@reduxjs/toolkit'
import {RSVPModalPopupProps} from "./RSVPModalPopup";

export type SliceState = RSVPModalPopupProps[];
type SliceAction = { payload: RSVPModalPopupProps, type: string };

const initialState: SliceState = [];

export const RSVPModalPopupSlice = createSlice({
    name: 'rsvpModalPopup',
    initialState,
    reducers: {
        pushRSVPStatus: (state: SliceState, action: SliceAction) => {
            state.push({...action.payload });
        },
        popRSVPStatus: (state: SliceState) => {
            state.shift();
        }
    },
});

export const RSVPModalPopupActions = RSVPModalPopupSlice.actions;

export default RSVPModalPopupSlice;