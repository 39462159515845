import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {fetchBookmarks} from '../endpoints/api';

interface FetchBookmarkOptions {
  type?: string;
  page?: string;
}

export const useFetchBookmarks = ({type, page = '1'}: FetchBookmarkOptions) => {
  return useQuery(['bookmarks', {type, page}], () => fetchBookmarks({type, page, cancelReq: null}));
};

export const useInfiniteBookmarks = ({type}) => {
  return useInfiniteQuery(
    ['infinite-bookmarks', {type}],
    ({pageParam = 1}) => fetchBookmarks({type, page: pageParam, cancelReq: null}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export const useBookmarks = ({type, page}: FetchBookmarkOptions) => {
  const fetchBookmarks = useFetchBookmarks({type, page});

  return {bookmarks: fetchBookmarks.data?.data.data, bookmarksIsLoading: fetchBookmarks.isLoading};
};
