import {
  AbsoluteCenter,
  Box,
  Container,
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  Image,
  useTheme,
  VStack,
  Textarea,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import Header from '../../assets/images/create-meetup.png';
import {BlueButton} from '../../components/button';
import IceBreakerSelect from '../../components/IceBreakerSelect';
import Icon from '../../components/Icon';
import {TextG12, TextG14, TextG24} from '../../components/typography';
import {EventRSVPStatus, useSetAttendance} from '../../hooks/useEvent';
import {useCreateMeetup} from '../../hooks/useMeetUps';
import {Place} from '../../types/acrticle';
import PlaceSelectModal from './PlaceSelectModal';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const CreateMeetup = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {mutateAsync} = useCreateMeetup();
  const setAttendance = useSetAttendance();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(
    dayjs().add(1, 'day').minute(0).format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs(startDate).add(2, 'hour').minute(0).format(DATE_FORMAT)
  );
  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [iceBreaker, setIceBreaker] = useState<string>('');
  const [customIceBreaker, setCustomIceBreaker] = useState<string>('');

  const isValid =
    description &&
    startDate &&
    startDate.length > 0 &&
    selectedPlace &&
    (iceBreaker == 'custom' ? customIceBreaker : iceBreaker);

  const handleCreateClicked = async () => {
    if (!isValid) {
      return;
    }

    const utcStartDate = dayjs(startDate).utc().format(DATE_FORMAT);
    const utcEndDate = dayjs(endDate).utc().format(DATE_FORMAT);

    const newMeetup = await mutateAsync({
      title: title,
      description: description,
      reason: iceBreaker == 'custom' ? customIceBreaker : iceBreaker,
      start_date_time: utcStartDate,
      end_date_time: utcEndDate,
      is_private: false,
      place_id: selectedPlace.id,
    });

    if (newMeetup.data?.event) {
      const createdEvent = newMeetup.data?.event;
      await setAttendance.mutateAsync({
        eventId: createdEvent.id,
        prevStatus: EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.GOING,
      });

      navigate(`/meet-up/${newMeetup.data?.event?.id}`, {replace: true});
    }
  };

  return (
    <Container p="2px 3px">
      <VStack align="start" h="100%">
        <Box pos="relative">
          <AbsoluteCenter>
            <TextG24 color="white" fontWeight="700">
              Meet-Up
            </TextG24>
          </AbsoluteCenter>
          <Image src={Header} />
          <Icon
            onClick={() => navigate(-1)}
            iconName="fi-rr-cross-small"
            style={{
              position: 'absolute',
              right: '18px',
              top: '13px',
              fontSize: '24px',
              height: '24px',
              width: '24px',
              color: 'white',
              fill: 'white',
            }}
          />
        </Box>
        <VStack py={3} px={4} w="100%" gap="20px">
          <FormControl isRequired>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Give your Meet-Up a Title
              </TextG14>
            </FormLabel>
            <Textarea
              isRequired
              resize="none"
              borderRadius="10px !important"
              minH="85px"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter a title"
              py={3}
              px={4}
              color={theme.colors.lightBlack}
              _placeholder={{color: theme.colors.lightestGrey, fontWeight: '500', fontSize: '12px'}}
              border={`1px solid ${theme.colors.lighterGrey} !important`}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Describe your Meet-Up
              </TextG14>
            </FormLabel>
            <Textarea
              isRequired
              resize="none"
              borderRadius="10px !important"
              minH="85px"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Eg: Who want’s to fly with me?"
              py={3}
              px={4}
              color={theme.colors.lightBlack}
              _placeholder={{color: theme.colors.lightestGrey, fontWeight: '500', fontSize: '12px'}}
              border={`1px solid ${theme.colors.lighterGrey} !important`}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Select a start time
              </TextG14>
            </FormLabel>
            <input type="hidden" id="timezone" name="timezone" value="-08:00" />
            <Input
              isRequired
              min={dayjs().format(DATE_FORMAT)}
              border="1px solid #E5E5E5 !important"
              fontFamily="Gordita"
              fontSize="12px !important"
              fontWeight="500"
              defaultValue={startDate}
              onChange={(e) => setStartDate(dayjs(e.target.value).format(DATE_FORMAT))}
              borderRadius="22.5px !important"
              padding="9px 24px"
              placeholder="Select Date and Time"
              color={startDate ? theme.colors.lightBlack : theme.colors.lightestGrey}
              size="xs"
              type="datetime-local"
              height="auto"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Select an end time
              </TextG14>
            </FormLabel>
            <input type="hidden" id="timezone" name="timezone" value="-08:00" />
            <Input
              isRequired
              min={dayjs(startDate).format(DATE_FORMAT)}
              border="1px solid #E5E5E5 !important"
              fontFamily="Gordita"
              fontSize="12px !important"
              fontWeight="500"
              defaultValue={endDate}
              onChange={(e) => setEndDate(dayjs(e.target.value).format(DATE_FORMAT))}
              borderRadius="22.5px !important"
              padding="9px 24px"
              placeholder="Select Date and Time"
              color={endDate ? theme.colors.lightBlack : theme.colors.lightestGrey}
              size="xs"
              type="datetime-local"
              height="auto"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Select a Place
              </TextG14>
            </FormLabel>
            <PlaceSelectModal selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Ice Breaker
              </TextG14>
            </FormLabel>
            <FormHelperText mb={4}>
              <TextG12 color={theme.colors.grey}>
                Choose something to talk about before your meet-up so you can get to know each other
                before you hang out!
              </TextG12>
            </FormHelperText>
            <IceBreakerSelect
              iceBreaker={iceBreaker}
              setIceBreaker={setIceBreaker}
              customIceBreaker={customIceBreaker}
              setCustomIceBreaker={setCustomIceBreaker}
            />
          </FormControl>
          <BlueButton isDisabled={!isValid} onClick={handleCreateClicked} w="100%" bottom={0}>
            Annouce Meet-Up
          </BlueButton>
        </VStack>
      </VStack>
    </Container>
  );
};

export default CreateMeetup;
