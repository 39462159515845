import {Flex, HStack, Box} from '@chakra-ui/react';
import React from 'react';
import {components} from 'react-select';
import DotSeparator from '../../../DotSeparator';
import {TextG10} from '../../../typography';

export const Option = (props) => {
  return <components.Option {...props}>{props.children}</components.Option>;
};

export const RecentSearchOption = (props) => {
  const {
    data,
    selectProps: {setParams},
  } = props;

  const recentSearchHandler = () => {
    const options = {
      term: data.term,
      goals: data.goals,
      tags: data.tags,
      type: data.type,
      value: data.value,
    };

    setParams(data?.params, options);
  };

  return (
    <components.Option {...props}>
      <Flex
        className="recent-search"
        style={{
          borderBottom: '1px solid #E5E5E5',
          fontWeight: 400,
          fontSize: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0',
          alignItems: 'center',
          color: 'black',
          width: '100%',
        }}
        _last={{borderBottom: 'unset'}}
        onClick={recentSearchHandler}>
        <Flex gap={2} alignItems={'center'}>
          <i style={{width: '12px', height: '12px'}} className="fi fi-rr-time-past"></i>
          <span>{data.term}</span>
          {(data.goals ?? []).map((goal) => (
            <Box
              key={`${goal.id}_${goal.type}`}
              alignItems={'center'}
              borderRadius={'4px'}
              color={'#272929'}
              backgroundColor={'#F4F4F4'}
              padding={'4px'}>
              <HStack fontSize={'8px'}>
                <TextG10>{goal.label}</TextG10>
              </HStack>
            </Box>
          ))}
          {(data.tags ?? []).map((tag) => (
            <Box
              key={`${tag.id}_${tag.type}`}
              alignItems={'center'}
              borderRadius={'4px'}
              color={'#272929'}
              backgroundColor={'#F4F4F4'}
              padding={'4px'}>
              <HStack fontSize={'8px'}>
                <TextG10>{tag.label}</TextG10>
              </HStack>
            </Box>
          ))}
          <DotSeparator color="#D9D9D9" marginLeft='0' marginRight='0'/>
          <div style={{fontSize: '8px', color: '#757D84'}}> in {data.type === 'All' ? data.type : `${data.type}s`}</div>
        </Flex>
        <i
          style={{
            fontSize: '15px',
            height: '10px',
            transform: 'rotate(45deg)',
          }}
          className="fi fi-rr-arrow-small-left"></i>
      </Flex>
    </components.Option>
  );
};
