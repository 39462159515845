import {Box, Flex, Image, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {TextA14, TextG10} from '../../components/typography';
import {getTicketDate} from '../../utils/date';

const TicketCard = ({ticket, numTickets}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate(`/tickets/${ticket.event?.id}`)}
      borderRadius="12px"
      py={2}
      px={3}
      boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.14)">
      <Flex flexDir="column" gap="8px">
        <Image
          objectFit="cover"
          borderRadius="10px"
          src={ticket.event?.image_url}
          h="80px"
          w="100%"
        />
        <TextA14 fontWeight="900" theme={theme.colors.darkGrey}>
          {ticket.name}
        </TextA14>
        <Flex my={1} justifyContent="space-between">
          <Flex gap="4px">
            <TextG10 fontWeight="700">Date: </TextG10>
            <TextG10>
              {getTicketDate(ticket.event?.start_date_time, ticket.event?.end_date_time)}
            </TextG10>
          </Flex>
          <Flex gap="4px">
            <TextG10 fontWeight="700">Tickets: </TextG10>
            <TextG10>{numTickets.toString().padStart(2, '0')}</TextG10>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TicketCard;
