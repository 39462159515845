import {Box, Flex, useTheme} from '@chakra-ui/react';
import {isEmpty} from 'lodash';
import React from 'react';
import BottomModalSheet from '../../../components/Modals/BottomModalSheet';
import {TextG14} from '../../../components/typography';
import {OPTIONS as DEFAULT_OPTONS} from '../../../constants/eventOptions';
import {EventRSVPStatus} from '../../../hooks/useEvent';
import {Event} from '../../../types/article';
import {deviceDetected} from '../../../utils/deviceCheck';

export type RSVPSelectProps = {
  event: Event;
  openModal?: boolean;
  onModalClose?: () => void;
  onOptionClick?: (status: EventRSVPStatus) => void;
  rsvpOptions?: any;
};

const RSVPSelect = ({
  onModalClose,
  openModal,
  onOptionClick,
  event,
  rsvpOptions,
}: RSVPSelectProps) => {
  const theme = useTheme();
  const device = deviceDetected();
  const options = rsvpOptions || DEFAULT_OPTONS;

  return (
    <BottomModalSheet openModal={openModal} onModalClose={onModalClose}>
      <Box px={3} pb={device === 'iphone' || device === 'iphone-pwa' ? '14px' : 0}>
        {options.map((option, i) => {
          if (i === 0 || isEmpty(options[i])) return;
          const style = {
            style: {
              fontSize: '18px',
              height: '18px',
              width: '18px',
              color: theme.colors[option.color] || option.color,
              fill: theme.colors[option.color] || option.color,
            },
          };
          return (
            <Flex
              key={i}
              alignItems="center"
              borderBottom="1px solid #E5E5E5"
              pt={i == 1 ? 0 : 5}
              pb={5}
              flexDir="row"
              gap="20px"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onOptionClick && onOptionClick(i);
              }}>
              {i === event.rsvp_status.status_value
                ? option.renderSelectedIcon(style)
                : option.renderIcon(style)}
              <Flex flexDir="column">
                <TextG14 fontWeight={i === event.rsvp_status.status_value ? 700 : undefined}>
                  {option.text}
                </TextG14>
              </Flex>
            </Flex>
          );
        })}
      </Box>
    </BottomModalSheet>
  );
};

export default RSVPSelect;
