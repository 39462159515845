import {Box, Flex} from '@chakra-ui/react';
import React from 'react';
import ReactPlayer from 'react-player/youtube';
import Icon from '../Icon';
import {TextG10} from '../typography';
import styled from 'styled-components';

export interface ProgressState {
    played: number; // Fraction of the media that has been played (0 to 1)
    playedSeconds: number; // Number of seconds that have been played
    loaded: number; // Fraction of the media that has been buffered (0 to 1)
    loadedSeconds: number; // Number of seconds that have been buffered
}

const StyledPlayer = styled(ReactPlayer)`
  .react-player__preview {
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    border-radius: 8px;
  }
`;

const WidgetVideoPlayer = ({
                               url,
                               height = '100%',
                               playing = false,
                               light = false,
                               controls = true,
                               setModalUrl,
                               onVideoEnd,
                               setVideoPlaying,
                               onProgress,
                           }: {
    url: string;
    height?: string;
    playing?: boolean;
    light?: boolean;
    controls?: boolean;
    setModalUrl?: (url: string) => void;
    onVideoEnd?: () => void;
    setVideoPlaying?: (playing: boolean) => void;
    onProgress?: (progress: ProgressState) => void;
}) => {
    return (
        <Box
            className="player-wrapper"
            h={height}
            pos="relative"
            overflow="hidden"
            background="black"
            borderRadius="8px">
            <StyledPlayer
                onProgress={onProgress}
                onPlay={() => {
                    if (setVideoPlaying) setVideoPlaying(true);
                }}
                onPause={() => {
                    if (setVideoPlaying) setVideoPlaying(false);
                }}
                light={light}
                volume={0.5}
                playIcon={
                    light && (
                        <Box borderRadius="16px" background="rgba(0,0,0,0.7);">
                            <Flex gap="4px" alignItems="center" px={3} py={2}>
                                <Icon style={{color: 'white', fontSize: '12px'}} iconName="fi-sr-play" />
                                <TextG10 color="white" fontWeight="500">
                                    Watch Now
                                </TextG10>
                            </Flex>
                        </Box>
                    )
                }
                playing={playing}
                controls={controls}
                onEnded={() => {
                    if (onVideoEnd) {
                        onVideoEnd();
                    }

                    if (setVideoPlaying) setVideoPlaying(false);
                    // const nextIndex = findNextTaskIndex(url);
                    // if (nextIndex != -1 && setModalUrl) setModalUrl(TASKS[nextIndex].url);
                }}
                width="100%"
                height="100%"
                playsinline
                config={{
                    youtube: {
                        playerVars: {playsinline: true}, // Enable inline playback
                    },
                }}
                url={url}
            />
        </Box>
    );
};

export default WidgetVideoPlayer;
