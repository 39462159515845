import {CloseButton, Flex, Image, useTheme} from '@chakra-ui/react';
import React, {useEffect, useMemo, useState} from 'react';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import Modal from '../Modals/Modal';

const FullscreenImage = ({show, setShow, image}) => {
  const theme = useTheme();
  const [showClose, setShowClose] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowClose(show), 150);
    } else {
      setShowClose(show);
    }
  }, [show]);

  const currentImageBody = useMemo(
    () => (
      <Flex background={theme.colors.darkGrey} justifyContent="center">
        <TransformWrapper>
          <TransformComponent
            contentStyle={{width: '95vw', height: '85vh', justifyContent: 'center'}}
            wrapperStyle={{background: theme.colors.darkGrey}}>
            <Image
              fallbackStrategy="onError"
              src={image}
              alt="cover image"
              width="100%"
              height="100%"
              objectFit="contain"
              maxHeight="95vh"
              background={theme.colors.darkGrey}
            />
          </TransformComponent>
        </TransformWrapper>
      </Flex>
    ),
    [image]
  );

  return (
    <Modal
      header={
        <CloseButton
          opacity={showClose ? 1 : 0}
          transition={'opacity 500ms'}
          position="fixed"
          top={2}
          right={3}
          color="#9b9b9b"
          border="2px solid #9b9b9b"
          borderRadius="full"
          onClick={() => setShow(false)}
          zIndex={1}
        />
      }
      children={currentImageBody}
      show={show}
      parentCallback={() => setShow(false)}
      modalPadding={0}
      headerPadding="0px"
      isCentered
      overlayBackground={theme.colors.darkGrey}
    />
  );
};

export default FullscreenImage;
