import {Flex, HStack, Radio, RadioGroup, Stack, useTheme, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {BlueButton, WhiteButton} from '../../../components/button';
import {EventChatIsClosed} from '../../../components/Chat/EventChat';
import Icon from '../../../components/Icon';
import {SpinnerLoader} from '../../../components/Loader';
import Modal from '../../../components/Modals/Modal';
import {ToastMessage} from '../../../components/toast';
import {TextA24, TextG12, TextG14} from '../../../components/typography';
import useAuth from '../../../hooks/useAuth';
import {
  BROADCAST_AUDIENCE,
  useBroadcastReach,
  useMeetupBroadcast,
} from '../../../hooks/useBroadcast';
import { removeFooterFromDescription } from '../../CreateMeetup/MeetupForm';

const MeetupBroadcastButton = ({event}) => {
  const theme = useTheme();
  const {user} = useAuth();
  const {mutateAsync: sendBroadcast, isLoading, isSuccess, error} = useMeetupBroadcast();

  const [modalOpen, setModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inviteGroup, setInviteGroup] = useState('1');

  const broadcastCount = event.broadcasts_count;

  const eventChatClosed = EventChatIsClosed(event);

  const handleSendClicked = async () => {
    setIsSaving(true);

    const broadcastMessage = removeFooterFromDescription(event.description, user?.name);

    await sendBroadcast({
      eventId: event.id,
      audienceRuleset:
        inviteGroup == '1'
          ? BROADCAST_AUDIENCE.ANYONE_NEARBY
          : BROADCAST_AUDIENCE.LOCAL_INSIDERS_CLUB,
      message: broadcastMessage,
    });

    setIsSaving(false);
    setModalOpen(false);
    ToastMessage({status: 'success', text: 'Broadcast Sent!'});
  };

  const {data: broadcastReach, isLoading: reachLoading} = useBroadcastReach({
    audienceRuleset:
      inviteGroup == '1'
        ? BROADCAST_AUDIENCE.ANYONE_NEARBY
        : BROADCAST_AUDIENCE.LOCAL_INSIDERS_CLUB,
    placeId: event.place?.id.toString(),
  });
  const reachUserCount = broadcastReach?.data.user_count;

  return (
    <>
      <BlueButton
        background={broadcastCount > 0 ? theme.colors.ligherGrey : theme.colors.blue}
        color={broadcastCount > 0 ? theme.colors.lightestGrey : 'white'}
        onClick={() => {
          if (broadcastCount == 0) {
            setModalOpen(true);
          } else if (eventChatClosed) {
            ToastMessage({
              status: 'error',
              text: 'This Event has ended and you can no longer broadcast',
            });
          } else {
            ToastMessage({status: 'error', text: 'You can only send 1 broadcast per meet-up'});
          }
        }}>
        <Flex gap="8px" alignItems="center">
          <Icon
            iconName="fi-rr-megaphone"
            style={{fontSize: '16px', width: '16px', height: '13px'}}
          />
          <TextG12>Broadcast to Nearby Neighbors</TextG12>
        </Flex>
      </BlueButton>
      <Modal
        headerPadding="1rem 1.5rem 0 1.5rem"
        header={<TextA24 textAlign="center">Send Broadcast</TextA24>}
        isCentered
        parentCallback={() => setModalOpen(false)}
        show={modalOpen}
        closeButton
        footer={
          <HStack gap="12px" w="100%" justify="center" px={4}>
            {isSaving ? (
              <SpinnerLoader />
            ) : (
              <VStack>
                <BlueButton w="100%" isDisabled={isSaving} onClick={handleSendClicked}>
                  <Flex gap="8px" alignItems="center">
                    <Icon
                      iconName="fi-rr-megaphone"
                      style={{fontSize: '16px', width: '16px', height: '13px'}}
                    />
                    <TextG12>Send Broadcast Now</TextG12>
                  </Flex>
                </BlueButton>
                <WhiteButton
                  isDisabled={isSaving}
                  outline={`1px solid ${theme.colors.grey}`}
                  color={theme.colors.grey}
                  w="100%"
                  onClick={() => setModalOpen(false)}>
                  <TextG12>Maybe Later</TextG12>
                </WhiteButton>
              </VStack>
            )}
          </HStack>
        }>
        <>
          <VStack textAlign="center" color={theme.colors.grey} px={4} my="4px">
            <TextG14 fontWeight="500">{`Notify your neighbors of your plans via sms! Once submitted your invitation will extend to ${
              reachUserCount > 0 ? `at least ${reachUserCount || 0}` : ''
            } neighbors in the community.`}</TextG14>
            <TextG12
              whiteSpace="pre-wrap"
              mt="10px !important"
              p={2}
              border={`1px solid ${theme.colors.lightBlack}`}
              borderRadius="10px"
              lineHeight="20px">
              {removeFooterFromDescription(event.description, user?.name)}
            </TextG12>
          </VStack>
          <VStack alignItems="flex-start" my="8px">
            <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
              Broadcast To
            </TextG14>
            <RadioGroup onChange={setInviteGroup} value={inviteGroup}>
              <Stack direction="column">
                <Radio
                  colorScheme="green"
                  value="1"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <TextG14>Anyone nearby</TextG14>
                </Radio>
                <Radio
                  colorScheme="green"
                  value="2"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <Flex flexDir="row" align="center">
                    <TextG14>Insiders Club Members Only</TextG14>
                  </Flex>
                </Radio>
              </Stack>
            </RadioGroup>
          </VStack>
        </>
      </Modal>
    </>
  );
};

export default MeetupBroadcastButton;
