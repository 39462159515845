export function getMembershipGradient(membership, topBar = false) {
  const membershipColor = !membership
    ? 'white'
    : membership.id == 1
    ? `linear-gradient(180deg, #ECFCFF 0%, #E5FDF6 ${topBar ? '250%' : '100%'})`
    : `linear-gradient(180deg, #F8F9E7 0%, #FFEBF5 ${topBar ? '250%' : '100%'})`;
  return membershipColor;
}

// export function getMembershipGradient(membership, topBar = false) {
//   const membershipColor = !membership
//     ? 'white'
//     : membership.id == 1
//     ? `linear-gradient(0deg, #ECFCFF 0%, #FFFFFF ${topBar ? '90%' : '200%'})`
//     : `linear-gradient(0deg, #F8F9E7 0%, #FFFFFF ${topBar ? '90%' : '200%'})`;
//   return membershipColor;
// }

export function getMembershipColor(membership) {
  const membershipColor = !membership ? '#E02F84' : membership.id == 1 ? '#4A92A2' : '#7D3EEE';
  return membershipColor;
}

export function passIsSignature(pass) {
  return pass?.name?.includes('Signature') || pass?.title?.includes('Signature');
}
export function passIsExperience(pass) {
  return pass?.name?.includes('Experience') || pass?.title?.includes('Experience');
}
