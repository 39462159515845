import {Flex, HStack, Radio, RadioGroup, Stack, useTheme, VStack} from '@chakra-ui/react';
import React from 'react';
import {BlueButton} from '../../components/button';
import Icon from '../../components/Icon';
import {SpinnerLoader} from '../../components/Loader';
import Modal from '../../components/Modals/Modal';
import {TextA24, TextG12, TextG14} from '../../components/typography';

const BroadcastConfirmationModal = ({
  isSaving,
  description,
  reachNum,
  sendBroadcast,
  setConfirmationOpen,
  confirmationOpen,
  setInviteGroup,
  inviteGroup,
}) => {
  const theme = useTheme();

  return (
    <Modal
      closeOnOverlayClick={false}
      headerPadding="1rem 1.5rem 0 1.5rem"
      header={<TextA24 textAlign="center">Send Broadcast</TextA24>}
      isCentered
      parentCallback={() => setConfirmationOpen(false)}
      show={confirmationOpen}
      closeButton={false}
      footer={
        <HStack gap="12px" w="100%" justify="center" px={4}>
          {isSaving ? (
            <SpinnerLoader />
          ) : (
            <VStack w="100%">
              <BlueButton w="100%" isDisabled={isSaving} onClick={() => sendBroadcast(true)}>
                <Flex gap="8px" alignItems="center">
                  <Icon
                    iconName="fi-rr-megaphone"
                    style={{fontSize: '16px', width: '16px', height: '13px'}}
                  />
                  <TextG12>Send Broadcast Now</TextG12>
                </Flex>
              </BlueButton>
              <BlueButton w="100%" isDisabled={isSaving} onClick={() => sendBroadcast(false)}>
                <TextG12>Maybe Later</TextG12>
              </BlueButton>
            </VStack>
          )}
        </HStack>
      }>
      <>
        <VStack textAlign="center" color={theme.colors.grey} px={4} mb="4px">
          <TextG14 fontWeight="500">{`Notify your neighbors of your plans via sms! Once submitted your invitation will extend to ${
            reachNum > 0 ? `at least ${reachNum || 0}` : ''
          } neighbors in the community.`}</TextG14>
          <TextG12
            mt="10px !important"
            p={2}
            border={`1px solid ${theme.colors.lightBlack}`}
            borderRadius="10px"
            lineHeight="20px">
            {description}
          </TextG12>
        </VStack>
        <VStack alignItems="flex-start" my="8px">
          <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
            Broadcast To
          </TextG14>
          <RadioGroup onChange={setInviteGroup} value={inviteGroup}>
            <Stack direction="column">
              <Radio
                colorScheme="green"
                value="1"
                transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                <TextG14>Anyone nearby</TextG14>
              </Radio>
              <Radio
                colorScheme="green"
                value="2"
                transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                <Flex flexDir="row" align="center">
                  <TextG14>Insiders Club Members Only</TextG14>
                </Flex>
              </Radio>
            </Stack>
          </RadioGroup>
        </VStack>
      </>
    </Modal>
  );
};

export default BroadcastConfirmationModal;
