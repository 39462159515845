import {SimpleGrid, Image, Box, useTheme, CloseButton, Flex} from '@chakra-ui/react';
import React, {useEffect, useMemo, useState} from 'react';
import FallbackImg from '../../assets/images/fallback.jpg';
import {TextA18} from '../typography';
import Modal from '../Modals/Modal';
import FullscreenImage from './FullscreenImage';

const PhotoGallery = ({
  images,
  show,
  setShow,
}: {
  images: string[];
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [currentImg, setCurrentImg] = useState<string>();
  const theme = useTheme();

  const modalBody = useMemo(
    () => (
      <SimpleGrid columns={2} spacing={1} px={4} pb={4} justifyItems="center">
        {images.map((image, i) => (
          <Image
            fallbackStrategy="onError"
            key={i}
            onClick={() => {
              setShowFullscreen(true);
              setCurrentImg(image);
            }}
            fallbackSrc={FallbackImg}
            src={image}
            alt="cover image"
            width="165px"
            height="165px"
            objectFit="cover"
          />
        ))}
      </SimpleGrid>
    ),
    [images]
  );


  useEffect(() => {
    if (showFullscreen) {
      setTimeout(() => setShowClose(showFullscreen), 150);
    } else {
      setShowClose(showFullscreen);
    }
  }, [showFullscreen]);

  return (
    <>
      <FullscreenImage show={showFullscreen} setShow={setShowFullscreen} image={currentImg} />
      <Modal
        scrollBehavior="inside"
        header={<TextA18 textAlign="center">Gallery</TextA18>}
        children={modalBody}
        show={show}
        parentCallback={() => setShow(false)}
        modalPadding={0}
        closeButton
      />
    </>
  );
};

export default PhotoGallery;