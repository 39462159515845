import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  HStack,
  Image,
  useTheme,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import ActiveRedemptionCard from '../../components/Articles/Offers/ActiveRedemptionCard';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import {TextG14} from '../../components/typography';
import useActiveRedemptions from '../../hooks/useActiveRedemptions';
import {Offer, Place, Event} from '../../types/acrticle';

const OfferAccordion = ({offers, place, event}: {offers: Offer[]; place?: Place, event?: Event}) => {
  const theme = useTheme();
  const firstOffers = offers.slice(0, 3);
  const {getActiveRedemption, isActiveOffer} = useActiveRedemptions();

  if (!offers || offers.length == 0) {
    return null;
  }

  if (offers.length == 1) {
    const item = offers[0];
    return (
      <>
        {/* <ActiveRedemptionCard
          offer={item as Offer}
          activeRedemption={getActiveRedemption(item as Offer)}
          hideCard={!isActiveOffer(item as Offer)}
        /> */}
        <OfferCard
          place={place}
          offer={item as Offer}
          event={event}
          // hideActive={(item as Offer).countdown_required}
        />
      </>
    );
  }

  return (
    <Accordion mt="5px !important" w="100%" allowMultiple={true} borderColor="transparent">
      <AccordionItem pb={2} borderColor="transparent">
        {/* {({isExpanded}) => (
          <> */}
        <Flex
          alignItems="center"
          position="relative"
          justifyContent="space-between"
          px="15px"
          py="10px"
          boxShadow="0px 4px 20px rgba(0, 0, 0, 0.15)"
          borderRadius="7px">
          {/* <Box
                px="20px"
                py="10px"
                width="100%"
                opacity={isExpanded ? 0 : 1}
                height={isExpanded ? 0 : '70px'}
                overflow="hidden"
                transition="opacity 0.33s, height 0.33s"
                boxShadow="0px 4px 20px rgba(0, 0, 0, 0.15)"
                borderRadius="7px"> */}
          <HStack gap="10px">
            {firstOffers.map((item) => {
              return (
                <Image
                  margin="0 !important"
                  key={item.id}
                  objectFit="cover"
                  height="-webkit-fill-available"
                  borderRadius="10px"
                  w="50px"
                  h="50px"
                  src={(item as Offer).image_url}
                />
              );
            })}
            {offers && offers.length > 3 && (
              <TextG14 fontWeight="500" color={theme.colors.rose}>{`+ ${
                offers.length - 3
              } more`}</TextG14>
            )}
          </HStack>
          {/* </Box> */}
          <AccordionButton
            cursor="none"
            _hover={{}}
            position="absolute"
            right="10px"
            height="70%"
            justifyContent="flex-end"
            p={0}>
            <AccordionIcon _hover={{}} />
          </AccordionButton>
        </Flex>

        <Box maxH="450px" overflow="scroll">
          {offers.map((item) => {
            return (
              <AccordionPanel px={2} key={item.id} pb={0} pt="15px">
                {/* <ActiveRedemptionCard
                  offer={item as Offer}
                  activeRedemption={getActiveRedemption(item as Offer)}
                  hideCard={!isActiveOffer(item as Offer)}
                /> */}
                <OfferCard
                  place={place}
                  offer={item as Offer}
                  event={event}
                  // hideActive={(item as Offer).countdown_required}
                />
              </AccordionPanel>
            );
          })}
        </Box>
        {/* </>
        )} */}
      </AccordionItem>
    </Accordion>
  );
};

export default OfferAccordion;
