import React, {useMemo} from 'react';
import {Box, Flex, Image, useTheme} from '@chakra-ui/react';
import styled from 'styled-components';
import FallbackImg from '../../assets/images/fallback.jpg';
import ArticleInfo from '../Articles/ArticleInfo';
import ExclusiveCommunityLabel from '../ExclusiveCommunityLabel';
import {TextG8} from '../typography';
import {toLower} from 'lodash';
import useTrackEvent from '../../hooks/useTrackEvent';
import dayjs from 'dayjs';

// const Cover = styled(Image)`
//   width: 100%;
//   height: 200px;
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
//   object-fit: cover;
// `;

const Cover = styled(Image)`
  min-width: 85px;
  max-width: 85px;
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  object-fit: cover;
  border-radius: ${(props) => props.borderRadius};
`;

const ChildContainer = styled(Box)`
  width: calc(100% - 95px);
  border-radius: ${(props) => props.borderRadius};
  z-index: 2;
`;

const Card = ({
  children,
  coverImage,
  leftTopItem,
  rightTopItem,
  height,
  onClick,
  borderRadius = '12px',
  childTop = '',
  isSlide = false,
  article,
  minHeight = '85px',
  maxHeight = '85px',
  outline,
  childMarginTop,
}) => {
  const theme = useTheme();
  const {addUserEvent} = useTrackEvent();

  const isExclusive = article?.communities?.length > 0 && article.is_private;
  const hasCardBadge = article?.is_walkabout_official || !!article?.partner_id;

  return (
    <Box w="100%" mt={isExclusive ? '-8px' : 0}>
      {isExclusive ? (
        <ExclusiveCommunityLabel
          community={article.communities[0]}
          isSlide={isSlide}
          hasCardBadge={hasCardBadge}
        />
      ) : null}
      <Box
        outline={outline}
        background="white"
        height={`${isSlide ? '97.5%' : height}`}
        mt={`${isSlide && !isExclusive ? '1.25%' : '0%'}`}
        width={`${isSlide ? '97.5%' : '100%'}`}
        ml={`${isSlide ? '1.25%' : '0%'}`}
        p="12px"
        boxShadow="0px 2px 8px 0px #00000026"
        position="relative"
        borderRadius={borderRadius}
        onClick={() => {
          onClick ? onClick() : null;

          const articleType = article.is_meetup
            ? 'meetup'
            : article.type?.name
            ? 'place'
            : toLower(article.type);

          const articleId = article.id;

          addUserEvent({
            type: 'click',
            event_name: 'card_click',
            event_time: dayjs().format(),
            data: {itemType: articleType, itemId: articleId},
          });
        }}>
        <Flex flexDir="row" gap="9px" mt={childMarginTop ? childMarginTop : 0}>
          {coverImage && (
            <Box pos="relative">
              <Cover
                minHeight={minHeight}
                maxHeight={maxHeight}
                borderRadius={borderRadius}
                fallbackStrategy="onError"
                loading={null}
                fallbackSrc={FallbackImg}
                src={coverImage}
                alt={'Cover image'}
              />
              <ArticleInfo 
                article={article} 
                bottom="-8px" 
                left="50%" 
                transform="translateX(-50%)" 
                ArticleTypeFont={TextG8} 
              />
            </Box>
          )}
          {rightTopItem}
          <ChildContainer borderRadius={borderRadius} top={childTop}>
            {children}
          </ChildContainer>
        </Flex>
      </Box>
    </Box>
  );
};

Card.defaultProps = {
  coverImage: null,
  leftTopItem: null,
  rightTopItem: null,
  childPaddingX: 4,
  childPaddingY: 3,
  height: '45vw',
  outline: null,
  onClick: () => null,
  childMarginTop: null,
};

export default Card;
