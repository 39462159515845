import React, {useState} from 'react';
import {
  FormControl,
  FormLabel,
  Input as CInput,
  Textarea,
  InputProps,
  InputGroup,
  InputRightElement,
  FormHelperText,
  FormErrorMessage,
  useTheme,
  Image,
  InputLeftElement,
} from '@chakra-ui/react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled, {css} from 'styled-components';
import {isEmpty} from 'lodash';
import warningIcon from '../../assets/images/warning.png';
import errorIcon from '../../assets/images/error.png';
import successIcon from '../../assets/images/success.png';
import PropTypes from 'prop-types';
import {useEffect} from 'react';

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  input:focus {
    outline: none;
  }
  ${(props) =>
    !props.noBorder
      ? props.$roundBorder
        ? css`
            border: 1px solid ${props.theme.colors.lighterGrey};
            border-radius: 12px;
            padding-left: 12px;
            padding-right: 12px;
          `
        : css`
            border-bottom: 0.5px solid #c4c4c4;
          `
      : css`
          border-bottom: none !important;
        `};

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: Gordita;
  line-height: 20px;
`;

export const StyledLabel = styled(FormLabel)`
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;

const StyledInput = styled(CInput)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: Gordita;
  line-height: 20px;
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important;
  ${(props) =>
    !props.noBorder
      ? css`
          border-bottom: 0.5px solid ${(props) => props.color || 'none'};
        `
      : css`
          border-bottom: none !important;
        `};
  border-radius: unset !important;
`;

const StyledTextArea = styled(Textarea)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: Gordita;
  line-height: 20px;
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important;
  ${(props) =>
    !props.noBorder
      ? css`
          border-bottom: 0.5px solid ${(props) => props.color || 'none'};
        `
      : css`
          border-bottom: none !important;
        `};
  border-radius: unset !important;
`;

interface InputType extends InputProps {
  label: string;
  placeholder?: string;
  helperText?: string;
  error?: string;
  onBlur?: (e?: any) => any;
  validationStatusIcon?: boolean;
  validate?: (val: any) => boolean;
  noBorder?: boolean;
  LeftIcon?: JSX.Element;
  LeftIconProps?: InputProps;
  RightIcon?: JSX.Element;
  RightIconProps?: InputProps;
  RightIconOnClick?: () => Promise<void> | void;
  typeOfInput?: 'default' | 'textArea' | 'phoneNumber';
  isVerified?: boolean;
  isRequired?: boolean;
  roundBorder?: boolean;
  fontSize?: string | number;
}

function Input({
  id,
  error,
  label,
  placeholder,
  helperText,
  onBlur,
  validationStatusIcon,
  validate,
  noBorder,
  LeftIcon,
  LeftIconProps,
  RightIcon,
  RightIconProps,
  RightIconOnClick,
  typeOfInput,
  isVerified,
  isRequired,
  roundBorder,
  fontSize,
  ...props
}: InputType) {
  const [inputVal, setInputVal] = useState(props.value || '');
  const [isFocus, setIsFocus] = useState(false);
  const [isValid, setIsValid] = useState(inputVal || false);
  const [isError, setIsError] = useState(false);
  const theme = useTheme();

  const validateHandler = (val: string): boolean =>
    !(typeof validate === 'function' && !validate(val));

  useEffect(() => {
    if (validationStatusIcon && isVerified !== undefined) setIsError(!isVerified);
  }, [validationStatusIcon, isVerified]);

  const blurHandler = (e) => {
    const value = e.target.value;

    if (typeof onBlur === 'function') {
      const newValue = onBlur(inputVal);
      setInputVal(newValue);
    }

    if (!isEmpty(value)) {
      // HTML validation
      if (!e.target.checkValidity()) {
        e.target.reportValidity();
        setIsValid(false);
        setIsFocus(false);
        return;
      }

      // CUSTOM validation
      if (validateHandler(value)) {
        setIsValid(true);
      }
    }

    setIsFocus(false);
  };

  const InvalidMsgHandler = () => {
    if (isVerified !== undefined) setIsError(!isVerified);
  };

  return (
    <FormControl isInvalid={isError}>
      {label && (
        <StyledLabel fontSize="10px" mb={0} color={theme.colors.grey}>
          {label}
        </StyledLabel>
      )}
      <InputGroup>
        {validationStatusIcon &&
          ((isError && (
            <InputRightElement
              h="full"
              className="InputLeft"
              pointerEvents="none"
              children={<Image src={errorIcon} alt="Error" />}
            />
          )) ||
            (isValid && (
              <InputRightElement
                h="full"
                className="InputLeft"
                pointerEvents="none"
                children={<Image src={successIcon} alt="Success" />}
              />
            )))}
        {LeftIcon && (
          <InputLeftElement h="full" pointerEvents="none" children={LeftIcon} {...LeftIconProps} />
        )}
        {RightIcon && (
          <InputRightElement
            h="full"
            pointerEvents={RightIconOnClick ? 'unset' : 'none'}
            onClick={RightIconOnClick}
            children={RightIcon}
            {...RightIconProps}
          />
        )}
        {typeOfInput === 'textArea' && (
          <StyledTextArea
            id={id}
            value={inputVal}
            pl={0}
            color={theme.colors.darkGrey}
            placeholder={placeholder}
            $noBorder={noBorder}
            onChange={({target: {value}}) => setInputVal(value)}
            onBlur={blurHandler}
            onInvalid={InvalidMsgHandler}
            {...props}
          />
        )}
        {typeOfInput === 'phoneNumber' && (
          <StyledPhoneInput
            style={{fontSize: `${fontSize}`}}
            placeholder={placeholder}
            theme={theme}
            $roundBorder={roundBorder}
            value={inputVal}
            onChange={(value) => setInputVal(value ? value.toString() : '')}
            defaultCountry="US"
            color={theme.colors.darkGrey}
            $noBorder={noBorder}
            required={isRequired}
            {...props}
          />
        )}
        {typeOfInput === 'default' && (
          <StyledInput
            _placeholder={props._placeholder}
            id={id}
            value={inputVal}
            pl={0}
            color={theme.colors.darkGrey}
            placeholder={placeholder}
            onChange={({target: {value}}) => setInputVal(value)}
            $noBorder={noBorder}
            onBlur={blurHandler}
            onInvalid={InvalidMsgHandler}
            required={isRequired}
            {...props}
          />
        )}
      </InputGroup>
      {!isError && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText fontSize="12px">{helperText}</FormHelperText>}
    </FormControl>
  );
}

Input.defaultProps = {
  type: 'text',
  typeOfInput: 'default',
  error: '',
  label: null,
  placeholder: null,
  helperText: null,
  noBorder: false,
  validationStatusIcon: false,
  validate: (e) => e,
  onBlur: (e) => e,
};

StyledInput.propTypes = {
  noBorder: PropTypes.bool,
};

StyledTextArea.propTypes = {
  noBorder: PropTypes.bool,
};

export default Input;
