import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useTheme,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import ConfettiExplosion, {ConfettiProps} from 'react-confetti-explosion';
import styled from 'styled-components';
import WidgetVideoPlayer from '../../components/GuidedExperienceWidget/WidgetVideoPlayer';
import {TextA20, TextG12} from '../../components/typography';
import {BlueButton} from '../../components/button';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const StyledConfetti = styled(ConfettiExplosion)`
  position: absolute;
  top: 0%;
  left: 50%;
`;

const SuccessModal = ({membership, isOpen, setIsOpen}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {reFetchUserData} = useAuth();
  const [videoPlaying, setVideoPlaying] = useState(false);
  const largeExplosionProps: ConfettiProps = {
    force: 1,
    duration: 5000,
    particleCount: 250,
    width: 800,
    colors: [theme.colors.rose, theme.colors.blue],
  };

  const onVideoEnd = () => {
    // Get the current path without query parameters
    const currentPath = location.pathname;
    // Navigate to the current path without query parameters
    navigate(currentPath, {replace: true});
    setIsOpen(false);

    reFetchUserData();
  };

  const backgroundGradient =
    membership?.id == 1
      ? 'linear-gradient(180deg, #EAFFFB 0%, #FFFFFF 100%)'
      : 'linear-gradient(180deg, #F6F1FF 0%, #FFFFFF 100%)';

  return (
    <Modal isOpen={isOpen} onClose={onVideoEnd}>
      <ModalOverlay />
      <ModalContent mx={4}>
        <Box
          borderRadius="10px"
          position="absolute"
          top="0px"
          background={backgroundGradient}
          height="100%"
          zIndex="-1"
          w="100%"
        />
        {/* <ModalCloseButton background="transparent" _focus={{boxShadow: 'none'}} /> */}
        <ModalBody my={4} mb={6}>
          <StyledConfetti zIndex={1500} {...largeExplosionProps} />
          <Flex flexDir="column">
            <Center flexDir="column" mt={2}>
              <Flex alignItems="baseline">
                <TextA20 fontWeight="700" color={theme.colors.rose}>
                  You just leveled up! 🥳
                </TextA20>
              </Flex>
              <TextG12 fontWeight="500" textAlign="center" mt={2} mx={4}>
                Check out the video below to learn how to use your new membership!
              </TextG12>
            </Center>
            <Box mt={5} mb="15px" w="100%" h="100%" style={{aspectRatio: '9/16'}}>
              <WidgetVideoPlayer
                url={'https://www.youtube.com/shorts/DBDyV_UFZBM'}
                playing={videoPlaying}
                onVideoEnd={onVideoEnd}
                setVideoPlaying={setVideoPlaying}
                controls={false}
              />
            </Box>
            <BlueButton
              border={videoPlaying ? '0px solid transparent' : `1px solid ${theme.colors.blue}`}
              color={videoPlaying ? theme.colors.blue : 'white'}
              background={videoPlaying ? 'white' : theme.colors.blue}
              _focus={{boxShadow: 'none'}}
              _hover={{boxShadow: 'none'}}
              _active={{boxShadow: 'none'}}
              borderRadius="7px"
              onClick={() => {
                if (!videoPlaying) setVideoPlaying(true);
                else {
                  onVideoEnd();
                }
                // // Get the current path without query parameters
                // const currentPath = location.pathname;
                // // Navigate to the current path without query parameters
                // navigate(currentPath, {replace: true});
                // setIsOpen(false);
              }}>
              <TextG12 fontSize={videoPlaying ? '10px' : '12px'}>
                {!videoPlaying ? `Let's go!` : `Skip For Now`}
              </TextG12>
            </BlueButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
