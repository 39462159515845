import React, {useMemo} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Tab, TabList, Tabs, useTheme} from '@chakra-ui/react';
import {isNumber} from 'lodash';
import useAuth from '../../../hooks/useAuth';
import {isStandaloneSearchPage} from "../../../utils/GlobalSearch/helpers";

const StyledTabList = styled(TabList)`
  gap: 6px;
  overflow-x: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const StyledTab = styled(Tab)`
  margin-right: 5px;
  height: 40px;
`;

type TabTypes = 'post' | 'event' | 'place' | 'offer' | 'partner';
export type TabsInterface = {
  [index in TabTypes]: {
    tabName: string;
    tabElement: JSX.Element;
    title?: string;
    subtitle?: string;
    endpoint?: (...params) => Promise<any>;
    endpointParams?: Record<string, number | string | number[] | string[]>;
    renderArticleType?: boolean;
    hook?: any;
  };
};

const ArticleSearchNavigation = ({
  articleTabs,
  selectedIndex,
}: {
  articleTabs: any;
  selectedIndex?: number;
}) => {
  const theme = useTheme();
  const {type} = useParams();
  const {user} = useAuth();

  if(isStandaloneSearchPage(location)) {
    return null;
  }

  const tabs = useMemo(() => {
    return Object.keys(Object.assign(articleTabs));
  }, []);

  return (
    <Tabs
      isLazy={true}
      index={isNumber(selectedIndex) ? selectedIndex : type ? tabs.indexOf(type) : 0}
      isFitted
      size="sm"
      variant="solid-rounded"
      borderBottom={`1px solid ${theme.colors.lighterGrey}`}>
      <StyledTabList
        overflowY="hidden"
        sx={{
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        {tabs.map((tab) =>
          articleTabs[tab].tabName == 'Meet-Ups' && user ? null : (
            <StyledTab
              theme={theme}
              px={1}
              key={tab}
              _selected={{
                borderRadius: '0',
                borderBottom: `3px solid ${theme.colors.blue}`,
                color: theme.colors.blue,
              }}>
              <Link style={{whiteSpace: 'nowrap'}} to={`/search/${tab}`}>
                {articleTabs[tab].tabName}
              </Link>
            </StyledTab>
          )
        )}
      </StyledTabList>
    </Tabs>
  );
};

export default ArticleSearchNavigation;
