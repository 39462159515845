import React, {useEffect, useMemo, useRef} from 'react';
import {Box, Center, Flex, HStack, Spacer, Stack, useTheme, Wrap, WrapItem} from '@chakra-ui/react';
import dayjs from 'dayjs';
import Card from '../../Card';
import {TextG10, TextG12, TextG14} from '../../typography';
import {generatePath, Link, useLocation} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Post} from '../../../types/acrticle';
import styled from 'styled-components';
import ArticleSocialIcon from '../ArticleSocialIcon';
import CardInterestTags from '../CardInterestTags';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import useAuth from '../../../hooks/useAuth';
import NonPremiumCard from '../../Card/NonPremiumCard';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import {ConditionalWrapper} from '../../ConditionalWrapper';

const NoWrapText = styled(TextG10)`
  word-wrap: break-word !important;
`;

const NonPremiumPostCard = ({
  post,
  leftTopItem,
  rightTopItem,
  height,
  modal,
  borderRadius = '12px',
  isFeatured = false,
  isSlide = false,
  onClick,
  isPreview = false,
}: {
  post?: Post;
  leftTopItem?: any;
  rightTopItem?: any;
  height?: string;
  modal?: boolean;
  borderRadius?: string | undefined;
  isFeatured?: boolean;
  isSlide?: boolean;
  onClick?: () => void;
  isPreview?: boolean;
}) => {
  if (!post) return null;
  const theme = useTheme();
  const cardChildContent = useRef<HTMLDivElement>(null);
  const {user} = useAuth();
  const [ref, {wasEverVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();
  const {pathname} = useLocation();

  useEffect(() => {
    if (wasEverVisible && post?.id.toString()) {
      addVisibleArticle({id: post.id.toString(), type: 'post'});
    }
  }, [wasEverVisible]);

  const image = useMemo(() => post?.image_url, [post]);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: function (number, withoutSuffix) {
        return withoutSuffix ? 'now' : 'a few seconds';
      },
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mth',
      MM: '%dmth',
      y: '1y',
      yy: '%dy',
    },
  });

  return (
    <ConditionalWrapper
      condition={!onClick}
      wrapper={(children) => (
        <Link
          style={{width: '100%'}}
          to={
            isPreview
              ? ''
              : modal
              ? {
                  pathname,
                  search: `?id=${post.id.toString()}&articleType=${
                    typeof post?.type === 'string' ? post.type.toLowerCase() : 'post'
                  }`,
                }
              : generatePath(ROUTES.POST, {
                  postId: post.id.toString(),
                })
          }
          onClick={(e) => {
            storeScrollPositionInSession();
            e.stopPropagation();
          }}
          ref={ref}>
          {children}
        </Link>
      )}>
      <NonPremiumCard
        onClick={onClick}
        article={post}
        isSlide={isSlide}
        borderRadius={borderRadius}
        childPaddingX={4}
        coverImage={image}
        leftTopItem={leftTopItem}
        rightTopItem={rightTopItem}
        height={'auto'}>
        <Box
          ref={cardChildContent}
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          height="100%">
          <Stack gap={2}>
            {isFeatured && post?.released_at && (
              <NoWrapText color={theme.colors.black}>
                {dayjs.utc(post?.released_at).fromNow(true)}
              </NoWrapText>
            )}
            <TextG14
              pr="30px"
              fontWeight="700"
              mt={'0 !important'}
              isTruncated="&hellip;"
              noOfLines={3}
              mb={1}
              color={theme.colors.black}
              dangerouslySetInnerHTML={{__html: post.title || post.description}}
              children={undefined}
            />
          </Stack>
          <Flex justifyContent="flex-end">
            {!isFeatured && post.topics_of_interest && post.topics_of_interest.length > 0 && (
              <>
                <CardInterestTags
                  autoScroll={true}
                  tagsVisible={true}
                  onlyShowMatched={false}
                  topicsOfInterest={post.topics_of_interest}
                />
                <Spacer />
              </>
            )}
            {!isFeatured && post?.released_at && (
              <NoWrapText color={theme.colors.black}>
                {dayjs.utc(post?.released_at).fromNow(true)}
              </NoWrapText>
            )}
          </Flex>
        </Box>
      </NonPremiumCard>
    </ConditionalWrapper>
  );
};

export default NonPremiumPostCard;
