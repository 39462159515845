import {
  Box,
  Center,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BlueButton} from '../../../components/button';
import Icon from '../../../components/Icon';
import {TextA12, TextA18, TextG10, TextG12} from '../../../components/typography';
import styled from 'styled-components';
import {fetchMembershipStripLink, fetchMembershipUpgradeStripLink} from '../../../endpoints/api';
import PassCard from '../../MyPasses/PassCard';
import {useFetchMemberships} from '../../../hooks/useMembershipPlans';

const StyledModalContent = styled(Box)`
  .chakra-modal__content-container {
    overflow: hidden !important;
  }
`;

const UpgradeModal = ({user, show, setShow, event}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {data, isLoading} = useFetchMemberships();
  const memberships = data?.data;
  const lifestyleMembership = memberships && memberships.length > 0 && memberships[1];

  const {isOpen, onOpen, onClose} = useDisclosure();
  useEffect(() => (show ? onOpen() : onClose()), [show]);

  const [stripeLoading, setStripeLoading] = useState(false);

  if (isLoading || stripeLoading)
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  if (!lifestyleMembership) return null;

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => setShow(false)}>
      <ModalOverlay />
      <StyledModalContent>
        <ModalContent px={6} pb="24px" background="white">
          <ModalCloseButton
            mt={1}
            mr={0}
            background="transparent"
            fontSize="16px"
            _focus={{boxShadow: 'none'}}
          />
          <ModalHeader pt={6} pr="40px" pl={0} color={theme.colors.rose}>
            <TextA18 color="#7D3EEE" fontWeight="700">
              Upgrade to Local Neighborhood Insider
            </TextA18>
            <TextG12 fontWeight="400" pt="10px" color={theme.colors.darkGrey}>
              Get a Signature pass and other premium benefits worth $300 every month!
            </TextG12>
          </ModalHeader>
          <PassCard
            showArrow={false}
            showGetMore={false}
            pass={{
              title: 'Signature Pass',
              description:
                'Used for exclusive, locally curated events that bring the community together in a unique way -- from yacht parties to behind-the-scenes culinary experiences.',
            }}
          />

          <Center mt="20px">
            <Icon iconName="fi-br-plus" />
          </Center>

          <TableContainer mt={4} px={5} maxW="100%" overflowY="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th py={0} px="0px" pb="10px">
                    <TextA12 textTransform="none" letterSpacing="-0.02em" fontWeight="700">
                      Benefits
                    </TextA12>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td pl="0px" pr={1} borderBottom="none">
                    <TextG10 fontSize="11px" letterSpacing="-0.02em" fontWeight="500">
                      Experience Passes / month
                    </TextG10>
                  </Td>
                  <Td px="10px" borderBottom="none" background="#7D3EEE1A">
                    <TextG12
                      transition="font-weight 0.3s ease, color 0.3s ease"
                      textAlign="center"
                      color="#7D3EEE"
                      fontWeight="700">
                      2
                    </TextG12>
                  </Td>
                </Tr>
                <Tr>
                  <Td pl="0px" pr={1} borderBottom="none">
                    <TextG10 fontSize="11px" letterSpacing="-0.02em" fontWeight="500">
                      Event Discounts
                    </TextG10>
                  </Td>
                  <Td px="10px" borderBottom="none" background="#7D3EEE1A">
                    <TextG12
                      transition="font-weight 0.3s ease, color 0.3s ease"
                      textAlign="center"
                      color="#7D3EEE"
                      fontWeight="700">
                      {Number(lifestyleMembership.event_discount).toFixed(0)}%
                    </TextG12>
                  </Td>
                </Tr>
                <Tr>
                  <Td pl="0px" pr={1} borderBottom="none">
                    <TextG10 fontSize="11px" letterSpacing="-0.02em" fontWeight="500">
                      Service Discounts
                    </TextG10>
                  </Td>
                  <Td px="10px" borderBottom="none" background="#7D3EEE1A">
                    <TextG12
                      transition="font-weight 0.3s ease, color 0.3s ease"
                      textAlign="center"
                      color="#7D3EEE"
                      fontWeight="700">
                      {Number(lifestyleMembership.service_discount).toFixed(0)}%
                    </TextG12>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Center>
            <BlueButton
              background="#7D3EEE"
              borderRadius="7px"
              w="100%"
              onClick={() => {
                setStripeLoading(true);
                const cancelLink = event
                  ? `${location.origin}/event/${event.id}/tickets`
                  : location.origin;
                fetchMembershipStripLink(2, cancelLink).then((ret) => {
                  if (ret.data.link) {
                    window.location.href = ret.data.link;
                  } else {
                    setStripeLoading(false);
                  }
                });
              }}
              mt="20px">
              Upgrade to Local Neighborhood Insider
            </BlueButton>
          </Center>
        </ModalContent>
      </StyledModalContent>
    </Modal>
  );
};

export default UpgradeModal;
