import {Box, Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import Icon from '../../components/Icon';
import {TextG10, TextG12, TextG9} from '../../components/typography';
import styled from 'styled-components';

const Banner = styled.div`
  position: absolute;
  top: 1.1rem;
  right: 33%;
  background-color: white;
  // color: black;
  font-size: 12px;
  font-weight: bold;
  //padding: 3px 8px;
  border-radius: 7px;
  //transform: rotate(15deg);
  z-index: 2;
  // border: 1px solid black;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); // Black with alpha for transparency
  z-index: 1; // Under the banner but over the button
  pointer-events: none; // Allows clicks to pass through to disabled button
  border-radius: 10px;
`;

const BenefitCard = ({title, description, icon, onClick, comingSoon = false}) => {
  const theme = useTheme();

  return (
    <Box
      borderRadius="10px"
      background="white"
      position="relative"
      boxShadow="0px 4px 25px 0px #0000001A"
      onClick={onClick}
      pl="40px"
      pr="65px"
      py={4}>
      {comingSoon && (
        <>
          <Banner>
            <TextG10 fontWeight="400" color={theme.colors.grey}>
              Coming Soon!
            </TextG10>
          </Banner>
          {/* <Overlay /> */}
        </>
      )}
      <Box position="absolute" top={4} left={3}>
        {icon}
      </Box>
      {!comingSoon && (
        <Box position="absolute" top="40%" right={3}>
          <Icon iconName="fi-br-angle-right" />
        </Box>
      )}
      <Flex gap="8px" flexDir="column">
        <TextG12 fontWeight="700" color={comingSoon ? theme.colors.grey2 : ''}>
          {title}
        </TextG12>
        <TextG10 color={comingSoon ? theme.colors.grey2 : ''}>{description}</TextG10>
      </Flex>
    </Box>
  );
};

export default BenefitCard;
