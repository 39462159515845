import React, {useEffect, useState} from 'react';
import {
  Center,
  Flex,
  HStack,
  useTheme,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Checkbox,
} from '@chakra-ui/react';
import {TextA18, TextG10, TextG12, TextG14} from '../../../../components/typography';
import {StyledInput} from '..';
import {StyledLabel} from '../../../../components/Input';
import {BlueButton} from '../../../../components/button';
import StateSelect from '../../../../components/StateSelect';
import {
  useAddressTypes,
  useAddUserAddress,
  useGetUserAddresses,
  useUpdateUserAddress,
} from '../../../../hooks/useUserAddress';
import {find} from 'lodash';
import useAuth from '../../../../hooks/useAuth';
import {SpinnerLoader} from '../../../../components/Loader';
import Icon from '../../../../components/Icon';
import {Tooltip} from 'react-tooltip';
import styled from 'styled-components';
import Modal from '../../../../components/Modals/Modal';
import {addressesMatch, AddressTypes} from '../../../../types/address';
import home from "../../../Home";

const ZIP_LENGTH = 5;

const StyledTooltip = styled(Tooltip)`
  transition: opacity 0.33s ease-out, transform 0.33s ease-out;
  z-index: 1600;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};

  border-radius: 7px;
  background: white;
  color: black;
  border: 1px solid grey;
  pointer-events: auto;
  width: 80%;
  max-width: 80%;
  padding: 14px 16px;

  .arrow-style {
    margin-left: -8px;
    z-index: -1;
    background-color: white !important;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    bottom: -9px !important;
  }
`;

const stateSelectStyle = {
  container: (baseStyles, state) => ({
    ...baseStyles,
    maxHeight: '32px',
    marginTop: '0px !important',
    borderBottom: '1px solid rgb(226, 232, 240)',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    width: '100px',
    backgroundColor: 'transparent',
    borderWidth: '0px',
  }),
  indicatorSeparator: () => ({
    width: '0px',
  }),
  loadingIndicator: (baseStyles, state) => ({
    ...baseStyles,
    display: 'none',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginBottom: '0px',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    maxHeight: '155px',
    fontSize: '10px',
    fontFamily: 'Gordita',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '14px',
    fontFamily: 'Gordita',
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    paddingLeft: '0px',
  }),
};

type FieldError = {[index: string]: string};

const EditAddress = ({
  addressType,
  header,
  showTooltip = false,
}: {
  addressType: string;
  header: string;
  showTooltip?: boolean;
}) => {
  const {user} = useAuth();
  const theme = useTheme();
  const [addressChanged, setAddressChanged] = useState(false);
  const [address, setAddress] = useState<any>();
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<FieldError>({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const isFenton = user?.isFenton;

  const {getIdForAddressType, addressTypesIsLoading} = useAddressTypes();
  const addUserAddress = useAddUserAddress();
  const updateUserAddress = useUpdateUserAddress();

  const {userAddresses, userAddressesLoading} = useGetUserAddresses({userId: user?.id});
  const currentAddress = find(userAddresses, {type_id: getIdForAddressType(addressType)});
  const homeAddress = find(userAddresses, {type_id: getIdForAddressType(AddressTypes.Home)});

  const typeString = addressType === AddressTypes.Home ? 'shipping' : 'billing';

  const matchesHome =
    addressType !== AddressTypes.Home &&
    homeAddress &&
    currentAddress &&
    addressesMatch(homeAddress, currentAddress);

  const [sameAsHomeChecked, setSameAsHomeChecked] = useState(false);

  useEffect(() => {
    if (
      addressType !== AddressTypes.Home &&
      homeAddress &&
      currentAddress &&
      addressesMatch(homeAddress, currentAddress)
    ) {
      setSameAsHomeChecked(true);
    } else {
      setSameAsHomeChecked(false);
    }
  }, [homeAddress, currentAddress]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.grey,
      fontSize: '14px',
    }),
    [theme]
  );

  const setFieldErrors = (field, error) => {
    setErrors({
      ...errors,
      [field]: error,
    });
  };

  useEffect(() => {
    if (address)
      isEmpty(address.street_address)
        ? setFieldErrors('street_address', 'Street Address field is empty')
        : setFieldErrors('street_address', '');
  }, [address?.street_address]);

  useEffect(() => {
    if (address)
      isEmpty(address.city) || isEmpty(address.city.name)
        ? setFieldErrors('city', 'City field is empty')
        : setFieldErrors('city', '');
  }, [address?.city]);

  useEffect(() => {
    if (address)
      isEmpty(address.state)
        ? setFieldErrors('state', 'State field is empty')
        : setFieldErrors('state', '');
  }, [address?.state]);

  useEffect(() => {
    if (address)
      isEmpty(address.zip_code)
        ? setFieldErrors('zip_code', 'Zip Code field is empty')
        : setFieldErrors('zip_code', '');
  }, [address?.zip_code]);

  const saveClicked = async () => {
    if (isFenton && homeAddress && addressType == AddressTypes.Home) {
      setConfirmationOpen(true);
    } else {
      await handleSaveAddress();
    }
  };

  const handleCopyHomeAddress = async (e) => {
    if (matchesHome) return;
    const currentAddress = find(userAddresses, {type_id: getIdForAddressType(addressType)});

    setIsSaving(true);
    if (user?.id) {
      if (currentAddress) {
        if (homeAddress && homeAddress.street_address && homeAddress.state) {
          const typeId = getIdForAddressType(addressType);
          await updateUserAddress.mutateAsync({
            userId: user?.id,
            addressId: currentAddress.id,
            address: {
              ...homeAddress,
              type_id: typeId,
              state_id: homeAddress.state.id,
              city: homeAddress.city.name,
            },
          });
          setAddressChanged(false);
        }
      } else {
        if (homeAddress && homeAddress.street_address && homeAddress.state) {
          const typeId = getIdForAddressType(addressType);
          await addUserAddress.mutateAsync({
            userId: user?.id,
            address: {
              ...homeAddress,
              type_id: typeId,
              state_id: homeAddress.state.id,
              city: homeAddress.city.name,
            },
          });
        }
        setAddressChanged(false);
      }
    }
    setIsSaving(false);
  };

  const handleSaveAddress = async () => {
    if (!address.zip_code || address.zip_code.length < ZIP_LENGTH) {
      setFieldErrors('zip', 'Zip must be at least 5 numbers');
      return;
    } else {
      setFieldErrors('zip', '');
    }
    const currentAddress = find(userAddresses, {type_id: getIdForAddressType(addressType)});
    setIsSaving(true);
    if (user?.id) {
      if (currentAddress) {
        if (address && address.street_address && address.state) {
          await updateUserAddress.mutateAsync({
            userId: user?.id,
            addressId: currentAddress.id,
            address: {...address, state_id: address.state.id, city: address.city.name},
          });
          setAddressChanged(false);
        }
      } else {
        if (address && address.street_address && address.state) {
          const typeId = getIdForAddressType(addressType);
          await addUserAddress.mutateAsync({
            userId: user?.id,
            address: {
              ...address,
              type_id: typeId,
              state_id: address.state.id,
              city: address.city.name,
            },
          });
        }
        setAddressChanged(false);
      }
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (!userAddressesLoading && !addressTypesIsLoading) {
      setAddress(find(userAddresses, {type_id: getIdForAddressType(addressType)}));
    }
  }, [userAddresses, addressTypesIsLoading]);

  return (
    <Accordion borderRadius="12px" background={theme.colors.veryLightBlue} allowMultiple>
      <AccordionItem borderColor="transparent">
        <AccordionButton py={3} _hover={{}} justifyContent="space-between">
          <Flex align="center" gap={2}>
            {addressTypesIsLoading || userAddressesLoading ? (
              <SpinnerLoader />
            ) : address ? (
              <TextG14 fontWeight="700" color={theme.colors.darkGrey}>
                {header}
              </TextG14>
            ) : (
              <TextG14 color={theme.colors.grey}>
                <i
                  className="fi fi-rr-add"
                  style={{position: 'relative', marginRight: '5px', top: '2px'}}
                />
                {`Add ${header}`}
              </TextG14>
            )}

            {showTooltip && (
              <>
                <StyledTooltip
                  classNameArrow="arrow-style"
                  anchorId="address-tooltip"
                  isOpen={tooltipOpen}>
                  <VStack>
                    <TextG10 color={theme.colors.darkGrey} fontWeight="500">
                      Why do you ask for this?
                    </TextG10>
                    <TextG10 color={theme.colors.grey}>
                      You might get access to special perks and personalized Offers from Places
                      nearby.
                    </TextG10>
                  </VStack>
                </StyledTooltip>
                <Icon
                  id="address-tooltip"
                  onClick={() => {
                    setTooltipOpen(true);
                    setTimeout(() => setTooltipOpen(false), 10000);
                  }}
                  iconName="fi-sr-info"
                  style={iconStyle}
                />
              </>
            )}
          </Flex>
          <Flex alignItems="center" gap="15px">
            {addressType !== AddressTypes.Home && (
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    handleCopyHomeAddress(e);
                  }
                  setSameAsHomeChecked(e.target.checked);
                }}
                isChecked={sameAsHomeChecked}
                colorScheme="green">
                <TextG10 color={theme.colors.grey}>Same as Home</TextG10>
              </Checkbox>
            )}
            <AccordionIcon />
          </Flex>
        </AccordionButton>
        <AccordionPanel pt="0" my="10px" pb="10px">
          <VStack spacing="20px">
            <StyledInput
              autoComplete={`${typeString} address-line1`}
              id="address-line1"
              label="Address"
              name="address"
              onChange={(e) => {
                setAddressChanged(true);
                setAddress((prev) => {
                  return {...prev, street_address: e.target.value};
                });
              }}
              value={address?.street_address || ''}
            />
            <StyledInput
              autoComplete={`${typeString} address-line2`}
              id="address-line2"
              label="Unit"
              name="address2"
              onChange={(e) => {
                setAddressChanged(true);
                setAddress((prev) => {
                  return {...prev, unit: e.target.value};
                });
              }}
              value={address?.unit || ''}
            />
            <HStack spacing="22px" mt="20px">
              <StyledInput
                pr={0}
                autoComplete={`${typeString} address-level2`}
                id="city"
                label="City"
                name="city"
                onChange={(e) => {
                  setAddressChanged(true);
                  setAddress((prev) => {
                    return {...prev, city: {name: e.target.value}};
                  });
                }}
                value={address?.city?.name || ''}
              />
              <VStack alignItems="flex-start">
                <StyledLabel fontSize="10px" mb={0} color={theme.colors.grey}>
                  State
                </StyledLabel>
                <StateSelect
                  hidePlaceholder
                  styles={stateSelectStyle}
                  changeHandler={(e) => {
                    setAddressChanged(true);
                    setAddress((prev) => {
                      return {...prev, state: e};
                    });
                  }}
                  value={address?.state}
                />
              </VStack>
              <StyledInput
                type="number"
                autoComplete={`${typeString} postal-code`}
                id="zip"
                label="Zip code"
                name="zip"
                onChange={(e) => {
                  if (e.target.value.length > ZIP_LENGTH) return;
                  setAddressChanged(true);
                  setAddress((prev) => {
                    return {...prev, zip_code: e.target.value};
                  });
                }}
                value={address?.zip_code || ''}
              />
            </HStack>
            {errors &&
              Object.values(errors).map((error, index) => {
                if (error) {
                  return (
                    <Center key={index} mt="10px !important">
                      <TextG12 color={theme.colors.rose}>{error}</TextG12>
                    </Center>
                  );
                }
              })}
            {addressChanged && (
              <BlueButton
                border={`1px solid ${theme.colors.grey};`}
                backgroundColor="white"
                color={theme.colors.darkGrey}
                isDisabled={
                  isEmpty(address.street_address) ||
                  isEmpty(address.state) ||
                  isEmpty(address.zip_code) ||
                  isEmpty(address.city) ||
                  isEmpty(address.city.name)
                }
                w="100%"
                borderRadius="7px"
                onClick={saveClicked}>
                {isSaving ? <SpinnerLoader /> : 'Save Changes'}
              </BlueButton>
            )}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
      <Modal
        headerPadding="1.25rem 0.5rem 0 0.5rem"
        isCentered={true}
        header={<TextA18 textAlign="center">You are changing your Home address.</TextA18>}
        children={
          <>
            <TextG12>You might lose access to special perks and rewards. Continue?</TextG12>
            <Flex justifyContent="center" pt={3} pb={1} gap="10px">
              <BlueButton
                onClick={() => {
                  setAddress(currentAddress)
                  setConfirmationOpen(false);
                }}
                color="black"
                width="90px"
                border={`0.883657px solid ${theme.colors.grey}`}
                background="transparent">
                No
              </BlueButton>
              <BlueButton
                onClick={() => handleSaveAddress().then(() => setConfirmationOpen(false))}
                width="90px">
                Yes
              </BlueButton>
            </Flex>
          </>
        }
        show={confirmationOpen}
        parentCallback={() => setConfirmationOpen(false)}
      />
    </Accordion>
  );
};

const isEmpty = (str: string) => {
  return !str || str === '';
};

export default EditAddress;
