import React from 'react';
import {Helmet} from 'react-helmet';

const EnchantLauncher = () => {
  return (
    <Helmet>
      <script>var enchant = enchant || [];</script>
      <script
        src="//platform.enchant.com"
        data-enchant-messenger-id="9f3bc04a"
        async
        defer></script>
      <script>{`
          enchant.push(function(){
            enchant.messenger.config({
              showLauncher: false,
              showKnowledgeBase: true,
            });
          })
        `}</script>
    </Helmet>
  );
};

export default EnchantLauncher;
