import React from 'react';
import Logo from '../../assets/logos/Word_logo3.png';
import PhoneImage from '../../assets/images/phone-download.png';
import AppStoreImage from '../../assets/images/app-store-btn.png';
import PlayStoreImage from '../../assets/images/play-store-btn.png';
import {Box, Center, Flex, Image, Link, SimpleGrid, useTheme} from '@chakra-ui/react';
import {TextA18, TextG14} from '../../components/typography';
import QRCode from 'react-qr-code';
import {APPLE_STORE_LINK, GOOGLE_STORE_LINK} from '../../constants/appStores';
import ExternalLink from '../../components/ExternalLink';

const DownloadApp = () => {
  const QRLink = window.location.origin + '/download-app';
  const theme = useTheme();

  return (
    <Box p={8} w="100vw" h="100vh">
      <Link href="https://walkabout.app">
        <Image boxSize="225px" height="auto" src={Logo} alt={'Logo'} />
      </Link>
      <SimpleGrid columns={2} spacing={10} height={'90%'}>
        <Center>
          <Flex gap={{base: '18px', md: '24px', lg: '32px'}} flexDir="column" maxW="500px">
            <TextA18
              lineHeight={{base: '36px', md: '42px', lg: '56px'}}
              fontSize={{sm: '24px', md: '32px', lg: '48px'}}
              fontWeight="700">
              Take Walkabout With You Everywhere
            </TextA18>
            <TextG14
              lineHeight={{base: '18px', md: '24px', lg: '32px'}}
              fontSize={{base: '12px', md: '18px', lg: '24px'}}
              fontWeight="400">
              Search for <span style={{color: theme.colors.rose}}>Walkabout.app </span>
              on Apple and Google apps stores, or follow the links below.
            </TextG14>
            <Flex align="center" justifyContent="space-between" gap={10} pr={10}>
              <Flex gap="22px" flexDir="column">
                <ExternalLink href={APPLE_STORE_LINK} isExternal>
                  <Image w="165px" src={AppStoreImage} alt={'App Store'} />
                </ExternalLink>
                <ExternalLink href={GOOGLE_STORE_LINK} isExternal>
                  <Image w="190px" src={PlayStoreImage} alt={'Play Store'} />
                </ExternalLink>
              </Flex>
              <QRCode size={155} value={QRLink} />
            </Flex>
          </Flex>
        </Center>
        <Center>
          <Image maxH="600px" src={PhoneImage} alt={'Logo'} />
        </Center>
      </SimpleGrid>
    </Box>
  );
};

export default DownloadApp;
