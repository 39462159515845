import {Box, Collapse, Divider, Flex, HStack, Progress, useTheme, VStack} from '@chakra-ui/react';
import useLocalStorage from '@rehooks/local-storage';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {getMembershipColor} from '../../utils/membership';
import {BlueButton} from '../button';
import {Checkbox} from '../checkbox';
import Icon from '../Icon';
import {TextA10, TextA14, TextG10, TextG12} from '../typography';
import styled, {css} from 'styled-components';

const PulseAnimation = styled(Box)`
  transform: scale(1);
  backface-visibility: hidden;

  ${(props) =>
    props.$isAnimating &&
    css`
      animation: pulse 2s infinite ease-in-out;
    `};

  ${(props) =>
    !props.$isAnimating &&
    css`
      animation: null;
    `};

  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      filter: brightness(1);
      -webkit-filter: brightness(1);
    }

    50% {
      transform: scale(1.05);
      filter: brightness(1.1);
    }
  }
`;

enum MEMBERSHIP_ACTIVITIES {
  ATTEND_A_MIXER = 'ATTEND_A_MIXER',
  PASS_USED = 'PASS_USED',
  POINTS_USED = 'POINTS_USED',
  DISCOUNT_USED = 'DISCOUNT_USED',
  SIGNATURE_PASS_USED = 'SIGNATURE_PASS_USED',
}

const activityCompleted = (userActivities, activityName) => {
  if (userActivities && activityName)
    return userActivities.some((activity) => activity.activity_name === activityName);
};

const MembershipDashboard = ({defaultOpen = false}: {defaultOpen?: boolean}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();

  const [newMemberHomePulse, setNewMemberHomePulse] =
    useLocalStorage<boolean>('newMemberHomePulse');
  const [isOpen, setIsOpen] = useState(newMemberHomePulse || false);
  const [isAnimating, setIsAnimating] = useState(newMemberHomePulse);
  const {pathname} = useLocation();

  useEffect(() => {
    if (!pathname.includes('home')) return;

    if (newMemberHomePulse) {
      const timeoutId = setTimeout(() => {
        setNewMemberHomePulse(false);
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [pathname]);

  useEffect(() => {
    setIsAnimating(newMemberHomePulse);
  }, [newMemberHomePulse]);

  // const fetchActivePasses = useFetchActivePasses();

  // const uniquePassIds = [
  //   ...new Set(
  //     fetchActivePasses.data?.data
  //       .filter((item) => item.pass !== null)
  //       .map((item) => item.pass.type_id)
  //   ),
  // ];

  const hasMembership = user?.hasActiveMembership;
  const membershipName = user?.activeMembershipPlan?.name;

  const totalNumOfActivities =
    user?.activeMembershipPlan.id == 1
      ? Object.keys(MEMBERSHIP_ACTIVITIES).length - 1
      : Object.keys(MEMBERSHIP_ACTIVITIES).length;

  const membershipActivities = user?.membershipActivities;
  const numCompleteActivites = membershipActivities?.length;
  const percComplete = (numCompleteActivites / totalNumOfActivities) * 100;

  return (
    <PulseAnimation $isAnimating={isAnimating}>
      <Box
        onClick={() => {
          if (!hasMembership) {
            navigate('/memberships');
          }
        }}
        pos="relative"
        borderRadius="8px"
        background="#FFF"
        //boxShadow="0px 0px 30px 0px rgba(0, 0, 0, 0.15)"
        mb={6}
        mt="15px">
        <Box position="absolute" mt="-10px" left="10px">
          <TextA10
            boxShadow="0.5px 0.5px 1px 0px rgba(0,0,0,0.15)"
            borderRadius="5px"
            fontWeight="700"
            px={2}
            py={1}
            background="white"
            color={getMembershipColor(user?.activeMembershipPlan)}>
            {membershipName}
          </TextA10>
        </Box>
        <Flex
          alignItems="center"
          minHeight="50px"
          transition="border-radius 0.3s ease"
          justifyContent="space-between"
          px={4}
          py={3}
          borderRadius="8px"
          borderBottomRadius={isOpen ? '0px' : '8px'}
          background={getMembershipColor(user?.activeMembershipPlan)}
          onClick={() => setIsOpen((prev) => !prev)}>
          <HStack>
            <TextA14 fontWeight="700" fontStyle="italic" color="white">
              Use Your Membership
            </TextA14>
          </HStack>

          <Icon
            iconName="fi fi-rr-arrow-small-up"
            style={{
              color: 'white',
              fontSize: '20px',
              marginTop: '0px',
              transition: 'transform 0.3s ease',
              transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </Flex>
        <Box
          borderBottomRadius="8px"
          px={4}
          transition="all 1s ease"
          boxShadow="0px 0px 30px 0px #0000001A">
          <Collapse style={{fontWeight: '500', marginTop: '0 !important'}} in={isOpen}>
            <Flex flexDir="column" my="20px">
              <VStack
                w="100%"
                alignItems="flex-start"
                style={{filter: !hasMembership ? 'blur(1px)' : ''}}>
                <Progress
                  transition="width 1s ease"
                  colorScheme="progress"
                  w="100%"
                  value={numCompleteActivites}
                  max={totalNumOfActivities}
                  borderRadius="10px"
                  h="6px"
                />
                <TextG10 letterSpacing="-0.18px">{`${percComplete.toFixed(
                  0
                )}% Progress | ${numCompleteActivites}/${totalNumOfActivities} Benefits Used`}</TextG10>
              </VStack>

              <Flex mt="15px" flexDir="column" gap="10px">
                <HStack
                  position="relative"
                  onClick={() =>
                    navigate(
                      '/standalone-search/event?filterTitle=Member%20Mixer%20Events&back=1&by=start_date_time&direction=asc&passTypes=3'
                    )
                  }>
                  <Checkbox
                    isCircle={false}
                    checked={activityCompleted(
                      membershipActivities,
                      MEMBERSHIP_ACTIVITIES.ATTEND_A_MIXER
                    )}
                  />
                  <TextG12 fontWeight="400">Check Out Our Member Mixers</TextG12>
                  <Icon
                    iconName="fi fi-rr-angle-small-right"
                    style={{
                      color: theme.colors.lightBlack,
                      fontSize: '14px',
                      marginTop: '4px',
                      position: 'absolute',
                      right: '0px',
                    }}
                  />
                </HStack>
                <Divider />
                <HStack
                  position="relative"
                  onClick={() => {
                    // const searchUrl = `/standalone-search/event?filterTitle=Pass%20Events&back=1&by=start_date_time&direction=asc&passTypes=${encodeURIComponent(
                    //   uniquePassIds.join(',')
                    // )}`;
                    // navigate(searchUrl);
                    navigate(
                      '/standalone-search/event?filterTitle=Pass%20Events&back=1&by=start_date_time&direction=asc&passTypes=1'
                    );
                  }}>
                  <Checkbox
                    isCircle={false}
                    checked={activityCompleted(
                      membershipActivities,
                      MEMBERSHIP_ACTIVITIES.PASS_USED
                    )}
                  />
                  <TextG12 fontWeight="400">Use an Experience Pass</TextG12>
                  <Icon
                    iconName="fi fi-rr-angle-small-right"
                    style={{
                      color: theme.colors.lightBlack,
                      fontSize: '14px',
                      marginTop: '4px',
                      position: 'absolute',
                      right: '0px',
                    }}
                  />
                </HStack>
                <Divider />
                <HStack position="relative" onClick={() => navigate('/my-stuff?tab=Discounts')}>
                  <Checkbox
                    isCircle={false}
                    checked={activityCompleted(
                      membershipActivities,
                      MEMBERSHIP_ACTIVITIES.DISCOUNT_USED
                    )}
                  />
                  <TextG12 fontWeight="400">Use One of Your Discounts</TextG12>
                  <Icon
                    iconName="fi fi-rr-angle-small-right"
                    style={{
                      color: theme.colors.lightBlack,
                      fontSize: '14px',
                      marginTop: '4px',
                      position: 'absolute',
                      right: '0px',
                    }}
                  />
                </HStack>
                <Divider />
                <HStack position="relative" onClick={() => navigate('/search/offer')}>
                  <Checkbox
                    isCircle={false}
                    checked={activityCompleted(
                      membershipActivities,
                      MEMBERSHIP_ACTIVITIES.POINTS_USED
                    )}
                  />
                  <TextG12 fontWeight="400">Get a Local Perk With Points</TextG12>
                  <Icon
                    iconName="fi fi-rr-angle-small-right"
                    style={{
                      color: theme.colors.lightBlack,
                      fontSize: '14px',
                      marginTop: '4px',
                      position: 'absolute',
                      right: '0px',
                    }}
                  />
                </HStack>
                {user?.activeMembershipPlan?.id == 2 && (
                  <>
                    <Divider />
                    <HStack
                      position="relative"
                      onClick={() =>
                        navigate(
                          '/standalone-search/event?filterTitle=Pass%20Events&back=1&by=start_date_time&direction=asc&passTypes=2'
                        )
                      }>
                      <Checkbox
                        isCircle={false}
                        checked={activityCompleted(
                          membershipActivities,
                          MEMBERSHIP_ACTIVITIES.SIGNATURE_PASS_USED
                        )}
                      />
                      <TextG12 fontWeight="400">Use a Signature Pass</TextG12>
                      <Icon
                        iconName="fi fi-rr-angle-small-right"
                        style={{
                          color: theme.colors.lightBlack,
                          fontSize: '14px',
                          marginTop: '4px',
                          position: 'absolute',
                          right: '0px',
                        }}
                      />
                    </HStack>
                  </>
                )}
              </Flex>
              <BlueButton
                mt="20px"
                borderRadius="8px"
                background={getMembershipColor(user?.activeMembershipPlan)}
                onClick={() => {
                  setNewMemberHomePulse(false);
                  navigate(`/membership/${user?.activeMembershipPlan.id}`);
                }}>
                <TextA14 fontWeight="700">Quickly Access Your Benefits</TextA14>
              </BlueButton>
            </Flex>
          </Collapse>
        </Box>
      </Box>
    </PulseAnimation>
  );
};

export default MembershipDashboard;
