import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {fetchGuidedVideos, watchedGuidedVideoCompleted} from '../endpoints/api';

export function useGuidedVideos() {
  return useQuery(['guided-videos'], () => fetchGuidedVideos());
}

export function useCompleteGuidedVideo() {
  const queryClient = useQueryClient();
  return useMutation(({videoId}: {videoId: Number}) => watchedGuidedVideoCompleted(videoId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['guided-videos']);
    },
  });
}
