import React, {useState} from 'react';
import {useTheme, Flex, Box, keyframes} from '@chakra-ui/react';
import {TextG12} from '../../../components/typography';
import Icon from '../../../components/Icon';
import {useReactToMeetup} from '../../../hooks/useMeetUps';
import {ToastMessage} from '../../../components/toast';

const popAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.8); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
`;

const floatUpAnimation = keyframes`
  0% { transform: translateY(0) scale(1); opacity: 1; }
  50% { transform: translateY(-20px) scale(1.4); opacity: 0.7; }
  100% { transform: translateY(-40px) scale(0.8); opacity: 0; }
`;

const floatLeftAnimation = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 1; }
  50% { transform: translate(-15px, -15px) scale(1.2); opacity: 0.7; }
  100% { transform: translate(-30px, -20px) scale(0.8); opacity: 0; }
`;

const floatRightAnimation = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 1; }
  50% { transform: translate(15px, -15px) scale(1.2); opacity: 0.7; }
  100% { transform: translate(30px, -20px) scale(0.8); opacity: 0; }
`;

const popAnim = `${popAnimation} 0.4s ease-in-out`;
const floatUpAnim = `${floatUpAnimation} 1s forwards`;
const floatLeftAnim = `${floatLeftAnimation} 1.2s forwards`;
const floatRightAnim = `${floatRightAnimation} 0.9s forwards`;

interface HeartReactionButtonProps {
  eventId: number;
  initialReacted?: boolean;
  iconStyle?: React.CSSProperties;
}

const HeartReactionButton: React.FC<HeartReactionButtonProps> = ({
  eventId,
  initialReacted = false,
  iconStyle = {},
}) => {
  const [hasReacted, setHasReacted] = useState(initialReacted);
  const [isAnimating, setIsAnimating] = useState(false);
  const theme = useTheme();
  const {mutate: reactToMeetup} = useReactToMeetup();

  const handleReactClicked = () => {
    if (hasReacted) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 1000);
      return;
    }

    setIsAnimating(true);
    setHasReacted(true);

    reactToMeetup(eventId, {
      onSuccess: () => {
        setTimeout(() => {
          setIsAnimating(false);
          ToastMessage({
            status: 'success',
            text: 'Thanks for the ❤️!',
          });
        }, 1000);
      },
      onError: () => {
        setIsAnimating(false);
      },
    });
  };

  const onClickHandler = () => {
    if (!isAnimating) {
      handleReactClicked();
    }
  };

  return (
    <Flex
      direction="column"
      align="center"
      onClick={onClickHandler}
      opacity={1}
      cursor={hasReacted || isAnimating ? 'default' : 'pointer'}>
      <Box position="relative" height="26px" maxH={'26px'}>
        {/* Main heart icon */}
        <Icon
          iconName={hasReacted ? 'fi-sr-heart' : 'fi-rr-heart'}
          style={{
            ...iconStyle,

            color: hasReacted ? 'red' : theme.colors.lightBlue,
            animation: isAnimating ? popAnim : 'none',
            transition: 'color .6s ease-in-out',
          }}
        />

        {/* Animated hearts */}
        {isAnimating && (
          <>
            {/* Center heart (up) */}
            <Box position="absolute" top="0" left="0" animation={floatUpAnim}>
              <Icon
                iconName="fi-sr-heart"
                style={{
                  ...iconStyle,
                  color: 'red',
                  fontSize: '1.2rem',
                }}
              />
            </Box>

            {/* Left floating heart */}
            <Box position="absolute" top="0" left="0" animation={floatLeftAnim}>
              <Icon
                iconName="fi-sr-heart"
                style={{
                  ...iconStyle,
                  color: 'red',
                  fontSize: '1rem',
                }}
              />
            </Box>

            {/* Right floating heart */}
            <Box position="absolute" top="0" left="0" animation={floatRightAnim}>
              <Icon
                iconName="fi-sr-heart"
                style={{
                  ...iconStyle,
                  color: 'red',
                  fontSize: '0.9rem',
                }}
              />
            </Box>

            {/* Additional smaller heart with slight delay */}
            <Box
              position="absolute"
              top="0"
              left="2px"
              animation={`${floatUpAnimation} 1.3s 0.2s forwards`}>
              <Icon
                iconName="fi-sr-heart"
                style={{
                  ...iconStyle,
                  color: 'pink',
                  fontSize: '0.7rem',
                }}
              />
            </Box>
          </>
        )}
      </Box>

      <TextG12
        textAlign="center"
        maxW="150px"
        whiteSpace="wrap"
        color={hasReacted ? 'red' : theme.colors.lightBlue}
        transition="color 0.6s ease-in-out">
        {hasReacted
          ? 'You sent the Host a thanks!'
          : 'Send the Host a thanks for creating community'}
      </TextG12>
    </Flex>
  );
};

export default HeartReactionButton;
