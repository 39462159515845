import {Box, Flex, Text, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import {TextA18, TextG14} from '../../components/typography';
import useAuth from '../../hooks/useAuth';

const Details = () => {
  const theme = useTheme();
  const {user} = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <BackButton top={3} pos='absolute' onClick={() => navigate('/home')} />
      <Flex flexDir="column" gap="20px" color={theme.colors.grey}>
        <Box textAlign="center">
          <TextA18 mb={4} fontWeight="700">
            CONGRATULATIONS!
          </TextA18>
          <TextG14 fontWeight="500" mt={2}>
            You’ve been gifted 1 month of <b>FREE</b> access to the Local Insiders Club with
            Walkabout!
          </TextG14>
        </Box>

        <Box>
          <TextG14 mb={3} fontWeight="500">
            Here’s how to redeem:
          </TextG14>
          <TextG14>{`1. ${user ? 'Click the button below' : 'Register for Walkabout'}`}</TextG14>
          <TextG14>2. Subscribe to the Local Insiders Club for FREE</TextG14>
          <TextG14>{`3. ${
            user ? 'Enjoy your new perks!' : 'Download the app and log in!'
          }`}</TextG14>
        </Box>
      </Flex>
    </>
  );
};

export default Details;
