import {FilterConf} from '../../../../utils/GlobalSearch/types';
import {CONTENT_TYPE, FILTER_TYPES} from '../../../../utils/GlobalSearch/enums';

const PARTNER_CONFIG: {[CONTENT_TYPE.PARTNER]: Record<string, FilterConf>} = {
  partner: {
    withActiveOffers: {
      key: 'withActiveOffers',
      label: 'Has Offers',
      type: FILTER_TYPES.TOGGLE,
      quick: true,
    },
  },
};

export default PARTNER_CONFIG;
