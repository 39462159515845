import React, {useEffect, useMemo} from 'react';
import {Box, Flex, HStack, Spacer, useTheme, Grid, GridItem} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../typography';
import {generatePath, Link, useParams} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Place} from '../../../types/article';
import {calculateAndGetDistance, getDistanceString} from '../../../utils/distance';
import {usePlaceTypes} from '../../../hooks/usePlaceTypes';
import CardInterestTags from '../CardInterestTags';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import {useOffers} from '../../../hooks/useOffers';
import useGetUserLocation from '../../../hooks/useUserLocation';
import NonPremiumCard from '../../Card/NonPremiumCard';
import DotSeparator from '../../DotSeparator';
import styled from 'styled-components';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import CardBadge from '../../CardBadge';
import PointBoostBadge from './PointBoostBadge';

const WalkaboutBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  padding: 5px;
  color: white;
  background-color: white;
  position: absolute;
  right: 34px;
  top: 8px;
  width: 26px;
  height: 26px;
  z-index: 10;
`;

const renderRightTopItems = (isPartner: boolean, rightTopItem?: any) => {
  if (rightTopItem) {
    return <>{rightTopItem}</>;
  }
};

const PlaceCard = ({
  place,
  leftTopItem,
  rightTopItem,
  modal,
  isSlide = false,
  isPreview = false,
}: {
  place?: Place;
  leftTopItem?: any;
  rightTopItem?: any;
  height?: string;
  modal?: boolean;
  isSlide?: boolean;
  isPreview?: boolean;
}) => {
  if (!place) return null;
  const theme = useTheme();
  const {getPlaceTypeFromId} = usePlaceTypes(!isPreview);
  const placeTypeName = getPlaceTypeFromId(place?.place_type_id)?.display_name;
  const {pathname} = useParams();
  const isProduction = process.env.NODE_ENV === 'production';
  const isPartner = place.partner || place.partner_id;
  const isExclusive = place?.communities && place?.communities.length > 0 && place.is_private;

  let userLocation;
  if (!isPreview) {
    userLocation = useGetUserLocation();
  }
  const distance = useMemo(() => {
    if (place && userLocation) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(place.latitude),
          longitude: Number(place.longitude),
        }
      );
    }
    return 0;
  }, [userLocation?.data, place]);

  const [ref, {wasEverVisible, isVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();

  useEffect(() => {
    if (wasEverVisible && place?.id.toString()) {
      addVisibleArticle({id: place.id.toString(), type: 'place'});
    }
  }, [wasEverVisible]);

  const image = useMemo(() => {
    if (place) {
      return place.image_url;
    }
  }, [place]);

  const fetchOffers = useOffers({
    articleType: 'placeId',
    articleId: place.id.toString(),
    enabled: !isPreview && isVisible,
  });

  return (
    <Link
      style={{width: '100%'}}
      to={
        isPreview
          ? ''
          : modal
          ? {pathname, search: `?id=${place.id.toString()}&articleType=place`}
          : generatePath(ROUTES.PLACE, {
              placeId: place.id.toString(),
            })
      }
      onClick={() => storeScrollPositionInSession()}
      ref={ref}>
      {isPartner ? (
        <CardBadge
          top={isExclusive ? '44px' : isSlide ? '12px' : '9px'}
          w="145px"
          label="WALKABOUT PARTNER"
          center="true"
        />
      ) : null}
      <NonPremiumCard
        outline={isPartner ? '1px solid #FFC0C6' : 'none'}
        isSlide={isSlide}
        height={'auto'}
        coverImage={image}
        leftTopItem={leftTopItem}
        rightTopItem={renderRightTopItems(isPartner, rightTopItem)}
        childPaddingX={4}
        childMarginTop={isPartner ? '6px' : 0}
        article={place}>
        <Grid height="100%" alignContent="space-between">
          <GridItem>
            <HStack align="center" pr={isPartner ? 16 : 8}>
              <TextG14
                isTruncated="&hellip;"
                noOfLines={null}
                fontWeight={700}
                color={theme.colors.black}>
                {place.name || place.title}
              </TextG14>
            </HStack>
            <Flex py={1} alignItems="center" textAlign="center">
              {placeTypeName && (
                <TextG10 color={theme.colors.grey} noOfLines={null}>
                  {placeTypeName}
                </TextG10>
              )}
              {fetchOffers && fetchOffers.offers && fetchOffers.offers.length > 0 && (
                <>
                  <DotSeparator color={theme.colors.grey} />
                  <TextG10
                    borderTopLeftRadius="12px"
                    borderBottomRightRadius="10px"
                    color={theme.colors.rose}>{`${fetchOffers.offers.length} ${
                    fetchOffers.offers.length == 1 ? 'Offer' : 'Offers'
                  }`}</TextG10>
                </>
              )}
            </Flex>
            <PointBoostBadge flexDir="column" alignItems="left" article={place} />
          </GridItem>
          <GridItem>
            <Flex mt={1} justifyContent="space-between" align="center">
              {place.topics_of_interest && place.topics_of_interest.length > 0 ? (
                <Box maxW="calc(55vw - 12px)" minW="150px">
                  <CardInterestTags
                    width="100%"
                    onlyShowMatched={false}
                    autoScroll={true}
                    tagsVisible={isVisible}
                    topicsOfInterest={place.topics_of_interest}
                  />
                </Box>
              ) : null}
              <Spacer />
              {distance ? (
                <TextG10 sx={{wordWrap: 'normal !important'}} color={theme.colors.lightBlack}>
                  {getDistanceString(distance)}
                </TextG10>
              ) : null}
            </Flex>
          </GridItem>
        </Grid>
      </NonPremiumCard>
    </Link>
  );
};

export default PlaceCard;
