import React, {useState} from 'react';
import {Button, ButtonProps} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../components/typography';
import {EventRSVPStatus, useSetAttendance} from '../../hooks/useEvent';
import ConvinceMeToGoModal from './ConvinceMeToGoModal';
import {Event} from '../../types/acrticle';
import dayjs from 'dayjs';
import useTrackEvent from '../../hooks/useTrackEvent';

type Props = {
  event: Event;
  textColor?: string;
  background?: string;
  buttonProps?: ButtonProps;
  typographySize?: 'sm' | 'md' | 'lg';
  onClick?;
  modelClosedEvent?;
};

const BUTTON_TEXT = 'Convince Me To Go';
const STORAGE_KEY = 'do-not-show-convince-me-to-go-modal';

const ConvinceMeToGoButton = ({
  event,
  textColor = 'white',
  background = '#276ACD',
  buttonProps,
  typographySize,
  onClick,
  modelClosedEvent,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {mutateAsync} = useSetAttendance();

  const {addUserEvent} = useTrackEvent();

  const onClickConvinceMeToGo = (e) => {
    e.preventDefault();
    const doNotShowConvinceMeToGoModalKey = localStorage.getItem(STORAGE_KEY) || '0';

    if (parseInt(doNotShowConvinceMeToGoModalKey) >= 3) {
      const avoidDispatchRSVPModalPopupActions = false;
      // Do not show again then set Interested Status by calling below function as we are not opening modal, we already have a call to initate this code on modal if modal opens
      mutateAsync({
        avoidDispatchRSVPModalPopupActions,
        eventId: event.id,
        prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
        status: 1,
      });
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }

    addUserEvent({
      type: 'click',
      event_name: 'convince_me_to_go_clicked',
      event_time: dayjs().format(),
      data: {eventId: event.id.toString()},
    });
  };

  const onClickConvinceMeToGoCloseModal = (e) => {
    let doNotShowConvinceMeToGoModalKey = localStorage.getItem(STORAGE_KEY) || '0';
    let doNotShowConvinceMeToGoCount = parseInt(doNotShowConvinceMeToGoModalKey);

    e === true ? (doNotShowConvinceMeToGoCount = 3) : ++doNotShowConvinceMeToGoCount;
    localStorage.setItem(STORAGE_KEY, doNotShowConvinceMeToGoCount.toString());
    setIsModalOpen(false);
    modelClosedEvent && modelClosedEvent(true);
  };

  const renderButtonText = () => {
    if (event.rsvp_status.status_value === null || isModalOpen) {
      return (
        <Button
          //colorScheme="messenger"
          id="rsvp-tooltip"
          borderRadius={'2.5rem'}
          justifyContent={'space-between'}
          alignItems={'center'}
          textAlign="center"
          onClick={onClickConvinceMeToGo}
          px={0}
          background={background}
          {...buttonProps}>
          {typographySize === 'sm' ? (
            <TextG10 sx={{flexGrow: 1, color: textColor}}>{BUTTON_TEXT}</TextG10>
          ) : (
            <TextG14 sx={{flexGrow: 1, color: textColor}}>{BUTTON_TEXT}</TextG14>
          )}
        </Button>
      );
    } else {
      return (
        <>
          <TextG10
            sx={{flexGrow: 0, color: textColor, marginLeft: '0.2rem', display: 'inline-flex'}}>
            <i className="fi fi-br-check" style={{color: textColor, marginRight: '0.3rem'}}></i>
            {getStatusText(event.rsvp_status.status_value)}
          </TextG10>
        </>
      );
    }
  };

  const getStatusText = (statusId: number) => {
    switch (statusId) {
      case 1:
        return 'Considering Going';
      case 2:
        return 'Going';
      case 3:
      case 4:
        return 'Not Going';
    }
  };

  return (
    <>
      {renderButtonText()}
      {isModalOpen && (
        <ConvinceMeToGoModal onClose={onClickConvinceMeToGoCloseModal} event={event} />
      )}
    </>
  );
};

export default ConvinceMeToGoButton;
