import {AbsoluteCenter, VStack, Flex, useTheme, Box, Divider, Image} from '@chakra-ui/react';
import {find} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Icon from '../../components/Icon';
import {SpinnerLoader} from '../../components/Loader';
import {TextG12} from '../../components/typography';
import {useFetchPurchasablePasses} from '../../hooks/usePassPurchase';
import PassCard from '../MyPasses/PassCard';
import Header from './Header';
import PassPurchaseQtyContainer from './PassPurchaseQtyContainer';
import Background from '../../assets/images/ticketBG.png';
import {BlueButton} from '../../components/button';
import dayjs from 'dayjs';

const PassPurchase = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useFetchPurchasablePasses();
  const {passId} = useParams();
  const [pass, setPass] = useState<any>();
  const theme = useTheme();
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (data?.data && passId) {
      setPass(find(data?.data, (p) => p.id == passId));
    }
  }, [data, passId]);

  if (isLoading || !pass)
    return (
      <AbsoluteCenter>
        <SpinnerLoader />
      </AbsoluteCenter>
    );

  return (
    <Flex flexDir="column">
      <Header />
      <VStack background={theme.colors.veryLightBlue} px="16px">
        <PassCard
          pass={pass}
          showGetMore={false}
          showArrow={false}
          expires_at={dayjs.utc().add(1, 'year').format('YYYY-MM-DDTHH:mm')}
        />
        <PassPurchaseQtyContainer pass={pass} quantity={quantity} setQuantity={setQuantity} />
      </VStack>
      <Divider />
      <Box
        w="100%"
        borderTop={`1px solid ${theme.colors.blueShade1}`}
        background={theme.colors.blueShade2}
        py={4}
        px={8}>
        <Flex w="100%" alignItems="center" gap="18px">
          <Icon
            iconName="fi-rr-envelope"
            style={{fontSize: '16px', color: theme.colors.darkGrey}}
          />
          <TextG12 color={theme.colors.darkGrey}>
            Instructions about the Passes purchase and delivery in the account
          </TextG12>
        </Flex>
      </Box>
      <Image zIndex="-1" pos="relative" top="-75px" w="100%" src={Background} />
      <Flex
        background="white"
        pos="fixed"
        bottom="0px"
        justify="center"
        margin="0px !important"
        px={4}
        py={6}
        w="100%">
        <BlueButton
          w="100%"
          onClick={() => navigate(`/checkout?qty=${quantity}&pass=${pass.id}`, {replace: true})}>
          Continue to Checkout
        </BlueButton>
      </Flex>
    </Flex>
  );
};

export default PassPurchase;
