import React from 'react'
import {TextG14} from '../../../typography'
import {Flex} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

interface DatePickerFilterProps {
  id?: string
  label: string
  value?: Date | null
  format?: string
  onChange: (date: Date | null) => void
}

  const handleFocus = (e) => {
    const {target} = e;

    if (target) {
      target.readOnly = true; // -------> this for all others
      target.blur(); //  ------> this for ios iphone
    }
  };

const DatePickerWrapper = styled.div`
  min-width: 230px;
  & .react-datepicker-wrapper > .react-datepicker__input-container > input {
    text-align: end;
    color: #757d84;
    font-family: Gordita, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  & .react-datepicker-wrapper > .react-datepicker__input-container:focus-visible {
    border: none;
    outline: none !important;
  }
  & .react-datepicker-wrapper > .react-datepicker__input-container > input:focus-visible {
    border: none;
    outline: none !important;
  }
  & .react-datepicker-wrapper {
    text-align: end;
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 2px 8px;
  }
  &
    .react-datepicker-wrapper
    > .react-datepicker__input-container
    > .react-datepicker__calendar-icon {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'

const DatePickerFilter = ({
  id,
  label,
  value,
  format = DEFAULT_DATE_FORMAT,
  onChange,
}: DatePickerFilterProps) => {
  return (
    <Flex alignItems={'center'} justifyContent={'space-between'} pl={5} pr={4} py={5}>
      <TextG14 sx={{wordWrap: 'normal'}}>{label}</TextG14>
      <DatePickerWrapper>
        <DatePicker
          id={id}
          key={id}
          showIcon
          icon={'fi-rr-calendar'}
          minDate={new Date()}
          placeholderText={'Select any date'}
          selected={value}
          onChange={onChange}
          dateFormat={format}
          onFocus={handleFocus}
        />
      </DatePickerWrapper>
    </Flex>
  );
}

export default DatePickerFilter
