import { createSlice } from '@reduxjs/toolkit'

export type BottomSheetNotificationProps = {
    title: string;
    // customHeader?: JSX.Element;
    // content: JSX.Element;
}

export type SliceState = BottomSheetNotificationProps[];
type SliceAction = { payload: BottomSheetNotificationProps, type: string };

const initialState: SliceState = [];

export const BottomSheetNotificationSlice = createSlice({
    name: 'bottomSheetNotifications',
    initialState,
    reducers: {
        pushNotification: (state: SliceState, action: SliceAction) => {
            state.push({
                title: action.payload.title,
            });
        },
        popNotification: (state: SliceState) => {
            state.shift();
        }
    },
});

export const BottomSheetNotificationActions = BottomSheetNotificationSlice.actions;

export default BottomSheetNotificationSlice;