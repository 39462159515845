import {
  Flex,
  Divider,
  Image,
  useTheme,
  Box,
  Collapse,
  SystemStyleObject,
  Icon as ChakIcon,
} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {EventRSVPStatus, useFetchEventRSVPStats} from '../../../hooks/useEvent';
import {Event} from '../../../types/acrticle';
import {getEventDate} from '../../../utils/date';
import BackButton from '../../BackButton';
import DotSeparator from '../../DotSeparator';
import {TextG14, TextG10} from '../../typography';
import DefaultEventImage from '../../../assets/images/default_event.png';
import {OPTIONS as EventOptions} from '../../../constants/eventOptions';
import {OPTIONS as MeetupOptions} from '../../../constants/meetupOptions';
import Icon from '../../Icon';
import {useInfiniteFeaturedEventComments} from '../../../hooks/useEventComments';
import ChatComment, {ChatComment as ChatCommentInterface} from '../ChatComment';
import ChatMenu from './ChatMenu';
import ChatTabs from './ChatTabs';
import {EventChatIsClosed} from '../EventChat';
import {findIndex} from 'lodash';
import {EventChatMenuOption, OPTIONS} from './ChatMenu/EventChatMenuOptions';
import {ToastMessage} from '../../toast';
import {useFetchChatUsers} from '../../../hooks/useEvents';
import {canGoBack} from '../../../utils/history';
import RSVPController from '../../../pages/Event/RSVP/RSVPController';

const ChatHeader = ({
  event,
  tabIndex,
  setHeaderHeight,
  showTabs = true,
  addComment,
  updateComment,
  deleteComment,
  reportComment,
  helpfulComment,
}: {
  event: Event;
  tabIndex?: number;
  setHeaderHeight?: (height) => void;
  showTabs?: boolean;
  addComment: (content: string, parent_id?: string) => void;
  updateComment: (commentId: string, content: string, parent_id?: string) => void;
  deleteComment: (commentId: string) => void;
  reportComment: (commentId: string) => void;
  helpfulComment: (commentId: string) => void;
}) => {
  const navigate = useNavigate();
  const eventChatClosed = EventChatIsClosed(event);
  const isRSVPd = !!(
    event?.rsvp_status.status_value &&
    event?.rsvp_status.status_value != EventRSVPStatus.NOT_INTERESTED
  );
  const fetchRSVPStats = useFetchEventRSVPStats(event.id);
  const rsvpStats = fetchRSVPStats.data?.data.rsvp_stats;
  const theme = useTheme();
  const headerRef = useRef<HTMLDivElement>(null);
  const [guidelinesOpen, setGuidelinesOpen] = useState(true);
  const {data} = useInfiniteFeaturedEventComments(event.id, isRSVPd && !eventChatClosed);
  const featuredComment = data?.pages[0]?.data.data[0] as ChatCommentInterface;
  const guidelinesClosed = JSON.parse(localStorage.getItem('chat_guidelines') || '[]').includes(
    event.id
  );

  const [featuredCommentOpen, setFeaturedCommentOpen] = useState(false);

  const regexChats = /\/chat/;
  const regexChatsWithRandomString = /\/chat\/\w+/;

  const isChatsMatch = regexChats.test(window.location.href);
  const isChatsWithRandomStringMatch = regexChatsWithRandomString.test(window.location.href);

  const route = event.is_meetup ? `/meet-up/${event.id}` : `/event/${event.id}`;
  const [optionsOpen, setOptionsOpen] = useState(false);
  const handleOptionClicked = (option: EventChatMenuOption) => {
    switch (option) {
      case EventChatMenuOption.SHARE:
        handleShareClicked();
        break;
      case EventChatMenuOption.GUIDELINES:
        navigate('/community-guidelines');
        break;
      case EventChatMenuOption.EVENT_PAGE:
        navigate(route, {replace: true});
        break;
    }
    setOptionsOpen(false);
  };

  const handleShareClicked = () => {
    const href = location.origin + route;
    navigator.clipboard.writeText(href);
    ToastMessage({
      status: 'success',
      showStatusIcon: true,
      text: 'Link copied. Paste it anywhere and invite others to this event!',
    });
  };

  useEffect(() => {
    if (featuredComment) {
      const featuredComments = JSON.parse(localStorage.getItem('chat_featured') || '[]');
      if (featuredComments.length > 0) {
        const index = findIndex(featuredComments, {
          event_id: event?.id,
          comment_id: featuredComment?.id,
        });
        const isOpened = index !== -1 ? featuredComments[index].open : false;
        setFeaturedCommentOpen(isOpened);
      } else {
        setFeaturedCommentOpen(true);
      }
    }
  }, [featuredComment]);

  const iconStyles: SystemStyleObject = {
    width: '2em',
    height: '2em',
    position: 'absolute',
    top: '8px',
    right: '8px',
    zIndex: 999,
    transition: 'all 200ms',
    transform: featuredCommentOpen ? 'rotate(-180deg)' : undefined,
    transformOrigin: 'center',
    color: theme.colors.lightestGrey,
  };

  useEffect(() => {
    if (headerRef.current?.clientHeight && setHeaderHeight)
      setHeaderHeight(headerRef.current?.clientHeight);
  }, [headerRef.current?.clientHeight, guidelinesOpen, featuredComment, rsvpStats, tabIndex]);

  useEffect(() => {
    setTimeout(() => {
      if (headerRef.current?.clientHeight && setHeaderHeight)
        setHeaderHeight(headerRef.current?.clientHeight);
    }, 1);
  }, [featuredCommentOpen]);

  const getIconStyle = (option) => {
    {
      return {
        fontSize: '12px',
        height: '12px',
        width: '12px',
        color: theme.colors[option.color] || option.color,
        fill: theme.colors[option.color] || option.color,
      };
    }
  };

  return (
    <>
      <Flex
        ref={headerRef}
        w="95%"
        position="fixed"
        top={0}
        left="2.5%"
        flexDir="column"
        align="center"
        gap={2.5}
        background="white"
        zIndex="1300"
        margin="auto"
        pt={3}>
        <BackButton
          mx="0.15rem"
          my="0.75rem"
          onClick={() => {
            if (isChatsWithRandomStringMatch) {
              navigate(`/event/${event.id}/chat`, {replace: true});
            } else {
              canGoBack() ? navigate(-1) : navigate('/home');
            }
          }}
          pos="absolute"
          top={0}
          left={0}
          zIndex="1301"
        />
        <ChatMenu
          options={OPTIONS}
          handleOptionClicked={handleOptionClicked}
          setOptionsOpen={setOptionsOpen}
          optionsOpen={optionsOpen}
        />
        <Box
          onClick={() =>
            navigate(event.is_meetup ? `/meet-up/${event.id}` : `/event/${event.id}`, {
              replace: true,
            })
          }>
          <Image
            mt={0}
            mb={2.5}
            mx="auto"
            fallbackStrategy="onError"
            fallbackSrc={DefaultEventImage}
            src={event.image_url || DefaultEventImage}
            alt="cover image"
            w="36px"
            h="36px"
            objectFit="cover"
            borderRadius="100%"
          />
          {event.title && (
            <TextG14
              noOfLines={2}
              textAlign="center"
              fontWeight="500"
              color={theme.colors.lightBlack}>
              {event.title}
            </TextG14>
          )}
        </Box>
        <Flex align="center" gap={1.5} color={theme.colors.grey}>
          <TextG10>{getEventDate(event.start_date_time, event.end_date_time)}</TextG10>
          {event.place && (
            <>
              <DotSeparator color="grey" width="4px" height="4px" marginLeft="0" marginRight="0" />
              <TextG10>{event.place.name}</TextG10>
            </>
          )}
        </Flex>
        <RSVPController
          rsvpOptions={event.is_meetup ? MeetupOptions : EventOptions}
          buttonProps={{
            flexGrow: 1,
            maxHeight: '33px',
            gap: '5px',
            height: '2rem',
            border: '1px solid white',
            background: !event.rsvp_status?.status ? 'lighterBlue !important' : '',
          }}
          variant="sm"
          event={event}
        />
        {showTabs && <ChatTabs tabIndex={tabIndex} />}
        {rsvpStats &&
          (rsvpStats.INTERESTED > 0 || rsvpStats.GOING > 0 || rsvpStats.NOT_GOING > 0) && (
            <Flex w="100%" justify="space-between">
              <TextG10 color={theme.colors.lightBlack}>
                {rsvpStats.INTERESTED + rsvpStats.GOING + rsvpStats.NOT_GOING}{' '}
                {rsvpStats.INTERESTED + rsvpStats.GOING + rsvpStats.NOT_GOING > 1
                  ? 'people in this chat'
                  : 'person in this chat'}
              </TextG10>
              <Flex gap={3}>
                <Flex gap={1} align="center">
                  {EventOptions[EventRSVPStatus.INTERESTED].renderSelectedIcon({
                    style: getIconStyle(EventOptions[EventRSVPStatus.INTERESTED]),
                  })}
                  <TextG10 color={theme.colors.lightBlack}>{rsvpStats.INTERESTED}</TextG10>
                </Flex>
                <Flex gap={1} align="center">
                  {EventOptions[EventRSVPStatus.GOING].renderSelectedIcon({
                    style: getIconStyle(EventOptions[EventRSVPStatus.GOING]),
                  })}
                  <TextG10 color={theme.colors.lightBlack}>{rsvpStats.GOING}</TextG10>
                </Flex>
                <Flex gap={1} align="center">
                  {EventOptions[EventRSVPStatus.NOT_GOING].renderSelectedIcon({
                    style: getIconStyle(EventOptions[EventRSVPStatus.NOT_GOING]),
                  })}
                  <TextG10 color={theme.colors.lightBlack}>{rsvpStats.NOT_GOING}</TextG10>
                </Flex>
              </Flex>
            </Flex>
          )}
        <Divider w="100vw" />
        {!guidelinesClosed && guidelinesOpen && (
          <>
            <Box py={1} pr={10} pos="relative">
              <TextG10 fontWeight="400" color={theme.colors.grey}>
                Remember to keep comments respectful and follow our{' '}
                <a
                  style={{color: theme.colors.blue, fontWeight: '500'}}
                  href={'/community-guidelines'}>
                  Community Guidelines
                </a>
              </TextG10>
              <Icon
                onClick={() => {
                  setGuidelinesOpen(false);
                  const curGuidelindesClosed = JSON.parse(
                    localStorage.getItem('chat_guidelines') || '[]'
                  );
                  curGuidelindesClosed.push(event.id);
                  localStorage.setItem('chat_guidelines', JSON.stringify(curGuidelindesClosed));
                }}
                style={{
                  color: '#374957',
                  fontSize: '10px',
                  position: 'absolute',
                  top: '40%',
                  right: '5px',
                }}
                iconName="fi-rr-cross"
              />
            </Box>
            <Divider w="100vw" />
          </>
        )}
        {tabIndex === 0 && featuredComment && isRSVPd && (
          <Collapse
            style={{width: '100%'}}
            in={featuredCommentOpen}
            animateOpacity={false}
            startingHeight="73px">
            <Box
              w="100%"
              mt={-2}
              onClick={() => {
                setFeaturedCommentOpen((prev) => !prev);
                const chatFeatured = JSON.parse(localStorage.getItem('chat_featured') || '[]');
                const index = findIndex(chatFeatured, {
                  event_id: event.id,
                  comment_id: featuredComment.id,
                });
                if (index == -1) {
                  chatFeatured.push({
                    event_id: event.id,
                    comment_id: featuredComment.id,
                    open: !featuredCommentOpen,
                  });
                } else {
                  chatFeatured[index] = {
                    event_id: event.id,
                    comment_id: featuredComment.id,
                    open: !featuredCommentOpen,
                  };
                }
                localStorage.setItem('chat_featured', JSON.stringify(chatFeatured));
              }}
              pos="relative">
              <ChakIcon
                viewBox="0 0 24 24"
                aria-hidden
                // className={_className}
                __css={iconStyles}
                // {...props}
              >
                <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
              </ChakIcon>
              <ChatComment
                fetchUsersHook={useFetchChatUsers}
                addComment={addComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                reportComment={reportComment}
                helpfulComment={helpfulComment}
                numOfLines={!featuredCommentOpen ? 1 : undefined}
                comment={featuredComment}
                articleId={event.id}
                name={event.title}
                isRSVPd={isRSVPd}
                background="#EAF5FF"
                borderRadius="10px"
                px="6px"
                py="6px"
                minH="64px"
              />
            </Box>
          </Collapse>
        )}
      </Flex>
    </>
  );
};

export default ChatHeader;
