import {useState, useRef, useEffect} from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Textarea,
  Select,
  Radio,
  RadioGroup,
  Stack,
  useToast,
  Progress,
  Text,
  usePrevious,
  FormControl,
  FormHelperText,
  FormLabel,
  VStack,
  useTheme,
} from '@chakra-ui/react';
import {motion, AnimatePresence} from 'framer-motion';
import {BlueButton} from '../../components/button';
import React from 'react';
import MeetUpCard from '../../components/Articles/MeetUpCard';
import {Event, Place} from '../../types/article';
import useAuth from '../../hooks/useAuth';
import PlaceSelectModal from './PlaceSelectModal';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {fetchEvent, fetchPlace} from '../../endpoints/api';
import dayjs from 'dayjs';
import {addMinutes, startOfToday} from 'date-fns';
import PromptModal from '../../components/Modals/PromptModal';
import IceBreakerSelect from '../../components/IceBreakerSelect';
import {TextG14, TextG12} from '../../components/typography';
import BroadcastConfirmationModal from './BroadcastConfirmationModal';
import HourSelect from './HourSelect';
import {BROADCAST_AUDIENCE, useBroadcastReach, useMeetupBroadcast} from '../../hooks/useBroadcast';
import {useSetAttendance, EventRSVPStatus} from '../../hooks/useEvent';
import {useCreateMeetup} from '../../hooks/useMeetUps';
import {ToastMessage} from '../../components/toast';
import {generateDescription, getBroadcastFooter} from '.';
import MeetupCreatedModal from './MeetupCreatedModal';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const MotionBox = motion(Box);

// Helper function to format description with footer
export const getDescriptionWithFooter = (description, userName) => {
  // Check if footer is already appended
  if (description.includes(getBroadcastFooter(userName))) return description;
  return `${description}\n\n${getBroadcastFooter(userName)}`;
};

// Helper function to remove footer from a message (for broadcast)
export const removeFooterFromDescription = (description, userName) => {
  const footer = getBroadcastFooter(userName);
  if (description.includes(footer)) {
    return description.replace(footer, '').trim();
  }
  return description;
};

const MeetupForm = ({place, event}: {place?: Place; event?: Event}) => {
  const theme = useTheme();
  const {user} = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {mutateAsync} = useCreateMeetup();
  const setAttendance = useSetAttendance();
  const {mutateAsync: sendBroadcast, isLoading, isSuccess, error} = useMeetupBroadcast();

  // If a place is pre-selected, start at step 2; otherwise, start at step 1.
  const [step, setStep] = useState(!!searchParams.get('placeId') ? 2 : 1);

  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [selectedStartDate, setSelectedStartDate] = useState<dayjs.Dayjs>();
  const [createdEvent, setCreatedEvent] = useState<Event>();

  useEffect(() => {
    if (place) {
      setSelectedPlace(place);
    }
  }, [place]);

  useEffect(() => {
    if (event) {
      setSelectedEvent(event);
      setSelectedPlace(event.place);
    }
  }, [event]);

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [eventDuration, setEventDuration] = useState('3');

  const inputRef = useRef<HTMLInputElement>(null);

  const [minTime, setMinTime] = useState<string>();
  const [maxTime, setMaxTime] = useState<string>();
  const previousStartDate = usePrevious(startDate);

  const [iceBreaker, setIceBreaker] = useState<string>('Describe yourself in 3 words');
  const [customIceBreaker, setCustomIceBreaker] = useState<string>('');

  // timeOption: "1" = Now, "2" = Later
  const [timeOption, setTimeOption] = useState('1');
  const [isSaving, setIsSaving] = useState(false);

  const [title, setTitle] = useState(
    !!searchParams.get('placeId') ? '' : `${user?.name}'s Meet-Up`
  );
  const [description, setDescription] = useState<string>(
    !!searchParams.get('placeId')
      ? ''
      : generateDescription(selectedPlace?.name, startDate, user?.name)
  );
  const [descriptionPromptOpen, setDescriptionPromptOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [meetupCreatedOpen, setMeetupCreatedOpen] = useState(false);
  const [createdEventId, setCreatedEventId] = useState(null);

  const toast = useToast();
  // Use a ref for animation direction
  const direction = useRef(1);

  const [inviteGroup, setInviteGroup] = useState('1');

  const {data: broadcastReach, isLoading: reachLoading} = useBroadcastReach({
    audienceRuleset:
      inviteGroup == '1'
        ? BROADCAST_AUDIENCE.ANYONE_NEARBY
        : BROADCAST_AUDIENCE.LOCAL_INSIDERS_CLUB,
    placeId: selectedPlace?.id.toString(),
  });
  const reachUserCount = broadcastReach?.data.user_count;

  const handleCreateClicked = async () => {
    setIsSaving(true);

    try {
      const start = timeOption == '1' ? dayjs() : startDate;
      const end = timeOption == '1' ? dayjs().add(Number(eventDuration), 'hour') : endDate;

      const utcStartDate = dayjs(start).utc().format(DATE_FORMAT);
      const utcEndDate = dayjs(end).utc().format(DATE_FORMAT);

      // Add footer to description at creation time
      const descriptionWithFooter = getDescriptionWithFooter(description, user?.name);

      const newMeetup = await mutateAsync({
        title: title,
        description: descriptionWithFooter, // Use description with footer
        reason: iceBreaker == 'custom' ? customIceBreaker : iceBreaker,
        start_date_time: utcStartDate,
        end_date_time: utcEndDate,
        is_private: false,
        place_id: selectedPlace?.id,
      });

      if (newMeetup.data?.event) {
        const createdEvent = newMeetup.data?.event;
        await setAttendance.mutateAsync({
          eventId: createdEvent.id,
          prevStatus: EventRSVPStatus.NO_STATUS,
          status: EventRSVPStatus.GOING,
          showDispatchRSVPModalPopupActions: false,
          showToast: false,
        });

        setCreatedEvent(createdEvent);
        setCreatedEventId(createdEvent.id);
        setMeetupCreatedOpen(true);
        setIsSaving(false);
      }
    } catch (e) {
      setIsSaving(false);
      ToastMessage({status: 'error', text: 'An error occurred. Please try again.'});
    }
  };

  const handleSendBroadcast = async (withBroadcast) => {
    if (!createdEventId) return;

    if (withBroadcast) {
      // Remove footer from broadcast message
      const broadcastMessage = removeFooterFromDescription(description, user?.name);

      try {
        setIsSaving(true);
        await sendBroadcast({
          eventId: createdEventId,
          audienceRuleset:
            inviteGroup == '1'
              ? BROADCAST_AUDIENCE.ANYONE_NEARBY
              : BROADCAST_AUDIENCE.LOCAL_INSIDERS_CLUB,
          message: broadcastMessage, // Clean message without footer
        });
        setIsSaving(false);
        navigate(`/meet-up/${createdEventId}`, {replace: true});
      } catch (e) {
        setIsSaving(false);
        ToastMessage({status: 'error', text: 'Failed to send broadcast. Please try again.'});
      }
    } else {
      navigate(`/meet-up/${createdEventId}`, {replace: true});
    }
  };

  // Set initial times (rounded to nearest 5 minutes)
  useEffect(() => {
    const now = new Date();
    const closestInterval = Math.ceil(now.getMinutes() / 5) * 5;
    const nextAvailableTime = addMinutes(startOfToday(), now.getHours() * 60 + closestInterval);
    setStartDate(dayjs(nextAvailableTime).format(DATE_FORMAT));
    setEndDate(dayjs(nextAvailableTime).add(Number(eventDuration), 'hour').format(DATE_FORMAT));
    setMinTime(dayjs(nextAvailableTime).format(DATE_FORMAT));
    setMaxTime(dayjs(nextAvailableTime).add(6, 'weeks').format(DATE_FORMAT));
  }, []);

  // Fetch place and event details if provided.
  useEffect(() => {
    if (!!searchParams.get('placeId')) {
      const placeId = Number(searchParams.get('placeId'));
      if (placeId) {
        fetchPlace({id: placeId}).then((res) => {
          setSelectedPlace(res.data);
        });
      }
    }
    if (!!searchParams.get('eventId')) {
      const eventId = Number(searchParams.get('eventId'));
      if (eventId) {
        fetchEvent({id: eventId}).then((res) => {
          setSelectedEvent(res.data);
        });
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedPlace) {
      const placeId = Number(searchParams.get('placeId'));
      if (
        selectedPlace.id !== placeId &&
        selectedPlace.id !== place?.id &&
        selectedPlace.id !== event?.place?.id
      ) {
        setDescriptionPromptOpen(true);
      } else {
        const placeName = selectedPlace?.name;
        setDescription(generateDescription(placeName, startDate, user?.name));
        if (!searchParams.get('eventId')) {
          setTitle(`${user?.name}'s Meet-Up at ${placeName}`);
        }
      }
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (selectedEvent) {
      const eventName = selectedEvent?.name || selectedEvent?.title;
      setTitle(`${user?.name}'s Meet-Up at ${eventName}`);
      if (dayjs.utc(selectedEvent.start_date_time).isAfter(dayjs().utc())) {
        setStartDate(dayjs.utc(selectedEvent.start_date_time).format(DATE_FORMAT));
        setTimeOption('2');
      }
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (startDate) {
      if (
        previousStartDate !== (null || undefined) &&
        startDate !== dayjs.utc(selectedEvent?.start_date_time).format(DATE_FORMAT)
      ) {
        setDescriptionPromptOpen(true);
      } else {
        const placeName = selectedPlace?.name;
        setDescription(generateDescription(placeName, startDate, user?.name));
      }
    }
  }, [startDate]);

  // Update endDate based on eventDuration (as a number)
  useEffect(() => {
    if (startDate) {
      setEndDate(dayjs(startDate).add(Number(eventDuration), 'hour').format(DATE_FORMAT));
    }
  }, [eventDuration, startDate]);

  useEffect(() => {
    if (selectedEvent) {
      const eventName = selectedEvent?.name || selectedEvent?.title;
      setTitle(`${user?.name}'s Meet-Up at ${eventName}`);
      if (dayjs.utc(selectedEvent.start_date_time).isAfter(dayjs().utc())) {
        setStartDate(dayjs.utc(selectedEvent.start_date_time).format(DATE_FORMAT));
        setTimeOption('2');
      }
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (timeOption === '2' && inputRef.current) {
      inputRef.current?.focus();
      if (selectedStartDate) {
        setStartDate(selectedStartDate.format(DATE_FORMAT));
        setEndDate(selectedStartDate.add(Number(eventDuration), 'hour').format(DATE_FORMAT));
      }
    } else if (timeOption === '1') {
      const now = new Date();
      const closestInterval = Math.ceil(now.getMinutes() / 5) * 5;
      const nextAvailableTime = addMinutes(startOfToday(), now.getHours() * 60 + closestInterval);
      setStartDate(dayjs(nextAvailableTime).format(DATE_FORMAT));
    }
  }, [timeOption]);

  const steps = [
    // {
    //   label: 'Select Place',
    //   description: `Choose your meet-up spot. Not sure yet? Just choose the neighborhood you're gooing to be in!`,
    // },
    {label: 'Title & Description', description: 'Enter title and description'},
    {label: 'Date, Time & Duration', description: 'Pick date, time and duration'},
    {
      label: 'Ice Breaker',
      description: `Choose something to talk about before your meet-up so you can get to know each other before you hang out!`,
    },
    {label: 'Review', description: 'Confirm your details'},
  ];

  const nextStep = () => {
    direction.current = 1;
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    direction.current = -1;
    setStep((prev) => prev - 1);
  };

  const variants = {
    initial: (custom: number) => ({
      x: custom > 0 ? 300 : -300,
      opacity: 0,
      ease: 'easeInOut',
    }),
    animate: {
      x: 0,
      opacity: 1,
      transition: {duration: 0.3},
      ease: 'easeInOut',
    },
    exit: (custom: number) => ({
      x: custom > 0 ? -300 : 300,
      opacity: 0,
      transition: {duration: 0.3},
      ease: 'easeInOut',
    }),
  };

  const progressValue = (step / steps.length) * 100;
  const currentStep = steps[step - 1];

  return (
    <Flex align="center" justify="center" bg="transparent" p={4}>
      <MeetupCreatedModal
        meet-up={
          {
            title,
            place: selectedPlace,
            user: {id: user?.id},
            start_date_time: dayjs(startDate).utc().format(DATE_FORMAT),
            is_meetup: true,
          } as Event
        }
        isOpen={meetupCreatedOpen}
        onSendBroadcast={() => {
          setMeetupCreatedOpen(false);
          setConfirmationOpen(true);
        }}
        onMaybeLater={() => {
          setMeetupCreatedOpen(false);
          navigate(`/meet-up/${createdEventId}`, {replace: true});
        }}
      />
      <PromptModal
        buttonWidth="fit-content"
        title={`Change the description to:`}
        description={generateDescription(selectedPlace?.name, startDate, user?.name)}
        declineText="Keep Current Description"
        declineCallback={() => setDescriptionPromptOpen(false)}
        acceptCallback={() => {
          setDescription(generateDescription(selectedPlace?.name, startDate, user?.name));
          setDescriptionPromptOpen(false);
        }}
        acceptText="Use This Description"
        isOpen={descriptionPromptOpen}
      />
      {/* <Icon
        onClick={() => navigate(-1)}
        iconName="fi-rr-cross-small"
        style={{
          position: 'absolute',
          right: '18px',
          top: '13px',
          fontSize: '24px',
          height: '24px',
          width: '24px',
          color: black,
          fill: black,
        }}
      /> */}
      <AnimatePresence mode="wait" custom={direction.current}>
        <MotionBox
          key={step}
          custom={direction.current}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          w="100%">
          <Box
            maxW="400px"
            w="100%"
            bg="white"
            p={5}
            borderWidth="1px"
            borderRadius="lg"
            shadow="md">
            <Box mb={4}>
              <Text fontSize="sm" color="gray.600" mb={1}>
                Step {step} of {steps.length}: {currentStep.label}
              </Text>
              <Text fontSize="xs" color="gray.500" mb={2}>
                {currentStep.description}
              </Text>
              <Progress value={progressValue} size="sm" colorScheme="progress" borderRadius="md" />
            </Box>
            {/* {step === 1 && (
              <>
                <Heading size="md" mb={3}>
                  Step 1: Pick a Place
                </Heading>
                <PlaceSelectModal
                  selectedPlace={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                />
                <Flex mt={3} w="100%" justify="flex-end">
                  <BlueButton minW="90px" onClick={nextStep} isDisabled={!selectedPlace}>
                    Next
                  </BlueButton>
                </Flex>
              </>
            )} */}
            {step === 1 && (
              <>
                <Heading size="md" mb={3}>
                  Step 1: Title & Description
                </Heading>
                <Input
                  placeholder="Meet-Up Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  mb={3}
                  isRequired
                />
                <Textarea
                  minH={52}
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  mb={3}
                />
                <Flex w="100%" justify="flex-end">
                  {/* <Button w="90px" borderRadius="22px" onClick={prevStep} mr={2}>
                    Back
                  </Button> */}
                  <BlueButton minW="90px" onClick={nextStep} isDisabled={!title || !description}>
                    Next
                  </BlueButton>
                </Flex>
              </>
            )}
            {step === 2 && (
              <>
                <Heading size="md" mb={3}>
                  Step 2: Date, Time & Duration
                </Heading>
                <RadioGroup onChange={setTimeOption} value={timeOption} mb={4}>
                  <Stack direction="row">
                    <Radio value="1" colorScheme="green">
                      Now
                    </Radio>
                    <Radio value="2" colorScheme="green">
                      Later
                    </Radio>
                  </Stack>
                </RadioGroup>
                {timeOption === "2" ? (
                  <Input
                    ref={inputRef}
                    type="datetime-local"
                    max={maxTime}
                    min={minTime}
                    border="1px solid #E5E5E5 !important"
                    fontFamily="Gordita"
                    fontSize="12px !important"
                    fontWeight="500"
                    defaultValue={startDate}
                    onChange={(e) => {
                      const selectedDate = dayjs(e.target.value);
                      setSelectedStartDate(selectedDate);
                      setStartDate(selectedDate.format(DATE_FORMAT));
                      setEndDate(
                        selectedDate.add(Number(eventDuration), "hour").format(DATE_FORMAT)
                      );
                    }}
                    onClick={() => inputRef.current?.focus()}
                    borderRadius="22.5px !important"
                    padding="9px 24px"
                    placeholder="Select Date and Time"
                    size="xs"
                    height="auto"
                  />
                ) : (
                  <Text fontSize="md" fontWeight="medium">
                    Starting Now!
                  </Text>
                )}
                <Heading size="md" mb={3} mt={6}>
                  How long will your meet-up last?
                </Heading>
                <Select
                  value={eventDuration}
                  onChange={(e) => setEventDuration(e.target.value)}
                  mb={3}
                >
                  {Array.from({ length: 24 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>
                      {num} {num === 1 ? "hour" : "hours"}
                    </option>
                  ))}
                </Select>
                <Flex mt={3} w="100%" justify="space-between">
                  <Button w="90px" borderRadius="22px" onClick={prevStep} mr={2}>
                    Back
                  </Button>
                  <BlueButton minW="90px" onClick={nextStep} isDisabled={!startDate}>
                    Next
                  </BlueButton>
                </Flex>
              </>
            )}
            {step == 3 && (
              <>
                <Heading size="md" mb={3}>
                  Step 3: Choose an Ice Breaker
                </Heading>
                <IceBreakerSelect
                  iceBreaker={iceBreaker}
                  setIceBreaker={setIceBreaker}
                  customIceBreaker={customIceBreaker}
                  setCustomIceBreaker={setCustomIceBreaker}
                />
                <Flex w="100%" mt={3} justify="space-between">
                  <Button w="90px" borderRadius="22px" onClick={prevStep} mr={2}>
                    Back
                  </Button>
                  <BlueButton minW="90px" onClick={nextStep}>
                    Next
                  </BlueButton>
                </Flex>
              </>
            )}
            {step === 4 && (
              <>
                <Heading size="md" mb={3}>
                  Step 4: Review
                </Heading>
                <MeetUpCard
                  onClick={() => {}}
                  meet-up={
                    {
                      title,
                      place: selectedPlace,
                      user: { id: user?.id },
                      start_date_time: dayjs(startDate).utc().format(DATE_FORMAT),
                      is_meetup: true,
                    } as Event
                  }
                />
                <>
                  <PromptModal
                    buttonWidth="fit-content"
                    title={`Change the description to:`}
                    description={generateDescription(selectedPlace?.name, startDate, user?.name)}
                    declineText="Keep Current Description"
                    declineCallback={() => setDescriptionPromptOpen(false)}
                    acceptCallback={() => {
                      setDescription(
                        generateDescription(selectedPlace?.name, startDate, user?.name)
                      );
                      setDescriptionPromptOpen(false);
                    }}
                    acceptText="Use This Description"
                    isOpen={descriptionPromptOpen}
                  />
                  <BroadcastConfirmationModal
                    isSaving={isSaving}
                    description={description}
                    reachNum={reachUserCount}
                    sendBroadcast={handleSendBroadcast}
                    confirmationOpen={confirmationOpen}
                    setConfirmationOpen={setConfirmationOpen}
                    setInviteGroup={setInviteGroup}
                    inviteGroup={inviteGroup}
                  />
                  <VStack align="start" h="100%">
                    <VStack py={3} px={0} w="100%" gap="12px">
                      <FormControl>
                        <FormLabel display="flex" alignItems="center">
                          <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                            Select a Place
                          </TextG14>
                        </FormLabel>
                        <PlaceSelectModal
                          selectedPlace={selectedPlace}
                          setSelectedPlace={setSelectedPlace}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel display="flex" alignItems="center">
                          <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                            Name Your Invitation
                          </TextG14>
                        </FormLabel>
                        <Textarea
                          resize="none"
                          borderRadius="10px !important"
                          minH="85px"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Enter a title"
                          py={3}
                          px={4}
                          color={theme.colors.lightBlack}
                          _placeholder={{
                            color: theme.colors.lightestGrey,
                            fontWeight: "500",
                            fontSize: "12px",
                            fontFamily: "Gordita",
                          }}
                          border={`1px solid ${theme.colors.lighterGrey} !important`}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel display="flex" alignItems="center">
                          <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                            Describe Your Meet-Up
                          </TextG14>
                        </FormLabel>
                        <Textarea
                          resize="none"
                          borderRadius="10px !important"
                          minH="80px"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Tell your neighbors what you're doing and who to look for -- ex: I'm grabbing a beer by the bar, look for the green hat!"
                          py={3}
                          px={4}
                          color={theme.colors.lightBlack}
                          _placeholder={{
                            fontFamily: "Gordita",
                            color: theme.colors.lightestGrey,
                            fontWeight: "500",
                            fontSize: "12px",
                            paddingRight: "3rem",
                          }}
                          border={`1px solid ${theme.colors.lighterGrey} !important`}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel display="flex" alignItems="center">
                          <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                            Select a Time
                          </TextG14>
                        </FormLabel>
                        <input type="hidden" id="timezone" name="timezone" value="-08:00" />
                        <RadioGroup onChange={setTimeOption} value={timeOption}>
                          <Stack direction="column">
                            <Radio
                              colorScheme="green"
                              value="1"
                              transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease"
                            >
                              <TextG14>Now</TextG14>
                            </Radio>
                            <Radio
                              colorScheme="green"
                              value="2"
                              transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease"
                            >
                              <Flex flexDir="row" align="center" gap="10px">
                                <TextG14 whiteSpace="nowrap">Later</TextG14>
                                <Input
                                  ref={inputRef}
                                  isDisabled={timeOption != "2"}
                                  max={maxTime}
                                  min={minTime}
                                  border="1px solid #E5E5E5 !important"
                                  fontFamily="Gordita"
                                  fontSize="12px !important"
                                  fontWeight="500"
                                  defaultValue={startDate}
                                  onChange={(e) => {
                                    const selectedDate = dayjs(e.target.value);
                                    setSelectedStartDate(selectedDate);
                                    setStartDate(selectedDate.format(DATE_FORMAT));
                                    setEndDate(
                                      selectedDate
                                        .add(Number(eventDuration), "hour")
                                        .format(DATE_FORMAT)
                                    );
                                  }}
                                  onClick={(e) => {
                                    inputRef.current?.focus();
                                  }}
                                  borderRadius="22.5px !important"
                                  padding="9px 24px"
                                  placeholder="Select Date and Time"
                                  size="xs"
                                  type="datetime-local"
                                  height="auto"
                                />
                              </Flex>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                      <FormControl>
                        <Flex flexDir="row" alignItems="stretch" gap="10px">
                          <TextG14 color={theme.colors.lightBlack}>For</TextG14>
                          <HourSelect
                            hourSelected={eventDuration}
                            onChange={(value) => setEventDuration(value)}
                          />
                          <TextG14 color={theme.colors.lightBlack}>
                            {Number(eventDuration) == 1 ? "Hour" : "Hours"}
                          </TextG14>
                        </Flex>
                      </FormControl>
                      <FormControl>
                        <FormLabel display="flex" alignItems="center">
                          <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                            Ice Breaker
                          </TextG14>
                        </FormLabel>
                        <FormHelperText mb={4}>
                          <TextG12 color={theme.colors.grey}>
                            Choose something to talk about before your meet-up so you can get to
                            know each other before you hang out!
                          </TextG12>
                        </FormHelperText>
                        <IceBreakerSelect
                          iceBreaker={iceBreaker}
                          setIceBreaker={setIceBreaker}
                          customIceBreaker={customIceBreaker}
                          setCustomIceBreaker={setCustomIceBreaker}
                        />
                      </FormControl>
                    </VStack>
                  </VStack>
                </>
                <Flex w="100%" mt={3} justify="space-between">
                  <Button w="90px" borderRadius="22px" onClick={prevStep} mr={2}>
                    Back
                  </Button>
                  <BlueButton
                    isDisabled={!selectedPlace || !startDate || !title || !description}
                    minW="90px"
                    onClick={handleCreateClicked}
                  >
                    <TextG14>Create My Meet-Up</TextG14>
                  </BlueButton>
                </Flex>
              </>
            )}
          </Box>
        </MotionBox>
      </AnimatePresence>
    </Flex>
  );
};

export default MeetupForm;
