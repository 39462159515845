import React from 'react';
import {Button, useTheme} from '@chakra-ui/react';

export function BlueButton({children, ...props}) {
  const theme = useTheme();

  return (
    <Button
      _selected={{}}
      _hover={{}}
      background={theme.colors.blue}
      _disabled={{
        background: theme.colors.lighterGrey,
        color: theme.colors.lightestGrey,
      }}
      color="white"
      borderRadius="22px"
      px={4}
      {...props}>
      {children}
    </Button>
  );
}

export function CustomButton({children, background, color, width = 'full', ...props}) {
  return (
    <Button
      _selected={{}}
      _hover={{}}
      width={width}
      background={background}
      color={color}
      borderRadius="22px"
      px={4}
      {...props}>
      {children}
    </Button>
  );
}

export function BlueButtonFixedWidth({children, ...props}) {
  const theme = useTheme();
  return (
    <BlueButton
      _selected={{}}
      _hover={{}}
      w="190px"
      _disabled={{
        background: theme.colors.lighterGrey,
        color: theme.colors.lightestGrey,
      }}
      {...props}>
      {children}
    </BlueButton>
  );
}

export function WhiteButton({children, ...props}) {
  const theme = useTheme();

  return (
    <Button
      _selected={{}}
      _hover={{}}
      background="white"
      color={theme.colors.blue}
      borderRadius="22px"
      px={4}
      {...props}>
      {children}
    </Button>
  );
}

export function WhiteButtonFixedWidth({children, ...props}) {
  return (
    <WhiteButton _selected={{}} _hover={{}} w="190px" {...props}>
      {children}
    </WhiteButton>
  );
}

export function WhiteGreyButton({children, ...props}) {
  const theme = useTheme();

  return (
    <Button
      _selected={{}}
      _hover={{}}
      background="white"
      color={theme.colors.grey}
      borderRadius="15px"
      border={`1px solid ${theme.colors.lightestGrey}`}
      {...props}>
      {children}
    </Button>
  );
}

export function DarkWhiteButton({children, ...props}) {
  const theme = useTheme();

  return (
    <Button
      _selected={{}}
      _hover={{}}
      background={theme.colors.darkerWhite}
      color={theme.colors.darkGrey}
      borderRadius="7px"
      {...props}>
      {children}
    </Button>
  );
}
