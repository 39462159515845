import {GlobalSearchParams} from "../../../utils/GlobalSearch/types";

export interface FilterOption {
  value?: string;
  label?: string;
  id?: string;
  type?: string;
  isSingle?: boolean;
  featuredContent?: number;
}

export interface RecentOption {
  term?: string;
  tags?: FilterOption[];
  goals?: FilterOption[];
  type?: string;
  isSingle?: boolean;
  featuredContent?: number;
  value?: string;
  label?: string;
  params?: GlobalSearchParams;
}

export const entityOptions: FilterOption[] = [
  { value: "all", label: "All", isSingle: true, type: "All" },
  { value: "offer", label: "Offers", isSingle: true, type: "Offer" },
  { value: "event", label: "Events", isSingle: true, type: "Event" },
  { value: "place", label: "Places", isSingle: true, type: "Place" },
  { value: "post", label: "Posts", isSingle: true, type: "Post" },
  { value: "partner", label: "Partners", isSingle: true, type: "Partner" },
];

export interface GroupedOption {
  label?: string;
  options: FilterOption[] | RecentOption[];
}

export type SearchOptions = FilterOption | string | RecentOption;

export const initialGroupOptions: GroupedOption[] = [
  {
    options: entityOptions,
  },
];

export const initialRecentGroupOptions: GroupedOption[] = [
  {
    options: [],
  },
];

export const customStyles = {
  control: (provided, state) => ({
    borderRadius: "40px",
    border: "1px solid #757D84",
    display: "flex",
    marginTop: "15px",
    height: "45px",
    backgroundColor: 'white',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "40px",
    width: "100%",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: "0px 4px 2px 0",
  }),
  indicatorSeparator: (provided, state) => ({
    display: "none",
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    fontSize: "12px",
    fontWeight: "500",
    marginRight: "20px",
    opacity: "1",
    maxWidth: "130px",
    gridTemplateColumns: "0fr",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#276ACD",
    fontWeight: 700,
  }),
  option: (provided, state) => {
    if ("term" in state.data) {
      return {
        ...provided,
        backgroundColor: "unset",
        padding: "0",
        height: "40px",
        display: "flex",
      };
    }
    return {
      ...provided,
      paddingTop: "6px",
      paddingBottom: "6px",
      fontWeight: 400,
      fontSize: "10px",
      display: "flex",
      alignItems: "center",
      color: state.isSelected ? "#FFFFFF" : "#757D84",
      border: `1px solid ${state.isSelected ? "#3FB8FC" : "#757D84"}`,
      borderRadius: "8px",
      backgroundColor: state.isSelected ? "#3FB8FC" : "#FFFFFF",
      justifyContent: "center",
      height: "22px",
      whiteSpace: "nowrap",
      width: "auto",
      maxWidth: "120px",
    };
  },
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#F4F4F4",
      borderRadius: "7px",
      fontWeight: 400,
      fontSize: "10px",
      display: "none",
      ":first-of-type": {
        display: "block",
      },
      ":nth-of-type(2)": {
        display: "block",
      },
    };
  },
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#272929",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    padding: "4px 10px",
  }),
  container: (provided, state) => ({
    ...provided,
    margin: "0 0 15px 0",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000,
    borderRadius: "15px",
    marginTop: "10px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: "0 15px 0",
  }),
  groupHeading: (provided, state) => {
    if (
      (state.data.label === undefined || state.data.label === "") &&
      !("term" in state.data.options[0])
    ) {
      return {
        ...provided,
        display: "none",
      };
    }
    return {
      ...provided,
      fontSize: "8px",
      paddingLeft: "0",
      color: "#272929",
    };
  },
  group: (provided, state) => {
    if (
      (state.data.label === undefined || state.data.label === "") &&
      !("term" in state.data.options[0])
    ) {
      return {
        ...provided,
        backgroundColor: "#F4F7F8",
        padding: "15px",
        borderRadius: "10px",
        marginTop: "15px",
        justifyContent: "space-around",
        display: "flex",
        marginBottom: "15px",
      };
    }

    return provided;
  },
  multiValueContainer: (provided, state) => {
    return {
      ...provided,
      width: "100%"
    };
  },
};

