import {Box, Button, Center, Spinner, Textarea, useTheme, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {EventRSVPStatus, useSetAttendance} from '../../hooks/useEvent';
import {useCreateEventComment} from '../../hooks/useEventComments';
import {BlueButton} from '../button';
import Modal from '../Modals/Modal';
import {TextG10, TextG12, TextG14, TextG18} from '../typography';

const MAX_ICE_BREAKER_LENGTH = 255;

const JoinMeetUpModal = ({event, show, setShow, routeAfterCreate = true}) => {
  const theme = useTheme();
  const {mutateAsync} = useSetAttendance();
  const addEventComment = useCreateEventComment();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);
  const [iceBreakerText, setIceBreakerText] = useState('');

  const handleJoinClicked = async () => {
    setIsSaving(true);
    const join = await mutateAsync({
      eventId: event.id,
      prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
      status: EventRSVPStatus.GOING,
    });

    if (join.data.status) {
      await addEventComment.mutateAsync({
        eventId: event.id,
        content: `Ice Breaker: ${event.reason}
        
        ${iceBreakerText}`,
      });
    }

    setIsSaving(false);
    setShow(false);
    if (routeAfterCreate) navigate(`/event/${event.id}/chat`);
  };

  return (
    <Modal
      parentCallback={() => setShow(false)}
      //isCentered
      headerPadding="0"
      modalPadding="0"
      show={show}
      children={
        <Box margin="0 !important">
          <Box
            py="15px"
            borderTopRadius="0.375rem"
            textAlign="center"
            background={theme.colors.yellow}>
            <TextG14 fontWeight="500">Answer the Ice Breaker to Join!</TextG14>
          </Box>
          <VStack mt="25px" py={2} px={6}>
            {isSaving ? (
              <Center height="200px">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor={theme.colors.lightGrey}
                  color={theme.colors.lightBlue}
                  size="lg"
                />
              </Center>
            ) : (
              <>
                <TextG18 mb="12px" fontWeight="700">
                  {event.reason}
                </TextG18>
                <Box w="100%" mb="0.5rem !important">
                  <Textarea
                    value={iceBreakerText}
                    onChange={(e) =>
                      (e.target.value.length || 0) <= MAX_ICE_BREAKER_LENGTH &&
                      setIceBreakerText(e.target.value)
                    }
                    resize="none"
                    w="100%"
                    h="115px"
                  />
                  <TextG10
                    w="100%"
                    textAlign="right"
                    mt="6px"
                    pr="4px"
                    color={theme.colors.lighterGrey}>
                    {iceBreakerText.length || 0}/{MAX_ICE_BREAKER_LENGTH}
                  </TextG10>
                </Box>
                <BlueButton
                  onClick={handleJoinClicked}
                  isDisabled={!iceBreakerText || iceBreakerText.length < 1}
                  w="100%">
                  Join the Meet-Up
                </BlueButton>
                <Button background="transparent" color={theme.colors.grey} fontWeight="400">
                  <TextG12 onClick={() => setShow(false)}>Cancel</TextG12>
                </Button>
              </>
            )}
          </VStack>
        </Box>
      }></Modal>
  );
};

export default JoinMeetUpModal;
