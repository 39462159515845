import React from 'react';
import {useTheme, Flex, Link} from '@chakra-ui/react';
import {TextG10, TextG12} from '../../../components/typography';
import Icon from '../../../components/Icon';
import getPlaceAddress, {getDirectionsLinkFromEvent} from '../../../utils/address';
import {Event} from '../../../types/acrticle';
import {ToastMessage} from '../../../components/toast';
import {atcb_action} from 'add-to-calendar-button-react';
import {ATCBActionEventConfig} from 'add-to-calendar-button';
import dayjs from 'dayjs';
import {sendCalendarEventNativeApp} from '../../../pushService';
import ExternalLink from '../../../components/ExternalLink';

const IconsHeader = ({
  event,
  setSignUpRequiredOpen,
  inviteCode,
}: {
  event: Event;
  setSignUpRequiredOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inviteCode?: string;
}) => {
  const theme = useTheme();

  const isWalkaboutEvent = Boolean(event.is_walkabout_official);

  const inviteLink = inviteCode
    ? `/event/${event.id}/preview?invite-code=${inviteCode}`
    : `/event/${event.id}/preview`;

  const addToCalendarConfig: ATCBActionEventConfig = {
    name: event.title,
    description: event.description,
    startDate: dayjs.utc(event.start_date_time).local().format('YYYY-MM-DD'),
    startTime: dayjs.utc(event.start_date_time).local().format('HH:mm'),
    endDate: dayjs.utc(event.end_date_time).local().format('YYYY-MM-DD'),
    endTime: dayjs.utc(event.end_date_time).local().format('HH:mm'),
    location: event.place
      ? getPlaceAddress(event.place)
      : event.location
      ? event.location
      : `${event.latitude}, ${event.longitude}`,
    options: ['Google', 'Outlook.com', 'Apple', 'iCal'],
    hideCheckmark: true,
    timeZone: 'America/Los_Angeles',
  };

  const handleInviteClicked = () => {
    const eventLink = location.origin + inviteLink;
    navigator.clipboard.writeText(eventLink);
    ToastMessage({
      status: 'success',
      text: 'Invite link copied to your clipboard',
    });
  };

  const handleAppleClicked = () => {
    sendCalendarEventNativeApp({
      name: event.title,
      description: event.description,
      startDate: dayjs.utc(event.start_date_time).local().format('YYYY-MM-DDTHH:mm:ss'),
      //startTime: dayjs.utc(event.start_date_time).tz('America/Los_Angeles').format('hh:mm'),
      endDate: dayjs.utc(event.end_date_time).local().format('YYYY-MM-DDTHH:mm:ss'),
      //endTime: dayjs.utc(event.end_date_time).tz('America/Los_Angeles').format('hh:mm'),
      location: event.place
        ? getPlaceAddress(event.place)
        : event.location
        ? event.location
        : `${event.latitude}, ${event.longitude}`,
    });
  };

  const handleCalendarClicked = () => {
    atcb_action(addToCalendarConfig);

    setTimeout(() => {
      const appleBtn = document
        .getElementById('atcb-btn-custom-modal-host')
        ?.shadowRoot?.getElementById('atcb-btn-custom-apple');
      appleBtn?.addEventListener('click', handleAppleClicked);
    }, 300);
  };

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  return (
    <Flex flexWrap="wrap" mt={6} w="100%" justify="space-evenly" gap=".5rem" fontWeight={500}>
      {event.place && (
        <ExternalLink href={getDirectionsLinkFromEvent(event)} isExternal>
          <Flex direction="column" align="center">
            <Icon iconName="fi-rr-map-marker" style={iconStyle} />
            <TextG12 color={theme.colors.lightBlue}>Directions</TextG12>
          </Flex>
        </ExternalLink>
      )}

      <Flex
        direction="column"
        align="center"
        color={theme.colors.grey}
        opacity={0.25}
        onClick={() => setSignUpRequiredOpen(true)}>
        <Icon iconName="fi-rr-users-alt" style={iconStyle} />
        <TextG10 mt="2px">Create a Meet-Up</TextG10>
      </Flex>

      <Flex direction="column" align="center" onClick={handleInviteClicked}>
        <Icon iconName="fi-rr-user-add" style={iconStyle} />
        <TextG10 mt="2px" color={theme.colors.lightBlue}>
          Invite Friends
        </TextG10>
      </Flex>

      {isWalkaboutEvent && (
        <Flex
          onClick={() => setSignUpRequiredOpen(true)}
          color={theme.colors.grey}
          opacity={0.25}
          direction="column"
          align="center">
          <Icon iconName="fi-rr-comment" style={{fontSize: '1.5rem'}} />
          <TextG12>Event Chat</TextG12>
        </Flex>
      )}

      <Flex direction="column" align="center" onClick={handleCalendarClicked}>
        <Icon iconName="fi-rr-calendar" style={iconStyle} />
        <TextG12 color={theme.colors.lightBlue}>Add to Cal</TextG12>
      </Flex>
    </Flex>
  );
};

export default IconsHeader;
