import {Box, useTheme, VStack} from '@chakra-ui/react';
import React, {useCallback} from 'react';
import Countdown from 'react-countdown';
import {ActiveRedemption} from '../../../../../../hooks/useActiveRedemptions';
import useAuth from '../../../../../../hooks/useAuth';
import {Offer, OfferType} from '../../../../../../types/acrticle';
import {BlueButton} from '../../../../../button';
import {TextA18, TextG10, TextG12, TextG14} from '../../../../../typography';

const RedemptionStepThree = ({
  offer,
  setShowModal,
  timeRemaining,
  voucherCode,
  removeActiveRedemption,
  activeRedemption,
  voucher,
}: {
  offer: Offer;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  timeRemaining: number;
  voucherCode: string;
  removeActiveRedemption?: (redemption: ActiveRedemption) => void;
  activeRedemption?: ActiveRedemption;
  voucher?: Record<string, string> | null;
}) => {
  const theme = useTheme();
  const pointCost = offer.points_redeem_amount;
  const {user} = useAuth();

  const renderer = useCallback(({hours, minutes, seconds, completed, formatted}) => {
    if (offer?.offer_type == OfferType.EVENT_REDEMPTION || !offer?.countdown_required) return null;
    return (
      <TextG12>
        {minutes}:{formatted.seconds} Remaining
      </TextG12>
    );
  }, []);

  return (
    <>
      <TextG12
        py="2px"
        color={theme.colors.lightestGrey}
        px={4}
        fontWeight="500"
        isTruncated="&hellip;"
        noOfLines={1}>
        {`${pointCost.toLocaleString()} points used for this redemption`}
      </TextG12>
      {offer.offer_type === OfferType.EVENT_REDEMPTION ? (
        <Box
          py={3}
          px={2}
          textAlign="center"
          border={`1px solid ${theme.colors.lightGrey}`}
          borderRadius="7px">
          <VStack>
            <TextG10 fontSize="20px" color="#329241" fontWeight="700">
              You're Going!
            </TextG10>
            <TextG14
              fontWeight="500"
              color={theme.colors.grey}>{`RSVP for: ${user?.name} ${user?.surname}`}</TextG14>
            <TextG14 fontWeight="400" color={theme.colors.grey}>{`1 ${
              offer.quantity_type ? ` ${offer.quantity_type}` : ``
            } confirmed`}</TextG14>
            <TextG14 fontWeight="400" color={theme.colors.grey}>
              This voucher is saved in your Perks. We'll be sending you more details by e-mail
              as we get closer to the date.
            </TextG14>
            <TextG14 fontWeight="400" color={theme.colors.grey}>
              {`CODE: ${voucherCode}`}
            </TextG14>
          </VStack>
        </Box>
      ) : (
        <Box
          pt={2}
          pb={4}
          px={2}
          textAlign="center"
          border={`1px dashed ${theme.colors.blue}`}
          borderRadius="7px">
          <VStack>
            <TextG14 fontWeight="700">Present this code at checkout</TextG14>
            <TextA18 color="green">{voucherCode}</TextA18>
            <Countdown
              onComplete={() => {
                if (removeActiveRedemption) {
                  if (activeRedemption) removeActiveRedemption(activeRedemption);
                  else {
                    if (voucher) {
                      removeActiveRedemption({
                        id: timeRemaining,
                        offer: offer,
                        voucherCode: voucher?.code,
                        timeRemaining: timeRemaining,
                      });
                    }
                  }
                }
                setShowModal(false);
              }}
              date={timeRemaining}
              renderer={renderer}
            />
          </VStack>
        </Box>
      )}

      <Box>
        <BlueButton
          background={theme.colors.blue}
          w="180px"
          onClick={() => setShowModal(false)}
          _focus={{boxShadow: 'none'}}
          _hover={{background: ''}}>
          <TextG12 color={theme.colors.white} fontWeight="500">Done</TextG12>
        </BlueButton>
      </Box>
    </>
  );
};

export default RedemptionStepThree;
