import React from 'react';
import {Modal, ModalOverlay, ModalContent, ModalBody} from '@chakra-ui/react';
import Place from '../../../pages/Place';
import Event from '../../../pages/Event';
import Post from '../../../pages/Post';
import Partner from '../../../pages/Partner';
import Meetup from '../../../pages/Meetup';

const ArticleModal = ({type, open, close}: {type: string; open: boolean; close: () => void}) => {
  return (
    <>
      <Modal size="full" isOpen={open} onClose={close} motionPreset="none">
        <ModalOverlay zIndex="1300" background="white" />
        <ModalContent
          containerProps={{zIndex: '1300'}}
          zIndex="1301"
          pb="64px"
          borderRadius={0}
          boxShadow="none">
          <ModalBody padding={0}>
            {type === 'place' && <Place onClose={close} />}
            {type === 'event' && <Event onClose={close} />}
            {type === 'post' && <Post onClose={close} />}
            {type === 'partner' && <Partner onClose={close} />}
            {type === 'meet-up' && <Meetup onClose={close} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ArticleModal;
