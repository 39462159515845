import React from 'react';
import {Outlet} from 'react-router-dom';
import {Container} from '@chakra-ui/react';
import PostCard from '../../components/Articles/PostCard';
import EventCard from '../../components/Articles/EventCard';
import PlaceCard from '../../components/Articles/PlaceCard';
import ArticleTabsNavigation, {TabsInterface} from '../../components/ArticleTabsNavigation';
import {fetchEvents, fetchOffers, fetchPlaces} from '../../endpoints/api';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import {useInfinitePlaces} from '../../hooks/usePlaces';
import {useInfinitePlacesByType} from '../../hooks/useFetchPlacesByType';
import {useInfiniteEvents} from '../../hooks/useEvents';
import {useInfinitePosts} from '../../hooks/usePosts';
import {useInfiniteOffers} from '../../hooks/useOffers';

export const articleTabs: TabsInterface = {
  place: {
    tabName: 'Places',
    tabElement: <PlaceCard modal />,
    //endpoint: fetchPlaces,
    renderArticleType: false,
    hook: useInfinitePlacesByType,
  },
  event: {
    tabName: 'Events',
    tabElement: <EventCard modal />,
    //endpoint: fetchEvents,
    renderArticleType: false,
    hook: useInfiniteEvents,
  },
  post: {
    tabName: 'Posts',
    tabElement: <PostCard modal />,
    renderArticleType: false,
    hook: useInfinitePosts,
  },
  offer: {
    tabName: 'Offers',
    tabElement: <OfferCard hideActive={false} />,
    //endpoint: fetchOffers,
    renderArticleType: false,
    hook: useInfiniteOffers,
  },
};

const Tabs = () => {
  return (
    <Container pt={'113px'} pb={10} h="auto">
      <ArticleTabsNavigation articleTabs={articleTabs} />
      <Outlet />
    </Container>
  );
};

export default Tabs;
