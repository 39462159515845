import {MeetUp, PostUpvote} from '../types/article';
import {apiHttp} from '.';
import {map, omit} from 'lodash';
import {SortOptionsValue} from '../components/SortPlaces/index';
import {Address} from '../types/address';
import dayjs from 'dayjs';
import {GlobalSearchParams} from '../utils/GlobalSearch/types';
import {BROADCAST_AUDIENCE} from '../hooks/useBroadcast';

/**
 * Validate invitation code and return true if code is valid.
 */
export function validateInvitationCode(invitationCode) {
  return apiHttp.post({
    url: '/invite-codes/validate',
    params: {code: invitationCode},
  });
}

/**
 * Perform a soft check and allow user to register without invitation code
 */
export function softCheck(params) {
  return apiHttp.post({
    url: '/user/soft-check',
    params,
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Try to login and get api token.
 */
export function login(params) {
  return apiHttp.post({
    url: '/user/login',
    params,
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Try to login and get api token.
 */
export function register(params) {
  return apiHttp.post({
    url: '/user/register',
    params,
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Fetch user data.
 */
export function fetchUserData() {
  return apiHttp.get({
    url: '/user/profile',
  });
}

/**
 * Registration completed.
 */
export function completeRegistration() {
  return apiHttp.post({
    url: '/user/registration-completed',
  });
}

/**
 * Fetch user active passes
 */
export function fetchUserActivePasses() {
  return apiHttp.get({
    url: '/user/active-passes',
  });
}

/**
 * Fetch list of articles.
 */
export function fetchArticles({
  type,
  page,
  cancelReq,
}: {
  type?: string;
  page: string;
  cancelReq: any;
}) {
  return apiHttp.get({
    url: '/articles',
    params: {...(type && {type}), page},
    cancelReq,
  });
}

export function fetchPosts({
  page,
  communityId,
  cancelReq,
}: {
  page: string;
  communityId?: string;
  cancelReq: any;
}) {
  return apiHttp.get({
    url: communityId ? `/articles/get/user-relevant/${communityId}` : '/articles/get/user-relevant',
    params: {page},
    cancelReq,
  });
}

export function fetchPlaces({
  page,
  cancelReq,
  lat,
  long,
  open,
}: {
  page: string;
  cancelReq: any;
  lat?: string;
  long?: string;
  open?: string;
}) {
  return apiHttp.get({
    url: '/places',
    params: {page, ...(lat && {lat}), ...(long && {long}), ...(open && {open})},
    cancelReq,
  });
}

export function fetchEvents({
  page,
  communityId,
  cancelReq,
}: {
  page: string;
  communityId?: string;
  cancelReq: any;
}) {
  return apiHttp.get({
    url: '/events',
    params: {page, ...(communityId && {ci: communityId})},
    cancelReq,
  });
}

export function checkInList({
  term,
  type = 'default',
  page,
  cancelReq,
  lat,
  long,
}: {
  term?: string;
  type: string;
  page: string;
  cancelReq: any;
  lat?: string;
  long?: string;
}) {
  return apiHttp.get({
    url: `/check-in/list/${type}`,
    params: {page, ...(term && {term}), ...(lat && {lat}), ...(long && {long})},
    cancelReq,
  });
}
/**
 * Search places
 */
export function searchPlaces({
  term,
  page,
  cancelReq,
  lat,
  long,
}: {
  term: string;
  page: string;
  cancelReq: any;
  lat?: string;
  long?: string;
}) {
  return apiHttp.get({
    url: '/places/search',
    params: {term, page, ...(lat && {lat}), ...(long && {long})},
    cancelReq,
  });
}

/**
 * Sets lifestyle to current user
 */
export function setLifestyle(lifestyleId) {
  return apiHttp.post({
    url: `user/lifestyle-groups/add/${lifestyleId}`,
  });
}

/**
 * Removes lifestyle to current user
 */
export function removeLifestyle(lifestyleId) {
  return apiHttp.delete({
    url: `user/lifestyle-groups/remove/${lifestyleId}`,
  });
}

/**
 * Adds selected topic to current user.
 */
export function setTopic(topicId) {
  return apiHttp.post({
    url: `user/topics`,
    params: {topics: [topicId]},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Removes selected topic to current user.
 */
export function removeTopic(topicId) {
  return apiHttp.delete({
    url: `user/topics`,
    config: {data: {topics: [topicId]}},
  });
}

/**
 * Updates user data.
 */
export function updateUser(data) {
  return apiHttp.put({
    url: `user/update`,
    params: {...data},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Updates user handle.
 */
export function updateUserHandle(data) {
  return apiHttp.put({
    url: `user/update-handle`,
    params: {...data},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Add bookmark for specified article.
 */
export function addBookmark({
  articleId,
  customUrl,
}: {
  articleId: number;
  customUrl: (articleId) => string;
}) {
  return apiHttp.post({
    url: customUrl ? customUrl(articleId) : `user/bookmarks/add/${articleId}`,
  });
}

/**
 * Remove bookmark for specified article.
 */
export function removeBookmark({
  articleId,
  customUrl,
}: {
  articleId: number;
  customUrl: (articleId) => string;
}) {
  return apiHttp.delete({
    url: customUrl ? customUrl(articleId) : `user/bookmarks/remove/${articleId}`,
  });
}

/**
 * Fetch list of bookmarks.
 */
export async function fetchBookmarks({
  type,
  page,
  cancelReq,
}: {
  type?: string;
  page: string;
  cancelReq: any;
}) {
  return apiHttp.get({
    url: '/user/bookmarks',
    params: {...(type && {type}), page},
    cancelReq,
  });
}

/**
 * Fetch featured contents
 */
export async function fetchFeaturedContents({
  page,
  cancelReq,
  communityId,
}: {
  page: string;
  cancelReq: any;
  communityId?: string;
}) {
  return apiHttp.get({
    url: '/featured-contents',
    params: {page, ...(communityId && {ci: communityId})},
    cancelReq,
  });
}

/**
 * Fetch lifestyle groups.
 */
export function fetchLifestyleGroups() {
  return apiHttp.get({
    url: '/lifestyle-groups',
  });
}

/**
 * Fetch specific place.
 */
export function fetchPlace({id, lat, long}: {id: number; lat?: number; long?: number}) {
  return apiHttp.get({
    url: `/places/${id}`,
    params: {...(lat && {lat}), ...(long && {long})},
  });
}

/**
 * Fetch specific article.
 */
export function fetchArticle({id}: {id: number}) {
  return apiHttp.get({
    url: `/articles/${id}`,
  });
}

/**
 * Fetch specific event.
 */
export function fetchEvent({id}: {id: number}) {
  return apiHttp.get({
    url: `/events/${id}`,
  });
}

/**
 * Fetch topics.
 */
export function fetchTopics() {
  return apiHttp.get({
    url: '/topic-categories',
  });
}

export function registerTicketUrlClick(eventId) {
  return apiHttp.get({
    url: `/events/${eventId}/visit-tickets-url`,
  });
}

/**
 * Filter entities.
 */
export function filterEntities({
  page,
  entityType,
  ids,
}: {
  page: string;
  entityType: string;
  ids: number[];
}) {
  return apiHttp.post({
    options: {headers: {'Content-Type': 'application/json'}},
    url: `/filter/${entityType}`,
    params: {page, topic_categories: ids},
  });
}

/**
 * Filter user topics.
 */
export function filterUserTopics({type, id}: {type: 'articles' | 'events'; id: number}) {
  return apiHttp.get({
    url: `/filter/user-topics-related-${type}/${id}`,
  });
}

export function requestVerificationCode(phoneNumber) {
  return apiHttp.post({
    url: '/user/send-sms-verification-code',
    params: {number: phoneNumber},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function requestEmailVerificationCode(email) {
  return apiHttp.post({
    url: '/user/send-email-verification-code',
    params: {email},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function validateSmsCode(phoneNumber, code) {
  return apiHttp.post({
    url: '/user/validate-sms-verification-code',
    params: {number: phoneNumber, code},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function validateEmailCode(email, code) {
  return apiHttp.post({
    url: '/user/validate-email-verification-code',
    params: {email, code},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function validateEmailAndInviteCode(email, code) {
  return apiHttp.post({
    url: 'registration/is-valid-email',
    params: {email, code},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function getPasswordResetCode(emailOrPhone) {
  return apiHttp.post({
    url: '/user/get-password-reset-code',
    params: {email: emailOrPhone},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function verifyPasswordResetCode(email, code) {
  return apiHttp.post({
    url: '/user/verify-password-reset-code',
    params: {email, code},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function setNewPassword(email, password, code) {
  return apiHttp.post({
    url: '/user/set-new-password',
    params: {email, password, code},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function checkInToPlace({
  placeId,
  latitude = 0,
  longitude = 0,
  isPublic,
  offerId,
  sdv = false,
  qr_code_id,
}) {
  return apiHttp.post({
    url: `/places/${placeId}/check-ins`,
    params: {
      latitude,
      longitude,
      is_public: isPublic,
      offerId,
      sdv,
      ...(qr_code_id && {qr_code_id}),
    },
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function checkInToEvent({
  eventId,
  latitude = 0,
  longitude = 0,
  isPublic,
  sdv = false,
  qr_code_id,
}) {
  return apiHttp.post({
    url: `/events/${eventId}/check-ins`,
    params: {latitude, longitude, is_public: isPublic, sdv, ...(qr_code_id && {qr_code_id})},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Upload user profile photo
 */
export function uploadProfilePhoto(formData: FormData) {
  return apiHttp.post({
    url: '/user/profile-photo/save',
    params: {photo: formData.get('file')},
    options: {headers: {'Content-Type': 'multipart/form-data'}},
  });
}

/**
 * Fetch number of users registered in our system.
 */
export function fetchNumOfRegisteredUsers() {
  return apiHttp.get({
    url: '/statistics/users-number',
  });
}

export function fetchRedemptionHistory({page, cancelReq, ...params}) {
  return apiHttp.get({
    url: '/redemption/history',
    params: {page, ...params},
    cancelReq,
  });
}

/**
 * Fetch list of user notifications
 */
export function fetchNotifications({page}: {page: string}) {
  return apiHttp.get({
    url: '/user/notifications',
    params: {page},
  });
}

export function markNotificationRead(id: Number) {
  return apiHttp.post({
    url: `/user/notifications/${id}/mark-as-read`,
  });
}

export function registerNotificationActionUrlClick(id: Number) {
  return apiHttp.get({
    url: `/user/notifications/${id}/action-url-click`,
  });
}

export function markAllNotificationsRead() {
  return apiHttp.post({
    url: '/user/notifications/mark-as-read',
  });
}

export function updateEventAttendance(id: number, status: number) {
  return apiHttp.post({
    url: `/events/${id}/attendances`,
    params: {status: status},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function updateEventNotificationPreference(id: number, receiveNotifications: boolean) {
  return apiHttp.put({
    url: `/events/${id}/attendance/notifications`,
    params: {receive_notifications: receiveNotifications.toString()},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Post an upvote of a place
 */
export function upvotePlace(id: number, upvote: PostUpvote) {
  return apiHttp.post({
    url: `/places/${id}/rate`,
    params: {...upvote},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Mark/Unmark Upvote as help
 */
export function markUpvoteHelpful(id: number, helpful: number) {
  return apiHttp.post({
    url: `/endorsement/${id}/helpful`,
    params: {helpful},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Fetch place types
 * place-types
 */
export function fetchPlaceTypes(params) {
  return apiHttp.get({
    url: `/place-types`,
    params: {...params},
  });
}

/**
 * Fetch place types by category
 */
export function fetchPlaceTypesByCategory(category) {
  return apiHttp.get({
    url: `/place-types-by-category/${category}`,
  });
}

//places/filter-by-types?types[0]=2&types[1]=1
export function filterPlaceByType({
  typeIds,
  page = '1',
  lat,
  long,
  sort = 'default',
  open,
  communityId,
}: {
  typeIds?: string[];
  page?: string;
  lat?: number;
  long?: number;
  sort?: SortOptionsValue;
  open?: string;
  communityId?: string;
}) {
  const params = {};
  if (typeIds?.length) {
    map(typeIds, (type, i) => {
      params[`types[${i}]`] = type;
    });
  }

  if (lat && long) {
    params['lat'] = lat;
    params['long'] = long;
  }

  if (sort) {
    params['sort'] = sort;
  }

  if (open) {
    params['open'] = open;
  }

  return apiHttp.post({
    url: `/places/filter-by-types`,
    params: {...params, page, ...(communityId && {ci: communityId})},
  });
}

export function fetchGeneralSettings() {
  return apiHttp.get({
    url: `/general-settings`,
  });
}

export function fetchPointsDistribution() {
  return apiHttp.get({
    url: `/general-settings/points-distribution`,
  });
}

export function fetchPointsHistory({page, perPage}: {page: string; perPage: number}) {
  return apiHttp.get({
    url: `/user/points-history`,
    params: {page, perPage},
  });
}

export function updateActivity() {
  return apiHttp.post({
    url: `/user/update-activity`,
  });
}

/**
 * Fetch offers
 */
export function fetchOffers({
  page,
  articleType,
  articleId,
  communityId,
}: {
  page: string;
  articleType?: string;
  articleId?: string | number;
  communityId?: string;
}) {
  return apiHttp.get({
    url: `/offers`,
    params: {
      page,
      ...(articleType && articleId && {[articleType]: articleId}),
      ...(communityId && {communityId}),
    },
  });
}

/*
 * Fetch single offer
 */
export function fetchOffer(offerId: number) {
  return apiHttp.get({
    url: `/offers/${offerId}`,
  });
}

/**
 * Redeem offer voucher
 *
 */
export function redeemVoucher(voucherId: string, offerId: number) {
  return apiHttp.post({
    url: `/redemption/redeem-voucher/${voucherId}`,
    params: {offerId: offerId},
  });
}

/**
 * Fetch offer voucher
 */
export function getVoucher(voucherId: string) {
  return apiHttp.get({
    url: `/redemption/show-voucher/${voucherId}`,
  });
}

/**
 * Global entity search
 *
 */
export async function globalSearch({
  page = '1',
  cancelReq = null,
  communityId,
  term,
  ...rest
}: GlobalSearchParams) {
  const params = {
    ...omit(rest, ['filters', 'sort']),
    ...(rest.filters ? rest.filters : {}),
    ...(rest.sort ? {orderBy: rest.sort.by, sort: rest.sort.direction} : {}),
  };

  return apiHttp.get({
    url: `/search2/global/${term}`,
    params: {...params, page, ...(communityId && {ci: communityId})},
    cancelReq,
  });
}
/**
 * Search types and goals
 *
 */
export function searchTags({
  term,
  page,
  cancelReq = null,
}: {
  term: string;
  page?: string;
  cancelReq?: any;
}) {
  return apiHttp.get({
    url: `/search/scope/${term}`,
  });
}

export function fetchTagsForSearch() {
  return apiHttp.get({
    url: `/search2/tags/`,
  });
}

export function fetchSortingOptions() {
  return apiHttp.post({
    url: '/search2/sorting-options',
  });
}

export function fetchUserAddresses({userId, addressType}: {userId?: number; addressType?: string}) {
  if (userId) {
    return apiHttp.get({
      url: `/users/${userId}/addresses`,
      params: {...(addressType && {'filter[type]': addressType})},
    });
  }
}

export function addUserAddress({userId, address}: {userId: number; address: Address}) {
  return apiHttp.post({
    url: `/users/${userId}/addresses`,
    params: {...address},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function fetechAddress({userId, addressId}: {userId: number; addressId: number}) {
  return apiHttp.get({
    url: `/users/${userId}/addresses/${addressId}`,
  });
}

export function updateAddress({
  userId,
  addressId,
  address,
}: {
  userId: number;
  addressId: number;
  address: Address;
}) {
  return apiHttp.put({
    url: `/users/${userId}/addresses/${addressId}`,
    params: {...address},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function deleteAddress({userId, addressId}: {userId: number; addressId: number}) {
  return apiHttp.delete({
    url: `/users/${userId}/addresses/${addressId}`,
  });
}

export function fetchAddressTypes() {
  return apiHttp.get({
    url: `/address-types`,
  });
}
/**
 * Fetch list of valid US States
 */
export function fetchStateList() {
  return apiHttp.get({
    url: `/states`,
  });
}

/**
 * Set user super filters
 */
export function setSuperFilters({topics, goals}: {topics: string[]; goals: string[]}) {
  const params = {};
  map(topics, (topic, i) => {
    params[`topics[${i}]`] = topic;
  });

  map(goals, (goal, i) => {
    params[`lifestyles[${i}]`] = goal;
  });

  return apiHttp.post({
    url: `/user/superfilters/set`,
    params: {...params},
  });
}

/**
 * Fetch home-screen context
 */
export function fetchHomeContext() {
  return apiHttp.get({
    url: `/contexts`,
  });
}

/**
 * Fetch home-screen context by time
 */
export function fetchHomeContextTime() {
  const time = dayjs(Date.now()).format('HH:mm:ss').toString();
  return apiHttp.get({
    url: `/contexts-by-time`,
    params: {time: time},
  });
}

/**
 * Fetch home-screen context content by time
 */
export function fetchHomeContextContentTime({lat, long}: {lat?: number; long?: number}) {
  const time = dayjs(Date.now()).format('HH:mm:ss').toString();
  return apiHttp.get({
    url: `/contextual-content-by-time`,
    params: {time: time, ...(lat && {lat}), ...(long && {long})},
  });
}

/**
 * Fetch Calendars
 */
export function fetchCalendars() {
  return apiHttp.get({
    url: `/calendars`,
  });
}

/**
 * Fetch RSVP stats for an Event
 */
export function fetchEventRSVPStats(eventId: number) {
  return apiHttp.get({
    url: `/events/${eventId}/rsvp-stats`,
  });
}

/**
 * Fetch speciic comment for an Event
 */
export function fetchEventComment({eventId, commentId}: {eventId: number; commentId: string}) {
  return apiHttp.get({
    url: `/events/${eventId}/comment/${commentId}`,
  });
}

/**
 * Fetch comments for an Event
 */
export function fetchEventComments({eventId, page}: {eventId: number; page: string}) {
  return apiHttp.get({
    url: `/events/${eventId}/comments`,
    params: {page},
  });
}

/**
 * Fetch featured comments for an Event
 */
export function fetchFeaturedEventComments({eventId, page}: {eventId: number; page: string}) {
  return apiHttp.get({
    url: `/events/${eventId}/featured-comments`,
    params: {page},
  });
}

/**
 * Fetch replies and mentions for an Event
 */
export function fetchReplyMentionComments({eventId}: {eventId: number}) {
  return apiHttp.get({
    url: `/events/${eventId}/replies-mentions`,
  });
}

/**
 * Create a comment for an Event
 */
export function createEventComment({
  eventId,
  content,
  parent_id,
  is_featured,
}: {
  eventId: number;
  content: string;
  parent_id?: string;
  is_featured?: boolean;
}) {
  return apiHttp.post({
    url: `/events/${eventId}/comment`,
    params: {
      content,
      ...(parent_id && {parent_id}),
      ...(is_featured ? {is_featured: true} : {is_featured: false}),
    },
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Update a comment for an Event
 */
export function updateEventComment({
  eventId,
  commentId,
  content,
}: {
  eventId: number;
  commentId: string;
  content: string;
}) {
  return apiHttp.put({
    url: `/events/${eventId}/comment`,
    params: {event_comment_id: commentId, content},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Delete a comment for an Event
 */
export function deleteEventComment({eventId, commentId}: {eventId: number; commentId: string}) {
  return apiHttp.delete({
    url: `/events/${eventId}/comment/${commentId}`,
  });
}

/**
 * Report a comment for an Event
 */
export function reportComment({eventId, commentId}: {eventId: number; commentId: string}) {
  return apiHttp.post({
    url: `/events/${eventId}/report-as-abusive/${commentId}`,
  });
}

/**
 * Mark a comment as helpful for an Event
 */
export function helpfulComment({eventId, commentId}: {eventId: number; commentId: string}) {
  return apiHttp.post({
    url: `/events/${eventId}/report-as-helpful/${commentId}`,
  });
}

/**
 * Check availablity of user handle
 */
export function fetchUserHandleAvailable(username: string) {
  return apiHttp.post({
    url: `/user/check-username-availability`,
    params: {username},
  });
}

/**
 * Fetch all the events a user has RSVP'd to
 */
export function fetchEventRSVP({page, closed}: {page: string; closed?: string}) {
  return apiHttp.get({
    url: `/events/my-events-rsvp`,
    params: {page, ...(closed && {closed})},
  });
}

/**
 * Fetch new event chat messages
 */
export function fetchNewEventMessages() {
  return apiHttp.get({
    url: `/events/new-messages-count`,
  });
}

/**
 * Update user last chat visit
 */
export function lastChatVisit({eventId}) {
  return apiHttp.post({
    url: `/events/${eventId}/last-chat-visit`,
  });
}

/**
 * Update user last community chat visit
 */
export function lastCommunityChatVisit({communityId}) {
  return apiHttp.post({
    url: `/communities/${communityId}/last-chat-visit`,
  });
}

export function fetchChatUsers({eventId, page}: {eventId?: number; page: string}) {
  if (!eventId) return null;
  return apiHttp.get({
    url: `/events/${eventId}/users`,
    params: {page},
  });
}

/**
 * Fetch comments for a Community
 */
export function fetchCommunityComments({communityId, page}: {communityId: number; page: string}) {
  return apiHttp.get({
    url: `/communities/${communityId}/comments`,
    params: {page},
  });
}

/**
 * Fetch featured comments for a Community
 */
export function fetchFeaturedCommunityComments({
  communityId,
  page,
}: {
  communityId: number;
  page: string;
}) {
  return apiHttp.get({
    url: `/communities/${communityId}/featured-comments`,
    params: {page},
  });
}

/**
 * Fetch replies and mentions for a Community
 */
export function fetchCommunityReplyMentionComments({communityId}: {communityId: number}) {
  return apiHttp.get({
    url: `/communities/${communityId}/replies-mentions`,
  });
}

/**
 * Fetch speciic comment for a Community
 */
export function fetchCommunityComment({
  communityId,
  commentId,
}: {
  communityId: number;
  commentId: string;
}) {
  return apiHttp.get({
    url: `/communities/${communityId}/comment/${commentId}`,
  });
}

/**
 * Create a comment for a Community
 */
export function createCommunityComment({
  communityId,
  content,
  parent_id,
  is_featured,
}: {
  communityId: number;
  content: string;
  parent_id?: string;
  is_featured?: boolean;
}) {
  return apiHttp.post({
    url: `/communities/${communityId}/comment`,
    params: {
      content,
      ...(parent_id && {parent_id}),
      ...(is_featured ? {is_featured: true} : {is_featured: false}),
    },
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Update a comment for a Community
 */
export function updateCommunityComment({
  communityId,
  commentId,
  content,
}: {
  communityId: number;
  commentId: string;
  content: string;
}) {
  return apiHttp.put({
    url: `/communities/${communityId}/comment`,
    params: {community_comment_id: commentId, content},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Delete a comment for a Community
 */
export function deleteCommunityComment({
  communityId,
  commentId,
}: {
  communityId: number;
  commentId: string;
}) {
  return apiHttp.delete({
    url: `/communities/${communityId}/comment/${commentId}`,
  });
}

/**
 * Fetch Community chat users
 */
export function fetchCommunityChatUsers({communityId, page}: {communityId: number; page: string}) {
  return apiHttp.get({
    url: `/communities/${communityId}/users`,
    params: {page},
  });
}

/**
 * Report a comment for an Event
 */
export function reportCommunityComment({
  communityId,
  commentId,
}: {
  communityId: number;
  commentId: string;
}) {
  return apiHttp.post({
    url: `/communities/${communityId}/report-as-abusive/${commentId}`,
  });
}

/**
 * Mark a comment as helpful for an Event
 */
export function helpfulCommunityComment({
  communityId,
  commentId,
}: {
  communityId: number;
  commentId: string;
}) {
  return apiHttp.post({
    url: `/communities/${communityId}/report-as-helpful/${commentId}`,
  });
}

/**
 * Fetch Community
 */
export function fetchCommunity(communityId: number) {
  return apiHttp.get({
    url: `/communities/${communityId}`,
  });
}

/**
 * Fetch Community user count
 */
export function fetchCommunityUserNum(communityId: number) {
  return apiHttp.get({
    url: `/communities/${communityId}/number-of-users`,
  });
}

/**
 * Fetch Community Stripe payment link for community
 */
export function fetchCommunityStripeURL(communityId: number) {
  return apiHttp.post({
    url: `/communities/${communityId}/join?c=${location.origin}/communities/${communityId}&s=${location.origin}/communities/${communityId}?success=1`,
  });
}

export function deleteUser() {
  return apiHttp.delete({
    url: '/user/delete',
  });
}

export function fetchEventShareAndInviteCode(eventId: number, name: string) {
  return apiHttp.post({
    url: `/events/${eventId}/invite-and-share`,
    params: {name: name},
  });
}

export function fetchEventInvites() {
  return apiHttp.get({
    url: `/events/my-invitations`,
  });
}

export function fetchPublicEvent({id}: {id: number}) {
  return apiHttp.get({
    url: `/events/public/${id}`,
  });
}

/**
 * Fetch list of partners
 */
export function fetchPartners({page}: {page: string}) {
  return apiHttp.get({
    url: '/partners',
    params: {page},
  });
}

export function fetchPartner({id}: {id: number}) {
  return apiHttp.get({
    url: `/partners/${id}`,
  });
}

export function fetchMeetups({page}: {page: string}) {
  return apiHttp.get({
    url: '/meetup/all',
    params: {page},
  });
}

export function fetchMeetup({id}: {id: number}) {
  return apiHttp.get({
    url: `/meetup/${id}`,
  });
}

export function createMeetup(meetup: MeetUp) {
  return apiHttp.post({
    url: `/meetup/store`,
    params: {...meetup},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function updateMeetup(meetup: MeetUp) {
  return apiHttp.put({
    url: `/meetup/${meetup.eventId}`,
    params: {...meetup},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function removeMeetup(id: number) {
  return apiHttp.delete({
    url: `/meetup/${id}`,
  });
}

export function reactToMeetup(id: number) {
  return apiHttp.post({
    url: `/meetup/${id}/react`,
  });
}

export function addChatMedia({
  formData,
  target_type,
  target_id,
  comment_type,
  comment_id,
}: {
  formData: FormData;
  target_type: string;
  target_id: string;
  comment_type: string;
  comment_id: string;
}) {
  return apiHttp.post({
    url: '/thread-media',
    params: {
      media: formData.get('file'),
      target_type: target_type,
      target_id,
      comment_type,
      comment_id,
    },
    options: {headers: {'Content-Type': 'multipart/form-data'}},
  });
}

export function deleteChatMedia({mediaId}) {
  return apiHttp.delete({
    url: `/thread-media/${mediaId}`,
  });
}

export function fetchChatMedia({mediaId}) {
  return apiHttp.get({
    url: `/thread-media/${mediaId}`,
  });
}

/**
 * Fetch comments for a Lifestyle
 */
export function fetchLifestyleComments({page}: {page: string}) {
  return apiHttp.get({
    url: `/lfp/comments`,
    params: {page},
  });
}

/**
 * Fetch featured comments for Lifestyle
 */
export function fetchFeaturedLifestyleComments({page}: {page: string}) {
  return apiHttp.get({
    url: `/lfp/featured-comments`,
    params: {page},
  });
}

/**
 * Fetch replies and mentions for Lifestyle
 */
export function fetchLifestyleReplyMentionComments() {
  return apiHttp.get({
    url: `/lfp/replies-mentions`,
  });
}

/**
 * Fetch speciic comment for Lifestyle
 */
export function fetchLifestyleComment({commentId}: {commentId: string}) {
  return apiHttp.get({
    url: `/lfp/comment/${commentId}`,
  });
}

/**
 * Create a comment for Lifestyle
 */
export function createLifestyleComment({
  content,
  parent_id,
  is_featured,
}: {
  content: string;
  parent_id?: string;
  is_featured?: boolean;
}) {
  return apiHttp.post({
    url: `/lfp/comment`,
    params: {
      content,
      ...(parent_id && {parent_id}),
      ...(is_featured ? {is_featured: true} : {is_featured: false}),
    },
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Update a comment for Lifestyle
 */
export function updateLifestyleComment({commentId, content}: {commentId: string; content: string}) {
  return apiHttp.put({
    url: `/lfp/comment`,
    params: {lifestyle_plus_comment_id: commentId, content},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Delete a comment for Lifestyle
 */
export function deleteLifestyleComment({commentId}: {commentId: string}) {
  return apiHttp.delete({
    url: `/lfp/comment/${commentId}`,
  });
}

// /**
//  * Fetch Community chat Lifestyle
//  */
export function fetchLifestyleChatUsers(page: string) {
  return apiHttp.get({
    url: `/lfp/users`,
    params: {page},
  });
}

/**
 * Report a comment for Lifestyle
 */
export function reportLifestyleComment({commentId}: {commentId: string}) {
  return apiHttp.post({
    url: `/lfp/report-as-abusive/${commentId}`,
  });
}

/**
 * Mark a comment as helpful for Lifestyle
 */
export function helpfulLifestyleComment({commentId}: {commentId: string}) {
  return apiHttp.post({
    url: `/lfp/report-as-helpful/${commentId}`,
  });
}

/**
 * Purchase an event ticket
 */
export function purchaseEventTicket({
  event_ticket_ids,
  cash,
  points,
  pass_id,
  willcall_name,
  success_url,
  cancel_url,
}: {
  event_ticket_ids: Array<string>;
  cash?: string;
  points?: string;
  pass_id?: string;
  willcall_name?: string;
  success_url: string;
  cancel_url: string;
}) {
  return apiHttp.post({
    url: `/event-ticket/purchase`,
    params: {event_ticket_ids, cash, points, pass_id, willcall_name, success_url, cancel_url},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Fetch Membership Stripe payment link for membership
 */
export function fetchMembershipStripeLink({
  membershipId,
  sLink,
  cLink,
  promoCode,
}: {
  membershipId: number;
  sLink?: string;
  cLink?: string;
  promoCode?: string;
}) {
  const cancelLink = cLink ? cLink : `${location.origin}/memberships?membership=${membershipId}`;
  const successLink = sLink ? sLink : `${location.origin}/membership/${membershipId}?success=1`;
  if (promoCode) {
    return apiHttp.post({
      url: `/membership-plans/${membershipId}/join?c=${cancelLink}&s=${successLink}&p=${promoCode}`,
    });
  }
  return apiHttp.post({
    url: `/membership-plans/${membershipId}/join?c=${cancelLink}&s=${successLink}`,
  });
}

/**
 * Fetch Membership Stripe payment link for membership upgrade
 */
export function fetchMembershipUpgradeStripLink(membershipId: number, cLink?: string) {
  const cancelLink = cLink ? cLink : `${location.origin}/memberships?membership=${membershipId}`;
  return apiHttp.post({
    url: `/membership-plans/${membershipId}/upgrade?c=${cancelLink}&s=${location.origin}/membership/${membershipId}?success=1`,
  });
}

/**
 * Fetch Membership plans
 */
export function fetchMembershipPlans() {
  return apiHttp.get({
    url: `/membership-plans`,
  });
}

/**
 * Fetch a single Membership plan
 */
export function fetchMembershipPlan(membershipId: number) {
  return apiHttp.get({
    url: `/membership-plans/${membershipId}`,
  });
}

/**
 * Fetch purchasable passes
 */
export function fetchPurchasablePasses() {
  return apiHttp.get({
    url: `/passes/purchasables`,
  });
}

/**
 * Fetch pass type
 */
export function fetchPassTypesForSearch() {
  return apiHttp.get({
    url: `/passes/types`,
  });
}

/**
 * Purchase a pass
 */
export function purchasePass({
  passId,
  quantity,
  successUrl,
  cancelUrl,
}: {
  passId: string;
  quantity: number;
  successUrl: string;
  cancelUrl: string;
}) {
  return apiHttp.post({
    url: `/passes/${passId}/purchase`,
    params: {passId, quantity, s: successUrl, c: cancelUrl},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Fetch users tickets
 */
export function fetchUserTickets() {
  return apiHttp.get({
    url: `/event-ticket/my-tickets`,
  });
}

/**
 * Fetch users discounts
 */
export function fetchUserDiscounts(page = 1) {
  return apiHttp.get({
    url: `/offers/with/discount`,
    params: {page},
  });
}

/**
 * Fetch public place
 */
export function fetchPublicPlace(uuid) {
  return apiHttp.get({
    url: `/places/preview/${uuid}`,
  });
}

/**
 * Fetch guided videos
 */
export function fetchGuidedVideos() {
  return apiHttp.get({
    url: `/user/getting-started/videos`,
  });
}

/**
 * Watched guided video complete
 */
export function watchedGuidedVideoCompleted(videoId) {
  return apiHttp.post({
    url: `/user/getting-started/watched`,
    params: {vid: videoId},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function fetchPublicEvents({
  page = 1,
  walkaboutOfficial,
  lat,
  long,
}: {
  page?: number;
  walkaboutOfficial?: string;
  lat?: string;
  long?: string;
}) {
  return apiHttp.get({
    url: `/public/events`,
    params: {
      page,
      ...(lat && {lat}),
      ...(long && {long}),
      ...(walkaboutOfficial !== undefined && {w: walkaboutOfficial == '1' ? '1' : '0'}),
    },
  });
}

export function fetchPublicPost(uuid) {
  return apiHttp.get({
    url: `/articles/${uuid}/preview`,
  });
}

export function fetchPublicPartner(uuid) {
  return apiHttp.get({
    url: `/partners/${uuid}/preview`,
  });
}

export function fetchPublicOffer(uuid) {
  return apiHttp.get({
    url: `/offers/${uuid}/preview`,
  });
}

export function fetchQRCodeDetails(slug) {
  return apiHttp.post({
    url: `/public/qr-code/fetch-details`,
    params: {slug: slug},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

export function editQrCode(id, data) {
  return apiHttp.put({
    url: `/qr-code/${id}`,
    params: {...data},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Fetch comments for a Place
 */
export function fetchPlaceComments({placeId, page}: {placeId: number; page: string}) {
  return apiHttp.get({
    url: `/places/${placeId}/comments`,
    params: {page},
  });
}

/**
 * Fetch featured comments for a Place
 */
export function fetchFeaturedPlaceComments({placeId, page}: {placeId: number; page: string}) {
  return apiHttp.get({
    url: `/places/${placeId}/featured-comments`,
    params: {page},
  });
}

/**
 * Fetch replies and mentions for a Place
 */
export function fetchPlaceReplyMentionComments({placeId}: {placeId: number}) {
  return apiHttp.get({
    url: `/places/${placeId}/replies-mentions`,
  });
}

/**
 * Fetch specific comment for a Place
 */
export function fetchPlaceComment({placeId, commentId}: {placeId: number; commentId: string}) {
  return apiHttp.get({
    url: `/places/${placeId}/comment/${commentId}`,
  });
}

/**
 * Create a comment for a Place
 */
export function createPlaceComment({
  placeId,
  content,
  parent_id,
  is_featured,
}: {
  placeId: number;
  content: string;
  parent_id?: string;
  is_featured?: boolean;
}) {
  return apiHttp.post({
    url: `/places/${placeId}/comment`,
    params: {
      content,
      ...(parent_id && {parent_id}),
      ...(is_featured ? {is_featured: true} : {is_featured: false}),
    },
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Update a comment for a Place
 */
export function updatePlaceComment({
  placeId,
  commentId,
  content,
}: {
  placeId: number;
  commentId: string;
  content: string;
}) {
  return apiHttp.put({
    url: `/places/${placeId}/comment`,
    params: {place_comment_id: commentId, content},
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

/**
 * Delete a comment for a Place
 */
export function deletePlaceComment({placeId, commentId}: {placeId: number; commentId: string}) {
  return apiHttp.delete({
    url: `/places/${placeId}/comment/${commentId}`,
  });
}

/**
 * Fetch Place chat users
 */
export function fetchPlaceChatUsers({placeId}: {placeId: number}) {
  return apiHttp.get({
    url: `/places/${placeId}/users`,
  });
}

/**
 * Report a comment for a Place
 */
export function reportPlaceComment({placeId, commentId}: {placeId: number; commentId: string}) {
  return apiHttp.post({
    url: `/places/${placeId}/report-as-abusive/${commentId}`,
  });
}

/**
 * Mark a comment as helpful for a Place
 */
export function helpfulPlaceComment({placeId, commentId}: {placeId: number; commentId: string}) {
  return apiHttp.post({
    url: `/places/${placeId}/report-as-helpful/${commentId}`,
  });
}

/**
 * Fetch list of Place chats users is in
 */
export function fetchPlaceChats({pageParam: page}) {
  return apiHttp.get({
    url: `/places/list/my-chats`,
    params: {page},
  });
}

export function buyGiftOffer({offerId}: {offerId: string | number}) {
  return apiHttp.post({
    url: `/gifts/buy/${offerId}`,
  });
}

export function fetchBroadcastReach({audienceRuleset, placeId, latitude, longitude, radius}) {
  if (!placeId && (latitude === undefined || longitude === undefined)) {
    throw new Error('Either placeId or both latitude and longitude are required.');
  }

  return apiHttp.get({
    url: '/broadcasts/reach',
    params: {
      ...(placeId ? {place_id: placeId} : {latitude, longitude}),
      ...{ruleset: audienceRuleset},
      ...(radius && {radius_in_ft: radius}),
    },
  });
}

export function sendMeetupBroadcast({
  eventId,
  audienceRuleset,
  message,
  radius,
}: {
  eventId: string;
  audienceRuleset: BROADCAST_AUDIENCE;
  message: string;
  radius?: number;
}) {
  return apiHttp.post({
    url: `/events/${eventId}/broadcasts`,
    params: {
      ruleset: audienceRuleset,
      message: message,
      ...(radius && {radius_in_ft: radius}),
    },
    options: {headers: {'Content-Type': 'application/json'}}, // Ensure JSON payload
  });
}


/**
 * Fetch list of Meet-Ups this user has created
 */
export function fetchUserMeetups() {
  return apiHttp.get({
    url: `/user/meetups`,
  });
}

/**
 * Send apartment listing form
 */
export function sendApartmentListingForm({
  place_id,
  name,
  email,
  phone,
  prefers_sms,
}: {
  place_id: number;
  name: string;
  email: string;
  phone: string;
  prefers_sms: boolean;
}) {
  return apiHttp.post({
    url: `/user/apartment-leads`,
    params: { place_id, name, email, phone, prefers_sms },
    options: {headers: {'Content-Type': 'application/json'}},
  });
}

