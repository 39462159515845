export enum CONTENT_TYPE {
  ALL = 'all',
  EVENT = 'event',
  PLACE = 'place',
  OFFER = 'offer',
  POST = 'post',
  PARTNER = 'partner',
  MEET_UP = 'meet-up',
}
export const enum TAG_TYPE {
  TOPICS = 'topics',
  GOALS = 'goals',
}

export enum FILTER_TYPES {
  TOGGLE = 'Toggle',
  MULTISELECT = 'MultiSelect',
  SINGLE_SELECT = 'SingleSelect',
  DATEPICKER = 'DatePicker',
}
