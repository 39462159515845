import { Flex } from "@chakra-ui/react";
import React from "react";
import { components, MultiValueRemoveProps } from "react-select";
import { SearchOptions, GroupedOption } from "..";

export const MultiValueRemove = (
  props: MultiValueRemoveProps<SearchOptions, true, GroupedOption>
) => <></>;

export const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>{props.children}</components.MultiValue>
  );
};

export const Input = (props) => {
  return <components.Input {...props}>{props.children}</components.Input>;
};

export const ValueContainer = (props) => {
  const { children } = props;

  const inputComponent = children![1];

  return (
    <components.ValueContainer {...props}>
      <Flex>
        <i
          className="fi fi-rr-search"
          style={{
            left: "15px",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            height: "16px",
            bottom: "14px",
          }}
        ></i>
        <Flex>
          <Input {...inputComponent.props} />
        </Flex>
      </Flex>
    </components.ValueContainer>
  );
};
export const ClearIndicator = (props) => {
  const { clearValue, innerProps, selectProps, ...rest } = props;
  const { onClear } = selectProps;
  const { onMouseDown, onTouchEnd, ...restInnerProps } = innerProps;
  const customInnerProps = {
    ...restInnerProps,
    onMouseDown: onClear,
    onTouchEnd: onClear,
  };
  return (
    <components.ClearIndicator {...rest} innerProps={...customInnerProps}>
      <i
        id="clear-indicator"
        className="fi fi-rr-cross-circle"
        style={{
          color: "#757D84",
          fontSize: "15px",
          height: "16px",
        }}
      ></i>
    </components.ClearIndicator>
  );
};

export const DropdownIndicator = (props) => {
  const {
    isDisabled,
    innerProps,
    selectProps: { inputValue, onSubmit, searchSubmitted },
    ...rest
  } = props;
  const { onMouseDown, onTouchEnd, ...restInnerProps } = innerProps;
  const customInnerProps = {
    ...restInnerProps,
    onMouseDown: onSubmit,
    onTouchEnd: onSubmit,
  };
  return (
    <components.DropdownIndicator {...rest} innerProps={customInnerProps}>
      <i
        className="fi fi-sr-arrow-circle-right"
        style={{
          height: "25px",
          width: "25px",
          color: "#276ACD",
          fontSize: "23px",
        }}
      />
    </components.DropdownIndicator>
  );
};

export const IndicatorsContainer = (props) => {
  const {
    children,
    selectProps: { searchSubmitted, inputValue },
  } = props;
  const clearIndicator = children![0];
  const dropdownIndicator = children![3];

  return (
    <components.IndicatorsContainer {...props}>
      {searchSubmitted && clearIndicator && inputValue.length > 0 && (
        <ClearIndicator {...clearIndicator.props} />
      )}
      {!searchSubmitted && inputValue.length > 0 && (
        <DropdownIndicator {...dropdownIndicator.props} />
      )}
    </components.IndicatorsContainer>
  );
};
