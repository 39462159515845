import {Modal, ModalOverlay, useTheme} from '@chakra-ui/react';
import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Tooltip, {TooltipProps} from './Tooltip';
import RemoveClick from './Tooltip/RemoveClick';
import {RemoveScroll} from 'react-remove-scroll';
import ConfettiExplosion, {ConfettiProps} from 'react-confetti-explosion';
import styled from 'styled-components';

export type ToolTipFunction = (
  nextStep,
  prevStep,
  finish,
  setConfettiExploding,
  theme
) => TooltipProps[];

const StyledConfetti = styled(ConfettiExplosion)`
  position: absolute;
  top: 45%;
  left: 50%;
`;

const TutorialTooltips = ({tooltipFunc}: {tooltipFunc: ToolTipFunction}) => {
  const [step, setStep] = useState(0);
  const [confettiExploding, setConfettiExploding] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const largeExplosionProps: ConfettiProps = {
    force: 1,
    duration: 5000,
    particleCount: 250,
    width: 800,
    colors: [theme.colors.rose, theme.colors.blue],
  };

  const nextStep = useCallback(() => setStep((curStep) => curStep + 1), [setStep]);
  const prevStep = useCallback(() => setStep((curStep) => curStep - 1), [setStep]);
  const finish = useCallback((path) => navigate(path, {replace: true}), []);

  const tooltipSteps = useMemo(
    () => tooltipFunc(nextStep, prevStep, finish, setConfettiExploding, theme),
    [nextStep, prevStep, finish, setConfettiExploding]
  );

  const filteredTooltips = tooltipSteps.filter((tooltip) => !tooltip.skip);

  return (
    <>
      {confettiExploding && (
        <StyledConfetti
          zIndex={1500}
          {...largeExplosionProps}
          onComplete={() => setConfettiExploding(false)}
        />
      )}
      <Modal isCentered isOpen={true} onClose={() => {}}>
        <ModalOverlay background="rgba(0,0,0, 0.7)" />
        <RemoveClick />
        <RemoveScroll>
          {filteredTooltips.map((tooltip, i) => (
            <Tooltip
              key={i}
              step={i}
              totalSteps={filteredTooltips.length}
              {...tooltip}
              isOpen={step == i}
            />
          ))}
        </RemoveScroll>
      </Modal>
    </>
  );
};

export default TutorialTooltips;
