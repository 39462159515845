import {Box, Flex, VStack, Image, useTheme} from '@chakra-ui/react';
import React, {useMemo} from 'react';
import {getOfferDate, isFutureOffer} from '../../utils/date';
import {TextG10, TextG14, TextG8, TextG9} from '../typography';
import dayjs from 'dayjs';
import {getImageURL} from '../../constants/imageStorage';
import LockedBanner from '../../assets/images/lockedBanner.png';
import useAuth from '../../hooks/useAuth';
import {BlueButton} from '../button';
import {useNavigate} from 'react-router-dom';
import useOfferModal from '../../hooks/useOfferModal';
import {Offer} from '../../types/acrticle';
import HTMLReactParser from 'html-react-parser';

const DiscountCard = ({discount}: {discount: Offer}) => {
  const theme = useTheme();
  const {user} = useAuth();
  const navigate = useNavigate();

  const {showOfferModal} = useOfferModal();
  const isLocked = !user?.hasActiveMembership;
  const datePassed = useMemo(() => dayjs().utc().isAfter(dayjs.utc(discount?.ends_at)), [discount]);
  const futureOffer = useMemo(() => isFutureOffer(discount), [discount]);
  return (
    <Box
      boxShadow="0px 0px 25px 0px #00000033"
      position="relative"
      px={3}
      py={3}
      borderRadius="12px"
      background="white"
      height={'135px'}
      width="100%"
      onClick={() => {
        isLocked ? navigate('/memberships') : showOfferModal({offer: discount});
        // onClick ? onClick() : showOfferModal({offer});
        // addUserEvent({
        //   type: 'click',
        //   event_name: 'card_click',
        //   event_time: dayjs().format(),
        //   data: {itemType: 'offer', itemId: offer.id},
        // });
      }}>
      {isLocked && (
        <Image zIndex="10" h="90px" src={LockedBanner} pos="absolute" top={0} right={0} />
      )}
      <Flex gap={2} w="100%" h="100%" userSelect="none" flexDir={'row'}>
        <Box maxW={'100px'} flex="none" pos="relative">
          <Image
            fallbackStrategy="onError"
            w={'100px'}
            borderRadius="10px"
            objectFit="cover"
            h={'-webkit-fill-available'}
            src={discount?.image_url ? getImageURL(discount.image_url) : ''}
          />
        </Box>
        <VStack
          justifyContent="space-around"
          align="flex-start"
          color={theme.colors.white}
          px={2}
          position="relative"
          flex="1">
          <TextG14
            textAlign="left"
            pr={'36px'}
            wordBreak="break-word"
            fontWeight="700"
            color={theme.colors.black}
            noOfLines={2}>
            {discount?.title}
          </TextG14>

          {discount?.summary && (
            <TextG10
              isTruncated="&hellip;"
              noOfLines={2}
              style={{filter: isLocked ? 'blur(3px)' : ''}}
              fontWeight="500"
              color={theme.colors.grey}>
              {HTMLReactParser(discount?.summary)}
            </TextG10>
          )}
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            {futureOffer ? (
              <TextG10
                style={{filter: isLocked ? 'blur(3px)' : ''}}
                fontWeight="400"
                color={theme.colors.rose}
                isTruncated="&hellip;"
                noOfLines={1}>
                Available {getOfferDate(discount?.starts_at)}
              </TextG10>
            ) : (
              <TextG10
                style={{filter: isLocked ? 'blur(3px)' : ''}}
                fontWeight="400"
                color={theme.colors.rose}
                isTruncated="&hellip;"
                noOfLines={1}>
                {`${datePassed ? 'Expired on' : 'Expires'} ${getOfferDate(discount?.ends_at)}`}
              </TextG10>
            )}
            <Box
              textAlign={'center'}
              w="57px"
              h="18px"
              // p={1}
              borderRadius="9px"
              background="#E0F3E3">
              <TextG8 color="#329241" fontWeight="700">
                IN STORE
              </TextG8>
            </Box>
          </Flex>
          {isLocked && (
            <BlueButton pos="absolute" bottom={0} borderRadius="6px" h="25px">
              <TextG9 fontWeight="500">Upgrade Membership</TextG9>
            </BlueButton>
          )}
        </VStack>
      </Flex>
    </Box>
  );
};

export default DiscountCard;
