import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {fetchBroadcastReach, sendMeetupBroadcast} from '../endpoints/api';

export const enum BROADCAST_AUDIENCE {
  ANYONE_NEARBY = 'anyone_nearby',
  LOCAL_INSIDERS_CLUB = 'local_insiders_club',
}

export const useMeetupBroadcast = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendMeetupBroadcast,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['infinite_meetups']);
      queryClient.invalidateQueries(['meetup', variables.eventId]);
    },
  });
};

export const useBroadcastReach = ({
  audienceRuleset,
  latitude,
  longitude,
  placeId,
  radius,
}: {
  audienceRuleset: string;
  latitude?: string;
  longitude?: string;
  placeId?: string;
  radius?: string;
}) => {
  return useQuery({
    queryKey: ['broadcastReach', {audienceRuleset, latitude, longitude, placeId, radius}],
    queryFn: () => fetchBroadcastReach({audienceRuleset, latitude, longitude, placeId, radius}),
    enabled: Boolean(placeId || (latitude !== undefined && longitude !== undefined)),
  });
};
