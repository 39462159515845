import React, {useEffect, useState} from 'react';
import {
  Image,
  Center,
  VStack,
  useTheme,
  HStack,
  Link,
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input as ChkrInput,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Divider,
} from '@chakra-ui/react';
import styled from 'styled-components';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Logo from '../../../assets/logos/logo_120x120.png';
import {H2, TextG10, TextG12, TextG14} from '../../../components/typography';
import {BlueButton} from '../../../components/button';
import BackButton from '../../../components/BackButton';
import {getAppName} from '../../../utils/app';
import {SpinnerLoader} from '../../../components/Loader';
import {softCheck} from '../../../endpoints/api';
import ExternalLink from '../../../components/ExternalLink';

const ZIP_LENGTH = 5;

const StyledForm = styled.form`
  padding: 28px 24px;
`;

type FormData = {
  name?: string;
  surname?: string;
  zip?: string;
};

type FieldError = {[index: string]: string};

function SoftCheck() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [checking, setChecking] = useState(false);

  const [data, setData] = useState<FormData>({});
  const [errors, setErrors] = useState<FieldError>({});

  const [showModal, setShowModal] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  const setField = (field, value) => {
    setData((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const setFieldErrors = (field, error) => {
    setErrors({
      ...errors,
      [field]: error,
    });
  };

  useEffect(() => {
    if (searchParams.get('name')) {
      setField('name', searchParams.get('name'));
    }
    if (searchParams.get('surname')) {
      setField('surname', searchParams.get('surname'));
    }
    if (searchParams.get('zip')) {
      setField('zip', searchParams.get('zip'));
    }

    if (searchParams.get('name') && searchParams.get('surname') && searchParams.get('zip')) {
      handleSoftCheck(null);
    }

    if (searchParams.get('membershipPreview')) {
      setIsPreview(true);
    }
  }, [searchParams]);

  const handleSoftCheck = (e) => {
    if (e) e.preventDefault();

    if (data.zip && data.zip.length > 0 && data.zip.length < ZIP_LENGTH) {
      setFieldErrors('zip', 'Zip must be at least 5 numbers');
      return;
    } else {
      setFieldErrors('zip', '');
    }

    setChecking(true);

    const params = {
      name: data.name,
      surname: data.surname,
      zip_code: data.zip,
    };

    softCheck(params)
      .then((res) => {
        if (!res.status) {
          setShowModal(true);
          return;
        }

        navigate(isPreview ? '/register?membershipPreview=true' : `/register`, {
          state: {
            name: data.name,
            surname: data.surname,
            zip: data.zip,
          },
        });
      })
      .catch(() => setShowModal(true))
      .finally(() => setChecking(false));
  };

  const handleContinue = () => {
    navigate(isPreview ? '/register?membershipPreview=true' : `/register`, {
      state: {
        name: data.name,
        surname: data.surname,
        zip: data.zip,
      },
    });
  };

  return (
    <Box>
      <Modal isCentered isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalHeader pb={0}>
            <Box mt={6}>
              <VStack spacing="18px">
                <Center>
                  <Image src={Logo} width="56px" alt="Logo" />
                </Center>
                <TextG14 textAlign="center">
                  We are not exactly where you are, but you can still experience these great
                  neighborhoods on Walkabout.
                </TextG14>
              </VStack>
            </Box>
          </ModalHeader>
          <ModalBody>
            <VStack spacing="12px" py={8} alignItems={'start'}>
              <TextG12>&bull; North Park and Surrounding Area, San Diego</TextG12>
              <Divider />
              <TextG12>&bull; Little Italy, San Diego</TextG12>
              <Divider />
              <TextG12 alignSelf={'center'} color={theme.colors.lightestGrey}>
                More to come!
              </TextG12>
            </VStack>
            <VStack textAlign="center" spacing="14px" w="100%" mb={5}>
              <BlueButton w="100%" borderRadius="7px" onClick={handleContinue}>
                Continue Sign Up
              </BlueButton>
              <TextG12
                w="100%"
                textDecoration="unset"
                onClick={() => {
                  setShowModal(false);
                  isPreview ? navigate('/memberships') : navigate(-1);
                }}>
                Not Now
              </TextG12>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box px="25px" pt="35px">
        <Flex>
          <BackButton
            pos={'absolute'}
            onClick={() => {
              isPreview ? navigate('/memberships') : navigate('/auth');
            }}
          />
          <Box flex="1">
            <Center>
              <Link href="https://walkabout.app">
                <H2 color="rose">{getAppName()}</H2>
              </Link>
            </Center>
          </Box>
        </Flex>
      </Box>
      <Box mt={12}>
        <VStack spacing="12px">
          <Center>
            <Image src={Logo} width="61px" alt="Logo" />
          </Center>
          <H2>Sign-Up</H2>
          <TextG12 color={theme.colors.lightBlack}>Let’s Create Your Account</TextG12>
        </VStack>
      </Box>
      <StyledForm autocomplete="off" onSubmit={handleSoftCheck}>
        <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}} />
        <VStack spacing="25px" mb="20px">
          <VStack alignItems="left" w="100%">
            <TextG10 fontWeight="500">First Name</TextG10>
            <InputGroup ml="-5px !important" h="45px" display="block">
              <InputLeftElement
                top="8px"
                fontSize="12px"
                pointerEvents="none"
                children={<i className="fi fi-rr-user" />}
              />
              <TextG12>
                <ChkrInput
                  h="45px"
                  focusBorderColor={theme.colors.lightestGrey}
                  _placeholder={{color: `${theme.colors.lightestGrey}`}}
                  fontSize="12px"
                  pl={9}
                  border={`1px solid ${theme.colors.lighterGrey};`}
                  borderRadius="12px"
                  size="sm"
                  id="first-name"
                  isRequired
                  name="first_name"
                  autoComplete="given-name"
                  minLength={2}
                  value={data?.name || ''}
                  onChange={(e) => setField('name', e.target.value)}
                />
              </TextG12>
            </InputGroup>
          </VStack>
          <VStack alignItems="left" w="100%">
            <TextG10 fontWeight="500">Last Name</TextG10>
            <InputGroup ml="-5px !important" h="45px" display="block">
              <InputLeftElement
                top="8px"
                fontSize="12px"
                pointerEvents="none"
                children={<i className="fi fi-rr-user" />}
              />
              <TextG12>
                <ChkrInput
                  h="45px"
                  focusBorderColor={theme.colors.lightestGrey}
                  _placeholder={{color: `${theme.colors.lightestGrey}`}}
                  fontSize="12px"
                  pl={9}
                  border={`1px solid ${theme.colors.lighterGrey};`}
                  borderRadius="12px"
                  size="sm"
                  id="surname"
                  isRequired
                  name="surname"
                  autoComplete="family-name"
                  minLength={2}
                  value={data?.surname || ''}
                  onChange={(e) => setField('surname', e.target.value)}
                />
              </TextG12>
            </InputGroup>
          </VStack>
          <VStack alignItems="left" w="100%">
            <TextG10 fontWeight="500">Zip Code</TextG10>
            <InputGroup ml="-5px !important" h="45px" display="block">
              <InputLeftElement
                top="8px"
                fontSize="12px"
                pointerEvents="none"
                children={<i className="fi fi-rr-home" />}
              />
              <TextG12>
                <ChkrInput
                  type="number"
                  h="45px"
                  focusBorderColor={theme.colors.lightestGrey}
                  _placeholder={{color: `${theme.colors.lightestGrey}`}}
                  fontSize="12px"
                  pl={9}
                  border={`1px solid ${theme.colors.lighterGrey};`}
                  borderRadius="12px"
                  autoComplete={'postal-code'}
                  size="sm"
                  id="zip_code"
                  name="zip_code"
                  isRequired
                  onChange={(e) => {
                    if (e.target.value.length > ZIP_LENGTH) return;
                    setField('zip', e.target.value);
                  }}
                  value={data?.zip || ''}
                />
              </TextG12>
            </InputGroup>
          </VStack>
          {errors &&
            Object.values(errors).map((error, index) => {
              if (error) {
                return (
                  <Center key={index} mt="10px !important">
                    <TextG12 color={theme.colors.rose}>{error}</TextG12>
                  </Center>
                );
              }
            })}
        </VStack>
        {!checking ? (
          <>
            <Center px={6} py={5}>
              <Divider />
              <TextG10 w="100%" textAlign="center">
                OR
              </TextG10>
              <Divider />
            </Center>
            <Center mt={3} mb={6}>
              <Link
                onClick={() => {
                  navigate(isPreview ? '/invite-code?membershipPreview=true' : '/invite-code');
                }}
                color={theme.colors.blue}>
                Got an invite code?
              </Link>
            </Center>
          </>
        ) : (
          <VStack spacing={4} mt={12} mb={9}>
            <SpinnerLoader />
            <TextG14 color={theme.colors.blue}>Checking...</TextG14>
          </VStack>
        )}
        <Center>
          <BlueButton
            w="100%"
            borderRadius="7px"
            ml="-5px !important"
            _hover={{background: theme.colors.blue}}
            _active={{background: theme.colors.blue}}
            type="submit">
            Continue
          </BlueButton>
        </Center>
        <Center>
          <HStack spacing={4} pt={4} px={10}>
            <TextG10 textAlign="center" color={theme.colors.grey}>
              {'By creating an account, you agree to our '}
              <ExternalLink
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/terms-of-use/`}
                color={theme.colors.lighterBlue}>
                terms of use
              </ExternalLink>
              {' and '}
              <ExternalLink
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/privacy-policy/`}
                color={theme.colors.lighterBlue}>
                privacy policy.
              </ExternalLink>
            </TextG10>
          </HStack>
        </Center>
      </StyledForm>
    </Box>
  );
}

export default SoftCheck;
