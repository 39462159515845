import {VStack, HStack, Image, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../../../components/BackButton';
import {H2, TextG14} from '../../../../components/typography';
import Logo from '../../../../assets/logos/logo_120x120.png';

const Header = ({
  title,
  subtitle,
  onBackClick,
  showBack = true,
}: {
  title?: string;
  subtitle?: string | JSX.Element;
  onBackClick?: () => void;
  showBack?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <VStack align={'flex-start'} spacing="12px" alignItems="center" gap={6}>
      <HStack mt={5} w="100%" alignItems="center">
        {showBack && (
          <BackButton
            onClick={() => {
              onBackClick ? onBackClick() : navigate(-1);
            }}
            pos="relative"
          />
        )}
        <Image
          position="relative"
          margin="0 !important"
          mr="auto !important"
          ml="auto !important"
          left={showBack ? '-1.1rem' : 0}
          paddingInline={0}
          src={Logo}
          width="61px"
          alt="Logo"
        />
      </HStack>
      {title && <H2>{title}</H2>}
      {subtitle && <TextG14 color={theme.colors.grey}>{subtitle}</TextG14>}
    </VStack>
  );
};

export default Header;
