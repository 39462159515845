import {Box, Flex, HStack, VStack} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import Icon from '../../components/Icon';
import MembershipPill from '../../components/MembershipPill';
import {TextA18, TextG10, TextG14} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import {getMembershipGradient} from '../../utils/membership';

const Header = ({membership, success}) => {
  const navigate = useNavigate();
  const {user} = useAuth();

  return (
    <>
      <Box
        position="absolute"
        top="0px"
        background={getMembershipGradient(membership)}
        height="270px"
        zIndex="-1"
        w="100vw"
      />
      <Flex
        background="transparent"
        gap="8px"
        width="100%"
        px={5}
        pb="0"
        pt="30px"
        flexDirection={'column'}>
        <Flex justifyContent="space-between" align="center">
          <BackButton
            onClick={() => {
              success ? navigate('/home') : navigate(-1);
            }}
          />
          <MembershipPill overrideMembership={membership} />
          <Icon
            onClick={() => navigate('/home')}
            iconName={'fi-rr-home'}
            style={{fontSize: '20px', margin: '0 !important', width: '35px', textAlign: 'center'}}
          />
        </Flex>
        <VStack mt="10px" textAlign="center">
          {/* <TextA18 fontWeight="700">{membership.name} Membership</TextA18>
          <TextG10>{membership.subtitle}</TextG10> */}
          <TextG14 mb="25px !important" fontWeight="700">
            Make the most of your membership
          </TextG14>
        </VStack>
      </Flex>
    </>
  );
};

export default Header;
