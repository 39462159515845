import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AlertPopupActions} from './AlertPopupSlice';
import {Center, HStack, VStack} from '@chakra-ui/react';
import {H2, TextG14} from '../../components/typography';
import Modal from '../../components/Modals/Modal';
import {BlueButton, WhiteButton} from "../../components/button";

const AlertPopup = ({ notification }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(AlertPopupActions.popNotification());
    };
  }, []);

  useEffect(() => {
    if (!show) {
      dispatch(AlertPopupActions.popNotification());
    }
  }, [show]);

  return (
    <Modal
      isCentered={true}
      closeOnOverlayClick={false}
      show={show}
      closeButton={false}
      header={
        <>
          <Center>
            <H2>{notification.title}</H2>
          </Center>
        </>
      }>
      <VStack pb={4} spacing={4}>
        <TextG14>{notification.message}</TextG14>
        <HStack>
          <BlueButton onClick={() => setShow(false)}>Stay on this page</BlueButton>
          <WhiteButton variant='outline' onClick={() => {
            notification.action();
            setShow(false);
          }}>Leave this page</WhiteButton>
        </HStack>
      </VStack>
    </Modal>
  );
};

export default AlertPopup;
