import { isEqual } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import useAuth from "./useAuth";

type SuperFilterProps = {
  superFiltersActive: boolean;
  setSuperFiltersActive: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGoals: string[];
  setSelectedGoals: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTopics: string[];
  setSelectedTopics: React.Dispatch<React.SetStateAction<string[]>>;
  superFiltersCount: number;
  selectedTagsDidntChange: boolean;
};

const SuperFiltersContext = React.createContext<SuperFilterProps>(
  {} as SuperFilterProps
);

export const SuperFiltersProvider = ({ children }) => {
  const { user } = useAuth();
  const [superFiltersActive, setSuperFiltersActive] = useState(false);
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [superFiltersCount, setSuperFiltersCount] = useState(0);

  useEffect(() => {
    if (user?.superfilters === null) return;
    if (
      user?.superfilters.lifestyle_group_ids &&
      user?.superfilters.lifestyle_group_ids !== null
    ) {
      setSelectedGoals(user.superfilters.lifestyle_group_ids);
    }

    if (user?.superfilters.topic_ids && user?.superfilters.topic_ids !== null) {
      setSelectedTopics(user.superfilters.topic_ids);
    }

    if (
      user?.superfilters.topic_ids !== null ||
      user.superfilters.lifestyle_group_ids !== null
    ) {
      setSuperFiltersActive(true);
      let countSuperFilters = 0;
      if (
        user?.superfilters.lifestyle_group_ids &&
        user.superfilters.lifestyle_group_ids.length > 0
      ) {
        countSuperFilters += user.superfilters.lifestyle_group_ids.length;
      }

      if (
        user?.superfilters.topic_ids &&
        user.superfilters.topic_ids.length > 0
      ) {
        countSuperFilters += user.superfilters.topic_ids.length;
      }
      if (countSuperFilters > 0) {
        setSuperFiltersCount(countSuperFilters);
      }
    } else {
      setSuperFiltersActive(false);
      setSuperFiltersCount(0);
    }
  }, [user?.superfilters]);

  const selectedTagsDidntChange = useMemo(() => {
    if (user?.superfilters === null)
      return isEqual(selectedTopics, []) && isEqual(selectedGoals, []);

    if (
      user?.superfilters.topic_ids === null &&
      isEqual(selectedTopics, []) &&
      user?.superfilters.lifestyle_group_ids !== null &&
      isEqual(selectedGoals, user?.superfilters.lifestyle_group_ids)
    ) {
      return true;
    }

    if (
      user?.superfilters.lifestyle_group_ids === null &&
      isEqual(selectedGoals, []) &&
      user?.superfilters.topic_ids !== null &&
      isEqual(selectedTopics, user?.superfilters.topic_ids)
    ) {
      return true;
    }

    if (
      user?.superfilters.lifestyle_group_ids === null &&
      isEqual(selectedGoals, []) &&
      user?.superfilters.topic_ids === null &&
      isEqual(selectedTopics, [])
    ) {
      return true;
    }

    return (
      isEqual(selectedGoals, user?.superfilters.lifestyle_group_ids) &&
      isEqual(selectedTopics, user?.superfilters.topic_ids)
    );
  }, [selectedGoals, selectedTopics, user?.superfilters]);

  const superFilters = {
    superFiltersActive,
    setSuperFiltersActive,
    selectedTopics,
    setSelectedTopics,
    selectedGoals,
    setSelectedGoals,
    superFiltersCount,
    selectedTagsDidntChange,
  };

  return (
    <SuperFiltersContext.Provider value={superFilters}>
      {children}
    </SuperFiltersContext.Provider>
  );
};

export default function useSuperFilters() {
  return React.useContext(SuperFiltersContext);
}
