import React, {useState} from "react";
import {Box, Center, Container, Image, VStack, Link} from "@chakra-ui/react";
import {setNewPassword} from "../../../../endpoints/api";
import BackButton from "../../../../components/BackButton";
import {H2, TextG12, TextG14} from "../../../../components/typography";
import Input from "../../../../components/Input";
import {BlueButtonFixedWidth} from "../../../../components/button";
import ShowPassword from "../../../../assets/images/eye.png";
import { PASSWORD_REGEX } from "../../../Auth/Register/RegisterForm";
import ExternalLink from "../../../../components/ExternalLink";

const CreateNewPassword = ({ previousStep, nextStep, additionalData }) => {
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>('');

  const handlePasswordChange = (e) => {
    setPassword(e?.target?.value)
    PASSWORD_REGEX.test(e?.target?.value)
      ? setError('')
      : setError('Password requires minimum 8 characters using a combination of uppercase, lowercase, numbers and symbols');
  };

  const handleCreateNewPassword = () => {
    setNewPassword(additionalData?.email, password, additionalData?.code).then(() => {
      nextStep();
    }).catch((err) => setError(err.message));
  }

  return (
    <Container px={5} py={8}>
      <Box>
        <BackButton pos={'relative'} onClick={() => previousStep()} />
      </Box>
      <VStack pt={5} alignItems={'start'} spacing={2}>
        <H2 fontWeight={700} py={3}>
          Create New Password
        </H2>
        <TextG14 fontWeight={500} lineHeight={'176.5%'}>
          Note: We'll never ask for your password by email or SMS. If you think your password has
          been compromised,{' '}
          <ExternalLink
            isExternal
            href={'https://ctbs.tech/contact-us/'}
            textDecoration={'underline'}>
            contact us
          </ExternalLink>{' '}
          for help.
        </TextG14>
        <Box w={'100%'} py={5}>
          <Input
            size="sm"
            id="password"
            type={showPassword ? 'text' : 'password'}
            isRequired
            label="New Password"
            placeholder="8 characters, letters and numbers"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            RightIcon={<Image src={ShowPassword} alt={'Show password'} />}
            RightIconOnClick={() => setShowPassword(!showPassword)}
          />
        </Box>
        {error && (
          <Center>
            <TextG12>{error}</TextG12>
          </Center>
        )}
        <Box py={3} w={'100%'}>
          <BlueButtonFixedWidth isDisabled={error || !password} onClick={handleCreateNewPassword}>
            Continue
          </BlueButtonFixedWidth>
        </Box>
      </VStack>
    </Container>
  );
}

export default CreateNewPassword;