import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import SendCode from "./SendCode";
import CodeVerification from "./CodeVerification";
import CreateNewPassword from "./CreateNewPassword";
import PasswordChanged from "./PasswordChanged";

enum FORGOT_PASSWORD_STEPS {
  RESET_PASSWORD,
  CODE_VERIFICATION,
  CREATE_NEW_PASSWORD,
  PASSWORD_CHANGED,
}

const ResetPassword = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(FORGOT_PASSWORD_STEPS.RESET_PASSWORD);
  const [data, setData] = useState({});

  return (
    <>
      { step === FORGOT_PASSWORD_STEPS.RESET_PASSWORD && (
        <SendCode
          previousStep={() => navigate('/login')}
          nextStep={() => setStep(FORGOT_PASSWORD_STEPS.CODE_VERIFICATION)}
          setAdditionalData={(data) => setData(data)}
        />
      )}
      { step === FORGOT_PASSWORD_STEPS.CODE_VERIFICATION && (
        <CodeVerification
          previousStep={() => setStep(FORGOT_PASSWORD_STEPS.RESET_PASSWORD)}
          nextStep={() => setStep(FORGOT_PASSWORD_STEPS.CREATE_NEW_PASSWORD)}
          additionalData={data}
          setAdditionalData={(data) => setData(data)}
        />
      )}
      { step === FORGOT_PASSWORD_STEPS.CREATE_NEW_PASSWORD && (
        <CreateNewPassword
          previousStep={() => setStep(FORGOT_PASSWORD_STEPS.CODE_VERIFICATION)}
          nextStep={() => setStep(FORGOT_PASSWORD_STEPS.PASSWORD_CHANGED)}
          additionalData={data}
        />
      )}
      { step === FORGOT_PASSWORD_STEPS.PASSWORD_CHANGED && (
        <PasswordChanged nextStep={() => navigate('/login')} />
      )}
    </>
  )
}

export default ResetPassword;