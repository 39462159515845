import {find} from 'lodash'

interface VisibleArticle {
  id: string
  type: 'place' | 'event' | 'post' | 'offer' | 'partner' | 'meet-up'
}

const useVisibleArticles = () => {
  const addVisibleArticle = (visibleArticle: VisibleArticle) => {
    const articles = JSON.parse(sessionStorage.getItem('visible_articles') || '[]')
    if (!find(articles, {id: articles.id})) {
      articles.push(visibleArticle)
      sessionStorage.setItem('visible_articles', JSON.stringify(articles))
    }
  }

  return {addVisibleArticle}
}

export default useVisibleArticles
