import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Center, SimpleGrid, Spinner, usePrevious, useTheme} from '@chakra-ui/react';
import axios from 'axios';
import {jsx} from '@emotion/react';
import JSX = jsx.JSX;
import {filter, isEqual} from 'lodash';
interface InfiniteScrollParams {
  showItems: (item) => JSX.Element;
  noItems?: JSX.Element;
  endpoint: (...params) => Promise<any>;
  endpointParams: Record<string, number | string>;
}
interface DataType {
  id: number;
  [x: string]: string | number;
}
const InfiniteScroll = ({showItems, noItems, endpoint, endpointParams}: InfiniteScrollParams) => {
  const theme = useTheme();
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const previousParams = usePrevious(endpointParams);
  const previousEndpoint = usePrevious(endpoint);

  const [data, setData] = useState<Array<DataType>>([]);

  const observer = useRef<any>();

  const lastEventElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  let cancelReq;
  useEffect(() => {
    if (pageNumber > 1) {
      setError(false);
      endpoint({...endpointParams, page: pageNumber.toString(), cancelReq})
        .then(({data}) => {
          const {data: e} = data;
          const {per_page: perPage} = data;
          setData((prevData) => {
            return [...prevData, ...e];
          });
          perPage ? setHasMore(e.length >= perPage) : setHasMore(e.length > 0);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(e.message);
        });
    }
  }, [pageNumber]);

  useEffect(() => {
    // we should only fire if the endpoint and the params are actually changed from the previous.
    if (isEqual(previousParams, endpointParams) && isEqual(previousEndpoint, endpoint)) {
      return;
    }
    setLoading(true);
    setPageNumber(1);
    setError(false);
    endpoint({...endpointParams, page: '1', cancelReq})
      .then(({data}) => {
        const {data: e} = data;
        const {per_page: perPage} = data;
        setData(() => [...e]);
        perPage ? setHasMore(e.length >= perPage) : setHasMore(e.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(e.message);
        setLoading(false);
      });
  }, [endpoint, endpointParams]);

  if (loading)
    return (
      <Center pt={16}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <SimpleGrid gap="15px" columns={1} pb={16}>
      {!data.length && !loading && noItems}
      {data.map((item, index) => {
        if (data.length === index + 1 && data.length !== 1) {
          if (item.type == 'offer' && item.readable_status == 'Available')
            return <div key={`${item.id.toString()}_${index}`} ref={lastEventElementRef} />;
          return (
            <div key={`${item.id.toString()}_${index}`} ref={lastEventElementRef}>
              {showItems(item)}
            </div>
          );
        }
        return (
          <div style={{display: 'contents'}} key={`${item.id.toString()}_${index}`}>
            {showItems(item)}
          </div>
        );
      })}
      {error && <div style={{color: 'red', paddingInline: '1rem'}}>{error}</div>}
    </SimpleGrid>
  );
};

export default InfiniteScroll;
