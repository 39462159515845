import React, {useEffect, useMemo, useState} from 'react';
import {Image, VStack, useTheme, Link, Flex, Box} from '@chakra-ui/react';
import {Offer, Event} from '../../../../types/article';
import {TextA18, TextG12, H2, TextG14} from '../../../typography';
import Modal from '../../../Modals/Modal';
import {has} from 'lodash';
import Icon from '../../../Icon';
import OfferImage from '../../../../assets/images/offerImage.png';
import {useNavigate} from 'react-router-dom';
import {OfferType} from '../../../../types/article';
import PointBoostModalContent from './PointBoostModalContent';
import {getImageURL} from '../../../../constants/imageStorage';
import RedemptionModalContent from './RedemptionModalContent';
import {calculateAndGetDistance, getDistanceString} from '../../../../utils/distance';
import {useGeneralSettings} from '../../../../hooks/useGeneralSettings';
import useGetUserLocation from '../../../../hooks/useUserLocation';
import {ActiveRedemption} from '../../../../hooks/useActiveRedemptions';
import dayjs from 'dayjs';
import useTrackEvent from '../../../../hooks/useTrackEvent';
import {isFutureOffer} from '../../../../utils/date';
import useBookmark from '../../../../hooks/useBookmark';
import {useFetchEvent} from '../../../../hooks/useEvent';
import {checkIsOfferLocked} from '../OfferCard';
import {storeScrollPositionInSession} from '../../../../utils/scroll';
import SignUpRequiredWrapper from '../../../SignupRequiredModal/SignupRequiredWrapper';
import SignupRequiredModal from '../../../SignupRequiredModal';
import useAuth from '../../../../hooks/useAuth';

const OfferModal = ({
  offer,
  showModal,
  setShowModal,
  bookmarkHandler,
  activeRedemption,
  isPreview = false,
}: {
  offer: Offer;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bookmarkHandler?: (e: any) => Promise<string | number | undefined> | undefined;
  activeRedemption?: ActiveRedemption;
  isPreview?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {getSetting, isLoading} = useGeneralSettings(!isPreview);
  const userLocation = useGetUserLocation(!isPreview);
  const datePassed = useMemo(() => dayjs().utc().isAfter(dayjs.utc(offer?.ends_at)), [offer]);
  const {addUserEvent} = useTrackEvent();
  const futureOffer = useMemo(() => isFutureOffer(offer), [offer]);
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(offer as Offer, 'offer');
  const place = offer.place;
  const event = offer.event;
  const bookmarkHandle = bookmarkHandler ? bookmarkHandler : handleOnBookmarkClick;
  const [signUpRequiredOpen, setSignUpRequiredOpen] = useState(false);
  const {user} = useAuth();

  const [offerEvent, setOfferEvent] = useState<Event | undefined>(event);
  const {data: lazyLoadadedEvent} = useFetchEvent(offer.event_id, !isPreview);

  const onSignupClicked = () => {
    if (user) {
      navigate('/download');
    } else {
      navigate('/soft-check');
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (
      (!offerEvent ||
        (offerEvent && has(offerEvent, 'unlocks_offer_id') && has(offerEvent, 'unlocked_offer'))) &&
      lazyLoadadedEvent
    ) {
      const fetchedOffer = lazyLoadadedEvent?.data;
      setOfferEvent(fetchedOffer);
    }
  }, [lazyLoadadedEvent]);

  useEffect(() => {
    if (showModal) {
      addUserEvent({
        type: 'modal_view',
        event_name: 'view_offer',
        event_time: dayjs().format(),
        data: {offerId: offer.id.toString()},
      });
    }
  }, [showModal]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
      position: 'absolute',
      top: '10px',
      right: '10px',
    }),
    [theme]
  );

  const distance = useMemo(() => {
    if (offer.place || place) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(offer?.place?.latitude || place.latitude),
          longitude: Number(offer?.place?.longitude || place.longitude),
        }
      );
    }
    return 0;
  }, [userLocation, place, offer.place]);

  const offerContent = useMemo(() => {
    switch (offer.offer_type) {
      case OfferType.POINTS_BOOST:
        return (
          <PointBoostModalContent
            setShowModal={setShowModal}
            isBookmarked={isBookmarked}
            bookmarkHandler={bookmarkHandle}
            isFutureOffer={futureOffer}
            offer={offer}
            place={place}
            {...(offerEvent && {event: offerEvent})}
            datePassed={datePassed}
            isPreview={isPreview}
          />
        );
      case OfferType.REDEMPTION:
      case OfferType.PREMIUM_REDEMPTION:
      case OfferType.INDIVIDUAL_REDEMPTION:
      case OfferType.EVENT_REDEMPTION:
      default:
        return (
          <RedemptionModalContent
            isFutureOffer={futureOffer}
            offer={offer}
            {...(offerEvent && {event: offerEvent})}
            place={place}
            setShowModal={setShowModal}
            isBookmarked={isBookmarked}
            bookmarkHandler={bookmarkHandle}
            activeRedemption={activeRedemption}
            datePassed={datePassed}
            isPreview={isPreview}
          />
        );
    }
  }, [offer, setShowModal, isBookmarked, bookmarkHandler, offerEvent]);

  return (
    <Modal
      parentCallback={() => setShowModal(false)}
      closeButton={true}
      closeButtonPosition="left"
      contentStyle={{maxHeight: screen.height * 0.95, overflow: 'scroll'}}
      header={
        <VStack mt={4}>
          {!activeRedemption && !isPreview && (
            <Flex minW="60px" direction="column" align="center" onClick={bookmarkHandle}>
              {isBookmarked ? (
                <Icon iconName="fi-sr-bookmark" style={iconStyle} />
              ) : (
                <Icon iconName="fi-rr-bookmark" style={iconStyle} />
              )}
            </Flex>
          )}
          {event && event.unlocks_offer_id === offer.id ? (
            <>
              <TextA18 align="center" fontWeight="700">
                {event.title}
              </TextA18>
              {!isNaN(distance) && (
                <TextG12 fontWeight="500" color={theme.colors.grey}>
                  <i
                    style={{marginRight: '5px', color: theme.colors.blue}}
                    className="fi fi-rr-marker"></i>
                  {getSetting('checkin_distance') && distance < getSetting('checkin_distance')
                    ? `You're Here!`
                    : `${getDistanceString(distance)} away`}
                </TextG12>
              )}
            </>
          ) : place && place.name ? (
            <>
              <TextA18 align="center" fontWeight="700">
                {place.name}
              </TextA18>
              {!isNaN(distance) && (
                <TextG12 fontWeight="500" color={theme.colors.grey}>
                  <i
                    style={{marginRight: '5px', color: theme.colors.blue}}
                    className="fi fi-rr-marker"></i>
                  {getSetting('checkin_distance') && distance < getSetting('checkin_distance')
                    ? `You're Here!`
                    : `${getDistanceString(distance)} away`}
                </TextG12>
              )}
            </>
          ) : null}
        </VStack>
      }
      show={showModal}
      children={
        isPreview ? (
          <SignUpRequiredWrapper setSignUpRequiredOpen={setSignUpRequiredOpen}>
            <SignupRequiredModal
              isOpen={signUpRequiredOpen}
              setIsOpen={setSignUpRequiredOpen}
              onButtonClicked={onSignupClicked}
            />
            <VStack mt={-2} mb={2} gap={1}>
              {offer.title && (
                <H2
                  color={theme.colors.rose}
                  dangerouslySetInnerHTML={{__html: offer?.title}}
                  mb={3}
                  align="center"
                  maxW="240px"
                  fontWeight="700">
                  {}
                </H2>
              )}
              <Image
                fallbackStrategy="onError"
                mt={'0 !important'}
                borderRadius="14px"
                width="184px"
                height="136px"
                objectFit="cover"
                src={offer?.image_url ? getImageURL(offer.image_url) : OfferImage}
                // onClick={() => {
                //   storeScrollPositionInSession();
                //   offer.place || place ? navigate(`/place/${offer.place?.id || place.id}`) : null;
                //   setShowModal(false);
                // }}
              />

              {offerContent}
            </VStack>
          </SignUpRequiredWrapper>
        ) : (
          <VStack mt={-2} mb={2} gap={1}>
            {offer.title && (
              <H2
                color={theme.colors.rose}
                dangerouslySetInnerHTML={{__html: offer?.title}}
                mb={3}
                align="center"
                maxW="240px"
                fontWeight="700">
                {}
              </H2>
            )}
            <Image
              fallbackStrategy="onError"
              mt={'0 !important'}
              borderRadius="14px"
              width="184px"
              height="136px"
              objectFit="cover"
              src={offer?.image_url ? getImageURL(offer.image_url) : OfferImage}
              // onClick={() => {
              //   storeScrollPositionInSession();
              //   offer.place || place ? navigate(`/place/${offer.place?.id || place.id}`) : null;
              //   setShowModal(false);
              // }}
            />

            {offerContent}
          </VStack>
        )
      }
    />
  );
};

export default OfferModal;
