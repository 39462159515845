import {Flex} from '@chakra-ui/react';
import React from 'react';

const HourSelect = ({hourSelected, onChange}) => {
  return (
    <Flex alignItems="center">
      <select
        id="hour-select"
        value={hourSelected}
        onChange={(e) => onChange(Number(e.target.value))}
        style={{
          background: 'white',
          fontFamily: 'Gordita',
          fontSize: '12px',
          fontWeight: '500',
          padding: '5px 10px',
          border: '1px solid #E5E5E5',
          borderRadius: '4px',
          textAlign: 'center',
          appearance: 'none', // Hides the default dropdown arrow
          width: '50px',
        }}>
        {Array.from({length: 23}, (_, i) => (
          <option key={i + 1} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </select>
    </Flex>
    // <Select
    //   options={options}
    //   defaultValue={options.find((option) => option.value === 1)} // Default to 1
    //   onChange={(selected) => selected && setHourSelected(selected.value)}
    //   styles={customStyles}
    //   isSearchable={false}
    // />
  );
};

export default HourSelect;
