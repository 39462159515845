import React, {useState} from 'react';
import {Image, Center, VStack, useTheme, Container, HStack} from '@chakra-ui/react';
import Logo from '../../../../assets/logos/logo_120x120.png';
import LocalIcon from '../../../../assets/icons/custom/local.png';
import {H2,TextG14, TextA18} from '../../../../components/typography';
import {BlueButtonFixedWidth} from '../../../../components/button';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import {PageLoader} from '../../../../components/PageLoader';
import {getAppName} from '../../../../utils/app';

const BLOCKS = [
  {
    description: 'Create a fun nickname and upload a photo.',
    icon: LocalIcon,
  },
  {
    description: 'Let us know your lifestyle goals and interests.',
    icon: LocalIcon,
  },
  {
    description: `We'll give you 15,000 points you can use for local perks and rewards.`,
    icon: LocalIcon,
  },
  {
    description: `We'll show you how to get the most out of Walkabout.`,
    icon: LocalIcon,
  },
];

const Welcome = () => {
  const {user} = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = () => {
    setIsLoading(true);
    navigate('/register/username');
  };

  if (isLoading) return <PageLoader />;

  if (!user) {
    return <TextG14>Something went wrong</TextG14>;
  }

  return (
    <Container px={8}>
      <Header title={`Welcome, ${user?.name}!`} />
      <Center mt={5}>
        <TextA18 textAlign="center" fontWeight="normal" color={theme.colors.blue} mb={4}>
          {getAppName()} curates the best of your community just for you.
        </TextA18>
      </Center>
      <Center>
        <TextG14 textAlign="center" color={theme.colors.grey} mb={3}>
          How to Personalize Your {getAppName()} Experience...
        </TextG14>
      </Center>
      <VStack spacing={8} pt={1} pb={8}>
        {BLOCKS.map((block, i) => (
          <HStack key={i} spacing={8} px={6} w="100%" borderRadius={'10px'} bg={theme.colors.darkerWhite}>
            <Image w="20px" py={6} src={block.icon} />
            <VStack spacing={1} alignItems={'start'}>
              <TextG14 py={4} fontWeight={700}>
                {block.description}
              </TextG14>
            </VStack>
          </HStack>
        ))}
      </VStack>
      <Center py={0}>
        <BlueButtonFixedWidth onClick={handleOnClick}>Start Personalization</BlueButtonFixedWidth>
      </Center>
    </Container>
  );
};

const Header = ({title}) => {
  return (
    <VStack align={'flex-start'} spacing="12px" alignItems="center" gap={6}>
      <HStack mt={5} w="100%" alignItems="center">
        <Image
          position="relative"
          margin="0 !important"
          mr="auto !important"
          ml="auto !important"
          paddingInline={0}
          src={Logo}
          width="61px"
          alt="Logo"
        />
      </HStack>
      <H2>{title}</H2>
    </VStack>
  );
};

export default Welcome;
