import {FilterConf} from "../../../../utils/GlobalSearch/types";
import {CONTENT_TYPE, FILTER_TYPES, TAG_TYPE} from "../../../../utils/GlobalSearch/enums";
import {fetchTags, fetchPassTypes} from "../../../../utils/GlobalSearch/fetchOptions";
import {HAPPENING_FILTER_OPTIONS} from "../../../../utils/GlobalSearch/constants";

const EVENT_CONFIG: {[CONTENT_TYPE.EVENT]: Record<string, FilterConf>} = {
  event: {
    featured: {
      key: 'featured',
      label: 'Featured',
      type: FILTER_TYPES.TOGGLE,
      quick: false,
    },
    free: {
      key: 'free',
      label: 'Free Events',
      type: FILTER_TYPES.TOGGLE,
      quick: true,
    },
    walkaboutOfficial: {
      key: 'walkaboutOfficial',
      label: 'Walkabout Official',
      type: FILTER_TYPES.TOGGLE,
      quick: true,
    },
    withActiveOffers: {
      key: 'withActiveOffers',
      label: 'Has Offers',
      type: FILTER_TYPES.TOGGLE,
      quick: false,
    },
    topics: {
      key: 'tags',
      label: 'Tags',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.TOPICS,
      quick: false,
      fetchOptions: fetchTags,
    },
    goals: {
      key: 'tags',
      label: 'Tags',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.GOALS,
      quick: false,
      fetchOptions: fetchTags,
    },
    passTypes: {
      key: 'passTypes',
      label: 'Eligible Pass Type',
      type: FILTER_TYPES.MULTISELECT,
      quick: false,
      fetchOptions: fetchPassTypes,
      hides: ['free'],
    },
    happening: {
      key: 'happening',
      label: 'Happening',
      type: FILTER_TYPES.MULTISELECT,
      quick: false,
      options: HAPPENING_FILTER_OPTIONS,
    },
    fromDate: {
      key: 'fromDate',
      label: 'From Date',
      type: FILTER_TYPES.DATEPICKER,
      quick: false,
      condition: ({ filters }) =>
        typeof filters?.happening?.[0] === 'string'
          ? filters?.happening.includes('date-range')
          : filters?.happening?.map(({value}) => value).includes('date-range'),
    },
    toDate: {
      key: 'toDate',
      label: 'To Date',
      type: FILTER_TYPES.DATEPICKER,
      quick: false,
      condition: ({ filters }) =>
        typeof filters?.happening?.[0] === 'string'
          ? filters?.happening.includes('date-range')
          : filters?.happening?.map(({value}) => value).includes('date-range'),
    },
  },
};

export default EVENT_CONFIG;
