import {debounce} from "lodash";

export const storeScrollPositionInSession = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const key = window.location.pathname.split('/').filter(Boolean).join('-');

  const scrollPositionKey = id ? `${key}-${id}` : key;
  sessionStorage.setItem(scrollPositionKey, String(window.pageYOffset));
}

export const handleScrollPositionFromSession = () => {
  const scrollPositionKey = window.location.pathname.split('/').filter(Boolean).join('-');

  const scrollPosition = sessionStorage.getItem(scrollPositionKey);
  if (scrollPosition) {
    window.addEventListener(
      "scroll",
      debounce(() => {
        sessionStorage.removeItem(scrollPositionKey);
      }, 1000)
    );
    window.scrollTo(0, parseInt(scrollPosition));
  }
};