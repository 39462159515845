import React from 'react';
import Icon from '../components/Icon';
import {ToastMessage} from '../components/toast';

export enum CommentOption {
  REPORT = 'Report',
  HELPFUL = 'Helpful',
  EDIT = 'Edit',
  DELETE = 'Delete',
}

export const USER_OPTIONS = [
  {
    option: CommentOption.EDIT,
    renderIcon: (props: any) => <Icon iconName={'fi fi-rr-pencil'} {...props} />,
    color: 'lighterBlue',
    text: CommentOption.EDIT,
  },
  {
    option: CommentOption.DELETE,
    renderIcon: (props: any) => <Icon iconName={'fi fi-rr-cross-circle'} {...props} />,
    color: 'red',
    text: CommentOption.DELETE,
  },
];

export const OPTIONS = [
  // {
  //   option: CommentOption.HELPFUL,
  //   renderIcon: (props: any) => <Icon iconName={'fi fi-rr-social-network'} {...props} />,
  //   color: 'lighterBlue',
  //   text: CommentOption.HELPFUL,
  //   message: () =>
  //     ToastMessage({
  //       status: 'success',
  //       showStatusIcon: true,
  //       text: 'Thanks! This message has been marked as helpful.',
  //     }),
  // },
  {
    option: CommentOption.REPORT,
    renderIcon: (props: any) => <Icon iconName={'fi fi-rr-pennant'} {...props} />,
    color: 'red',
    text: CommentOption.REPORT,
    message: () =>
      ToastMessage({
        status: 'success',
        showStatusIcon: true,
        text: 'Thanks! Your report for this message has been sent.',
      }),
  },
];
