import React from 'react';
import {checkInToPlace, checkInToEvent} from '../endpoints/api';
import {generatePath, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {ModalNotificationActions} from '../state/ModalNotification/ModalNotificationSlice';
import {ROUTES} from '../constants/routes';
import {useDispatch} from 'react-redux';
import useAuth from './useAuth';
import {ToastMessage} from '../components/toast';
import {Place, Event, Offer} from '../types/article';
import {useQueryClient} from '@tanstack/react-query';
import {ActiveRedemption} from './useActiveRedemptions';
import {find} from 'lodash';
import {TextG12} from '../components/typography';
import {useTheme} from '@chakra-ui/react';

const invalidateOffers = (queryClient) => {
  queryClient.invalidateQueries([`offers`]);
  queryClient.invalidateQueries([`infinite-offers`]);
};

const invalidateEvent = (queryClient, eventId) => {
  queryClient.invalidateQueries(['events']);
  queryClient.invalidateQueries(['event', eventId]);
  queryClient.invalidateQueries(['infinite-events']);
};

type PlaceCheckInParams = {
  place: Place;
  latitude: number | undefined;
  longitude: number | undefined;
  isPublic: boolean;
  offerId?: number;
  offers: Array<Offer>;
  sdv?: boolean;
  qr_code_id?: number;
};

type EventCheckInParams = {
  event: Event;
  eventOffers: Array<Offer>;
  latitude: number | undefined;
  longitude: number | undefined;
  isPublic: boolean;
  activeRedemptions: Array<ActiveRedemption>;
  sdv?: boolean;
  qr_code_id?: number;
};

/**
 * This is the API provided by useCheckIn()
 */
interface CheckInAPI {
  placeCheckIn(params: PlaceCheckInParams): void;
  eventCheckIn(params: EventCheckInParams): void;
}

const CheckInContext = React.createContext<CheckInAPI>({} as CheckInAPI);

export const CheckInProvider = ({children}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const {reFetchUserData} = useAuth();
  const {pathname} = useLocation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  const placeCheckIn = ({
    place,
    latitude,
    longitude,
    isPublic,
    offerId,
    offers,
    sdv,
    qr_code_id,
  }: PlaceCheckInParams): void => {
    checkInToPlace({
      placeId: place.id,
      latitude,
      longitude: longitude,
      isPublic,
      offerId,
      sdv,
      qr_code_id,
    })
      .then(({data}) => {
        invalidateOffers(queryClient);
        // Re-fetch user data in order to get new value of points total.
        reFetchUserData();
        let subtitle;
        let cta;
        let closeLabel;

        if (offers.length > 0) {
          const url = new URL(location.href);
          if (!url.searchParams.get('tab')) url.searchParams.append('tab', 'Offers');
          subtitle = (
            <>
              This place has&nbsp;
              <TextG12 as="span" userSelect="none" fontWeight="500" color={theme.colors.rose}>
                {offers.length} active Offers
              </TextG12>
              &nbsp;you can redeem with points.
            </>
          );
          cta = {
            ctaLabel: 'View Offers',
            ctaLink: url.pathname + url.search,
          };
          closeLabel = 'Skip for now';
        } else {
          subtitle = place.is_bookmarked
            ? '' //'This place is already in your bookmarks.'
            : 'Bookmark this place to stay up on special offers, upcoming events, and unique experiences.';
          cta = {
            ctaLabel: !place.is_bookmarked ? 'Bookmark' : 'Ok',
            ctaLink: !place.is_bookmarked ? 'bookmark' : null,
          };
          closeLabel = !place.is_bookmarked ? 'Skip for now' : null;
        }

        let bonusPoints = 0;

        let boostedPoints = 0;
        let checkinMessage;

        const basePoints = data?.points_given ?? 0;

        // Find the bonus points entry, if it exists
        const bonusEntry = data.points_history.find(
          (entry) => entry.activity === 'MEMBERSHIP_BOOST'
        );
        bonusPoints = bonusEntry?.data?.bonus_points ?? 0;

        // Find the boosted points entry, if it exists
        const boostedEntry = data.points_history.find(
          (entry) => entry.activity === 'PLACE_CHECK_IN_BOOST'
        );
        boostedPoints = boostedEntry?.data?.bonus_points ?? 0;

        // Construct message based on whether bonus points exist
        if (basePoints > 0) {
          checkinMessage = `You've just earned earned ${basePoints} points for checking in!`;
        } else {
          checkinMessage = `You're now checked-in and ready to roll.`;
        }

        closeLabel = closeLabel || (data.points_given > 0 ? 'Close' : 'OK');

        let bottomCtaLabel;
        let bottomCtaLink;
        let closeButton;
        let bottomCtaColor;
        let bottomCtaBackground;
        let bottomCtaIconName;
        let bottomCtaBorder;

        if (!user?.hasActiveMembership) {
          bottomCtaLabel = 'Explore Premium Options';
          bottomCtaLink = '/memberships';
          closeLabel = null;
          closeButton = 'true';
        } else {
          bottomCtaIconName = 'fi-rr-users-medical';
          bottomCtaColor = theme.colors.blue;
          bottomCtaBorder = `1px solid ${theme.colors.blue}`;
          bottomCtaBackground = 'white';
          bottomCtaLabel = 'Invite Your Neighbors to Join you';
          bottomCtaLink = `/create-meetup?placeId=${place.id}`;
          closeLabel = null;
          closeButton = 'true';
        }

        dispatch(
          ModalNotificationActions.pushNotification({
            hasActiveMembership: user?.hasActiveMembership ? 'true' : 'false',
            placeData: JSON.stringify(place),
            points: data?.points_given,
            subtitle,
            closeLabel,
            ...cta,
            boostedPoints: boostedPoints?.toString(),
            bonusPoints: bonusPoints?.toString(),
            bottomCtaLabel,
            bottomCtaLink,
            closeButton,
            bottomCtaColor,
            bottomCtaBackground,
            bottomCtaIconName,
            bottomCtaBorder,
          })
        );
        if (searchParams.has('id')) {
          navigate(`${pathname}?id=${searchParams.get('id')}`, {replace: true});
          return;
        }
        navigate(generatePath(ROUTES.PLACE, {placeId: place.id.toString()}), {replace: true});
      })
      .catch((e) => {
        ToastMessage({
          status: 'error',
          text: e.message,
        });
      });
  };

  const eventCheckIn = ({
    event,
    eventOffers,
    activeRedemptions,
    latitude,
    longitude,
    isPublic,
    sdv,
    qr_code_id,
  }: EventCheckInParams): void => {
    checkInToEvent({eventId: event.id, latitude, longitude: longitude, isPublic, sdv, qr_code_id})
      .then(({data}) => {
        invalidateOffers(queryClient);
        invalidateEvent(queryClient, event.id);
        // Re-fetch user data in order to get new value of points total.
        reFetchUserData();
        let title;
        let checkinMessage;
        let subtitle;
        let ctaLabel;
        let ctaLink;
        let closeLabel;

        const hasActive = find(activeRedemptions, (redemption) => {
          return redemption.offer.event?.id == event.id;
        });

        const unlocksOffer = event.unlocks_offer_id;

        if (unlocksOffer) {
          title = 'Congrats!';
          checkinMessage = 'You’ve unlocked a special offer with this check-in.';
          subtitle =
            "Lucky you! This offer is only valid during this event, so get it now before it's gone.";
          ctaLabel = 'Get Offer';
          ctaLink = 'offer-redemption';
          closeLabel = 'Close';
        } else if (hasActive) {
          title = data.points_given > 0 ? 'Woo hoo!' : 'Excellent';
          subtitle = 'You have an offer in your wallet that you can use right now. ';
          ctaLabel = 'View Offer';
          ctaLink = '/wallet';
        } else if (eventOffers.length <= 0 && data.points_given > 0) {
          title = data.points_given > 0 ? 'Woo hoo!' : 'Excellent';
          subtitle = 'Check out Perks to see how you can use these points.';
          ctaLabel = 'View Perks';
          ctaLink = '/search/offer';
        } else if (eventOffers.length > 0) {
          const url = new URL(location.href);
          if (!url.searchParams.get('highlight')) url.searchParams.append('highlight', 'offers');
          title = data.points_given > 0 ? 'Woo hoo!' : 'Excellent';
          subtitle = 'There are cool offers on this event that you can use right now.';
          ctaLabel = 'View Offers';
          ctaLink = url.pathname + url.search;
        }

        let bonusPoints = 0;
        let boostedPoints = 0;

        if (!checkinMessage) {
          const basePoints = data?.points_given ?? 0;

          // Find the bonus points entry, if it exists
          const bonusEntry = data.points_history.find(
            (entry) => entry.activity === 'MEMBERSHIP_BOOST'
          );
          bonusPoints = bonusEntry?.data?.bonus_points ?? 0;

          // Find the boosted points entry, if it exists
          const boostedEntry = data.points_history.find(
            (entry) => entry.activity === 'EVENT_CHECK_IN_BOOST'
          );
          boostedPoints = boostedEntry?.data?.bonus_points ?? 0;

          // Construct message based on whether bonus points exist
          if (bonusPoints > 0) {
            checkinMessage = `You've just earned earned ${basePoints} points for checking-in!`;
          } else if (eventOffers.length > 0) {
            checkinMessage = `You're checked-in to this event.`;
          } else if (basePoints > 0) {
            checkinMessage = `You've just earned earned ${basePoints} points for checking in!`;
          } else {
            checkinMessage = `You're now checked-in and ready to roll.`;
          }
        }

        closeLabel = closeLabel || (data.points_given > 0 ? 'Close' : 'OK');

        let bottomCtaLabel;
        let bottomCtaLink;
        let closeButton;

        if (!user?.hasActiveMembership) {
          bottomCtaLabel = 'Explore Premium Options';
          bottomCtaLink = '/memberships';
          closeLabel = null;
          closeButton = 'true';
        }

        dispatch(
          ModalNotificationActions.pushNotification({
            hasActiveMembership: user?.hasActiveMembership ? 'true' : 'false',
            eventData: JSON.stringify(event),
            title,
            checkinMessage,
            points: data.points_given,
            boostedPoints: boostedPoints?.toString(),
            bonusPoints: bonusPoints?.toString(),
            subtitle,
            ctaLabel,
            ctaLink,
            closeLabel,
            bottomCtaLabel,
            bottomCtaLink,
            closeButton,
          })
        );
        // if (searchParams.has('id')) {
        //   navigate(`${pathname}?id=${searchParams.get('id')}`, {replace: true});
        //   return;
        // }
        // //navigate(generatePath(ROUTES.PLACE, {placeId: place.id.toString()}), {replace: true});
      })
      .catch((e) => {
        ToastMessage({
          status: 'error',
          text: e.message,
        });
      });
  };

  const checkInValue: CheckInAPI = {
    placeCheckIn,
    eventCheckIn,
  };

  return <CheckInContext.Provider value={checkInValue}>{children}</CheckInContext.Provider>;
};

export default function useCheckIn() {
  return React.useContext(CheckInContext);
}
