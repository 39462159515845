import {Tab, TabList, TabPanel, TabPanels, Tabs, useTheme} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {Offer, Place} from '../../types/article';
import PlaceAbout from './About';
import Upvotes from './Upvotes';
import PlaceEvents from './PlaceEvents';
import {filter, pull} from 'lodash';
import PlacePosts from './PlacePosts';
import dayjs from 'dayjs';
import PlaceOffers from './PlaceOffers';
import {useSearchParams} from 'react-router-dom';

const PlaceTabs = ({
  place,
  placeOffers,
  disableLocation,
  preview = false,
  setSignUpRequiredOpen,
}: {
  place: Place;
  placeOffers: Offer[];
  disableLocation: () => void;
  preview?: boolean;
  setSignUpRequiredOpen?: any;
}) => {
  const theme = useTheme();
  const now = dayjs();

  const tabs = ['About'];

  const filteredEvents = filter(
    place.events,
    (e) => now.isBefore(dayjs.utc(e.end_date_time)) && !e.is_meetup
  );

  const hasEvents = filteredEvents && filteredEvents.length > 0;
  const hasPosts = place.articles && place.articles.length > 0;
  const hasOffers = placeOffers && placeOffers.length > 0;

  const isProduction = process.env.NODE_ENV === 'production';

  if (hasOffers) {
    tabs.push('Offers');
  } else {
    pull(tabs, 'Offers');
  }

  if (hasEvents) {
    tabs.push('Events');
  } else {
    pull(tabs, 'Events');
  }

  if (hasPosts) {
    tabs.push('Posts');
  } else {
    pull(tabs, 'Posts');
  }

  // if (!isProduction) tabs.push('Up-Votes'); //temp hide upvotes on production

  const [searchParams] = useSearchParams();
  const activeTabIndex = tabs.includes(searchParams.get('tab') || '')
    ? tabs.indexOf(searchParams.get('tab') || '')
    : 0;
  const [tabIndex, setTabIndex] = React.useState(activeTabIndex);
  const handleTabsChange = (index) => setTabIndex(index);

  useEffect(() => {
    setTabIndex(activeTabIndex);
  }, [searchParams]);

  const tabStyle: React.CSSProperties = React.useMemo(
    () => ({
      borderBottomColor: theme.colors.blue,
      color: theme.colors.blue,
    }),
    []
  );

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange} align="center">
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab} _selected={tabStyle}>
            {tab}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        <TabPanel p="0">
          <PlaceAbout
            setSignUpRequiredOpen={setSignUpRequiredOpen}
            preview={preview}
            place={place}
            placeOffers={placeOffers}
            disableLocation={disableLocation}
          />
        </TabPanel>

        {hasOffers && (
          <TabPanel>
            <PlaceOffers
              setSignUpRequiredOpen={setSignUpRequiredOpen}
              preview={preview}
              place={place}
              placeOffers={placeOffers}
            />
          </TabPanel>
        )}
        {hasEvents && (
          <TabPanel>
            <PlaceEvents
              setSignUpRequiredOpen={setSignUpRequiredOpen}
              preview={preview}
              events={filteredEvents}
            />
          </TabPanel>
        )}

        {hasPosts && (
          <TabPanel>
            <PlacePosts
              setSignUpRequiredOpen={setSignUpRequiredOpen}
              preview={preview}
              posts={place.articles}
            />
          </TabPanel>
        )}
        {/* temp hide on production */}
        {/* {!isProduction && (
          <TabPanel>
            <Upvotes place={place} />
          </TabPanel>
        )} */}
      </TabPanels>
    </Tabs>
  );
};

export default PlaceTabs;
