import {Center, Image, Spinner, useTheme, Flex, Box, Progress, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useYouTubeVideoData} from '../../hooks/useYoutube';
import {getYouTubeVideoThumbnail, getYouTubeVideoDuration} from '../../utils/youtube';
import {TextA18, TextG10, TextG12, TextG14} from '../typography';
import {VideoTask} from './GuidedExperienceWidget';
import GuidedJourneyHeader from './GuidedJourneyHeader';
import JourneyBadge from './JouryneyBadge';
import VideoPreview from './VideoPreview';

const NextVideoData = ({
                           url,
                           thumbnail,
                           task,
                           textColor,
                           variant = 'default',
                           rightComponent,
                           taskNum,
                           totalTasks,
                           setBottomSheetOpen,
                           onPreviewClick,
                       }: {
    url: string;
    thumbnail?: any;
    task?: VideoTask;
    textColor?: string;
    variant?: 'default' | 'large';
    rightComponent?: JSX.Element;
    taskNum?: number;
    totalTasks?: number;
    setBottomSheetOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    onPreviewClick?: () => void;
}) => {
    const theme = useTheme();
    const nextUrlThumbnail = thumbnail ? thumbnail : getYouTubeVideoThumbnail(url);
    const [duration, setDuration] = useState('');
    const [title, setTitle] = useState('');
    const {data} = useYouTubeVideoData(url);

    useEffect(() => {
        if (data) {
            if (task?.title) setTitle(task.title);
            else setTitle(data.snippet.title);

            setDuration(getYouTubeVideoDuration(data.contentDetails.duration));
        }
    }, [data, task]);

    // Default variant: thumbnail on the left, title and duration on the right
    if (variant === 'default') {
        return (
            <Flex color={textColor || 'black'} flexDir="column" gap="4px" w="100%">
                <Flex w="100%" justifyContent="space-between" alignItems="center">
                    <TextG10>{`Up Next:`}</TextG10>
                    <TextG10>{`${duration}`}</TextG10>
                </Flex>
                <Flex w="100%" justifyContent="space-between" alignItems="center">
                    <Flex flexDir="row" alignItems="center" gap="10px" w="100%">
                        {nextUrlThumbnail && (
                            <Image borderRadius="4px" w="50px" h="28px" src={nextUrlThumbnail} />
                        )}
                        <TextG10 fontWeight="500">{title}</TextG10>
                    </Flex>
                    {rightComponent ? rightComponent : null}
                </Flex>
            </Flex>
        );
    }

    // Large variant: full-width thumbnail on top, title and duration below
    if (variant === 'large') {
        return (
            <Flex gap="0px" color={textColor || 'black'} flexDir="column" alignItems="start">
                <GuidedJourneyHeader setBottomSheetOpen={setBottomSheetOpen} />
                <Box onClick={onPreviewClick} w="100%" h="100%">
                    <VideoPreview
                        url={url}
                        task={task}
                        taskNum={taskNum}
                        totalTasks={totalTasks}
                        thumbnail={nextUrlThumbnail}
                    />
                </Box>
                {/* <TextA18>{`Up Next:`}</TextA18>
        {nextUrlThumbnail && <Image borderRadius="4px" w="100%" h="auto" src={nextUrlThumbnail} />}
        <TextG10 fontWeight="500">
          {`Duration: ${duration}`}
          <br />
          {title}
        </TextG10> */}
            </Flex>
        );
    }

    return null; // Fallback if no valid variant

    // return (
    //   <Flex gap="10px" color={textColor || 'black'} alignItems="center">
    //     {nextUrlThumbnail && <Image borderRadius="4px" w="50px" h="28px" src={nextUrlThumbnail} />}
    //     <Flex flexDir="column" justifyContent="space-evenly">
    //       <TextG10>{`Up Next: ${duration}`}</TextG10>
    //       <TextG10 fontWeight="500">
    //         {title}
    //       </TextG10>
    //     </Flex>
    //   </Flex>
    // );
};

export default NextVideoData;
