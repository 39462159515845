import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {Offer} from '../../types/article';
import {TextG10, TextG8} from '../typography';

const OffersCardBanner = ({fullCard, offers}: {fullCard: boolean; offers: Array<Offer>}) => {
  const theme = useTheme();

  if (fullCard)
    return (
      <Flex ml="10px" mb="2px" align="center" textAlign="center">
        <TextG10
          borderRadius="10px"
          px="8px"
          pt="2px"
          background={theme.colors.rose}
          color="white">{`${offers.length} ${offers.length == 1 ? 'Offer' : 'Offers'}`}</TextG10>
      </Flex>
    );
  return (
    <Flex pos="absolute" top={0} left={0}>
      <TextG10
        borderTopLeftRadius="12px"
        borderBottomRightRadius="10px"
        px={2}
        py={1}
        background={theme.colors.rose}
        color="white">{`${offers.length} ${offers.length == 1 ? 'Offer' : 'Offers'}`}</TextG10>
    </Flex>
  );
};

export default OffersCardBanner;
