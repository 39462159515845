import {createSlice} from '@reduxjs/toolkit';
import { Community } from "../../types/community";

export type CommunitySliceProps = {
  community: any;
};

export function saveCommunityToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem('selectedCommunity', serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

export function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem('selectedCommunity');
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

export type SliceState = CommunitySliceProps;
type SliceAction = {payload: CommunitySliceProps; type: string};
const initialState: SliceState = {
  community: loadFromLocalStorage() || {name: 'All', id: undefined},
};

export const CommunitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    selectCommunity: (state: SliceState, action: SliceAction) => {
      state.community = action.payload.community;
    },
  },
});

export const CommunityActions = CommunitySlice.actions;

export default CommunitySlice;
