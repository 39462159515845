import {
  Center,
  Container,
  Divider,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from '@chakra-ui/react';
import {startCase, camelCase} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {H3, TextG12} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import Bookmarks from '../Bookmarks';
import MyDiscounts from '../MyDiscounts';
import MyPasses from '../MyPasses';
import MyTickets from '../MyTickets';
import Wallet from '../Wallet';
 const STUFF_TABS = ['Discounts', 'Redeemed Offers', 'Passes', 'Tickets', 'Bookmarks'];

const MyStuff = () => {
  const theme = useTheme();
  const {user} = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();

 
  const getDefaultTab = () => {
    const tabFromParams = searchParams.get('tab');
    const tabIndex = STUFF_TABS.indexOf(startCase(camelCase(tabFromParams || STUFF_TABS[0])));
    return tabIndex >= 0 ? tabIndex : 0; // Default to the first tab if not found
  };

  const [tabIndex, setTabIndex] = useState(getDefaultTab());

  // Update the tab index when searchParams change
  useEffect(() => {
    const newTabIndex = getDefaultTab();
    setTabIndex(newTabIndex);
  }, [searchParams]);

  // Update the searchParams when tabIndex changes
  useEffect(() => {
    setSearchParams({['tab']: STUFF_TABS[tabIndex]}, {replace: true});
  }, [tabIndex]);

  return (
    <Container pb={10} h="auto">
      <Tabs
        outline={'none !important'}
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isFitted
        isLazy
        lazyBehavior="keepMounted">
        <Flex
          flexDir="column"
          w="100vw"
          ml={-4}
          justifyContent="center"
          background={theme.colors.blueShade2}>
          <H3 mt="25px" mb="10px" mx={4} lineHeight="24px" color={theme.colors.black}>
            My Stuff
          </H3>
          <TabList w="100vw" justifyContent="center" px={2}>
            {STUFF_TABS.map((tab, index) => (
              <Tab
                px={0}
                _selected={{
                  color: theme.colors.blue,
                  borderBottomColor: theme.colors.blue,
                  borderBottomWidth: '3px',
                }}
                key={index}>
                <TextG12
                  fontWeight={tabIndex === index ? 700 : 500}
                  color={tabIndex === index ? theme.colors.blue : theme.colors.grey}>
                  {tab}
                </TextG12>
              </Tab>
            ))}
          </TabList>
        </Flex>

        <TabPanels zIndex="1000" background="white">
          <TabPanel p={0}>
            <MyDiscounts />
          </TabPanel>
          <TabPanel p={0}>
            <Wallet />
          </TabPanel>
          <TabPanel p={0}>
            <MyPasses />
          </TabPanel>
          <TabPanel p={0}>
            <MyTickets />
          </TabPanel>
          <TabPanel p={0}>
            <Bookmarks />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default MyStuff;