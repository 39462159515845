import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import {TextA18} from '../../../components/typography';

const Header = ({passName, passAmount}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Flex
      flexDir={'column'}
      background={theme.colors.blueShade2}
      px={3}
      py={5}
      align="flex-start"
      w="100%">
      <BackButton onClick={() => navigate('/my-stuff?tab=Passes')} />
      <TextA18 fontWeight="700" w="100%" ml="2px" mt="14px">
        {`${passName} Passes (${passAmount})`}
      </TextA18>
    </Flex>
  );
};

export default Header;
