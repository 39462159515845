import {Box} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomSheetSelect from '../../../BottomSheetSelect';
import Icon from '../../../Icon';
import {ToastMessage} from '../../../toast';
import {EventChatMenuOption, OPTIONS} from './EventChatMenuOptions';

const ChatMenu = ({
  options,
  handleOptionClicked,
  optionsOpen,
  setOptionsOpen,
}: {
  options: any;
  handleOptionClicked: (any) => void;
  optionsOpen: boolean;
  setOptionsOpen: (open) => void;
}) => {
  return (
    <>
      <BottomSheetSelect
        options={options}
        openModal={optionsOpen}
        onModalClose={() => setOptionsOpen(false)}
        onClick={handleOptionClicked}
      />
      <Box
        m="0.75rem"
        mt="1.1rem"
        pos="absolute"
        top={0}
        right={0}
        onClick={() => setOptionsOpen(true)}>
        <Icon
          iconName="fi-rr-menu-dots-vertical"
          style={{fontSize: '24px', width: '24px', height: '24px'}}
        />
      </Box>
    </>
  );
};

export default ChatMenu;
