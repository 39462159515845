import React from 'react';
import {isIphoneAndChrome, isIphoneAndSafari, isAndroidAndChrome} from '../../utils/deviceCheck';
import {TextG12, H3} from '../typography';
import {Box, Image, VStack} from '@chakra-ui/react';
import useGetUserLocation, {Location} from '../../hooks/useUserLocation';
import LocationImg from '../../assets/images/location.png';
import LocationIphoneAa from '../../assets/images/location-iphone-aa.png';
import WebsiteSettings from '../../assets/images/website-settings.png';
import AllowLocation from '../../assets/images/allow-location.png';
import BackButton from '../BackButton';
import { useNavigate } from 'react-router-dom';

const LocationDisabled = () => {
  const userLocation = useGetUserLocation();
  const navigate = useNavigate();

  const locationServicesInstruction =
    isAndroidAndChrome || isIphoneAndChrome ? (
      <>
        <TextG12 px={6} textAlign={'center'}>
          1. In the browser address bar, tap the Lock symbol → Permissions.
        </TextG12>
        <TextG12 px={6} textAlign={'center'}>
          2. Set Location to <b>On</b>.
        </TextG12>
      </>
    ) : isIphoneAndSafari ? (
      <>
        <TextG12 px={6} width={'300px'} textAlign={'center'}>
          → In the browser address bar, tap the <b>AA symbol</b>.
        </TextG12>
        <Image w={'200px'} src={LocationIphoneAa} alt={'location-instruction-iphone'} />
        <TextG12 px={6} width={'300px'} textAlign={'center'}>
          → Then tap <b>Website Settings</b>
        </TextG12>
        <Image w={'200px'} src={WebsiteSettings} alt={'website-settings-instruction'} />
        <TextG12 px={6} width={'300px'} textAlign={'center'}>
          → Set Location to <b>Allow</b>
        </TextG12>
        <Image w={'200px'} src={AllowLocation} alt={'allow-location-instruction'} />
      </>
    ) : null;

  return (
    <>
      <Box p={3}>
        <BackButton onClick={() => navigate(-1)} />
      </Box>
      <Box
        px={8}
        pos={'fixed'}
        top={'50%'}
        left={'50%'}
        transform={'translate(-50%, -50%)'}
        w={'100%'}>
        <VStack spacing={4}>
          <Image src={LocationImg} alt={'location'} />
          {userLocation.isError ? (
            <>
              <H3 style={{fontFamily: 'Gordita', fontSize: '20px'}}>Enable Location Service</H3>
              <TextG12 width={'245px'} px={6} textAlign={'center'}>
                Please check if your device location settings are turned on, and permission is
                granted for this app.
              </TextG12>
              {locationServicesInstruction}
            </>
          ) : (
            <H3>Verifying your location...</H3>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default LocationDisabled;
