import React from 'react';
import {TextG12} from '../typography';
import {Flex, Switch} from '@chakra-ui/react';

const CustomSwitch = ({
  checked,
  onChange,
  title,
}: {
  checked: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
}) => {
  return <Flex alignItems={"flex-end"} gap={"5px"} marginBottom={"5px"}>
    <TextG12>{title}</TextG12>
    <Switch 
        size={"md"} 
        defaultChecked={checked}
        colorScheme="green"
        onChange={onChange}/>
  </Flex>  
}

export default CustomSwitch;