import mapKeys from 'lodash/mapKeys';
import {snakeCase} from "lodash";

/**
 * transform key strings to camelCase
 *
 * @param obj
 * @param except
 */
const snakeCaseObj = (obj, except?: string[]) => mapKeys(obj, (value, key) => except && except.includes(key) ? key : snakeCase(key));

export default snakeCaseObj;
