import {AbsoluteCenter, Center, Container, Flex, useTheme, VStack} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import MeetUpCard from '../../components/Articles/MeetUpCard';
import {BlueButton} from '../../components/button';
import Icon from '../../components/Icon';
import {SpinnerLoader} from '../../components/Loader';
import {TextG14, TextG12} from '../../components/typography';
import {useFetchMeetups} from '../../hooks/useUserMeetups';

const MyMeetups = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {data, isLoading} = useFetchMeetups();
  const userMeetups = data?.data?.data;

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '2rem',
    }),
    [theme]
  );

  if (isLoading) {
    return (
      <AbsoluteCenter>
        <SpinnerLoader />
      </AbsoluteCenter>
    );
  }
  return (
    <Container pt={4} px={'0 !important'} pb={'initial'} h="auto">
      {userMeetups && userMeetups.length > 0 ? (
        <VStack gap={2}>
          {userMeetups.map((meetup, i) => {
            return <MeetUpCard key={i} meet-up={meetup} />;
          })}
        </VStack>
      ) : (
        <AbsoluteCenter w="250px">
          <Flex alignItems="center" textAlign="center" flexDir="column" gap={4}>
            <Center>
              <Icon iconName="fi-rr-users-medical" style={iconStyle} />
            </Center>
            <TextG14 color="#8E8E8E" fontWeight="500" lineHeight="20px" letterSpacing="-0.01em">
              It looks like you haven't created any meet-ups yet.
            </TextG14>
            <TextG12 color="#8E8E8E" fontWeight="400" lineHeight="20px" letterSpacing="-0.01em">
              Create a new meet-up now and invite your neighbors to come hang out!
            </TextG12>
            <BlueButton
              onClick={() =>
                navigate({
                  pathname: '/create-meetup',
                })
              }
              borderRadius="27.5px"
              px={6}
              py={3}
              w="auto"
              h="auto">
              Create a Meet-Up
            </BlueButton>
          </Flex>
        </AbsoluteCenter>
      )}
    </Container>
  );
};

export default MyMeetups;
