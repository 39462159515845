import {useQuery} from '@tanstack/react-query';
import {fetchPartner, fetchPublicPartner} from '../endpoints/api';

export function useFetchPartner(partnerId: number | undefined) {
  return useQuery(['partner', partnerId], () => fetchPartner({id: Number(partnerId)}), {
    enabled: !!partnerId && typeof partnerId === 'number',
  });
}

export function useFetchPublicPartner(partnerUuid: string | undefined) {
  return useQuery(['public_partner', partnerUuid], () => fetchPublicPartner(partnerUuid), {
    enabled: !!partnerUuid && typeof partnerUuid === 'string',
  });
}
