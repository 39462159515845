import {useQueryClient, useMutation, useInfiniteQuery} from '@tanstack/react-query';
import {fetchNotifications, markNotificationRead, markAllNotificationsRead} from '../endpoints/api';
import useAuth from "./useAuth";

export const useGetNotifications = () => {
  const { reFetchUserData } = useAuth();
  
  return useInfiniteQuery(
    ['userNotifications'],
    ({pageParam = 1}) => fetchNotifications({page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      onSuccess: () => {
        reFetchUserData();
      },
    }
  );
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  return useMutation((id: Number) => markNotificationRead(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['userNotifications']);
    },
  });
};

export const useReadAllNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(() => markAllNotificationsRead(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['userNotifications']);
    },
  });
};
