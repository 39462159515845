import React from "react";
import {
  createStandaloneToast,
  Flex,
  HStack,
  Image,
  Spacer,
  UseToastOptions
} from "@chakra-ui/react";
import CheckmarkIcon from "../assets/images/check.png";
import { TextG14 } from "./typography";
import styled from "styled-components";
import { jsx } from "@emotion/react";

const { toast } = createStandaloneToast();
import JSX = jsx.JSX;

const StyledFlex = styled(Flex)`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  color: white;
`;

const Toast = ({ status, text, children, showStatusIcon, action }) => {
  let icon;
  let toastColor;

  switch (status) {
    case "success":
      icon = CheckmarkIcon;
      toastColor = "#276ACD";
      break;
    case "error":
      // icon = InfoIcon;
      toastColor = "#F5737F";
      break;
    case "info":
      toastColor = "#276ACD";
    default:
    // icon = InfoIcon;
  }

  return (
    children || (
      <StyledFlex backgroundColor={toastColor} align="center" p={3} mb={5}>
        <HStack spacing={2}>
          {showStatusIcon && icon && <Image src={icon} alt={"Success"} />}
          <TextG14 fontWeight={"500"}>{text}</TextG14>
        </HStack>
        <Spacer />
        {action}
      </StyledFlex>
    )
  );
};

Toast.defaultParams = {
  children: null,
  showStatusIcon: false,
  action: null,
};

type ToastParams = {
  status: Status;
  text: string;
  children?: JSX.Element | null;
  showStatusIcon?: boolean;
  action?: JSX.Element;
  id?: string;
  duration?: number;
};

type Status = "success" | "info" | "error";

/**
 * @param status 'success' | 'info' | 'error'
 * @param showStatusIcon
 * @param text string
 * @param children
 * @param action
 */
export const ToastMessage = ({
  status,
  showStatusIcon,
  text,
  children,
  action,
  id,
  duration = 3000,
}: ToastParams) => {
  if (id && toast.isActive(id)) {
    return;
  }
  toast({
    position: "bottom",
    duration,
    render: () => (
      <Toast
        status={status}
        text={text}
        showStatusIcon={showStatusIcon}
        action={action}
      >
        {children}
      </Toast>
    ),
    ...(id && { id }),
  });
};

export const FullScreenToast = (
  onCloseCompleteCallback: () => void | undefined,
  component: React.ReactNode,
  duration: number = 2500,
 ) => {
  toast({
    containerStyle: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: 0,
    },
    duration,
    onCloseComplete: onCloseCompleteCallback,
    render: () => component,
  });
};

export default Toast;
