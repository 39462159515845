import React, {useEffect, useState} from 'react';
import BackButton from '../../../components/BackButton';
import {Box, Divider, useTheme} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {H3, TextG12} from '../../../components/typography';
import RoundedBoxItems from '../../../components/RoundedBoxItems';
import topics, {GroupedTopics} from '../../Auth/Register/Topics';
import {
  fetchLifestyleGroups,
  fetchTopics,
  removeLifestyle,
  removeTopic,
  setLifestyle,
  setTopic,
} from '../../../endpoints/api';
import useAuth from '../../../hooks/useAuth';
import {LifestyleGroup} from '../../Auth/Register/LifestyleGoals';
import {getAppName} from '../../../utils/app';
import {sortBy} from 'lodash';
import {ToastMessage} from '../../../components/toast';

const LifestyleGoalsAndInterests = ({onClose}: {onClose?: () => void}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user, reFetchUserData} = useAuth();
  const [lifestyleGroups, setLifestyleGroups] = useState<Array<LifestyleGroup>>([]);
  const [groupedTopics, setGroupedTopics] = useState<Array<GroupedTopics>>([]);

  const [selectedLifestyleGroupIds, setSelectedLifestyleGroupIds] = useState<number[]>(user?.lifestyleGroups.map((lifestyleGroup) => parseInt(lifestyleGroup.id)) || []);
  const [selectedTopicIds, setSelectedTopicIds] = useState<number[]>(user?.topics.map((topic) => parseInt(topic.id)) || []);

  useEffect(() => {
    fetchLifestyleGroups().then(({data}) => setLifestyleGroups(data));
    fetchTopics().then(({data}) => setGroupedTopics(sortBy(data, 'name')));
  }, []);

  useEffect(() => {
    if(user && user?.lifestyleGroups.length < 1) {
      ToastMessage({
        status: 'error',
        text: 'Choose at least one Goal.',
      });
      return;
    }

    if (user && (user?.topics.length < 1)) {
      ToastMessage({
        status: 'error',
        text: 'Choose at least one tag from any category.',
      });
      return;
    }
  }, [location.href]);

  const handleOnSelectLifestyle = (lifestyleId, isSelected) => {
    isSelected
      ? setLifestyle(lifestyleId).then(() => {
        setSelectedLifestyleGroupIds([...selectedLifestyleGroupIds, lifestyleId]);
      }).catch(() => alert('Something went wrong'))
      : removeLifestyle(lifestyleId).then(() => {
        setSelectedLifestyleGroupIds(selectedLifestyleGroupIds.filter((id) => id !== lifestyleId));
      }).catch(() => alert('Something went wrong'));
    reFetchUserData();
  };

  const handleOnSelectTopic = (topicId, isSelected) => {
    isSelected
      ? setTopic(topicId).then(() => {
        setSelectedTopicIds([...selectedTopicIds, topicId]);
      }).catch(() => alert('Something went wrong'))
      : removeTopic(topicId).then(() => {
        setSelectedTopicIds(selectedTopicIds.filter((id) => id !== topicId));
      }).catch(() => alert('Something went wrong'));
    reFetchUserData();
  };

  const onGoBack = () => {
    if(selectedLifestyleGroupIds.length < 1) {
      ToastMessage({
        status: 'error',
        text: 'Choose at least one Goal.',
      });
      return;
    }

    if (selectedTopicIds.length < 1) {
      ToastMessage({
        status: 'error',
        text: 'Choose at least one tag from any category.',
      });
      return;
    }

    onClose ? onClose() : navigate('/profile');
  };

  return (
    <>
      <Box px={6} py={4} bg={theme.colors.veryLightBlue}>
        <BackButton my={4} pos={'relative'} onClick={onGoBack} />
        <H3 mb={2}>Lifestyle Goals & Interests</H3>
        <TextG12>
          Personalize your {getAppName()} experience. Choose at least one goal and one tag from any category.
        </TextG12>
      </Box>
      <Box px={6} pt={5}>
        <RoundedBoxItems
          key={'myGoals'}
          title={'Goals'}
          elements={lifestyleGroups}
          selected={
            user?.lifestyleGroups.length ? user?.lifestyleGroups.map((l) => parseInt(l.id)) : []
          }
          onSelect={handleOnSelectLifestyle}
        />
      </Box>
      <Divider mt={8} />
      <Box px={6}>
        {groupedTopics.map(({name: category, topics}) => (
          <Box key={category} py={5}>
            <RoundedBoxItems
              key={category}
              title={category}
              elements={topics}
              selected={user?.topics.length ? user?.topics.map((t) => parseInt(t.id)) : []}
              onSelect={handleOnSelectTopic}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default LifestyleGoalsAndInterests;
