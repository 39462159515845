import React from 'react';
import PublicRoutes from './PublicRoutes';
import AuthorizedRoutes from './AuthorizedRoutes';
import useAuth from '../hooks/useAuth';

function Routes() {
    const { isUserAuthenticated } = useAuth();
    return isUserAuthenticated
        ? (
            <AuthorizedRoutes />
        )
        : (
            <PublicRoutes />
        );
}

export default Routes;
