import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useTheme,
  Image,
  Heading,
  Box,
  Divider,
  HStack,
  Icon as ChakraIcon,
  Checkbox,
  Alert,
  AlertIcon,
  useToast,
} from '@chakra-ui/react';
import { TextG12, TextG14 } from '../../../components/typography';
import { Place } from '../../../types/article';
import useAuth from '../../../hooks/useAuth';
import Icon from '../../../components/Icon';
import getPlaceAddress from '../../../utils/address';
import { BlueButton } from '../../../components/button';
import { sendApartmentListingForm } from '../../../endpoints/api';

interface ContactFormProps {
  place: Place;
  onClose?: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ place, onClose }) => {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [smsPreferred, setSmsPreferred] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();
  const theme = useTheme();

  useEffect(() => {
    if (user) {
      setName((user.name || '') + (user.surname ? ` ${ user.surname }` : '' ));
      setEmail(user.email || '');
      setPhone(user.phone || '');
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    
    try {
      await sendApartmentListingForm({
        place_id: place.id,
        name,
        email,
        phone,
        prefers_sms: smsPreferred
      });
      
      toast({
        title: "Application request sent!",
        description: "The property manager will contact you shortly.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      
      if (onClose) onClose();
    } catch (err) {
      console.error('Failed to submit application:', err);
      setError('Failed to submit your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <VStack spacing={0} align="stretch">
      <Box position="relative" height="150px" overflow="hidden">
        <Image
          borderTopRadius={theme.radii.md}
          src={place.default_media?.full_url || place.image_url}
          alt={place.name}
          objectFit="cover"
          width="100%"
          height="100%"
        />
        <Box position="absolute" bottom="0" left="0" right="0" bg="rgba(0,0,0,0.6)" p={3}>
          <TextG14 color="white" fontWeight="bold">
            {place.name}
          </TextG14>
        </Box>
      </Box>

      <Box bg={theme.colors.darkerWhite} p={4}>
        <HStack spacing={4} mb={2}>
          <HStack>
            <ChakraIcon as={() => <Icon iconName="fi-rr-map-marker" />} color={theme.colors.blue} />
            <TextG12>{getPlaceAddress(place)}</TextG12>
          </HStack>
        </HStack>
        <TextG12 color={theme.colors.grey}>
          Complete the form below to request an application for this apartment.
        </TextG12>
      </Box>

      <Divider />

      <VStack as="form" onSubmit={handleSubmit} spacing={4} align="stretch" p={4}>
        <Heading mt="0 !important" size="md" color={theme.colors.blue} mb={1}>
          Request For Application
        </Heading>
        <TextG12 color={theme.colors.grey} mb={3}>
          Your information will be sent to the property manager who will contact you shortly.
        </TextG12>

        {error && (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            {error}
          </Alert>
        )}

        <FormControl id="name" isRequired>
          <FormLabel fontSize="sm">Name</FormLabel>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your name"
            borderColor={theme.colors.lightGrey}
            _focus={{borderColor: theme.colors.blue}}
            isDisabled={isSubmitting}
          />
        </FormControl>

        <FormControl id="email" isRequired>
          <FormLabel fontSize="sm">Email</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
            type="email"
            borderColor={theme.colors.lightGrey}
            _focus={{borderColor: theme.colors.blue}}
            isDisabled={isSubmitting}
          />
        </FormControl>

        <FormControl id="phone" isRequired>
          <FormLabel fontSize="sm">Phone Number</FormLabel>
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Your phone number"
            type="tel"
            borderColor={theme.colors.lightGrey}
            _focus={{borderColor: theme.colors.blue}}
            isDisabled={isSubmitting}
          />
        </FormControl>

        <FormControl id="smsPreferred">
          <Checkbox
            isChecked={smsPreferred}
            onChange={(e) => setSmsPreferred(e.target.checked)}
            colorScheme="green"
            mt={2}
            isDisabled={isSubmitting}>
            <TextG12>
              Request the landlord to communicate with you via text (SMS). If unchecked you will
              receive a phone call or e-mail.
            </TextG12>
          </Checkbox>
        </FormControl>

        <BlueButton
          mt={6}
          type="submit"
          width="100%"
          height="48px"
          isLoading={isSubmitting}
          disabled={isSubmitting}>
          <TextG14 fontWeight="500" color="white">
            Request For Application
          </TextG14>
        </BlueButton>
      </VStack>
    </VStack>
  );
};

export default ContactForm;
