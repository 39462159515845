import {Box, useTheme, VStack} from '@chakra-ui/react';
import React, {Dispatch, SetStateAction} from 'react';
import {useSelector} from 'react-redux';
import {TextG10, TextG12, TextG14} from '../../../components/typography';

type Option = {
  id: string;
  name: string | JSX.Element;
  description: string | JSX.Element;
  availability?: string | JSX.Element;
  appliedElement?: JSX.Element | Function;
  highlightElement?: JSX.Element | Function;
  selectedElement?: JSX.Element;
  discountAmount?: number;
  background?: string;
  availabilityColor?: string;
};

const PaymentOption = ({
  ticket,
  pass,
  option,
  index,
  highlightedOptionId,
  setHighlightedOptionId,
  // appliedIds,
  disabled,
}: {
  ticket: any;
  pass: any;
  option: Option;
  index: number;
  highlightedOptionId?: string;
  setHighlightedOptionId: Dispatch<SetStateAction<string | undefined>>;
  // appliedIds?: Array<string>;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  if (!option) return null;

  const {appliedIds} = useSelector((state: any) => state.checkout);

  const isHighlighted = !disabled && highlightedOptionId == option.id;
  const isApplied = (appliedIds as Array<any>)?.includes(option.id);

  if (disabled && !isApplied) {
    return null;
  }

  return (
    <VStack
      transition="all ease 0.3s"
      onClick={() => setHighlightedOptionId(option.id)}
      pos="relative"
      align="flex-start"
      px={4}
      pr={12}
      py={3}
      borderRadius="8px"
      color={disabled && !isApplied ? 'grey !important' : theme.colors.darkGrey}
      background={
        isApplied
          ? option.id == 'card_payment'
            ? '#FEF4E6'
            : '#E0F3E3'
          : option.background || 'white'
      }
      w="100%"
      outline={
        isHighlighted ? `1px solid ${theme.colors.blue}` : `1px solid ${theme.colors.lighterGrey}`
      }>
      {!isApplied && (
        <>
          <TextG14 fontWeight="700">{option.name}</TextG14>
          <TextG12>{option.description}</TextG12>
          {option.availability ? (
            <TextG10 color={option.availabilityColor || theme.colors.grey}>
              {option.availability}
            </TextG10>
          ) : (
            <Box></Box>
          )}
        </>
      )}
      {isHighlighted &&
        !isApplied &&
        (typeof option.highlightElement === 'function' && option.discountAmount
          ? option.highlightElement(option.discountAmount, ticket ? ticket.price : pass.amount)
          : option.highlightElement)}
      {isApplied &&
        (typeof option.appliedElement === 'function'
          ? option.appliedElement(option.discountAmount, ticket ? ticket.price : pass.amount)
          : option.appliedElement)}
    </VStack>
  );
};

export default PaymentOption;
