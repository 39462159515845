import React from 'react';
import Select from 'react-select';

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '15px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: '10px',
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#276ACD',
    fontWeight: 700,
  }),
  option: (provided, state) => ({
    ...provided,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '15px',
    backgroundColor: 'white',
    fontWeight: 700,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    color: state.isSelected ? '#276ACD' : '#757D84',
  }),
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'white',
      fontWeight: 700,
      color: '#276ACD',
      display: 'flex',
      alignItems: 'center',
    };
  },
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#276ACD',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  }),
  container: (provided, state) => ({
    ...provided,
    margin: '0 0 15px 0',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99,
  }),
};

export const CONTENT_TYPE_OPTIONS = [
  { value: 'default', label: 'Places & Events'},
  { value: 'places', label: 'Places'},
  { value: 'events', label: 'Events'},
]
const SelectContentType = (props) => {

  return (
    <Select
      placeholder={props.placeholder ? props.placeholder : 'Filter by...'}
      value={props.selected}
      defaultValue={props.selected}
      styles={customStyles}
      isSearchable={false}
      onChange={props.changeHandler}
      options={CONTENT_TYPE_OPTIONS}
      components={{
        IndicatorSeparator: () => null,
      }}
      hideSelectedOptions={false}
    />)
}

export default SelectContentType;