import React from 'react';
import {Input, InputProps} from '@chakra-ui/react';

interface CustomPinInputProp extends InputProps {
  onChange: (e: any) => void;
}

const CustomPinInput = ({onChange, ...rest}: CustomPinInputProp) => {
  return (
    <Input
      {...rest}
      onChange={
        rest.maxLength
          ? (e) => {
              if (rest.maxLength && e.target.value.length > rest.maxLength) return;
              onChange(e);
            }
          : onChange
      }
    />
  );
};

export default CustomPinInput;
