import {Place, Event} from '../types/acrticle';
import {Location} from '../hooks/useUserLocation';

const getPlaceAddress = (place: Place, streetOnly = false) => {
  if (place.street_address) {
    if (streetOnly) return place.street_address;
    return [place.street_address, place.city, place.state, place.zipcode]
      .filter(Boolean)
      .join(', ');
  } else return place.location;
};

// Use apple maps for apple device and google maps for android.
// We could probably just use the maps.apple link, as I think that redirect to google maps on other devices
export const getDirectionsLink = (location: Location) => {
  if (/iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
    return `https://maps.apple.com/?q=${location.latitude},${location.longitude}`;
  }

  return `https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`;
};

export const getDirectionsLinkFromPlace = (place: Place) => {
  if (place.latitude && place.longitude)
    return getDirectionsLink({latitude: place.latitude, longitude: place.longitude});
};

export const getDirectionsLinkFromEvent = (event: Event) => {
  if (event.latitude && event.longitude) {
    return getDirectionsLink({latitude: Number(event.latitude), longitude: Number(event.longitude) });
  }

  if(event.place) {
    return getDirectionsLinkFromPlace(event.place);
  }

  return undefined;
}

export default getPlaceAddress;
