import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {fetchPosts} from '../endpoints/api';

interface FetchPostsOptions {
  page?: string;
  communityId?: string;
}

export const useFetchPosts = ({page = '1', communityId}: FetchPostsOptions) => {
  return useQuery(['posts', {page, communityId}], () =>
    fetchPosts({page, communityId, cancelReq: null})
  );
};

export const useInfinitePosts = ({communityId}: {communityId?: string}) => {
  return useInfiniteQuery(
    ['infinite-posts', {communityId}],
    ({pageParam = 1}) => fetchPosts({page: pageParam, communityId, cancelReq: null}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export const usePosts = ({page, communityId}: FetchPostsOptions) => {
  const fetchPosts = useFetchPosts({page, communityId});

  return {posts: fetchPosts.data?.data.data, postsIsLoading: fetchPosts.isLoading};
};
