import React, {useEffect} from 'react';
import {Container} from '@chakra-ui/react';
import Articles from '../../components/Articles';
import {logToNative, pushPermissionState, sendPushToken} from '../../pushService';
import {useDispatch} from 'react-redux';
import {NotificationReminderPopupActions} from '../../state/NotificationReminderPopup/NotificationReminderPopupSlice';
import useAuth from '../../hooks/useAuth';

function Home() {
  const dispatch = useDispatch();
  const {user} = useAuth();

  const pushPermissionStateCallback = () => {
    if (user && user.pushEnabledUpdatedAt) {
      const currentDate = new Date();
      const pushEnabledUpdatedAt = new Date(user.pushEnabledUpdatedAt);

      const differenceInMs = currentDate.getTime() - pushEnabledUpdatedAt.getTime();
      const differenceInDays = differenceInMs / (1000 * 3600 * 24);

      // if (differenceInDays > 14) {
      //   dispatch(NotificationReminderPopupActions.setShow({show: true}));
      // }
    }
  };

  useEffect(() => {
    sendPushToken();
    pushPermissionState({
      callback: () => {
        pushPermissionStateCallback();
      },
    });
  }, []);
  return (
    <Container pt={'130px'} h={'auto'} pb={10}>
      <Articles
        type={'home'}
        //title={`Welcome, ${user?.name}!`}
        //subtitle={'Here’s the latest recommended posts, events, and places based on your interests.'}
      >

				
			</Articles>
    </Container>
  );
}

export default Home;
