import {
  Container,
  Flex,
  Center,
  theme,
  Spacer,
  Divider,
  useTheme,
  VStack,
  Box,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {Dictionary, filter, find, groupBy, map, orderBy, remove} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Icon from '../../components/Icon';
import {H3, TextG10, TextG12, TextG14} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import {useFetchPurchasablePasses} from '../../hooks/usePassPurchase';
import {useFetchActivePasses} from '../../hooks/useUserPasses';
import PassCard from './PassCard';

const MyPasses = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {data} = useFetchPurchasablePasses();
  const fetchPurchasablePasses = data?.data;
  const fetchActivePasses = useFetchActivePasses();
  const [groupedPasses, setGroupedPasses] = useState<Dictionary<any[]>>();
  const [purchasablePasses, setPurchasablePasses] = useState<any>();
  const {user} = useAuth();

  let renewalDate;
  const primaryMembership = user?.activeMemberships?.find((membership) => membership.is_primary);

  if (primaryMembership) {
    renewalDate = dayjs(primaryMembership.ends_at).format('MM/DD/YY');
  }

  useEffect(() => {
    if (fetchPurchasablePasses) {
      setPurchasablePasses([...fetchPurchasablePasses]);
    }
  }, [fetchPurchasablePasses]);

  useEffect(() => {
    if (fetchPurchasablePasses) {
      setPurchasablePasses([...fetchPurchasablePasses]);
    }
  }, [fetchPurchasablePasses]);

  useEffect(() => {
    if (fetchActivePasses.data?.data) {
      const grouped = groupBy(fetchActivePasses.data.data, 'pass_id');
      setGroupedPasses(grouped);
    }
  }, [fetchActivePasses.data]);

  return (
    <Container pt={1} px={'0 !important'} pb={'initial'} h="auto">
      <Flex my={3} flexDir="column">
        <Flex>
          <H3 fontWeight="700" mr={1} color={theme.colors.rose}>{`Membership `}</H3>
          <H3 fontWeight="400" color={theme.colors.darkGrey}>
            Passes
          </H3>
        </Flex>
        <Spacer />
        <TextG10 color={theme.colors.darkGrey}>
          Passes refill at your monthly renewal & valid for a month
        </TextG10>
        {renewalDate && (
          <TextG10 color={theme.colors.darkGrey}>
            You'll Get Your Next Passes On: <b style={{marginLeft: 2}}>{renewalDate}</b>
          </TextG10>
        )}
      </Flex>
      <Divider mb={3} />
      {purchasablePasses && (
        <Flex gap="20px" flexDir="column">
          {map(groupedPasses, (passArray) => {
            const passAmount = passArray.length;
            const passData = orderBy(passArray, ['expires_at'], ['asc'])[0];
            if (!passData || !passData.pass) return null;
            let purchasable = false;
            const removed = filter(purchasablePasses, (item: any) => item.id == passData.pass.id);
            if (removed.length > 0) {
              purchasable = true;
            }
            return (
              <PassCard
                nextExpire
                key={passData.id}
                expires_at={passData.expires_at}
                pass={passData.pass}
                showGetMore={purchasable && user?.hasActiveMembership}
                numberOfPasses={passAmount}
                arrowCTA={
                  <TextG10 color={theme.colors.blue} fontWeight="500">
                    See my Passes
                  </TextG10>
                }
                showNumberOfPasses
                onClick={() => {
                  navigate(`/pass/${passData.pass.id}`);
                }}
              />
            );
          })}
          {user?.hasActiveMembership &&
            purchasablePasses.map((pass) => {
              if (groupedPasses && groupedPasses[pass.id]) {
                return null;
              }
              return <PassCard key={pass.id} pass={pass} showNumberOfPasses />;
            })}
        </Flex>
      )}
      {!user?.hasActiveMembership && (
        <Box
          mt={3}
          onClick={() => navigate('/memberships')}
          flexDir="column"
          py={4}
          px={6}
          w="100%"
          boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.14)"
          border="1px solid #FFC0AE"
          borderRadius="12px">
          <Center>
            <Flex color={theme.colors.rose} align="center">
              <TextG14 fontWeight="700">Upgrade your membership to get passes!</TextG14>
              <Icon
                iconName="fi fi-rr-arrow-right"
                style={{
                  fontSize: '14px',
                  marginTop: '2px',
                }}
              />
            </Flex>
          </Center>
        </Box>
      )}
    </Container>
  );
};

export default MyPasses;
