import {Image} from '@chakra-ui/react';
import React from 'react';
import GettingStartedBadge from '../../assets/images/getting-started-badge.png';

const JourneyBadge = ({w = '32px', h = '32px'}) => (
  <Image src={GettingStartedBadge} w={w} h={h} />
  //   <svg width={w} height={h} viewBox={`0 0 32 36`} fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <defs>
  //     <filter id="dropShadow" x="-50%" y="-50%" width="200%" height="200%">
  //       <feDropShadow dx="0" dy="1.33" stdDeviation="1.33" floodColor="#00000040" />{' '}
  //       {/* Adjust shadow parameters */}
  //     </filter>
  //   </defs>
  //   <path
  //     d="M13.7768 0.790084C15.0717 -0.263361 16.9283 -0.263361 18.2232 0.790083L18.5199 1.03139C19.2987 1.66493 20.3108 1.93613 21.302 1.77686L21.6796 1.7162C23.3278 1.45138 24.9356 2.37965 25.5303 3.93945L25.6666 4.29675C26.0243 5.23481 26.7652 5.97572 27.7032 6.33341L28.0605 6.46965C29.6203 7.06442 30.5486 8.67222 30.2838 10.3204L30.2231 10.698C30.0639 11.6892 30.3351 12.7013 30.9686 13.4801L31.2099 13.7768C32.2634 15.0717 32.2634 16.9283 31.2099 18.2232L30.9686 18.5199C30.3351 19.2987 30.0639 20.3108 30.2231 21.302L30.2838 21.6796C30.5486 23.3278 29.6203 24.9356 28.0605 25.5303L27.7033 25.6666C26.7652 26.0243 26.0243 26.7652 25.6666 27.7033L25.5303 28.0605C24.9356 29.6203 23.3278 30.5486 21.6796 30.2838L21.302 30.2231C20.3108 30.0639 19.2987 30.3351 18.5199 30.9686L18.2232 31.2099C16.9283 32.2634 15.0717 32.2634 13.7768 31.2099L13.4801 30.9686C12.7013 30.3351 11.6892 30.0639 10.698 30.2231L10.3204 30.2838C8.67222 30.5486 7.06442 29.6203 6.46965 28.0605L6.33341 27.7032C5.97572 26.7652 5.23481 26.0243 4.29675 25.6666L3.93945 25.5303C2.37965 24.9356 1.45138 23.3278 1.7162 21.6796L1.77686 21.302C1.93613 20.3108 1.66493 19.2987 1.03139 18.5199L0.790084 18.2232C-0.263361 16.9283 -0.263361 15.0717 0.790083 13.7768L1.03139 13.4801C1.66493 12.7013 1.93613 11.6892 1.77686 10.698L1.7162 10.3204C1.45138 8.67222 2.37965 7.06442 3.93945 6.46965L4.29675 6.33341C5.23481 5.97572 5.97572 5.23481 6.33341 4.29675L6.46965 3.93945C7.06442 2.37965 8.67222 1.45138 10.3204 1.7162L10.698 1.77686C11.6892 1.93613 12.7013 1.66494 13.4801 1.03139L13.7768 0.790084Z"
  //     fill="#E5D3FD"
  //     filter="url(#dropShadow)"
  //   />
  //   <g filter="url(#filter0_i_15775_2342)">
  //     <path
  //       d="M14.0988 4.1487C15.2059 3.24803 16.7932 3.24803 17.9004 4.1487C18.5663 4.69036 19.4316 4.92222 20.2791 4.78606C21.6882 4.55964 23.0629 5.35329 23.5714 6.68687C23.8772 7.48889 24.5106 8.12235 25.3127 8.42817C26.6462 8.93668 27.4399 10.3113 27.2135 11.7205C27.0773 12.568 27.3092 13.4333 27.8508 14.0991C28.7515 15.2063 28.7515 16.7936 27.8508 17.9008C27.3092 18.5666 27.0773 19.4319 27.2135 20.2794C27.4399 21.6886 26.6462 23.0632 25.3127 23.5717C24.5106 23.8775 23.8772 24.511 23.5714 25.313C23.0629 26.6466 21.6882 27.4403 20.2791 27.2138C19.4316 27.0777 18.5663 27.3095 17.9004 27.8512C16.7932 28.7519 15.2059 28.7519 14.0988 27.8512C13.4329 27.3095 12.5676 27.0777 11.7201 27.2138C10.3109 27.4403 8.93631 26.6466 8.4278 25.313C8.12199 24.511 7.48853 23.8775 6.68651 23.5717C5.35292 23.0632 4.55928 21.6886 4.78569 20.2794C4.92186 19.4319 4.69 18.5666 4.14833 17.9008C3.24767 16.7936 3.24766 15.2063 4.14833 14.0991C4.69 13.4333 4.92186 12.568 4.78569 11.7205C4.55928 10.3113 5.35292 8.93668 6.68651 8.42817C7.48853 8.12235 8.12199 7.48889 8.4278 6.68687C8.93631 5.35329 10.3109 4.55964 11.7201 4.78606C12.5676 4.92222 13.4329 4.69036 14.0988 4.1487Z"
  //       fill="#7D3EEE"
  //     />
  //   </g>
  //   <path
  //     d="M8.11069 14.6221C7.53502 13.6251 7.87665 12.3501 8.87372 11.7744C9.8755 11.1961 11.1566 11.5439 11.7283 12.5495L13.8548 16.29C14.036 16.6088 14.3936 16.7845 14.7567 16.7331C15.5024 16.6277 16.0362 17.4353 15.6468 18.08L14.5106 19.9611C13.7483 21.2233 11.9114 21.2052 11.1741 19.9281L8.11069 14.6221Z"
  //     fill="white"
  //     filter="url(#dropShadow)"
  //   />
  //   <path
  //     d="M14.6458 14.6033C14.0702 13.6063 14.4118 12.3313 15.4089 11.7556C16.4107 11.1773 17.6918 11.5251 18.2635 12.5307L20.3899 16.2712C20.5712 16.59 20.9288 16.7657 21.2919 16.7143C22.0376 16.6089 22.5713 17.4165 22.182 18.0612L21.0458 19.9423C20.2834 21.2045 18.4466 21.1864 17.7093 19.9093L14.6458 14.6033Z"
  //     fill="white"
  //     filter="url(#dropShadow)"
  //   />
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M20.8993 14.6356C20.4691 13.9228 20.4956 13.0088 20.9279 12.3154L21.0485 12.1653C21.6631 11.3999 22.7102 11.233 23.5298 11.7696C24.4986 12.404 24.7894 13.7729 24.1657 14.7634L23.9881 15.0454C23.3223 16.1028 21.7759 16.0882 21.1303 15.0183L20.8993 14.6356ZM22.5488 14.6807C23.2208 14.6807 23.7656 14.1359 23.7656 13.4639C23.7656 12.7919 23.2208 12.2471 22.5488 12.2471C21.8768 12.2471 21.332 12.7919 21.332 13.4639C21.332 14.1359 21.8768 14.6807 22.5488 14.6807Z"
  //     fill="white"
  //     filter="url(#dropShadow)"
  //   />
  // </svg>
);

export default JourneyBadge;
