import {round} from 'lodash';
import {Place} from '../types/acrticle';
import {DISTANCE} from '../constants/placeDistance';
import {Location} from '../hooks/useUserLocation';

export const convertFootToMiles = (distance: number) => round(distance / 5280, 2);
export const convertMilesToFeet = (miles) => {
  return round(miles * 5280.0, 2);
};

const getDistanceToPlace = (place: Place) => {
  if (place.distance) {
    return getDistanceString(place.distance);
  }
};

export const getDistanceString = (distance: number) => {
  if (distance >= DISTANCE.MILE) return `${convertFootToMiles(distance)}mi`;

  return `${Math.trunc(distance)}ft`;
};

export const calculateAndGetDistance = (location1: Location, location2: Location) => {
  const lat1 = location1.latitude;
  const lat2 = location2.latitude;

  const lon1 = location1.longitude;
  const lon2 = location2.longitude;

  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return convertMilesToFeet(dist);
  }
};

export default getDistanceToPlace;
