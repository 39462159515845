import {Modal, ModalOverlay} from '@chakra-ui/react';
import React from 'react';
import {RemoveScroll} from 'react-remove-scroll';
import Tooltip from '../TutorialTooltips/Tooltip';

const DockbarButtonTooltip = ({isOpen}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay background="rgba(0,0,0, 0.7)" />
      <RemoveScroll>
        <Tooltip
          offset={50}
          place="top"
          showDots={false}
          isOpen={isOpen}
          anchorId="dockbar-tooltip"
          highlightId="dockbar-tooltip-highlight"
          title="Walkabout Button"
          message="Use the ‘W’ button to see local spots nearby that you can support and check-in at to earn points."
          hideButtons={true}
          showClose={false}
        />
      </RemoveScroll>
    </Modal>
  );
};

export default DockbarButtonTooltip;
