import {Area} from 'react-easy-crop/types';

export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image: HTMLImageElement = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getImage(
  imageSrc: string,
  pixelCrop: Area | null,
  mime = 'image/jpeg',
  quality = 0.9
) {
  if (!imageSrc) {
    return;
  }
  const image = await createImage(imageSrc);
  const blob = await new Promise<any>((resolve) => {
    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = image.width;
    tmpCanvas.height = image.height;
    
    const ctx = tmpCanvas.getContext('2d');

    if (!ctx) return;

    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop?.x || 0, pixelCrop?.y || 0, pixelCrop?.width || image.width, pixelCrop?.height || image.height);

    // if (!data) return

    // set canvas width to final desired crop size - this will clear existing context
    tmpCanvas.width = pixelCrop?.width || image.width;
    tmpCanvas.height = pixelCrop?.height || image.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    tmpCanvas.toBlob(resolve, mime, quality);
  });

  const formData = new FormData();

  const file = new File([blob], 'photo.jpeg', {
    type: 'image/jpeg',
  });
  formData.append('file', file, 'photo.jpeg');

  return formData;
}

export function ImageFileToURL(file: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
