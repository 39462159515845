import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import React, {useEffect, useState, useRef} from 'react';
import Icon from '../../components/Icon';
import Modal from '../../components/Modals/Modal';
import {TextA18, TextG10, TextG12, TextG16} from '../../components/typography';
import {useInfiniteGlobalSearchWithParams} from '../../hooks/useFetchGlobalSearch';
import useGetUserLocation from '../../hooks/useUserLocation';
import {Place} from '../../types/article';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';

type ModalParams = {
  selectedPlace?: Place;
  setSelectedPlace: (place: Place) => void;
  placeHolderText?: boolean;
  showButton?: boolean;
  showModal?: boolean;
  setShowModal?: (...params) => void;
};

const PlaceSelectModal = ({
  selectedPlace,
  setSelectedPlace,
  placeHolderText = true,
  showButton = true,
  showModal = false,
  setShowModal,
}: ModalParams) => {
  const [show, setShow] = useState(false);
  const [term, setTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const userLocation = useGetUserLocation();
  const theme = useTheme();
  
  // Handle debouncing for search term
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedTerm(term);
    }, 500);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [term]);

  const {data, isLoading} = useInfiniteGlobalSearchWithParams({
    term: debouncedTerm.length >= 3 ? debouncedTerm : '',
    type: 'place',
    lat: userLocation.data?.latitude,
    long: userLocation.data?.longitude,
    sort: {
      by: 'distance',
      direction: 'asc',
    },
  });

  const searchRes = data?.pages[0]?.data?.data || [];

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <>
      {showButton && (
        <Button
          onClick={() => {
            setShow(true);
            if (setShowModal) {
              setShowModal(true);
            }
          }}
          height="auto"
          alignItems="flex-end"
          w="100%"
          px="16px"
          py="12px"
          justifyContent="left"
          background="transparent"
          border="1px solid #E5E5E5"
          borderRadius="30px">
          <Flex gap="5px" align="center">
            {selectedPlace && (
              <Image
                objectFit="cover"
                minW="30px"
                minH="30px"
                maxH="30px"
                maxW="30px"
                borderRadius="full"
                src={selectedPlace?.default_media?.full_url}
              />
            )}
            <TextG12
              fontWeight="500"
              color={selectedPlace ? theme.colors.lightBlack : theme.colors.lightestGrey}>
              {selectedPlace?.name || 'Choose a place'}
            </TextG12>
          </Flex>
        </Button>
      )}
      <Modal
        closeButton
        parentCallback={() => {
          setShow(false);
          if (setShowModal) {
            setShowModal(false);
          }
        }}
        header={
          <TextA18 fontWeight="700" mt={2}>
            {placeHolderText ? 'Where are you meeting up?' : ''}
          </TextA18>
        }
        children={
          <VStack w="100%">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon iconName="fi-rr-search" color="gray.300" />
              </InputLeftElement>

              <Input
                borderRadius="40px"
                mb={5}
                onChange={(value) => setTerm(value.target.value)}
                type="search"
                placeholder={placeHolderText ? 'Search for a Meet-up spot' : ''}></Input>
            </InputGroup>
            {isLoading ? (
              <Center h="400px">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor={theme.colors.lightGrey}
                  color={theme.colors.lightBlue}
                  size="lg"
                />
              </Center>
            ) : (
              <VStack w="100%" maxHeight="400px" overflowY="scroll">
                {searchRes.map((place, i) => {
                  const isSelected = selectedPlace?.id == place.id;
                  return (
                    <Box key={i} w="100%">
                      <HStack
                        boxShadow={isSelected ? 'md' : 'none'}
                        transition="all 0.5s"
                        py={2}
                        borderRadius="12px"
                        border={
                          isSelected ? `1px solid ${theme.colors.blue}` : '1px solid transparent'
                        }
                        w="100%"
                        onClick={() => {
                          setShow(false);
                          if (setShowModal) {
                            setShowModal(false);
                          }
                          setSelectedPlace(place);
                        }}>
                        {place.default_media?.full_url && (
                          <Image
                            objectFit="cover"
                            minW="75px"
                            minH="75px"
                            maxH="75px"
                            maxW="75px"
                            borderRadius="full"
                            src={place.default_media.full_url}
                          />
                        )}
                        <VStack w="100%" alignItems={'flex-start'}>
                          <TextG16
                            transition="all 0.5s"
                            //textAlign="center"
                            isTruncated="&hellip;"
                            noOfLines={2}
                            fontWeight="500"
                            color={isSelected ? theme.colors.blue : theme.colors.lightBlack}>
                            {place.name}
                          </TextG16>
                          {userLocation.data?.latitude &&
                            userLocation.data.longitude &&
                            place?.latitude &&
                            place?.longitude && (
                              <TextG10 color={theme.colors.lightBlack}>
                                {`${getDistanceString(
                                  calculateAndGetDistance(
                                    {
                                      latitude: Number(userLocation.data?.latitude),
                                      longitude: Number(userLocation.data?.longitude),
                                    },
                                    {
                                      latitude: Number(place.latitude),
                                      longitude: Number(place.longitude),
                                    }
                                  )
                                )} away`}
                              </TextG10>
                            )}
                          <TextG10 color={theme.colors.grey}>{place.street_address}</TextG10>
                        </VStack>
                      </HStack>
                      <Divider />
                    </Box>
                  );
                })}
              </VStack>
            )}
          </VStack>
        }
        show={show}
      />
    </>
  );
};

export default PlaceSelectModal;
