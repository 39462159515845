import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Center,
  Flex,
  HStack,
  useTheme,
  VStack,
  Container,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Input as ChakraInput,
  Button,
} from "@chakra-ui/react";
import styled, { css } from "styled-components";
import { isEqual, pick } from "lodash";
import snakeCaseObj from "../../../utils/snakeCaseObj";
import BackButton from "../../../components/BackButton";
import ProfilePicModal from "./ProfilePhotoModal";
import UserAvatar from "../../../components/UserAvatar";
import useAuth from "../../../hooks/useAuth";
import Icon from "../../../components/Icon";
import {
  deleteUser,
  fetchUserHandleAvailable,
  requestEmailVerificationCode,
  updateUser,
  validateEmailCode,
} from "../../../endpoints/api";
import { ToastMessage } from "../../../components/toast";
import {
  H3,
  TextG10,
  TextG12,
  TextG14,
  TextG16,
} from "../../../components/typography";
import { Checkbox } from "../../../components/checkbox";
import InputPhoneNumber, {
  AvailableFunctions,
} from "../../../components/InputPhoneNumber";
import CustomPinInput from "../../../components/CustomPinInput";
import { VERIFICATION_CODE_LENGTH } from "../../Auth/Register/RegisterForm";
import { SpinnerLoader } from "../../../components/Loader";
import successImg from "../../../assets/images/success.png";
import { BlueButton, BlueButtonFixedWidth } from "../../../components/button";
import EmailVerificationModalSheet, {
  BottomModalSheetRefProps,
} from "../../../components/Modals/BottomModalSheet";
import usePrompt from "../../../components/prompt";
import { AddressTypes } from "../../../types/address";
import EditAddress from "./EditAddress";
import PromptModal from "../../../components/Modals/PromptModal";
import Input from "../../../components/Input";
import * as pushService from "../../../pushService";
import { isIphone, deviceDetected } from "../../../utils/deviceCheck";

interface FormData {
  name?: string;
  surname?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  email?: string;
  handle?: string;
  mailingAddress?: string;
  smsEnabled: boolean;
  emailEnabled: boolean;
  pushEnabled: boolean;
	isMobileDebugEnabled?: boolean;
	isMobileDebugAvailable?: boolean;
	createdAt?:string;
}

const EditIconContainer = styled(Box)`
  background: white;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 5px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  ${(props) =>
    props.theme &&
    props.theme.colors &&
    css`
      border: 1px solid ${props.theme.colors.grey};
    `};
`;

const EditOverlay = styled(Box)`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
`;

export const StyledInput = styled(Input)`
  font-family: "Gordita";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 15px;
  letter-spacing: -0.01em;
  height: 32px !important;
`;

const StyledPhoneInput = styled(InputPhoneNumber)`
  .PhoneInputInput {
    ${(props) =>
      props.theme &&
      props.theme.colors &&
      css`
        background: ${props.theme.colors.veryLightBlue};
      `};
  }
`;

function EditProfile({ onClose }: { onClose?: () => void }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, reFetchUserData } = useAuth();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [error, setError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [smsPromptModalOpen, setSmsPromptModalOpen] = useState(false);
  const [emailPromptModalOpen, setEmailPromptModalOpen] = useState(false);
  const [pushPromptModalOpen, setPushPromptModalOpen] = useState(false);
  const [deleteAccountPromptOpen, setDeleteAccountPromptOpen] = useState(false);
  const [userNameError, setUserNameError] = useState("");

  const userData: FormData = {
    ...pick(user, [
      "name",
      "surname",
      "address",
      "address2",
      "city",
      "state",
      "zip",
      "handle",
      "email",
      "phone",
      "mailingAddress",
    ]),
    smsEnabled: !!user?.smsEnabled,
    emailEnabled: !!user?.emailEnabled,
    pushEnabled: !!user?.pushEnabled,
		isMobileDebugEnabled: !!user?.isMobileDebugEnabled,
		isMobileDebugAvailable: !!user?.isMobileDebugAvailable,
		createdAt: user?.createdAt
  };

  const stateSelectStyle = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      maxHeight: "32px",
      marginTop: "0px !important",
      borderBottom: "1px solid rgb(226, 232, 240)",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: "100px",
      backgroundColor: "transparent",
      borderWidth: "0px",
    }),
    indicatorSeparator: () => ({
      width: "0px",
    }),
    loadingIndicator: (baseStyles, state) => ({
      ...baseStyles,
      display: "none",
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      marginBottom: "0px",
    }),
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      maxHeight: "155px",
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "14px",
      fontFamily: "Gordita",
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      paddingLeft: "0px",
    }),
  };

  const [data, setData] = useState<FormData>(userData);
  const [smsNotificationsEnabled, setSmsNotificationsEnabled] =
    useState<boolean>(data.smsEnabled);
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] =
    useState<boolean>(data.emailEnabled);
  const [pushNotificationsEnabled, setPushNotificationsEnabled] =
    useState<boolean>(data.pushEnabled);
		const [isMobileDebugEnabled, setIsMobileDebugEnabled] = useState<boolean | undefined>(data.isMobileDebugEnabled);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const profilePhotoUrl = user?.profilePhotoUrl;
  const inputElement = useRef<HTMLInputElement | null>(null);
  const [uploadedImage, setUploadedImage] =
    useState<ChangeEvent<HTMLInputElement>>();
  const handleUpload = (upload) => setUploadedImage(upload);

  const [isPhoneVerified, setIsPhoneVerified] = useState(!!user?.phone);

  const phoneNumberInputRef = useRef<AvailableFunctions>(null);

  const emailVerificationModalSheetRef = useRef<BottomModalSheetRefProps>();
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [emailVerifyButtonEnabled, setEmailVerifyButtonEnabled] =
    useState(false);
  const [isEmailVerificationInProgress, setEmailVerificationProgress] =
    useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState<string>(
    userData?.email || ""
  );
  const [emailVerificationError, setEmailVerificationError] = useState("");

  const [smsChanged, setSmsChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [pushChanged, setPushChanged] = useState(false);
  const [loginChanged, setLoginChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const isProduction = process.env.NODE_ENV === "production";
  const { logout } = useAuth();

  const handleDeleteUserAccount = async () => {
    await deleteUser();
    logout();
  };

  usePrompt(
    "Confirm navigation",
    "You have unsaved changes. Are you sure you want to leave this page?",
    !isEqual(data, pick(userData, Object.keys(data))) && !formSubmitted
  );

  const handleSubmitEmailVerification = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const newEmailAddress = data.email;
    if (!newEmailAddress) {
      ToastMessage({
        status: "error",
        text: "Invalid email address",
      });
      return;
    }

    setEmailVerificationProgress(true);

    validateEmailCode(newEmailAddress, emailVerificationCode)
      .then(() => {
        setEmailVerificationProgress(false);
        setVerifiedEmail(newEmailAddress);
        setTimeout(() => {
          setField("email", newEmailAddress);
          setShowEmailVerification(false);
        }, 2000);
      })
      .catch((err) => {
        setEmailVerificationProgress(false);
        setEmailVerificationError(err.message);
      });
  };

  const sendEmailVerifyCodeRequest = ({ resendCode = false }) =>
    requestEmailVerificationCode(data.email)
      .then(() => {
        setShowEmailVerification(true);
        if (resendCode)
          ToastMessage({
            status: "info",
            text: "Code resent",
          });
      })
      .catch(({ response }) => setError(response.data.message));

  const handleSave = () => {
    if (formRef.current && formRef.current?.reportValidity()) {
      // if (!isValidPhoneNumber(data?.phone || '')) {
      //   setError('Phone number should be 10 digits.');
      //   return;
      // }

      // if (!isPhoneVerified) {
      //   setError('Phone number not verified.');
      //   return;
      // }

      if (verifiedEmail !== data.email) {
        setError("Email not verified.");
        return;
      }
      setError("");
      setIsSaving(true);

      setFormSubmitted(true);
      updateUser(snakeCaseObj(data, ["address2"]))
        .then(() => {
          reFetchUserData();
          setIsSaving(false);
          setLoginChanged(false);
          setSmsChanged(false);
          setEmailChanged(false);
          setPushChanged(false);
          //navigate('/profile');
        })
        .catch((e) => {
          setSmsChanged(false);
          setEmailChanged(false);
          setPushChanged(false);
          setIsSaving(false);
          setLoginChanged(false);
          setFormSubmitted(false);
          ToastMessage({
            status: "error",
            text: e.message,
            duration: 5000,
          });
        });
    }
  };

  const setField = (field: string, value: string | boolean) => {
    setData({
      ...data,
      [field]: typeof value === "boolean" ? value : value ? value : "",
    });
  };

  const saveButton = useMemo(
    () => (
      <BlueButton w="100%" borderRadius="7px" onClick={handleSave}>
        {isSaving ? <SpinnerLoader /> : "Save Changes"}
      </BlueButton>
    ),
    [handleSave, isSaving]
  );

  const onChangePushNotificationEnabled = () => {
    if (pushNotificationsEnabled) {
      setPushPromptModalOpen(true);
    } else {
      setField("pushEnabled", !pushNotificationsEnabled);
      setPushNotificationsEnabled(
        !pushNotificationsEnabled
      );
      setPushChanged(true);
      if(window['ReactNativeWebView']) {
        pushService.pushPermissionRequest();
      } else {
        if(!isIphone) {
          pushService
          .askNotificationPermission()
          .then((token) =>
            updateUser({
              device_token: token,
              device_platform: deviceDetected(),
            })
          )
        } else {
          pushService.pushPermissionRequest();
        }
      }
    }
  }

	const onChangeEnableMobileDebug = async (value) => {
			setIsMobileDebugEnabled(value);
			await updateUser({is_mobile_debug_enabled: value});
			reFetchUserData();
			if (value === true) {
				alert("Mobile Debugging Enabled")
			} else {
				alert("Mobile Debugging Disabled")
			}
	}

  return (
    <Container>
      <Box pt={6}>
        <Flex alignItems="center">
          <BackButton pos={'relative'} onClick={onClose ? onClose : navigate('/profile')} />
          <Box flex="1">
            <Center ml="-35px">
              <H3 color={theme.colors.darkGrey}>Account Settings</H3>
            </Center>
          </Box>
        </Flex>
      </Box>
      <Box
        my={7}
        position="relative"
        onClick={() => {
          if (!profilePhotoUrl && inputElement && inputElement.current) {
            inputElement.current.click();
          } else setShowPhotoModal(true);
        }}>
        <Center>
          <UserAvatar size="xl" user={user} />
          <EditOverlay>
            <EditIconContainer theme={theme}>
              <Icon style={iconStyle} iconName="fi-rr-camera" />
            </EditIconContainer>
          </EditOverlay>
          <label htmlFor="image-file">
            <ChakraInput
              ref={inputElement}
              type="file"
              id="image-file"
              accept="image/*;capture=camera"
              onChange={handleUpload}
              hidden
            />
            <TextG16 style={{fontWeight: '400'}}>{''}</TextG16>
          </label>
        </Center>
      </Box>
      <form ref={formRef}>
        <Flex pb="20px" flexDir="column" gap={4}>
          <Accordion
            defaultIndex={[0]}
            borderRadius="12px"
            background={theme.colors.veryLightBlue}
            allowMultiple>
            <AccordionItem borderColor="transparent">
              <AccordionButton py={3} _hover={{}} justifyContent="space-between">
                <Flex>
                  <TextG14 color={theme.colors.darkGrey} fontWeight="700">
                    Login & Security
                  </TextG14>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pt="0" my="10px" pb="10px">
                <VStack spacing="20px">
                  <HStack spacing="22px">
                    <StyledInput
                      size="sm"
                      id="first-name"
                      required
                      label="First name"
                      name="first_name"
                      minLength={4}
                      onChange={(e) => {
                        setLoginChanged(true);
                        setField('name', e.target.value);
                      }}
                      value={data?.name}
                    />
                    <StyledInput
                      size="sm"
                      id="last-name"
                      required
                      label="Last name"
                      name="last_name"
                      minLength={4}
                      onChange={(e) => {
                        setLoginChanged(true);
                        setField('surname', e.target.value);
                      }}
                      value={data?.surname}
                    />
                  </HStack>
                  <StyledInput
                    id="handle"
                    required
                    label="Nickname"
                    name="handle"
                    minLength={4}
                    onChange={(e) => {
                      if (e.target.value == user?.handle) {
                        setField('handle', e.target.value);
                        setLoginChanged(false);
                        return;
                      }

                      fetchUserHandleAvailable(e.target.value)
                        .then((res) => {
                          if (res.status == 200) {
                            setLoginChanged(true);
                            setUserNameError('');
                          }
                        })
                        .catch((res) => {
                          setUserNameError(res.message);
                          setLoginChanged(false);
                        });
                      setField('handle', e.target.value);
                    }}
                    value={data?.handle}
                  />
                  {userNameError && (
                    <Center mt="5px !important">
                      <TextG12 color={'red'}>{userNameError}</TextG12>
                    </Center>
                  )}
                  <StyledInput
                    id="email"
                    required
                    type="email"
                    label="Email Address"
                    name="email"
                    onChange={(e) => {
                      setLoginChanged(true);
                      setField('email', e.target.value);
                    }}
                    value={data?.email}
                    {...{
                      ...(verifiedEmail === data.email
                        ? {validationStatusIcon: true}
                        : {
                            RightIcon: (
                              <TextG10 fontWeight={'bold'} color={theme.colors.blue}>
                                Verify
                              </TextG10>
                            ),
                            RightIconOnClick: () => sendEmailVerifyCodeRequest({}),
                          }),
                    }}
                  />
                  <StyledPhoneInput
                    theme={theme}
                    value={data?.phone || ''}
                    ref={phoneNumberInputRef}
                    onSuccess={() => {
                      setIsPhoneVerified(true);
                      setError('');
                    }}
                    onChange={(val) => {
                      setLoginChanged(true);
                      setIsPhoneVerified(user?.phone === val?.toString());
                      setField('phone', val?.toString());
                    }}
                    onError={(error) => setError(error)}
                    {...{
                      ...(isPhoneVerified
                        ? {validationStatusIcon: true}
                        : {
                            RightIcon: (
                              <TextG10 fontWeight={'bold'} color={theme.colors.blue}>
                                Verify
                              </TextG10>
                            ),
                            RightIconOnClick: () =>
                              phoneNumberInputRef.current?.sendVerifyCodeRequest(),
                          }),
                    }}
                  />
                  <StyledInput
                    id="password_last_updated"
                    type="text"
                    label="Password"
                    name="password_last_updated"
                    isDisabled={true}
                    value={`********`}
                    RightIcon={
                      <TextG10 fontWeight={'bold'} color={theme.colors.blue}>
                        Reset Password
                      </TextG10>
                    }
                    RightIconOnClick={() => navigate('/reset-password')}
                    RightIconProps={{width: 'unset'}}
                  />
                  {loginChanged && saveButton}
                </VStack>
                {error && (
                  <Center>
                    <TextG12 color={'red'}>{error}</TextG12>
                  </Center>
                )}
                {!loginChanged && (
                  <>
                    <Flex mt={4} onClick={() => setDeleteAccountPromptOpen(true)}>
                      <TextG10 textDecoration="underline" color={theme.colors.blue}>
                        Delete My Account
                      </TextG10>
                    </Flex>
                    <PromptModal
                      title="Are you sure you want to premanately delete your account?"
                      declineText={'Cancel'}
                      acceptText={'Delete'}
                      declineCallback={() => setDeleteAccountPromptOpen(false)}
                      acceptCallback={(e) => {
                        handleDeleteUserAccount();
                        setDeleteAccountPromptOpen(false);
                      }}
                      isOpen={deleteAccountPromptOpen}
                    />
                  </>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box borderRadius="12px" p={5} background={theme.colors.veryLightBlue}>
            <TextG14 fontWeight="700" color={theme.colors.darkGrey}>
              Notifications
            </TextG14>
            <TextG12 color={theme.colors.grey} pt={2}>
              Occasionally send me community announcements and special offers that highly match my
              interests.
            </TextG12>
            <VStack alignItems={'start'} pt={5} spacing={4}>
              <HStack spacing={4}>
                <Box alignSelf={'start'} lineHeight={'100%'}>
                  <Checkbox
                    isCircle
                    onChange={() => {
                      if (smsNotificationsEnabled) {
                        setSmsPromptModalOpen(true);
                      } else {
                        setField('smsEnabled', !smsNotificationsEnabled);
                        setSmsNotificationsEnabled(!smsNotificationsEnabled);
                        setSmsChanged(true);
                      }
                    }}
                    checked={smsNotificationsEnabled}
                    disabled={false}
                    styled
                  />
                </Box>
                <VStack spacing={1}>
                  <TextG14 alignSelf={'start'} color={theme.colors.darkGrey}>
                    Receive SMS Texts
                  </TextG14>
                </VStack>
              </HStack>
              <HStack spacing={4}>
                <Box alignSelf={'start'} lineHeight={'100%'}>
                  <Checkbox
                    isCircle
                    onChange={() => {
                      if (emailNotificationsEnabled) {
                        setEmailPromptModalOpen(true);
                      } else {
                        setField('emailEnabled', !emailNotificationsEnabled);
                        setEmailNotificationsEnabled(!emailNotificationsEnabled);
                        setEmailChanged(true);
                      }
                    }}
                    checked={emailNotificationsEnabled}
                    disabled={false}
                    styled
                  />
                </Box>
                <VStack spacing={1}>
                  <TextG14 alignSelf={'start'} color={theme.colors.darkGrey}>
                    Receive Email Notifications
                  </TextG14>
                </VStack>
              </HStack>
            </VStack>
            <VStack alignItems={'start'} pt={6} spacing={4}>
              <HStack spacing={4}>
                <Box alignSelf={'start'} lineHeight={'100%'}>
                  <Checkbox
                    isCircle
                    onChange={onChangePushNotificationEnabled}
                    checked={pushNotificationsEnabled}
                    disabled={false}
                    styled
                  />
                </Box>
                <VStack spacing={1}>
                  <TextG14 alignSelf={'start'} color={theme.colors.darkGrey}>
                    Receive Push Notifications
                  </TextG14>
                </VStack>
              </HStack>
							{(pushChanged || emailChanged || smsChanged) && saveButton}
            </VStack>
					
          </Box>
					{user?.isMobileDebugAvailable && (
					<Box borderRadius="12px" p={5} background={theme.colors.veryLightBlue}>
            <TextG14 fontWeight="700" color={theme.colors.darkGrey}>
              Debug Settings
            </TextG14>
          	<VStack alignItems={'start'} pt={6} spacing={4}>
              <HStack spacing={4}>
                <Box alignSelf={'start'} lineHeight={'100%'}>
                  <Checkbox
                    isCircle
                    onChange={onChangeEnableMobileDebug}
                    checked={isMobileDebugEnabled}
                    disabled={false}
                    styled
                  />
                </Box>
                <VStack spacing={1}>
                  <TextG14 alignSelf={'start'} color={theme.colors.darkGrey}>
                    Enable Mobile Debug
                  </TextG14>
                </VStack>
              </HStack>
            </VStack>
					
          </Box>
					)}
          <EditAddress showTooltip addressType={AddressTypes.Home} header="Home Address" />
          <EditAddress addressType={AddressTypes.Billing} header="Billing Address" />
          <EditAddress addressType={AddressTypes.Mailing} header="Mailing Address" />
        </Flex>
      </form>
      <ProfilePicModal
        show={showPhotoModal}
        setShow={setShowPhotoModal}
        uploadedImage={uploadedImage}
      />
      <PromptModal
        style={{
          ModalContent: {h: 'auto', pb: 2, pt: 5},
        }}
        title="You might lose access to special perks and rewards. Opt out of SMS messages?"
        declineText={'No'}
        acceptText={'Yes'}
        declineCallback={() => {
          setSmsPromptModalOpen(false);
        }}
        acceptCallback={() => {
          setField('smsEnabled', !smsNotificationsEnabled);
          setSmsNotificationsEnabled(!smsNotificationsEnabled);
          setSmsChanged(true);
          setSmsPromptModalOpen(false);
        }}
        isOpen={smsPromptModalOpen}
      />
      <PromptModal
        style={{
          ModalContent: {h: 'auto', pb: 2, pt: 5},
        }}
        title="You might lose access to special perks and rewards. Opt out of Email notifications?"
        declineText={'No'}
        acceptText={'Yes'}
        declineCallback={() => {
          setEmailPromptModalOpen(false);
        }}
        acceptCallback={() => {
          setField('emailEnabled', !emailNotificationsEnabled);
          setEmailNotificationsEnabled(!emailNotificationsEnabled);
          setEmailChanged(true);
          setEmailPromptModalOpen(false);
        }}
        isOpen={emailPromptModalOpen}
      />
      <PromptModal
        style={{
          ModalContent: {h: 'auto', pb: 2, pt: 5},
        }}
        title="You might lose access to special perks and rewards. Opt out of Push notifications?"
        declineText={'No'}
        acceptText={'Yes'}
        declineCallback={() => {
          setPushPromptModalOpen(false);
        }}
        acceptCallback={() => {
          setField('pushEnabled', !pushNotificationsEnabled);
          setPushNotificationsEnabled(!pushNotificationsEnabled);
          setPushChanged(true);
          setPushPromptModalOpen(false);
          // pushService.unsubscribeUserToPush();
        }}
        isOpen={pushPromptModalOpen}
      />
      <EmailVerificationModalSheet
        ref={emailVerificationModalSheetRef}
        openModal={showEmailVerification}
        onModalClose={() => {
          setEmailVerificationCode('');
          setShowEmailVerification(false);
        }}>
        <VStack px={6} py={8} alignItems={'left'} spacing={2}>
          <TextG14 fontWeight={700}>Enter Code</TextG14>
          <TextG12 color={theme.colors.grey}>
            We’ve sent a code to your new email address. Didn’t receive the code?
            <Box
              onClick={() => sendEmailVerifyCodeRequest({resendCode: true})}
              as={'span'}
              color={theme.colors.blue}>
              {' '}
              Click here
            </Box>{' '}
            to resend.
          </TextG12>
          <form style={{width: '100%'}} onSubmit={handleSubmitEmailVerification}>
            <HStack spacing={4} py={4}>
              <CustomPinInput
                letterSpacing="5vw"
                value={emailVerificationCode}
                onChange={(e) => {
                  const value = e.target.value;
                  setEmailVerificationCode(value.toUpperCase());
                  setEmailVerifyButtonEnabled(value.length >= VERIFICATION_CODE_LENGTH);
                }}
                type="alphanumeric"
                placeholder=""
                variant="flushed"
                maxLength={VERIFICATION_CODE_LENGTH}
                fontSize="20px"
                textAlign="center"
              />
            </HStack>
            {isEmailVerificationInProgress ? (
              <SpinnerLoader py={2} />
            ) : verifiedEmail === data.email ? (
              <TextG14 my={7} textAlign={'center'}>
                Good to go!
                <Image
                  display="inline"
                  pl="5px"
                  verticalAlign="sub"
                  src={successImg}
                  alt="Successful"
                />
              </TextG14>
            ) : (
              <>
                {emailVerificationError && (
                  <Center py={3}>
                    <TextG12 color={'red'}>Entered verification code is not valid.</TextG12>
                  </Center>
                )}
                <VStack spacing={5}>
                  <BlueButtonFixedWidth
                    disabled={!emailVerifyButtonEnabled}
                    type={'submit'}
                    border={`1px solid ${theme.colors.grey}`}>
                    Verify
                  </BlueButtonFixedWidth>
                  <TextG14 fontWeight={'bold'} onClick={() => setShowEmailVerification(false)}>
                    Cancel
                  </TextG14>
                </VStack>
              </>
            )}
          </form>
        </VStack>
      </EmailVerificationModalSheet>
    </Container>
  );
}

const iconStyle: React.CSSProperties = {
  display: "block",
  marginTop: "-1px",
  textAlign: "center",
  fontSize: "14px",
};

export default EditProfile;
