const deviceCheck = (device: string, browser: string): boolean => {
  return (
    window.navigator.userAgent.toLowerCase().includes(device) &&
    window.navigator.userAgent.toLowerCase().includes(browser)
  );
};

//crios user agent for ios + chrome on mobile
export const isIphoneAndSafari: boolean =
  deviceCheck('iphone', 'safari') && !deviceCheck('iphone', 'crios');
export const isIphoneAndChrome: boolean = !isIphoneAndSafari;
export const isAndroidAndChrome: boolean = deviceCheck('android', 'chrome');

export const browserDetected = (): string => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) return 'chrome';
  if (userAgent.match(/firefox|fxios/i)) return 'firefox';
  if (userAgent.match(/safari/i)) return 'safari';
  if (userAgent.match(/opr\//i)) return 'opera';
  if (userAgent.match(/edg/i)) return 'edge';

  return '';
};

export const deviceDetected = (): 'iphone' | 'android' | 'iphone-pwa' | 'android-pwa' | '' => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.match(/iphone/i)) {
    if (isStandalone()) return 'iphone-pwa';
    return 'iphone';
  }
  if (userAgent.match(/android/i)) {
    if (isStandalone()) return 'android-pwa';
    return 'android';
  }

  return '';
};

export const osVersion = (device: ReturnType<typeof deviceDetected>): string | null => {
  const userAgent = window.navigator.userAgent;
  let version: RegExpMatchArray | null;

  switch (device) {
    case 'iphone':
      version = userAgent.match(/OS (\d+(?:\_\d+)*)/);
      break;
    case 'android':
      version = userAgent.match(/Android (\d+(?:\.\d+)*)/);
      break;
    default:
      version = null;
  }

  return !!version ? `${version[1]}` : version;
};

export const isStandalone = () => {
  return (
    ('standalone' in window.navigator && window.navigator['standalone']) ||
    window.matchMedia('(display-mode: standalone)').matches
  );
};

export const isIphone = deviceDetected() === 'iphone' || deviceDetected() === 'iphone-pwa';

export const isMobile = deviceDetected() !== '';
