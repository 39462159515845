import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  useDisclosure,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import {H3} from '../../components/typography';
import LocationImg from '../../assets/images/location.png';
import Input from '../../components/Input';
import {BlueButton} from '../../components/button';
import BottomModalSheet, {BottomModalSheetRefProps} from '../../components/Modals/BottomModalSheet';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {checkInList} from '../../endpoints/api';
import InfiniteScroll from '../../components/InfiniteScroll';
import useGetUserLocation, {Location} from '../../hooks/useUserLocation';
import {useGeneralSettings} from '../../hooks/useGeneralSettings';
import LocationDisabled from '../../components/LocationDisabled';
import CheckinCard from './CheckinCard';
import SelectContentType, {CONTENT_TYPE_OPTIONS} from "./SelectContentType";

const CheckIn = () => {
  const theme = useTheme();
  const userLocation = useGetUserLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [contentList, setContentList] = useState(<></>);
  const [verifiedLocation, setVerifiedLocation] = useState<Location | null>(null);
  const bottomModalSheetRef = useRef<BottomModalSheetRefProps>();
  const [selectedContentType, setSelectedContentType] = useState(CONTENT_TYPE_OPTIONS[0]);
  const {isLoading, getSetting} = useGeneralSettings();
  const [endpoint, setEndpoint] = useState<any>();

  // Start loader and fetch places
  useEffect(() => {
    setLoading(true);
    if (isLoading || !getSetting('checkin_distance')) return;
    if (verifiedLocation) {
      setEndpoint({
        endpoint: checkInList,
        params: {
          term: searchTerm || '',
          type: selectedContentType.value,
          lat: verifiedLocation?.latitude,
          long: verifiedLocation?.longitude,
        },
      });
    }
  }, [
    searchTerm,
    verifiedLocation,
    selectedContentType,
    getSetting('checkin_distance'),
  ]);

  // Stop loader when we fetch places.
  useEffect(() => setLoading(false), [contentList]);

  useEffect(() => {
    if (!verifiedLocation && userLocation.data) {
      setVerifiedLocation(userLocation.data);
    }
  }, [userLocation.data]);

  useEffect(() => {
    if (endpoint && endpoint.endpoint && endpoint.params) {
      setContentList(
        <InfiniteScroll
          showItems={(article) => <CheckinCard article={article} handleSelect={handleSelect} />}
          endpoint={endpoint.endpoint}
          endpointParams={endpoint.params}
        />
      );
    }
  }, [endpoint]);

  const handleSelect = async (article) => {
    navigate(`/${article.model_type}/${article.id}`, { replace: false });
  };

  if (!verifiedLocation) return <LocationDisabled />;

  return (
    <>
      <Box pos="absolute" top={0} w="100%">
        <Box
          height={'6px'}
          bg={'linear-gradient(to right, #276ACD, #47DDFE)'}
          borderBottomLeftRadius="15px"
          borderBottomRightRadius="15px"
        />
        <Center py={1}>
          <i onClick={() => navigate(-1)} className="fi fi-rr-angle-down"></i>
        </Center>
      </Box>
      <Box px={4} bg={theme.colors.veryLightBlue} pb={1}>
        <Flex pt={10} pb={5} alignItems={'center'}>
          <H3 mr={10}>Check-In</H3>
        </Flex>
        <Input
          size="sm"
          id="search-content"
          placeholder="Type place or event name"
          name="search_content"
          pl={10}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          LeftIcon={
            <Box>
              <i className="fi fi-rr-search"></i>
            </Box>
          }
        />
      </Box>
      <Box px={4} mt="15px">
        <SelectContentType
          selected={selectedContentType}
          changeHandler={(e) => setSelectedContentType(e)}
        />
      </Box>
      {loading ? (
        <Center pt={16}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </Center>
      ) : (
        contentList
      )}
      <BottomModalSheet ref={bottomModalSheetRef}>
        <VStack spacing={4}>
          <Center mt={8}>
            <Image src={LocationImg} alt={'location'} />
          </Center>
          <H3 px={12} pt={3} textAlign={'center'}>
            Sorry, You’re too far away to check-in here
          </H3>
          <Box py={4} px={8} w={'100%'}>
            <BlueButton
              w={'100%'}
              onClick={() =>
                bottomModalSheetRef?.current && bottomModalSheetRef.current?.setState(false)
              }>
              Okay
            </BlueButton>
          </Box>
        </VStack>
      </BottomModalSheet>
    </>
  );
};

export default CheckIn;
