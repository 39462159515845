import React from 'react';
import {Box, HStack, useTheme} from '@chakra-ui/react';
import {ArticleType, ARTICLE_TYPES, Place} from '../../../types/article';
import {capitalize, toUpper} from 'lodash';
import {TextG10} from '../../typography';
//@ts-ignore
import IntrinsicAttributes = React.JSX.IntrinsicAttributes;
import {Event} from '../../../types/article';

const ArticleInfo = ({
  article,
  renderArticleType = true,
  left = 2,
  top = 3,
  bottom,
  ArticleTypeFont = TextG10,
}: {
  article: ArticleType;
  renderArticleType?: boolean;
  left?: string | number;
  top?: string | number;
  bottom?: string | number;
  ArticleTypeFont?: IntrinsicAttributes;
}) => {
  const shouldFormatArticleType = Object.keys(ARTICLE_TYPES).includes(toUpper(article.type));
  const type = shouldFormatArticleType
    ? `${capitalize(article.type)}s`
    : typeof article.type == 'object' || article.type == null || article.type == 'article'
    ? 'Places'
    : article.type;

  const theme = useTheme();

  if (!renderArticleType) <></>;

  return (
    <HStack
      pos={'absolute'}
      left={left}
      top={!bottom ? top : undefined}
      bottom={bottom}
      spacing={2}>
      {article.type == 'Partner' ||
      (article as Event).is_walkabout_official ||
      (article as Place).partner ||
      (article as Place).partner_id ? (
        <HStack bg={theme.colors.rose} borderRadius="15px" px={'6px'} py={'2px'}>
          <ArticleTypeFont fontWeight={500} color={'white'}>
            {type}
          </ArticleTypeFont>
        </HStack>
      ) : (
        <Box bg={'white'} borderRadius="15px" border={`1px solid ${theme.colors.lighterGrey}`}>
          <ArticleTypeFont fontWeight={500} color={'grey'} px={'6px'} py={'1px'}>
            {type}
          </ArticleTypeFont>
        </Box>
      )}
    </HStack>
  );
};

export default ArticleInfo;
