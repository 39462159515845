import { createSlice } from '@reduxjs/toolkit'

export type ModalNotificationProps = {
    [index: string]: string;
}

export type SliceState = ModalNotificationProps[];
type SliceAction = { payload: ModalNotificationProps, type: string };

const initialState: SliceState = [];

export const ModalNotificationSlice = createSlice({
    name: 'modalNotifications',
    initialState,
    reducers: {
        pushNotification: (state: SliceState, action: SliceAction) => {
            state.push({...action.payload });
        },
        popNotification: (state: SliceState) => {
            state.shift();
        }
    },
});

export const ModalNotificationActions = ModalNotificationSlice.actions;

export default ModalNotificationSlice;