import {useQuery} from '@tanstack/react-query';
import {fetchStateList} from '../endpoints/api';

export const useFetchStatesList = () => {
  return useQuery(['fetchStates'], () => fetchStateList(), {staleTime: Infinity});
};

export const useStatesList = () => {
  const fetchStates = useFetchStatesList();

  return {statesList: fetchStates.data?.data, stateListIsLoading: fetchStates.isLoading};
};
