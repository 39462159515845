import {Center, Flex, Spinner, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import MediaGallery from '../../components/MediaGallery';
import useAuth from '../../hooks/useAuth';
import { Community } from "../../types/community";
import {useFetchCommunity} from '../../hooks/useCommunityComments';
import NotFound from '../NotFound';
import About from './About';
import CommunitySuccessModal from './CommunitySuccessModal';
import Header from './Header';

const Community = () => {
  const {communityId} = useParams();
  const [searchParams] = useSearchParams();

  const isSuccess = !!searchParams.get('success');
  const navigate = useNavigate();
  const theme = useTheme();

  const {data, isLoading} = useFetchCommunity(Number(communityId));
  const community = data?.data;

  const {user} = useAuth();

  if (isLoading)
    return (
      <Center h="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <>
      {isSuccess && <CommunitySuccessModal community={community} />}
      <Flex position="relative" height="250px">
        <BackButton onClick={() => navigate(-1)} m={'.75rem'} pos="relative" />
        <MediaGallery media={community.media} />
      </Flex>
      <Header community={community} />
      <About community={community} />
    </>
  );
};

export default Community;
