import React from 'react';
import {HStack, Icon, Checkbox as ChakraCheckbox, useTheme} from '@chakra-ui/react';
import styled, {css} from 'styled-components';
import CheckmarkIcon from '../assets/images/checkmark.svg';
import { TextG10, TextG12, TextG14 } from "./typography";

const StyledInput = styled.input`
  display: none;
`;

const CheckmarkContainer = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  overflow: hidden;
  margin-inline: 0 !important;

  & svg {
    transition: all 0.15s ease-in-out;
    opacity: ${(props) => (props.isChecked ? 1 : 0)};
  }

  ${(props) =>
    props.isCircle &&
    css`
      border-radius: 50%;
    `};
  ${(props) =>
    props.theme &&
    css`
      outline: 1px solid ${props.theme.colors.grey};
    `};
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: ${props.isChecked ? 0.3 : 0};
    `};
`;

interface CheckboxProps {
  id?: string;
  name?: string;
  isCircle: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange: (e: boolean) => void;
  styled?: boolean;
}

export function Checkbox({
  id = undefined,
  name,
  isCircle,
  checked,
  onChange,
  disabled,
  styled,
}: CheckboxProps) {
  const toggleCheck = () => onChange(!checked);
  const theme = useTheme();

  return !isCircle ? (
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      disabled={disabled}
      onChange={disabled ? undefined : toggleCheck}
      style={styled ? {width: '16px', height: '16px'} : {}}
    />
  ) : (
    <>
      <StyledInput _focus={{boxShadow: 'none'}} type="checkbox" id={id} />
      <CheckmarkContainer
        isCircle={isCircle}
        theme={theme}
        data-cy={id}
        onClick={disabled ? undefined : toggleCheck}
        disabled={disabled}
        isChecked={checked}
        // {...props}
      >
        <Icon as={CheckmarkIcon} w={5} h={5} />
      </CheckmarkContainer>
    </>
  );
}

Checkbox.defaultProps = {
  isCircle: true,
  checked: false,
  disabled: false,
  onChange: (e) => e,
};

const Label = styled(TextG14)`
  margin-left: 18px !important;
  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.lightestGrey} !important;
    `}
`;


/**
 * Checkbox with label
 */
export const LabelledCheckbox = ({checked, onChange, label, disabled, ...props}) => {
  const theme = useTheme();
  return (
    <HStack>
      <Checkbox checked={checked} onChange={onChange} disabled={disabled} {...props} />
      <Label
        theme={theme}
        disabled={disabled}
        color={checked ? theme.colors.blue : theme.colors.lightBlack}
        fontWeight={checked ? 700 : 400}>
        {label}
      </Label>
    </HStack>
  );
};

export const LabelledCheckboxGrey = ({checked, onChange, label, disabled, ...props}) => {
  return (
    <HStack>
      <Checkbox checked={checked} onChange={onChange} disabled={disabled} {...props} />
      <TextG10>{label}</TextG10>
    </HStack>
  );
};
