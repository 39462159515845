import React from 'react';
import Helmet from 'react-helmet';
import {Post} from '../../types/article';

const PostHelmet = ({post}: {post: Post}) => {
  return (
    <Helmet>
      <title>{post.title}</title>
      <meta name="description" content={post.description_plain_text} />
      <meta name="twitter:title" content={post.title} />
      <meta name="twitter:description" content={post.description_plain_text} />
      <meta name="twitter:card" content={post.title} />
      <meta name="twitter:image" content={post.image_url} />
      <meta property="og:title" content={post.title} />
      <meta property="og:description" content={post.description_plain_text} />
      <meta property="og:image" content={post.image_url} />
      <meta property="og:image:secure_url" content={post.image_url} />
      <meta property="og:image:url" content={post.image_url} />
    </Helmet>
  );
};

export default PostHelmet;
