import {AbsoluteCenter, Center, Spinner, useTheme, VStack} from '@chakra-ui/react';
import {filter, find, some, without} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import useAuth from '../../../hooks/useAuth';
import PaymentOption from './PaymentOption';
import {pointsToDollars, dollarsToPoints} from '../../../utils/price';
import {useDispatch, useSelector} from 'react-redux';
import {
  setAmountDue,
  setAppliedIds,
  setAppliedPass,
  setCardPaymentAmount,
  setNumPointsApplied,
  setPassDiscountAmount,
} from '../../../state/Checkout/CheckoutSlice';
import {getPaymentOptions} from './options';
import {useFetchPurchasablePasses} from '../../../hooks/usePassPurchase';
import {useNavigate} from 'react-router-dom';
import UpgradeModal from './UpgradeModal';

const PaymentOptions = ({
  pass,
  ticket,
  highlightedOptionId,
  setHighlightedOptionId,
  event,
  userPasses,
}) => {
  const {user} = useAuth();
  const theme = useTheme();
  const {
    appliedIds,
    numPointsApplied,
    amountDue,
    passDiscountAmount,
    cardPaymentAmount,
    qty,
    membershipDiscountAmount,
  } = useSelector((state: any) => state.checkout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const eligibleUserPasses = filter(userPasses, (userPass) =>
    some(event?.pass_types, (passType) => passType.id === userPass?.pass?.type?.id)
  );
  const firstUserPass = eligibleUserPasses[0];

  const [numPointsEntered, setNumPointsEntered] = useState<number>(0);

  const setMaxPointAmount = (amtDue?: number): void => {
    const due = amtDue || amountDue;
    const pointCost = dollarsToPoints(due, ticket ? ticket.points_value : pass.denomination);

    if (
      pointsToDollars(user?.pointsTotal, ticket ? ticket.points_value : pass.denomination) >= due
    ) {
      setNumPointsEntered(
        Math.ceil(
          Math.max(dollarsToPoints(due, ticket ? ticket.points_value : pass.denomination), 1)
        )
      );
    } else if (user?.pointsTotal && Number(pointCost) > user?.pointsTotal) {
      setNumPointsEntered(user?.pointsTotal);
    }
  };

  //const passDiscountAmount = total/2; // Pass covers entire total for now

  const handlePassApplied = (passDiscountAmount) => {
    const discAmount = passDiscountAmount >= amountDue ? amountDue : passDiscountAmount;
    if (!appliedIds?.includes('user_pass')) {
      dispatch(setAppliedIds([...appliedIds, 'user_pass']));
      dispatch(setAmountDue(amountDue - discAmount));
      dispatch(setPassDiscountAmount(discAmount));
      dispatch(setAppliedPass(firstUserPass));
    }
  };

  const handlePassRemoved = () => {
    dispatch(setAppliedIds(without(appliedIds, 'user_pass')));

    let amtDue = (ticket ? parseFloat(ticket.price.replace(/[^\d.-]/g, '')) : pass.amount) * qty;
    if (cardPaymentAmount && cardPaymentAmount > 0) amtDue -= cardPaymentAmount;

    if (numPointsApplied && numPointsApplied > 0)
      amtDue -= parseFloat(
        pointsToDollars(numPointsApplied, ticket ? ticket.points_value : pass.denomination)
      );

    if (membershipDiscountAmount && membershipDiscountAmount > 0) {
      amtDue -= membershipDiscountAmount;
    }
    dispatch(setAmountDue(amtDue));
    dispatch(setPassDiscountAmount(0));
  };

  const handlePointsApplied = () => {
    if (!appliedIds?.includes('walkabout_points')) {
      numPointsEntered > 0 && dispatch(setAppliedIds([...appliedIds, 'walkabout_points']));
      dispatch(setNumPointsApplied(numPointsEntered));
      const discAmount = parseFloat(
        pointsToDollars(numPointsEntered, ticket ? ticket.points_value : pass.denomination)
      );
      dispatch(setAmountDue(amountDue - discAmount));
    }
  };

  const handlePointsEnteredChanged = (e) => {
    if (
      pointsToDollars(e.target.value, ticket ? ticket.points_value : pass.denomination) >
        amountDue &&
      pointsToDollars(user?.pointsTotal, ticket ? ticket.points_value : pass.denomination) >=
        amountDue
    ) {
      setNumPointsEntered(
        Math.ceil(
          Math.max(dollarsToPoints(amountDue, ticket ? ticket.points_value : pass.denomination), 1)
        )
      );
    } else if (user?.pointsTotal && Number(e.target.value) > user?.pointsTotal) {
      setNumPointsEntered(user?.pointsTotal);
    } else if (isNaN(parseInt(e.target.value))) {
      setNumPointsEntered(0);
    } else if (user?.pointsTotal && parseInt(e.target.value) <= user?.pointsTotal) {
      setNumPointsEntered(parseInt(e.target.value));
    }
  };

  const handlePointsEdit = () => {
    dispatch(setAppliedIds(without(appliedIds, 'walkabout_points')));
    setHighlightedOptionId('walkabout_points');
    let amtDue = (ticket ? parseFloat(ticket.price.replace(/[^\d.-]/g, '')) : pass.amount) * qty;
    if (passDiscountAmount && passDiscountAmount > 0) amtDue -= passDiscountAmount;
    if (cardPaymentAmount && cardPaymentAmount > 0) amtDue -= cardPaymentAmount;
    if (membershipDiscountAmount && membershipDiscountAmount > 0) {
      amtDue -= membershipDiscountAmount;
    }

    //setNumPointsEntered(0);
    dispatch(setNumPointsApplied(0));
    dispatch(setAmountDue(amtDue));
    setMaxPointAmount(amtDue);
  };

  const handleCardApplied = () => {
    if (!appliedIds?.includes('card_payment')) {
      dispatch(setAppliedIds([...appliedIds, 'card_payment']));
      dispatch(setAmountDue(0));
      dispatch(setCardPaymentAmount(amountDue));
    }
  };

  const handleCardRemoved = () => {
    dispatch(setAppliedIds(without(appliedIds, 'card_payment')));

    let amtDue = (ticket ? parseFloat(ticket.price.replace(/[^\d.-]/g, '')) : pass.amount) * qty;
    if (passDiscountAmount && passDiscountAmount > 0) amtDue -= passDiscountAmount;
    if (numPointsApplied && numPointsApplied > 0)
      amtDue -= parseFloat(
        pointsToDollars(numPointsApplied, ticket ? ticket.points_value : pass.denomination)
      );
    if (membershipDiscountAmount && membershipDiscountAmount > 0) {
      amtDue -= membershipDiscountAmount;
    }

    dispatch(setAmountDue(amtDue));
    dispatch(setCardPaymentAmount(0));
  };

  const fetchPasses = useFetchPurchasablePasses(event?.pass_types[0] != undefined);
  const purchasablePass = find(
    fetchPasses?.data?.data,
    (item) => item.type_id == event?.pass_types[0]?.id
  );

  const options = useMemo(
    () =>
      getPaymentOptions({
        event,
        user,
        ticket,
        pass,
        theme,
        handlePassApplied,
        handlePassRemoved,
        handleCardApplied,
        handleCardRemoved,
        handlePointsEnteredChanged,
        handlePointsEdit,
        handlePointsApplied,
        numPointsEntered,
        numPointsApplied,
        cardPaymentAmount,
        eligibleUserPasses,
        purchasablePass,
        navigate,
        setShowUpgradeModal,
      }),
    [user, event, ticket, pass, eligibleUserPasses, purchasablePass]
  );

  useEffect(() => {
    if (!fetchPasses.isLoading) {
      setHighlightedOptionId(options[0]?.id);
    }
  }, [fetchPasses.isLoading]);

  useEffect(() => {
    if (highlightedOptionId == 'walkabout_points') {
      setMaxPointAmount();
    }
  }, [highlightedOptionId]);

  if (event?.pass_types[0] != undefined && fetchPasses.isLoading)
    return (
      <AbsoluteCenter style={{minHeight: '100vh'}} top="100%">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </AbsoluteCenter>
    );

  return (
    <>
      <VStack w="100%" gap="12px" pt={3} pb={20}>
        {options &&
          options.length > 0 &&
          options.map((option, i) => {
            if (option)
              return (
                <PaymentOption
                  key={i}
                  index={i}
                  ticket={ticket}
                  pass={pass}
                  option={option}
                  disabled={amountDue <= 0}
                  highlightedOptionId={highlightedOptionId}
                  setHighlightedOptionId={setHighlightedOptionId}
                />
              );
          })}
      </VStack>
      <UpgradeModal
        event={event}
        user={user}
        show={showUpgradeModal}
        setShow={setShowUpgradeModal}
      />
    </>
  );
};

export default PaymentOptions;
