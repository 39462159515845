import React, {useState} from 'react';
import {EventRSVPStatus, useSetAttendance} from '../../../hooks/useEvent';
import RSVPButton, {RSVPButtonProps} from './RSVPButton';
import RSVPSelect, {RSVPSelectProps} from './RSVPSelect';

interface RSVPControllerProps extends RSVPButtonProps, RSVPSelectProps {
  buttonClickCallback?: () => void;
  optionClickCallback?: () => void;
}

const RSVPController = ({
  event,
  optionClickCallback,
  buttonClickCallback,
  ...props
}: RSVPControllerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {mutateAsync} = useSetAttendance();
  const currentStatus = event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS;

  const onClickRSVPButton = (e) => {
    buttonClickCallback && buttonClickCallback();
    e.preventDefault();
    setIsOpen(true);
  };

  const onClickRSVPOption = (status: EventRSVPStatus) => {
    optionClickCallback && optionClickCallback();
    setIsOpen(false);
    mutateAsync({
      eventId: event.id,
      prevStatus: currentStatus,
      status,
    });
  };

  const onCloseRSVPSelect = () => setIsOpen(false);

  return (
    <>
      <RSVPButton event={event} onButtonClick={onClickRSVPButton} {...props} />
      <RSVPSelect
        event={event}
        onModalClose={onCloseRSVPSelect}
        openModal={isOpen}
        onOptionClick={onClickRSVPOption}
        {...props}
      />
    </>
  );
};

export default RSVPController;
