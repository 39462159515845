import React from 'react';
import {HStack, VStack, useTheme} from '@chakra-ui/react';
import {BlueButton} from '../../components/button';
import Modal from '../../components/Modals/Modal';
import {TextA24, TextG12, TextG14} from '../../components/typography';
import MeetUpCard from '../../components/Articles/MeetUpCard';

const MeetupCreatedModal = ({isOpen, onSendBroadcast, onMaybeLater, 'meet-up': meetUp}) => {
  const theme = useTheme();

  return (
    <Modal
      modalPadding={0}
      closeOnOverlayClick={false}
      headerPadding="1rem 1.5rem 0 1.5rem"
      header={<TextA24 textAlign="center">Meet-Up Created!!!!</TextA24>}
      isCentered
      show={isOpen}
      closeButton={false}
      footer={
        <HStack gap="12px" w="100%" justify="center" px={4}>
          <VStack w="100%">
            <BlueButton w="100%" onClick={onSendBroadcast}>
              <TextG12>Send Broadcast Now</TextG12>
            </BlueButton>
            <BlueButton w="100%" onClick={onMaybeLater}>
              <TextG12>Maybe Later</TextG12>
            </BlueButton>
          </VStack>
        </HStack>
      }>
      <VStack textAlign="center" color={theme.colors.grey} px={4} my={2} gap={2}>
        <MeetUpCard onClick={() => {}} meet-up={meetUp} />
        <TextG14 fontWeight="500">Do you want to Broadcast it to nearby people by Text?</TextG14>
      </VStack>
    </Modal>
  );
};

export default MeetupCreatedModal;
