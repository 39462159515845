import {Box, Flex, useTheme} from '@chakra-ui/react';
import {find, sortBy} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import RoundedBoxItems from '../../../../components/RoundedBoxItems';
import {TextG18, TextG14} from '../../../../components/typography';
import {fetchTopics, removeTopic, setTopic} from '../../../../endpoints/api';
import useAuth from '../../../../hooks/useAuth';
import {GroupedTopics} from '../Topics';

const Interests = ({numOfSelectedTopics, setNumOfSelectedTopics}) => {
  const theme = useTheme();
  const {reFetchUserData, user} = useAuth();
  const [groupedTopics, setGroupedTopics] = useState<Array<GroupedTopics>>([]);
  const [meetingPeopleTopic, setMeetingPeopleTopic] = useState<any>();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchTopics().then(({data}) => {
      setGroupedTopics(sortBy(data, 'name'));
      setLoading(false);

      const meetingPeopleTopic = find(find(data, {name: 'Social'})?.topics, {
        name: 'Meeting People',
      });

      if (meetingPeopleTopic && meetingPeopleTopic.id) {
        setMeetingPeopleTopic(meetingPeopleTopic);
        setTopic(meetingPeopleTopic.id).then(() => reFetchUserData());
        setNumOfSelectedTopics((prev) => prev + 1);
      }
    });
    reFetchUserData();
  }, []);

  const handleOnSelect = (topicId, isSelected) => {
    isSelected
      ? setTopic(topicId)
          .then(() => setNumOfSelectedTopics(numOfSelectedTopics + 1))
          .catch(() => alert('Something went wrong'))
      : removeTopic(topicId)
          .then(() => setNumOfSelectedTopics(numOfSelectedTopics - 1))
          .catch(() => alert('Something went wrong'));
  };

  const initialSelected = useMemo(
    () => (meetingPeopleTopic ? [meetingPeopleTopic?.id] : undefined),
    [meetingPeopleTopic]
  );

  return (
    <Flex flexDir="column" my={6} id="interest">
      <TextG18 color={theme.colors.lightBlack} fontWeight="700" mb={2}>
        Interests
      </TextG18>
      <TextG14 color={theme.colors.grey}>
        Tell us what you love, we’ll find things nearby that really insprie you.
      </TextG14>

      {groupedTopics.map(({name: category, topics}) => (
        <Box key={category} pt={5}>
          <RoundedBoxItems
            title={category}
            elements={topics}
            selected={user?.topics.length ? user?.topics.map((t) => parseInt(t.id)) : []}
            onSelect={handleOnSelect}
            initialSelected={initialSelected}
          />
        </Box>
      ))}
    </Flex>
  );
};

export default Interests;
