import {ToolTipFunction} from '.';
import {TooltipProps} from './Tooltip';

const EventTooltips: ToolTipFunction = (
  nextStep,
  prevStep,
  finish,
  setConfettiExploding,
  theme
) => {
  const url = new URL(location.href);
  if (url.searchParams.get('tutorial')) url.searchParams.delete('tutorial');
  const path = url.pathname + url.search;

  const TOOLTIP_STEPS: TooltipProps[] = [
    {
      anchorId: 'none',
      title: 'NEW! Event Features',
      message: `We just dropped 3 new features you can use right now.`,
      place: 'bottom',
      onCtaClick: nextStep,
      buttonPrev: 'Skip',
      onPrevClick: finish,
      onCloseClick: finish,
      prevButtonStyle: {color: theme.colors.blue, background: 'transparent'},
      closePath: path,
    },
    {
      anchorId: 'rsvp-tooltip',
      highlightId: 'rsvp-tooltip-highlight',
      pulseId: 'rsvp-tooltip',
      title: 'RSVP For Events',
      message: 'Plan your week and get updates about events you’re interested in or going to.',
      place: 'bottom',
      buttonCta: 'Next',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: path,
    },
    {
      anchorId: 'checkin-tooltip',
      highlightId: 'rsvp-tooltip-highlight',
      pulseId: 'checkin-tooltip',
      title: 'Check-in To Events',
      message: 'Check-in to earn points and unlock special offers from our partners.',
      place: 'bottom',
      buttonCta: 'Next',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: path,
    },
    {
      anchorId: 'share-tooltip',
      highlightId: 'rsvp-tooltip-highlight',
      pulseId: 'share-tooltip',
      title: 'Share This Event',
      message: 'Know someone who would love an event? Share the link with them here.',
      place: 'bottom',
      buttonCta: 'Next',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: path,
    },
    {
      anchorId: 'none',
      title: 'You’re good to go!',
      message: 'Have fun out there. 🎉',
      place: 'top',
      buttonCta: 'Done',
      onCtaClick: finish,
      onPrevClick: prevStep,
      onCloseClick: finish,
      onShow: () => setConfettiExploding(true),
      closePath: path,
    },
  ];

  return TOOLTIP_STEPS;
};

export default EventTooltips;
