import {Flex, HStack, Image, useTheme, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ChatCard from '.';
import DefaultEventImage from '../../../assets/images/default_event.png';
import DotSeparator from '../../../components/DotSeparator';
import {TextG12, TextG14} from '../../../components/typography';
import { Community } from "../../../types/community";
import { useFetchCommunityUserNum } from '../../../hooks/useCommunityComments';

const CommunityChatCard = ({
  community: community,
  closed = false,
}: {
  community: Community;
  closed?: boolean;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const fetchNumUsers = useFetchCommunityUserNum(community.id);
  const numUsers = fetchNumUsers.data?.data.count;

  return (
    <ChatCard showDivider={false} setHeight={false}>
      <VStack
        minW="fit-content"
        align="center"
        onClick={() => !closed && navigate(`/communities/${community.id}/chat`)}>
        <Image
          borderRadius="100%"
          w="70px"
          h="70px"
          objectFit="cover"
          //fallbackSrc={DefaultEventImage}
          src={community.logo || DefaultEventImage}
        />
        <VStack align="center" w="100%" mt="8px !important">
          <TextG14
            //w="80px"
            noOfLines={1}
            fontWeight="500"
            color={closed ? theme.colors.lightestGrey : theme.colors.lightBlack}>
            {community.name}
          </TextG14>

          {numUsers &&
            <TextG12 mt="0 !important" color={closed ? theme.colors.lightestGrey : theme.colors.grey}>
              {`${numUsers}`} {numUsers <= 1 ? 'Person' : 'People'}
            </TextG12>
          }
          {/* {numUnread > 0 && (
              <>
                <DotSeparator
                  color={theme.colors.rose}
                  width="4px"
                  height="4px"
                  marginLeft="0"
                  marginRight="0"
                />
                <TextG12 color={theme.colors.rose} margin="0 !important">
                  {numUnread == 1 ? 'New Message' : 'New Messsages'}
                </TextG12>
              </>
            )} */}
        </VStack>
      </VStack>
    </ChatCard>
  );
};

export default CommunityChatCard;
