import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Center, Flex, HStack, Image, Link, Spacer} from '@chakra-ui/react';
import styled, {css} from 'styled-components';

import background from '../../assets/images/background2.png';
import Logo from '../../assets/logos/logo_120x120.png';
import profile1 from '../../assets/images/profile-landing-1.png';
import profile2 from '../../assets/images/profile-landing-2.png';
import profile3 from '../../assets/images/profile-landing-3.png';
import {H1, H2, TextG14} from '../typography';
import {getAppName} from '../../utils/app';

const StyledBox = styled(Box)`
  background-repeat: no-repeat !important;
  background-size: cover !important;
  ${(props) =>
    props.bg &&
    css`
      background: linear-gradient(0deg, #272929 -3.94%, rgba(39, 41, 41, 0) 108.19%),
        url(${background});
    `};
`;

interface LandingWrapperProps {
  title: string;
  subtitle: string;
  numOfRegistered: number;
  children: React.ReactNode;
}

function LandingWrapper({children, title, subtitle, numOfRegistered}: LandingWrapperProps) {
  const navigate = useNavigate();

  return (
    <StyledBox bg={background} minH={'100%'}>
      <Flex px={6} py={'5vh'} alignItems="center">
        <Box>
          <Link href="https://walkabout.app">
            <H2 color={'white'}>{getAppName()}</H2>
          </Link>
        </Box>
        <Spacer />
        <Box>
          <Image src={Logo} width="42px" alt="Logo" onClick={() => navigate('/login')} />
        </Box>
      </Flex>
      <Center mt="10vh">
        <H1 color="white" textAlign={'center'}>
          {title}
        </H1>
      </Center>
      <TextG14 py={2} color={'white'} textAlign={'center'} px="56px">
        {subtitle}
      </TextG14>
      <Center>
        <HStack mt={'4vh'}>
          <Box display="inline-flex">
            <Image src={profile1} alt="Profile 1" />
            <Image ml="-26px" src={profile2} alt="Profile 2" />
            <Image ml="-26px" src={profile3} alt="Profile 3" />
          </Box>
          <TextG14 color={'white'} textAlign={'center'}>{`+${
            numOfRegistered || 0
          } locals like you`}</TextG14>
        </HStack>
      </Center>
      {children}
    </StyledBox>
  );
}

export default LandingWrapper;
