import React from 'react';
import Helmet from 'react-helmet';
import {Event} from '../../types/article';

const EventHelmet = ({event}: {event: Event}) => {
  return (
    <Helmet>
      <title>{event.title}</title>
      <meta name="description" content={event.description_plain_text} />
      <meta name="twitter:title" content={event.title} />
      <meta name="twitter:description" content={event.description_plain_text} />
      <meta name="twitter:card" content={event.title} />
      <meta name="twitter:image" content={event.image_url} />
      <meta property="og:title" content={event.title} />
      <meta property="og:description" content={event.description_plain_text} />
      <meta property="og:image" content={event.image_url} />
      <meta property="og:image:secure_url" content={event.image_url} />
      <meta property="og:image:url" content={event.image_url} />
    </Helmet>
  );
};

export default EventHelmet;
