import {AbsoluteCenter, Box, VStack} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackgroundImage from '../../assets/images/meet-up-btn-bkgd.png';
import {BlueButton} from '../button';
import {TextG10, TextG18} from '../typography';

const MeetUpButton = () => {
  const navigate = useNavigate();

  return (
    <Box
      mb="15px"
      onClick={() => navigate('/create-meetup')}
      textAlign="center"
      minH="145px"
      backgroundRepeat="round"
      backgroundSize="contain"
      backgroundImage={BackgroundImage}>
      <VStack py="20px" px="30px">
        <TextG10 color="white">On Walkabout, you've always got plans!</TextG10>
        <TextG18 lineHeight="normal" color="white" fontWeight="700">
          Create a meet-up to discover new people to hangout with.
        </TextG18>
        <BlueButton w="162px" mt="20px !important">
          <TextG10 fontWeight="500" color="white">
            Create a Meet-Up
          </TextG10>
        </BlueButton>
      </VStack>
    </Box>
  );
};

export default MeetUpButton;
