import { Flex, HStack, Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { components, GroupProps } from "react-select";
import { TextG10 } from "../../../typography";
import { take } from "lodash";
import { SearchOptions, GroupedOption } from "..";
import { RecentSearchOption, Option } from "../Option";

const RecentSearchGroup = (props) => {
  const { options, children, label } = props;
  return (
    <components.Group {...props}>
      <Flex flexDirection={"column"}>
        {take(options, 5).map((option, index) => (
          <RecentSearchOption
            key={children![index].key}
            {...children![index].props}
          ></RecentSearchOption>
        ))}
      </Flex>
    </components.Group>
  );
};

const Group = (props: GroupProps<SearchOptions, true, GroupedOption>) => {
  const {
    selectProps: { inputValue, inputCleared },
  } = props;
  return (inputValue === "" && inputCleared) ? (
    <RecentSearchGroup {...props} />
  ) : <></>;
};

export default Group;
