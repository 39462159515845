import {Box, Flex, useTheme} from "@chakra-ui/react";
import React from "react";
import {TextG10, TextG12} from "../typography";
import {useNavigate} from "react-router-dom";
import Icon from "../../components/Icon";
import styled from "styled-components";

const StyledBox = styled(Box)`
  border: 2px solid #f9ce39;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
  padding: 5px;
  color: white;
  width: 12px;
  height: 12px;
  background-color: #f9ac39;
`;

const ProgressPoints = ({title}: {title: string}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flex="1"
      onClick={() => navigate('/search/offer')}>
      <Flex
        id="points-tooltip"
        alignItems={'center'}
        padding={'7px 10px'}
        borderRadius={'14.5px'}
        background={theme.colors.veryLightBlue}>
        {/* <StyledBox>
          <Icon
            centered
            iconName="fi fi-sr-star"
            style={{
              fontSize: '7px',
              marginBottom: '2px',
            }}
          />
        </StyledBox> */}
        {title && (
          <TextG10 marginTop="2px" lineHeight="16px" fontWeight="900" color={theme.colors.blue}>
            {title}
          </TextG10>
        )}
      </Flex>
    </Flex>
  );
};

export default ProgressPoints;
