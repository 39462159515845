import React from 'react';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import {Autoplay, FreeMode, Pagination, Navigation, Lazy} from 'swiper';
import styled from 'styled-components';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

type Item<T> = T & {id: number | string};

export interface SwiperSliderProps<T> {
  items: Item<T>[];
  render: (item: Item<T>, index: number) => JSX.Element | null;
  slidesPerView?: number | string;
  spaceBetween?: number;
  slidesPerGroup?: number | string;
  autoHeight?: boolean;
  autoplay?: boolean;
  freeMode?: boolean;
  lastCard?: JSX.Element | null;
  dynamicBullets?: boolean;
}

const StyledSwiper = styled(Swiper)`
  z-index: 0;
  margin-top: 0px !important;
  .swiper-pagination {
    position: relative !important;
    bottom: 0 !important;
    margin-top: 10px;
  }
`;
function SwiperSlider<T>({
  items,
  render,
  slidesPerView,
  spaceBetween,
  slidesPerGroup,
  autoHeight,
  autoplay,
  freeMode,
  lastCard,
  dynamicBullets,
}: SwiperSliderProps<T>) {
  if (!items) return null;
  return (
    <StyledSwiper
      freeMode={freeMode}
      autoHeight={autoHeight}
      autoplay={autoplay ? {delay: 8000} : false}
      watchSlidesProgress={true}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      slidesPerGroup={slidesPerGroup}
      loopedSlides={items.length}
      rewind={true}
      pagination={{
        clickable: true,
        dynamicBullets: dynamicBullets,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation, FreeMode, Lazy]}>
      {items.map((item, i) => {
        const rend = render(item, i);
        if (rend) {
          return (
            <SwiperSlide className="swiper-lazy" style={{width: 'fit-content'}} key={item.id}>
              {rend}
            </SwiperSlide>
          );
        }
      })}
      {lastCard && (
        <SwiperSlide style={{width: 'fit-content', height: '100%'}}>{lastCard}</SwiperSlide>
      )}
    </StyledSwiper>
  );
}

SwiperSlider.defaultProps = {
  slidesPerView: 1,
  spaceBetween: 10,
  slidesPerGroup: 1,
  autoPlay: false,
  freeMode: false,
  autoHeight: false,
  dynamicBullets: true,
};

export default SwiperSlider;
