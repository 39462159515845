import {SimpleGrid, Image, Box, useTheme, CloseButton, Flex} from '@chakra-ui/react';
import React, {useEffect, useMemo, useState} from 'react';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import FallbackImg from '../../assets/images/fallback.jpg';
import {TextA18} from '../typography';
import Modal from '../Modals/Modal';

const PhotoGallery = ({
  images,
  show,
  setShow,
}: {
  images: string[];
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [currentImg, setCurrentImg] = useState<string>();
  const theme = useTheme();

  const modalBody = useMemo(
    () => (
      <SimpleGrid columns={2} spacing={1} px={4} pb={4} justifyItems="center">
        {images.map((image, i) => (
          <Image
            fallbackStrategy="onError"
            key={i}
            onClick={() => {
              setShowFullscreen(true);
              setCurrentImg(image);
            }}
            fallbackSrc={FallbackImg}
            src={image}
            alt="cover image"
            width="165px"
            height="165px"
            objectFit="cover"
          />
        ))}
      </SimpleGrid>
    ),
    [images]
  );

  const currentImageBody = useMemo(
    () => (
      <Flex background={theme.colors.darkGrey}>
        <TransformWrapper>
          <TransformComponent
            contentStyle={{width: '95vw', height: '85vh'}}
            wrapperStyle={{background: theme.colors.darkGrey}}>
            <Image
              fallbackStrategy="onError"
              src={currentImg}
              alt="cover image"
              width="100%"
              height="100%"
              objectFit="contain"
              maxHeight="95vh"
              background={theme.colors.darkGrey}
            />
          </TransformComponent>
        </TransformWrapper>
      </Flex>
    ),
    [currentImg]
  );

  useEffect(() => {
    if (showFullscreen) {
      setTimeout(() => setShowClose(showFullscreen), 150);
    } else {
      setShowClose(showFullscreen);
    }
  }, [showFullscreen]);

  return (
    <>
      <Modal
        header={
          <CloseButton
            opacity={showClose ? 1 : 0}
            transition={'opacity 500ms'}
            position="fixed"
            top={2}
            right={3}
            color="#9b9b9b"
            border="2px solid #9b9b9b"
            borderRadius="full"
            onClick={() => setShowFullscreen(false)}
            zIndex={1}
          />
        }
        children={currentImageBody}
        show={showFullscreen}
        parentCallback={() => setShowFullscreen(false)}
        modalPadding={0}
        headerPadding="0px"
        isCentered
        overlayBackground={theme.colors.darkGrey}
      />
      <Modal
        scrollBehavior="inside"
        header={<TextA18 textAlign="center">Gallery</TextA18>}
        children={modalBody}
        show={show}
        parentCallback={() => setShow(false)}
        modalPadding={0}
        closeButton
      />
    </>
  );
};

export default PhotoGallery;
