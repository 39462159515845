import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {BottomSheetNotificationActions} from "./BottomSheetNotificationSlice";
import BottomModalSheet, {BottomModalSheetRefProps} from "../../components/Modals/BottomModalSheet";
import {Center, Link, useTheme, VStack} from "@chakra-ui/react";
import { TextG12, TextG14} from "../../components/typography";
import {BlueButton} from "../../components/button";
import {useLocalStorage} from "@rehooks/local-storage";
import { IS_COOKIE_ACCEPTED } from "../../pages/Auth";
import ExternalLink from "../../components/ExternalLink";

// TODO pass modal sheet content from outside of this component.
const BottomSheetNotification = ({ notification }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const bottomModalSheetRef = useRef<BottomModalSheetRefProps>();
    const [_, setIsCookieAccepted] = useLocalStorage<boolean>(IS_COOKIE_ACCEPTED);

    useEffect(() => {
        bottomModalSheetRef?.current && bottomModalSheetRef.current?.setState(true);
        return () => {
            dispatch(BottomSheetNotificationActions.popNotification());
        }
    }, []);

    return (
      <BottomModalSheet
        ref={bottomModalSheetRef}
        customHeader={
          <TextG14 alignItems={'start'} fontWeight={700} color={theme.colors.lightBlack} py={1}>
            We Use Cookies
          </TextG14>
        }
        style={{padding: '24px'}}>
        <VStack spacing={0}>
          <TextG12 py={1} color={theme.colors.darkerGrey}>
            We use cookies to personalize your experience on our site. For more info, read our
            <ExternalLink
              isExternal
              href={`${process.env.CONDITIONS_WEB_PAGE_URL}/privacy-policy/`}
              color={theme.colors.blue}>
              {' '}
              Privacy Policy
            </ExternalLink>
            .
          </TextG12>
          <BlueButton
            w={'100%'}
            py={1}
            style={{marginTop: '12px'}}
            onClick={() => {
              setIsCookieAccepted(true);
              bottomModalSheetRef.current?.setState(false);
            }}>
            Okay
          </BlueButton>
        </VStack>
      </BottomModalSheet>
    );
};

export default BottomSheetNotification;
