import React, {useMemo} from 'react';
import {
  Accordion as ChkrAccordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react';
import {TopicOfInterest} from '../../../types/article';
import InterestTag from './InterestTag';

const ArticleInterestTags: React.FC<{
  interestTags: TopicOfInterest[];
  showName?: boolean;
}> = ({interestTags, showName = true}) => {
  return (
    <Flex
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
      gap={showName ? 2 : 1}
      w="100%">
      {interestTags.map((item) => {
        return (
          <InterestTag
            key={item.id}
            item={item}
            icon={item.icon}
            name={showName ? item.name : ''}
          />
        );
      })}
    </Flex>
  );
};

export default ArticleInterestTags;
