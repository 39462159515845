import {Box, Divider, Flex, HStack, useTheme, VStack} from '@chakra-ui/react';
import React from 'react';
import {TextG12, TextG14} from '../../../components/typography';
import {numberToCurrency, renderPointsToDollars} from '../../../utils/price';
import Header from './Header';
import {StyledCheckCircleFilled} from '../../../constants/eventOptions';
import {BlueButton} from '../../../components/button';
import TicketDetails from '../../TicketSelect/TicketDetailsContainer/TicketDetails';
import {useSelector} from 'react-redux';
import {getRandomElements} from '../../../utils/random';
import {purchaseEventTicket, purchasePass} from '../../../endpoints/api';
import PassCard from '../../MyPasses/PassCard';
import {ToastMessage} from '../../../components/toast';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {EventRSVPStatus, useSetAttendance} from '../../../hooks/useEvent';
import {OBSERVATORY_ID} from '../PaymentOptions/options';

function transformString(str) {
  return str
    .split(/[_\s]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const OrderSummary = ({setShowSummary, ticket, event, pass, setStripeLoading}) => {
  const theme = useTheme();
  const {
    appliedIds,
    qty,
    total,
    numPointsApplied,
    passDiscountAmount,
    cardPaymentAmount,
    willcallName,
    appliedPass,
    membershipDiscountPercentage,
    membershipDiscountAmount,
    originalTotal,
  } = useSelector((state: any) => state.checkout);
  const navigate = useNavigate();
  const {mutateAsync} = useSetAttendance();

  const isObservatoryEvent = event?.place_id == OBSERVATORY_ID;

  const handlePaymentClicked = async () => {
    setStripeLoading(true);
    if (event) {
      const tickets = getRandomElements(event.available_tickets, qty)?.map((ticket) => ticket.id);

      try {
        const {data} = await purchaseEventTicket({
          event_ticket_ids: [...tickets],
          points: numPointsApplied?.toFixed(0),
          cash: cardPaymentAmount?.toFixed(2),
          willcall_name: willcallName,
          ...(appliedPass && {pass_id: appliedPass.id}),
          success_url: `${window.location.origin}/tickets/${event.id}?success=1`,
          cancel_url: window.location.href,
        });

        if (!data.status && data.message) {
          ToastMessage({
            status: 'error',
            text: data.message,
          });
          setStripeLoading(false);
          return;
        }

        if (data && data?.url) {
          window.location.href = data.url;
          return;
        } else {
          navigate(`/tickets/${event.id}?success=1`, {replace: true});
          if (event.rsvp_status.status_value == EventRSVPStatus.NO_STATUS) {
            mutateAsync({
              eventId: event.id,
              prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
              status: EventRSVPStatus.GOING,
              avoidDispatchRSVPModalPopupActions: false,
            });
          }
        }
      } catch (e: any) {
        if (e && e.message) {
          ToastMessage({
            status: 'error',
            text: e?.message,
          });
          setStripeLoading(false);
        }
      }
    } else if (pass) {
      const purchasedPasses = await purchasePass({
        passId: pass.id,
        quantity: qty,
        successUrl: location.origin + `/pass/${pass.id}`,
        cancelUrl: location.origin + '/my-stuff?tab=Passes',
      });

      const stripeLink = purchasedPasses.data?.link;

      window.location.href = stripeLink;
    }
  };

  return (
    <>
      <Header setShowSummary={setShowSummary} />
      <VStack px={4} align="flex-start">
        <HStack justify="space-between" w="100%">
          <TextG14 fontWeight="700">Type: {ticket ? 'Tickets' : 'Pass'}</TextG14>
          <TextG14 fontWeight="700">{`Quantity: ${qty}`}</TextG14>
        </HStack>
        {ticket ? (
          <TicketDetails
            event={event}
            ticket={ticket}
            background="#F4F7F8"
            total={`${numberToCurrency(originalTotal)}`}
            discountedTotal={`${numberToCurrency(total)}`}
          />
        ) : (
          <PassCard
            pass={pass}
            showGetMore={false}
            showArrow={false}
            expires_at={dayjs.utc().add(1, 'year').format('YYYY-MM-DDTHH:mm')}
          />
        )}

        <Box w="100%">
          <TextG14 my="10px" fontWeight="700" color={theme.colors.darkGrey}>
            Payment Method
          </TextG14>

          {appliedIds.map((id) => (
            <Box
              lineHeight="12px"
              key={id}
              background={id == 'card_payment' ? '#FEF4E6' : '#E0F3E3'}
              px={4}
              py={3}
              borderRadius="8px"
              mb={2}>
              <TextG12 display="inline-flex" gap="10px">
                <StyledCheckCircleFilled w="16px" h="16px" fill="green" color="white" />
                {id == 'user_pass' ? `${appliedPass.pass.title} Pass` : transformString(id)}
              </TextG12>
            </Box>
          ))}
        </Box>
        {!isObservatoryEvent && (
          <VStack w="100%" align="flex-start" gap="5px">
            <TextG14 fontWeight="700">Summary</TextG14>
            <Divider color={theme.colors.lighterGrey} />
            <HStack fontWeight="500" justify="space-between" w="100%">
              <TextG12>Total Amount:</TextG12>
              <TextG12 color={theme.colors.red}>{`${numberToCurrency(originalTotal)}`}</TextG12>
            </HStack>
            {passDiscountAmount > 0 && (
              <HStack fontWeight="500" justify="space-between" w="100%">
                <TextG12>Pass:</TextG12>
                <TextG12 color="#329241">{`-${numberToCurrency(passDiscountAmount)}`}</TextG12>
              </HStack>
            )}
            {numPointsApplied > 0 && (
              <HStack fontWeight="500" justify="space-between" w="100%">
                <TextG12>{`Walkabout Points (${numPointsApplied}):`}</TextG12>
                <TextG12 color="#329241">{`-${renderPointsToDollars(
                  numPointsApplied,
                  ticket ? ticket.points_value : pass.denomination
                )}`}</TextG12>
              </HStack>
            )}
            {membershipDiscountPercentage > 0 && (
              <HStack fontWeight="500" justify="space-between" w="100%">
                <TextG12>{`Membership Discount(${membershipDiscountPercentage}%):`}</TextG12>
                <TextG12 color="#329241">{`-${numberToCurrency(
                  membershipDiscountAmount
                )}`}</TextG12>
              </HStack>
            )}
            {/* {cardPaymentAmount > 0 && (
            <HStack fontWeight="500" justify="space-between" w="100%">
              <TextG12>{`Credit/Debit card:`}</TextG12>
              <TextG12 color="#329241">{`-$${cardPaymentAmount.toFixed(2)}`}</TextG12>
            </HStack>
          )} */}
            <Divider color={theme.colors.lighterGrey} />
            <HStack fontWeight="500" justify="space-between" w="100%">
              <TextG12>{`Balance${
                cardPaymentAmount > 0 ? ` (Amount to be charged to card)` : ``
              }:`}</TextG12>
              <TextG12 color={cardPaymentAmount <= 0 ? '#329241' : theme.colors.red}>
                {cardPaymentAmount ? `${numberToCurrency(cardPaymentAmount)}` : `$0.00`}
              </TextG12>
            </HStack>
          </VStack>
        )}
      </VStack>
      <Flex w="100%" p={4} pos="fixed" bottom="0" background="white">
        <BlueButton
          w="100%"
          onClick={handlePaymentClicked}
          _hover={{backgroundColor: theme.colors.blue}} // for some reason hover state gets triggered on iOS
        >
          <TextG14>{cardPaymentAmount > 0 ? 'Continue to Card Payment' : 'Place Order'}</TextG14>
        </BlueButton>
      </Flex>
    </>
  );
};

export default OrderSummary;
