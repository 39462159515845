import { Box, useTheme } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import Countdown from 'react-countdown';
import { TextG14 } from '../../components/typography';
import { Event } from '../../types/acrticle';
import useCountdown from '../../hooks/useCountdown';

const EventCountdown = ({ event }: { event: Event }) => {
  const theme = useTheme();
  const { startTime, endTime, startPassed, setStartPassed, endPassed, setEndPassed } = useCountdown(event);

  const renderer = ({ days, hours, minutes, seconds, completed, formatted }) => {
    if (!startPassed) {
      return (
        <TextG14 fontWeight="500">
          Starts in {days ? `${formatted.days}d ` : ''}
          {hours ? `${formatted.hours}hr ` : ''}
          {minutes ? `${formatted.minutes}m ` : ''}
          {`${formatted.seconds}s`}
        </TextG14>
      );
    }

    if (!endPassed) {
      return (
        <TextG14 fontWeight="500">
          Ends in {days ? `${formatted.days}d ` : ''}
          {hours ? `${formatted.hours}hr ` : ''}
          {minutes ? `${formatted.minutes}m ` : ''}
          {`${formatted.seconds}s`}
        </TextG14>
      );
    }

    return <TextG14 fontWeight="500">Event Ended</TextG14>;
  };

  return (
    <Box
      textAlign="center"
      mt="20px"
      background={theme.colors.darkGrey}
      color="white"
      mx="-0.75rem"
      borderBottomRadius="0.5rem"
      p={3}>
      <Countdown
        overtime
        zeroPadTime={0}
        onComplete={() => {
          setStartPassed(dayjs().utc().isAfter(startTime.subtract(1, 'second')));
          setEndPassed(dayjs().utc().isAfter(endTime.subtract(1, 'second')));
        }}
        date={!startPassed ? startTime.valueOf() : endTime.valueOf()}
        renderer={renderer}
      />
    </Box>
  );
};

export default EventCountdown;
