import React, {useEffect, useMemo, useRef} from 'react';
import {HStack, useTheme, Grid, GridItem, VStack} from '@chakra-ui/react';
import {TextG10, TextG12, TextG14} from '../../typography';
import {generatePath, Link, useParams} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Partner} from '../../../types/acrticle';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import NonPremiumCard from '../../Card/NonPremiumCard';
import DefaultEventImage from '../../../assets/images/default_event.png';
import {usePlaceTypes} from '../../../hooks/usePlaceTypes';
import CardBadge from '../../CardBadge';
import PremiumCard from '../../Card/PremiumCard';

const PartnerCard = ({
  partner,
  leftTopItem,
  modal,
}: {
  partner?: Partner;
  leftTopItem?: any;
  modal?: boolean;
}) => {
  if (!partner) return null;
  const theme = useTheme();
  const {pathname} = useParams();
  const [ref, {wasEverVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();
  const {getPlaceTypeFromId} = usePlaceTypes();
  const placeTypeName = getPlaceTypeFromId(partner?.place_type_id)?.display_name;

  useEffect(() => {
    if (wasEverVisible && partner?.id.toString()) {
      addVisibleArticle({id: partner.id.toString(), type: 'partner'});
    }
  }, [wasEverVisible]);

  const image = useMemo(() => {
    if (partner) {
      return partner.media?.[0]?.full_url || DefaultEventImage;
    }
  }, [partner]);

  return (
    <Link
      style={{width: '100%'}}
      to={
        modal
          ? {pathname, search: `?id=${partner.id.toString()}&articleType=partner`}
          : generatePath(ROUTES.PARTNER, {
              partnerId: partner.id.toString(),
            })
      }
      onClick={() => storeScrollPositionInSession()}
      ref={ref}>
      {/* <CardBadge top="9px" w="145px" label="WALKABOUT PARTNER" center="true" /> */}

      <PremiumCard
        outline={'1px solid #FFC0C6'}
        height={'auto'}
        coverImage={image}
        leftTopItem={leftTopItem}
        childPaddingX={4}
        article={partner}>
        <CardBadge label="WALKABOUT PARTNER" w="145px" top="-12px" marginLeft="auto" />
        <Grid mt="-10px" height="100%" alignContent="space-between">
          <GridItem>
            <VStack align="start" pr={8} gap="5px">
              <TextG14
                isTruncated="&hellip;"
                noOfLines={null}
                fontWeight={700}
                color={theme.colors.black}>
                {partner.name}
              </TextG14>
              {partner.website && <TextG10 mt="0 !important" color={theme.colors.grey}>{partner.website}</TextG10>}
            </VStack>
          </GridItem>
          {placeTypeName && (
            <GridItem>
              <TextG10 color={theme.colors.grey}>{placeTypeName}</TextG10>
            </GridItem>
          )}
        </Grid>
      </PremiumCard>
    </Link>
  );
};

export default PartnerCard;
