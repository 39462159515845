import {
  AbsoluteCenter,
  Box,
  Container,
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  Image,
  useTheme,
  VStack,
  Textarea,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  usePrevious,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Header from '../../assets/images/create-meetup.png';
import {BlueButton} from '../../components/button';
import IceBreakerSelect from '../../components/IceBreakerSelect';
import Icon from '../../components/Icon';
import {TextG12, TextG14, TextG24} from '../../components/typography';
import {EventRSVPStatus, useSetAttendance} from '../../hooks/useEvent';
import {useCreateMeetup} from '../../hooks/useMeetUps';
import {Place, Event} from '../../types/article';
import PlaceSelectModal from './PlaceSelectModal';
import {addMinutes, startOfToday} from 'date-fns';
import HourSelect from './HourSelect';
import {useSearchParams} from 'react-router-dom';
import {fetchEvent, fetchPlace} from '../../endpoints/api';
import {BROADCAST_AUDIENCE, useBroadcastReach, useMeetupBroadcast} from '../../hooks/useBroadcast';
import BroadcastConfirmationModal from './BroadcastConfirmationModal';
import useAuth from '../../hooks/useAuth';
import PromptModal from '../../components/Modals/PromptModal';
import MeetUpCard from '../../components/Articles/MeetUpCard';
import {ToastMessage} from '../../components/toast';
import MeetupCreatedModal from './MeetupCreatedModal';
import {getDescriptionWithFooter, removeFooterFromDescription} from './MeetupForm';

// `Hey Neighbors! I'm heading ${
//           selectedPlace ? `to ${selectedPlace?.name}` : 'out'
//         } - drop by and say hi. - ${user?.name} (Sent via Walkabout Broadcast)

// P.S. If you can't attend but want to let ${
//           user?.name
//         } know you appreciate them for building community, react to this text with a ❤️!`

export const getBroadcastFooter = (userName: string) => {
  return `If you can't attend but want to let ${userName} know you appreciate them for building community, leave them a Heart by hitting the button above!`;
};

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const roundToNextEvenTime = (timeString: string): string => {
  let time = dayjs(timeString);
  const minutes = time.minute();
  let roundedMinutes = Math.ceil(minutes / 5) * 5;
  if (roundedMinutes === 60) {
    time = time.add(1, 'hour').minute(0);
  } else {
    time = time.minute(roundedMinutes);
  }
  return time.minute() === 0 ? time.format('h A') : time.format('h:mm A');
};

export const generateDescription = (
  placeName: string | undefined,
  startTime: string | undefined,
  userName: string | undefined
): string => {
  if (!userName) return '';
  const roundedTime = startTime ? roundToNextEvenTime(startTime) : null;
  return `Hey Neighbors! I'm heading ${placeName ? `to ${placeName}` : 'out'} ${
    roundedTime ? `at about ${roundedTime}` : ''
  } - drop by and say hi. - ${userName}`;
};

const CreateMeetup = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();
  const [searchParams] = useSearchParams();

  const [descriptionPromptOpen, setDescriptionPromptOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [meetupCreatedOpen, setMeetupCreatedOpen] = useState(false);
  const [createdEventId, setCreatedEventId] = useState(null);
  const [createdEvent, setCreatedEvent] = useState<Event>();

  const {mutateAsync} = useCreateMeetup();
  const setAttendance = useSetAttendance();

  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [selectedStartDate, setSelectedStartDate] = useState<dayjs.Dayjs>();

  const previousStartDate = usePrevious(startDate);

  const [title, setTitle] = useState(
    !!searchParams.get('placeId') ? '' : `${user?.name}'s Meet-Up`
  );
  const [description, setDescription] = useState<string>(
    !!searchParams.get('placeId')
      ? ''
      : generateDescription(selectedPlace?.name, startDate, user?.name)
  );

  const [iceBreaker, setIceBreaker] = useState<string>('Describe yourself in 3 words');
  const [customIceBreaker, setCustomIceBreaker] = useState<string>('');

  const [timeOption, setTimeOption] = React.useState('1');

  const [minTime, setMinTime] = useState<string>();
  const [maxTime, setMaxTime] = useState<string>();

  const [inviteGroup, setInviteGroup] = useState('1');
  const [eventDuration, setEventDuration] = useState(3);

  const [isSaving, setIsSaving] = useState(false);

  const {data: broadcastReach, isLoading: reachLoading} = useBroadcastReach({
    audienceRuleset:
      inviteGroup == '1'
        ? BROADCAST_AUDIENCE.ANYONE_NEARBY
        : BROADCAST_AUDIENCE.LOCAL_INSIDERS_CLUB,
    placeId: selectedPlace?.id.toString(),
  });
  const reachUserCount = broadcastReach?.data.user_count;

  const {mutateAsync: sendBroadcast, isLoading, isSuccess, error} = useMeetupBroadcast();

  const isValid =
    description &&
    startDate &&
    startDate.length > 0 &&
    selectedPlace &&
    (iceBreaker == 'custom' ? customIceBreaker : iceBreaker);

  const handleCreateMeetup = async () => {
    if (!isValid) {
      return;
    }

    setIsSaving(true);

    try {
      const start = timeOption == '1' ? dayjs() : startDate;
      const end = timeOption == '1' ? dayjs().add(eventDuration, 'hour') : endDate;

      const utcStartDate = dayjs(start).utc().format(DATE_FORMAT);
      const utcEndDate = dayjs(end).utc().format(DATE_FORMAT);

      const descriptionWithFooter = getDescriptionWithFooter(description, user?.name);

      const newMeetup = await mutateAsync({
        title: title,
        description: descriptionWithFooter,
        reason: iceBreaker == 'custom' ? customIceBreaker : iceBreaker,
        start_date_time: utcStartDate,
        end_date_time: utcEndDate,
        is_private: false,
        place_id: selectedPlace.id,
      });

      if (newMeetup.data?.event) {
        const createdEvent = newMeetup.data?.event;
        setCreatedEvent(createdEvent);
        await setAttendance.mutateAsync({
          eventId: createdEvent.id,
          prevStatus: EventRSVPStatus.NO_STATUS,
          status: EventRSVPStatus.GOING,
          showDispatchRSVPModalPopupActions: false,
          showToast: false,
        });

        setCreatedEventId(createdEvent.id);
        setMeetupCreatedOpen(true);

        setIsSaving(false);
      }
    } catch (e) {
      setIsSaving(false);
      ToastMessage({status: 'error', text: 'An error occurred. Please try again.'});
    }
  };

  const handleSendBroadcast = async (withBroadcast) => {
    if (!createdEventId) return;

    if (withBroadcast) {
      const broadcastMessage = removeFooterFromDescription(description, user?.name);

      try {
        setIsSaving(true);
        await sendBroadcast({
          eventId: createdEventId,
          audienceRuleset:
            inviteGroup == '1'
              ? BROADCAST_AUDIENCE.ANYONE_NEARBY
              : BROADCAST_AUDIENCE.LOCAL_INSIDERS_CLUB,
          message: broadcastMessage,
        });
        setIsSaving(false);
        navigate(`/meet-up/${createdEventId}`, {replace: true});
      } catch (e) {
        setIsSaving(false);
        ToastMessage({status: 'error', text: 'Failed to send broadcast. Please try again.'});
      }
    } else {
      navigate(`/meet-up/${createdEventId}`, {replace: true});
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (timeOption === '2' && inputRef.current) {
      inputRef.current?.focus();
      if (selectedStartDate) {
        setStartDate(selectedStartDate.format(DATE_FORMAT));
        setEndDate(selectedStartDate.add(Number(eventDuration), 'hour').format(DATE_FORMAT));
      }
    } else if (timeOption === '1') {
      const now = new Date();
      const closestInterval = Math.ceil(now.getMinutes() / 5) * 5;
      const nextAvailableTime = addMinutes(startOfToday(), now.getHours() * 60 + closestInterval);
      setStartDate(dayjs(nextAvailableTime).format(DATE_FORMAT));
    }
  }, [timeOption]);

  useEffect(() => {
    const now = new Date();
    const closestInterval = Math.ceil(now.getMinutes() / 5) * 5;
    const nextAvailableTime = addMinutes(startOfToday(), now.getHours() * 60 + closestInterval);
    setStartDate(dayjs(nextAvailableTime).format(DATE_FORMAT));
    setEndDate(dayjs(nextAvailableTime).add(3, 'hour').format(DATE_FORMAT));
    setMinTime(dayjs(nextAvailableTime).format(DATE_FORMAT));
    setMaxTime(dayjs(nextAvailableTime).add(6, 'weeks').format(DATE_FORMAT));
  }, []);

  useEffect(() => {
    setEndDate(dayjs(startDate).add(eventDuration, 'hour').format(DATE_FORMAT));
  }, [eventDuration, startDate]);

  useEffect(() => {
    if (!!searchParams.get('placeId')) {
      const placeId = Number(searchParams.get('placeId'));
      if (placeId) {
        fetchPlace({id: placeId}).then((res) => {
          setSelectedPlace(res.data);
        });
      }
    }
    if (!!searchParams.get('eventId')) {
      const eventId = Number(searchParams.get('eventId'));
      if (eventId) {
        fetchEvent({id: eventId}).then((res) => {
          setSelectedEvent(res.data);
        });
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedPlace) {
      const placeId = Number(searchParams.get('placeId'));
      if (selectedPlace.id !== placeId) {
        setDescriptionPromptOpen(true);
      } else {
        const placeName = selectedPlace?.name;
        setDescription(generateDescription(placeName, startDate, user?.name));
        !!!searchParams.get('eventId') && setTitle(`${user?.name}'s Meet-Up at ${placeName}`);
      }
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (selectedEvent) {
      const eventName = selectedEvent?.name || selectedEvent?.title;
      setTitle(`${user?.name}'s Meet-Up at ${eventName}`);
      if (dayjs.utc(selectedEvent.start_date_time).isAfter(dayjs().utc())) {
        setStartDate(dayjs.utc(selectedEvent.start_date_time).format(DATE_FORMAT));
        setTimeOption('2');
      }
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (startDate) {
      if (
        previousStartDate !== (null || undefined) &&
        startDate !== dayjs.utc(selectedEvent?.start_date_time).format(DATE_FORMAT)
      ) {
        setDescriptionPromptOpen(true);
      } else {
        const placeName = selectedPlace?.name;
        setDescription(generateDescription(placeName, startDate, user?.name));
      }
    }
  }, [startDate]);

  return (
    <Container p="2px 3px">
      <PromptModal
        buttonWidth="fit-content"
        title={`Change the description to:`}
        description={generateDescription(selectedPlace?.name, startDate, user?.name)}
        declineText="Keep Current Description"
        declineCallback={() => setDescriptionPromptOpen(false)}
        acceptCallback={() => {
          setDescription(generateDescription(selectedPlace?.name, startDate, user?.name));
          setDescriptionPromptOpen(false);
        }}
        acceptText="Use This Description"
        isOpen={descriptionPromptOpen}
      />
      <MeetupCreatedModal
        meet-up={
          {
            title,
            place: selectedPlace,
            user: {id: user?.id},
            start_date_time: dayjs(startDate).utc().format(DATE_FORMAT),
            is_meetup: true,
          } as Event
        }
        isOpen={meetupCreatedOpen}
        onSendBroadcast={() => {
          setMeetupCreatedOpen(false);
          setConfirmationOpen(true);
        }}
        onMaybeLater={() => {
          setMeetupCreatedOpen(false);
          navigate(`/meet-up/${createdEventId}`, {replace: true});
        }}
      />
      <BroadcastConfirmationModal
        isSaving={isSaving}
        description={description}
        reachNum={reachUserCount}
        sendBroadcast={handleSendBroadcast}
        confirmationOpen={confirmationOpen}
        setConfirmationOpen={setConfirmationOpen}
        setInviteGroup={setInviteGroup}
        inviteGroup={inviteGroup}
      />
      <VStack align="start" h="100%">
        <Box pos="relative">
          <AbsoluteCenter>
            <TextG24 color="white" fontWeight="700" whiteSpace="nowrap">
              Invite Locals to Meet-Up
            </TextG24>
          </AbsoluteCenter>
          <Image src={Header} />
          <Icon
            onClick={() => navigate(-1)}
            iconName="fi-rr-cross-small"
            style={{
              position: 'absolute',
              right: '18px',
              top: '13px',
              fontSize: '24px',
              height: '24px',
              width: '24px',
              color: 'white',
              fill: 'white',
            }}
          />
        </Box>

        <Box w="100%" px={3}>
          <MeetUpCard
            onClick={() => {}}
            meet-up={
              {
                title,
                place: selectedPlace,
                user: {id: user?.id},
                start_date_time: dayjs(startDate).utc().format(DATE_FORMAT),
                is_meetup: true,
              } as Event
            }
          />
        </Box>

        <VStack py={3} px={4} w="100%" gap="12px">
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Select a Place
              </TextG14>
            </FormLabel>
            <PlaceSelectModal selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} />
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Name Your Invitation
              </TextG14>
            </FormLabel>
            <Textarea
              resize="none"
              borderRadius="10px !important"
              minH="85px"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter a title"
              py={3}
              px={4}
              color={theme.colors.lightBlack}
              _placeholder={{
                color: theme.colors.lightestGrey,
                fontWeight: '500',
                fontSize: '12px',
                fontFamily: 'Gordita',
              }}
              border={`1px solid ${theme.colors.lighterGrey} !important`}
            />
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Describe Your Meet-Up
              </TextG14>
            </FormLabel>
            <Textarea
              resize="none"
              borderRadius="10px !important"
              minH="80px"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Tell your neighbors what you're doing and who to look for -- ex: I'm grabbing a beer by the bar, look for the green hat!"
              py={3}
              px={4}
              color={theme.colors.lightBlack}
              _placeholder={{
                fontFamily: 'Gordita',
                color: theme.colors.lightestGrey,
                fontWeight: '500',
                fontSize: '12px',
                paddingRight: '3rem',
              }}
              border={`1px solid ${theme.colors.lighterGrey} !important`}
            />
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Select a Time
              </TextG14>
            </FormLabel>
            <input type="hidden" id="timezone" name="timezone" value="-08:00" />
            <RadioGroup onChange={setTimeOption} value={timeOption}>
              <Stack direction="column">
                <Radio
                  colorScheme="green"
                  value="1"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <TextG14>Now</TextG14>
                </Radio>
                <Radio
                  colorScheme="green"
                  value="2"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <Flex flexDir="row" align="center" gap="10px">
                    <TextG14 whiteSpace="nowrap">Later</TextG14>
                    <Input
                      ref={inputRef}
                      isDisabled={timeOption != '2'}
                      max={maxTime}
                      min={minTime}
                      border="1px solid #E5E5E5 !important"
                      fontFamily="Gordita"
                      fontSize="12px !important"
                      fontWeight="500"
                      defaultValue={startDate}
                      onChange={(e) => {
                        const selectedDate = dayjs(e.target.value);
                        setSelectedStartDate(selectedDate);
                        setStartDate(selectedDate.format(DATE_FORMAT));
                        setEndDate(
                          selectedDate.add(Number(eventDuration), 'hour').format(DATE_FORMAT)
                        );
                      }}
                      onClick={(e) => {
                        inputRef.current?.focus();
                      }}
                      borderRadius="22.5px !important"
                      padding="9px 24px"
                      placeholder="Select Date and Time"
                      size="xs"
                      type="datetime-local"
                      height="auto"
                    />
                  </Flex>
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Flex flexDir="row" alignItems="stretch" gap="10px">
              <TextG14 color={theme.colors.lightBlack}>For</TextG14>
              <HourSelect
                hourSelected={eventDuration}
                onChange={(value) => setEventDuration(value)}
              />
              <TextG14 color={theme.colors.lightBlack}>
                {Number(eventDuration) == 1 ? 'Hour' : 'Hours'}
              </TextG14>
            </Flex>
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Ice Breaker
              </TextG14>
            </FormLabel>
            <FormHelperText mb={4}>
              <TextG12 color={theme.colors.grey}>
                Choose something to talk about before your meet-up so you can get to know each other
                before you hang out!
              </TextG12>
            </FormHelperText>
            <IceBreakerSelect
              iceBreaker={iceBreaker}
              setIceBreaker={setIceBreaker}
              customIceBreaker={customIceBreaker}
              setCustomIceBreaker={setCustomIceBreaker}
            />
          </FormControl>
          <BlueButton isDisabled={!isValid} onClick={handleCreateMeetup} w="100%" bottom={0}>
            <Flex gap="8px" alignItems="center">
              <TextG14>Create My Meet-Up</TextG14>
            </Flex>
          </BlueButton>
        </VStack>
      </VStack>
    </Container>
  );
};

export default CreateMeetup;
