export default [
  'Rabbit',
  'Panda',
  'Dolphin',
  'Llama',
  'Zebra',
  'Antelope',
  'Cardinal',
  'Chipmunk',
  'Parakeet',
  'Roadrunner',
  'Fox',
  'Giraffe',
  'Grasshopper',
  'Hamster',
  'Kangaroo',
  'Kiwi',
  'Koala',
  'Swordfish',
  'Wallaby',
  'Wren',
];
