import {Box, useTheme} from '@chakra-ui/react';
import React from 'react';
import useAuth from '../../hooks/useAuth';
import {TextG10} from '../typography';

const MembershipPill = ({overrideMembership}: {overrideMembership?: any}) => {
  const theme = useTheme();
  const {user} = useAuth();
  const membership = overrideMembership ? overrideMembership : user?.activeMembershipPlan;

  const membershipName = !membership ? 'Free' : membership.name;
  const backgroundColor = !membership ? '#F8EEDA' : membership.id == 1 ? '#4A92A2' : '#7D3EEE';
  const color = !membership ? 'black' : 'white';

  return (
    <Box>
      <TextG10
        color={color}
        background={backgroundColor}
        borderRadius="15px"
        px={3}
        py={1}
        fontWeight="500">{`${membershipName} Member`}</TextG10>
    </Box>
  );
};

export default MembershipPill;
