export const getDisplayPrice = (
  price: number,
  max_price?: number | null,
  price_override?: string | null
) => {
  if (price_override) {
    return price_override;
  }

  if (max_price && max_price !== price) {
    return `${numberToCurrency(price)} - ${numberToCurrency(max_price)}`;
  }

  if (isFree(price)) {
    return 'FREE';
  }

  return `${numberToCurrency(price)}`;
};

export const isFree = (price: number) => {
  return price == 0;
};

export const dollarsToPoints = (currency, pointRatio) => currency / pointRatio;
export const pointsToDollars = (pointAmount, pointRatio) => (pointAmount * pointRatio).toFixed(2);
export const renderPointsToDollars = (pointAmount, pointRatio) =>
  numberToCurrency(pointsToDollars(pointAmount, pointRatio));
export const numberToCurrency = (num) => {
  const formatted = Number(num).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // If the number has a ".00", remove the trailing zeros; otherwise, return as is
  return formatted.endsWith('.00')
    ? formatted.slice(0, -3) // Remove the ".00" part
    : formatted;
};

export function renderPointRatio(ratio) {
  // Calculate points needed for $1
  const points = 1 / ratio;

  // Format the result as 'X points = $1'
  return `${points} points = $1`;
}
