import React, {useEffect, useRef, useState} from 'react';
import {Flex, useTheme} from '@chakra-ui/react';
import {TopicOfInterest} from '../../../types/acrticle';
import {map, includes, orderBy, filter} from 'lodash';
import useAuth from '../../../hooks/useAuth';
import {TextG10} from '../../typography';
import styled, {css, keyframes} from 'styled-components';

const animateContainer = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const StyledFlex = styled(Flex)`
  ${(props) =>
    props.$autoScroll &&
    props.$numberOfTags &&
    css`
      animation: ${animateContainer} linear ${props.$numberOfTags * 4}s infinite;
    `}
`;

const CardInterestTags = ({
  topicsOfInterest,
  onlyShowMatched,
  autoScroll,
  width = '90%',
  tagsVisible,
  color,
}: {
  topicsOfInterest: TopicOfInterest[];
  onlyShowMatched?: boolean;
  autoScroll?: boolean;
  width?: string | number;
  tagsVisible?: boolean;
  color?: string;
}) => {
  //const topicSplice = useMemo(() => topicsOfInterest.slice(0, 4), [topicsOfInterest]);
  const [scrolled, setScrolled] = useState(0);
  const {user} = useAuth();
  const scrollerRef = useRef(null);
  const theme = useTheme();
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (scrollerRef && scrollerRef?.current && autoScroll) {
      setHasScroll(
        scrollerRef.current['offsetHeight'] < scrollerRef.current['scrollHeight'] ||
          scrollerRef.current['offsetWidth'] < scrollerRef.current['scrollWidth']
      );
    }
  }, [scrollerRef]);

  var topics = orderBy(
    map(
      filter(topicsOfInterest, (topic) => !topic.name.startsWith('Context:')),
      (topic) => {
        const matched = includes(
          map(user?.topics, (topic) => topic.name),
          topic.name
        );
        return {...topic, matched: matched};
      }
    ),
    ['matched', 'name'],
    ['desc', 'asc']
  );

  if (onlyShowMatched) {
    topics = filter(topics, 'matched');
  }

  return (
    <Flex
      style={{
        WebkitMaskImage:
          scrolled > 0
            ? `linear-gradient(90deg, #000 ${Math.min(80 + scrolled / 2, 100)}%, transparent)`
            : 'linear-gradient(90deg, #000 80%, transparent)',
        maskImage:
          scrolled > 0
            ? `linear-gradient(90deg, #000 ${Math.min(80 + scrolled / 2, 100)}%, transparent)`
            : 'linear-gradient(90deg, #000 80%, transparent)',
      }}
      onScroll={(e) => {
        const target = e.target as HTMLDivElement;
        setScrolled(target.scrollLeft);
      }}
      maxW={width}
      w={width}
      ref={scrollerRef}
      pos={'relative'}
      overflow={'hidden'}>
      <StyledFlex
        rounded="full"
        $autoScroll={hasScroll && tagsVisible}
        $numberOfTags={topics.length}
        color={color || theme.colors.grey}
        maxH={'auto'}
        whiteSpace="nowrap">
        {map(topics, (topic, index) => (
          <TextG10
            mr={2}
            key={`${topic.id}_${index}`}
            fontWeight={topic.matched ? 'bold' : 'normal'}>
            #{topic.name}
          </TextG10>
        ))}
      </StyledFlex>
      {hasScroll && tagsVisible && (
        <StyledFlex
          rounded="full"
          $autoScroll={hasScroll && tagsVisible}
          $numberOfTags={topics.length}
          color={color || theme.colors.grey}
          maxH={'auto'}
          whiteSpace="nowrap">
          {map(topics, (topic, index) => (
            <TextG10
              mr={2}
              key={`${topic.id}_${index}`}
              fontWeight={topic.matched ? 'bold' : 'normal'}>
              #{topic.name}
            </TextG10>
          ))}
        </StyledFlex>
      )}
    </Flex>
  );
};

export default CardInterestTags;
