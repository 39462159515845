import React from 'react';
import parse, {
  HTMLReactParserOptions,
  Element,
  Text,
  domToReact,
  DOMNode,
  Element as ReactElement,
} from 'html-react-parser';

// Define the types for component generation
export type ComponentGenerator = (props: any) => React.ReactNode;

export interface KeywordMapping {
  keyword: string; // The text pattern to look for (e.g. "[[applyButton]]")
  component: ComponentGenerator; // Function that returns the component
  props?: Record<string, any>; // Additional props to pass to the component
}

interface ParseOptions {
  preview?: boolean;
  setSignUpRequiredOpen?: any;
  keywordMappings: KeywordMapping[]; // Array of keyword mappings
}

// Helper to check if a node is a Text node with a specific keyword
const hasKeywordInTextNode = (node: DOMNode, keywords: string[]): node is Text => {
  if (!(node.type === 'text' && 'data' in node && typeof node.data === 'string')) {
    return false;
  }

  return keywords.some((keyword) => node.data.includes(keyword));
};

// Define types for our custom nodes
type TextNodeWithData = Text & {data: string};
type ComponentMarkerNode = {
  type: 'component-marker';
  key: string;
  keyword: string;
};
type CustomNode = TextNodeWithData | ComponentMarkerNode | DOMNode;

export const parseHtmlWithComponents = (html: string, options: ParseOptions) => {
  const {preview = false, setSignUpRequiredOpen, keywordMappings = []} = options;

  if (!html) return null;
  if (!keywordMappings.length) return <div dangerouslySetInnerHTML={{__html: html}} />;

  // Extract all keywords for easy checking
  const keywords = keywordMappings.map((mapping) => mapping.keyword);

  // Function to get component by keyword
  const getComponentForKeyword = (
    keyword: string,
    props: any = {}
  ): React.ReactElement | null | undefined => {
    const mapping = keywordMappings.find((m) => m.keyword === keyword);
    if (!mapping) return null;

    const component = mapping.component({
      ...mapping.props,
      ...props,
      preview,
      setSignUpRequiredOpen,
      key: `component-${Math.random()}`,
    });

    // Ensure we only return valid types for html-react-parser
    if (component === null || component === undefined) return component;
    if (React.isValidElement(component)) return component;

    // If it's a string or other ReactNode type, wrap it in a span
    return <span>{component}</span>;
  };

  const parserOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      // Case 1: Full paragraph containing only a keyword
      if (
        domNode instanceof Element &&
        domNode.name === 'p' &&
        domNode.children?.length === 1 &&
        domNode.children[0].type === 'text' &&
        'data' in domNode.children[0] &&
        typeof domNode.children[0].data === 'string'
      ) {
        const text = domNode.children[0].data.trim();
        const matchingKeyword = keywords.find((keyword) => text === keyword);

        if (matchingKeyword) {
          return getComponentForKeyword(matchingKeyword, {inline: false});
        }
      }

      // Case 2: Direct text node containing any keyword
      if (hasKeywordInTextNode(domNode, keywords)) {
        // Find which keywords are in this text
        let result: React.ReactNode[] = [];
        let remainingText = domNode.data;

        // Process multiple keywords in the same text node
        while (remainingText) {
          const keywordPositions = keywords
            .map((keyword) => ({
              keyword,
              position: remainingText.indexOf(keyword),
            }))
            .filter((kw) => kw.position !== -1)
            .sort((a, b) => a.position - b.position);

          if (keywordPositions.length === 0) {
            // No more keywords in this text
            if (remainingText) {
              result.push(
                <React.Fragment key={`text-${result.length}`}>{remainingText}</React.Fragment>
              );
            }
            break;
          }

          const {keyword, position} = keywordPositions[0];

          // Add text before the keyword
          if (position > 0) {
            result.push(
              <React.Fragment key={`text-${result.length}`}>
                {remainingText.substring(0, position)}
              </React.Fragment>
            );
          }

          // Add the component for this keyword
          result.push(getComponentForKeyword(keyword));

          // Update the remaining text
          remainingText = remainingText.substring(position + keyword.length);
        }

        // Return as an element, not a fragment directly
        return React.createElement(React.Fragment, null, ...result);
      }

      // Case 3: Handle elements that might contain keywords in children
      if (domNode instanceof Element && domNode.children) {
        // Check if any direct text child contains our keywords
        const hasKeywordsInChildren = domNode.children.some((child) =>
          hasKeywordInTextNode(child, keywords)
        );

        if (hasKeywordsInChildren) {
          // Create a modified version of children with components injected
          const newChildren: CustomNode[] = [];

          for (const child of domNode.children) {
            if (hasKeywordInTextNode(child, keywords)) {
              let remainingText = child.data;
              let lastIndex = 0;

              // Find all keywords in this text node
              while (remainingText) {
                const keywordPositions = keywords
                  .map((keyword) => ({
                    keyword,
                    position: remainingText.indexOf(keyword),
                  }))
                  .filter((kw) => kw.position !== -1)
                  .sort((a, b) => a.position - b.position);

                if (keywordPositions.length === 0) {
                  // No more keywords in this text
                  if (remainingText) {
                    newChildren.push({
                      ...child,
                      data: remainingText,
                    } as TextNodeWithData);
                  }
                  break;
                }

                const {keyword, position} = keywordPositions[0];

                // Add text before the keyword
                if (position > 0) {
                  newChildren.push({
                    ...child,
                    data: remainingText.substring(0, position),
                  } as TextNodeWithData);
                }

                // Add a marker for the component position
                newChildren.push({
                  type: 'component-marker',
                  key: `marker-${lastIndex++}`,
                  keyword: keyword,
                } as ComponentMarkerNode);

                // Update the remaining text
                remainingText = remainingText.substring(position + keyword.length);
              }
            } else {
              newChildren.push(child);
            }
          }

          // Now process the new children array, replacing markers with actual components
          const tagName = domNode.name;

          // Use React.createElement instead of JSX for dynamic tag names
          try {
            const childElements = newChildren
              .map((newChild, idx) => {
                if (newChild.type === 'component-marker') {
                  const markerNode = newChild as ComponentMarkerNode;
                  return getComponentForKeyword(markerNode.keyword, {key: `component-${idx}`});
                }
                // Cast to DOMNode to ensure type compatibility
                return domToReact([newChild as DOMNode], parserOptions);
              })
              .filter(Boolean);

            return React.createElement(tagName, domNode.attribs, ...childElements);
          } catch (err) {
            console.error('Error creating element:', err);
            return undefined;
          }
        }
      }

      return undefined;
    },
  };

  try {
    return parse(html, parserOptions);
  } catch (error) {
    console.error('Error parsing HTML with components:', error);
    return <div dangerouslySetInnerHTML={{__html: html}} />; // Fallback to plain html if parsing fails
  }
};
