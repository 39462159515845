import {ToolTipFunction} from '.';
import useAuth from '../../hooks/useAuth';
import {TooltipProps} from './Tooltip';

const HomeTooltips: ToolTipFunction = (nextStep, prevStep, finish, setConfettiExploding, theme) => {
  const TOOLTIP_STEPS: TooltipProps[] = [
    {
      anchorId: 'none',
      title: `Let’s dive in!`,
      message: `Here’s how to have the best experience with your Walkabout app.`,
      place: 'bottom',
      onCtaClick: nextStep,
      buttonPrev: 'Skip',
      onPrevClick: finish,
      onCloseClick: finish,
      prevButtonStyle: {color: theme.colors.blue, background: 'transparent'},
      closePath: '/home',
    },
    // {
    //   anchorId: 'dockbar-tooltip',
    //   highlightId: 'dockbar-tooltip-highlight',
    //   pulseId: 'dockbar-tooltip',
    //   title: 'Walkabout Button',
    //   message:
    //     'Use the ‘W’ button to see local spots nearby that you can support and check-in at to earn points.',
    //   place: 'top',
    //   onCtaClick: nextStep,
    //   onPrevClick: prevStep,
    //   onCloseClick: finish,
    //   closePath: '/home',
    // },
    // {
    //   anchorId: 'hero-tooltip',
    //   highlightId: 'hero-tooltip',
    //   title: 'Curated by Walkabout',
    //   message:
    //     'From grand openings to local meetups, here’s where we’ll share new ways you can connect with your community.',
    //   place: 'bottom',
    //   color: 'white',
    //   onCtaClick: nextStep,
    //   onPrevClick: prevStep,
    //   onCloseClick: finish,
    //   closePath: '/home',
    // },
    {
      anchorId: 'home-rsvp-tooltip',
      highlightId: 'rsvp-tooltip-highlight',
      pulseId: 'rsvp-tooltip-highlight',
      title: 'RSVP for events',
      message: 'Plan your week and get updates about events you’re interested in or going to.',
      place: 'top',
      color: 'white',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: '/home',
    },
    {
      anchorId: 'points-tooltip',
      highlightId: 'topbar-tooltip-highlight',
      pulseId: 'points-tooltip',
      title: 'Points',
      message: 'Earn points by connecting with new places and people.',
      place: 'bottom',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: '/home',
    },
    {
      anchorId: 'feed2-tooltip',
      highlightId: 'feed2-tooltip-highlight',
      pulseId: 'feed2-tooltip-highlight',
      title: 'Perks',
      message: 'Be involved, and get rewarded. Trade the points you earn to get perks.',
      place: 'top',
      color: 'white',
      arrowLeft: '40px',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: '/home',
    },
    {
      anchorId: 'quick-links-tooltip',
      highlightId: 'quick-links-tooltip',
      pulseId: 'quick-links-tooltip',
      title: 'Quick Buttons',
      message: 'Click these buttons to discover all the events, perks, food, and bars nearby.',
      place: 'top',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: '/home',
    },
    {
      anchorId: 'help-tooltip',
      highlightId: 'dockbar-tooltip-highlight',
      pulseId: 'help-tooltip',
      title: 'Talk to Us',
      message: 'Have a question or want to give us your two-cents? Chat with us here.',
      place: 'top',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: '/home',
    },
    {
      anchorId: 'chat-tooltip',
      highlightId: 'dockbar-tooltip-highlight',
      pulseId: 'chat-tooltip',
      title: 'Event Chat',
      message: 'Use Chat to connect with other people interested or going to the same events.',
      place: 'top',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: '/home',
    },
    {
      anchorId: 'stuff-tooltip',
      highlightId: 'dockbar-tooltip-highlight',
      pulseId: 'stuff-tooltip',
      title: 'My Stuff',
      message: 'Your perks and bookmarks are saved here.',
      place: 'top',
      onCtaClick: nextStep,
      onPrevClick: prevStep,
      onCloseClick: finish,
      closePath: '/home',
    },
    {
      anchorId: 'none',
      title: 'Let’s Go!',
      message: `You're ready to begin your Walkabout journey.`,
      place: 'bottom',
      buttonCta: 'Done',
      onCtaClick: finish,
      onPrevClick: prevStep,
      onCloseClick: finish,
      onShow: () => setConfettiExploding(true),
      closePath: '/home',
    },
  ];

  return TOOLTIP_STEPS;
};

export default HomeTooltips;
