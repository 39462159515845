import { dayMap, PLACE_STATUS } from "../constants/openingHours";
import { DaysOfTheWeek, OpeningHours } from "../types/article";
import { OpenHour } from "../components/OpenHours";

export type PlaceStatus =
  | PLACE_STATUS.OPEN
  | PLACE_STATUS.CLOSED
  | PLACE_STATUS.OPENING_SOON
  | PLACE_STATUS.CLOSING_SOON
  | PLACE_STATUS.UNKNOWN
  | PLACE_STATUS.TEMPORARILY_CLOSED;

export function getOpeningHoursStatus(
  currDate: Date,
  openingHours: OpeningHours,
  nextStart: string
) {
  const [currHours, currMinutes] = [currDate.getHours(), currDate.getMinutes()];

  if (openingHours.length === 0)
    return { status: PLACE_STATUS.CLOSED, timeMsg: nextStart };

  let [start, end] = openingHours[0].split("-");

  let [startHours, startMinutes] = start
    .split(":")
    .map((hour) => Number.parseInt(hour));

  let [endHours, endMinutes] = end
    .split(":")
    .map((hour) => Number.parseInt(hour));

  if (
    currHours < startHours ||
    (currHours === startHours && currMinutes < startMinutes)
  )
    return { status: PLACE_STATUS.CLOSED, timeMsg: `Opens at ${start}` };

  if (
    currHours < endHours ||
    (currHours === endHours && currMinutes <= endMinutes)
  ) {
    if (openingHours.length === 1)
      return { status: PLACE_STATUS.OPEN, timeMsg: `Closes at ${end}` };
    else
      return {
        status: PLACE_STATUS.OPEN,
        timeMsg: `Temporarily closes at ${end}`,
      };
  }

  if (openingHours.length !== 2)
    return { status: PLACE_STATUS.CLOSED, timeMsg: nextStart };

  [start, end] = openingHours[1].split("-");

  [startHours, startMinutes] = start
    .split(":")
    .map((hour) => Number.parseInt(hour));

  [endHours, endMinutes] = end.split(":").map((hour) => Number.parseInt(hour));

  if (
    currHours < startHours ||
    (currHours === startHours && currMinutes < startMinutes)
  )
    return {
      status: PLACE_STATUS.TEMPORARILY_CLOSED,
      timeMsg: `Opens at ${end}`,
    };

  if (
    currHours < endHours ||
    (currHours === endHours && currMinutes <= endMinutes)
  )
    return { status: PLACE_STATUS.OPEN, timeMsg: `Closes at ${end}` };

  return { status: PLACE_STATUS.CLOSED, timeMsg: nextStart };
}

export function getNextDayOpeningHours(
  date: number,
  week: DaysOfTheWeek | string
) {
  const dayName = dayMap.get(date);
  if (dayName === undefined) return;
  const openingHours = week[dayName] as OpeningHours;

  if (openingHours.length === 0)
    return getNextDayOpeningHours(date < 7 ? date + 1 : 1, week);

  return `Opens ${dayName.charAt(0).toUpperCase() + dayName.slice(1)} at ${
    openingHours[0].split("-")[0]
  }`;
}

export const getOpenHours = (weekdayText: string[]) => {
  const currDate = new Date();
  const dayOfTheWeek = dayMap.get(currDate.getDay());

  const openingHoursRegExp = /([A-Za-z]*)\s?:\s?(.*)/;

  const convertedWorkDays = weekdayText.map((weekDay) => {
    const convertedWorkDay: any = weekDay.match(openingHoursRegExp);
    return {
      day: convertedWorkDay[1],
      hours: convertedWorkDay[2],
      current: false,
    };
  });


  const currentDayIndex = convertedWorkDays.findIndex((openHour) => {
    return openHour.day.toLowerCase() === dayOfTheWeek;
  });

  const slicedOpenHours = convertedWorkDays.slice(currentDayIndex);

  const sortedOpenHours = slicedOpenHours
    .concat(convertedWorkDays.slice(0, currentDayIndex))
    .map((openHour) => {
      const formatedOpenHour = { ...openHour };
      if (openHour.day.toLowerCase() === dayOfTheWeek) {
        formatedOpenHour.day = "Today";
        formatedOpenHour.current = true;
      }
      return formatedOpenHour;
    });

  return sortedOpenHours;
};

export const getConvertedOpenHours = (
  openHours: string | DaysOfTheWeek
): OpenHour[] => {
  const currDate = new Date();
  const dayOfTheWeek = dayMap.get(currDate.getDay());

  const openingHoursRegExp =
    /([A-Za-z]*)\s?-?\s?(([0-9][0-9]?:?[0-9]?[0-9]?\s?(AM|PM)?)\s?-?\u2013?\s?([0-9]?[0-9]?:?[0-9]?[0-9]?\s?(AM|PM)?)|Closed)/g;

  const openingHours: any =
    typeof openHours == "string"
      ? [...openHours.matchAll(openingHoursRegExp)]
      : [];

  const convertedOpenHours = openingHours
    .filter((open) => {
      if (!open[1]) return false;
      return true;
    })
    .map((open) => {
      return {
        day: open[1],
        openTime: open[2] === PLACE_STATUS.CLOSED ? open[2] : open[3],
        closeTime: open[2] === PLACE_STATUS.CLOSED ? "" : open[5],
        current:
          open[1].toLowerCase() === dayOfTheWeek ||
          open[1].toLowerCase() === (dayOfTheWeek as string).slice(0, 3),
        closed: open[2] === PLACE_STATUS.CLOSED,
      };
    });

  const currentDayIndex = convertedOpenHours.findIndex((openHour) => {
    return (
      openHour.day.toLowerCase() === dayOfTheWeek ||
      openHour.day.toLowerCase() === (dayOfTheWeek as string).slice(0, 3)
    );
  });
  const slicedOpenHours = convertedOpenHours.slice(currentDayIndex);

  const sortedOpenHours = slicedOpenHours
    .concat(convertedOpenHours.slice(0, currentDayIndex))
    .map((openHour) => {
      if (
        openHour.day.toLowerCase() === dayOfTheWeek ||
        openHour.day.toLowerCase() === (dayOfTheWeek as string).slice(0, 3)
      )
        openHour.day = "Today";
      return openHour;
    });

  return sortedOpenHours;
};

export const getCurrentDateHours = (openHours: OpenHour[]): OpenHour => {
  return openHours.filter((openHour) => openHour.current)[0];
};

export const getOpenHoursStatus = (
  openHour: any
): PlaceStatus | undefined => {
  if (!openHour || !openHour.openTime || !openHour.closeTime)
    return PLACE_STATUS.CLOSED;
  const currDate = new Date(),
    endDate = new Date();

  if (openHour.openTime === PLACE_STATUS.CLOSED) return PLACE_STATUS.CLOSED;

  const start = openHour.openTime.replace(/(:\d{1,2})\s?/g, '').match(/\d+|\D+/g);
  const end = openHour.closeTime.replace(/(:\d{1,2})\s?/g, '').match(/\d+|\D+/g);

  if (end && end[1] && end[1] == "AM") endDate.setDate(currDate.getDate() + 1);

  const startUnix = new Date(
    `${
      currDate.getMonth() + 1
    }/${currDate.getDate()}/${currDate.getFullYear()} ${
      start && start[0]
    }:00:00 ${start && start[1] ? start[1] : ""}`
  ).getTime();

  const endUnix = new Date(
    `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()} ${
      end && end[0]
    }:00:00 ${end && end[1] ? end[1] : ""}`
  ).getTime();

  if (currDate.getTime() >= startUnix && currDate.getTime() <= endUnix) {
    return PLACE_STATUS.OPEN;
  }

  if (currDate.getTime() < startUnix || currDate.getTime() > endUnix) {
    return PLACE_STATUS.CLOSED;
  }
};
