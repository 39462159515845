import React from 'react';
import {Center, Spinner, useTheme} from "@chakra-ui/react";

export const SpinnerLoader = (props) => {
  const theme = useTheme();

  return (
    <Center {...props}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor={theme.colors.lightGrey}
        color={theme.colors.lightBlue}
        size="lg"
      />
    </Center>
  )
}