import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  usePrevious,
  useTheme,
} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useAuth from '../../../hooks/useAuth';
import {Community} from '../../../types/community';
import {isStandalone, isIphone} from '../../../utils/deviceCheck';
import {BlueButton} from '../../button';
import Icon from '../../Icon';
import {ToastMessage} from '../../toast';
import {TextG10, TextG14, TextG18} from '../../typography';
import UserAvatar from '../../UserAvatar';
import ChatComment, {ChatComment as ChatCommentInterface} from '../ChatComment';
import CommentInput from '../ChatComment/CommentInput';
import ChatNotificationsNudge from '../ChatNotificationsNudge';


const ThreadTab = ({
  name,
  articleId,
  isRSVPd,
  headerHeight,
  fetchCommentsHook,
  fetchUsersHook,
  addComment,
  updateComment,
  deleteComment,
  reportComment,
  helpfulComment,
  showUserRSVP = true,
  noMessageComponent = (
    <Flex flexDir="column" gap={8}>
      <Icon iconName="fi-rr-comment" style={{fontSize: '48px', color: '#F4F4F4'}} />
      <TextG14 fontWeight="500">
        Welcome to this exclusive event chat just for attendees!
        <br />
        <br />
        Make the most out of your experience by asking questions, sharing your excitement about the
        event, or planning meet-ups with like-minded locals on Walkabout.
      </TextG14>
    </Flex>
  ),
}: {
  name: string;
  articleId?: number | undefined;
  isRSVPd: boolean;
  community?: Community;
  headerHeight: number;
  fetchCommentsHook: any;
  fetchUsersHook?: any;
  addComment: (content: string, parent_id?: string, imageURLs?: Array<any>) => void;
  updateComment: (commentId: string, content: string, parent_id?: string) => void;
  deleteComment: (commentId: string) => void;
  reportComment: (commentId: string) => void;
  helpfulComment: (commentId: string) => void;
  showUserRSVP?: boolean;
  noMessageComponent?: JSX.Element;
}) => {
  const theme = useTheme();
  const fetchComments = articleId ? fetchCommentsHook(articleId) : fetchCommentsHook();
  const [eventComments, setEventComments] = useState<Array<ChatCommentInterface> | null>(null);
  const previousComments = usePrevious(eventComments);
  const [newLatestComment, setNewLastestComment] = useState<ChatCommentInterface | null>(null);
  const [latestRef, {rootRef, isVisible, wasEverVisible}] = useTrackVisibility();
  const bottomRef = useRef<HTMLDivElement>(null);
  const [scrollPos, setScrollPos] = useState(0);
  const {user} = useAuth();
  //const addCommunityComment = useCreateCommunityComment();
  const [inputOpen, setInputOpen] = useState(false);
  const [inputHidden, setInputHidden] = useState(false);
  const prevOpen = usePrevious(inputOpen);
  const prevHidden = usePrevious(inputHidden);

  const shouldShowNotificationNudge = !!!(
    user?.emailEnabled &&
    user.smsEnabled &&
    user.pushEnabled
  );

  const [bottomBrowserOffset, setBottomBrowserOffset] = useState(!isStandalone() ? 83 : 0);

  // const users = uniqBy(
  //   map(eventComments, (comment) => {
  //     return {
  //       id: comment.user.id,
  //       display: comment.user.handle,
  //       avatar: get(comment.user, 'profile_photo_url'),
  //     };
  //   }),
  //   (c) => c.id
  // );

  const handleAddComment = (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    addComment(content, parent_id, imageURLs);
    setInputOpen(false);
  };

  useEffect(() => {
    if (!isStandalone() && ((prevHidden && !inputHidden) || (!prevOpen && inputOpen))) {
      setBottomBrowserOffset(0);
    }
  }, [inputOpen, inputHidden]);

  // const isRVSPd =
  //   event &&
  //   event.rsvp_status.status_value &&
  //   event.rsvp_status.status_value != EventRSVPStatus.NOT_INTERESTED;

  useEffect(() => {
    if (!fetchComments.data || !fetchComments.data.pages) return;
    const comments: Array<ChatCommentInterface> = [];
    fetchComments.data.pages.map((p) => p.data.data.map((comment) => comments.push(comment)));
    setEventComments(comments);
  }, [fetchComments.data?.pages]);

  useEffect(() => {
    if (isVisible) {
      setNewLastestComment(null);
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      previousComments &&
      eventComments &&
      previousComments[0] &&
      eventComments[0] &&
      previousComments[0].id != eventComments[0].id
    ) {
      if (eventComments[0].user?.id == user?.id || scrollPos >= -100) {
        bottomRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      } else {
        setNewLastestComment(eventComments[0]);
      }
    }
  }, [previousComments, eventComments]);

  if (fetchComments.isLoading)
    return (
      <Center h="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <Box overflow="hidden !important">
      {newLatestComment && !inputHidden && !inputOpen && (
        <BlueButton
          onClick={() =>
            bottomRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          }
          style={{
            zIndex: '1400',
            position: 'fixed',
            bottom: '100px',
            width: '33.3%',
            left: '33.3%',
            padding: '0px 5px',
          }}>
          <Flex gap={2} align="center">
            <TextG10>Latest Messages</TextG10>
            <Icon style={{fontSize: '10px'}} iconName="fi-rr-arrow-down" />
          </Flex>
        </BlueButton>
      )}
      {!inputHidden && (
        <Box
          borderTop={`1px solid ${theme.colors.lightGrey}`}
          background="white"
          w="100%"
          position="fixed"
          bottom="0px"
          zIndex="1399"
          left={0}
          px="5%"
          pt={1}
          pb={3}>
          {articleId && shouldShowNotificationNudge && user?.hasEarlyAccess && (
            <ChatNotificationsNudge articleId={articleId} />
          )}
          <Flex my={3} align="center" gap={1.5}>
            {!inputOpen && (
              <>
                <UserAvatar user={user} size="sm" />
                <Button
                  onClick={() => {
                    if (!isRSVPd) {
                      ToastMessage({
                        status: 'error',
                        text: 'You must RSVP to this event to participate in chat.',
                      });
                    } else {
                      setInputOpen(true);
                    }
                  }}
                  _selected={{}}
                  _hover={{}}
                  px={2}
                  py={1}
                  h="100%"
                  fontWeight="400"
                  w="100%"
                  background="transparent"
                  borderRadius="15px"
                  border="1px solid rgb(226, 232, 240)"
                  color="grey"
                  textAlign="left"
                  justifyContent="left">
                  Message Everyone
                </Button>
              </>
            )}
          </Flex>
          {inputOpen && (
            <CommentInput
              fetchUsersHook={fetchUsersHook}
              articleId={articleId}
              onCloseClick={() => setInputOpen(false)}
              onSaveClick={handleAddComment}
            />
          )}
        </Box>
      )}

      {!eventComments ? (
        <Center
          h={`calc(100vh - ${inputHidden ? '0px' : '75px'} - ${headerHeight}px)`}
          mt={`${headerHeight}px`}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </Center>
      ) : eventComments.length > 0 || inputOpen ? (
        <Flex
          overscrollBehavior="none !important"
          flexDir="column-reverse"
          id="scrollableDiv"
          overflowY="auto"
          height={`calc(100vh - ${inputHidden ? '0px' : '75px'} - ${headerHeight}px)`}
          mt={`${headerHeight}px`}
          px={4}
          //pb={inputOpen ? '83px' : 0}
        >
          <InfiniteScroll
            onScroll={(e) => setScrollPos((e.target as HTMLDivElement).scrollTop || 0)}
            scrollThreshold={'500px'}
            scrollableTarget="scrollableDiv"
            dataLength={eventComments.length}
            next={fetchComments.fetchNextPage}
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              paddingBottom: inputOpen && !(isStandalone() && isIphone) ? '30%' : 0,
            }} //To put endMessage and loader to the top.
            inverse={true}
            hasMore={fetchComments?.hasNextPage || false}
            loader={
              <Center py="30px">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor={theme.colors.lightGrey}
                  color={theme.colors.lightBlue}
                  size="lg"
                />
              </Center>
            }>
            <Box h="1px" ref={bottomRef} />
            {eventComments.map((comment, i) => (
              <Box key={comment.id} ref={comment.id == newLatestComment?.id ? latestRef : null}>
                <ChatComment
                  fetchUsersHook={fetchUsersHook}
                  addComment={addComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  reportComment={reportComment}
                  helpfulComment={helpfulComment}
                  comment={comment}
                  articleId={articleId}
                  name={name}
                  isRSVPd={isRSVPd}
                  setInputOpen={setInputOpen}
                  setInputHidden={setInputHidden}
                  showUserRSVP={showUserRSVP}
                />
              </Box>
            ))}
          </InfiniteScroll>
        </Flex>
      ) : (
        <AbsoluteCenter
          px={4}
          style={{position: 'fixed'}}
          w="100%"
          textAlign="center"
          color={theme.colors.grey}>
          {noMessageComponent}
        </AbsoluteCenter>
      )}
    </Box>
  );
};

export default ThreadTab;
