import {
  Flex,
  useTheme,
  Box,
  HStack,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
} from '@chakra-ui/react';
import React from 'react';
import {H2, TextG10, TextG12} from '../../../components/typography';
import useGetUserLocation from '../../../hooks/useUserLocation';
import DotSeparator from '../../../components/DotSeparator';

import MeetupRSVPButton from './MeetupRSVPButton';
import IconsHeader from './IconHeader';
import {getDistanceString, calculateAndGetDistance} from '../../../utils/distance';
import {getEventDate} from '../../../utils/date';
import UserAvatar from '../../../components/UserAvatar';
import {useNavigate} from 'react-router-dom';
import {useFetchEventRSVPStats} from '../../../hooks/useEvent';
import EditableControls from '../../../components/EditableControls';
import {useUpdateMeetUp} from '../../../hooks/useMeetUps';

const Header: React.FC<{event; isHost}> = ({event, isHost}) => {
  const theme = useTheme();
  const userLocation = useGetUserLocation();
  const navigate = useNavigate();
  const {data} = useFetchEventRSVPStats(event.id);
  const rsvp_stats = data?.data.rsvp_stats;
  const usersGoing = rsvp_stats?.GOING;
  const {mutateAsync} = useUpdateMeetUp();

  const eventDate = getEventDate(event.start_date_time, event.end_date_time);

  return (
    <Flex
      position="relative"
      top="0"
      width="90%"
      marginLeft="5%"
      transform="auto"
      translateY="-50%"
      marginBottom="-8rem"
      backgroundColor="white"
      boxShadow={`0 0 12px ${theme.colors.grey}`}
      borderRadius=".5rem"
      p="0.75rem"
      flexDirection="column"
      gap="18px"
      pb="calc(1.25rem + 48px)">
      <Flex direction="column" gap="8px">
        <TextG10
          fontWeight="500"
          color={theme.colors.grey}
          maxW="150px"
          isTruncated="&hellip;"
          noOfLines={1}>
          {eventDate}
        </TextG10>
        {isHost ? (
          <Editable
            onSubmit={(value) => {
              mutateAsync({
                ...event,
                title: value,
                place_id: event.place?.id,
                eventId: event.id,
              });
            }}
            pos="relative"
            w="100%"
            fontSize="24px"
            lineHeight="28px"
            fontFamily="Aileron"
            fontWeight="900"
            defaultValue={event.title}
            isPreviewFocusable={false}
            selectAllOnFocus={false}>
            <EditablePreview />
            <EditableInput />
            <EditableControls top="-25px" />
          </Editable>
        ) : (
          <H2>{event.title}</H2>
        )}
      </Flex>
      {event.place && (
        <Flex direction="row" align="center">
          <TextG12
            onClick={() => navigate(`/place/${event.place.id}`)}
            color={theme.colors.blue}
            width="max-content">
            {event.place.name}
          </TextG12>
          {userLocation.data?.latitude &&
            userLocation.data.longitude &&
            event.place?.latitude &&
            event.place?.longitude && (
              <>
                <DotSeparator width="3px" height="3px" color={theme.colors.grey} />
                <TextG12 color={theme.colors.grey}>
                  {getDistanceString(
                    calculateAndGetDistance(
                      {
                        latitude: userLocation.data?.latitude,
                        longitude: userLocation.data?.longitude,
                      },
                      {
                        latitude: Number(event.place.latitude),
                        longitude: Number(event.place.longitude),
                      }
                    )
                  )}
                </TextG12>
              </>
            )}
        </Flex>
      )}
      {usersGoing > 0 && (
        <TextG12 whiteSpace="nowrap" color={theme.colors.grey}>{`${usersGoing} Going`}</TextG12>
      )}

      <MeetupRSVPButton event={event} />
      <IconsHeader event={event} />

      <Box
        display="flex"
        justifyContent="center"
        borderBottomRadius=".5rem"
        w="100%"
        left={0}
        bottom={0}
        pos="absolute"
        py="10px"
        background={theme.colors.lightBlack}>
        <HStack>
          <UserAvatar size="xs" user={event.user} />
          <TextG12 fontWeight="500" color="white">
            {isHost
              ? `You’re hosting this Meet-Up`
              : `${event.user.handle} is hosting this Meet-Up`}
          </TextG12>
        </HStack>
      </Box>
    </Flex>
  );
};

export default Header;
