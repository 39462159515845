import {Image, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import Modal from '../../../../components/Modals/Modal';
import Logo from '../../../../assets/logos/logo_57x57.png';
import {TextG10, TextG14} from '../../../../components/typography';
import {BlueButton, WhiteButton} from '../../../../components/button';

const SkipInterestsModal = ({handleClickBlueButton}) => {
  const [showModal, setShowModal] = useState(true);

  const header = (
    <VStack gap="16px">
      <Image src={Logo} />
      <TextG14 margin="0 !important" fontWeight="700">
        Lifestyle Goals and Interests
      </TextG14>
    </VStack>
  );

  return (
    <Modal
      closeOnOverlayClick
      isCentered
      show={showModal}
      header={header}
      parentCallback={() => setShowModal(false)}>
      <>
        <>
          <TextG14>
            Pick your lifestyle goals and interests to get matched with events and info that suit
            your vibe!
          </TextG14>
          <br />
          <TextG14>
            We've kickstarted things by selecting a couple for you: <b>"Experience More"</b> and{' '}
            <b>"Meeting People"</b>.
          </TextG14>
          <br />
          <TextG14>
            Feel free to take your time with it, and if you're not in the mood now, you can always
            update them later in your profile.
          </TextG14>
        </>
        <VStack mt="32px" mb="8px">
          <BlueButton
            w="100%"
            borderRadius="6px"
            onClick={() => {
              setShowModal(false);
            }}>
            I'm Ready to Choose
          </BlueButton>
          <WhiteButton
            _hover={{background: ''}}
            onClick={() => {
              if (handleClickBlueButton) {
                handleClickBlueButton();
              }
            }}>
            <TextG10 fontSize="11px" fontWeight="400" color="#272929">
              Skip For Now
            </TextG10>
          </WhiteButton>
        </VStack>
      </>
    </Modal>
  );
};

export default SkipInterestsModal;
