import {AbsoluteCenter, Flex, Box, Image, AspectRatio, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useYouTubeVideoData} from '../../hooks/useYoutube';
import {getYouTubeVideoDuration, getYouTubeVideoThumbnail} from '../../utils/youtube';
import Icon from '../Icon';
import {TextG10, TextG12} from '../typography';
import {VideoTask} from './GuidedExperienceWidget';

const VideoPreview = ({
                        url,
                        thumbnail,
                        taskNum,
                        totalTasks,
                        task,
                        callToAction,
                        hideDetails = false,
                        buttonFontSize = '14px',
                        playButtonColor = '#E02F84',
                        playButtonFontColor = 'white',
                        buttonFontLineHeight = '20px',
                        playIcon = (
                            <Icon
                                style={{
                                  fill: 'white',
                                  color: 'white',
                                  fontSize: '16px',
                                  width: '16px',
                                  height: '16px',
                                  //textShadow: '1px 1px 3px rgba(0,0,0,0.7)', // Add shadow to the icon
                                }}
                                iconName="fi-rr-play-circle"
                            />
                        ),
                      }: {
  url: string;
  thumbnail?: any;
  taskNum?: number;
  totalTasks?: number;
  task?: VideoTask;
  callToAction?: string;
  playButtonColor?: string;
  playButtonFontColor?: string;
  playIcon?: JSX.Element;
  hideDetails?: boolean;
  buttonFontSize?: string | number;
  buttonFontLineHeight?: string;
}) => {
  const theme = useTheme();
  const [duration, setDuration] = useState('');
  const [title, setTitle] = useState('');
  const videoThumbnail = thumbnail ? thumbnail : getYouTubeVideoThumbnail(url);
  let playCallToAction = 'Take The Next Step';

  if (taskNum == 1) {
    playCallToAction = 'Take The First Step';
  } else if (taskNum == totalTasks) {
    playCallToAction = 'Take The Last Step';
  }

  if (callToAction) playCallToAction = callToAction;

  const {data} = useYouTubeVideoData(url);

  useEffect(() => {
    if (data) {
      if (task?.title) setTitle(task.title);
      else setTitle(data.snippet.title);
      setDuration(getYouTubeVideoDuration(data.contentDetails.duration));
    }
  }, [data, task]);

  return (
      <Box w="100%" pos="relative">
        <Box zIndex={1} w="100%" h="100%" background="#2729295C" pos="absolute" borderRadius="8px" />
        <AbsoluteCenter zIndex={1}>
          {/* <Box borderRadius="16px" background="rgba(0,0,0,0.9);">
          <Flex gap="6px" alignItems="center" px={2} py={2}>
            <TextG10 color="white" fontWeight="500">
              {playCallToAction}
            </TextG10>
            <Icon
              style={{color: 'white', fontSize: '12px', width: '12px', height: '12px'}}
              iconName="fi-sr-play"
            />
          </Flex>
        </Box> */}
          <Box
              background={playButtonColor}
              borderRadius="16px"
              //background="rgba(0,0,0,0.9)" // Slightly less transparent for better contrast
              boxShadow="0 4px 10px rgba(0,0,0,0.4)">
            <Flex gap="6px" alignItems="center" px={2} py={2}>
              {playIcon}
              <TextG12
                  lineHeight={buttonFontLineHeight}
                  fontSize={buttonFontSize}
                  whiteSpace="nowrap"
                  color={playButtonFontColor}
                  fontWeight="500"
                  //style={{textShadow: '1px 1px 3px rgba(0,0,0,0.7)'}} // Add text shadow
              >
                {playCallToAction}
              </TextG12>
            </Flex>
          </Box>
        </AbsoluteCenter>
        {!hideDetails && (
            <Box zIndex={1} fontWeight="500" color="white" pos="absolute" bottom={3} left={4}>
              {/* <TextG10 mb={2}>{`Video ${taskNum}:`} </TextG10> */}
              <TextG10>{`${title}`}</TextG10>
            </Box>
        )}
        {!hideDetails && (
            <Box zIndex={1} color="white" pos="absolute" top={3} left={4}>
              <TextG10>{`Duration: ${duration}`}</TextG10>
            </Box>
        )}
        {videoThumbnail && (
            <AspectRatio ratio={16 / 9} w="100%">
              <Image
                  borderRadius="8px"
                  src={videoThumbnail}
                  h="100%"
                  w="100%"
                  fallbackSrc={getYouTubeVideoThumbnail(url)}
                  fallbackStrategy="onError"
                  objectFit="cover"
              />
            </AspectRatio>
        )}
      </Box>
  );
};

export default VideoPreview;
