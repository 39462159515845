import {Center, Flex, Link, Stack} from '@chakra-ui/react';
import React from 'react';
import {Partner} from '../../../types/acrticle';
import {TextA18, TextG12, TextG14} from '../../../components/typography';
import {BlueButton} from '../../../components/button';
import SwiperSlider from '../../../components/SwiperSlider';
import PlaceCard from '../../../components/Articles/PlaceCard';
import ArticleInfo from '../../../components/Articles/ArticleInfo';
import {BookmarkElement} from '../../../components/Articles';
import ExternalLink from '../../../components/ExternalLink';
import SignUpRequiredWrapper from '../../../components/SignupRequiredModal/SignupRequiredWrapper';

const About = ({
  partner,
  isPreview = false,
  setSignUpRequiredOpen,
}: {
  partner: Partner;
  isPreview?: boolean;
  setSignUpRequiredOpen?: any;
}) => {
  return (
    <>
      <Stack gap=".25rem" textAlign="start" p="1rem">
        {partner.website && (
          <Center>
            <ExternalLink
              w="90%"
              href={partner.website}
              isExternal
              style={{textDecoration: 'none'}}>
              <BlueButton w="100%">
                <TextG14>Website</TextG14>
              </BlueButton>
            </ExternalLink>
          </Center>
        )}

        <Flex px="5%" pt="5%" flexDir="column" gap="10px">
          <TextG12 dangerouslySetInnerHTML={{__html: partner.description}}>{}</TextG12>
          <TextG12 dangerouslySetInnerHTML={{__html: partner.content}}>{}</TextG12>
        </Flex>
        {partner.places && partner.places.length > 0 && (
          <>
            <SignUpRequiredWrapper setSignUpRequiredOpen={setSignUpRequiredOpen}>
              <TextA18>Visit This Place</TextA18>
              <SwiperSlider
                items={partner.places}
                render={(item) => (
                  <PlaceCard
                    isPreview={isPreview}
                    place={item}
                    leftTopItem={<ArticleInfo article={item} />}
                    rightTopItem={isPreview ? null : <BookmarkElement article={item} />}
                  />
                )}
              />
            </SignUpRequiredWrapper>
          </>
        )}
      </Stack>
    </>
  );
};

export default About;
