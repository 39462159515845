import React from 'react';
import {BookmarkElement} from '../components/Articles';
import ArticleInfo from '../components/Articles/ArticleInfo';

export const getArticleTopItems = (article, renderArticleType = true) => {
  const rightTopItem = <BookmarkElement article={article} />;
  const leftTopItem = <ArticleInfo article={article} renderArticleType={renderArticleType} />;

  return {rightTopItem, leftTopItem};
};
