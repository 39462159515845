import {Center, Spinner, Flex, useTheme, Box, AbsoluteCenter} from '@chakra-ui/react';
import {filter, sortBy, map} from 'lodash';
import React, {useState, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useAuth from '../../../hooks/useAuth';
import Icon from '../../Icon';
import {TextG14} from '../../typography';
import {ChatComment as ChatCommentInterface} from '../ChatComment';
import ReplyMentionNotification from './ReplyMentionNotification';

const RepliesMentionsTab = ({
  articleId,
  headerHeight,
  fetchRepliesHook,
}: {
  articleId?: number | undefined;
  headerHeight: number;
  fetchRepliesHook: any;
}) => {
  const {data, isLoading} = articleId ? fetchRepliesHook(articleId) : fetchRepliesHook();
  const [messages, setMessages] = useState<Array<ChatCommentInterface>>();
  const theme = useTheme();
  const {user} = useAuth();

  useEffect(() => {
    if (data?.data.replies.length > 0 || data?.data.mentions.length > 0) {
      setMessages(
        sortBy(
          [
            ...map(
              filter(
                data?.data.replies,
                (reply: ChatCommentInterface) => reply.user_id != user?.id
              ),
              (reply: ChatCommentInterface) => {
                return {...reply, isReply: true};
              }
            ),
            ...filter(
              data?.data.mentions,
              (mention: ChatCommentInterface) => mention.user_id != user?.id
            ),
          ],
          'created_at'
        )
      );
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Center mt={`calc(100vh - ${headerHeight}px)`}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </Center>
      ) : !messages || messages.length == 0 ? (
        <AbsoluteCenter
          w="95%"
          textAlign="center"
          color={theme.colors.grey}
          pt={`${headerHeight / 2}px`}>
          <Flex flexDir="column" gap={8}>
            <Icon
              iconName="fi-rr-comment"
              style={{fontSize: '48px', color: theme.colors.lightGrey}}
            />
            <TextG14 fontWeight="500">You don't have any replies or mentions yet!</TextG14>
          </Flex>
        </AbsoluteCenter>
      ) : (
        <Flex
          flexDir="column-reverse"
          id="scrollableDiv"
          overflowY="auto"
          h={`calc(max(100vh - 15px - ${headerHeight}px), 500px)`}
          mt={`${headerHeight}px`}
          px={4}
          mb="15px">
          <InfiniteScroll
            scrollThreshold={'500px'}
            scrollableTarget="scrollableDiv"
            dataLength={messages?.length || 0}
            next={() => {}}
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }} //To put endMessage and loader to the top.
            inverse={true}
            hasMore={false}
            loader={
              <Center py="30px">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor={theme.colors.lightGrey}
                  color={theme.colors.lightBlue}
                  size="lg"
                />
              </Center>
            }>
            {messages &&
              messages.map((comment, i) => (
                <Box key={`${comment.id}-${i}`}>
                  <ReplyMentionNotification comment={comment} />
                </Box>
              ))}
          </InfiniteScroll>
        </Flex>
      )}
    </>
  );
};

export default RepliesMentionsTab;
