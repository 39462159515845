import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';

export interface Location {
  latitude: number;
  longitude: number;
}

declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

// Helper to detect if we're in a webview
const isWebView = () => {
  return window.ReactNativeWebView !== undefined;
};

export default function useGetUserLocation(enabled = true) {
  const [error, setError] = useState<GeolocationPositionError>();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(['userLocation']);
  const [userLocation, setUserLocation] = useState<Location | null>(cachedData as Location);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!enabled) return;

    // Handle location updates in webview
    if (isWebView()) {
      const handleLocationUpdate = (event: CustomEvent) => {
        const {latitude, longitude} = event.detail.location.coords;
        const location = {
          latitude,
          longitude,
        };
        setUserLocation(location);
        setLoading(false);
        // Update query cache
        queryClient.setQueryData(['userLocation'], location);
      };

      window.addEventListener('LOCATION_UPDATE', handleLocationUpdate as EventListener);
      return () => {
        window.removeEventListener('LOCATION_UPDATE', handleLocationUpdate as EventListener);
      };
    }

    // Handle location updates in web browser
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setUserLocation(location);
        setLoading(false);
        // Update query cache
        queryClient.setQueryData(['userLocation'], location);
      },
      (err) => {
        setError(err);
        setLoading(false);
      },
      {enableHighAccuracy: true}
    );

    return () => {
      if (watchId) return navigator.geolocation.clearWatch(watchId);
    };
  }, [enabled, queryClient]);

  return useQuery<Location | null, Error>(
    ['userLocation'],
    () => {
      return new Promise<Location | null>(async (resolve, reject) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(userLocation || null);
      });
    },
    {retry: false, staleTime: 0, refetchInterval: 500, enabled: !loading && enabled}
  );
}
