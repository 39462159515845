import React, { useEffect } from 'react';
import {Heading, Text} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const NotFound = () => {
    const navigate = useNavigate();
    const { isUserAuthenticated } = useAuth();

    useEffect(() => {
        setTimeout(() => {
            navigate(isUserAuthenticated ? '/home' : '/auth');
        },3000);
    });

    return (
        <>
            <Heading>Page not found...</Heading>
            <Text>Redirecting to default route</Text>
        </>
    );
}

export default NotFound;