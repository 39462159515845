import {Image, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import Modal from '../../../../components/Modals/Modal';
import Logo from '../../../../assets/logos/logo_57x57.png';
import {TextG10, TextG14} from '../../../../components/typography';
import {BlueButton, WhiteButton} from '../../../../components/button';

const SkipInterestsModal = ({handleClickBlueButton}) => {
  const [showModal, setShowModal] = useState(true);

  const header = (
    <VStack gap="16px">
      <Image src={Logo} />
      <TextG14 margin="0 !important" fontWeight="700">
        Customize Walkabout and Make It Yours
      </TextG14>
    </VStack>
  );

  return (
    <Modal
      closeOnOverlayClick
      isCentered
      show={showModal}
      header={header}
      parentCallback={() => setShowModal(false)}>
      <>
        <>
          <TextG14>
            Pick what matters to you so we can match you with the best local events, meet-ups, and
            exclusive experiences.
          </TextG14>
          <br />
          <TextG14>
            We've kickstarted things by selecting <b>'Experience More'</b> and{' '}
            <b>'Meeting People'</b> for you.
          </TextG14>
        </>
        <VStack mt="32px" mb="8px">
          <BlueButton
            w="100%"
            borderRadius="6px"
            onClick={() => {
              setShowModal(false);
            }}>
            Got It! Let me choose!
          </BlueButton>
          <WhiteButton
            _hover={{background: ''}}
            onClick={() => {
              if (handleClickBlueButton) {
                handleClickBlueButton();
              }
            }}>
            <TextG10 fontSize="11px" fontWeight="400" color="#272929">
              I’ll Do It Later
            </TextG10>
          </WhiteButton>
        </VStack>
      </>
    </Modal>
  );
};

export default SkipInterestsModal;
