import {AbsoluteCenter, Box, Center, Flex, HStack, Spinner, useTheme} from '@chakra-ui/react';
import {filter, groupBy, has, orderBy} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useFetchUserTickets} from '../../../hooks/useUserTickets';
import Header from './Header';

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import {Autoplay, FreeMode, Pagination, Navigation, Lazy} from 'swiper';
import styled from 'styled-components';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {TextA18, TextG10, TextG12} from '../../../components/typography';
import TicketDetails from './TicketDetails';
import Icon from '../../../components/Icon';
import {EventRSVPStatus, useFetchEvent, useSetAttendance} from '../../../hooks/useEvent';
import {BlueButton} from '../../../components/button';
import {ToastMessage} from '../../../components/toast';
import useAuth from '../../../hooks/useAuth';

const StyledSwiper = styled(Swiper)`
  z-index: 0;
  margin-top: 0px !important;
  .swiper-pagination {
    position: relative !important;
    bottom: 10 !important;
    margin-top: 10px;
  }
  .custom-swiper-button-prev,
  .custom-swiper-button-next {
    position: absolute;
    bottom: 0px;
    z-index: 10;
    cursor: pointer;
  }

  .custom-swiper-button-prev {
    left: 18px;
  }

  .custom-swiper-button-next {
    right: 18px;
  }

  .swiper-button-disabled,
  .custom-swiper-button-prev.disabled,
  .custom-swiper-button-next.disabled {
    cursor: not-allowed;
  }
`;

const TicketPage = () => {
  const {eventId} = useParams();
  const theme = useTheme();
  const fetchEvent = useFetchEvent(Number(eventId));
  const event = fetchEvent?.data?.data;
  const {data, isLoading} = useFetchUserTickets();
  const {user} = useAuth();
  const userTickets = groupBy(
    filter(data?.data, (item) => has(item, 'deleted_at') && item.deleted_at == null),
    (item) => item?.event?.id
  );
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const navigate = useNavigate();
  const {mutateAsync} = useSetAttendance();
  const [searchParams] = useSearchParams();

  const isSuccess = !!searchParams.get('success');

  const handleInviteClicked = async () => {
    if (event && navigator.share) {
      const shareData = {
        title: event.title,
        text: `Hey! I found this event on Walkabout called '${event.title}' that I want to invite you to! Check it out - `,
        url: location.origin + `/event/${event.id}/preview?invite-code=${user?.referralCode}`,
      };
      navigator.share(shareData);
    }

    // const eventLink =
    //   location.origin + `/event/${event?.id}/preview?invite-code=${user?.referralCode}`;
    // navigator.clipboard.writeText(eventLink);
    // ToastMessage({
    //   status: 'success',
    //   text: 'Invite link copied to your clipboard',
    // });
  };

  useEffect(() => {
    if (isSuccess && event && event.rsvp_status.status_value != EventRSVPStatus.GOING) {
      mutateAsync({
        eventId: event.id,
        prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.GOING,
        avoidDispatchRSVPModalPopupActions: false,
      });
    }
  }, [event, isSuccess]);

  const eventTickets = eventId && orderBy(userTickets[eventId], ['updated_at'], ['asc']);
  const ticketDescription = event?.ticket_description;

  const loading = isLoading || (fetchEvent.isLoading && !!!eventId);

  if (loading) {
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <Flex flexDir="column">
      <Header isSuccess={isSuccess} />

      {!eventTickets || eventTickets.length < 0 || eventTickets[0]?.event == null ? (
        <AbsoluteCenter w="90vw">
          <Flex
            mt="-100px"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap="4"
            textAlign={'center'}>
            <i
              className="fi fi-rr-comment-user"
              style={{
                color: `${theme.colors.lighterGrey}`,
                fontSize: '100px',
                width: '100px',
                height: '100px',
              }}></i>
            <TextA18 textAlign="center" color={theme.colors.darkGrey}>
              Sorry, it looks like something is wrong with your tickets!
              <br />
              <br />
              If you think this is a mistake please reach out to support and let us know!
            </TextA18>
          </Flex>
        </AbsoluteCenter>
      ) : (
        <Box w="100%" pos="relative">
          <StyledSwiper
            autoHeight={true}
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={(swiper) => {
              setIsBeginning(swiper.isBeginning);
              setIsEnd(swiper.isEnd);
            }}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            navigation={{
              nextEl: '.custom-swiper-button-next',
              prevEl: '.custom-swiper-button-prev',
            }}
            modules={[Autoplay, Pagination, Navigation, FreeMode, Lazy]}>
            {eventTickets.map((item, index) => (
              <SwiperSlide className="swiper-lazy" style={{width: 'fit-content'}} key={index}>
                <TicketDetails
                  ticket={item}
                  ticketNum={index + 1}
                  totalTickets={eventTickets.length}
                />
              </SwiperSlide>
            ))}
            {eventTickets && eventTickets.length > 1 && (
              <>
                <Box className="custom-swiper-button-prev">
                  <Icon
                    iconName="fi-rr-arrow-small-left"
                    style={{
                      fontSize: '24px',
                      height: '14px',
                      color: isBeginning ? theme.colors.grey2 : theme.colors.darkGrey,
                    }}
                  />
                </Box>
                <Box className="custom-swiper-button-next">
                  <Icon
                    iconName="fi-rr-arrow-small-right"
                    style={{
                      fontSize: '24px',
                      height: '14px',
                      color: isEnd ? theme.colors.grey2 : theme.colors.darkGrey,
                    }}
                  />
                </Box>
              </>
            )}
          </StyledSwiper>

          {event && (
            <Flex gap={3} flexDir="column">
              <BlueButton mx={6} onClick={() => navigate(`/event/${event.id}/tickets`)}>
                Get More Tickets
              </BlueButton>
              <BlueButton mx={6} onClick={() => navigate(`/event/${event.id}`)}>
                See This Event
              </BlueButton>
              <BlueButton mx={6} onClick={handleInviteClicked}>
                Invite Your Friends to This Event
              </BlueButton>
            </Flex>
          )}
          {ticketDescription && (
            <Flex gap="8px" mx={6} my={4} flexDir="column">
              <TextA18 fontWeight="700">Instructions</TextA18>
              <TextG12 dangerouslySetInnerHTML={{__html: ticketDescription}}>{}</TextG12>
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default TicketPage;
