import {Box, Flex, useTheme, VStack, Image, Link} from '@chakra-ui/react';
import React from 'react';
import {OFFER_STEPS} from '..';
import useAuth from '../../../../../../hooks/useAuth';
import {useGeneralSettings} from '../../../../../../hooks/useGeneralSettings';
import {Offer, OfferType, Place, Event} from '../../../../../../types/article';
import {getDirectionsLinkFromPlace} from '../../../../../../utils/address';
import {getOfferDate, isFutureDate} from '../../../../../../utils/date';
import {BlueButton} from '../../../../../button';
import {TextG10, TextG12} from '../../../../../typography';
import dayjs from 'dayjs';
import Icon from '../../../../../Icon';
import {AnchorAttributes, createAnchorWithAttributes} from '../../../../../../utils/externalLink';
import HTMLReactParser from 'html-react-parser';
import {checkIsOfferLocked} from '../../../OfferCard';
import {storeScrollPositionInSession} from '../../../../../../utils/scroll';

const RedemptionStepOne = ({
  offer,
  event,
  place,
  bookmarkHandler,
  isBookmarked,
  setShowModal,
  distance,
  setCurStep,
  isFutureOffer,
  datePassed,
  isPreview = false,
}: {
  offer: Offer;
  event?: Event;
  place: Place;
  bookmarkHandler: (e: any) => Promise<string | number | undefined> | undefined;
  isBookmarked: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  distance: Number;
  setCurStep: React.Dispatch<React.SetStateAction<OFFER_STEPS>>;
  isFutureOffer: boolean;
  datePassed: boolean;
  isPreview?: boolean;
}) => {
  const theme = useTheme();
  const {getSetting, isLoading} = useGeneralSettings(!isPreview);
  const {user} = useAuth();

  const userPoints = user?.pointsTotal;
  const pointCost = offer.points_redeem_amount; //50000;

  const pointDiff = (userPoints || 0) - pointCost;
  const enoughPoints = pointDiff >= 0;

  const unlocksByEventCheckIn = event && event.unlocks_offer_id === offer.id;
  const unlockedByEventCheckIn = event && event.unlocked_offer?.offer_id === offer.id;
  const isOfferLocked = checkIsOfferLocked(offer.id, event);

  const actionsBar = () => {
    if (isOfferLocked) {
      const isFuture = isFutureDate(event?.start_date_time);
      const date = dayjs.utc(event?.start_date_time).local().format('MMM DD');

      return (
        <>
          <BlueButton
            cursor="none !important;"
            _focus={{boxShadow: 'none'}}
            _hover={{boxShadow: 'none'}}
            _active={{boxShadow: 'none'}}
            isDisabled={true}
            w="auto">
            <Icon
              style={{color: theme.colors.white, fontSize: '14px'}}
              iconName={'fi fi-sr-lock'}
            />
            <TextG12 ml={2} color={theme.colors.white} fontWeight="500">
              Unlock with Check-In {isFuture && `on ${date}`}
            </TextG12>
          </BlueButton>
          {!isBookmarked && !datePassed && (
            <TextG12
              onClick={(e) => {
                if (!isPreview) {
                  setShowModal(false);
                  bookmarkHandler(e);
                }
              }}
              textDecoration="none"
              fontWeight="400"
              color={theme.colors.grey}>
              Remind Me Later
            </TextG12>
          )}
        </>
      );
    }

    if (unlockedByEventCheckIn) {
      return (
        <>
          <BlueButton
            cursor="none !important;"
            _focus={{boxShadow: 'none'}}
            _hover={{boxShadow: 'none'}}
            _active={{boxShadow: 'none'}}
            isDisabled={!enoughPoints || datePassed || isFutureOffer}
            onClick={() => (!isPreview ? setCurStep(OFFER_STEPS.Confirm) : null)}
            w="180px">
            <TextG10 color={theme.colors.white} fontWeight="500">
              Redeem Now
            </TextG10>
          </BlueButton>

          {!isBookmarked && !datePassed && (
            <TextG12
              onClick={(e) => {
                if (!isPreview) {
                  setShowModal(false);
                  bookmarkHandler(e);
                }
              }}
              textDecoration="none"
              fontWeight="400"
              color={theme.colors.grey}>
              Remind Me Later
            </TextG12>
          )}
        </>
      );
    }

    if (isFutureOffer && !isBookmarked) {
      return (
        <BlueButton
          cursor="none !important;"
          _focus={{boxShadow: 'none'}}
          _hover={{boxShadow: 'none'}}
          _active={{boxShadow: 'none'}}
          w="180px"
          onClick={(e) => {
            setShowModal(false);
            bookmarkHandler(e);
          }}
          fontWeight="400"
          color={theme.colors.white}>
          Remind Me
        </BlueButton>
      );
    }

    if (offer.is_geofencing_required && (offer.place || place)) {
      if (getSetting('checkin_distance') && distance < getSetting('checkin_distance')) {
        return (
          <>
            <BlueButton
              cursor="none !important;"
              _focus={{boxShadow: 'none'}}
              _hover={{boxShadow: 'none'}}
              _active={{boxShadow: 'none'}}
              isDisabled={!enoughPoints || datePassed}
              onClick={() => (!isPreview ? setCurStep(OFFER_STEPS.Confirm) : null)}
              w="180px">
              <TextG10 color={theme.colors.white} fontWeight="500">
                Redeem Now
              </TextG10>
            </BlueButton>

            {!isBookmarked && !datePassed && (
              <TextG12
                onClick={(e) => {
                  if (!isPreview) {
                    setShowModal(false);
                    bookmarkHandler(e);
                  }
                }}
                textDecoration="none"
                fontWeight="400"
                color={theme.colors.grey}>
                Remind Me Later
              </TextG12>
            )}
          </>
        );
      } else {
        return (
          <>
            <BlueButton
              cursor="none !important;"
              _focus={{boxShadow: 'none'}}
              _hover={{boxShadow: 'none'}}
              _active={{boxShadow: 'none'}}
              isDisabled={datePassed}
              w="180px"
              onClick={() => {
                let url = getDirectionsLinkFromPlace(place);
                const attributes: AnchorAttributes = {
                  href: url,
                  target: '_blank',
                  id: 'getDirections',
                };
                const anchor = createAnchorWithAttributes(attributes);
                window.document.body.appendChild(anchor);
                let link = document.getElementById('getDirections');
                link?.click();
                location.href = `/place/${place.id}`;
              }}>
              <TextG12 color={theme.colors.white} fontWeight="500">
                Take Me There!
              </TextG12>
            </BlueButton>

            {!isBookmarked && !datePassed && (
              <TextG12
                onClick={(e) => {
                  if (!isPreview) {
                    setShowModal(false);
                    bookmarkHandler(e);
                  }
                }}
                textDecoration="none"
                fontWeight="400"
                color={theme.colors.grey}>
                Remind Me Later
              </TextG12>
            )}
          </>
        );
      }
    } else {
      return (
        <>
          <BlueButton
            cursor="none !important;"
            _focus={{boxShadow: 'none'}}
            _hover={{boxShadow: 'none'}}
            _active={{boxShadow: 'none'}}
            isDisabled={isFutureOffer || !enoughPoints || datePassed}
            onClick={() => (!isPreview ? setCurStep(OFFER_STEPS.Confirm) : null)}
            w="180px">
            <TextG10 color={theme.colors.white} fontWeight="500">
              Redeem Now
            </TextG10>
          </BlueButton>

          {!isBookmarked && !datePassed && (
            <TextG12
              onClick={(e) => {
                if (!isPreview) {
                  setShowModal(false);
                  bookmarkHandler(e);
                }
              }}
              textDecoration="none"
              fontWeight="400"
              color={theme.colors.grey}>
              Remind Me Later
            </TextG12>
          )}
        </>
      );
    }
  };

  const learnMoreUrl = (place: Place, event?: Event) => {
    if (event && (unlockedByEventCheckIn || unlocksByEventCheckIn)) {
      return `/event/${event?.id || offer.event_id}`;
    } else {
      return `/place/${place?.id || offer.place_id}`;
    }
  };

  return (
    <>
      <Flex
        color={theme.colors.white}
        py={1}
        px={2}
        background={theme.colors.rose}
        borderRadius="10px"
        mt="-30px !important"
        flexDir="column"
        align="center">
        <TextG12 fontWeight="700">Redeem For</TextG12>
        <Box>
          <TextG12 fontWeight="700">{`${pointCost.toLocaleString()} Points`}</TextG12>
        </Box>
      </Flex>
      {userPoints && (
        <TextG12 color={(!enoughPoints && theme.colors.rose) || theme.colors.grey} fontWeight="500">
          {enoughPoints
            ? `Your Balance: ${userPoints?.toLocaleString()}`
            : `You Need: ${Math.abs(pointDiff).toLocaleString()} More Points`}
        </TextG12>
      )}
      {isFutureOffer ? (
        <Flex textAlign="center" py="2px" px={4} align="center" gap="4px">
          <Icon
            iconName="fi-rr-clock-nine"
            style={{
              color: theme.colors.blue,
              marginBottom: '0.25rem',
              fontSize: '18px',
              width: '18px',
              height: '18px',
            }}
          />
          <TextG12
            lineHeight="14px"
            color={theme.colors.blue}
            fontWeight="700"
            isTruncated="&hellip;"
            noOfLines={1}>
            AVAILABLE {getOfferDate(offer.starts_at)?.toUpperCase()}
          </TextG12>
        </Flex>
      ) : (
        <>
          <TextG10
            lineHeight="10px"
            color={theme.colors.lightestGrey}
            py="2px"
            px={4}
            fontWeight="500"
            isTruncated="&hellip;"
            noOfLines={1}>
            {`${datePassed ? 'EXPIRED ON' : 'EXPIRES'} ${getOfferDate(
              offer?.ends_at
            )?.toUpperCase()}`}
          </TextG10>
          <TextG10
            lineHeight="10px"
            mt="0 !important"
            color={theme.colors.lightestGrey}
            fontWeight="400">
            Limit {offer?.total_use_limit_per_user}x per person
          </TextG10>
        </>
      )}

      {offer.summary && (
        <Box
          py={3}
          px={4}
          textAlign="center"
          border={`1px solid ${theme.colors.lighterGrey}`}
          borderRadius="7px">
          <TextG12 color={theme.colors.grey} align="center" maxW="284px" fontWeight="500">
            {HTMLReactParser(offer?.summary)}{' '}
            <Link
              textDecoration={'none'}
              color={'#276ACD'}
              href={learnMoreUrl(place, event)}
              onClick={() => storeScrollPositionInSession()}>
              Learn more
            </Link>
            <Icon
              style={{marginLeft: 4, color: '#276ACD', fontSize: '9px'}}
              iconName={'fi fi-br-arrow-up-right'}
            />
          </TextG12>
          {offer && offer.offer_type === OfferType.EVENT_REDEMPTION && (
            <Flex gap={3} mt={3}>
              <TextG12 color={theme.colors.grey} fontWeight="700">
                {offer?.event?.start_date_time &&
                  dayjs.utc(offer.event.start_date_time).local().format('MMM. D [at] h:mmA')}
              </TextG12>
              <TextG12 color="#B6B6B6">
                {offer.quantity_available}
                {offer.quantity_type ? ` ${offer.quantity_type}` : ``} left
              </TextG12>
            </Flex>
          )}
        </Box>
      )}
      <VStack>{actionsBar()}</VStack>
    </>
  );
};

export default RedemptionStepOne;
