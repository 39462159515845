import React, {useState} from 'react';
import {Box, Center, Container, HStack, useTheme, VStack} from '@chakra-ui/react';
import {replace} from 'lodash';
import {VERIFICATION_CODE_LENGTH} from '../../Register/RegisterForm';
import BackButton from '../../../../components/BackButton';
import {H2, TextG12, TextG14} from '../../../../components/typography';
import {PinInputField} from '@chakra-ui/react';
import {BlueButtonFixedWidth} from '../../../../components/button';
import {verifyPasswordResetCode} from '../../../../endpoints/api';
import {SpinnerLoader} from '../../../../components/Loader';
import styled, {css} from 'styled-components';
import CustomPinInput from '../../../../components/CustomPinInput';

const UnderlinedInput = styled(PinInputField)`
  width: 40px !important;
  height: 40px !important;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-family: Gordita;
  line-height: 20px;
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important;
  ${(props) =>
    !props.noBorder
      ? css`
          border-bottom: 0.5px solid ${(props) => props.color || 'none'};
        `
      : css`
          border-bottom: none !important;
        `};
  border-radius: unset !important;

  &:focus-visible {
    outline: none !important;
    outline-color: none !important;
  }
`;

const CodeVerification = ({previousStep, nextStep, additionalData, setAdditionalData}) => {
  const theme = useTheme();

  const [code, setCode] = useState('');
  const [error, setError] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const [isVerifyButtonEnabled, setIsVerifyButtonEnabled] = useState(false);

  const hideEmail = (email): string => {
    if (!email) {
      return 'your email address';
    }

    const substring = email.substring(1, email.indexOf('@'));
    return replace(email, substring, '*'.repeat(substring.length));
  };

  const handleVerification = (e) => {
    e.preventDefault();
    setShowLoader(true);
    verifyPasswordResetCode(additionalData?.email, code)
      .then(() => {
        setShowLoader(false);
        setAdditionalData({...additionalData, code: code});
        nextStep();
      })
      .catch((err) => {
        setShowLoader(false);
        setError(err.message);
      });
  };

  return (
    <Container px={5} py={8}>
      <Box>
        <BackButton pos={'relative'} onClick={() => previousStep()} />
      </Box>
      <form style={{width: '100%'}} onSubmit={handleVerification}>
        <VStack pt={7} spacing={3}>
          <H2 fontWeight={700}>Verify it’s You</H2>
          <TextG14 px={10} textAlign={'center'} fontWeight={500} lineHeight={'176.5%'}>
            {`Enter the 6-digit verification code sent to ${hideEmail(additionalData?.email)}`}
          </TextG14>
          {showLoader ? (
            <Box pt={16}>
              <SpinnerLoader />
            </Box>
          ) : (
            <HStack spacing={4} py={4}>
              <CustomPinInput
                value={code}
                letterSpacing="5vw"
                fontSize="20px"
                textAlign="center"
                type="alphanumeric"
                maxLength={VERIFICATION_CODE_LENGTH}
                onChange={(e) => {
                  const value = e.target.value;
                  setCode(value);
                  setIsVerifyButtonEnabled(value.length >= VERIFICATION_CODE_LENGTH);
                }}
              />
              {/* <PinInput
                type='alphanumeric'
                placeholder=""
                value={code}
                onChange={(value) => {
                  setCode(value);
                  if (value.length >= VERIFICATION_CODE_LENGTH) {
                    setIsVerifyButtonEnabled(true);
                  }
                  else {
                    setIsVerifyButtonEnabled(false);
                  }
                }}>
                {[...Array(VERIFICATION_CODE_LENGTH)].map((key, i) => (
                  <FormControl key={i} isRequired>
                    <UnderlinedInput
                      id={i}
                      color={theme.colors.lightBlack}
                      border={`2px solid ${theme.colors.grey}`}
                      textAlign={'center'}
                      fontSize={'18px'}
                      p={0}
                    />
                  </FormControl>
                ))}
              </PinInput> */}
            </HStack>
          )}
          {error && (
            <Center>
              <TextG12 color={'red'}>
                {error} Paste it here, or go{' '}
                <Box as={'span'} onClick={() => previousStep()}>
                  back
                </Box>
                {' '}to resend.
              </TextG12>
            </Center>
          )}
          <Box py={3} w={'100%'}>
            <Center>
              <BlueButtonFixedWidth isDisabled={!isVerifyButtonEnabled} type={'submit'}>
                Verify
              </BlueButtonFixedWidth>
            </Center>
            <Center>
              <TextG12
                px={10}
                py={3}
                textAlign="center"
                color={theme.colors.grey}
                lineHeight={'160%'}>
                Didn't get the email? Check your spam/junk filter, or{' '}
                <TextG12 as="span" color={theme.colors.blue} onClick={() => previousStep()}>go back</TextG12> to resend.
              </TextG12>
            </Center>
          </Box>
        </VStack>
      </form>
    </Container>
  );
};

export default CodeVerification;
