import React, {useState} from 'react';
import {Center, Flex, Image, Spinner, useTheme} from '@chakra-ui/react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Header from './Header';
import BackButton from '../../components/BackButton';
import PlaceTabs from './Tabs';
import useGetUserLocation, {Location} from '../../hooks/useUserLocation';
import LocationDisabled from '../../components/LocationDisabled';
import {useOffers} from '../../hooks/useOffers';
import {useFetchPlace} from '../../hooks/useFetchPlace';
import MediaGallery from '../../components/MediaGallery';

const Place = ({
  assignPlaceName,
  onClose,
}: {
  assignPlaceName?: (string) => void;
  onClose?: () => void;
}) => {
  const {placeId} = useParams();
  const theme = useTheme();
  const userLocation = useGetUserLocation();
  const navigate = useNavigate();
  const [verifiedLocation, setVerifiedLocation] = useState<Location | null>(null);
  const [locationDisabled, setLocationDisabled] = useState(false);
  const [searchParams] = useSearchParams();

  const fetchPlace = useFetchPlace({
    id: Number(placeId || searchParams.get('id')),
    lat: userLocation?.data?.latitude,
    long: userLocation?.data?.longitude,
  });
  const place = fetchPlace.data?.data;

  const {offers, offersIsLoading} = useOffers({
    articleType: 'placeId',
    articleId: placeId || searchParams.get('id'),
  });

  const navigateBack = React.useCallback(() => {
    if (searchParams.has('id') && onClose) {
      onClose();
      return;
    }
    navigate(-1);
  }, []);

  React.useEffect(() => {
    if (!verifiedLocation && userLocation.status === 'success') {
      setVerifiedLocation(userLocation.data);
    }
  }, [userLocation.status]);

  if (!verifiedLocation && locationDisabled) return <LocationDisabled />;

  if (offersIsLoading || fetchPlace.isLoading || place === undefined) {
    return (
      <Center style={centerCss}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Flex position="relative" height="275px">
        <BackButton onClick={navigateBack} m={'.75rem'} pos="relative" />
        {/* <Image
          fallbackSrc={FallbackImg}
          src={place.image_url}
          alt="cover image"
          position="absolute"
          width="100%"
          height="100%"
          objectFit="cover"
          zIndex="-1"
          borderBottomRadius="14px"
        /> */}
        <MediaGallery media={place.media} />
      </Flex>
      <Header place={place} />
      <PlaceTabs
        place={place}
        placeOffers={offers}
        disableLocation={() => setLocationDisabled(true)}
      />
      {/* <PlaceAbout place={place} /> */}
    </>
  );
};

export default Place;

const centerCss: React.CSSProperties = {
  minHeight: '100vh',
};
