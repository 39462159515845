import {Box, chakra} from '@chakra-ui/react';
import CheckCircle from '../assets/icons/flaticons/check-circle.svg';
import CheckCircleFilled from '../assets/icons/flaticons/check-circle-filled.svg';
import Face from '../assets/icons/flaticons/face-expressionless.svg';
import FaceFilled from '../assets/icons/flaticons/face-expressionless-filled.svg';
import React from 'react';
import Icon from '../components/Icon';
import styled from 'styled-components';
import {ToastMessage} from '../components/toast';

const StyledHeaderIcon = styled(Box)`
  background: white;
  border: 2px solid #276acd;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  position: absolute;
  top: -45px;
`;

export const StyledCheckCircle = chakra(CheckCircle);
export const StyledCheckCircleFilled = chakra(CheckCircleFilled);
export const StyledFace = chakra(Face);
export const StyledFaceFilled = chakra(FaceFilled);

const RSVP_UPDATED_MESSAGE = 'Thanks! Your RSVP is updated.';

export const OPTIONS = [
  {
    renderIcon: (props: any) => null,
    renderSelectedIcon: (props: any) => null,
    color: '',
    borderColor: 'black',
    bgColor: 'transparent',
    text: 'Join Meet-Up',
    disabledText: "You didn't RSVP",
    message: (customMessage?: string) =>
      ToastMessage({
        status: 'success',
        showStatusIcon: true,
        text: customMessage || RSVP_UPDATED_MESSAGE,
      }),
  },
  {},
  {
    renderIcon: (props: any) => <StyledCheckCircle {...props} />,
    renderSelectedIcon: (props: any) => <StyledCheckCircleFilled {...props} />,
    color: '#409241',
    bgColor: '#EDF2E7',
    text: `I'm going`,
    disabledText: 'You Went',
    popup: {
      title: 'Cool!',
      subtitle: 'You’ve Unlocked Chat',
      description: 'Start chatting now with others who you might meet at this Meet-Up.',
      renderIcon: (...props: any) => (
        <StyledHeaderIcon w={90} h={90} bg={'#3FB8FC'} borderColor={'#3FB8FC'}>
          <Icon iconName="fi fi-sr-star" style={{color: 'white', fontSize: '45px'}} {...props} />
        </StyledHeaderIcon>
      ),
      ctaLabel: (n: number) => `Chat Now ${n ? `with ${n} others` : ''}`,
      ctaLink: 'chat?tab=Thread',
    },
    message: (customMessage?: string) =>
      ToastMessage({
        status: 'success',
        showStatusIcon: true,
        text: customMessage || RSVP_UPDATED_MESSAGE,
      }),
  },
  //   {
  //     renderIcon: (props: any) => <StyledCheckCircle {...props} />,
  //     renderSelectedIcon: (props: any) => <StyledCheckCircleFilled {...props} />,
  //     color: '#409241',
  //     bgColor: '#EDF2E7',
  //     text: "I'm going",
  //     disabledText: 'You were there!',
  //     popup: {
  //       title: 'Cool!',
  //       subtitle: 'You’ve Unlocked Chat',
  //       description: 'Start chatting now with others who you might meet at this Event.',
  //       renderIcon: (...props: any) => (
  //         <StyledCheckCircleFilled
  //           {...props}
  //           pos="absolute"
  //           p={0}
  //           fill="#329241"
  //           w={90}
  //           h={90}
  //           boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
  //           borderRadius="100%"
  //         />
  //       ),
  //       ctaLabel: (n: number) => `Chat Now ${n ? `with ${n} others` : ''}`,
  //       ctaLink: 'chat?tab=Thread',
  //     },
  //     message: (customMessage?: string) =>
  //       ToastMessage({
  //         status: 'success',
  //         showStatusIcon: true,
  //         text: customMessage || RSVP_UPDATED_MESSAGE,
  //       }),
  //   },
  //   {
  //     renderIcon: (props: any) => <Icon iconName={'fi fi-rr-cross-circle'} {...props} />,
  //     renderSelectedIcon: (props: any) => <Icon iconName={'fi fi-sr-cross-circle'} {...props} />,
  //     color: 'darkYellow',
  //     bgColor: '#FFF1DC',
  //     text: 'I can’t make it',
  //     disabledText: 'You didn’t make it',
  //     popup: {
  //       title: 'We’ll Miss You',
  //       subtitle: 'Thanks for letting us know.',
  //       description: 'Even though you’re can’t make it, you can still chat with others that are.',
  //       renderIcon: (...props: any) => (
  //         <StyledHeaderIcon w={90} h={90} bg={'white'} p={0} border={'none'}>
  //           <Icon
  //             iconName="fi fi-sr-cross-circle"
  //             style={{color: '#F9AC39', fontSize: '90px'}}
  //             {...props}
  //           />
  //         </StyledHeaderIcon>
  //       ),
  //       ctaLabel: (n: number) => `Chat Now ${n ? `with ${n} others` : ''}`,
  //       ctaLink: 'chat?tab=Thread',
  //     },
  //     message: (customMessage?: string) =>
  //       ToastMessage({
  //         status: 'success',
  //         showStatusIcon: true,
  //         text: customMessage || RSVP_UPDATED_MESSAGE,
  //       }),
  //   },
  {
    renderIcon: (props: any) => <Icon iconName={'fi fi-rr-cross-circle'} {...props} />,
    renderSelectedIcon: (props: any) => <Icon iconName={'fi fi-sr-cross-circle'} {...props} />,
    color: 'rose',
    bgColor: '#FDEAEC',
    text: 'I can’t make it',
    disabledText: "You Weren't Interested",
    popup: {
      title: 'Not for you?',
      subtitle: 'Thanks for letting us know.',
      description:
        'You won’t see updates about this event, and you won’t have access to the group Chat. Change to “I can’t make it” instead and stay in the chat?',
      renderIcon: (...props: any) => (
        <StyledHeaderIcon w={90} h={90} bg={'white'} p={0} border={'none'}>
          <Icon
            iconName="fi fi-sr-cross-circle"
            style={{color: '#F9AC39', fontSize: '90px'}}
            {...props}
          />
        </StyledHeaderIcon>
      ),
      ctaLabel: () => 'Yes',
      ctaLink: 'chat?tab=Thread',
      closeLabel: 'No',
    },
    message: (customMessage?: string) =>
      ToastMessage({
        status: 'success',
        showStatusIcon: true,
        text: customMessage || RSVP_UPDATED_MESSAGE,
      }),
  },
  {},
];
