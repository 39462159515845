import {useQuery} from '@tanstack/react-query';
import { getYouTubeVideoID } from '../utils/youtube';
const YOUTUBE_API_KEY = process.env.YOUTUBE_API_KEY;

const fetchYouTubeVideoData = async (url) => {
  const videoId = getYouTubeVideoID(url);
  if (!videoId) {
    return null;
  }

  const response = await fetch(
    `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet,contentDetails,player`
  );

  if (!response.ok) {
    throw new Error('Error fetching video details');
  }

  const data = await response.json();
  if (data.items && data.items.length > 0) {
    return data.items[0];
  }

  return null;
};

export const useYouTubeVideoData = (url) => {
  return useQuery(['youtubeVideoData', url], () => fetchYouTubeVideoData(url), {
    enabled: !!url, // Only fetch if URL is provided
    retry: 1, // Retry once on failure
  });
};
