import {Flex, useTheme, Image} from '@chakra-ui/react';
import {find} from 'lodash';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {BlueButton} from '../../../components/button';
import {H3, TextG12} from '../../../components/typography';
import useAuth from '../../../hooks/useAuth';
import { Community } from "../../../types/community";
import {
  useFetchCommunityStripeURL,
  useFetchCommunityUserNum,
} from '../../../hooks/useCommunityComments';

const Header: React.FC<{community: Community}> = ({community}) => {
  const theme = useTheme();
  const fetchNumUsers = useFetchCommunityUserNum(community.id);
  const numUsers = fetchNumUsers.data?.data.count;
  const {user} = useAuth();
  const isInCommunity = find(user?.communities, {id: community.id});
  const navigate = useNavigate();
  const {data, isLoading, isError} = useFetchCommunityStripeURL(community.id, !isInCommunity);
  const stripLink = data?.data.link;

  return (
    <Flex
      position="relative"
      top="15px"
      width="90%"
      marginLeft="5%"
      transform="auto"
      translateY="-50%"
      marginBottom="-1.5rem"
      backgroundColor="white"
      boxShadow={`0 0 12px ${theme.colors.grey}`}
      borderRadius=".5rem"
      p="0.75rem"
      flexDirection={'column'}>
      <Image
        background="white"
        borderRadius="100%"
        w="80px"
        h="80px"
        src={community.logo}
        position="absolute"
        top="-40px"
      />
      <H3 mt="40px">{community.name}</H3>
      <Flex mt={1} flexDir="row" color={theme.colors.grey} justifyContent="space-between">
        <TextG12>{numUsers} Members</TextG12>
        <TextG12 fontWeight="500">{`$${community.monthly_price_usd}/Month`}</TextG12>
      </Flex>

      <BlueButton
        isDisabled={(isLoading || isError) && !isInCommunity}
        mt={4}
        mb={1}
        onClick={() => {
          isInCommunity
            ? navigate(`/communities/${community.id}/chat`)
            : window.location.assign(stripLink);
        }}>
        {isInCommunity ? (
          <TextG12>You are already in! Join the Chat</TextG12>
        ) : (
          <TextG12>Join the Community</TextG12>
        )}
      </BlueButton>
    </Flex>
  );
};

export default Header;
