import {
  AbsoluteCenter,
  Box,
  Center,
  Fade,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Icon from '../Icon';
import {TextA24, TextG10, TextG12, TextG14} from '../typography';
import {VideoTask} from './GuidedExperienceWidget';
import NextVideoData from './NextVideoData';
import VideoDescription from './VideoDescription';
import VideoPreview from './VideoPreview';
import WidgetVideoPlayer, {ProgressState} from './WidgetVideoPlayer';
import JourneyBadge from './JouryneyBadge';
import {BlueButton} from '../button';
import {motion} from 'framer-motion';
import GuidedJourneyHeader from './GuidedJourneyHeader';

interface GuidedVideoModalProps {
  url: string;
  show: boolean;
  setShow: (show: boolean) => void;
  setModalUrl: (url: string) => void;
  onVideoEnd: () => void;
  onVideoProgress?: (progress: ProgressState) => void;
  task?: VideoTask;
  getNextVideo: (url: string) => VideoTask | null;
  videoCompletedPlaying?: boolean;
  setVideoCompletedPlaying: (playing: boolean) => void;
  taskNum?: number;
  totalTasks?: number;
  setBottomSheetOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  bottomSheetOpen?: boolean;
}

const GuidedVideoModal = ({
                            url,
                            show,
                            setShow,
                            setModalUrl,
                            onVideoEnd = () => {},
                            task,
                            getNextVideo,
                            videoCompletedPlaying,
                            setVideoCompletedPlaying,
                            taskNum,
                            totalTasks,
                            onVideoProgress,
                            setBottomSheetOpen,
                            bottomSheetOpen,
                          }: GuidedVideoModalProps) => {
  const theme = useTheme();
  const nextVideo = useMemo(() => getNextVideo(url), [url, getNextVideo]);
  const [videoPlaying, setVideoPlaying] = useState(true);
  const modalRef = useRef(null);
  const {isOpen, onOpen, onClose} = useDisclosure();
  useEffect(() => (show ? onOpen() : onClose()), [show]);

  const isLastTask = taskNum == totalTasks;

  useEffect(() => {
    if (isOpen) {
      setVideoPlaying(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (url && isOpen) {
      setVideoPlaying(true);
    }
  }, [url]);

  const onNextVideoClick = useCallback(() => {
    if (nextVideo) {
      setModalUrl(nextVideo.url);
      setVideoCompletedPlaying(false);
      setVideoPlaying(true);
    }
  }, [nextVideo]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  useEffect(() => {
    if (!bottomSheetOpen && isOpen) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 100);
    }
  }, [bottomSheetOpen, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setVideoPlaying(true);
    }
  }, [isOpen]);

  return (
      <Modal
          blockScrollOnMount={false}
          size="full"
          isOpen={isOpen}
          onClose={() => {
            setShow(false);
            setVideoCompletedPlaying(false);
          }}>
        <ModalOverlay background={theme.colors.lightBlack} />
        <ModalContent background={theme.colors.lightBlack} ref={modalRef}>
          <ModalCloseButton
              mt={3}
              mr={2}
              background="white"
              borderRadius="100%"
              _focus={{boxShadow: 'none'}}
          />

          <AbsoluteCenter w="100%" h="60%" top="40% !important">
            {!videoCompletedPlaying && (
                <Box px={4} color="white !important">
                  <GuidedJourneyHeader hideBadge setBottomSheetOpen={setBottomSheetOpen} />
                </Box>
            )}
            {!videoCompletedPlaying ? (
                <WidgetVideoPlayer
                    url={url}
                    playing={videoPlaying}
                    setModalUrl={setModalUrl}
                    onVideoEnd={onVideoEnd}
                    setVideoPlaying={setVideoPlaying}
                    onProgress={onVideoProgress}
                />
            ) : (
                nextVideo && (
                    <AbsoluteCenter
                        borderRadius="7px"
                        background="black"
                        px={3}
                        pt={4}
                        pb={6}
                        w="100%"
                        h="fit-content">
                      <Flex flexDir="column" gap="20px">
                        {!isLastTask ? (
                            <NextVideoData
                                variant="large"
                                textColor="white"
                                url={nextVideo.url}
                                thumbnail={nextVideo.thumbnail}
                                task={nextVideo}
                                taskNum={(taskNum || 0) + 1}
                                totalTasks={totalTasks}
                                setBottomSheetOpen={setBottomSheetOpen}
                                onPreviewClick={onNextVideoClick}
                            />
                        ) : (
                            <Flex
                                flexDir="column"
                                alignItems="center"
                                textAlign="center"
                                gap="20px"
                                mb="10px">
                              <TextA24 fontWeight="700" color="white">
                                Congratulations!
                              </TextA24>
                              <motion.div
                                  initial={{opacity: 0, y: -20}}
                                  animate={{opacity: 1, y: 0}}
                                  transition={{duration: 0.5, delay: 0.3}}>
                                {/* <motion.div
                          style={{borderRadius: '100%', width: '60px', height: '60px'}}
                          initial={{boxShadow: '0px 0px 15px rgba(255, 255, 255, 0.5)'}}
                          animate={{boxShadow: '0px 0px 15px rgba(255, 255, 255, 0)'}}
                          transition={{duration: 1, ease: 'easeOut', delay: 0.5}} // Adjust duration and easing as needed
                        > */}
                                <JourneyBadge w="64px" h="64px" />
                                {/* </motion.div> */}
                              </motion.div>
                              <TextG14 color="white" px="5%">
                                You've completed the <b>Get Plugged-in</b> Journey. Now get out there and
                                enjoy the community!
                              </TextG14>
                              <BlueButton w="80%" onClick={() => setShow(false)}>
                                I'm In!
                              </BlueButton>
                            </Flex>
                        )}

                        <Box
                            w="40%"
                            onClick={() => {
                              setVideoCompletedPlaying(false);
                              setVideoPlaying(true);
                            }}>
                          <VideoPreview
                              url={url}
                              task={task}
                              taskNum={taskNum}
                              totalTasks={totalTasks}
                              thumbnail={task?.thumbnail}
                              hideDetails
                              callToAction="Rewatch Last Video"
                              buttonFontSize="8px"
                              buttonFontLineHeight="12px"
                              playButtonColor="white"
                              playButtonFontColor={theme.colors.blue}
                              playIcon={
                                <Icon
                                    iconName="fi-rr-rotate-left"
                                    style={{
                                      color: theme.colors.blue,
                                      fontSize: '10px',
                                      width: '10px',
                                      height: '10px',
                                    }}
                                />
                              }
                          />
                        </Box>
                      </Flex>
                    </AbsoluteCenter>
                )
            )}
            {videoCompletedPlaying && !isLastTask && (
                <Center top="100%" position="relative">
                  <BlueButton w="50%" onClick={() => setShow(false)}>
                    <TextG12>I'm Done For Today</TextG12>
                  </BlueButton>
                </Center>
            )}
            {!videoCompletedPlaying && (
                <Box px={4} py={4}>
                  <VideoDescription url={url} task={task} />
                </Box>
            )}
          </AbsoluteCenter>
        </ModalContent>
      </Modal>
  );
};

export default GuidedVideoModal;
