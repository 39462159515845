import {Box, Center, Flex} from '@chakra-ui/react';
import React from 'react';
import {Community} from '../../types/community';
import Icon from '../Icon';
import {TextA12} from '../typography';

const ExclusiveCommunityLabel = ({
  community,
  isSlide = false,
  hasCardBadge = false,
}: {
  community: Community;
  isSlide?: boolean;
  hasCardBadge?: boolean;
}) => {
  return (
    <Box
      zIndex={-1}
      borderTopRadius="8px"
      pos="relative"
      top={hasCardBadge ? '4px' : '8px'}
      w={isSlide ? '97.5%' : '100%'}
      ml={isSlide ? '1.25%' : 0}
      pb={hasCardBadge ? '16px' : '12px'}
      pt={2}
      px={3}
      background="#FED177">
      <Center>
        <Flex gap={2}>
          <Icon iconName="fi-sr-bolt" style={{fontSize: '14px'}} />
          <TextA12 fontWeight="700">Exclusive for {community.name}</TextA12>
        </Flex>
      </Center>
    </Box>
  )
}

export default ExclusiveCommunityLabel
