import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ModalNotificationActions} from './ModalNotificationSlice';
import {Box, Center, HStack, Image, useTheme, VStack} from '@chakra-ui/react';
import {H2, TextG12, TextG14} from '../../components/typography';
import {BlueButton} from '../../components/button';
import Modal from '../../components/Modals/Modal';
import HighFive from '../../assets/images/high-five.png';
import Check from '../../assets/images/check.png';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import useBookmark from '../../hooks/useBookmark';
import useOfferModal from '../../hooks/useOfferModal';
import {User} from '../../hooks/useAuth';
import Icon from '../../components/Icon';

const StyledHeaderIcon = styled(Box)`
  background: white;
  border: 2px solid #276acd;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  position: absolute;
  top: -45px;
`;

interface ModalNotificationProps {
  hasActiveMembership?: string;
  placeData?: any;
  eventData?: any;
  image?: string;
  title?: string;
  checkinMessage?: string;
  points?: string;
  bonusPoints?: number;
  closeLabel?: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaLink?: string;
  closeButton?: string;
  bottomCtaLabel?: string;
  bottomCtaLink?: string;
}

// TODO pass modal sheet content from outside of this component.
const ModalNotification = ({
  hasActiveMembership,
  placeData,
  eventData,
  image = HighFive,
  title = 'High Five!',
  checkinMessage,
  points,
  bonusPoints,
  closeLabel = 'Close',
  subtitle,
  ctaLabel,
  ctaLink,
  closeButton = 'false',
  bottomCtaLabel,
  bottomCtaLink,
}: ModalNotificationProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const place = placeData ? JSON.parse(placeData) : '';
  const event = eventData ? JSON.parse(eventData) : '';
  const {handleOnBookmarkClick} = useBookmark(place, 'place', true);
  const {showOfferModal} = useOfferModal();

  useEffect(() => {
    return () => {
      dispatch(ModalNotificationActions.popNotification());
    };
  }, []);

  useEffect(() => {
    if (!show) {
      dispatch(ModalNotificationActions.popNotification());
    }
  }, [show]);

  return (
    <Modal
      isCentered={true}
      closeOnOverlayClick={false}
      show={show}
      closeButton={closeButton == 'true' ? true : false}
      header={
        <>
          <Center pb={12}>
            <StyledHeaderIcon>
              <Image src={image} />
            </StyledHeaderIcon>
          </Center>
          <Center>
            <VStack>
              {title && <H2 m="0 !important">{title}</H2>}
              <Box color="white" borderRadius="10px" py={1} px={2} background={theme.colors.rose}>
                <TextG12 fontWeight={500}>{`+${(
                  Number(points || 0) + Number(bonusPoints || 0)
                ).toLocaleString()} Points`}</TextG12>
              </Box>
            </VStack>
          </Center>
        </>
      }>
      <VStack pb={4} spacing={4}>
        <VStack alignItems="flex-start">
          <HStack spacing={1} alignItems="flex-start">
            <Image src={Check} alt="Success" />
            {checkinMessage ? (
              <TextG12 fontWeight={500}>{checkinMessage}</TextG12>
            ) : (
              <TextG12 fontWeight={500}>
                {`You've earned ${points?.toLocaleString()} points for this Check-in.`}
              </TextG12>
            )}
          </HStack>
          {bonusPoints && bonusPoints > 0 ? (
            <HStack spacing={1} alignItems="flex-start">
              <Image src={Check} alt="Success" />
              <TextG12
                fontWeight={500}
                color={
                  theme.colors.rose
                }>{`+${bonusPoints} Points - Premium Member Boost`}</TextG12>
            </HStack>
          ) : hasActiveMembership == 'false' && Number(points) > 0 ? (
            <HStack spacing={1} borderRadius="4px" px={2} py={1} background="#FFEDF6">
              <Icon
                style={{fontSize: '12px', color: theme.colors.rose}}
                iconName="fi-rr-exclamation"
              />
              <TextG12 fontWeight={500} color={theme.colors.rose}>
                Earn up to 4x points by going premium
              </TextG12>
            </HStack>
          ) : null}
        </VStack>
        {subtitle && (
          <Center>
            <TextG12 textAlign={'center'} fontWeight={500} color={theme.colors.grey}>
              {subtitle}
            </TextG12>
          </Center>
        )}
        {ctaLabel ? (
          <Center w={'100%'}>
            <BlueButton
              onClick={(e) => {
                setShow(false);
                if (ctaLink === 'offer-redemption') {
                  showOfferModal({offer: event.locked_offer});
                  return;
                }
                if (ctaLink === 'bookmark') {
                  handleOnBookmarkClick(e);
                  return;
                }
                if (ctaLink) {
                  navigate(ctaLink);
                }
              }}
              w={'100%'}>
              {ctaLabel}
            </BlueButton>
          </Center>
        ) : null}
        {bottomCtaLabel ? (
          <TextG12
            textAlign={'center'}
            color={theme.colors.rose}
            onClick={(e) => {
              setShow(false);
              if (bottomCtaLink) {
                navigate(bottomCtaLink);
              }
            }}
            w={'100%'}>
            {bottomCtaLabel}
          </TextG12>
        ) : null}
        {closeLabel && (
          <TextG12 onClick={() => setShow(false)} textAlign={'center'} color={theme.colors.grey}>
            {closeLabel}
          </TextG12>
        )}
      </VStack>
    </Modal>
  );
};

export default ModalNotification;
