import {Box, Divider, HStack, Stack, useTheme, VStack} from '@chakra-ui/react';
import React from 'react';
import {H3, TextG12, TextG14} from '../../../components/typography';
import {Event} from '../../../types/article';
import PlaceCard from '../../../components/Articles/PlaceCard';
import getPlaceAddress from '../../../utils/address';
import TopicsOfInterest from '../../TopicsOfInterest';

const EventAbout = ({event}: {event: Event}) => {
  const theme = useTheme();

  return (
    <>
      <VStack mt={0} mb={4} px={4} spacing={4} alignItems={'start'}>
        <Box w="100%">
          <TextG14 fontWeight="500" mb={2}>
            Event details:
          </TextG14>
          <TextG12
            className="inner-content"
            dangerouslySetInnerHTML={{__html: event.description}}
            color={theme.colors.lightBlack}>
            {}
          </TextG12>
        </Box>
        {event?.topics_of_interest && event?.topics_of_interest.length && (
          <TopicsOfInterest topicsOfInterest={event?.topics_of_interest} />
        )}
        <Divider />
        <HStack spacing={2}>
          <i className="fi fi-rr-marker"></i>
          <TextG12 fontWeight={'500'}>
            {event.place ? getPlaceAddress(event.place) : event.location}
          </TextG12>
        </HStack>
      </VStack>
    </>
  );
};

export default EventAbout;
