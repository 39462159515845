import React from 'react';
import {Flex, Grid, GridItem, Skeleton, SkeletonText, Stack, StackProps} from '@chakra-ui/react';

interface SkeletonColumns extends StackProps {
  numOfColumns?: number;
  numOfRows?: number;
  hasTitle?: boolean;
  cardHeight?: number | string;
  cardBorderRadius?: number | string;
}

export function SkeletonLoader({
  numOfColumns = 1,
  hasTitle = true,
  numOfRows,
  cardHeight = '170px',
  cardBorderRadius,
  ...rest
}: SkeletonColumns) {
  const widthPropValue = numOfColumns % 2 == 0 ? 100 / numOfColumns - 2 : 100;

  return (
    <Stack {...rest}>
      {hasTitle && (
        <Skeleton mb="1" width={`${(widthPropValue * numOfColumns) / 2}%`} height="15px"></Skeleton>
      )}
      {numOfColumns && numOfRows ? (
        <Grid
          templateRows={`repeat(${numOfRows}, ${numOfRows}fr)`}
          templateColumns={`repeat(${numOfColumns}, ${numOfRows}fr)`}
          rowGap={3}
          columnGap={2.5}
          position="relative">
          {[...Array(numOfColumns * numOfRows)].map((item, index) => {
            return (
              <div key={index} style={{width: '100%'}}>
                <Skeleton height={cardHeight} borderRadius={cardBorderRadius}></Skeleton>
                {/* <Skeleton mt='3' ml='4' width={`${widthPropValue * numOfColumns * 3/4}%`} height='15px'></Skeleton>
							<SkeletonText mt='3' ml='4' mr='4' noOfLines={3}></SkeletonText> */}
              </div>
            );
          })}
        </Grid>
      ) : (
        <Flex
          justifyContent={'space-between'}
          flexDirection={numOfColumns % 2 == 0 ? 'row' : 'column'}>
          {[...Array(numOfColumns)].map((item, index) => {
            return (
              <div key={index} style={{width: `${widthPropValue}%`}}>
                <Skeleton height={cardHeight} borderRadius={cardBorderRadius}></Skeleton>
                {/* <Skeleton mt='3' ml='4' width={`${widthPropValue * numOfColumns * 3/4}%`} height='15px'></Skeleton>
							<SkeletonText mt='3' ml='4' mr='4' noOfLines={3}></SkeletonText> */}
              </div>
            );
          })}
        </Flex>
      )}
    </Stack>
  );
}
