import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import {TextA18} from '../../components/typography';

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Flex background={theme.colors.veryLightBlue} px={3} py={5} align="center" w="100%">
      <BackButton onClick={() => navigate(`/my-stuff?tab=Passes`)} />
      <TextA18 fontWeight="700" w="100%" textAlign="center" marginLeft="-38px">
        Passes
      </TextA18>
    </Flex>
  );
};

export default Header;
