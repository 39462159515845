import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {BlueButton} from '../button';
import {TextG12} from '../typography';
import {useTheme} from '@chakra-ui/react';

interface ApplyButtonProps {
  onClick?: () => void;
  preview?: boolean;
  setSignUpRequiredOpen?: any;
  inline?: boolean;
}

const ApplyButton: React.FC<ApplyButtonProps> = ({
  onClick,
  preview = false,
  setSignUpRequiredOpen,
  inline = true,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = () => {
    if (preview) {
      if (setSignUpRequiredOpen) {
        setSignUpRequiredOpen(true);
      }
      return;
    }

    if (onClick) {
      onClick();
    } else {
      searchParams.set('openForm', 'true');
      setSearchParams(searchParams);
    }
  };

  return (
    <BlueButton
      w="100%"
      my={inline ? '0' : '1rem'}
      px={3}
      py={inline ? 2 : 3}
      background={preview ? theme.colors.grey : theme.colors.blue}
      onClick={handleClick}
      display="inline-flex"
      verticalAlign="middle"
      height="auto"
      minW="auto">
      <TextG12 fontWeight="500">Request For Application</TextG12>
    </BlueButton>
  );
};

export const createApplyButton = (props: any) => {
  return (
    <ApplyButton
      preview={props.preview}
      setSignUpRequiredOpen={props.setSignUpRequiredOpen}
      inline={props.inline}
      key={props.key}
    />
  );
};

export default ApplyButton;
