import {Flex, HStack, Image, Skeleton, useTheme, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ChatCard from '.';
import {Event} from '../../../types/article';
import DefaultEventImage from '../../../assets/images/default_event.png';
import {TextG12, TextG14} from '../../../components/typography';
import {getEventDate} from '../../../utils/date';
import {useFetchEventRSVPStats} from '../../../hooks/useEvent';
import {capitalize, find} from 'lodash';
import {useNewEventChatMessages} from '../../../hooks/useEvents';
import DotSeparator from '../../../components/DotSeparator';
import Icon from '../../../components/Icon';
import BottomSheetSelect from '../../../components/BottomSheetSelect';
import {ToastMessage} from '../../../components/toast';

const EventChatCard = ({event, closed = false}: {event: Event; closed?: boolean}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const fetchRSVPStats = useFetchEventRSVPStats(event.id);
  const rsvpStats = fetchRSVPStats.data?.data.rsvp_stats;
  const numUsers = rsvpStats?.INTERESTED + rsvpStats?.GOING + rsvpStats?.NOT_GOING || 1;
  const {data} = useNewEventChatMessages();
  const numUnread = find(data?.data, {id: event.id})?.new_messages_count || 0;
  const [optionsOpen, setOptionsOpen] = useState(false);

  const isWalkaboutEvent = Boolean(event.is_walkabout_official);

  if (!isWalkaboutEvent && !event.is_meetup) {
    return null;
  }

  if (fetchRSVPStats.isLoading) {
    return (
      <ChatCard>
        <Skeleton height="68px" />
      </ChatCard>
    );
  }

  return (
    <ChatCard>
      <BottomSheetSelect
        options={[
          {
            option: 'Share',
            renderIcon: (props: any) => <Icon iconName={'fi-rr-share'} {...props} />,
            color: 'lighterBlue',
            text: 'Share',
          },
        ]}
        openModal={optionsOpen}
        onModalClose={() => {
          setOptionsOpen(false);
        }}
        onClick={() => {
          const href = location.origin + `/event/${event.id}`;
          navigator.clipboard.writeText(href);
          ToastMessage({
            status: 'success',
            showStatusIcon: true,
            text: 'Link copied. Paste it anywhere and invite others to this event!',
          });

          setOptionsOpen(false);
        }}
      />
      <HStack align="flex-start" onClick={() => !closed && navigate(`/event/${event.id}/chat`)}>
        <Image
          borderRadius="10px"
          w="50px"
          h="50px"
          objectFit="cover"
          //fallbackSrc={DefaultEventImage}
          src={event.image_url || DefaultEventImage}
        />
        <VStack align="left" w="100%" gap="5px">
          <Flex align="center" justify="space-between">
            <TextG14
              w="270px"
              noOfLines={1}
              fontWeight="500"
              color={closed ? theme.colors.lightestGrey : theme.colors.lightBlack}>
              {event.title}
            </TextG14>
            <Flex
              align="center"
              gap={0.5}
              zIndex={100}
              onClick={(e) => {
                if (!closed) {
                  e.preventDefault();
                  e.stopPropagation();

                  setOptionsOpen(true);
                }
              }}>
              {!closed && (
                <Icon
                  iconName="fi-rr-menu-dots"
                  style={{
                    fontSize: '16px',
                    color: theme.colors.lightestGrey,
                    width: '16px',
                    height: '16px',
                  }}
                />
              )}
            </Flex>
          </Flex>
          <TextG12 noOfLines={1} color={closed ? theme.colors.lightestGrey : theme.colors.grey}>
            {capitalize(getEventDate(event.start_date_time, event.end_date_time))}
          </TextG12>
          <HStack gap={2}>
            <TextG12 color={closed ? theme.colors.lightestGrey : theme.colors.grey}>
              {`${numUsers}`} {numUsers <= 1 ? 'Person' : 'People'}
            </TextG12>
            {numUnread > 0 && (
              <>
                <DotSeparator
                  color={theme.colors.rose}
                  width="4px"
                  height="4px"
                  marginLeft="0"
                  marginRight="0"
                  marginTop="0.25rem"
                />
                <TextG12 color={theme.colors.rose}>
                  {numUnread == 1 ? 'New Message' : 'New Messsages'}
                </TextG12>
              </>
            )}
          </HStack>
        </VStack>
      </HStack>
    </ChatCard>
  );
};

export default EventChatCard;
