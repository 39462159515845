import {useTheme} from '@chakra-ui/react';
import {map} from 'lodash';
import React, {useMemo} from 'react';
import Select, {components, MultiValue} from 'react-select';
import {useStatesList} from '../../hooks/useStateLIst';
import {TextG12} from '../typography';

const StateSelect = (props) => {
  const {statesList, stateListIsLoading} = useStatesList();
  const theme = useTheme();
  const options = useMemo(
    () =>
      map(statesList, (state) => ({
        value: state.id,
        id: state.id,
        label: state.name,
        abbreviation: state.abbreviation,
      })),
    [statesList]
  );

  return (
    <Select
      required={props.isRequired}
      menuPlacement="top"
      isLoading={stateListIsLoading}
      styles={props.styles}
      value={props.value}
      onChange={props.changeHandler}
      placeholder={
        props.hidePlaceholder ? null : <TextG12 color={theme.colors.lightestGrey}>State</TextG12>
      }
      isClearable={false}
      isSearchable={true}
      options={options}
      formatOptionLabel={(option, {context}) => {
        return context === 'menu' ? option.label : option.abbreviation;
      }}
    />
  );
};

export default StateSelect;
