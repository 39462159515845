import React, {useEffect, useState} from 'react';
import {addBookmark, removeBookmark} from '../endpoints/api';
import {ToastMessage} from '../components/toast';
import {Article} from '../components/Articles';
import {Link} from '@chakra-ui/react';
import {Place, Post, Event} from '../types/acrticle';
import {Offer} from '../types/acrticle';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useAuth from './useAuth';
import {map, set} from 'lodash';

/* 
  Invalidating this query causes this to return different places from the backend
  because places from the previous call already being seen. Iterate through query data
  update this place's bookmark instead of invalidating entire query.
*/
const updateInfiniteQueryData = (queryClient, queryKey, articleId, remove) => {
  const previousData = queryClient.getQueriesData([queryKey]);

  if (!previousData || previousData.length <= 0) return;

  map(previousData, (prevData, index) => {
    set(
      previousData,
      `[${index}][1].pages`,
      map(previousData[index][1]?.pages, (page) => {
        return {
          ...page,
          data: {
            ...page.data,
            data: map(page.data.data, (dData) => {
              return {
                ...dData,
                is_bookmarked: dData?.id == articleId ? !remove : dData?.is_bookmarked,
              };
            }),
          },
        };
      })
    );
  });

  // queryClient.setQueryData([queryKey], previousData);
};

// All the react-query queries related to bookmarks have to be invalidated when a bookmark is changed
const invalidateQueries = (queryClient, type, articleId, remove = false) => {
  if (type == 'place') {
    queryClient.removeQueries(['placesByType'], {
      predicate: (query) => query.queryKey[1].typeIds.length <= 0,
    });
    queryClient.invalidateQueries([`placesByType`]);

    // queryClient.invalidateQueries(['infinite-placesByType']);
    updateInfiniteQueryData(queryClient, 'infinite-placesByType', articleId, remove);
  }
  if (type == 'article') {
    queryClient.invalidateQueries([`post`]);
    queryClient.invalidateQueries(['posts']);
    //queryClient.invalidateQueries([`infinite-posts`]);
    updateInfiniteQueryData(queryClient, 'infinite-posts', articleId, remove);
  } else {
    queryClient.invalidateQueries([`${type}`, {id: articleId}]);
    queryClient.invalidateQueries([`${type}s`]);
    //queryClient.invalidateQueries([`infinite-${type}s`]);
    updateInfiniteQueryData(queryClient, `infinite-${type}s`, articleId, remove);
  }

  updateInfiniteQueryData(queryClient, 'infinite-global-search', articleId, remove);
  queryClient.invalidateQueries([`infinite-bookmarks`]);
  //updateInfiniteQueryData(queryClient, `infinite-bookmarks`, articleId, remove);
  queryClient.invalidateQueries([`current-home-context-content`]);
};

const bookmarkUrls = {
  place: {
    add: (placeId) => `places/${placeId}/bookmark`,
    remove: (placeId) => `places/${placeId}/remove-bookmark`,
  },
  event: {
    add: (eventId) => `events/${eventId}/add`,
    remove: (eventId) => `events/${eventId}/remove`,
  },
  offer: {
    add: (offerId) => `offers/${offerId}/bookmark`,
    remove: (offerId) => `offers/${offerId}/remove-bookmark`,
  },
};

export type ArticleType = Article | Post | Event | Place | Offer;

export const useAddBookmark = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({articleId, type = 'article'}: {articleId: number; type: string}) =>
      addBookmark({articleId: articleId, customUrl: bookmarkUrls[type]?.add}),
    {
      onSettled: (newComment, error, variables, context) => {
        invalidateQueries(queryClient, variables.type, variables.articleId);
      },
    }
  );
};

const useBookmark = (
  article: ArticleType,
  type: string = 'article',
  showAction: boolean = false
) => {
  const [isBookmarked, setIsBookmarked] = useState(article?.is_bookmarked);
  const [isChanging, setIsChanging] = useState(false);
  const queryClient = useQueryClient();
  const {user} = useAuth();

  useEffect(() => {
    if (isBookmarked !== article?.is_bookmarked) {
      setIsBookmarked(article?.is_bookmarked);
    }
  }, [article, article?.is_bookmarked]);

  const handleOnBookmarkClick = (e): any => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!article || isChanging) return;
    const changeToState = !isBookmarked;
    setIsChanging(true);
    setIsBookmarked(changeToState);

    if (changeToState) {
      return addBookmark({articleId: article?.id, customUrl: bookmarkUrls[type]?.add}).then(() => {
        setIsChanging(false);
        invalidateQueries(queryClient, type, article?.id);
        return ToastMessage({
          status: 'success',
          showStatusIcon: true,
          text: 'Saved in your Bookmarks!',
          ...(showAction && {
            action: <Link href={'/my-stuff?tab=bookmarks'}>View</Link>,
          }),
        });
      });
    } else {
      return removeBookmark({articleId: article?.id, customUrl: bookmarkUrls[type]?.remove}).then(
        () => {
          setIsChanging(false);
          invalidateQueries(queryClient, type, article?.id, true);
          return ToastMessage({
            status: 'info',
            text: 'Removed from your Bookmarks.',
          });
        }
      );
    }
  };

  return {
    isBookmarked,
    handleOnBookmarkClick,
  };
};

export default useBookmark;
