import {Center, Flex, Image, Spinner, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import SignupRequiredModal from '../../../components/SignupRequiredModal';
import {fetchPublicOffer} from '../../../endpoints/api';
import useAuth from '../../../hooks/useAuth';
import {useFetchPublicPlace} from '../../../hooks/useFetchPlace';
import useOfferModal from '../../../hooks/useOfferModal';
import {isMobile} from '../../../utils/deviceCheck';
import Header from '../Header';
import PlaceTabs from '../Tabs';

const PlacePreview = () => {
  const {uuid} = useParams();
  const {data, isLoading} = useFetchPublicPlace(uuid);
  const place = data?.data;
  const [signUpRequiredOpen, setSignUpRequiredOpen] = useState(false);
  const theme = useTheme();
  const {user} = useAuth();
  const [searchParams] = useSearchParams();

  const [inviteCode, setInviteCode] = useState('');

  useEffect(() => {
    if (!!searchParams.get('invite-code') && searchParams?.get('invite-code') !== '') {
      setInviteCode(searchParams?.get('invite-code') || '');
    }
  }, [searchParams]);

  const onSignupClicked = () => {
    if (window.self !== window.top) {
      // The page is being rendered in an iframe
      window.top ? (window.top.location.href = 'https://my.walkabout.app/soft-check') : null;
      return;
    } else {
      // The page is not in an iframe
      if (user) {
        navigate('/download');
      } else if (inviteCode) {
        navigate('/register', {state: {code: inviteCode}});
      } else {
        navigate('/soft-check');
      }
    }
  };

  const navigate = useNavigate();
  const {showOfferModal} = useOfferModal();

  useEffect(() => {
    if (searchParams.get('offer')) {
      fetchPublicOffer(searchParams.get('offer')).then(({data}) => {
        if (user && place && isMobile) {
          const currentQueryString = searchParams.toString();

          // Construct the new path with the additional `offer` parameter
          const newPath = currentQueryString
            ? `/place/${place.id}?${currentQueryString}&offer=${data.id}`
            : `/place/${place.id}?offer=${data.id}`;

          navigate(newPath, {replace: true});
        } else {
          showOfferModal({offer: data, isPreview: true});
        }
      });
    } else if (user && place) {
      const queryString = searchParams.toString();
      const newPath = queryString ? `/place/${place.id}?${queryString}` : `/place/${place.id}`;

      isMobile ? navigate(newPath, {replace: true}) : null;
    }
  }, [user, place, searchParams.get('offer')]);

  useEffect(() => {
    if (user && place) {
      isMobile ? navigate(`/place/${place.id}`, {replace: true}) : null; //navigate('/download', {replace: true});
    }
  }, [user, place]);

  if (isLoading || place === undefined) {
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Flex position="relative" height="275px">
        <Image
          src={place.default_media?.full_url}
          alt="cover image"
          position="absolute"
          width="100%"
          height="100%"
          objectFit="cover"
          zIndex="-1"
          borderBottomRadius="14px"
        />
        {/* <MediaGallery media={place.media} /> */}
      </Flex>
      <Header place={place} />
      <PlaceTabs
        setSignUpRequiredOpen={setSignUpRequiredOpen}
        preview
        place={place}
        placeOffers={place.offers}
        disableLocation={() => {}}
      />
      <SignupRequiredModal
        isOpen={signUpRequiredOpen}
        setIsOpen={setSignUpRequiredOpen}
        onButtonClicked={onSignupClicked}
      />
    </>
  );
};

export default PlacePreview;
