import {Box, Flex, useTheme} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TextG12} from '../../components/typography';
import {numberToCurrency} from '../../utils/price';

export const MAX_TICKET_QTY = 10;

const PassPurchaseQtyContainer = ({pass, quantity, setQuantity}) => {
  const theme = useTheme();

  const handleChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const total = parseFloat(pass.amount.replace(/[^\d.-]/g, '')) * quantity;

  return (
    <Flex
      align="center"
      justifyContent="space-between"
      py={3}
      w="100%"
      marginTop="5px !important"
      background="transparent">
      <Box borderRadius="8px" p={3} background="white" border={`1px solid ${theme.colors.grey}`}>
        <TextG12 fontWeight="500">
          <label style={{marginRight: '6px'}} htmlFor="quantity">
            Qty:
          </label>
          <select
            style={{fontWeight: '500'}}
            id="quantity"
            value={quantity}
            onChange={handleChange}>
            {[...Array(MAX_TICKET_QTY).keys()].map((num) => (
              <option key={num + 1} value={num + 1}>
                {num < 9 ? `0` : ``}
                {num + 1}
              </option>
            ))}
          </select>
        </TextG12>
      </Box>

      <Box borderRadius="8px" p={3} background="white" border={`1px solid ${theme.colors.grey}`}>
        <TextG12 fontWeight="500">
          <span style={{marginRight: '6px'}}>Total:</span>
          <span>{numberToCurrency(total)}</span>
        </TextG12>
      </Box>
    </Flex>
  );
};

export default PassPurchaseQtyContainer;
