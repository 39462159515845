import React, {useMemo} from 'react';
import {Box, Image, useTheme} from '@chakra-ui/react';
import styled from 'styled-components';
import FallbackImg from '../../assets/images/fallback.jpg';
import {toLower} from 'lodash';
import useTrackEvent from '../../hooks/useTrackEvent';
import dayjs from 'dayjs';

// const Cover = styled(Image)`
//   width: 100%;
//   height: 200px;
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
//   object-fit: cover;
// `;

const Cover = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  border-radius: ${(props) => props.borderRadius};
`;

const ChildContainer = styled(Box)`
  width: 100%;
  height: fit-content;
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: ${(props) => props.borderRadius};
  z-index: 2;
`;

const ImageOverlay = styled(Box)`
  width: 100%;
  height: ${(props) =>
    props.$overlayBackgroundColor && props.$overlayHeight ? props.$overlayHeight : '100%'};
  ${(props) =>
    props.$overlayBackgroundColor
      ? `background-color: ${props.$overlayBackgroundColor};`
      : `background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.33) ${props.$overlayHeight},
    transparent ${props.$overlayTransparency}
  );`}
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: ${(props) => props.borderRadius};
`;

const Card = ({
  children,
  coverImage,
  leftTopItem,
  rightTopItem,
  childPaddingX,
  childPaddingY,
  height,
  overlayHeight = '33%',
  overlayTransparency = '66%',
  onClick,
  borderRadius = '12px',
  overlayBackgroundColor = '',
  backgroundColor = '',
  childTop = '',
  article,
}) => {
  const theme = useTheme();
  const {addUserEvent} = useTrackEvent();
  return (
    <Box
      position="relative"
      height={height}
      borderRadius={borderRadius}
      onClick={() => {
        onClick ? onClick() : null;

        const articleType = article.is_meetup
          ? 'meetup'
          : article.type?.name
          ? 'place'
          : toLower(article.type);

        const articleId = article.id;

        addUserEvent({
          type: 'click',
          event_name: 'card_click',
          event_time: dayjs().format(),
          data: {itemType: articleType, itemId: articleId},
        });
      }}>
      <ImageOverlay
        borderRadius={borderRadius}
        $overlayHeight={overlayHeight}
        $overlayTransparency={overlayTransparency}
        $overlayBackgroundColor={overlayBackgroundColor}
      />
      <Cover
        borderRadius={borderRadius}
        fallbackStrategy="onError"
        loading={null}
        fallbackSrc={FallbackImg}
        src={coverImage}
        alt={'Cover image'}
        backgroundColor={backgroundColor}
      />
      {leftTopItem}
      {rightTopItem}
      <ChildContainer borderRadius={borderRadius} top={childTop}>
        <Box px={childPaddingX} py={childPaddingY}>
          {children}
        </Box>
      </ChildContainer>
    </Box>
  );
};

Card.defaultProps = {
  coverImage: null,
  leftTopItem: null,
  rightTopItem: null,
  childPaddingX: 4,
  childPaddingY: 3,
  height: '45vw',
  onClick: () => null,
};

export default Card;
