import {Center, Box, useTheme, BoxProps} from '@chakra-ui/react';
import React from 'react';
import {TextG10, TextG9} from '../typography';

interface CardBadgeProps extends BoxProps {
  label: string;
  center?: string;
}

const CardBadge = (props: CardBadgeProps) => {
  const theme = useTheme();

  if (props.center == 'true') {
    return (
      <Center>
        <Box
          outline="3px solid white"
          zIndex="1"
          position="relative"
          background="#FFEDF6"
          borderRadius="9px"
          padding="3px 6px"
          textAlign="center"
          {...props}>
          <TextG10 fontWeight="700" color={theme.colors.rose}>
            {props.label}
          </TextG10>
        </Box>
      </Center>
    );
  }

  return (
    <Box
      outline="3px solid white"
      zIndex="1"
      position="relative"
      background="#FFEDF6"
      borderRadius="9px"
      padding="3px 6px"
      textAlign="center"
      {...props}>
      <TextG10 fontWeight="700" color={theme.colors.rose}>
        {props.label}
      </TextG10>
    </Box>
  );
};

export default CardBadge;
