import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useTheme,
  Image,
} from '@chakra-ui/react';
import { filter } from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import useAuth from '../../hooks/useAuth';
import {TextG12, TextG14} from '../typography';
import CommunityOptionsList from './CommunityOptionsList';

export const ALL_COMMUNITY = {name: 'All', id: undefined};

const CommunitiesSelect = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const theme = useTheme();
  const {user} = useAuth();
  const communities = filter(user?.communities, (community) => community.is_visible_in_selector);
  const selectedCommunity = useSelector((state: any) => state.community.community);

  return (
    <Box onClick={() => onOpen()} borderRadius="15px" backgroundColor="#FFEED2" padding="5px 10px">
      <Flex alignItems="center" gap="5px" color={theme.colors.lightBlack}>
        {selectedCommunity.logo && (
          <Image
            w="20px"
            h="20px"
            borderRadius="100%"
            objectFit="scale-down"
            src={selectedCommunity.logo}
          />
        )}
        <TextG12 fontWeight="500">{selectedCommunity.name}</TextG12>
        <i
          className="fi fi-rr-angle-down"
          style={{fontSize: '10px', width: '10px', height: '10px'}}></i>
      </Flex>
      <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent mx={3} borderRadius="20px" padding="32px 26px">
          <ModalHeader p="0px">
            <TextG14 userSelect="none" fontWeight="700">
              Select community:
            </TextG14>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0px">
            <CommunityOptionsList
              communities={[ALL_COMMUNITY, ...communities]}
              selectedCommunity={selectedCommunity}
              //setSelectedCommunity={setSelectedCommunity}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CommunitiesSelect;
