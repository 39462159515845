import styled from 'styled-components';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import {Box} from '@chakra-ui/react';

const StyledBox = styled(Box)`
  font-size: 17px;
  line-height: 1.6;
  margin-bottom: 1em;
`;

const CommunityGuidelines = () => {
  const navigate = useNavigate();
  return (
    <div style={{marginTop: '60px', padding: '0 1.5rem 1.5rem 1.5rem'}}>
      <BackButton m="0.75rem" onClick={() => navigate(-1)} pos="absolute" top={0} left={0} />
      <h1 style={{fontSize: '32px', fontWeight: '700', textAlign: 'center', marginBottom: '1em'}}>
        Community Guidelines
      </h1>
      <div className="entry-content single-content">
        <StyledBox>
          Hey Friend, welcome to Walkabout! Get ready to dive in, meet awesome people just like you,
          and belong to a community that feels like home.&nbsp;
        </StyledBox>
        <StyledBox>
          The Walkabout Community is a safe space where everyone is welcome, so we have a few house
          rules to make sure we are always inclusive and respectful – it’s more fun for everyone
          that way.&nbsp;
        </StyledBox>
        <StyledBox>
          <strong>Respect and Kindness:&nbsp;</strong>Treat every community member, including
          businesses, with respect, kindness, and empathy. We celebrate diversity and believe
          everyone should feel valued here.&nbsp;&nbsp;Walkabout is a forum for sharing the good
          stuff, not to debate the bad stuff.
        </StyledBox>
        <StyledBox>
          <strong>Authenticity and Transparency</strong>: Stay real, keep it honest! There’s no
          better person to be than yourself. We want the real you here, and so does everyone else.
          That’s how real friends are made.
        </StyledBox>
        <StyledBox>
          <strong>Spam and Self-Promotion:</strong>&nbsp;No spam or off-topic stuff, cool? We want
          real friendships, not just random posts.
        </StyledBox>
        <StyledBox>
          <strong>Privacy and Confidentiality:</strong>&nbsp;Respect the privacy of others. Don’t
          share personal information without permission – keep it safe and considerate&nbsp; and in
          keeping with our policies.
        </StyledBox>
        <StyledBox>
          <strong>Report Inappropriate Behavior:</strong>&nbsp;See something on the app or in-person
          that makes you uncomfortable? Tell us! You can flag messages or email
          concerns@walkabout.app.&nbsp;
        </StyledBox>
        <StyledBox>
          Know that any violations of these guidelines may result in your account being suspended
          or&nbsp;removed from our app.&nbsp;
        </StyledBox>
        <StyledBox>
          Remember this, Walkabout is a place for like-minded people to connect, discover new things
          together, and experience more out of life and out of their community. By sticking to these
          guidelines, you’ll be part of making this a great place to be!
        </StyledBox>
        <StyledBox>Have fun out there, and know that we’re in this together 💪</StyledBox>
        <StyledBox>
          Cheers,&nbsp;
          <br />
          Jessica Young
          <br />
          Head of Community, Walkabout.app
        </StyledBox>
        <hr className="wp-block-separator has-alpha-channel-opacity" />
        <StyledBox></StyledBox>
      </div>
    </div>
  );
};

export default CommunityGuidelines;
