import {Box, Center, Collapse, Divider, Flex} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useYouTubeVideoData} from '../../hooks/useYoutube';
import {getYouTubeVideoDuration} from '../../utils/youtube';
import Icon from '../Icon';
import RenderNewLines from '../RenderNewLines';
import {TextG10, TextG14} from '../typography';
import {VideoTask} from './GuidedExperienceWidget';

const VideoDescription = ({url, task}: {url: string; task?: VideoTask}) => {
    const [duration, setDuration] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const {data} = useYouTubeVideoData(url);

    useEffect(() => {
        if (data) {
            if (task?.title) setTitle(task.title);
            else setTitle(data.snippet.title);

            if (task?.description) setDescription(task.description);
            else setDescription(data.snippet.description);

            setDuration(getYouTubeVideoDuration(data.contentDetails.duration));
        }
    }, [data, task]);

    return (
        <Box
            pointerEvents="none"
            cursor="none"
            w="100%"
            h="auto"
        >
            {/* <Center>
        <Icon
          style={{
            color: 'white',
            fontSize: '12px',
            transition: 'transform 0.3s ease',
            transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          iconName="fi-br-angle-up"
        />
      </Center> */}
            {/* <Collapse startingHeight="45px" in={isCollapsed}> */}
            <Box w="100%" h="auto" overflow="auto">
                <Flex flexDir="column" color="white" gap="5px">
                    <Flex gap="10px" alignItems="center">
                        <Icon
                            style={{
                                marginTop: '2px',
                                color: 'white',
                                fontSize: '16px',
                            }}
                            iconName="fi-rr-play-alt"
                        />
                        <TextG14 fontWeight="700">{title}</TextG14>
                    </Flex>
                    <TextG10>{`Duration: ${duration}`}</TextG10>
                    {/* <Divider my="10px" color="white" /> */}
                    {/* <TextG10>
              <RenderNewLines text={description} />
            </TextG10> */}
                </Flex>
            </Box>
            {/* </Collapse> */}
        </Box>
    );
};

export default VideoDescription;
