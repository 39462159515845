import {Box, useTheme, VStack} from '@chakra-ui/react';
import React from 'react';
import {OFFER_STEPS} from '..';
import {Offer, OfferType, Place} from '../../../../../../types/article';
import {getOfferDate} from '../../../../../../utils/date';
import {BlueButton} from '../../../../../button';
import {TextG10, TextG12, TextG14} from '../../../../../typography';

const RedemptionStepTwo = ({
  offer,
  setCurStep,
  redeemClicked,
  datePassed,
}: {
  offer: Offer;
  setCurStep: React.Dispatch<React.SetStateAction<OFFER_STEPS>>;
  redeemClicked: () => void;
  datePassed: boolean;
}) => {
  const theme = useTheme();
  const pointCost = offer.points_redeem_amount;

  return (
    <>
      <TextG10
        lineHeight="10px"
        py="2px"
        color={theme.colors.lightestGrey}
        px={4}
        fontWeight="500"
        isTruncated="&hellip;"
        noOfLines={1}>
        {`${datePassed ? 'EXPIRED ON' : 'EXPIRES'} ${getOfferDate(offer?.ends_at)?.toUpperCase()}`}
      </TextG10>
      <TextG10
        lineHeight="10px"
        mt="0 !important"
        color={theme.colors.lightestGrey}
        fontWeight="400">
        Limit {offer?.total_use_limit_per_user}x per person
      </TextG10>
      <Box
        pt={2}
        pb={4}
        px={2}
        textAlign="center"
        border={`1px solid ${theme.colors.lightGrey}`}
        borderRadius="7px">
        <VStack>
          <Box>
            <TextG14 display="inline" fontWeight="700">
              Are you ready to checkout and use
            </TextG14>
            <TextG14 display="inline" fontWeight="700" color={theme.colors.rose}>
              {` ${pointCost.toLocaleString()} `}
            </TextG14>
            <TextG14 display="inline" fontWeight="700">
              points to redeem this Offer?
            </TextG14>
          </Box>
          {offer.countdown_required && (
            <TextG12>
              {offer.offer_type === OfferType.EVENT_REDEMPTION
                ? `Tickets are non-refundable. For any questions, contact the event Host.`
                : `You'll be shown an Offer Code and have 5 minutes to use it for your purchase.`}
            </TextG12>
          )}
        </VStack>
      </Box>

      <BlueButton
        w="180px"
        onClick={redeemClicked}
        _focus={{boxShadow: 'none'}}
        _hover={{background: ''}}
        _active={{}}>
        <TextG12 color={theme.colors.white} fontWeight="500">Yes, I'm Ready!</TextG12>
      </BlueButton>

      <TextG10
        onClick={() => setCurStep(OFFER_STEPS.Initial)}
        textDecoration="underline"
        fontWeight="400"
        color={theme.colors.grey}>
        Not Now
      </TextG10>
    </>
  );
};

export default RedemptionStepTwo;
