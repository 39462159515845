import {Box, BoxProps, Flex, HStack, Image, Input, useTheme} from '@chakra-ui/react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {BlueButton} from '../../button';
import Icon from '../../Icon';
import {MentionsInput, Mention} from 'react-mentions';
import UserAvatar from '../../UserAvatar';
import {ChatComment, ChatMedia} from '.';
import {ImageFileToURL} from '../../../pages/Profile/Edit/CropImage';
import {find, map} from 'lodash';

const MAX_FILES = 4;

const defaultMentionStyle = {
  backgroundColor: '#cee4e5',
  //padding: '1px',
};

const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    height: '120px',
    border: '1px solid #E2E8F0',
    borderRadius: '6px',
  },

  '&multiLine': {
    control: {
      fontFamily: 'Gordita',
    },
    highlighter: {
      marginLeft: '0px',
      padding: '6px 40px 0 15px',
      border: 'none',
      outline: 'none',
      color: '#E02F84',
      zIndex: 100,
      pointerEvents: 'none',
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 80,
    },

    input: {
      padding: '8px 40px 0 30px',
      outline: 'none',
      paddingInlineStart: '1rem',
      overflow: 'auto',
      height: 80,
      marginLeft: '0 !important',
      marginBottom: '30px !important',
    },
  },

  suggestions: {
    zIndex: '9999',
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      maxHeight: '20vh',
      overflowY: 'auto',
      borderRadius: '6px',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      // '&focused': {
      //   backgroundColor: '#cee4e5',
      // },
    },
  },
};

interface CommentInputProps extends BoxProps {
  articleId?: number;
  communityId?: number;
  onSaveClick: (
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => void;
  onCloseClick: () => void;
  parentCommentId?: string;
  media?: ChatMedia[];
  defaultContent?: string;
  isReply?: boolean;
  parentComment?: ChatComment;
  fetchUsersHook: any;
}

const CommentInput = ({
  articleId,
  onSaveClick,
  onCloseClick,
  parentCommentId,
  media,
  defaultContent,
  isReply = false,
  parentComment,
  fetchUsersHook,
  ...rest
}: CommentInputProps) => {
  const [content, setContent] = useState<string>(defaultContent || '');
  const disabledButton = !content || content.length == 0;
  const ref = useRef<HTMLDivElement>(null);
  const mentionRef = useRef<HTMLElement>(null);
  const theme = useTheme();
  const {data} =
    fetchUsersHook && articleId
      ? fetchUsersHook(articleId)
      : fetchUsersHook
      ? fetchUsersHook()
      : {data: null};
  const [users, setUsers] = useState<Array<any>>([]);
  const inputElement = useRef<HTMLInputElement | null>(null);
  const [imageURLs, setImageURLs] = useState<Array<any>>(
    media?.map((image) => image.thumbnail_url) || []
  );
  //media?.map((image) => image.thumbnail_url) || []
  const [mediaToDelete, setMediaToDelete] = useState<Array<any>>([]);

  const handleUploadImage = useCallback(
    async ({target: {files}}) => {
      if (files && files.length > 0) {
        if (files.length > MAX_FILES || files.length + imageURLs.length > MAX_FILES) {
          alert(`You can only upload ${MAX_FILES} files.`);
          return;
        }
        for (const file of files) {
          const image: any = await ImageFileToURL(file);
          if (!imageURLs.includes(image)) setImageURLs((prev) => [...prev, image]); //URL.createObjectURL(file)
        }
      }
    },
    [imageURLs]
  );

  useEffect(() => {
    if (!data || !data.pages) return;
    const tmpUsers: Array<any> = [];
    data.pages.map((p) =>
      p.data.data.map((user) =>
        tmpUsers.push({id: user.id, display: user.handle, avatar: user.profile_photo_url})
      )
    );
    setUsers(tmpUsers);
  }, [data?.pages]);

  useEffect(() => {
    if (parentComment && isReply && content.length == 0) {
      const user = parentComment.user;
      setContent(`@${user.id}[${user.handle}] `);
    }
  }, [isReply]);

  const removeMediaClicked = (image) => {
    setImageURLs((prev) => prev.filter((item) => item !== image));

    const toDelete = find(media, {thumbnail_url: image});

    if (toDelete) {
      setMediaToDelete((prev) => [...prev, toDelete]);
    }
  };

  const onAtClicked = () => {
    if (content.length == 0) {
      setContent('@');
    } else if (content.endsWith(' ')) {
      setContent((prev) => `${prev}@`);
    } else {
      setContent((prev) => `${prev} @`);
    }

    mentionRef.current?.focus();
  };

  return (
    <>
      <Box w="100%" position="relative" background="white" {...rest}>
        <Box ref={ref} />
        <MentionsInput
          inputRef={mentionRef}
          suggestionsPortalHost={document.body}
          // customSuggestionsContainer={
          //   isIphone
          //     ? (children) => <div style={{position: 'absolute', top: '55px'}}>{children}</div>
          //     : undefined
          // }
          autoFocus
          forceSuggestionsAboveCursor
          //allowSuggestionsAboveCursor
          style={defaultStyle}
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          placeholder={isReply ? 'Reply to message' : 'Message everyone'}>
          <Mention
            displayTransform={(id, username) => `@${username}`}
            markup="@__id__[__display__]"
            appendSpaceOnAdd
            renderSuggestion={(search) => {
              return (
                <Flex w="50vw" flexDir="row" align="center" gap={4}>
                  <UserAvatar
                    user={{...search, handle: search.display, profilePhotoUrl: search.avatar}}
                    size="xs"
                    w="28px"
                  />
                  {search.display}
                </Flex>
              );
            }}
            style={defaultMentionStyle}
            trigger="@"
            data={users}
          />
        </MentionsInput>
        <BlueButton
          _selected={{}}
          _hover={{}}
          position="absolute"
          bottom={3}
          right={3}
          zIndex="100"
          p={0}
          minW="30px"
          minH="30px"
          maxH="30px"
          maxW="30px"
          isDisabled={disabledButton}
          onClick={() => {
            const thumbnailURLs = media?.map((image) => image.thumbnail_url);
            const filteredImages = imageURLs.filter((image) => !thumbnailURLs?.includes(image));
            onSaveClick(content, parentCommentId, filteredImages, mediaToDelete);
          }}>
          <Icon
            onClick={onCloseClick}
            style={{color: 'white', fontSize: '12px'}}
            iconName="fi-rr-arrow-right"
          />
        </BlueButton>
        <Box zIndex="100" position="absolute" textAlign="center" bottom={3} left={3}>
          <HStack gap={2}>
            <Icon
              onClick={onAtClicked}
              style={{color: 'grey', fontSize: '20px', width: '20px', height: '20px'}}
              iconName="fi-rr-at"
            />
            <label htmlFor="image-file">
              <Input
                ref={inputElement}
                type="file"
                id="image-file"
                accept="image/*;capture=camera"
                onChange={handleUploadImage}
                hidden
                multiple
              />
              <Icon
                style={{
                  display: 'block',
                  color: 'grey',
                  fontSize: '20px',
                  width: '20px',
                  height: '20px',
                }}
                iconName="fi-rr-add-image"
              />
            </label>
          </HStack>
        </Box>
        <Box
          zIndex="100"
          onClick={onCloseClick}
          position="absolute"
          textAlign="center"
          top={3}
          right={3}
          minW="30px"
          minH="30px"
          maxH="30px"
          maxW="30px">
          <Icon style={{color: 'grey', fontSize: '16px'}} iconName="fi-rr-cross" />
        </Box>
      </Box>
      {imageURLs && imageURLs.length > 0 && (
        <HStack mt={2} w="100%" overflow="auto">
          {map(imageURLs, (image, index) => {
            return (
              <Flex
                pos="relative"
                key={index}
                width="25%"
                justifyContent="center"
                background="lightGrey"
                borderRadius="12px">
                <Image src={image} height="75px" objectFit="contain" />
                <Icon
                  onClick={() => removeMediaClicked(image)}
                  style={{
                    position: 'absolute',
                    color: 'black',
                    fontSize: '8px',
                    background: 'white',
                    borderRadius: '100%',
                    padding: '6px',
                    top: '0',
                    right: '0',
                  }}
                  iconName="fi-rr-cross"
                />
              </Flex>
            );
          })}
        </HStack>
      )}
    </>
  );
};

export default CommentInput;
