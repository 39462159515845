import React, {useEffect, useState} from 'react';
import {
  Center,
  Image,
  useTheme,
  Container,
  SimpleGrid,
  Flex,
  Box,
  Spacer,
} from '@chakra-ui/react';
import {without} from 'lodash';
import {BlueButtonFixedWidth} from '../../../../components/button';
import {fetchLifestyleGroups, setLifestyle, removeLifestyle} from '../../../../endpoints/api';
import {LabelledCheckbox} from '../../../../components/checkbox';
import BookLifestyleGroup from '../../../../assets/images/lifestyle-groups/book.png';
import EcologyLifestyleGroup from '../../../../assets/images/lifestyle-groups/ecology.png';
import FamilyLifestyleGroup from '../../../../assets/images/lifestyle-groups/family.png';
import HandLifestyleGroup from '../../../../assets/images/lifestyle-groups/hand.png';
import HealthLifestyleGroup from '../../../../assets/images/lifestyle-groups/health.png';
import HeartLifestyleGroup from '../../../../assets/images/lifestyle-groups/heart.png';
import InspirationLifestyleGroup from '../../../../assets/images/lifestyle-groups/inspiration.png';
import ModeratorLifestyleGroup from '../../../../assets/images/lifestyle-groups/moderator.png';
import PerformingLifestyleGroup from '../../../../assets/images/lifestyle-groups/performing.png';
import PlanetLifestyleGroup from '../../../../assets/images/lifestyle-groups/planet.png';
import RocketLifestyleGroup from '../../../../assets/images/lifestyle-groups/rocket.png';
import SaveTheWorldLifestyleGroup from '../../../../assets/images/lifestyle-groups/save-the-world.png';
import useAuth from '../../../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {getAppName} from '../../../../utils/app';
import {SpinnerLoader} from '../../../../components/Loader';
import BackButton from '../../../../components/BackButton';
import Header from '../Header';

export interface LifestyleGroup {
  id: number;
  name: string;
  icon: string;
}

const LifestyleGoals = () => {
  const theme = useTheme();
  const {reFetchUserData, user} = useAuth();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  const [lifestyleGroups, setLifestyleGroups] = useState<Array<LifestyleGroup>>([]);
  const [selectedLifestyles, setSelectedLifestyles] = useState<number[]>([]);
  const lifestyleIds = user?.lifestyleGroups.length
    ? user?.lifestyleGroups.map((l) => parseInt(l.id))
    : [];
  const uniqueLifestylesId = [...new Set(lifestyleIds)];

  const handleCheckboxChange = (id: number) => {
    if (selectedLifestyles.includes(id)) {
      removeLifestyle(id).then(() => {
        reFetchUserData();
      });
      setSelectedLifestyles(without(selectedLifestyles, id));
      return;
    }

    setLifestyle(id).then(() => {
      reFetchUserData();
    });
    setSelectedLifestyles([...selectedLifestyles, id]);
  };

  useEffect(() => {
    fetchLifestyleGroups().then(({data}) => {
      setLifestyleGroups(data);
      setLoading(false);
    });
    setSelectedLifestyles(uniqueLifestylesId);
    reFetchUserData();
  }, []);

  const returnImageByType = (type: string) => {
    switch (type) {
      case 'heart':
        return HeartLifestyleGroup;
      case 'health':
        return HealthLifestyleGroup;
      case 'ecology':
        return EcologyLifestyleGroup;
      case 'performing':
        return PerformingLifestyleGroup;
      case 'hand':
        return HandLifestyleGroup;
      case 'save-the-world':
        return SaveTheWorldLifestyleGroup;
      case 'moderator':
        return ModeratorLifestyleGroup;
      case 'rocket':
        return RocketLifestyleGroup;
      case 'planet':
        return PlanetLifestyleGroup;
      case 'inspiration':
        return InspirationLifestyleGroup;
      case 'family':
        return FamilyLifestyleGroup;
      case 'book':
        return BookLifestyleGroup;
      default:
        return <></>;
    }
  };

  return (
    <Container px={6}>
      <Header
        title={`You’re On Your Way!`}
        subtitle={`Pick what matters to you—these shape the events, perks, and connections we send your way!`}
      />
      {isLoading ? (
        <SpinnerLoader mt={10} />
      ) : (
        <>
          <SimpleGrid columns={1} spacing={5} mt={5}>
            {lifestyleGroups.map((lifestyleGroup) => (
              <Flex
                key={lifestyleGroup.id}
                onClick={() => handleCheckboxChange(lifestyleGroup.id)}
                px={6}
                py={4}
                borderRadius={'7px'}
                border={`1px solid ${theme.colors.blue}`}>
                <Box alignSelf={'center'}>
                  <LabelledCheckbox
                    label={lifestyleGroup.name}
                    checked={selectedLifestyles.includes(lifestyleGroup.id)}
                    onChange={(e) => e}
                    disabled={false}
                  />
                </Box>
                <Spacer />
                <Box alignSelf={'center'}>
                  {lifestyleGroup.icon && (
                    <Image w={'42px'} h={'42px'} src={returnImageByType(lifestyleGroup.icon)} />
                  )}
                </Box>
              </Flex>
            ))}
          </SimpleGrid>
          <Center py={4}>
            <BlueButtonFixedWidth
              isDisabled={selectedLifestyles.length < 1}
              onClick={() => navigate('/register/topics')}>
              Continue
            </BlueButtonFixedWidth>
          </Center>
        </>
      )}
    </Container>
  );
};

export default LifestyleGoals;
