import {Box, Center, useTheme, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import Modal from '../../components/Modals/Modal';
import {H2, TextG10, TextG12, TextG14} from '../../components/typography';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import {Checkbox, LabelledCheckbox, LabelledCheckboxGrey} from '../../components/checkbox';
import {BlueButton} from '../../components/button';
import {useDispatch} from 'react-redux';
import {EventNotificationsModalActions} from './EventNotificationsModalSlice';
import useAuth from '../../hooks/useAuth';
import {updateUser} from '../../endpoints/api';
import {useUpdateNotificationPreference} from '../../hooks/useEvent';

const StyledHeaderIcon = styled(Box)`
  background: #925ff2;
  border-radius: 50%;
  padding: 20px;
  position: absolute;
  top: -45px;
`;

export interface EventNotificationsModalProps {
  eventId: number;
}

const EventNotificationsModal = ({eventId}: EventNotificationsModalProps) => {
  const theme = useTheme();
  const {reFetchUserData} = useAuth();
  const [notificationsChecked, setNotificationsChecked] = useState(true);
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const closeClicked = () => setShow(false);
  const {mutateAsync} = useUpdateNotificationPreference();
  const confirmClicked = async () => {
    setShow(false);
    if (notificationsChecked) {
      await mutateAsync({
        eventId,
        receiveNotifications: true,
      });
      await updateUser({email_enabled: true, sms_enabled: true, push_enabled: true});
      reFetchUserData();
    } else {
      mutateAsync({
        eventId,
        receiveNotifications: false,
      });
    }
  };
  useEffect(() => {
    return () => {
      dispatch(EventNotificationsModalActions.popEventNotifications());
    };
  }, []);

  useEffect(() => {
    if (!show) {
      dispatch(EventNotificationsModalActions.popEventNotifications());
    }
  }, [show]);

  return (
    <Modal
      headerPadding="1rem 1.5rem 0 1.5rem"
      isCentered={true}
      closeOnOverlayClick={false}
      show={show}
      closeButton={false}
      header={
        <>
          {
            <Center pb="20px">
              <StyledHeaderIcon>
                <Icon
                  iconName="fi fi-sr-bell-ring"
                  style={{
                    color: 'white',
                    fontSize: '32px',
                    display: 'block',
                    height: '32px',
                    width: '32px',
                  }}
                />
              </StyledHeaderIcon>
            </Center>
          }
          <Center>
            <H2 color={theme.colors.darkGrey}>Event Updates!</H2>
          </Center>
        </>
      }
      footer={
        <VStack w="100%" gap="10px" pt="5px" pb="10px">
          <BlueButton w="85%" onClick={confirmClicked}>
            Confirm
          </BlueButton>
          <TextG12 onClick={closeClicked} color={theme.colors.grey}>
            Close
          </TextG12>
        </VStack>
      }>
      <VStack>
        <Center textAlign="center" color={theme.colors.darkGrey}>
          <TextG14 fontWeight="500">Opt-In for Updates & Reminders</TextG14>
        </Center>
        <Center px={3} textAlign="center">
          <TextG14 pt="20px" fontWeight="500" color={theme.colors.grey}>
            Keep me informed about this event updates and chats & send me reminders
          </TextG14>
        </Center>
        <Box
          onClick={() => setNotificationsChecked((prev) => !prev)}
          mt="20px !important"
          background={theme.colors.lightGrey}
          borderRadius="14px"
          py={3}
          px={5}>
          <LabelledCheckboxGrey
            styled={true}
            isCircle={false}
            label="We’ll notify you about event updates and chat."
            checked={notificationsChecked}
            onChange={(e) => e}
            disabled={false}
          />
        </Box>
      </VStack>
    </Modal>
  );
};

export default EventNotificationsModal;
