import {Flex, useTheme, Divider} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import {TextG12, TextG10, TextG16, TextG14} from '../../typography';
import UserAvatar from '../../UserAvatar';
import {ChatComment, getUserHandle, MENTION_REGEXP} from '../ChatComment';
import {OPTIONS as EventOptions} from '../../../constants/eventOptions';
import {useNavigate} from 'react-router-dom';

const ReplyMentionNotification = ({comment}: {comment: ChatComment}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getIconStyle = (option) => {
    {
      return {
        fontSize: '10px',
        height: '10px',
        width: '10px',
        color: theme.colors[option.color] || option.color,
        fill: theme.colors[option.color] || option.color,
      };
    }
  };

  return (
    <>
      <Flex flexDir="column" my={2} onClick={() => navigate(`${comment.isReply ? comment.parent_id : comment.id}`)}>
        <Flex align="center" gap={2}>
          <UserAvatar user={comment.user} size="sm" avatar_image={comment.user_image} />
          <Flex align="center" gap={1}>
            <TextG12 noOfLines={1}>{comment.user?.handle || 'Null User Handle'}</TextG12>
            {EventOptions[comment.rsvp_status] &&
              EventOptions[comment.rsvp_status].renderSelectedIcon({
                style: getIconStyle(EventOptions[comment.rsvp_status]),
              })}
          </Flex>
          <TextG10 noOfLines={1} color={theme.colors.lightestGrey}>
            {dayjs.utc(comment.created_at).fromNow()}
          </TextG10>
        </Flex>
        <TextG14 ml="44px" noOfLines={2}>{`${comment.user.handle} ${
          comment.isReply ? 'replied' : 'mentioned you in'
        } '${comment.content.replace(MENTION_REGEXP, (match) =>
          match.replace(MENTION_REGEXP, `@${getUserHandle(match, comment).handle}`)
        )}'`}</TextG14>
      </Flex>
      <Divider mt={2} w="100%" />
    </>
  );
};

export default ReplyMentionNotification;
