import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {find} from 'lodash';
import {useCallback} from 'react';
import {
  addUserAddress,
  updateAddress,
  fetchAddressTypes,
  fetchUserAddresses,
} from '../endpoints/api';
import {Address} from '../types/address';
import useAuth from './useAuth';

export const useFetchAddressTypes = () => {
  return useQuery(['addressTypes'], () => fetchAddressTypes(), {staleTime: Infinity});
};

export const useAddressTypes = () => {
  const fetchAddressTypes = useFetchAddressTypes();
  const addressTypes = fetchAddressTypes.data?.data;

  const getIdForAddressType = useCallback(
    (typeName) => find(addressTypes, {name: typeName})?.id,
    [addressTypes]
  );

  return {addressTypes, addressTypesIsLoading: fetchAddressTypes.isLoading, getIdForAddressType};
};

export const useAddUserAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({userId, address}: {userId: number; address: Address}) => addUserAddress({userId, address}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userAddresses']);
      },
    }
  );
};

export const useUpdateUserAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({userId, addressId, address}: {userId: number; addressId: number; address: Address}) =>
      updateAddress({userId, addressId, address}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userAddresses']);
      },
    }
  );
};

export const useFetchUserAddresses = ({
  userId,
  addressType,
}: {
  userId: number | undefined;
  addressType?: string;
}) => {
  return useQuery(['userAddresses', addressType], () => fetchUserAddresses({userId, addressType}), {
    enabled: !!userId,
  });
};

export const useGetUserAddresses = ({
  userId,
  addressType,
}: {
  userId?: number;
  addressType?: string;
}) => {
  const auth = useAuth();
  const fetchUserAddresses = useFetchUserAddresses({userId: userId || auth.user?.id, addressType});
  const userAddresses = fetchUserAddresses.data?.data;

  return {userAddresses, userAddressesLoading: fetchUserAddresses.isLoading};
};

export const useMutateUserAddress = (address: Address, userId?: number) => {
  const auth = useAuth();
  const fetchAddressTypes = useFetchAddressTypes();
  const addressTypes = fetchAddressTypes.data?.data;

  const addUserAddress = useAddUserAddress();

  const addressTypeString: string = find(addressTypes, {id: address.type_id})?.name;

  const fetchUserAddresses = useFetchUserAddresses({
    userId: userId || auth.user?.id,
    addressType: addressTypeString,
  });
  const userAddresses = fetchUserAddresses.data?.data;
};
