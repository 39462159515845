import {createSlice} from '@reduxjs/toolkit';

export type NotificationReminderPopupProps = {
  show: boolean;
};

export type SliceState = NotificationReminderPopupProps;
type SliceAction = {payload: NotificationReminderPopupProps; type: string};

const initialState: SliceState = {
  show: false,
};

export const NotificationReminderPopupSlice = createSlice({
  name: 'notificationReminder',
  initialState,
  reducers: {
    setShow: (state: SliceState, action: SliceAction) => {
      state.show = action.payload.show;
    },
  },
});

export const NotificationReminderPopupActions = NotificationReminderPopupSlice.actions;

export default NotificationReminderPopupSlice;
