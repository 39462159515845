import React, {useState, useCallback, useRef, useEffect} from 'react';
import ProfileImg from '../../../assets/images/profile3.png';
import styled, {css} from 'styled-components';
import Cropper from 'react-easy-crop';
import {Point, Area} from 'react-easy-crop/types';
import {ImageFileToURL, getImage} from './CropImage';
import {uploadProfilePhoto} from '../../../endpoints/api';
import useAuth from '../../../hooks/useAuth';
import {get} from 'lodash';

import {
  Box,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  VStack,
  useTheme,
  Input,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modals/Modal';
import {TextA14, TextA18} from '../../../components/typography';
import {AxiosError} from 'axios';

const SCALE_MIN = 1;
const SCALE_MAX = 2.5;
const SCALE_STEP = 0.1;
const DEFAULT_SCALE = 1.1;
const CROPPER_CONTAINER_HEIGHT = 200;

const UploadLabel = styled.label`
  color: black;
  font-weight: 600;
`;

const UploadIconContainer = styled(Box)`
  display: inline-block;
  margin-right: 10px;
`;

const CropperWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: ${CROPPER_CONTAINER_HEIGHT}px;
`;

function ProfilePicModal({
  show,
  setShow,
  uploadedImage,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  uploadedImage?: any;
}) {
  const theme = useTheme();
  const {reFetchUserData, user} = useAuth();
  const [image, setImage] = useState<string>();
  const [crop, setCrop] = useState<Point>({x: 0, y: 0});
  const [zoom, setZoom] = useState(DEFAULT_SCALE);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isSaving, setSaving] = useState(false);
  const [error, setError] = useState<string>('');
  const profilePhotoUrl = get(user, 'profilePhotoUrl');

  useEffect(() => setImage(profilePhotoUrl), [profilePhotoUrl]);

  useEffect(() => {
    if (uploadedImage) {
      handleUploadImage(uploadedImage);
      if (!show && uploadedImage.target?.files?.length > 0) setShow(true);
    }
  }, [uploadedImage]);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleClose = useCallback(() => {
    setZoom(DEFAULT_SCALE);
    setShow(false);
  }, []);

  const handleUploadImage = useCallback(async ({target: {files}}) => {
    if (files && files.length > 0) {
      const loadedImage = files[0];
      const image: any = await ImageFileToURL(loadedImage);
      setImage(image);
    }
  }, []);

  const handleSave = useCallback(async () => {
    setSaving(true);
    if (image) {
      const croppedImage = await getImage(image, croppedAreaPixels);

      if (croppedImage) {
        try {
          await uploadProfilePhoto(croppedImage);
        } catch (err) {
          setSaving(false);

          err instanceof AxiosError && err.response?.status === 422
            ? setError(
                'Image is too large to upload. Please crop it further or select a smaller image.'
              )
            : setError('Something went wrong with uploading, please try again!');

          console.error(err);
          return;
        }

        reFetchUserData();
      }
    }
    setTimeout(() => {
      setSaving(false);
      handleClose();
    }, 500);
  }, [croppedAreaPixels, image]);

  const header = <TextA18>Edit Your Profile Photo</TextA18>;

  const body = (
    <VStack>
      <CropperWrapper>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
          objectFit="auto-cover"
        />
      </CropperWrapper>
      <Slider
        aria-label="scale-slider"
        defaultValue={zoom}
        value={zoom}
        w="80%"
        min={SCALE_MIN}
        max={SCALE_MAX}
        step={SCALE_STEP}
        onChange={(v) => setZoom(v)}>
        <SliderTrack>
          <SliderFilledTrack bg={theme.colors.lightBlue} />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </VStack>
  );

  const footer = (
    <Flex w="100%" flexDir="column">
      {error && (
        <TextA14 mb={4} color="red">
          {error}
        </TextA14>
      )}
      <Flex>
        <Button disabled={isSaving}>
          <UploadLabel htmlFor="image-file">
            <Input
              type="file"
              id="image-file"
              accept="image/*;capture=camera"
              onChange={handleUploadImage}
              hidden
            />
            <UploadIconContainer>
              <Icon iconName="fi-rr-upload" />
            </UploadIconContainer>
            Upload New Image
          </UploadLabel>
        </Button>
        <Spacer />
        <Button
          disabled={isSaving}
          color={'white'}
          backgroundColor={theme.colors.blue}
          mr={0}
          onClick={handleSave}>
          Save
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Modal
      parentCallback={handleClose}
      header={header}
      children={body}
      footer={footer}
      show={show}
      closeButton
    />
  );
}

export default ProfilePicModal;
