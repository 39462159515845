import {Button, ButtonProps, Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {TextG10, TextG14} from '../../../components/typography';
import {OPTIONS as DEFAULT_OPTIONS} from '../../../constants/eventOptions';
import {Event} from '../../../types/article';
import {useLocation} from 'react-router-dom';
import useCountdown from '../../../hooks/useCountdown';
import useAuth from '../../../hooks/useAuth';
import {isEmpty} from 'lodash';
import { EventRSVPStatus } from '../../../hooks/useEvent';

export type RSVPButtonProps = {
  event: Event;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonProps?: ButtonProps;
  variant?: 'sm' | 'lg';
  hideLabel?: boolean;
  color?: string;
  rsvpOptions?: any;
};

const RSVPButton = ({
  event,
  onButtonClick,
  buttonProps,
  variant = 'lg',
  hideLabel = false,
  color,
  rsvpOptions,
}: RSVPButtonProps) => {
  const theme = useTheme();
  const {pathname} = useLocation();
  const statusValue = event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS;
  const {endPassed} = useCountdown(event);
  const {user} = useAuth();

  const options = rsvpOptions || DEFAULT_OPTIONS;

  if (isEmpty(options[statusValue])) {
    return null;
  }

  const rsvpText = endPassed ? options[statusValue].disabledText : options[statusValue].text;

  return (
    <Button
      border={'1px solid'}
      borderColor={color ? color : options[statusValue].borderColor || 'transparent'}
      id="rsvp-tooltip"
      backgroundColor={theme.colors[options[statusValue].bgColor] || options[statusValue].bgColor}
      borderRadius={'2.5rem'}
      justifyContent={'center'}
      alignItems={'center'}
      textAlign="center"
      whiteSpace="normal"
      onClick={onButtonClick}
      px={3}
      isDisabled={endPassed}
      {...buttonProps}>
      {event.rsvp_status.status_value ? (
        <Flex direction={'row'} justifyContent={'center'} alignItems={'center'} gap={'.5rem'}>
          {options[statusValue].renderSelectedIcon({
            style: {
              fontSize: '18px',
              height: '18px',
              width: '18px',
              color: theme.colors[options[statusValue].color] || options[statusValue].color,
              fill: theme.colors[options[statusValue].color] || options[statusValue].color,
            },
          })}
          {!hideLabel &&
            (variant === 'lg' ? (
              <TextG14>{rsvpText}</TextG14>
            ) : (
              <TextG10
                style={
                  pathname === '/home'
                    ? {
                        textOverflow: 'ellipsis',
                        maxWidth: '11ch',
                        overflow: 'hidden',
                      }
                    : {}
                }
                flexGrow={0}>
                {rsvpText}
              </TextG10>
            ))}
        </Flex>
      ) : variant === 'lg' ? (
        <TextG14 sx={{flexGrow: 1, marginLeft: '0'}}>{rsvpText}</TextG14>
      ) : (
        <TextG10
          sx={{
            flexGrow: 1,
            marginLeft: '0',
            color: color ? color : 'black',
          }}>
          {rsvpText}
        </TextG10>
      )}
      {/* <i
        className="fi fi-rr-angle-down"
        style={{
          fontSize: '0.5rem',
          marginTop: '0.2rem',
          color: statusValue === 0 ? (variant === 'lg' ? 'black' : color ? color : 'black') : '',
        }}></i> */}
    </Button>
  );
};

export default RSVPButton;
