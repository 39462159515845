import {Box, Flex, Input, useTheme} from '@chakra-ui/react';
import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {TextG12} from '../../../components/typography';
import {useDispatch} from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import {setWillcallName} from '../../../state/Checkout/CheckoutSlice';

export const StyledRadio = styled.input.attrs({type: 'radio'})`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0px solid #fff;
  background-color: #fff;
  outline: 1px solid #757d84;
  cursor: pointer;
  margin-right: 4px;

  &:checked {
    outline: none;
    background-color: #276acd;
    border: 2px solid #276acd;
  }

  &:checked::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    margin: 1px;
    border-radius: 50%;
    background-color: #fff;
  }
`;

const WillCallSelect = () => {
  const theme = useTheme();
  const {user} = useAuth();
  const [selectedOption, setSelectedOption] = useState('me');
  const [enteredWillcallName, setEnteredWillcallName] = useState(`${user?.name} ${user?.surname}`);
  const dispatch = useDispatch();

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    if (dispatch) {
      dispatch(setWillcallName(`${user?.name} ${user?.surname}`));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (dispatch) dispatch(setWillcallName(enteredWillcallName));
  }, [enteredWillcallName, dispatch]);

  return (
    <Flex
      gap={3}
      flexDir="column"
      borderTop={`1px solid ${theme.colors.blueShade1}`}
      background={theme.colors.blueShade2}
      align="center"
      w="100%"
      p={4}
      margin="0px !important"
      justifyContent="space-between">
      <Flex align="center" justify="space-between" w="100%">
        <TextG12 fontWeight="500">Who will pickup tickets?</TextG12>
        <Box margin="0px !important">
          <label style={{marginRight: '20px'}}>
            <StyledRadio
              type="radio"
              value="me"
              checked={selectedOption === 'me'}
              onChange={handleOptionChange}
            />
            <TextG12 display="inline" fontWeight={selectedOption === 'me' ? 700 : 400}>
              Me
            </TextG12>
          </label>
          <label>
            <StyledRadio
              type="radio"
              value="someone_else"
              checked={selectedOption === 'someone_else'}
              onChange={handleOptionChange}
            />
            <TextG12 display="inline" fontWeight={selectedOption === 'someone_else' ? 700 : 400}>
              Someone else
            </TextG12>
          </label>
        </Box>
      </Flex>
      {selectedOption === 'someone_else' && (
        <Box w="100%">
          <Input
            border={`1px solid ${theme.colors.grey}`}
            background="white"
            type="text"
            w="100%"
            value={enteredWillcallName}
            onChange={(e) => setEnteredWillcallName(e.target.value)}
          />
        </Box>
      )}
    </Flex>
  );
};

export default WillCallSelect;
