import React, {useState} from 'react';
import JoinMeetUpModal from '../../../components/JoinMeetUpModal';
import {OPTIONS} from '../../../constants/meetupOptions';
import {EventRSVPStatus} from '../../../hooks/useEvent';
import RSVPController from '../../Event/RSVP/RSVPController';

const MeetupRSVPButton = ({event}) => {
  const [isSelectOpen, setSelectIsOpen] = useState(false);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const noStatus =
    !event.rsvp_status.status_value || event.rsvp_status.status_value == EventRSVPStatus.NO_STATUS;

  const onClickRSVPModal = () => {
    setSelectIsOpen(false);
  };

  const onClickRSVPButton = (e) => {
    e.preventDefault();
    if (noStatus) {
      setModalIsOpen(true);
    } else {
      setSelectIsOpen(true);
    }
  };

  return (
    <>
      <RSVPController
        event={event}
        rsvpOptions={OPTIONS}
        onButtonClick={onClickRSVPButton}
        optionClickCallback={onClickRSVPModal}
        openModal={isSelectOpen}
        onModalClose={() => setSelectIsOpen(false)}
      />
      <JoinMeetUpModal
        event={event}
        show={isModalOpen}
        setShow={setModalIsOpen}
        //routeAfterCreate={false}
      />
    </>
  );
};

export default MeetupRSVPButton;
