import {
  ModalOverlay,
  ModalContent,
  ModalContentProps,
  Modal,
  ModalProps,
  ModalBody,
  ModalBodyProps,
  Flex,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, {ReactNode} from 'react';
import { WhiteButton, BlueButton } from '../../button';
import { TextG10, TextG14 } from '../../typography';

type PromptModalProps = {
  declineText?: string;
  declineCallback?: (e: any) => void;
  acceptText?: string;
  acceptCallback?: (e: any) => void;
  title: string;
  isOpen: boolean;
  onClose?: () => void;
  style?: {
    Modal?: ModalProps;
    ModalContent?: ModalContentProps;
    ModalBody?: ModalBodyProps;
  }
}

const PromptModal = ({
  declineText,
  declineCallback,
  acceptCallback,
  acceptText,
  title,
  isOpen,
  onClose,
  style,
}: PromptModalProps) => {

  const handleAccept = (e) => {
    acceptCallback && acceptCallback(e);
  }

  const handleDecline = (e) => {
    declineCallback && declineCallback(e);
  }

  return (
    <Modal isOpen={isOpen} onClose={() => {onClose && onClose()}} isCentered {...style?.Modal}>
      <ModalOverlay />
      <ModalContent w='80%' px={7} pt={6} pb={3} h='auto' {...style?.ModalContent}>
        {onClose && <ModalCloseButton background={'transparent'} top={0} right={0} />}
        <ModalBody p={0} textAlign={'center'} {...style?.ModalBody}>
          <TextG14>{title}</TextG14>
          <Flex justifyContent={'space-evenly'}>
            {declineText && <WhiteButton
              h={'30px'}
              w={'100px'}
              borderRadius={'19px'}
              marginTop={'3'}
              marginBottom={'3'}
              color={'#757D84'}
              border={`1px solid #757D84`}
              onClick={handleDecline}
            >
              <TextG10 py={3}>{declineText}</TextG10>
            </WhiteButton>}
            {acceptText &&<BlueButton
              h={'30px'}
              w={'100px'}
              borderRadius={'19px'}
              marginTop={'3'}
              onClick={handleAccept}
            >
              <TextG10 py={3}>{acceptText}</TextG10>
            </BlueButton>}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PromptModal;
