import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  chakra,
  useTheme,
  Collapse,
  Box,
  Center,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TextA12, TextA8, TextG10, TextG12} from '../../components/typography';
// import {ChevronDownIcon} from '@chakra-ui/icons'; // Use ChevronDownIcon for open/close indicator
import CheckCircleFilled from '../../assets/icons/flaticons/check-circle-filled.svg';
import {getMembershipColor} from '../../utils/membership';
import Icon from '../../components/Icon';
import useAuth from '../../hooks/useAuth';

export const StyledCheckCircleFilled = chakra(CheckCircleFilled);

const MembershipBenefits = ({memberships, selectedMembershipId, setSelectedMembershipId}) => {
  const theme = useTheme();
  const [expandedBenefitIndex, setExpandedBenefitIndex] = useState(0);

  const essentialsSelected = selectedMembershipId === memberships[0]?.id;
  const insiderSelected = selectedMembershipId === memberships[1]?.id;

  const essientialColor = getMembershipColor(memberships[0]);
  const insiderColor = getMembershipColor(memberships[1]);

  const {user} = useAuth();

  const activeMembershipId = user?.activeMembershipPlan?.id;

  const toggleBenefit = (index) => {
    setExpandedBenefitIndex(index === expandedBenefitIndex ? null : index);
  };

  const freeBenefitCheck = (
    <Center>
      <StyledCheckCircleFilled
        transition="fill 0.3s ease"
        fill={theme.colors.grey2}
        w="16px"
        h="16px"
      />
    </Center>
  );

  const essentialBenefitCheck = (
    <Center marginTop="0px !important">
      <StyledCheckCircleFilled
        transition="fill 0.3s ease"
        fill={essentialsSelected ? essientialColor : theme.colors.grey2}
        w="16px"
        h="16px"
      />
    </Center>
  );
  const insiderBenefitCheck = (
    <Center marginTop="0px !important">
      <StyledCheckCircleFilled
        transition="fill 0.3s ease"
        fill={insiderSelected ? insiderColor : theme.colors.grey2}
        w="16px"
        h="16px"
      />
    </Center>
  );

  const BENEFITS = [
    {
      title: 'Earn Points for Connecting with Your Community',
      additionalInfo:
        'Earn points by attending events, inviting friends, connecting with neighbors, and supporting local businesses. Use your points for rewards that bring our community closer.',
      freeBenefit: '1x',
      essientialsBenefit: `${Number(memberships[0].points_boost_multiplier)}x`,
      insiderBenefit: `${Number(memberships[1].points_boost_multiplier)}x`,
    },
    {
      title: 'Access to the Walkabout Marketplace',
      additionalInfo:
        'Unlock a curated marketplace with special offers, services, and events crafted just for locals like you.',
      freeBenefit: freeBenefitCheck,
      essientialsBenefit: essentialBenefitCheck,
      insiderBenefit: insiderBenefitCheck,
    },
    {
      title: 'Exclusive Discounts',
      additionalInfo:
        'Save big on neighborhood goods, services, and events in the Walkabout Marketplace -- making it easier to experience the best your community has to offer.',
      essientialsBenefit: `${Number(memberships[0].event_discount).toFixed(0)}%`,
      insiderBenefit: `${Number(memberships[1].event_discount).toFixed(0)}%`,
    },
    {
      title: 'Passes for Select Events and Items',
      additionalInfo:
        'Get passes to select Walkabout-affiliated events and items in the Marketplace that you can use yourself or share with friends -- perfect for trying new things every month.',
      insiderBenefit: '2',
    },
  ];

  return (
    <TableContainer mt={6} px={4} maxW="100%" overflowY="auto">
      <Table variant="simple" maxW="100%" mb="6px">
        <Thead>
          <Tr>
            <Th py={0} px="0px" pb="10px">
              <TextA12 textTransform="none" letterSpacing="-0.02em" fontWeight="700">
                Benefits
              </TextA12>
            </Th>
            <Th
              textTransform="none"
              w="50px"
              maxW="50px"
              textAlign="center"
              py={0}
              px={0}
              pb="10px">
              <TextA8 color={theme.colors.lightestGrey} fontWeight="400" textAlign="center">
                Basic
                {user && !activeMembershipId && (
                  <>
                    <br />
                    (Current)
                  </>
                )}
              </TextA8>
            </Th>
            <Th
              transition="all 0.3s ease"
              textTransform="none"
              whiteSpace="normal"
              w="65px"
              maxW="65px"
              textAlign="center"
              py={0}
              px={0}
              pb="10px"
              onClick={() => setSelectedMembershipId(1)}>
              <TextA8
                color={essentialsSelected ? essientialColor : theme.colors.grey2}
                fontWeight={essentialsSelected ? '700' : '400'}
                textAlign="center">
                {memberships[0]?.name}
                {user && activeMembershipId == memberships[0]?.id && (
                  <>
                    <br />
                    (Current)
                  </>
                )}
              </TextA8>
            </Th>
            <Th
              transition="all 0.3s ease"
              textTransform="none"
              whiteSpace="normal"
              w="65px"
              maxW="65px"
              textAlign="center"
              py={0}
              px={0}
              pb="10px"
              onClick={() => setSelectedMembershipId(2)}>
              <TextA8
                color={insiderSelected ? insiderColor : theme.colors.grey2}
                fontWeight={insiderSelected ? '700' : '400'}
                textAlign="center">
                {memberships[1]?.name}
                {user && activeMembershipId == memberships[1]?.id && (
                  <>
                    <br />
                    (Current)
                  </>
                )}
              </TextA8>
            </Th>
          </Tr>
        </Thead>
        <Tbody transition="all 0.3s ease">
          {BENEFITS.map((benefit, index) => (
            <React.Fragment key={index}>
              <BenefitRow
                essientialColor={essientialColor}
                insiderColor={insiderColor}
                essentialsSelected={essentialsSelected}
                insiderSelected={insiderSelected}
                title={benefit.title}
                isExpanded={expandedBenefitIndex === index}
                onToggle={() => toggleBenefit(index)}
                freeColumn={
                  <TextG12
                    transition="all 0.3s ease"
                    textAlign="center"
                    color={theme.colors.grey2}
                    fontWeight="700">
                    {benefit.freeBenefit || '-'}
                  </TextG12>
                }
                essentialsColumn={
                  <TextG12
                    transition="all 0.3s ease"
                    textAlign="center"
                    color={essentialsSelected ? essientialColor : theme.colors.grey2}
                    fontWeight="700">
                    {benefit.essientialsBenefit || '-'}
                  </TextG12>
                }
                insiderColumn={
                  <TextG12
                    transition="all 0.3s ease"
                    textAlign="center"
                    color={insiderSelected ? insiderColor : theme.colors.grey2}
                    fontWeight="700">
                    {benefit.insiderBenefit || '-'}
                  </TextG12>
                }
              />
              <Tr>
                <Td colSpan={4} px={0} py={0} whiteSpace="normal">
                  <Collapse
                    in={expandedBenefitIndex === index}
                    style={{overflow: expandedBenefitIndex == index ? 'visible' : 'hidden'}}>
                    <Box
                      zIndex="99"
                      pos="relative"
                      p={3}
                      bg="rgba(255,255,255,0.65)"
                      boxShadow="0px 2px 6px 0px rgba(121, 121, 141, 0.20), 0px 3px 15px 0px rgba(121, 121, 141, 0.20);"
                      // border={`1px solid #B6C0C8`}
                      borderRadius="10px">
                      <TextG10 color={'#545A5F'}>{benefit.additionalInfo}</TextG10>
                    </Box>
                  </Collapse>
                </Td>
              </Tr>
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const BenefitRow = ({
  title,
  onToggle,
  isExpanded,
  essentialsColumn,
  insiderColumn,
  freeColumn,
  essentialsSelected,
  insiderSelected,
  essientialColor,
  insiderColor,
}) => {
  const theme = useTheme();

  return (
    <Tr onClick={onToggle} cursor="pointer">
      <Td pl="0px" pr={1} borderBottom="none" py={3}>
        {/* <Box display="flex" alignItems="center" justifyContent="space-between"> */}
        <Box
          h="50px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRight={`1px solid ${theme.colors.grey2}`}
          pr={2}
          mr="5px">
          <TextG10
            letterSpacing="-0.02em"
            fontWeight="500"
            whiteSpace="normal"
            minWidth="100px"
            pr="10px">
            {title}
          </TextG10>
          <Icon
            iconName="fi-br-info"
            style={{
              marginTop: '3px',
              height: '14px',
              width: '14px',
              color: isExpanded ? theme.colors.grey : theme.colors.grey2,
              transition: 'color 0.3s ease',
              fontSize: '14px',
            }}
          />
        </Box>
      </Td>
      <Td borderBottom="none" textAlign="center" background="rgba(228, 230, 231, 0.4)">
        {freeColumn}
      </Td>
      <Td
        transition="background 0.3s ease, width 0.3s ease"
        px="10px"
        borderBottom="none"
        background={essentialsSelected ? '#DEF3F8' : 'transparent'}
        textAlign="center">
        {essentialsColumn}
      </Td>
      <Td
        transition="background 0.3s ease, width 0.3s ease"
        px="10px"
        borderBottom="none"
        background={insiderSelected ? '#7D3EEE1A' : 'transparent'}
        textAlign="center">
        {insiderColumn}
      </Td>
    </Tr>
  );
};

export default MembershipBenefits;
