import React from 'react';
import {Event} from '../../../types/acrticle';
import {map, orderBy} from 'lodash';
import EventCard from '../../../components/Articles/EventCard';
import {SimpleGrid} from '@chakra-ui/react';

const PlaceEvents = ({
  events,
  setSignUpRequiredOpen,
  preview = false,
}: {
  events: Event[];
  setSignUpRequiredOpen?: any;
  preview?: boolean;
}) => {
  return (
    <SimpleGrid gap="15px" columns={1} textAlign="initial">
      {map(orderBy(events, 'start_date_time'), (event: Event, i) => {
        return (
          <div key={i} style={{display: 'contents'}}>
            <EventCard
              onClick={preview ? () => setSignUpRequiredOpen(true) : undefined}
              key={event.id}
              event={event}
            />
          </div>
        );
      })}
    </SimpleGrid>
  );
};

export default PlaceEvents;
