import React, {useState, useEffect} from 'react';
import {useInfiniteFeaturedEventComments} from '../../../hooks/useEventComments';
import ChatComment, {ChatComment as ChatCommentInterface} from '../ChatComment';
import {AbsoluteCenter, Box, Center, Flex, Spinner, useTheme} from '@chakra-ui/react';
import {Event} from '../../../types/article';
import InfiniteScroll from 'react-infinite-scroll-component';
import {TextG14} from '../../typography';
import Icon from '../../Icon';
import {Community} from '../../../types/community';

const FeaturedTab = ({
  articleId,
  fetchFeaturedCommentsHook,
  name,
  isRSVPd,
  headerHeight,
  addComment,
  updateComment,
  deleteComment,
  reportComment,
  helpfulComment,
  fetchUsersHook,
}: {
  articleId?: number;
  fetchFeaturedCommentsHook: any;
  name: string;
  isRSVPd: boolean;
  event?: Event;
  community?: Community;
  headerHeight: number;
  addComment: (content: string, parent_id?: string) => void;
  updateComment: (commentId: string, content: string, parent_id?: string) => void;
  deleteComment: (commentId: string) => void;
  reportComment: (commentId: string) => void;
  helpfulComment: (commentId: string) => void;
  fetchUsersHook?: any;
}) => {
  const {data, isLoading, fetchNextPage, hasNextPage} = articleId
    ? fetchFeaturedCommentsHook(articleId)
    : fetchFeaturedCommentsHook();
  const theme = useTheme();
  const [featuredComments, setFeaturedComments] = useState<Array<ChatCommentInterface>>([]);

  useEffect(() => {
    if (!data) return;
    const comments: Array<ChatCommentInterface> = [];
    data.pages.map((p) => p.data.data.map((comment) => comments.push(comment)));
    setFeaturedComments(comments);
  }, [data?.pages]);

  return (
    <>
      {isLoading ? (
        <Center h={`calc(100vh - ${headerHeight}px)`}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </Center>
      ) : !featuredComments || featuredComments.length == 0 ? (
        <AbsoluteCenter
          w="95%"
          textAlign="center"
          color={theme.colors.grey}
          pt={`${headerHeight / 2}px`}>
          <Flex flexDir="column" gap={8}>
            <Icon
              iconName="fi-rr-comment"
              style={{fontSize: '48px', color: theme.colors.lightGrey}}
            />
            <TextG14 fontWeight="500">There aren't any announcements yet!</TextG14>
          </Flex>
        </AbsoluteCenter>
      ) : (
        <Flex
          flexDir="column-reverse"
          id="scrollableDiv"
          overflowY="auto"
          h={`calc(max(100vh - 15px - ${headerHeight}px), 500px)`}
          mt={`${headerHeight}px`}
          px={4}
          mb="15px">
          <InfiniteScroll
            scrollThreshold={'500px'}
            scrollableTarget="scrollableDiv"
            dataLength={featuredComments.length}
            next={fetchNextPage}
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }} //To put endMessage and loader to the top.
            inverse={true}
            hasMore={hasNextPage || false}
            loader={
              <Center py="30px">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor={theme.colors.lightGrey}
                  color={theme.colors.lightBlue}
                  size="lg"
                />
              </Center>
            }>
            {featuredComments.map((comment, i) => (
              <Box key={comment.id}>
                <ChatComment
                  fetchUsersHook={fetchUsersHook}
                  addComment={addComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  reportComment={reportComment}
                  helpfulComment={helpfulComment}
                  mb={3}
                  comment={comment}
                  articleId={articleId}
                  name={name}
                  isRSVPd={isRSVPd}
                  hideOptions
                />
              </Box>
            ))}
          </InfiniteScroll>
        </Flex>
      )}
    </>
  );
};

export default FeaturedTab;
