import React from 'react';
import Helmet from 'react-helmet';
import {Place} from '../../types/article';

const PlaceHelmet = ({place}: {place: Place}) => {
  return (
    <Helmet>
      <title>{place.name}</title>
      <meta name="description" content={place.description_plain_text} />
      <meta name="twitter:title" content={place.name} />
      <meta name="twitter:description" content={place.description_plain_text} />
      <meta name="twitter:card" content={place.name} />
      <meta name="twitter:image" content={place.image_url} />
      <meta property="og:title" content={place.name} />
      <meta property="og:description" content={place.description_plain_text} />
      <meta property="og:image" content={place.image_url} />
      <meta property="og:image:secure_url" content={place.image_url} />
      <meta property="og:image:url" content={place.image_url} />
    </Helmet>
  );
};

export default PlaceHelmet;
