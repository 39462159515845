import React from 'react';
import { Container, Flex, HStack, Image } from "@chakra-ui/react";
import styled from 'styled-components';
import { H1, TextG14 } from "../../components/typography";
import Logo from '../../assets/logos/Word_logo3.png';
import {getAppName} from "../../utils/app";

const StyledContainer = styled(Container)`
  background: linear-gradient(90.89deg, #EEEFF4 25.38%, #FFFFFF 88.08%);
  max-width: 100% !important;
  display: flex;
  justify-content: center;

`;

const Offline = ({text, children}: {text: string, children?: JSX.Element}) => {
  return (
    <StyledContainer>
      <Flex alignItems='center' justifyContent='center' flexDirection='column' maxWidth='60ch'>
        <HStack mb="20px">
          <Image mr="10px" boxSize='180px' height='auto' src={Logo} alt={'Logo'} />
          {/* <H1 mx={0}>{getAppName()}</H1> */}
        </HStack>
        {children}
        <TextG14 textAlign='center'>
          {text}
        </TextG14>
      </Flex>
    </StyledContainer>
  )
}

export default Offline;