import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {Partner} from '../../../types/acrticle';
import {H2, TextG10} from '../../../components/typography';
import styled, {css} from 'styled-components';

const StyledFlex = styled(Flex)`
  ${(props) =>
    props.$isPartner &&
    css`
      border-image: linear-gradient(90deg, #e02f84 12.03%, #f9ac39 89.22%);
      border-image-slice: 1;
      border-bottom: 2px solid;
    `};
`;

const Header: React.FC<{partner: Partner}> = (props) => {
  const theme = useTheme();

  return (
    <StyledFlex
      position="relative"
      top="0"
      width="90%"
      marginLeft="5%"
      transform="auto"
      translateY="-50%"
      marginBottom="-1.5rem"
      backgroundColor="white"
      boxShadow={`0 0 12px ${theme.colors.grey}`}
      borderRadius=".5rem"
      p="0.75rem"
      flexDirection={'column'}>
      <Flex width="100%" justify="space-between">
        <H2>{props.partner.name}</H2>
      </Flex>
      {props.partner.website && (
        <TextG10 color={theme.colors.grey}>{props.partner.website}</TextG10>
      )}
    </StyledFlex>
  );
};

export default Header;
