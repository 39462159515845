import React, {useEffect, useState} from 'react';
import OfferModal from '../components/Articles/Offers/OfferModals';
import DiscountModal from '../components/DiscountModal';
import {Offer, OfferType} from '../types/article';
import {ActiveRedemption} from './useActiveRedemptions';
import {useSearchParams} from 'react-router-dom';

interface OfferModalContainerAPI {
  offer?: Offer;
  showOfferModal: ({
    offer,
    activeRedemption,
    isPreview,
  }: {
    offer: Offer;
    activeRedemption?: ActiveRedemption;
    isPreview?: boolean;
  }) => void;
}

const OfferModalContainerContext = React.createContext<OfferModalContainerAPI>(
  {} as OfferModalContainerAPI
);

export const OfferModalCotainer = ({children}) => {
  const [offer, setOffer] = useState<Offer>();
  const [activeRedemption, setActiveRedemption] = useState<ActiveRedemption>();
  const [showModal, setShowModal] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const isDiscount = offer?.offer_type == OfferType.DISCOUNT;
  const [selectedRedemptionId, setSelectedRedemptionId] = useState<string | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();

  const showOfferModal = ({
    offer,
    activeRedemption,
    isPreview = false,
  }: {
    offer: Offer;
    activeRedemption?: ActiveRedemption;
    isPreview?: boolean;
  }) => {
    setOffer(offer);
    setActiveRedemption(activeRedemption);
    setShowModal(true);
    setIsPreview(isPreview);

    if(!searchParams.get('redemptionId') && activeRedemption && activeRedemption.redemptionId) {
      const params = new URLSearchParams(searchParams);
      params.append('redemptionId', activeRedemption.redemptionId.toString());
      setSearchParams(params.toString());
    }
  };

  useEffect(() => {
    if (activeRedemption && !selectedRedemptionId && !showModal) {
      setShowModal(false);
    }
  }, [selectedRedemptionId]);

  useEffect(() => {
    if (activeRedemption && !showModal) {
      const params = new URLSearchParams(searchParams);
      params.delete('redemptionId');
      setSearchParams(params.toString());
    }
  }, [showModal]);

  const offerModalContainerAPI: OfferModalContainerAPI = {
    offer,
    showOfferModal,
  };

  return (
    <OfferModalContainerContext.Provider value={offerModalContainerAPI}>
      {!isDiscount && offer && (
        <OfferModal
          isPreview={isPreview}
          offer={offer}
          activeRedemption={activeRedemption}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {isDiscount && (
        <DiscountModal discount={offer} showModal={showModal} setShowModal={setShowModal} />
      )}
      {children}
    </OfferModalContainerContext.Provider>
  );
};

export default function useOfferModal() {
  return React.useContext(OfferModalContainerContext);
}
