import React from 'react';
import {getOpenHours} from '../utils/openingHours';

const useOpenHours = (weekdayText: string[] | undefined ) => {

    const placeOpenHours = React.useMemo(() => {
        return weekdayText ? getOpenHours(weekdayText) : [];
    }, []);

    return {
        placeOpenHours,
    }
}

export default useOpenHours;