import {Flex, Link, useTheme} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import Icon from '../../../components/Icon';
import {TextG12} from '../../../components/typography';
import useBookmark from '../../../hooks/useBookmark';
import {getDirectionsLinkFromPlace} from '../../../utils/address';
import {Place} from '../../../types/acrticle';
import {useSearchParams} from 'react-router-dom';
import ExternalLink from '../../../components/ExternalLink';

const IconsHeader = ({
  place,
  preview = false,
  setSignUpRequiredOpen,
}: {
  place: Place;
  preview?: boolean;
  setSignUpRequiredOpen?: any;
}) => {
  const theme = useTheme();
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(place, 'place');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!!searchParams.get('bookmark') && !isBookmarked) {
      handleOnBookmarkClick(null);
    }
  }, [searchParams]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  return (
    <Flex justify="space-evenly" gap=".5rem">
      {place.phone && (
        <Link href={`tel: +${place.phone}`} style={{textDecoration: 'none'}}>
          <Flex minW="60px" direction="column" align="center">
            <Icon iconName="fi-rr-phone-call" style={iconStyle} />
            <TextG12 color={theme.colors.lightBlue}>Call</TextG12>
          </Flex>
        </Link>
      )}
      <ExternalLink
        href={getDirectionsLinkFromPlace(place)}
        isExternal
        style={{textDecoration: 'none'}}>
        <Flex minW="60px" direction="column" align="center">
          <Icon iconName="fi-rr-map-marker" style={iconStyle} />
          <TextG12 color={theme.colors.lightBlue}>Directions</TextG12>
        </Flex>
      </ExternalLink>
      <Flex
        minW="60px"
        direction="column"
        align="center"
        onClick={
          preview
            ? () => {
                setSignUpRequiredOpen(true);
              }
            : handleOnBookmarkClick
        }>
        {isBookmarked ? (
          <Icon
            iconName="fi-sr-bookmark"
            style={preview ? {...iconStyle, color: theme.colors.grey} : iconStyle}
          />
        ) : (
          <Icon
            iconName="fi-rr-bookmark"
            style={preview ? {...iconStyle, color: theme.colors.grey} : iconStyle}
          />
        )}
        <TextG12 color={preview ? theme.colors.grey : theme.colors.lightBlue}>Bookmark</TextG12>
      </Flex>
      {place.website && (
        <ExternalLink href={place.website} isExternal style={{textDecoration: 'none'}}>
          <Flex minW="60px" direction="column" align="center">
            <Icon iconName="fi-rr-globe" style={iconStyle} />
            <TextG12 color={theme.colors.lightBlue}>Website</TextG12>
          </Flex>
        </ExternalLink>
      )}
    </Flex>
  );
};

export default IconsHeader;
