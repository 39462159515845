import {Box} from '@chakra-ui/react';
import React from 'react';

const SignUpRequiredWrapper = ({children, setSignUpRequiredOpen}) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSignUpRequiredOpen(true);
  };

  return <Box onClick={handleClick}>{children}</Box>;
};

export default SignUpRequiredWrapper;
