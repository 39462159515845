import {AbsoluteCenter, Box, Center, Flex, useTheme, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import ActiveRedemptionCard from '../Articles/Offers/ActiveRedemptionCard';
import useActiveRedemptions from '../../hooks/useActiveRedemptions';
import {TextG12, TextG14} from '../typography';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Icon from '../Icon';
import {BlueButton} from '../button';

const ActiveRedemptionCards = ({hideNoCountdown = true}: {hideNoCountdown?: boolean}) => {
  const [searchParams] = useSearchParams();
  const {activeRedemptions} = useActiveRedemptions();
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedRedemptionId, setSelectedRedemptionId] = useState<string | null>(null);

  useEffect(() => {
    const redemptionId = searchParams.get('redemptionId');
      setSelectedRedemptionId(redemptionId);
  }, [searchParams]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '2rem',
    }),
    [theme]
  );

  if (!activeRedemptions || activeRedemptions.length <= 0)
    return (
      <AbsoluteCenter w="250px">
        <Flex alignItems="center" textAlign="center" flexDir="column" gap={4}>
          <Center>
            <Icon iconName="fi-rr-wallet" style={iconStyle} />
          </Center>
          <TextG14 color="#8E8E8E" fontWeight="500" lineHeight="20px" letterSpacing="-0.01em">
            Oops! Looks like you haven't chosen any perks.
          </TextG14>
          <TextG12 color="#8E8E8E" fontWeight="400" lineHeight="20px" letterSpacing="-0.01em">
            Let's check out what cool things you can get with your points right now.
          </TextG12>
          <BlueButton
            onClick={() =>
              navigate({
                pathname: '/search/offer',
              })
            }
            borderRadius="27.5px"
            px={6}
            py={3}
            w="auto"
            h="auto">
            Show Me The Perks
          </BlueButton>
        </Flex>
      </AbsoluteCenter>
    );

  return (
    <VStack my={4} spacing={4} width="100%">
      {activeRedemptions.map((redemption, i) => (
          <ActiveRedemptionCard
            key={redemption.timeRemaining}
            offer={redemption.offer}
            activeRedemption={redemption}
            hideCard={false}
            hideNoCountdown={hideNoCountdown}
            isSelected={redemption.redemptionId?.toString() === selectedRedemptionId}
          />
      ))}
    </VStack>
  );
};

export default ActiveRedemptionCards;
