import seedrandom from 'seedrandom';

// generate a random int using the given seed and in a given range [min, max)
export const randomSeedInRange = (seed, min, max) => {
  const rng = seedrandom(seed);
  return Math.floor(rng() * (max - min) + min);
};

// randomly shuffle an array using the given seed
export const shuffle = (seed, arr) => {
  const array = arr;
  for (let i = array.length - 1; i > 0; i--) {
    const j = randomSeedInRange(seed, 0, i + 1);
    const x = array[i];
    array[i] = array[j];
    array[j] = x;
  }

  return array;
};

export const getRandomElements = (array, numElements) => {
  // Create a copy of the array to shuffle
  let shuffledArray = array.slice();

  // Fisher-Yates shuffle algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  // Return the first `numElements` items from the shuffled array
  return shuffledArray.slice(0, numElements);
};
