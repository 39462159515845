import React, {useImperativeHandle, useState, useRef} from 'react';
import Input from '../Input';
import {requestVerificationCode, validateSmsCode} from '../../endpoints/api';
import {Box, Center, HStack, Image, useTheme, VStack} from '@chakra-ui/react';
import {TextG12, TextG14} from '../typography';
import {SpinnerLoader} from '../Loader';
import successImg from '../../assets/images/success.png';
import {BlueButtonFixedWidth} from '../button';
import BottomModalSheet, {BottomModalSheetRefProps} from '../Modals/BottomModalSheet';
import {ToastMessage} from '../toast';
import {VERIFICATION_CODE_LENGTH} from '../../pages/Auth/Register/RegisterForm';
import CustomPinInput from '../CustomPinInput';
//fix toast wrapper has inline styles which can not be overriden with chakra theme extend
import './style.css';
import Modal from '../Modals/Modal';

type InputPhoneNumberProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSuccess: () => void;
  onError: (error: string) => void;
  isRequired?: boolean;
  validationStatusIcon?: boolean;
  LeftIcon?: JSX.Element;
  RightIcon?: JSX.Element;
  RightIconOnClick?: () => any;
  roundBorder?: boolean;
  placeholder?: string;
  fontSize?: string | number;
  showLabel?: boolean;
};

export interface AvailableFunctions {
  sendVerifyCodeRequest(resend?: boolean): Promise<void>;
  sendResendCode(value: boolean): void;
  getValue(): string;
}

const InputPhoneNumber = React.forwardRef<AvailableFunctions, InputPhoneNumberProps>(
  (
    {
      value,
      onChange,
      onSuccess,
      onError,
      isRequired,
      validationStatusIcon,
      RightIcon,
      RightIconOnClick,
      roundBorder = false,
      placeholder,
      fontSize,
      showLabel = true,
      ...props
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      sendVerifyCodeRequest,
      sendResendCode,
      getValue,
    }));

    const theme = useTheme();
    const [inputVal, setInputVal] = useState(value);
    const [showPhoneVerification, setShowPhoneVerification] = useState(false);
    const [isVerificationInProgress, setVerificationProgress] = useState(false);
    const [verificationError, setVerificationError] = useState('');
    const bottomModalCallback = (isOpen) => setShowPhoneVerification(!!isOpen);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [resendCode, setResendCode] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [verifyButtonEnabled, setVerifyButtonEnabled] = useState(false);
    const bottomModalSheetRef = useRef<BottomModalSheetRefProps>();

    const sendResendCode = (value) => setResendCode(value);

    const sendVerifyCodeRequest = (resend: boolean = false) =>
      requestVerificationCode(inputVal)
        .then(() => {
          setShowPhoneVerification(true);
          if (resend) {
            ToastMessage({
              status: 'info',
              text: 'Code resent',
            });
          }
        })
        .catch(({response}) => onError(response.data.message));

    const getValue = () => inputVal;

    const handleSubmitPhoneVerification = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setVerificationProgress(true);

      validateSmsCode(inputVal, verificationCode)
        .then(() => {
          setVerificationProgress(false);
          setPhoneVerified(true);
          setTimeout(() => {
            setShowPhoneVerification(false);
            onSuccess();
          }, 2000);
        })
        .catch((err) => {
          setVerificationProgress(false);
          setVerificationError(err.message);
        });
    };

    return (
      <>
        <Input
          fontSize={fontSize}
          placeholder={placeholder}
          isRequired={isRequired}
          size="sm"
          id="phone_number"
          label={showLabel ? 'Phone number' : ''}
          name="phone_number"
          type="tel"
          typeOfInput={'phoneNumber'}
          value={inputVal}
          onChange={(val) => {
            setInputVal(val?.toString());
            onChange(val);
          }}
          RightIcon={RightIcon}
          RightIconOnClick={RightIconOnClick}
          validationStatusIcon={validationStatusIcon}
          roundBorder={roundBorder}
          {...props}
        />
        {/* <BottomModalSheet
          closeOnOverlayClick={false}
          disableScrollLocking={true}
          ref={bottomModalSheetRef}
          openModal={showPhoneVerification}
          onModalClose={() => {
            setVerificationCode('');
            setShowPhoneVerification(false);
          }}> */}
        <Modal
          isCentered
          closeButton
          closeOnOverlayClick={false}
          show={showPhoneVerification}
          parentCallback={() => {
            setVerificationCode('');
            setShowPhoneVerification(false);
          }}>
          <VStack px={6} py={8} alignItems={'left'} spacing={2}>
            <TextG14 fontWeight={700}>Enter Code</TextG14>
            <TextG12 color={theme.colors.grey}>
              We’ve sent a code to your phone number. Didn’t receive the code?
              <Box
                onClick={() => sendVerifyCodeRequest(true)}
                as={'span'}
                color={theme.colors.blue}>
                {' '}
                Click here
              </Box>{' '}
              to resend.
            </TextG12>
            <form style={{width: '100%'}} onSubmit={handleSubmitPhoneVerification}>
              <HStack spacing={4} py={4}>
                <CustomPinInput
                  autoFocus
                  letterSpacing="5vw"
                  value={verificationCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    setVerificationCode(value.toUpperCase());
                    setVerifyButtonEnabled(value.length >= VERIFICATION_CODE_LENGTH);
                  }}
                  type="alphanumeric"
                  placeholder=""
                  variant="flushed"
                  maxLength={VERIFICATION_CODE_LENGTH}
                  fontSize="20px"
                  textAlign="center"
                />
                {/* <PinInput
                  type="alphanumeric"
                  placeholder=""
                  value={verificationCode}
                  onChange={setVerificationCode}
                  variant="flushed"
                  otp={true}>
                  {[...Array(VERIFICATION_CODE_LENGTH)].map((key, i) => (
                    <FormControl key={key} isRequired>
                      <PinInputField
                        color={theme.colors.lightBlack}
                        borderBottom={`2px solid ${theme.colors.grey}`}
                        textAlign={'center'}
                        fontSize={'18px'}
                        w="42px"
                        h="42px"
                        p={0}
                      />
                    </FormControl>
                  ))}
                </PinInput> */}
              </HStack>
              {isVerificationInProgress ? (
                <SpinnerLoader py={2} />
              ) : phoneVerified ? (
                <TextG14 my={7} textAlign={'center'}>
                  Good to go!
                  <Image
                    display="inline"
                    pl="5px"
                    verticalAlign="sub"
                    src={successImg}
                    alt="Successful"
                  />
                </TextG14>
              ) : (
                <>
                  {verificationError && (
                    <Center>
                      <TextG12 color={'red'}>
                        That code isn’t working. Try again or re-enter your phone number.
                      </TextG12>
                    </Center>
                  )}
                  <Center>
                    <BlueButtonFixedWidth
                      disabled={!verifyButtonEnabled}
                      type={'submit'}
                      mt={8}
                      border={`1px solid ${theme.colors.grey}`}>
                      Verify
                    </BlueButtonFixedWidth>
                  </Center>
                </>
              )}
            </form>
          </VStack>
        </Modal>
      </>
    );
  }
);

InputPhoneNumber.defaultProps = {
  validationStatusIcon: false,
};

export default InputPhoneNumber;
