import {Box, Tab, TabList, TabPanel, TabPanels, Tabs, useTheme, VStack} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {filter, pull, map} from 'lodash';
import dayjs from 'dayjs';
import {useSearchParams} from 'react-router-dom';
import {Offer, Partner} from '../../../types/article';
import About from '../About';
import OfferCard from '../../../components/Articles/Offers/OfferCard';
import EventCard from '../../../components/Articles/EventCard';
import PostCard from '../../../components/Articles/PostCard';
import SignUpRequiredWrapper from '../../../components/SignupRequiredModal/SignupRequiredWrapper';

const PartnerTabs = ({
  partner,
  isPreview = false,
  setSignUpRequiredOpen,
}: {
  partner: Partner;
  isPreview?: boolean;
  setSignUpRequiredOpen?: any;
}) => {
  const theme = useTheme();
  const now = dayjs();

  const tabs = ['About'];

  const filteredEvents = filter(partner.events, (e) => now.isBefore(dayjs.utc(e.end_date_time)));

  const filteredOffers = filter(partner.offers, (offer) => offer.readable_status == 'Available');

  const hasEvents = filteredEvents && filteredEvents.length > 0;
  const hasPosts = partner.articles && partner.articles.length > 0;
  const hasOffers = filteredOffers && filteredOffers.length > 0;

  if (hasOffers) {
    tabs.push('Offers');
  } else {
    pull(tabs, 'Offers');
  }

  if (hasEvents) {
    tabs.push('Events');
  } else {
    pull(tabs, 'Events');
  }

  if (hasPosts) {
    tabs.push('Posts');
  } else {
    pull(tabs, 'Posts');
  }

  const [searchParams] = useSearchParams();
  const activeTabIndex = tabs.includes(searchParams.get('tab') || '')
    ? tabs.indexOf(searchParams.get('tab') || '')
    : 0;
  const [tabIndex, setTabIndex] = React.useState(activeTabIndex);
  const handleTabsChange = (index) => setTabIndex(index);

  useEffect(() => {
    setTabIndex(activeTabIndex);
  }, [searchParams]);

  const tabStyle: React.CSSProperties = React.useMemo(
    () => ({
      borderBottomColor: theme.colors.blue,
      color: theme.colors.blue,
    }),
    []
  );

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange} align="center">
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab} _selected={tabStyle}>
            {tab}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        <TabPanel p="0">
          <About
            isPreview={isPreview}
            partner={partner}
            setSignUpRequiredOpen={setSignUpRequiredOpen}
          />
        </TabPanel>

        {!isPreview && hasOffers && (
          <TabPanel>
            <VStack gap="15px">
              {map(filteredOffers, (offer) => (
                <Box mt="0 !important" w="100%" textAlign={'start'} key={offer.id}>
                  <OfferCard offer={offer as Offer} />
                </Box>
              ))}
            </VStack>
          </TabPanel>
        )}
        {hasEvents && (
          <TabPanel>
            <VStack gap="15px">
              {map(filteredEvents, (event) => (
                <Box mt="0 !important" w="100%" textAlign={'start'} key={event.id}>
                  <EventCard
                    onClick={isPreview ? () => setSignUpRequiredOpen(true) : undefined}
                    isPreview={isPreview}
                    event={event}
                  />
                </Box>
              ))}
            </VStack>
          </TabPanel>
        )}

        {hasPosts && (
          <TabPanel>
            <VStack gap="15px">
              {map(partner.articles, (post) => (
                <Box
                  mt="0 !important"
                  w="100%"
                  textAlign={'start'}
                  key={post.id}>
                  <PostCard
                    onClick={isPreview ? () => setSignUpRequiredOpen(true) : undefined}
                    isPreview={isPreview}
                    post={post}
                  />
                </Box>
              ))}
            </VStack>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default PartnerTabs;
