import React, {useEffect, useLayoutEffect, useState} from "react";
import styled from 'styled-components';
import {useLocation} from "react-router-dom";
import angleUp from '../../assets/images/angle-up.png';
import {Image, useTheme} from "@chakra-ui/react";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .top-btn {
    font-size: 1.2rem;
    width: 2.6rem;
    height: 2.6rem;
    background-color: white;
    border-radius: 50%;
    position: fixed;
    bottom: 4.5rem;
    right: 1rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const StyledImage = styled(Image)`
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: auto;
`;

const ScrollTop = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      // window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = window.innerHeight / 2;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Wrapper>
      {isVisible && (
        <div className="top-btn" onClick={scrollToTop} style={{ border: `1px solid ${theme.colors.lightestGrey}`}}>
          <StyledImage src={angleUp} alt="Arrow left" w={'1.1rem'} h={'1.1rem'} />
        </div>
      )}
    </Wrapper>
  );
};

export default ScrollTop;