import {Flex, Image, useTheme, Center, Spinner} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useFetchPublicEvent} from '../../hooks/useEvent';
import {Event, Place} from '../../types/article';
import PreviewHeader from './Header';
import PreviewAbout from './About';
import useAuth from '../../hooks/useAuth';
import {isMobile} from '../../utils/deviceCheck';
import BackButton from '../../components/BackButton';
import useOfferModal from '../../hooks/useOfferModal';
import EventHelmet from '../Event/EventHelmet';
import {useFetchPublicPlace} from '../../hooks/useFetchPlace';
import MediaGallery from '../../components/MediaGallery';
import MeetupHeader from '../../assets/images/meetup-header.png';
import SignupRequiredModal from '../../components/SignupRequiredModal';

const MeetupPreview = () => {
  const theme = useTheme();
  const {eventId} = useParams();
  const {user} = useAuth();
  const navigate = useNavigate();
  const fetchEvent = useFetchPublicEvent(Number(eventId));
  const meetup: Event = fetchEvent.data?.data;

  const fetchPlace = useFetchPublicPlace(Number(meetup?.place_id));
  const place: Place = fetchPlace.data?.data;

  const isLoading = fetchEvent.isLoading;

  const [inviteCode, setInviteCode] = useState('');
  const [ni, setNi] = useState('');
  const [qrCodeId, setQRCodeId] = useState('');
  const [searchParams] = useSearchParams();
  const [signUpRequiredOpen, setSignUpRequiredOpen] = useState(false);

  useEffect(() => {
    if (!!searchParams.get('invite-code') && searchParams?.get('invite-code') !== '') {
      setInviteCode(searchParams?.get('invite-code') || '');
    }

    if (!!searchParams.get('ni') && searchParams?.get('ni') !== '') {
      setNi(searchParams?.get('ni') || '');
    }

    if (!!searchParams.get('qr_code_id') && searchParams?.get('qr_code_id') !== '') {
      setQRCodeId(searchParams?.get('qr_code_id') || '');
    }
  }, [searchParams]);

  const onSignupClicked = () => {
    if (window.self !== window.top) {
      // The page is being rendered in an iframe
      window.top ? (window.top.location.href = 'https://my.walkabout.app/soft-check') : null;
      return;
    } else {
      // The page is not in an iframe
      if (user) {
        navigate('/download');
      } else if (inviteCode) {
        navigate('/register', {state: {code: inviteCode, ni, qrCodeId: qrCodeId}});
      } else if (qrCodeId) {
        navigate('/register', {state: {qrCodeId: qrCodeId}});
      } else {
        navigate('/soft-check');
      }
    }
  };

  useEffect(() => {
    if (user && meetup) {
      const queryString = searchParams.toString();
      const newPath = queryString
        ? `/meet-up/${meetup.id}?${queryString}`
        : `/meet-up/${meetup.id}`;

      isMobile ? navigate(newPath, {replace: true}) : null;
    }
  }, [user, meetup]);

  if (isLoading || meetup === undefined || (user && isMobile))
    return (
      <Center minH="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <>
      <EventHelmet event={meetup} />
      <Flex position="relative" height="250px">
        {window.self !== window.top && (
          <BackButton onClick={() => navigate(-1)} m={'.75rem'} pos="relative" />
        )}
        {place?.media || meetup?.place?.default_media || meetup?.place?.default_image ? (
          <MediaGallery
            media={place?.media || [meetup?.place?.default_media] || [meetup?.place?.default_image]}
          />
        ) : (
          <Image
            fallbackStrategy="onError"
            src={MeetupHeader}
            alt="cover image"
            position="absolute"
            width="100%"
            height="100%"
            objectFit="cover"
            zIndex="-1"
            borderBottomRadius="14px"
          />
        )}
      </Flex>
      <PreviewHeader
        event={meetup}
        isHost={false}
        setSignUpRequiredOpen={setSignUpRequiredOpen}
        inviteCode={inviteCode}
      />
      <PreviewAbout event={meetup} isHost={false} />
      <SignupRequiredModal
        isOpen={signUpRequiredOpen}
        setIsOpen={setSignUpRequiredOpen}
        onButtonClicked={onSignupClicked}
      />
    </>
  );
};

export default MeetupPreview;
