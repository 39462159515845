import {Flex, HStack, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import Icon from '../Icon';
import {TextA10, TextA14, TextG10} from '../typography';

const GetMembership = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Flex
      onClick={() => navigate('/memberships')}
      mt={1}
      borderRadius="8px"
      background={theme.colors.blue}
      p={3}
      justifyContent="space-between"
      alignItems="center">
      <HStack>
        <TextA14 fontWeight="700" color="white">
          Check Out Premium Memberships
        </TextA14>
        {/* <TextA10
          fontStyle="italic"
          borderRadius="15px"
          fontWeight="700"
          px={2}
          py={1}
          background="white"
          color={theme.colors.rose}>
          Free Member
        </TextA10> */}
      </HStack>
      <Icon
        iconName="fi fi-rr-arrow-small-right"
        style={{
          color: 'white',
          fontSize: '20px',
          marginTop: '2px',
        }}
      />
    </Flex>
  );
};

export default GetMembership;
