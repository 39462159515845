import { createSlice } from '@reduxjs/toolkit'

export type AlertPopupProps = {
    [index: string]: string | (() => void);
}

export type SliceState = AlertPopupProps[];
type SliceAction = { payload: AlertPopupProps, type: string };

const initialState: SliceState = [];

export const AlertPopupSlice = createSlice({
    name: 'alertPopup',
    initialState,
    reducers: {
        pushNotification: (state: SliceState, action: SliceAction) => {
            state.push({...action.payload });
        },
        popNotification: (state: SliceState) => {
            state.shift();
        }
    },
});

export const AlertPopupActions = AlertPopupSlice.actions;

export default AlertPopupSlice;