import { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from "react";
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import {useDispatch} from "react-redux";
import { AlertPopupActions } from "../state/AlertPopup/AlertPopupSlice";

type ExtendNavigator = Navigator & Pick<History, "block">;
export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export default function usePrompt(title: string, message: string, when = true) {
  const dispatch = useDispatch();
  const blocker = useCallback((tx: Transition) => {
    dispatch(AlertPopupActions.pushNotification({ title, message, action: tx.retry }));
  }, [message]);

  useBlocker(blocker, when);
}