import {
  fetchMembershipPlan,
  fetchMembershipPlans,
  fetchMembershipStripLink,
} from '../endpoints/api';
import {useQuery} from '@tanstack/react-query';

export function useFetchMembershipStripeURL(
  membershipId: number,
  userId?: number,
  enabled: boolean = true
) {
  return useQuery(
    ['membership_stripe', membershipId, userId],
    () => fetchMembershipStripLink(membershipId),
    {
      enabled: enabled && typeof membershipId === 'number' && typeof userId === 'number',
      cacheTime: 0, // Don't save in cache because stripe URLs should be generated everytime
    }
  );
}

export function useFetchMemberships() {
  return useQuery(['memberships'], () => fetchMembershipPlans());
}

export function useFetchMembership(membershipId: number, enabled: boolean = true) {
  return useQuery(['membership', membershipId], () => fetchMembershipPlan(membershipId), {
    enabled: enabled && typeof membershipId === 'number',
  });
}
