import {Image, Center, VStack, HStack, theme, Box} from '@chakra-ui/react';
import React, {useState} from 'react';
import {BlueButton} from '../../../components/button';
import {LabelledCheckboxGrey} from '../../../components/checkbox';
import Modal from '../../../components/Modals/Modal';
import {H2, TextG12} from '../../../components/typography';
import {OPTIONS} from '../../../constants/eventOptions';
import { Community } from "../../../types/community";

const CommunitySuccessModal: React.FC<{community: Community}> = ({community}) => {
  const [show, setShow] = useState(true);

  return (
    <Modal
      headerPadding={2}
      isCentered={true}
      closeOnOverlayClick={false}
      show={show}
      closeButton={true}
      header={
        <>
          {
            <Center pb={12}>
              <Box w={90} h={90} position="absolute" top="-45px">
                <Image
                  background="white"
                  borderRadius="100%"
                  w="90px"
                  h="90px"
                  src={community.logo}
                />
              </Box>
            </Center>
          }
          <Center>
            <H2>You're In!</H2>
          </Center>
        </>
      }>
      <VStack pb={2} spacing={4}>
        <VStack spacing={1}>
          <TextG12 fontWeight={500}>{`You're now part of ${community.name}!`}</TextG12>
          <TextG12 fontWeight={500}>{`You can start chatting in the community now`}</TextG12>
        </VStack>
        {/* {description && (
          <Center>
            <TextG12 textAlign={'center'} fontWeight={500} color={theme.colors.grey}>
              {description}
            </TextG12>
          </Center>
        )} */}

        <Center w={'100%'}>
          <BlueButton
            onClick={(e) => {
              setShow(false);

              window.location.href = `/communities/${community.id}/chat`;
            }}
            w={'100%'}>
            Go to Community Chat now
          </BlueButton>
        </Center>
      </VStack>
    </Modal>
  );
};

export default CommunitySuccessModal;
