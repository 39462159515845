import React, {useRef, useState} from 'react';
import {Editable, EditableInput, EditablePreview, Input, Button, useTheme} from '@chakra-ui/react';
import {TextG10} from '../../../components/typography';
import EditableControls from '../../../components/EditableControls';
import {useUpdateMeetUp} from '../../../hooks/useMeetUps';
import {getEventDate} from '../../../utils/date';
import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const DateTimeEditor: React.FC<{event: any}> = ({event}) => {
  const theme = useTheme();
  const {mutateAsync} = useUpdateMeetUp();
  const inputRef = useRef<HTMLInputElement>(null);
  // Friendly display date (could be something like "Friday, Oct 1, 2:00 PM")
  const eventDate = getEventDate(event.start_date_time, event.end_date_time);
  // Properly formatted date for datetime-local input: "YYYY-MM-DDTHH:mm"
  //   const formattedStartDate = event.start_date_time
  //     ? new Date(event.start_date_time).toISOString().slice(0, 16)
  //     : '';
  // State to hold the datetime value (always in ISO format)
  const [dateValue, setDateValue] = useState(
    dayjs.utc(event.start_date_time).local().format(DATE_FORMAT)
  );

  // Track if user is editing
  const [isEditingDate, setIsEditingDate] = useState(false);

  return (
    <Editable
      pos="relative"
      w="100%"
      fontSize="10px"
      fontFamily="Gordita"
      value={dateValue}
      onChange={setDateValue}
      onEdit={() => {
        inputRef.current?.focus();
        setIsEditingDate(true);
      }}
      onCancel={() => setIsEditingDate(false)}
      onSubmit={(newValue) => {
        const newStart = dayjs(newValue);
        const originalDuration = dayjs(event.end_date_time).diff(dayjs(event.start_date_time));
        const newEnd = newStart.add(originalDuration, 'millisecond');
        const utcStartDate = newStart.utc().format(DATE_FORMAT);
        const utcEndDate = newEnd.utc().format(DATE_FORMAT);

        mutateAsync({
          ...event,
          start_date_time: utcStartDate,
          end_date_time: utcEndDate,
          eventId: event.id,
        });
        setIsEditingDate(false);
      }}
      isPreviewFocusable={false}
      selectAllOnFocus={false}>
      {isEditingDate ? (
        <EditableInput ref={inputRef} as={Input} type="datetime-local" />
      ) : (
        <TextG10 fontWeight="500" color={theme.colors.grey} maxW="150px" isTruncated noOfLines={1}>
          {eventDate}
        </TextG10>
      )}
      <EditableControls />
    </Editable>
  );
};

export default DateTimeEditor;
