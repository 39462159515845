import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  useTheme,
  useDisclosure,
  Modal as ChakraModal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Image,
  Box,
  Flex,
} from '@chakra-ui/react';
import Icon from '../Icon';
import IconCustom from '../../components/Icon';
import {isEmpty} from 'lodash';
import {TextG12, TextG14, TextG18, TextG8} from '../typography';
import {useNavigate} from 'react-router-dom';
import BottomModalSheet, {BottomModalSheetRefProps} from '../Modals/BottomModalSheet';
import HeaderImg from '../../assets/images/support-header.png';
import useAuth from '../../hooks/useAuth';

const ChatbotLauncher = ({
  customLauncher,
  id,
  customElement,
  setChatItem,
  disabled = false,
}: {
  customLauncher?: JSX.Element;
  id?: string;
  customElement?: React.ReactNode;
  setChatItem?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const navigate = useNavigate();
  const bottomModalSheetRef = useRef<BottomModalSheetRefProps>();
  const [src, setSrc] = useState('');
  const {user} = useAuth();

  useEffect(() => {
    if (user && window.enchant) {
      window.enchant.push(function () {
        window.enchant.messenger.clearIdentity();
        window.enchant.messenger.identify({
          name: `${user.name} ${user.surname}`,
          email: `${user.email}`,
        });
      });
    }
  }, [user, window.enchant]);

  const close = () => {
    onClose();
    setSrc('');
    setChatItem && setChatItem(false);
  };

  const open = () => {
    onOpen();
    setChatItem && setChatItem(true);
  };

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      fontSize: '1.3rem',
      height: '28px',
      marginBottom: '1px',
      paddingTop: '3px',
    }),
    [theme]
  );

  const optionStyle: React.CSSProperties = React.useMemo(
    () => ({
      fontSize: '18px',
      paddingTop: '3px',
      color: '#757D84',
    }),
    [theme]
  );

  const chatOptions = useMemo(
    () => [
      {
        header: 'Get Support',
        description: 'Browse our support articles or contact us!',
        icon: 'fi-rr-comment',
        src: ``,
      },
      {
        header: 'Submit a bug',
        description: 'Tell us what you found so we can squash it!',
        icon: 'fi-rr-bug',
        src: `https://share.hsforms.com/11msXg4XzS6WCQrM1QTnlCweektv?name=${user?.name}&surname=${user?.surname}&email=${user?.email}`,
      },
      {
        header: 'Suggest a feature',
        description: 'Got ideas about the app? Let us know!',
        icon: 'fi-rr-rocket-lunch',
        src: `https://share.hsforms.com/1obOWmmFRTOy6DgxMXWsp5geektv?name=${user?.name}&surname=${user?.surname}&email=${user?.email}`,
      },
      {
        header: 'Recommend a Place or Event',
        description: `Know of a place or event we're missing? Tell us about it!`,
        icon: 'fi-rr-shop',
        src: `https://share.hsforms.com/1nxmc5wtYSJOhVGqeNLnFIweektv?name=${user?.name}&surname=${user?.surname}&email=${user?.email}`,
      },
    ],
    [user]
  );

  return (
    <BottomModalSheet
      triggerDisabled={disabled}
      ref={bottomModalSheetRef}
      onModalClose={close}
      triggerElement={
        <Box>
          {!isEmpty(customLauncher) ? (
            <Box id={id} onClick={disabled ? () => {} : open}>
              {customLauncher}
            </Box>
          ) : (
            <Flex alignItems="center" flexDirection={'column'}>
              {isOpen && customElement}
              <IconCustom
                id={id}
                onClick={disabled ? () => {} : open}
                iconName={'fi-rr-comment-user'}
                style={iconStyle}
              />
              <TextG8 fontWeight={isOpen ? '700' : '400'}>Support</TextG8>
            </Flex>
          )}
        </Box>
      }>
      <>
        {src && <iframe src={src} style={{height: '535px', width: '100%'}} frameBorder="0" />}
        {!src && (
          <Flex flexDir={'column'} px={3}>
            <TextG18 fontWeight="700">Help & Support</TextG18>
            <TextG12
              my={2}
              onClick={() => {
                close();
                bottomModalSheetRef.current?.setState(false);
                navigate('/home?tutorial=t');
              }}
              color={theme.colors.blue}>
              View Quick Tutorial
            </TextG12>
            <Image src={HeaderImg} mr={-3} ml={-3} width="100vw" maxW="100vw" />

            <Box px={3}>
              {chatOptions.map((option, i) => (
                <Flex
                  key={i}
                  onClick={() => {
                    if (option.header !== 'Get Support') setSrc(option.src);
                    else {
                      close();
                      bottomModalSheetRef.current?.setState(false);
                      window.enchant?.messenger?.open();
                    }
                  }}
                  alignItems="center"
                  borderBottom="1px solid #E5E5E5"
                  py={5}
                  flexDir="row"
                  gap="20px">
                  <Icon iconName={option.icon} style={optionStyle} />
                  <Flex flexDir="column">
                    <TextG14 color="#272929" fontWeight="700">
                      {option.header}
                    </TextG14>
                    <TextG12 color="#757D84">{option.description}</TextG12>
                  </Flex>
                </Flex>
              ))}
            </Box>
          </Flex>
        )}
      </>
    </BottomModalSheet>
  );

  // return (
  //   <>
  //     {!isEmpty(customLauncher) ? (
  //       <Box id={id} onClick={open}>
  //         {customLauncher}
  //       </Box>
  //     ) : (
  //       <>
  //         {isOpen && customElement}
  //         <IconCustom id={id} onClick={open} iconName={'fi-rr-comment-user'} style={iconStyle} />
  //         <TextG8 fontWeight={isOpen ? '700' : '400'}>Support</TextG8>
  //       </>
  //     )}

  //     <ChakraModal isOpen={isOpen} onClose={close}>
  //       <ModalOverlay />
  //       <ModalContent mx={2} mb="5vh" mt="5vh" h="85vh">
  //         <ModalHeader>
  //           {'Help & Support'}
  //           <TextG12
  //             onClick={() => {
  //               close();
  //               navigate('/home?tutorial=t');
  //             }}
  //             color={theme.colors.blue}>
  //             View Quick Tutorial
  //           </TextG12>
  //         </ModalHeader>
  //         <ModalCloseButton />
  //         <ModalBody p={0}>
  //           <iframe
  //             src="https://x.formito.com/app/hb7gfRpYMWyESAogQE2x"
  //             width="100%"
  //             height="100%"
  //             frameBorder="0"
  //             style={{borderRadius: '6px'}}
  //           />
  //         </ModalBody>
  //       </ModalContent>
  //     </ChakraModal>
  //   </>
  // );
};

export default ChatbotLauncher;
