import {
  AbsoluteCenter,
  Box,
  Container,
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  Image,
  useTheme,
  VStack,
  Textarea,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import Header from '../../assets/images/create-meetup.png';
import {BlueButton} from '../../components/button';
import IceBreakerSelect from '../../components/IceBreakerSelect';
import Icon from '../../components/Icon';
import {TextG10, TextG12, TextG14, TextG24} from '../../components/typography';
import {EventRSVPStatus, useSetAttendance} from '../../hooks/useEvent';
import {useCreateMeetup} from '../../hooks/useMeetUps';
import {Place} from '../../types/article';
import PlaceSelectModal from './PlaceSelectModal';
import {format, endOfDay, addMinutes, startOfToday} from 'date-fns';
import HourSelect from './HourSelect';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import {useSearchParams} from 'react-router-dom';
import {fetchPlace} from '../../endpoints/api';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const CreateMeetup = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {mutateAsync} = useCreateMeetup();
  const setAttendance = useSetAttendance();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState<string>('');
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [iceBreaker, setIceBreaker] = useState<string>('');
  const [customIceBreaker, setCustomIceBreaker] = useState<string>('');

  const [timeOption, setTimeOption] = React.useState('1');

  const [minTime, setMinTime] = useState<string>();
  const [maxTime, setMaxTime] = useState<string>();

  const [inviteGroup, setInviteGroup] = useState('1');
  const [eventDuration, setEventDuration] = useState(1);

  const isValid =
    description &&
    startDate &&
    startDate.length > 0 &&
    selectedPlace &&
    (iceBreaker == 'custom' ? customIceBreaker : iceBreaker);

  const handleCreateClicked = async () => {
    if (!isValid) {
      return;
    }

    const start = timeOption == '1' ? dayjs() : startDate;
    const end = timeOption == '1' ? dayjs().add(eventDuration, 'hour') : endDate;

    const utcStartDate = dayjs(start).utc().format(DATE_FORMAT);
    const utcEndDate = dayjs(end).utc().format(DATE_FORMAT);

    const newMeetup = await mutateAsync({
      title: title,
      description: description,
      reason: iceBreaker == 'custom' ? customIceBreaker : iceBreaker,
      start_date_time: utcStartDate,
      end_date_time: utcEndDate,
      is_private: false,
      place_id: selectedPlace.id,
    });

    if (newMeetup.data?.event) {
      const createdEvent = newMeetup.data?.event;
      await setAttendance.mutateAsync({
        eventId: createdEvent.id,
        prevStatus: EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.INTERESTED,
      });

      navigate(`/meet-up/${newMeetup.data?.event?.id}`, {replace: true});
    }
  };

  useEffect(() => {
    const now = new Date();
    const closestInterval = Math.ceil(now.getMinutes() / 15) * 15; // Nearest 15-minute interval
    const nextAvailableTime = addMinutes(startOfToday(), now.getHours() * 60 + closestInterval);
    setStartDate(dayjs(nextAvailableTime).format(DATE_FORMAT));
    setEndDate(dayjs(nextAvailableTime).add(1, 'hour').format(DATE_FORMAT));
    setEndDate(dayjs(startDate).add(Number(timeOption), 'hour').format(DATE_FORMAT));
    setMinTime(dayjs(nextAvailableTime).format(DATE_FORMAT));
    setMaxTime(dayjs(nextAvailableTime).add(6, 'weeks').format(DATE_FORMAT));
  }, []);

  useEffect(() => {
    setEndDate(dayjs(startDate).add(eventDuration, 'hour').format(DATE_FORMAT));
  }, [eventDuration, startDate]);

  useEffect(() => {
    if (!!searchParams.get('placeId')) {
      const placeId = Number(searchParams.get('placeId'));
      if (placeId) {
        fetchPlace({id: placeId}).then((res) => {
          setSelectedPlace(res.data);
        });
      }
    }
  }, [searchParams]);

  return (
    <Container p="2px 3px">
      <VStack align="start" h="100%">
        <Box pos="relative">
          <AbsoluteCenter>
            <TextG24 color="white" fontWeight="700" whiteSpace="nowrap">
              Invite Locals to Join You
            </TextG24>
          </AbsoluteCenter>
          <Image src={Header} />
          <Icon
            onClick={() => navigate(-1)}
            iconName="fi-rr-cross-small"
            style={{
              position: 'absolute',
              right: '18px',
              top: '13px',
              fontSize: '24px',
              height: '24px',
              width: '24px',
              color: 'white',
              fill: 'white',
            }}
          />
        </Box>
        <VStack py={3} px={4} w="100%" gap="20px">
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Name Your Invitation
              </TextG14>
            </FormLabel>
            <Textarea
              resize="none"
              borderRadius="10px !important"
              minH="85px"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter a title"
              py={3}
              px={4}
              color={theme.colors.lightBlack}
              _placeholder={{
                color: theme.colors.lightestGrey,
                fontWeight: '500',
                fontSize: '12px',
                fontFamily: 'Gordita',
              }}
              border={`1px solid ${theme.colors.lighterGrey} !important`}
            />
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                What are your plans?
              </TextG14>
            </FormLabel>
            <Textarea
              resize="none"
              borderRadius="10px !important"
              minH="80px"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Tell your neighbors what you're doing and who to look for -- ex: I'm grabbing a beer by the bar, look for the red hat!"
              py={3}
              px={4}
              color={theme.colors.lightBlack}
              _placeholder={{
                fontFamily: 'Gordita',
                color: theme.colors.lightestGrey,
                fontWeight: '500',
                fontSize: '12px',
                paddingRight: '3rem',
              }}
              border={`1px solid ${theme.colors.lighterGrey} !important`}
            />
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Select a time
              </TextG14>
            </FormLabel>
            <input type="hidden" id="timezone" name="timezone" value="-08:00" />
            <RadioGroup onChange={setTimeOption} value={timeOption}>
              <Stack direction="column">
                <Radio
                  colorScheme="green"
                  value="1"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <TextG14 color={timeOption != '1' ? 'gray.300' : 'initial'}>Now</TextG14>
                </Radio>
                <Radio
                  colorScheme="green"
                  value="2"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <Flex flexDir="row" align="center" gap="10px">
                    <TextG14 color={timeOption != '2' ? 'gray.300' : 'initial'} whiteSpace="nowrap">
                      Later
                    </TextG14>
                    <Input
                      isDisabled={timeOption != '2'}
                      max={maxTime}
                      min={minTime}
                      border="1px solid #E5E5E5 !important"
                      fontFamily="Gordita"
                      fontSize="12px !important"
                      fontWeight="500"
                      defaultValue={startDate}
                      onChange={(e) => {
                        const selectedDate = dayjs(e.target.value);
                        setStartDate(selectedDate.format(DATE_FORMAT));
                        setEndDate(selectedDate.add(eventDuration, 'hour').format(DATE_FORMAT));
                      }}
                      borderRadius="22.5px !important"
                      padding="9px 24px"
                      placeholder="Select Date and Time"
                      color={timeOption != '2' ? 'gray.300' : 'initial'}
                      size="xs"
                      type="datetime-local"
                      height="auto"
                      sx={{
                        '&::-webkit-datetime-edit': {
                          color: timeOption !== '2' ? 'gray.300' : theme.colors.lightBlack, // Change text color for enabled/disabled states
                        },
                        '&::-webkit-datetime-edit-year-field': {
                          color: timeOption !== '2' ? 'gray.300' : theme.colors.lightBlack, // Ensure consistent color for year field
                        },
                        '&::-webkit-datetime-edit-month-field': {
                          color: timeOption !== '2' ? 'gray.300' : theme.colors.lightBlack, // Month field color
                        },
                        '&::-webkit-datetime-edit-day-field': {
                          color: timeOption !== '2' ? 'gray.300' : theme.colors.lightBlack, // Day field color
                        },
                        '&::-webkit-calendar-picker-indicator': {
                          opacity: timeOption !== '2' ? 0.5 : 1, // Adjust calendar icon opacity
                        },
                      }}
                    />
                    {/* <DatePicker
                      color={timeOption != '2' ? 'gray.300' : 'initial'}
                      disabled={timeOption != '2'}
                      // minTime={minTime}
                      // maxTime={maxTime}
                      selected={selectedTime}
                      onChange={handleTimeChange}
                      showTimeSelect
                      //showTimeSelectOnly
                      showTimeCaption={false}
                      timeIntervals={15} // Time intervals of 15 minutes
                      //dateFormat={DATE_FORMAT} // 12-hour format with AM/PM
                      customInput={
                        <Box
                          color={timeOption !== '2' ? 'gray.400' : 'black'}
                          mx={2}
                          as="button"
                          bg={timeOption !== '2' ? 'gray.100' : 'white'}
                          border="1px solid"
                          borderColor={timeOption !== '2' ? 'gray.300' : 'gray.500'}
                          borderRadius="md"
                          px={1}
                          py={1}
                          fontSize="sm"
                          textAlign="center"
                          width="85px"
                          _hover={{}}
                          _focus={{}}>
                          {selectedTime ? format(selectedTime, 'hh:mm aa') : 'Select'}
                        </Box>
                      }
                    /> */}
                  </Flex>
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Flex flexDir="row" alignItems="stretch" gap="10px">
              <TextG14 color={theme.colors.lightBlack}>For</TextG14>
              <HourSelect
                hourSelected={eventDuration}
                onChange={(value) => setEventDuration(value)}
              />
              <TextG14 color={theme.colors.lightBlack}>
                {eventDuration == 1 ? 'Hour' : 'Hours'}
              </TextG14>
            </Flex>
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Invite
              </TextG14>
            </FormLabel>
            <input type="hidden" id="timezone" name="timezone" value="-08:00" />
            <RadioGroup onChange={setInviteGroup} value={inviteGroup}>
              <Stack direction="column">
                <Radio
                  colorScheme="green"
                  value="1"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <TextG14 color={inviteGroup != '1' ? 'gray.300' : 'initial'}>
                    Anyone nearby
                  </TextG14>
                </Radio>
                <Radio
                  colorScheme="green"
                  value="2"
                  transition="color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease">
                  <Flex flexDir="row" align="center">
                    <TextG14 color={inviteGroup != '2' ? 'gray.300' : 'initial'}>
                      Insiders Club Members Only
                    </TextG14>
                  </Flex>
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Select a Place
              </TextG14>
            </FormLabel>
            <PlaceSelectModal selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} />
          </FormControl>
          <FormControl>
            <FormLabel display="flex" alignItems="center">
              <TextG14 fontWeight="700" color={theme.colors.lightBlack}>
                Ice Breaker
              </TextG14>
            </FormLabel>
            <FormHelperText mb={4}>
              <TextG12 color={theme.colors.grey}>
                Choose something to talk about before your meet-up so you can get to know each other
                before you hang out!
              </TextG12>
            </FormHelperText>
            <IceBreakerSelect
              iceBreaker={iceBreaker}
              setIceBreaker={setIceBreaker}
              customIceBreaker={customIceBreaker}
              setCustomIceBreaker={setCustomIceBreaker}
            />
          </FormControl>
          <BlueButton isDisabled={!isValid} onClick={handleCreateClicked} w="100%" bottom={0}>
            <Flex gap="8px" alignItems="center">
              <Icon
                iconName="fi-rr-megaphone"
                style={{fontSize: '16px', width: '16px', height: '13px'}}
              />
              <TextG14>Broadcast To Walkabouters</TextG14>
            </Flex>
          </BlueButton>
        </VStack>
      </VStack>
    </Container>
  );
};

export default CreateMeetup;
