import React, {useEffect, useState} from 'react';
import {
  Image,
  Text,
  Button,
  Center,
  HStack,
  useTheme,
  Box,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Link,
} from '@chakra-ui/react';
import {fetchNumOfRegisteredUsers, validateInvitationCode} from '../../../../endpoints/api';
import LandingWrapper from '../../../../components/LandingWrapper';
import successImg from '../../../../assets/images/success.png';
import warningImg from '../../../../assets/images/warning.png';
import {TextG14} from '../../../../components/typography';
import {SpinnerLoader} from '../../../../components/Loader';
import {useNavigate} from 'react-router-dom';
import CustomPinInput from '../../../../components/CustomPinInput';
import {getAppName} from '../../../../utils/app';
import ExternalLink from '../../../../components/ExternalLink';

// const SquareInput = styled(PinInputField)`
//   width: 42px !important;
//   height: 42px !important;
//   ${(props) =>
//     props.background &&
//     css`
//       background: ${props.background};
//     `};
//   border-radius: 5px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 18px !important;
//   line-height: 22px;
//   padding: 10px !important;
//   ${(props) =>
//     props.color &&
//     css`
//       color: ${props.color};
//     `};
//   text-align: center;
// `;

const INVITATION_CODE_LENGTH = 6;
const MRI_CODE_LENGTH = 10;

const isFirstCharLetter = (str: string): boolean => {
  if(!str.length) return false;

  const firstLetter = str.charAt(0);

  return !!firstLetter.match(/[a-zA-Z]/i);
}

function InviteForm() {
  const theme = useTheme();
  const [numOfRegisteredUsers, setNumOfRegisteredUsers] = useState(0);
  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [isInvitationCodeCorrect, setInvitationCodeStatus] = useState(false);
  const [isValidationTriggered, setValidationTrigger] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [codeLength, setCodeLength] = useState(INVITATION_CODE_LENGTH);

  const [codeAddress, setCodeAddress] = useState();
  const [isFenton, setIsFenton] = useState(false);

  useEffect(() => {
    fetchNumOfRegisteredUsers().then(({data: {count}}) => setNumOfRegisteredUsers(count));
  }, []);

  const handleValidate = (inviteCode: string) => {
    setShowLoader(true);
    validateInvitationCode(inviteCode).then(({data: {isValid, message, invite_code}}) => {
      if (!isValid) {
        setInvitationCodeStatus(false);
        !!message ? setValidationMessage(message) : setValidationMessage(''); //reset message if response not present for error message
      } else {
        setCode(invite_code.code);
        setInvitationCodeStatus(true);
        setCodeAddress(invite_code.address);
        setIsFenton(invite_code.is_fenton);
      }
      setValidationTrigger(true);
      setShowLoader(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isInvitationCodeCorrect) {
      handleValidate(code);
    } else {
      // nextStep(invitationCode);
      navigate('/register', {state: {code, codeAddress, isFenton}});
    }
  };

  return (
    <LandingWrapper
      title={`Welcome to ${getAppName()}`}
      subtitle={'Experience more and love where you live.'}
      numOfRegistered={numOfRegisteredUsers}>
      <form onSubmit={handleSubmit}>
        <Center mt={'4vh'}>
          <Text color="white" fontSize="lg" fontWeight="bold" mb="18px">
            Enter your invite code here:
          </Text>
        </Center>
        <Center>
          <HStack spacing="7px" w={codeLength === INVITATION_CODE_LENGTH ? '50%' : '85%'}>
            <InputGroup>
              <InputLeftElement />
              <CustomPinInput
                autoComplete="one-time-code"
                inputMode="text"
                letterSpacing="4vw"
                value={code}
                fontSize="20px"
                textAlign="center"
                variant="flushed"
                //type="number"
                maxLength={codeLength}
                onPaste={(e) =>
                  isFirstCharLetter(e.clipboardData.getData('text'))
                    ? setCodeLength(MRI_CODE_LENGTH)
                    : setCodeLength(INVITATION_CODE_LENGTH)
                }
                onChange={(e) => {
                  isFirstCharLetter(e.target.value)
                    ? setCodeLength(MRI_CODE_LENGTH)
                    : setCodeLength(INVITATION_CODE_LENGTH);
                  setCode(e.target.value);
                  if (e.target.value.length >= codeLength) {
                    handleValidate(e.target.value);
                    e.target.blur();
                  } else {
                    setValidationTrigger(false);
                    setInvitationCodeStatus(false);
                  }
                }}
                color={theme.colors.white}
              />
              <InputRightElement
                children={
                  isValidationTriggered &&
                  (isInvitationCodeCorrect ? (
                    <Image src={successImg} alt="Successful" />
                  ) : (
                    <Image src={warningImg} alt="Warning" />
                  ))
                }
              />
            </InputGroup>
            {/* <PinInput
              placeholder=""
              onChange={(value) => {
                setCode(value);
                if (value.length >= INVITATION_CODE_LENGTH && !isInvitationCodeCorrect) {
                  handleValidate(value);
                }
              }}>
              {[...Array(INVITATION_CODE_LENGTH)].map((key, i) => (
                <FormControl key={i} isRequired>
                  <SquareInput
                    id={i}
                    background={theme.colors.lightGrey}
                    color={theme.colors.grey}
                    border={`2px solid ${theme.colors.grey}`}
                    textAlign={'center'}
                    fontSize={'18px'}
                  />
                </FormControl>
              ))}
            </PinInput> */}
          </HStack>
        </Center>
        {showLoader ? (
          <Box pt={16}>
            <SpinnerLoader />
          </Box>
        ) : (
          <>
            <Center>
              <Button
                mt={4}
                background={theme.colors.blue}
                color="white"
                borderRadius="22px"
                px="64px"
                isDisabled={code.length < INVITATION_CODE_LENGTH}
                _disabled={{
                  backgroundColor: theme.colors.darkerGrey,
                  color: theme.colors.lighterGrey,
                }}
                type="submit">
                Continue
              </Button>
            </Center>
            {isValidationTriggered && (
              <>
                {!isInvitationCodeCorrect ? (
                  <TextG14 mt={4} px={18} color={theme.colors.yellow} textAlign={'center'}>
                    {!!validationMessage ? validationMessage : 'Code is not valid.'} <br />
                    <ExternalLink
                      isExternal
                      href={`${process.env.CONDITIONS_WEB_PAGE_URL}/contact-us/`}
                      textDecoration={'underline'}>
                      Contact us
                    </ExternalLink>
                    {' for help.'}
                  </TextG14>
                ) : (
                  <TextG14 mt={4} color="white" textAlign={'center'}>
                    Nicely done! Let's keep going.
                  </TextG14>
                )}
              </>
            )}
            {!isValidationTriggered && (
              <Center mt={4}>
                <TextG14 px={18} color={theme.colors.lightestGrey} textAlign={'center'}>
                  Don’t have your code? <br />
                  <ExternalLink
                    isExternal
                    href="https://walkabout.app/" // TODO: hardcoded, should be added as an .env file property for development purposes and as a server environment property
                    textDecoration={'underline'}>
                    Click here
                  </ExternalLink>
                  {' to get invited.'}
                </TextG14>
              </Center>
            )}
          </>
        )}
      </form>
    </LandingWrapper>
  );
}

export default InviteForm;
