import React from 'react';
import {Event} from '../../../types/article';
import {map, orderBy} from 'lodash';
import EventCard from '../../../components/Articles/EventCard';
import {SimpleGrid} from '@chakra-ui/react';
import MeetUpCard from '../../../components/Articles/MeetUpCard';
import useAuth from '../../../hooks/useAuth';
import RequiresEarlyAccess from '../../../components/RequiresEarlyAccess';

const PlaceEvents = ({
  events,
  setSignUpRequiredOpen,
  preview = false,
}: {
  events: Event[];
  setSignUpRequiredOpen?: any;
  preview?: boolean;
}) => {
  return (
    <SimpleGrid gap="15px" columns={1} textAlign="initial">
      {map(orderBy(events, 'start_date_time'), (event: Event, i) => {
        return (
          <div key={i} style={{display: 'contents'}}>
            {event.is_meetup ? (
              <MeetUpCard
                onClick={preview ? () => setSignUpRequiredOpen(true) : undefined}
                key={event.id}
                meet-up={event}
              />
            ) : (
              <EventCard
                onClick={preview ? () => setSignUpRequiredOpen(true) : undefined}
                key={event.id}
                event={event}
              />
            )}
          </div>
        );
      })}
    </SimpleGrid>
  );
};

export default PlaceEvents;
