import React, {useEffect, useState} from 'react';
import {take} from 'lodash';
import {H3, TextG12} from '../typography';
import {Box, HStack, useTheme, Wrap, WrapItem, Flex} from '@chakra-ui/react';
import {without} from 'lodash';
import {useLocation} from 'react-router-dom';

type Element = {
  id: number;
  name?: string;
  icon: string;
  total?: number;
};

type RoundedBoxItemsParams = {
  title?: string;
  elements: Array<Element>;
  selected?: number[];
  selectionDisabled?: boolean;
  onSelect?: (...params) => void;
  titleStyle?: React.CSSProperties;
  selectIndicator?: React.ReactNode;
  showOnlyNum?: number;
  showHashtag?: boolean;
  initialSelected?: number[];
};

const SHOW_BOXES_STEP = 5;

const RoundedBoxItems = ({
  title,
  elements,
  selected,
  selectionDisabled,
  onSelect,
  titleStyle,
  selectIndicator,
  showOnlyNum,
  showHashtag = true,
  initialSelected,
}: RoundedBoxItemsParams) => {
  const theme = useTheme();
  const [selectedTopics, setSelectedTopics] = useState<number[]>(initialSelected || selected || []);
  const [showOnly, setShowOnly] = useState(showOnlyNum || SHOW_BOXES_STEP);
  const {pathname} = useLocation();

  const handleSelection = (id) => {
    if (!selectionDisabled) {
      const isSelected = selectedTopics.includes(id);
      setSelectedTopics(isSelected ? without(selectedTopics, id) : [...selectedTopics, id]);
      onSelect && onSelect(id, !isSelected);
    }
  };

  useEffect(() => {
    selected &&
      !pathname.includes('profile') &&
      !pathname.includes('register') &&
      setSelectedTopics(selected);
  }, [selected, pathname]);

  useEffect(() => {
    if (initialSelected) {
      setSelectedTopics((prev) => [...prev, ...initialSelected]);
    }
  }, [initialSelected]);

  return (
    <Box>
      <Flex gap={'2'} alignItems={'baseline'}>
        {title && (
          <H3 mb={4} style={titleStyle}>
            {title}
          </H3>
        )}
        {selectIndicator && selectIndicator}
      </Flex>
      <Wrap>
        {take(elements, showOnly).map(({id, name, total}) => {
          return (
            <WrapItem
              key={id}
              alignItems={'center'}
              border={'1px solid #E5E5E5'}
              borderRadius={'22.5px'}
              py={1.5}
              px={2}
              onClick={() => handleSelection(id)}
              bg={selectedTopics.includes(id) ? theme.colors.lighterBlue : 'unset'}
              color={selectedTopics.includes(id) ? 'white' : 'unset'}>
              <HStack>
                <TextG12>{`${showHashtag ? `#` : ''}${name}`}</TextG12>
                {total && <TextG12 fontWeight={500}>({total})</TextG12>}
              </HStack>
            </WrapItem>
          );
        })}
        {elements.length > showOnly && (
          <WrapItem
            key={'more'}
            alignItems={'center'}
            border={'1px solid #E5E5E5'}
            borderRadius={'22.5px'}
            py={1}
            px={2}
            onClick={() => setShowOnly(showOnly + elements.length)}>
            <HStack>
              <TextG12>more...</TextG12>
            </HStack>
          </WrapItem>
        )}
      </Wrap>
    </Box>
  );
};

export default RoundedBoxItems;
