import {Box, HStack, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {TextG12, TextG14} from '../../../components/typography';
import {OBSERVATORY_ID} from '../PaymentOptions/options';

const OrderDetails = ({event, ticket, pass}) => {
  const theme = useTheme();
  const {qty} = useSelector((state: any) => state.checkout);

  const isObservatoryEvent = event?.place_id == OBSERVATORY_ID;

  return (
    <Box w="100%" px={3} background={theme.colors.veryLightBlue} margin="0px !important">
      <HStack justifyContent={'space-between'} pb={4}>
        {ticket ? (
          <TextG14 pl={1} fontWeight="700">
            {ticket.name ? ticket.name : 'The Premium Lounge'}
          </TextG14>
        ) : (
          <TextG14 dangerouslySetInnerHTML={{__html: pass.title}} fontWeight="700">
            {}
          </TextG14>
        )}
        {!isObservatoryEvent && (
          <TextG12 fontWeight="500" sx={{wordWrap: 'normal !important', whiteSpace: 'nowrap'}}>
            {ticket ? `Tickets: ${qty}` : `Qty: ${qty}`}
          </TextG12>
        )}
      </HStack>
    </Box>
  );
};

export default OrderDetails;
