import React from 'react';
import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  ListItem,
  Spacer,
  UnorderedList,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import BackButton from '../../../components/BackButton';
import {H2, H3, TextG12} from '../../../components/typography';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import PointHistory from './PointHistory';
import StarIcon from '../../../assets/images/star-icon.png';

export const PointsAndRewardsTopBar = ({onClose}: {onClose?: void}) => {
  const navigate = useNavigate();

  return (
    <Box px={4} py={6}>
      <Flex>
        <BackButton pos={'relative'} onClick={onClose ? onClose : () => navigate(-1)} />
        <Box flex="1">
          <Center>
            <H3>Points & Rewards</H3>
          </Center>
        </Box>
      </Flex>
    </Box>
  );
};

const TextBox = ({title, description}: {title: React.ReactNode; description: React.ReactNode}) => {
  const theme = useTheme();
  return (
    <Box bg={theme.colors.darkerWhite} borderRadius={'7px'} py={4} px={5} mb={4}>
      <Grid templateRows="repeat(2, 0fr)" templateColumns="repeat(8, 1fr)">
        <GridItem rowSpan={2} colSpan={1}>
          <Box
            w={'25px'}
            h={'25px'}
            bg={theme.colors.white}
            borderRadius={'50%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}>
            <Image src={StarIcon} alt={'Star'} width={'21px'} height={'21px'} />
          </Box>
        </GridItem>
        <GridItem colSpan={7} mb={2}>
          <TextG12 fontWeight={500} color={theme.colors.darkGrey}>
            {title}
          </TextG12>
        </GridItem>
        <GridItem colSpan={7}>
          <Box fontFamily="Gordita" fontSize="12px" lineHeight="16px" color={theme.colors.grey}>
            {description}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

const PointsAndRewards = () => {
  const {user} = useAuth();
  const theme = useTheme();

  return (
    <Container mb={8}>
      <Box bg={theme.colors.darkerWhite} borderRadius={'7px'} py={4} px={6} mb={4}>
        <VStack spacing={1} alignItems={'start'}>
          <TextG12 fontWeight={500} color={theme.colors.darkGrey}>
            Points Balance
          </TextG12>
          <H2 color={theme.colors.blue}>
            <strong>{user?.pointsTotal.toLocaleString() || 0}</strong> points
          </H2>
        </VStack>
      </Box>
      <PointHistory />
      <Box pb={12}>
        <H3 py={4}>How Points & Rewards Work</H3>
        <TextBox
          title={<>Earn points by doing more</>}
          description={
            <>
              Walkabout makes earning points an effortless feat and something you can do multiple
              times a day. Community engagement is key and will determine the number of points you
              earn and the types of redemption options available.
            </>
          }
        />
        <TextBox
          title={<>Points for Check-ins</>}
          description={
            <>
              You can earn points by checking in to any local business in your neighborhood. Whether
              grabbing a coffee, going to the gym, or meeting friends at a bar, you can check-in to
              the place upon arrival and earn points. Open the app, get directions and Walkabout to
              the Place, and click the Check-In icon when you arrive. That’s it! You’ve just earned
              points just for exploring something new in your community.
            </>
          }
        />
        <TextBox
          title={<>Bonus Points</>}
          description={
            <>
              You can earn bonus points by completing enrollment, community challenges, winning
              contests, reaching milestones, referring friends, and more. Bonus point opportunities{' '}
              <br />
              <strong>Challenges & Contests (Examples):</strong>
              <UnorderedList>
                <ListItem>
                  <strong>Limited Quantity:</strong> Be one of the first members to visit a new
                  place
                </ListItem>
                <ListItem>
                  <strong>Limited Time:</strong> Check-in to new places during hours when promotions
                  are offered
                </ListItem>
                <ListItem>
                  <strong>Milestones:</strong> Complete a specific number of challenges, check-ins,
                  and contests
                </ListItem>
                <ListItem>
                  <strong>Up-Vote and Help Curate Content:</strong> Share feedback and experiences,
                  or suggest places, events, and news items
                </ListItem>
                <ListItem>
                  <strong>Referrals:</strong> Refer friends to the app, events, or places
                </ListItem>
              </UnorderedList>
            </>
          }
        />
        <TextBox
          title={<>Redeem Points</>}
          description={
            <>
              Our redemption marketplace is ever-growing and represents the number of partnerships
              we form with local merchants.
              <br />
              <strong>Types of Redemptions:</strong>
              <UnorderedList>
                <ListItem>
                  Merchant Rewards: Are discounted rewards designed to give you more bang for your
                  “point” with increased exclusivity!
                </ListItem>
                <ListItem>
                  Giving Back: Point donations have higher value, and we add funds to the amount you
                  donate.
                </ListItem>
                <ListItem>
                  Points for purchase: Use your points as cash at participating merchant locations.
                </ListItem>
              </UnorderedList>
            </>
          }
        />
      </Box>
    </Container>
  );
};

export default PointsAndRewards;
