import React, {useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import PlaceCard from '../../components/Articles/PlaceCard';
import {BookmarkElement} from '../../components/Articles';
import ArticleInfo from '../../components/Articles/ArticleInfo';
import InfiniteScroll from '../../components/InfiniteScroll';
import {filterPlaceByType, fetchPlaces} from '../../endpoints/api';
import {Container} from '@chakra-ui/react';
import {usePlaceTypes, placeTypeData} from '../../hooks/usePlaceTypes';
import {map, filter, lowerCase, isEmpty} from 'lodash';
import {SpinnerLoader} from '../../components/Loader';
import ArticleTabsNavigation from '../../components/ArticleTabsNavigation';
import {articleTabs} from '../Tabs';
import useGetUserLocation from '../../hooks/useUserLocation';
import SelectPlaces, {MultiValueSelect} from '../../components/SelectPlaces';

const FilterPlaces = ({endpoint, noItems, renderArticleType}) => {
  const [endpointParams, setEndpointParams] = useState({});
  let {category} = useParams();
  const {placeTypes, getIdsForCategory, placeTypesLoading} = usePlaceTypes();
  const userLocation = useGetUserLocation();
  const [selectedPlaceTypes, setSelectedPlaceTypes] = useState<MultiValueSelect>([]);

  useEffect(() => {
    if (!category || !placeTypesLoading) {
      const ids = getIdsForCategory(category);

      const selectedTypes: unknown = filter(placeTypeData, (item) => {
        return lowerCase(item.category) == lowerCase(category);
      });

      setSelectedPlaceTypes(selectedTypes as MultiValueSelect);
      setEndpointParams({
        type: 'place',
        typeIds: ids,
        lat: userLocation?.data?.latitude,
        long: userLocation?.data?.longitude,
      });
    }
  }, [placeTypes, category]);

  return (
    <Container>
      {!isEmpty(endpointParams) ? (
        <>
          <ArticleTabsNavigation articleTabs={articleTabs} selectedIndex={1} />
          <Outlet />
          <Container my={4} p={0}>
            <SelectPlaces
              selected={selectedPlaceTypes}
              changeHandler={(e: MultiValueSelect) => {
                const typeIds: any = [];
                map(e, (option) => typeIds.push(...getIdsForCategory(option.category)));

                setSelectedPlaceTypes(e);
                setEndpointParams({
                  type: 'place',
                  typeIds: typeIds,
                  lat: userLocation?.data?.latitude,
                  long: userLocation?.data?.longitude,
                });
              }}
            />
            <InfiniteScroll
              showItems={(item) =>
                React.cloneElement(<PlaceCard />, {
                  ['place']: item,
                  leftTopItem: <ArticleInfo article={item} renderArticleType={renderArticleType} />,
                  rightTopItem: <BookmarkElement article={{...item, type: 'place'}} />,
                })
              }
              noItems={noItems}
              endpoint={endpoint}
              endpointParams={endpointParams}
            />
          </Container>
        </>
      ) : (
        <SpinnerLoader />
      )}
    </Container>
  );
};

FilterPlaces.defaultProps = {
  endpoint: filterPlaceByType,
  noItems: null,
  renderArticleType: false,
};

export default FilterPlaces;
