import {Center, Spinner, useTheme} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchQRDetails} from '../../hooks/useQRCodeDetails';
import useTrackEvent from '../../hooks/useTrackEvent';

const QRCodeHandler = () => {
  const theme = useTheme();
  const {slug} = useParams();
  const {data, isLoading, isError} = useFetchQRDetails(slug);
  const navigate = useNavigate();
  const {addUserEvent} = useTrackEvent();

  const redirectURL = data?.data?.qr_code.url;

  useEffect(() => {
    if (redirectURL) {
      window.location.href = redirectURL;

      addUserEvent({
        type: 'redirect',
        event_name: 'QR_code_redirect',
        event_time: dayjs().format(),
        data: {redirectUrl: redirectURL, slug: slug},
      });

      return;
    }

    if ((!redirectURL && !isLoading) || isError) {
      navigate('/home');

      addUserEvent({
        type: 'redirect',
        event_name: 'QR_code_error',
        event_time: dayjs().format(),
        data: {slug: slug},
      });
    }
  }, [data, redirectURL]);

  return (
    <Center h="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor={theme.colors.lightGrey}
        color={theme.colors.lightBlue}
        size="lg"
      />
    </Center>
  );
};

export default QRCodeHandler;
