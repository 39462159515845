// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { APPLICATION_KEYS } from "./constants/appKeys";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDRQFu_X1MDlYZcUbBQyDGPcVVMfQEzf34",
  authDomain: "walkabout-6f67f.firebaseapp.com",
  projectId: "walkabout-6f67f",
  storageBucket: "walkabout-6f67f.appspot.com",
  messagingSenderId: "702257515735",
  appId: "1:702257515735:web:89831046c4d7729864b492"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
let messaging;
try {
  isSupported().then((isSupported) => {
    if (!isSupported) {
      console.log('Firebase Messaging is not supported');
    }
    else {
      messaging = getMessaging();
    }
  });
} catch (err) {
  console.error('Failed to initialize Firebase Messaging', err);
}

export const fetchToken = async () => {
  if (!(await isSupported())) {
    console.log('Firebase Messaging is not supported');
    return;
  }

  return getToken(messaging, {vapidKey: APPLICATION_KEYS.publicKey}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // alert(currentToken);
      // setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export function askNotificationPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    } else {
      console.log("Notification permission granted!");
      // displayConfirmNotification();
      return fetchToken();
    }
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});