import React from 'react';
import Icon from '../../../Icon';

export enum CommunityChatMenuOption {
  SHARE = 'Share This Community',
  GUIDELINES = 'Community Guidelines',
  COMMUNITY_PAGE = 'Community Page',
}

export const OPTIONS = [
  {
    option: CommunityChatMenuOption.GUIDELINES,
    renderIcon: (props: any) => <Icon iconName={'fi-rr-pennant'} {...props} />,
    color: 'red',
    text: CommunityChatMenuOption.GUIDELINES,
  },
  {
    option: CommunityChatMenuOption.COMMUNITY_PAGE,
    renderIcon: (props: any) => <Icon iconName={'fi-sr-guitar'} {...props} />,
    color: '#5976bc',
    text: CommunityChatMenuOption.COMMUNITY_PAGE,
  },
  {
    option: CommunityChatMenuOption.SHARE,
    renderIcon: (props: any) => <Icon iconName={'fi-rr-share'} {...props} />,
    color: 'lighterBlue',
    text: CommunityChatMenuOption.SHARE,
  },
];
