import React, {CSSProperties, useEffect} from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ResponsiveValue, ModalProps,
} from '@chakra-ui/react';

type ModalParams = {
  children: React.ReactChild;
  parentCallback?: (...params) => void;
  show: boolean;
  closeOnOverlayClick?: boolean;
  isCentered?: boolean;
  closeButton?: boolean;
  header?: JSX.Element;
  footer?: JSX.Element;
  modalPadding?: number | string;
  closeButtonPosition?: 'left' | 'right';
  headerPadding?: number | string;
  closeButtonColor?: string;
  overlayBackground?: string;
  closePosition?: ResponsiveValue<any> | null;
  scrollBehavior?: 'inside' | 'outside';
  contentStyle?: CSSProperties;
};

const Modal = ({
  children,
  parentCallback,
  show,
  closeButton,
  header,
  footer,
  modalPadding,
  closeButtonPosition = 'right',
  headerPadding,
  closeButtonColor,
  overlayBackground,
  scrollBehavior= "outside",
  contentStyle,
  ...props
}: ModalParams) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => (show ? onOpen() : onClose()), [show]);

  const handleOnClose = () => {
    onClose();
    parentCallback && parentCallback(false);
  };

  return (
    <ChakraModal
      {...props}
      isOpen={isOpen}
      onClose={handleOnClose}
      autoFocus={false}
      returnFocusOnClose={false}
      scrollBehavior={scrollBehavior}>
      <ModalOverlay background={overlayBackground ? overlayBackground : 'rgba(0, 0, 0, 0.48)'} />
      <ModalContent style={contentStyle} boxShadow="none">
        <ModalHeader p={headerPadding ? headerPadding : ''}>{header}</ModalHeader>
        {closeButton && (
          <ModalCloseButton
            zIndex={1}
            color={closeButtonColor ? closeButtonColor : ''}
            _focus={{}}
            left={closeButtonPosition == 'left' ? 2 : ''}
          />
        )}
        <ModalBody p={modalPadding}>{children}</ModalBody>
        {footer && <ModalFooter pt={2}>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
};

Modal.defaultProps = {
  header: <></>,
};

export default Modal;
