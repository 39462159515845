import {Box, Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import BottomModalSheet from '../Modals/BottomModalSheet';
import {TextG14} from '../typography';
import {deviceDetected} from '../../utils/deviceCheck';

type BottomSheetSelectProps = {
  options: Array<any>;
  openModal: boolean;
  onModalClose: () => void;
  onClick: (option: any) => void;
};

const BottomSheetSelect = ({options, onModalClose, openModal, onClick}: BottomSheetSelectProps) => {
  const theme = useTheme();
  const device = deviceDetected();

  return (
    <BottomModalSheet openModal={openModal} onModalClose={onModalClose}>
      <Box px={3} pb={device === 'iphone' || device === 'iphone-pwa' ? '14px' : 0}>
        {options.map((option, i) => {
          const style = {
            style: {
              fontSize: '18px',
              height: '18px',
              width: '18px',
              color: theme.colors[option.color] || option.color || 'blue',
              fill: theme.colors[option.color] || option.color || 'white',
            },
          };
          return (
            <Flex
              key={i}
              alignItems="center"
              borderBottom="1px solid #E5E5E5"
              pt={i == 0 ? 0 : 5}
              pb={5}
              flexDir="row"
              gap="20px"
              onClick={() => {
                onClick(option.option);
                option.message && option.message();
              }}>
              {option.renderIcon(style)}
              <Flex flexDir="column">
                <TextG14>{option.text}</TextG14>
              </Flex>
            </Flex>
          );
        })}
      </Box>
    </BottomModalSheet>
  );
};

export default BottomSheetSelect;
