import useGlobalSearch from '../../../hooks/useGlobalSearch'
import React, {useEffect, useState} from 'react'
import {
  castBoolean, getAppliedFiltersForContentType,
  getFiltersConfigForPopup,
  isDateValid,
} from '../../../utils/GlobalSearch/helpers'
import {FilterOption} from '../../../utils/GlobalSearch/types'
import {CONTENT_TYPE, FILTER_TYPES} from '../../../utils/GlobalSearch/enums'
import {has, isArray} from 'lodash'
import {useQueryClient} from '@tanstack/react-query'
import {Box, Divider, VStack} from '@chakra-ui/react'
import ToggleFilter from '../FilterTypes/Toggle'
import SelectFilter from '../FilterTypes/Select'
import DatePickerFilter from '../FilterTypes/DatePicker'
import {BlueButton} from '../../button'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import {BottomModalSheetRefProps} from '../../Modals/BottomModalSheet'
import 'react-datepicker/dist/react-datepicker.css'
import {FILTERS} from '../FilterConfig'
import useAuth from "../../../hooks/useAuth";

dayjs.extend(weekday)

type ContentTypeFiltersProps = {
  type: CONTENT_TYPE
  sheetRef: React.MutableRefObject<BottomModalSheetRefProps | undefined>
}
const ContentTypeFilters = ({type, sheetRef}: ContentTypeFiltersProps) => {
  const { user } = useAuth();
  const {filters, setFilters, getTypeSpecificFilter} = useGlobalSearch()

  const contentTypeFiltersConfig = FILTERS[type]
  const contentTypeFiltersConfigForPopup = getFiltersConfigForPopup(type)
  const queryClient = useQueryClient()

  const getSelectedOptions = ({options, acc, filterConfigKey, filterComponentKey}) => {
    if (options) {
      const values = getTypeSpecificFilter(type, filterConfigKey) || []

      // Check if it already exists, in that case we merge options into one array
      if (has(acc, filterComponentKey)) {
        return {
          [filterComponentKey]: [
            ...acc[filterComponentKey],
            ...options.filter((option) => {
              const valType = contentTypeFiltersConfig[filterConfigKey].valueType
              if (valType && option.type !== valType) {
                return false
              }
              return (
                option.value &&
                values.includes(
                  typeof values[0] === 'string' ? option.value.toString() : option.value
                )
              )
            }),
          ],
        }
      }

      return {
        [filterComponentKey]: options.filter((option) => {
          const valType = contentTypeFiltersConfig[filterConfigKey].valueType
          if (valType && option.type !== valType) {
            return false
          }

          // based on this check we can determine if it is a single select or multi select
          if(!isArray(values)) {
            return (
              option.value &&
              values === (typeof values === 'string' ? option.value.toString() : option.value)
            )
          }

          return (
            option.value &&
            values.includes(typeof values[0] === 'string' ? option.value.toString() : option.value)
          )
        }),
      }
    }

    return {}
  }

  const initialState = () => {
    return Object.keys(contentTypeFiltersConfig).reduce((acc, filterConfigKey) => {
      const filterComponentKey = contentTypeFiltersConfig[filterConfigKey].key
      switch (contentTypeFiltersConfig[filterConfigKey].type) {
        case FILTER_TYPES.TOGGLE:
          return {
            ...acc,
            [filterComponentKey]: castBoolean(getTypeSpecificFilter(type, filterConfigKey)),
          }
        case FILTER_TYPES.MULTISELECT:
        case FILTER_TYPES.SINGLE_SELECT:
          const options = contentTypeFiltersConfig[filterConfigKey].options
          return {
            ...acc,
            ...getSelectedOptions({options, acc, filterConfigKey, filterComponentKey}),
          }
        case FILTER_TYPES.DATEPICKER:
          const date = getTypeSpecificFilter(type, filterConfigKey)
          return {...acc, [filterComponentKey]: date ? new Date(date?.toString()) : null}
      }
    }, {})
  }

  useEffect(() => {
    const filtersToApply = Object.keys(contentTypeFiltersConfig).reduce((acc, filterConfigKey) => {
      const filterComponentKey = contentTypeFiltersConfig[filterConfigKey].key
      if (contentTypeFiltersConfig[filterConfigKey]?.fetchOptions) {
        const options = queryClient.getQueryData([filterComponentKey])
        return {
          ...acc,
          ...getSelectedOptions({options, acc, filterConfigKey, filterComponentKey}),
        }
      }

      return acc
    }, {})

    setContentTypeFilters({
      ...contentTypeFilters,
      ...filtersToApply,
    })
  }, [])

  const [contentTypeFilters, setContentTypeFilters] = useState(initialState())

  const applyFilters = () => {
    setFilters({
      ...filters,
      [type]: Object.keys(contentTypeFiltersConfig).reduce((acc, key) => {
        if(typeof contentTypeFiltersConfig[key]?.condition === 'function' && !contentTypeFiltersConfig[key]?.condition?.({
          filters: contentTypeFilters,
          user
        })) {
          return acc;
        }
        const filterKey = contentTypeFiltersConfig[key].key

        // Hide filters turned off by other filters
        if (contentTypeFilters[filterKey]?.value && contentTypeFiltersConfig[key]?.hides) {
          contentTypeFiltersConfig[key].hides?.forEach((hide) => {
            delete acc[hide]
          });
        }

        switch (contentTypeFiltersConfig[key].type) {
          case FILTER_TYPES.TOGGLE:
            return {...acc, [filterKey]: +contentTypeFilters[filterKey]}
          case FILTER_TYPES.MULTISELECT:
            return {
              ...acc,
              [key]: contentTypeFiltersConfig[key].valueType
                ? contentTypeFilters[filterKey]
                    ?.filter((item) => item.type === contentTypeFiltersConfig[key].valueType)
                    ?.map((item) => item.value)
                : contentTypeFilters[filterKey]?.map((item) => item.value),
            }
          case FILTER_TYPES.SINGLE_SELECT:
            return {
              ...acc,
              [key]: contentTypeFilters[filterKey]?.value
            }
          case FILTER_TYPES.DATEPICKER:
            const filter = contentTypeFilters[filterKey];
            return {
              ...acc,
              ...(filter &&
                isDateValid(filter.toString()) && {
                  [filterKey]: dayjs(filter).format('YYYY-MM-DD HH:mm:ss'),
                }),
            }
        }
      }, {}),
    })
    sheetRef?.current?.setState(false)
  }

  const shouldHideFilter = (key: string) => {
    return Object.keys(contentTypeFiltersConfigForPopup).some((filterKey) => {
      return contentTypeFiltersConfigForPopup[filterKey]?.hides?.includes(key) && getTypeSpecificFilter(type, filterKey)
    });
  }

  return (
    <VStack px={2} align="start" maxHeight={'500px'} overflow={'scroll'}>
      <Box w={'100%'}>
        {Object.keys(contentTypeFiltersConfigForPopup).map((key) => {
          if(typeof contentTypeFiltersConfig[key]?.condition === 'function' && !contentTypeFiltersConfig[key]?.condition?.({
            filters: contentTypeFilters,
            user
          })) {
            return;
          }

          if(shouldHideFilter(key)) {
            return;
          }

          switch (contentTypeFiltersConfigForPopup[key].type) {
            case FILTER_TYPES.TOGGLE:
              return (
                <Box key={key}>
                  <ToggleFilter
                    id={key}
                    // key won't be passed to child since it is a 'special' prop in React. Pass id instead. https://react.dev/warnings/special-props
                    label={contentTypeFiltersConfigForPopup[key].label}
                    value={contentTypeFilters[key]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setContentTypeFilters({
                        ...contentTypeFilters,
                        [key]: e.target.checked,
                      })
                    }}
                  />
                  <Divider />
                </Box>
              )
            case FILTER_TYPES.MULTISELECT:
            case FILTER_TYPES.SINGLE_SELECT:
              const options = contentTypeFiltersConfigForPopup[key]?.options || []
              return (
                <Box key={key}>
                  <SelectFilter
                    id={key}
                    key={key} // key won't be passed to child since it is a 'special' prop in React. Pass id instead. https://react.dev/warnings/special-props
                    isMulti={contentTypeFiltersConfigForPopup[key].type === FILTER_TYPES.MULTISELECT}
                    label={contentTypeFiltersConfigForPopup[key].label}
                    value={contentTypeFilters[key]}
                    options={options.length ? options : queryClient.getQueryData([key]) || []}
                    onChange={(items) => {
                      setContentTypeFilters({
                        ...contentTypeFilters,
                        [key]: contentTypeFiltersConfigForPopup[key].type === FILTER_TYPES.MULTISELECT ? [...(items as FilterOption[])] : items as FilterOption,
                      })
                    }}
                  />
                  <Divider />
                </Box>
              )
            case FILTER_TYPES.DATEPICKER:
              return (
                <Box key={key}>
                  <DatePickerFilter
                    id={key}
                    key={key} // key won't be passed to child since it is a 'special' prop in React. Pass id instead. https://react.dev/warnings/special-props
                    label={contentTypeFiltersConfigForPopup[key].label}
                    value={contentTypeFilters[key]}
                    onChange={(date) =>
                      setContentTypeFilters({
                        ...contentTypeFilters,
                        [key]: date,
                      })
                    }
                  />
                  <Divider />
                </Box>
              )
          }
        })}
      </Box>
      <Box px={10} py={6} w={'100%'}>
        <BlueButton onClick={applyFilters} w={'100%'}>
          Show results
        </BlueButton>
      </Box>
    </VStack>
  )
}

export default ContentTypeFilters
