import {Flex} from '@chakra-ui/react';
import React from 'react';
import useAuth from '../../hooks/useAuth';
import {BlueButton} from '../button';
import Modal from '../Modals/Modal';
import {TextG12, TextG18} from '../typography';

const SignupRequiredModal = ({isOpen, setIsOpen, onButtonClicked}) => {
  const {user} = useAuth();
  return (
    <Modal
      closeButton
      modalPadding="10px 30px"
      isCentered
      header={
        <TextG18 fontSize="24px" mt="30px" textAlign="center" fontWeight="700">
          {user ? 'Get the Walkabout App' : 'Sign Up Required'}
        </TextG18>
      }
      show={isOpen}
      parentCallback={() => setIsOpen(false)}
      children={
        <>
          <TextG12>
            {user
              ? `You can only perform this action through the official Walkabout mobile app. No worries—just download the app on your phone using one of the links below, and you'll be good to go!`
              : 'In order to do this you must first join Walkabout! Click below to get started now'}
          </TextG12>
          <Flex justifyContent="center" pt={3} pb={3} gap="10px">
            <BlueButton
              onClick={() => {
                onButtonClicked();
              }}>
              {user ? 'Get the App Now' : 'Join Walkabout Now'}
            </BlueButton>
          </Flex>
        </>
      }
    />
  );
};

export default SignupRequiredModal;
