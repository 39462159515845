import {Box, Center, Flex, Spinner, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useYouTubeVideoData} from '../../hooks/useYoutube';
import Icon from '../Icon';
import {TextG14} from '../typography';

const BottomSheetOption = ({option, onClick, index}) => {
  const {data, isLoading} = useYouTubeVideoData(option.url);
  const [title, setTitle] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (data) {
      if (option.title) {
        setTitle(option.title);
      } else setTitle(data.snippet.title);
    }
  }, [data, option]);

  if (isLoading)
    return (
      <Center h="20px" my={5}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <Flex
      onClick={() => {
        onClick(option);
      }}
      alignItems="flex-start"
      borderBottom="1px solid #E5E5E5"
      pt={index == 0 ? 0 : 5}
      pb={5}
      px={3}
      flexDir="row"
      gap="12px">
      <Flex justifyContent="space-between" w="100%" align="center">
        <Flex gap="12px" align="center">
          {option.completed ? (
            <Box
              textAlign="center"
              h="16px"
              w="16px"
              minW="16px"
              minH="16px"
              borderRadius="5px"
              background="#C4C4C4">
              <Icon
                style={{color: 'white', fontSize: '8px', position: 'relative', bottom: '5px'}}
                iconName="fi-rr-check"
              />
            </Box>
          ) : (
            <Icon
              style={{
                marginTop: '2px',
                color: 'black',
                fontSize: '16px',
              }}
              iconName="fi-rr-play-alt"
            />
          )}
          <TextG14 as={option.completed ? 's' : ''} pr={20}>{`${title}`}</TextG14>
        </Flex>
        <Icon
          iconName="fi fi-rr-angle-small-right"
          style={{
            marginTop: '4px',
            color: 'black',
            fontSize: '16px',
          }}
        />
      </Flex>
    </Flex>
  );
};

export default BottomSheetOption;
