import {Box, Center, Spinner, Stack, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import EventCard from '../../../components/Articles/EventCard';
import PlaceCard from '../../../components/Articles/PlaceCard';
import SignupRequiredModal from '../../../components/SignupRequiredModal';
import SignUpRequiredWrapper from '../../../components/SignupRequiredModal/SignupRequiredWrapper';
import {TextA18} from '../../../components/typography';
import {fetchPublicOffer} from '../../../endpoints/api';
import useAuth from '../../../hooks/useAuth';
import useOfferModal from '../../../hooks/useOfferModal';
import {usePublicPost} from '../../../hooks/usePost';
import {isMobile} from '../../../utils/deviceCheck';
import Content from '../Content';
import Header from '../Header';

const PostPreview = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const {postId} = useParams();
  const {data, isLoading} = usePublicPost(postId || '');
  const [signUpRequiredOpen, setSignUpRequiredOpen] = useState(false);
  const {user} = useAuth();

  const post = data?.data;

  const [searchParams] = useSearchParams();
  const {showOfferModal} = useOfferModal();

  useEffect(() => {
    if (searchParams.get('offer')) {
      fetchPublicOffer(searchParams.get('offer')).then(({data}) => {
        if (user && post && isMobile) {
          navigate(`/post/${post.id}?offer=${data.id}`, {replace: true});
        } else {
          showOfferModal({offer: data, isPreview: true});
        }
      });
    } else if (user && post) {
      isMobile ? navigate(`/post/${post.id}`, {replace: true}) : null;
    }
  }, [user, post, searchParams.get('offer')]);

  if (isLoading || post === undefined) {
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  const onSignupClicked = () => {
    if (user) {
      navigate('/download');
    } else {
      navigate('/soft-check');
    }
  };

  return (
    <>
      <Header defaultImage={post.image_url} hideBack />
      <SignUpRequiredWrapper setSignUpRequiredOpen={setSignUpRequiredOpen}>
        <Content showBookmark={false} post={post} />
      </SignUpRequiredWrapper>
      <SignUpRequiredWrapper setSignUpRequiredOpen={setSignUpRequiredOpen}>
        <Stack gap=".5rem" p="1rem" textAlign="left">
          {post.event ? (
            <>
              <TextA18>Go To This Event</TextA18>
              <Box>
                <EventCard onClick={() => {}} isPreview event={post.event} hideRSVP />
              </Box>
            </>
          ) : post.place ? (
            <>
              <TextA18>Visit This Place</TextA18>
              <PlaceCard isPreview place={post.place} />
            </>
          ) : null}
        </Stack>
      </SignUpRequiredWrapper>
      <SignupRequiredModal
        isOpen={signUpRequiredOpen}
        setIsOpen={setSignUpRequiredOpen}
        onButtonClicked={onSignupClicked}
      />
    </>
  );
};

export default PostPreview;
