import {
  Container,
  Flex,
  Center,
  Spacer,
  Divider,
  useTheme,
  AbsoluteCenter,
  Box,
  Switch,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {AnimatePresence, motion} from 'framer-motion';
import {filter, groupBy, has, map, orderBy} from 'lodash';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Icon from '../../components/Icon';
import {H3, TextA18, TextG10, TextG14} from '../../components/typography';
import {useFetchUserTickets} from '../../hooks/useUserTickets';
import TicketCard from './TicketCard';

const MyTickets = () => {
  const theme = useTheme();
  const {data, isLoading} = useFetchUserTickets();
  const [showPastEvents, setShowPastEvents] = useState(false);

  // Toggle function to update the state
  const toggleShowPastEvents = () => {
    setShowPastEvents((prevState) => !prevState);
  };

  let userTickets: any = orderBy(
    groupBy(
      filter(data?.data, (item) => has(item, 'deleted_at') && item.deleted_at == null),
      (item) => item?.event?.id
    ),
    (eventTickets) => {
      const ticket = eventTickets[0];
      return ticket?.event?.start_date_time;
    },
    'desc'
  );

  if (!showPastEvents) {
    userTickets = filter(userTickets, (eventTickets) => {
      const ticket = eventTickets[0];

      if (ticket) {
        const eventEndTime = ticket.event.end_date_time;
        const isPassed = dayjs().utc().isAfter(dayjs.utc(eventEndTime));

        return !isPassed;
      }

      return false;
    });
  }

  if (isLoading) {
    return null;
  }

  return (
    <Container pt={1} px={'0 !important'} pb={'initial'} h="auto">
      <Flex my={3}>
        <Center>
          <H3 color={theme.colors.darkGrey}>Tickets</H3>
        </Center>
        <Spacer />
        <Flex align="center">
          <TextG10 fontWeight="500" mr={2}>
            Show Past Events
          </TextG10>
          <Switch
            size="md"
            sx={{
              '& .chakra-switch__track': {
                boxShadow: 'none !important', // Corrected !important
              },
              '& .chakra-switch__track:focus': {
                boxShadow: 'none !important', // Ensure it's removed on focus
              },
              '& .chakra-switch__track:focus-visible': {
                boxShadow: 'none !important', // Target focus-visible too
              },
            }}
            colorScheme="green"
            isChecked={showPastEvents}
            onChange={toggleShowPastEvents}
          />
        </Flex>
      </Flex>
      <Divider mb={3} />
      <AnimatePresence>
        {userTickets && userTickets.length > 0 ? (
          <Flex flexDir="column" gap="16px">
            {map(userTickets, (tickets) => {
              const ticket = tickets[0];
              return (
                <motion.div
                  key={ticket.id}
                  initial={{opacity: 0, y: 20}}
                  animate={{opacity: 1, y: 0}}
                  exit={{opacity: 0, y: -20}}
                  transition={{duration: 0.3}}>
                  <TicketCard key={ticket.uuid} ticket={ticket} numTickets={tickets.length} />
                </motion.div>
              );
            })}
          </Flex>
        ) : (
          <motion.div
            key="no-tickets"
            layout
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0, transition: {duration: 0.1}}}
            transition={{duration: 0.2, delay: 0.1}}>
            <AbsoluteCenter w="90%" textAlign="center" color={theme.colors.grey}>
              <Flex flexDir="column" gap={8}>
                <Icon
                  iconName="fi-rr-ticket"
                  style={{fontSize: '48px', color: theme.colors.lightGrey}}
                />
                <TextA18>You don't have any tickets yet!</TextA18>
                <TextA18>
                  Check out the Events you can get tickets for{' '}
                  <Box display="inline" color={theme.colors.blue}>
                    <Link
                      to={
                        '/standalone-search/event?passTypes=1%2C2&filterTitle=Ticketed%20Events&back=1&by=start_date_time&direction=asc'
                      }>
                      here
                    </Link>
                  </Box>
                </TextA18>
              </Flex>
            </AbsoluteCenter>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default MyTickets;
