import {Box, Divider, HStack, Stack, useTheme, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {H3, TextG12, TextG14} from '../../../components/typography';
import {Event, Offer} from '../../../types/acrticle';
import SwiperSlider from '../../../components/SwiperSlider';
import {filterUserTopics} from '../../../endpoints/api';
import EventCard from '../../../components/Articles/EventCard';
import PlaceCard from '../../../components/Articles/PlaceCard';
import {filter, map, uniqBy} from 'lodash';
import getPlaceAddress from '../../../utils/address';
import dayjs from 'dayjs';
import TopicsOfInterest from '../../TopicsOfInterest';
import useActiveRedemptions from '../../../hooks/useActiveRedemptions';
import OfferAccordion from '../../OfferAccordion';
import {useSearchParams} from 'react-router-dom';
import styled, {css} from 'styled-components';

const PulseAnimation = styled(Box)`
  transform: scale(1);
  filter: brightness(1);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      filter: brightness(1);
      -webkit-filter: brightness(1);
    }

    50% {
      transform: scale(1.05);
      filter: brightness(1.25);
      -webkit-filter: brightness(1.25);
    }
  }
`;

const EventAbout = ({event, eventOffers}: {event: Event; eventOffers: Offer[]}) => {
  const theme = useTheme();
  const [otherEvents, setOtherEvents] = React.useState<Event[]>([]);
  // TODO
  // const usersGoing = reverse(filter(event.event_attendance, (user) => user.pivot.status === 'Yes'));
  const usersGoing = [];
  const [searchParams] = useSearchParams();

  const {activeRedemptions} = useActiveRedemptions();

  const [offers, setOffers] = useState<Array<any>>([]);

  useEffect(() => {
    const activeOffers = map(
      filter(
        activeRedemptions,
        (redemption) =>
          redemption.offer?.event_id == event.id && !!redemption.offer.countdown_required
      ),
      (redemption) => redemption.offer
    );
    setOffers((prev) => uniqBy([...eventOffers, ...activeOffers], 'id'));
  }, [eventOffers]);

  // React.useEffect(() => {
  //   /**
  //    * TODO: change with 'More events from this host' route
  //    */
  //   const now = dayjs();
  //   filterUserTopics({
  //     id: event.id,
  //     type: 'events',
  //   }).then((res) =>
  //     setOtherEvents(
  //       res.data.data
  //         .filter((e) => now.isBefore(dayjs(e.end_date_time)))
  //         .sort((a, b) => +new Date(a.start_date_time) - +new Date(b.start_date_time))
  //     )
  //   );
  // }, [event.id]);

  return (
    <>
      <VStack mt={0} mb={4} px={4} spacing={4} alignItems={'start'}>
        {offers && offers.length > 0 && (
          <>
            {searchParams.get('highlight') == 'offers' ? (
              <>
                <TextG14 fontWeight="500">Offers:</TextG14>
                <PulseAnimation w="100%">
                  <OfferAccordion offers={offers} place={event.place} event={event}/>
                </PulseAnimation>
              </>
            ) : (
              <>
                <TextG14 fontWeight="500">Offers:</TextG14>
                <OfferAccordion offers={offers} place={event.place} event={event}/>
              </>
            )}
          </>
        )}

        <Box w="100%">
          <TextG14 fontWeight="500" mb={2}>
            Event details:
          </TextG14>
          <TextG12
            className="inner-content"
            dangerouslySetInnerHTML={{__html: event.description}}
            color={theme.colors.grey}>
            {}
          </TextG12>
        </Box>
        {event?.topics_of_interest && event?.topics_of_interest.length && (
          <TopicsOfInterest topicsOfInterest={event?.topics_of_interest} />
        )}
        <Divider />
        <HStack spacing={2}>
          <i className="fi fi-rr-marker"></i>
          <TextG12 fontWeight={'500'}>
            {event.place ? getPlaceAddress(event.place) : event.location}
          </TextG12>
        </HStack>
      </VStack>
      {event.place && (
        <Stack gap=".5rem" p="1rem" textAlign="left">
          <H3>Visit This Place</H3>
          <PlaceCard place={event.place} hideCheckin={event.allow_check_in} />
        </Stack>
      )}
      {!!otherEvents.length && !event.place && (
        <Stack gap=".5rem" p="1rem" textAlign="left">
          <H3>More events from this host</H3>
          <SwiperSlider items={otherEvents} render={(item) => <EventCard event={item} />} />
        </Stack>
      )}
    </>
  );
};

export default EventAbout;
