import React, {useCallback, useEffect, useState} from 'react';
import {useTheme, Flex, Link, VStack} from '@chakra-ui/react';
import useBookmark from '../../../hooks/useBookmark';
import {TextG10, TextG12, TextG14} from '../../../components/typography';
import Icon from '../../../components/Icon';
import getPlaceAddress, {getDirectionsLinkFromEvent} from '../../../utils/address';
import {Event} from '../../../types/article';
import {EventRSVPStatus, useSetAttendance} from '../../../hooks/useEvent';
import {ToastMessage} from '../../../components/toast';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Modal from '../../../components/Modals/Modal';
import {BlueButton} from '../../../components/button';
import {atcb_action} from 'add-to-calendar-button-react';
import {ATCBActionEventConfig} from 'add-to-calendar-button';
import {EventChatIsClosed} from '../../../components/Chat/EventChat';
import dayjs from 'dayjs';
import {sendCalendarEventNativeApp} from '../../../pushService';
import useAuth from '../../../hooks/useAuth';
import EventInviteModal from '../../../components/EventInviteModal';
import RequiresEarlyAccess from '../../../components/RequiresEarlyAccess';
import ExternalLink from '../../../components/ExternalLink';

const IconsHeader = ({event}: {event: Event}) => {
  const [isGoing, setIsGoing] = useState(event.attendance === 'Yes');
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(event, 'event');
  const setAttendance = useSetAttendance();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [promptOpen, setPromptOpen] = useState(false);
  const [eventInviteModalOpen, setEventInviteModalOpen] = useState(false);
  const isRSVPd = !!(
    event?.rsvp_status.status_value &&
    event?.rsvp_status.status_value != EventRSVPStatus.NOT_INTERESTED
  );
  const eventChatClosed = EventChatIsClosed(event);
  const isWalkaboutEvent = Boolean(event.is_walkabout_official);

  const {user} = useAuth();
  const {mutateAsync} = useSetAttendance();

  const addToCalendarConfig: ATCBActionEventConfig = {
    name: event.title,
    description: event.description,
    startDate: dayjs.utc(event.start_date_time).local().format('YYYY-MM-DD'),
    startTime: dayjs.utc(event.start_date_time).local().format('HH:mm'),
    endDate: dayjs.utc(event.end_date_time).local().format('YYYY-MM-DD'),
    endTime: dayjs.utc(event.end_date_time).local().format('HH:mm'),
    location: event.place
      ? getPlaceAddress(event.place)
      : event.location
        ? event.location
        : `${event.latitude}, ${event.longitude}`,
    options: ['Google', 'Outlook.com', 'Apple', 'iCal'],
    hideCheckmark: true,
    timeZone: 'America/Los_Angeles',
  };

  useEffect(() => {
    if (!!searchParams.get('bookmark') && !isBookmarked) {
      handleBookmarkClick(null);
    }
  }, [searchParams]);

  const handleBookmarkClick = (e) => {
    handleOnBookmarkClick(e);

    if (
      !isWalkaboutEvent &&
      !isBookmarked &&
      event.rsvp_status.status_value != EventRSVPStatus.INTERESTED
    ) {
      mutateAsync({
        eventId: event.id,
        prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.INTERESTED,
        showToast: false,
        showDispatchRSVPModalPopupActions: true,
      });
    }
  };

  const handleInviteClicked = () => {
    const eventLink =
      location.origin + `/event/${event.id}/preview?invite-code=${user?.referralCode}`;
    navigator.clipboard.writeText(eventLink);
    ToastMessage({
      status: 'success',
      text: 'Invite link copied to your clipboard',
    });
  };

  const handleChatClicked = () => {
    if (eventChatClosed) {
      ToastMessage({
        status: 'error',
        text: 'This event has ended and the chat is closed.',
      });
    } else if (!isRSVPd) {
      setPromptOpen(true);
    } else navigate(`/event/${event.id}/chat`);
  };

  const handleShareClicked = useCallback(() => {
    navigator.clipboard.writeText(location.href);
    ToastMessage({
      status: 'success',
      showStatusIcon: true,
      text: 'Link copied. Paste it anywhere and invite others to this event!',
    });
  }, []);

  const handleAppleClicked = () => {
    sendCalendarEventNativeApp({
      name: event.title,
      description: event.description,
      startDate: dayjs.utc(event.start_date_time).local().format('YYYY-MM-DDTHH:mm:ss'),
      //startTime: dayjs.utc(event.start_date_time).tz('America/Los_Angeles').format('hh:mm'),
      endDate: dayjs.utc(event.end_date_time).local().format('YYYY-MM-DDTHH:mm:ss'),
      //endTime: dayjs.utc(event.end_date_time).tz('America/Los_Angeles').format('hh:mm'),
      location: event.place
        ? getPlaceAddress(event.place)
        : event.location
          ? event.location
          : `${event.latitude}, ${event.longitude}`,
    });
  };

  const handleCalendarClicked = () => {
    atcb_action(addToCalendarConfig);

    setTimeout(() => {
      const appleBtn = document
        .getElementById('atcb-btn-custom-modal-host')
        ?.shadowRoot?.getElementById('atcb-btn-custom-apple');
      appleBtn?.addEventListener('click', handleAppleClicked);
    }, 300);
  };

  const handleGoingClicked = useCallback(async () => {
    if (setAttendance) {
      const res = await setAttendance.mutateAsync({
        eventId: event.id,
        prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.GOING,
      });
    }
  }, [setAttendance]);

  useEffect(() => {
    if (
      !!searchParams.get('attendance') &&
      !!!(event.rsvp_status.status_value == EventRSVPStatus.GOING)
    ) {
      handleGoingClicked();
    }
  }, [searchParams]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  return (
    <Flex flexWrap="wrap" mt={6} w="100%" justify="space-evenly" gap=".5rem" fontWeight={500}>
      {event.place && (
        <ExternalLink href={getDirectionsLinkFromEvent(event)} isExternal>
          <Flex direction="column" align="center">
            <Icon iconName="fi-rr-map-marker" style={iconStyle} />
            <TextG12 color={theme.colors.lightBlue}>Directions</TextG12>
          </Flex>
        </ExternalLink>
      )}

      {!eventChatClosed && (
        <RequiresEarlyAccess>
          <Flex
            direction="column"
            align="center"
            onClick={() => navigate(`/create-meetup?eventId=${event.id}`)}>
            <Icon iconName="fi-rr-users-alt" style={iconStyle} />
            <TextG10 mt="2px" color={theme.colors.lightBlue}>
              Create a Meet-Up
            </TextG10>
          </Flex>
        </RequiresEarlyAccess>
      )}
      <Flex direction="column" align="center" onClick={handleInviteClicked}>
        <Icon iconName="fi-rr-user-add" style={iconStyle} />
        <TextG10 mt="2px" color={theme.colors.lightBlue}>
          Invite Friends
        </TextG10>
        {/* <EventInviteModal
          event={event}
          show={eventInviteModalOpen}
          setShow={setEventInviteModalOpen}
        /> */}
      </Flex>

      {/* <Flex id="share-tooltip" direction="column" align="center" onClick={handleShareClicked}>
        <Icon iconName="fi-rr-share" style={iconStyle} />
        <TextG12 color={theme.colors.lightBlue}>Share</TextG12>
      </Flex> */}

      <>
        <Flex
          color={isRSVPd && !eventChatClosed ? theme.colors.lightBlue : theme.colors.grey}
          opacity={isRSVPd && !eventChatClosed ? 1 : 0.25}
          direction="column"
          align="center"
          onClick={handleChatClicked}>
          <Icon iconName="fi-rr-comment" style={{fontSize: '1.5rem'}} />
          <TextG12>Event Chat</TextG12>
        </Flex>

        <Modal
          isCentered
          parentCallback={() => setPromptOpen(false)}
          header={
            <TextG14 textAlign="center" fontWeight="500">
              Unlock Event Chat
            </TextG14>
          }
          children={
            <VStack textAlign="center" gap={4} mb={2}>
              <TextG12>
                {isWalkaboutEvent
                  ? `Click 'Interested?' and RSVP to join this event's chat.`
                  : `Click "I'm Interested" and show your interest in this event to join the event's chat.`}
              </TextG12>
              <BlueButton w="100px" onClick={() => setPromptOpen(false)}>
                Ok
              </BlueButton>
            </VStack>
          }
          show={promptOpen}
        />
      </>
      <Flex direction="column" align="center" onClick={handleCalendarClicked}>
        <Icon iconName="fi-rr-calendar" style={iconStyle} />
        <TextG12 color={theme.colors.lightBlue}>Add to Cal</TextG12>
      </Flex>
      <Flex minW="60px" direction="column" align="center" onClick={handleBookmarkClick}>
        {isBookmarked ? (
          <Icon iconName="fi-sr-bookmark" style={iconStyle} />
        ) : (
          <Icon iconName="fi-rr-bookmark" style={iconStyle} />
        )}
        <TextG12 color={theme.colors.lightBlue}>Bookmark</TextG12>
      </Flex>
    </Flex>
  );
};

export default IconsHeader;
