import React, {useEffect, useState} from 'react';
import {HStack, useTheme, VStack, Box, Flex} from '@chakra-ui/react';
import {TextG10, TextG12, TextG14} from '../../typography';
import {generatePath, Link, useNavigate, useParams} from 'react-router-dom';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import NonPremiumCard from '../../Card/NonPremiumCard';
import Icon from '../../Icon';
import {getCalenderDate} from '../../../utils/date';
import {BlueButton} from '../../button';
import {ROUTES} from '../../../constants/routes';
import {Event} from '../../../types/article';
import JoinMeetUpModal from '../../JoinMeetUpModal';
import {OPTIONS} from '../../../constants/meetupOptions';
import {EventRSVPStatus, useFetchEventRSVPStats, useSetAttendance} from '../../../hooks/useEvent';
import RSVPSelect from '../../../pages/Event/RSVP/RSVPSelect';
import useCountdown from '../../../hooks/useCountdown';
import useAuth from '../../../hooks/useAuth';

//   leftTopItem={<ArticleInfo article={item} />}
//   rightTopItem={<BookmarkElement article={item} />}

// const meet_up = {
//   id: 1,
//   name: 'Test Meet-up!',
//   is_bookmarked: false,
//   start_date_time: dayjs().add(24, 'hour'),
//   place_name: 'Concourse Bowling',
// }

const MeetUpCard = ({
  'meet-up': event,
  leftTopItem,
  rightTopItem,
  modal,
  isSlide,
  onClick,
}: {
  'meet-up'?: Event;
  leftTopItem?: any;
  rightTopItem?: any;
  modal?: boolean;
  isSlide?: boolean;
  onClick?: (e) => void;
}) => {
  if (!event) return null;
  const theme = useTheme();
  const navigate = useNavigate();
  const {pathname} = useParams();
  const [ref, {wasEverVisible, isVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [isSelectOpen, setSelectIsOpen] = useState(false);
  const {mutateAsync} = useSetAttendance();
  const {endPassed} = useCountdown(event);
  const {user} = useAuth();

  const {data} = useFetchEventRSVPStats(event.id, !onClick && isVisible && !!user);
  const rsvp_stats = data?.data.rsvp_stats;
  const usersGoing = rsvp_stats?.GOING;

  const statusValue = event.rsvp_status?.status_value;
  const noStatus = !statusValue || statusValue == 0;
  const rsvpText = statusValue
    ? endPassed
      ? OPTIONS[statusValue].disabledText
      : OPTIONS[statusValue].text
    : '';

  const isHost = user?.id == (event?.user?.id || event.user_id);

  const renderIcon =
    statusValue && OPTIONS[statusValue] && OPTIONS[statusValue].renderSelectedIcon
      ? OPTIONS[statusValue].renderSelectedIcon
      : null;
  const renderIconFunc =
    statusValue && OPTIONS[statusValue] && OPTIONS[statusValue].color && renderIcon
      ? renderIcon({
          style: {
            fontSize: '18px',
            height: '18px',
            width: '18px',
            color:
              theme.colors[OPTIONS[statusValue].color || 'white'] || OPTIONS[statusValue].color,
            fill: theme.colors[OPTIONS[statusValue].color || 'blue'] || OPTIONS[statusValue].color,
          },
        })
      : null;

  const onClickRSVPButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (noStatus) {
      setShowJoinModal(true);
    } else {
      setSelectIsOpen(true);
    }
  };

  const onClickRSVPModal = (status: EventRSVPStatus) => {
    setSelectIsOpen(false);
    mutateAsync({
      eventId: event?.id,
      prevStatus: event?.rsvp_status?.status_value || EventRSVPStatus.NO_STATUS,
      status,
    });
  };

  useEffect(() => {
    if (wasEverVisible && event && event?.id?.toString()) {
      addVisibleArticle({id: event?.id?.toString(), type: 'event'});
    }
  }, [wasEverVisible]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: '#374957',
      fontSize: '14px',
    }),
    [theme]
  );

  return (
    <>
      <NonPremiumCard
        onClick={(e) => {
          if (onClick) {
            onClick(e);
            return;
          }
          if (
            e?.target.tagName === 'BUTTON' ||
            e?.target.parentNode.tagName === 'BUTTON' ||
            e?.target.parentNode.parentNode.tagName === 'BUTTON'
          )
            return;

          const route = modal
            ? {pathname, search: `?id=${event.id.toString()}&articleType=meet-up`}
            : generatePath(ROUTES.MEET_UP, {
                meetupId: event.id.toString(),
              });
          storeScrollPositionInSession();
          navigate(route);
        }}
        isSlide={isSlide}
        height={'auto'}
        coverImage={event.place?.image_url}
        leftTopItem={leftTopItem}
        rightTopItem={rightTopItem}
        childPaddingX={4}
        article={event}
        minHeight="100%"
        maxHeight="100%">
        <VStack ref={ref} align="start" pr={8} gap="5px">
          <TextG10 color={theme.colors.grey}>
            {getCalenderDate(event?.start_date_time)?.toUpperCase()}
          </TextG10>
          <TextG14
            mt="0px !important"
            isTruncated="&hellip;"
            noOfLines={null}
            fontWeight={500}
            color={theme.colors.lightBlack}>
            {event?.title}
          </TextG14>
          {usersGoing > 0 && (
            <TextG10 mt="5px !important" color={theme.colors.grey}>
              {`${usersGoing} Going`}
            </TextG10>
          )}

          {event?.place && (
            <HStack maxW="110px">
              <Icon iconName="fi-rr-marker" style={iconStyle} />
              <TextG10 ml="4px !important" color={theme.colors.grey}>
                {event.place.name}
              </TextG10>
            </HStack>
          )}
          {!isHost && (
            <Box pos="absolute" bottom="10px" right="5px">
              <BlueButton
                p={2}
                color={noStatus ? 'white' : 'black'}
                backgroundColor={noStatus ? theme.colors.blue : OPTIONS[statusValue].bgColor}
                onClick={onClickRSVPButton}>
                <Flex
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'.5rem'}>
                  {renderIconFunc}
                  <TextG12 fontWeight="500">{noStatus ? `✋ Count me in!` : rsvpText}</TextG12>
                </Flex>
              </BlueButton>
            </Box>
          )}
        </VStack>
      </NonPremiumCard>
      <JoinMeetUpModal event={event} show={showJoinModal} setShow={setShowJoinModal} />
      <RSVPSelect
        rsvpOptions={OPTIONS}
        onOptionClick={onClickRSVPModal}
        onModalClose={() => setSelectIsOpen(false)}
        openModal={isSelectOpen}
        event={event}
      />
    </>
  );
};

export default MeetUpCard;
