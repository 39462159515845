import React from 'react';
import Icon from '../../../Icon';

export enum LifestyleChatMenuOption {
  GUIDELINES = 'Community Guidelines',
}

export const OPTIONS = [
  {
    option: LifestyleChatMenuOption.GUIDELINES,
    renderIcon: (props: any) => <Icon iconName={'fi-rr-pennant'} {...props} />,
    color: 'red',
    text: LifestyleChatMenuOption.GUIDELINES,
  },
];
