import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const ReferralRedirect = () => {
  const [searchParams, _] = useSearchParams();
  const {user} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.has('invite-code') || user) {
      navigate('/soft-check');
    } else {
      const inviteCode = searchParams.get('invite-code')?.toString();
      navigate('/register', {state: {code: inviteCode}});
    }
  }, [searchParams]);

  return <></>;
};

export default ReferralRedirect;
