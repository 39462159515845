import React from 'react';
import Helmet from 'react-helmet';
import {Partner} from '../../types/article';

const PartnerHelmet = ({partner}: {partner: Partner}) => {
  return (
    <Helmet>
      <title>{partner.name}</title>
      <meta name="description" content={partner.description_plain_text} />
      <meta name="twitter:title" content={partner.name} />
      <meta name="twitter:description" content={partner.description_plain_text} />
      <meta name="twitter:card" content={partner.name} />
      <meta name="twitter:image" content={partner.image_url} />
      <meta property="og:title" content={partner.name} />
      <meta property="og:description" content={partner.description_plain_text} />
      <meta property="og:image" content={partner.image_url} />
      <meta property="og:image:secure_url" content={partner.image_url} />
      <meta property="og:image:url" content={partner.image_url} />
    </Helmet>
  );
};

export default PartnerHelmet;
