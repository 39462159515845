import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
  contentTypeFromUrl,
  getFilterKeysForContentType,
  isValidSortOption,
  parseQueryParam,
} from '../utils/GlobalSearch/helpers';
import useGetUserLocation from './useUserLocation';
import {identity, pickBy} from 'lodash';
import {
  GlobalFilters,
  GlobalSearchParams,
  GlobalSort,
  NamedFilter,
  Sort,
} from '../utils/GlobalSearch/types';
import {CONTENT_TYPE} from '../utils/GlobalSearch/enums';
import {useSortingOptions} from './useSortingOptions';
import {FILTERS} from '../components/GlobalSearch/FilterConfig';
import useAuth from './useAuth';

export const initialParams: GlobalSearchParams = {
  term: '',
  type: CONTENT_TYPE.ALL,
  sort: {
    by: 'updated_at',
    direction: 'asc',
  },
};

const contentSpecificInitialParams = (type: CONTENT_TYPE) => {
  return {
    ...initialParams,
    type,
    ...(type === CONTENT_TYPE.EVENT && {
      sort: {
        by: 'start_date_time',
        direction: 'asc',
      },
      // filters: {
      //   walkaboutOfficial: '1',
      // },
    }),
    ...(type === CONTENT_TYPE.PLACE && {
      sort: {
        by: 'default',
        direction: 'asc',
      },
    }),
    ...(type === CONTENT_TYPE.OFFER && {
      sort: {
        by: 'ends_at',
        direction: 'asc',
      },
    }),
    ...(type === CONTENT_TYPE.POST && {
      sort: {
        by: 'released_at',
        direction: 'desc',
      },
    }),
    ...(type === CONTENT_TYPE.PARTNER && {
      sort: {
        by: 'created_at',
        direction: 'desc',
      },
    }),
    ...(type === CONTENT_TYPE.MEET_UP && {
      sort: {
        by: 'start_date_time',
        direction: 'asc',
      },
    }),
  };
};

const contentSpecificInitialFilters = (type: CONTENT_TYPE) => {
  return {
    ...(type === CONTENT_TYPE.EVENT &&
      {
        // event: {
        //   walkaboutOfficial: '1',
        // },
      }),
  };
};

const SORT_KEYS = ['by', 'direction'];

type GlobalSearchProps = {
  params: GlobalSearchParams;
  filters: GlobalFilters;
  namedFilter?: NamedFilter;
  searchTerm?: string;
  back?: boolean;
  setFilters: React.Dispatch<React.SetStateAction<GlobalFilters>>;
  setTypeSpecificFilter: (type: CONTENT_TYPE, key: string, value: any) => void;
  getTypeSpecificFilter: (type: CONTENT_TYPE, key: string) => any;
  resetTypeSpecificFilters: (type: CONTENT_TYPE) => void;
  setTypeSpecificSort: (type: CONTENT_TYPE, sort: Partial<Sort>) => void;
  getTypeSpecificSort: (type: CONTENT_TYPE) => Sort | undefined | null;
  getNumOfAppliedFilters: (type: string) => number;
  performSearch: (term: string) => void;
};

const GlobalSearchContext = React.createContext<GlobalSearchProps>({} as GlobalSearchProps);

export const GlobalSearchProvider = ({children}) => {
  const {user} = useAuth();
  const location = useLocation();
  const extractedType = contentTypeFromUrl(location);
  const navigate = useNavigate();
  const userLocation = useGetUserLocation(!!user);
  const [searchParams, setSearchParams] = useSearchParams();
  const sortOptionsData = useSortingOptions();
  const [type, setType] = useState<CONTENT_TYPE>(extractedType || CONTENT_TYPE.ALL);
  const [filters, setFilters] = useState<GlobalFilters>(contentSpecificInitialFilters(type));
  const [sort, setSort] = useState<GlobalSort>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [params, setParams] = useState(contentSpecificInitialParams(type));
  const [namedFilter, setNamedFilter] = useState<NamedFilter>();
  const [back, setBack] = useState(false);

  useEffect(() => {
    updateSortBasedOnQueryParams();
  }, [sortOptionsData]);

  useEffect(() => {
    const extractedType = contentTypeFromUrl(location);
    if (extractedType) {
      setType(extractedType);
    }
  }, []);

  useEffect(() => {
    if (!location.pathname.includes('search')) {
      clearStates();
      return;
    }

    const extractedType = contentTypeFromUrl(location);
    if (extractedType) {
      setType(extractedType);
      if (extractedType === type) {
        setFilters(contentSpecificInitialFilters(type));
        updateFiltersBasedOnQueryParams();
        updateSortBasedOnQueryParams();
      }
    }
    persistFiltersBetweenTabs(extractedType);
    resetSearchTerm();
  }, [location.pathname]);

  useEffect(() => {
    const extractedType = contentTypeFromUrl(location);
    if (extractedType) {
      setParams({
        ...contentSpecificInitialParams(type),
        ...(userLocation?.data && {
          lat: userLocation?.data.latitude,
          long: userLocation?.data.longitude,
        }),
      });
      setFilters(contentSpecificInitialFilters(type));
      updateFiltersBasedOnQueryParams();
      updateSortBasedOnQueryParams();
    }
  }, [type]);

  useEffect(() => {
    const extractedType = contentTypeFromUrl(location);
    if (extractedType) {
      setParams({
        ...params,
        ...(userLocation?.data && {
          lat: userLocation?.data.latitude,
          long: userLocation?.data.longitude,
        }),
      });
    }
  }, [userLocation.isLoading]);

  useEffect(() => {
    const extractedType = contentTypeFromUrl(location);
    const articleType = searchParams.get('articleType');
    const id = searchParams.get('id');
    if ((filters?.[type] || sort?.[type]) && extractedType && !(id && articleType)) {
      updateQueryParams();
      processNamedFilter();
      clearDependantFilters();
    }
  }, [filters, sort, searchTerm]);

  useEffect(() => {
    if (searchParams.get('filterTitle')) {
      processNamedFilter();
    }
    if (searchParams.get('back')) {
      setBack(true);
    }
    if (searchParams.get('term')) {
      setSearchTerm(searchParams.get('term') || '');
    }
  }, [searchParams]);

  const clearDependantFilters = () => {
    const contentTypeFiltersConfig = FILTERS[type];
    const adjustedFilters = {
      ...filters,
      [type]: Object.keys(filters[type] || {}).reduce((acc, key) => {
        if (
          typeof contentTypeFiltersConfig[key]?.condition === 'function' &&
          !contentTypeFiltersConfig[key]?.condition?.({
            filters: filters[type],
            user,
          })
        ) {
          return acc;
        }

        return {
          ...acc,
          [key]: filters[type]?.[key],
        };
      }, {}),
    };

    if (Object.keys(filters[type] || {}).length > Object.keys(adjustedFilters[type] || {}).length) {
      setFilters(adjustedFilters);
    }
  };

  const updateFiltersBasedOnQueryParams = () => {
    if (type) {
      const searchParamKeysValues = Array.from(searchParams).reduce((acc, [key]) => {
        const value = searchParams.get(key);
        return {
          ...acc,
          ...(getFilterKeysForContentType(type).includes(key) &&
            value && {[key]: parseQueryParam(key, value, type)}),
        };
      }, {});

      if (Object.keys(searchParamKeysValues).length === 0) {
        const cachedFilters = localStorage.getItem('filters');
        if (cachedFilters) {
          setFilters((prev) => {
            return {...prev, ...JSON.parse(cachedFilters)};
          });
          return;
        }
      }

      setFilters((prev) => {
        return {
          ...prev,
          [type]: searchParamKeysValues,
        };
      });
    }
  };

  const updateSortBasedOnQueryParams = () => {
    if (type) {
      const searchParamKeysValues = Array.from(searchParams).reduce(
        (acc, [key]) => ({
          ...acc,
          ...(SORT_KEYS.includes(key) && {
            [key]: parseQueryParam(key, searchParams.get(key), type),
          }),
        }),
        {}
      );

      const sortConfig = Object.keys(searchParamKeysValues).length
        ? searchParamKeysValues
        : getTypeSpecificDefaultSort(type);
      if (
        sortOptionsData &&
        isValidSortOption({
          options: sortOptionsData,
          type,
          by: sortConfig?.['by'],
          direction: sortConfig?.['direction'],
        })
      ) {
        setSort({
          ...sort,
          [type]: Object.keys(searchParamKeysValues).length
            ? searchParamKeysValues
            : getTypeSpecificDefaultSort(type),
        });
      }
    }
  };

  const updateQueryParams = () => {
    if (type) {
      const contentTypeFilters = pickBy(filters?.[type], identity);
      const contentTypeSort = sort?.[type];

      const searchParams = new URLSearchParams({
        ...(contentTypeFilters ? contentTypeFilters : {}),
        ...(contentTypeSort ? contentTypeSort : {}),
        ...(searchTerm ? {term: searchTerm} : {}),
        ...(namedFilter?.title ? {filterTitle: namedFilter.title} : {}),
        ...(back ? {back: '1'} : {}),
      });

      // Use navigate with 'replace: true' to replace the URL without adding to history
      navigate(`?${searchParams.toString()}`, {replace: true});

      setParams({
        ...params,
        term: searchTerm || '',
        filters: contentTypeFilters ? contentTypeFilters : {},
        sort: contentTypeSort ? contentTypeSort : contentSpecificInitialParams(type).sort,
      });
    }
  };

  const getNumOfAppliedFilters = (type: string) => {
    const contentSpecificFilters = filters?.[type] || [];
    return Object.values(contentSpecificFilters).reduce((num: number, filter) => {
      if (Array.isArray(filter)) {
        return num + filter.filter(Boolean).length;
      }
      return filter ? ++num : num;
    }, 0);
  };

  const setTypeSpecificFilter = (type: CONTENT_TYPE, key: string, value: any) => {
    setFilters({
      ...filters,
      [type]: {
        ...filters?.[type],
        [key]: value,
      },
    });
  };

  const getTypeSpecificFilter = (type: CONTENT_TYPE, key: string) => filters?.[type]?.[key];

  const resetTypeSpecificFilters = (type: CONTENT_TYPE) => {
    setFilters({
      ...filters,
      [type]: {},
    });
  };

  const setTypeSpecificSort = (type: CONTENT_TYPE, newSort: Partial<Sort>) => {
    setSort({
      ...sort,
      [type]: {
        ...sort?.[type],
        ...(newSort.by && {by: newSort.by}),
        ...(newSort.direction && {direction: newSort.direction}),
      },
    });
  };

  const getTypeSpecificSort = (type: CONTENT_TYPE) =>
    sort?.[type] || getTypeSpecificDefaultSort(type);

  const getTypeSpecificDefaultSort = (type: CONTENT_TYPE) =>
    contentSpecificInitialParams(type).sort;

  const processNamedFilter = () => {
    const filterTitle = searchParams.get('filterTitle');
    setNamedFilter(
      filterTitle
        ? {
            title: filterTitle,
            icon: searchParams.get('filterIcon'),
            color: searchParams.get('filterColor'),
          }
        : undefined
    );
  };

  const performSearch = (term: string) => {
    setSearchTerm(term);
    if (!location.pathname.includes('search')) {
      navigate(`/search/${type}`, {replace: true});
    }
  };

  const resetSearchTerm = () => {
    if (!location.pathname.includes('search')) {
      setSearchTerm('');
    }
  };

  const persistFiltersBetweenTabs = (type: CONTENT_TYPE | null) => {
    if (!type) {
      localStorage.removeItem('filters');
      setFilters({});
      return;
    }

    localStorage.setItem('filters', JSON.stringify(filters));
  };

  const clearStates = () => {
    setFilters({});
    setSort({});
    setSearchTerm('');
    setNamedFilter(undefined);
  };

  const search = {
    params,
    filters,
    namedFilter,
    searchTerm,
    setFilters,
    setTypeSpecificFilter,
    getTypeSpecificFilter,
    resetTypeSpecificFilters,
    setTypeSpecificSort,
    getTypeSpecificSort,
    getNumOfAppliedFilters,
    performSearch,
  };

  return <GlobalSearchContext.Provider value={search}>{children}</GlobalSearchContext.Provider>;
};

export default function useGlobalSearch() {
  return React.useContext(GlobalSearchContext);
}
