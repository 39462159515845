import {useTheme, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import styled, {css} from 'styled-components';
import Icon from '../Icon';
import {TextG10} from '../typography';

const StyledTooltip = styled(ReactTooltip)`
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  z-index: 1600;
  opacity: ${(props) => (props.isClosing ? '0' : '1')};

  border-radius: 7px;
  background: white;
  color: black;
  border: 1px solid grey;
  pointer-events: auto;
  width: 80%;
  max-width: 80%;
  padding: 14px 16px;

  .arrow-style {
    margin-left: -8px;
    z-index: -1;
    background-color: white !important;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    bottom: -9px !important;
  }
`;

const Tooltip = ({
  anchorId,
  iconName,
  title,
  description,
}: {
  anchorId: string;
  iconName?: string;
  title?: string;
  description?: string;
}) => {
  const theme = useTheme();
  const [isClosing, setIsClosing] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.grey,
      fontSize: '14px',
      height: '14px',
    }),
    [theme]
  );

  return (
    <>
      <StyledTooltip
        classNameArrow="arrow-style"
        anchorId={anchorId}
        isOpen={isOpen}
        isClosing={isClosing}>
        <VStack>
          {title && (
            <TextG10 color={theme.colors.darkGrey} fontWeight="500">
              {title}
            </TextG10>
          )}
          {description && <TextG10 color={theme.colors.grey}>{description}</TextG10>}
        </VStack>
      </StyledTooltip>
      <Icon
        id={anchorId}
        onClick={() => {
          setIsOpen(true);
          setIsClosing(false);
          setTimeout(() => setIsClosing(true), 7700);
          setTimeout(() => setIsOpen(false), 8000);
        }}
        iconName={iconName || 'fi-sr-info'}
        style={iconStyle}
      />
    </>
  );
};

export default Tooltip;
