import {
  Center,
  Container,
  Flex,
  Spinner,
  useDisclosure,
  useTheme,
  Box,
  Divider,
} from '@chakra-ui/react';
import React, {useEffect, useState, useRef, useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import Articles from '../../components/Articles';
import {has} from 'lodash';
import {Navigate, useSearchParams} from 'react-router-dom';
import useGetUserLocation from '../../hooks/useUserLocation';
import ArticleModal from '../../components/Articles/Modal';
import HomePage from '../../components/Articles/HomePage';
import PostCard from '../../components/Articles/PostCard';
import EventCard from '../../components/Articles/EventCard';
import PlaceCard from '../../components/Articles/PlaceCard';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import {TextG14} from '../../components/typography';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import ArticleSearchNavigation from '../../components/GlobalSearch/ArticleSearchNavigation';
import {AnimateContent} from '../../routes/AuthorizedRoutes';
import Sort from '../../components/GlobalSearch/Sort';
import {useInfiniteGlobalSearch} from '../../hooks/useFetchGlobalSearch';
import {useSelector} from 'react-redux';
import Filters from '../../components/GlobalSearch/Filters';
import {CONTENT_TYPE} from '../../utils/GlobalSearch/enums';
import PartnerCard from '../../components/Articles/PartnerCard';
import MeetUpButton from '../../components/MeetUpButton';
import {BottomModalSheetRefProps} from '../../components/Modals/BottomModalSheet';
import {useInfiniteMeetups} from '../../hooks/useMeetUps';
import MeetUpCard from '../../components/Articles/MeetUpCard';
import {
  getFilterKeysForContentType,
  isStandaloneSearchPage,
} from '../../utils/GlobalSearch/helpers';
import useAuth from '../../hooks/useAuth';

// All;
// Places;
// Events;
// Meet - ups;
// Partners;
// Posts;

type Config = {
  [key in CONTENT_TYPE]: {
    tabName: string;
    tabElement: JSX.Element;
    renderArticleType: boolean;
    hook: typeof useInfiniteGlobalSearch;
    defaultOrderBy?: string;
    defaultSortDirection?: string;
  };
};

const config = {
  all: {
    tabName: 'All',
    tabElement: <HomePage modal />,
    renderArticleType: false,
    hook: useInfiniteGlobalSearch,
  },
  place: {
    tabName: 'Places',
    tabElement: <PlaceCard modal />,
    renderArticleType: false,
    hook: useInfiniteGlobalSearch,
  },
  event: {
    tabName: 'Events',
    tabElement: <EventCard modal />,
    renderArticleType: false,
    hook: useInfiniteGlobalSearch,
  },
  'meet-up': {
    tabName: 'Meet-Ups',
    tabElement: <MeetUpCard modal />,
    renderArticleType: false,
    hook: useInfiniteGlobalSearch, // TODO: Add meet-ups to back-end search
  },
  partner: {
    tabName: 'Partners',
    tabElement: <PartnerCard modal />,
    renderArticleType: false,
    hook: useInfiniteGlobalSearch,
  },
  offer: {
    tabName: 'Offers',
    tabElement: <OfferCard hideActive={false} />,
    renderArticleType: false,
    hook: useInfiniteGlobalSearch,
  },
  post: {
    tabName: 'Posts',
    tabElement: <PostCard modal />,
    renderArticleType: false,
    hook: useInfiniteGlobalSearch,
  },
};



const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {type} = useParams();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const theme = useTheme();
  const {pathname} = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const {params, getTypeSpecificSort} = useGlobalSearch();
  const firstVisit = useRef(false);
  const userLocation = useGetUserLocation();
  const communityId = useSelector((state: any) => state.community.community)?.id;
  const {user} = useAuth();

  const sheetRef = useRef<BottomModalSheetRefProps>()!;

  const isStandaloneSearch = isStandaloneSearchPage(location);

  // const config = {
  //   ...baseConfig,
  //   ...(user?.hasEarlyAccess && {
  //     'meet-up': {
  //       tabName: 'Meet-Ups',
  //       tabElement: <MeetUpCard modal />,
  //       renderArticleType: false,
  //       hook: useInfiniteMeetups, // TODO: Add meet-ups to back-end search
  //     },
  //   }),
  // };

  const loadType = searchParams.has('articleType')
    ? searchParams.get('articleType')?.toLowerCase()
    : type;

  useEffect(() => {
    onOpen();
  }, [searchParams.get('id')]);

  useEffect(() => {
    if (!pathname.includes('search')) return;
    if (!firstVisit.current) {
      firstVisit.current = true;
      return;
    }
    setIsLoading(true);
  }, [type]);

  useEffect(() => {
    // setIsLoading(true);
    setIsLoading(false);
  }, [params]);

  const sortAndFilter = useMemo(() => {
    if (!type) return;

    const hasFilters = getFilterKeysForContentType(type as CONTENT_TYPE).length > 0;
    const hasSort = !!getTypeSpecificSort(type as CONTENT_TYPE);

    return (
      <Flex mb={5} flexDirection={'row'} flexWrap={'wrap'} maxW={'100%'}>
        {hasFilters ? (
          <Flex
            mx={-4}
            px={4}
            py={'6px'}
            mt={isStandaloneSearch ? '14px' : 0}
            flex={'1 0 50%'}
            gap={'5px'}
            alignItems={'center'}
            borderBottom={`1px solid ${theme.colors.lighterGrey}`}
            borderTop={isStandaloneSearch ? `1px solid ${theme.colors.lighterGrey}` : 'none'}
            bgColor={theme.colors.veryLightBlue}
            overflow={'auto'}>
            <Filters type={type as CONTENT_TYPE} sheetRef={sheetRef} />
          </Flex>
        ) : null}
        {hasSort ? (
          <Flex
            mx={-4}
            px={6}
            py={4}
            flex={'1 1 100%'}
            gap={'5px'}
            alignItems={'center'}
            borderBottom={`1px solid ${theme.colors.lighterGrey}`}
            bgColor={theme.colors.veryLightBlue}
            justifyContent={'space-between'}>
            <Sort type={type as CONTENT_TYPE} sheetRef={sheetRef} />
          </Flex>
        ) : null}
      </Flex>
    );
  }, [type, userLocation.isLoading]);

  if (!type || !(type in config)) {
    return <Navigate to="/home" />;
  }

  // if (!('sort' in params) || !('orderBy' in params)) return <></>;

  return (
    <AnimateContent>
      <Container pt={'120px'} pb={10} h="auto">
        <Box pos="sticky" zIndex="1300" top="120px" background="white" mr={-3} ml={-3} px={3}>
          <ArticleSearchNavigation articleTabs={config} />
          {pathname.includes('search') && sortAndFilter}
        </Box>
        {searchParams.has('id') && (
          <ArticleModal
            open={isOpen}
            close={() => {
              searchParams.delete('id');
              searchParams.delete('articleType');
              setSearchParams(searchParams);
              onClose();
            }}
            type={loadType ?? 'all'}
          />
        )}
        <>
          {isLoading ? (
            <Center pt={16}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor={theme.colors.lightGrey}
                color={theme.colors.lightBlue}
                size="lg"
              />
            </Center>
          ) : (
            <>
              {type && type == 'meet-up' && user?.hasActiveMembership && user?.hasEarlyAccess && (
                <MeetUpButton />
              )}
              <Articles
                type={type === 'all' ? 'article' : type}
                hook={config[type].hook}
                endpointParams={{
                  communityId,
                }}
                noItems={
                  <Box px={12}>
                    <TextG14 textAlign={'center'} color={theme.colors.grey}>
                      No exact matches found for that search criteria. Try a different search term
                      or changing your filters.
                    </TextG14>
                  </Box>
                }
                {...(has(config[type], 'renderArticleType')
                  ? {renderArticleType: config[type]?.renderArticleType}
                  : {})}>
                {config[type].tabElement}
              </Articles>
            </>
          )}
        </>
      </Container>
    </AnimateContent>
  );
};

export default Search;
