import React, {useEffect, useState} from 'react';
import {
  EditablePreview,
  IconButton,
  Input,
  useEditableControls,
  ButtonGroup,
  Editable,
  EditableInput,
  Flex,
  Box,
} from '@chakra-ui/react';
import {ToastMessage} from '../../../components/toast';
import {updateUser} from '../../../endpoints/api';
import useAuth from '../../../hooks/useAuth';

const UserHandle = ({text}) => {
  function EditableControls({onSubmitClick, onCancelClick, onEditClick, setIsEdit}) {
    const {isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps} =
      useEditableControls();

    useEffect(() => {
      setIsEdit(isEditing);
    }, [isEditing]);

    return isEditing ? (
      <Flex justifyContent="center" ms="2px">
        <ButtonGroup justifyContent="center" size="sm">
          <IconButton
            height="0"
            minWidth="0"
            size="lg"
            aria-label="Save"
            bg="none"
            icon={<i className="fi fi-rr-check"></i>}
            {...getSubmitButtonProps({onClick: onSubmitClick})}
          />
          <IconButton
            height="0"
            minWidth="0"
            size="lg"
            aria-label="Cancel"
            bg="none"
            icon={<i className="fi fi-sr-cross-small"></i>}
            {...getCancelButtonProps({onClick: onCancelClick})}
          />
        </ButtonGroup>
      </Flex>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          height="0"
          minWidth="0"
          aria-label="Edit"
          bg="none"
          icon={<i className="fi fi-rr-pencil"></i>}
          {...getEditButtonProps({onClick: onEditClick})}
        />
      </Flex>
    );
  }

  const [userHandle, setUserHandle] = useState(text);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const {user, reFetchUserData} = useAuth();

  useEffect(() => {
    setUserHandle(user?.handle);
  }, [user?.handle]);

  const handleSubmitClick = async () => {
    setIsEditingMode(false);
    await updateUser({handle: userHandle}).catch((e) => {
      ToastMessage({
        status: 'error',
        text: e.message,
        duration: 5000,
      });
    });

    await reFetchUserData();
    setUserHandle(user?.handle);
  };

  const handleCancelClick = () => {
    setUserHandle(text);
    setIsEditingMode(false);
  };

  const handleEditClick = () => {
    setIsEditingMode(true);
  };

  const onHandleChange = (event) => {
    setUserHandle(event.target.value);
  };

  return (
    <Editable
      textAlign="center"
      defaultValue={userHandle}
      value={userHandle}
      fontSize="14px"
      fontFamily="Gordita"
      fontWeight="bold"
      lineHeight="20px"
      isPreviewFocusable={false}>
      <Flex justifyContent="center" alignItems="center" gap="5px">
        <Box>
          {isEditingMode ? (
            <EditablePreview />
          ) : (
            <span>
              "<EditablePreview defaultValue={user?.handle} />"
            </span>
          )}
          <Input
            as={EditableInput}
            onChange={onHandleChange}
            sx={{
              '&:focus-visible': {
                zIndex: 1,
                border: 'none',
                borderRadius: 'unset',
                borderBottom: '1px solid #e5e5e5a3',
                boxShadow: 'none',
              },
            }}
          />
        </Box>

        <EditableControls
          setIsEdit={setIsEditingMode}
          onSubmitClick={handleSubmitClick}
          onCancelClick={handleCancelClick}
          onEditClick={handleEditClick}
        />
      </Flex>
    </Editable>
  );
};

export default UserHandle;
