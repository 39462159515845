import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {browserDetected, deviceDetected, osVersion} from '../utils/deviceCheck';
import he from 'he';

/**
 * Creates new instance of axios with baseUrl pre-set.
 * Uses interceptors to attach headers such as user token and active ghg inventory
 */
const instance: AxiosInstance = axios.create({baseURL: process.env.API_URL});

/**
 * Derive an error message from a 400 response
 */
const errorMessage400 = (response: AxiosResponse): string => {
  const {data} = response;
  if (!data) {
    return '400 Bad Request';
  }

  if (data?.message) {
    return data.message;
  }

  // This is assuming a structured response from some
  // specific backend route.
  if (data.errors) {
    const error = data.errors[0];
    return error.message || (error.messages && error.messages[0]);
  }

  return String(data.message || data.msg) || 'Bad Request';
};

/**
 * Derive an error message from a 5XX response
 *
 * In production this usually says Internal Server Error
 * In development it extracts the backend callstack from the response.
 */
function errorMessage5XX(error: AxiosError): string {
  if (process.env.NODE_ENV === 'production') {
    return error.message;
  }

  const msg: any =
    error.message || error?.response?.data || ''.replace(/<br>/gm, '\n').replace(/<[^>]*>?/gm, '');
  const doc = new DOMParser().parseFromString(msg, 'text/html');
  return doc.documentElement.textContent || error.message;
}

/**
 * Create an interceptor for requests that attaches user
 * token in every request sent to the API.
 */
instance.interceptors.request.use(
  (config) => {
    const userDeleted = localStorage.getItem('user_deleted_at');
    if (!(typeof userDeleted === 'object') && userDeleted !== 'null') {
      localStorage.removeItem('user_token');
    }
    const token = localStorage.getItem('user_token');
    const detectedBrowser = browserDetected();
    const detectedDevice = deviceDetected();
    const detectedOSVersion = osVersion(detectedDevice);
    const appVersion = APP_VERSION;
    const visibleArticles = JSON.parse(sessionStorage.getItem('visible_articles') || '[]');
    const userEvents = JSON.parse(sessionStorage.getItem('user_events') || '[]');
    const path = window.location.pathname;
    const params = window.location.search;

    const searchParams = new URLSearchParams(window.location.search);
    const qrCodeId = searchParams.get('qr_code_id');

    const regex = /^(\/tabs)?\/(place|events|event|post)?\/?(\d+$)?/;
    let match = path.match(regex);
    let articleId = {};

    if (match) {
      const search = new URLSearchParams(window.location.search);
      const type = match[2] && match[2].replace(/s$/, '');
      const id = match[3] || search.get('id');

      if (type && id) {
        articleId[`${type}Id`] = Number(id);
      }
    }

    const statisticData = {
      detectedBrowser,
      detectedDevice,
      detectedOSVersion,
      appVersion,
      visibleArticles,
      userEvents,
      path,
      params,
      ...articleId,
      ...(qrCodeId ? {qrCodeId} : {}), // Add qrCodeId only if it's not null
    };

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (config.method === 'get' || config.method === 'post') {
      config.headers = {
        ...config.headers,
        statisticData: JSON.stringify(statisticData),
      };
    }

    sessionStorage.setItem('visible_articles', '[]');
    sessionStorage.setItem('user_events', '[]');

    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Response interceptor that handles Bad Request, Unauthorized and Internal Server Error
 * responses from the API.
 */
instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (!error.response) {
      // if it is NetworkError then it must throw
      // otherwise the promise will resolve with nothing
      throw error;
    }
    const {status} = error.response;

    if (status >= 500) {
      error.message = `${error.response.status} Internal Server Error:\n ${errorMessage5XX(error)}`;
      throw error;
    }

    /**
     * Bad request.
     */
    if (status >= 400) {
      // Unauthenticated
      if (status === 401) {
        // location.reload();
        const userDeleted = localStorage.getItem('user_deleted_at');
        if (!(typeof userDeleted === 'object') && userDeleted !== 'null') {
          location.href = '/';
          localStorage.removeItem('user_deleted_at');
        }
      }
      // Not Found
      // if (status === 404) {
      //   if (error.request.responseURL?.includes('/preview')) {
      //     return;
      //   } else {
      //     window.location.href = '/';
      //   }
      // }

      error.message = errorMessage400(error.response);
      throw error;
    }

    throw error;
  }
);

interface ApiHttp {
  post: ({
    url,
    params,
    options,
  }: {
    url: string;
    params?: Record<
      string,
      any | string | string[] | number | number[] | FormDataEntryValue | boolean | null | undefined
    >;
    options?: AxiosRequestConfig;
  }) => AxiosPromise<any>;
  put: ({
    url,
    params,
    options,
  }: {
    url: string;
    params?: Record<string, string | number | number[] | boolean>;
    options?: AxiosRequestConfig;
  }) => AxiosPromise<any>;
  get: ({
    url,
    params,
    cancelReq,
  }: {
    url: string;
    params?: Record<string, string | number>;
    cancelReq?: any;
  }) => AxiosPromise<any>;
  delete: ({
    url,
    config,
  }: {
    url: string;
    config?: {data?: Record<string, string | number[]>};
  }) => AxiosPromise<any>;
}

/**
 * Handler for HTTP calls sent to alaska_administration server
 *
 * @type {{post: (function(*, *=, *=): AxiosPromise<any>), get: (function(*): AxiosPromise<any>)}}
 */
export const apiHttp: ApiHttp = {
  post: ({url, params, options = {headers: {'Content-Type': 'multipart/form-data'}}}) =>
    instance.post(`${url}`, params, options),
  put: ({url, params, options = {headers: {'Content-Type': 'multipart/form-data'}}}) =>
    instance.put(`${url}`, params, options),
  get: ({url, params, cancelReq}) =>
    instance.get(`${process.env.API_URL}${url}`, {
      params,
      ...(cancelReq && {cancelToken: new axios.CancelToken((c) => (cancelReq = c))}),
    }),
  delete: ({url, config}) => instance.delete(`${url}`, config),
};

export default instance;
