import React from 'react'
import {Link, useParams} from 'react-router-dom'
import styled from 'styled-components'
import {Tab, TabList, Tabs, useTheme} from '@chakra-ui/react'
import {isNumber} from 'lodash'

const StyledTabList = styled(TabList)`
  overflow-x: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const StyledTab = styled(Tab)`
  border: 1px solid ${(props) => props.theme.colors.lighterGrey};
  border-radius: 15px;
  margin-right: 5px;
`

type TabTypes = 'post' | 'event' | 'place' | 'offer'
export type TabsInterface = {
  [index in TabTypes]: {
    tabName: string
    tabElement: any
    title?: string
    subtitle?: string
    endpoint?: (...params) => Promise<any>
    endpointParams?: Record<string, number | string | number[] | string[] | undefined>
    renderArticleType?: boolean
    hook?: any
  }
}

const ArticleTabsNavigation = ({
  articleTabs,
  selectedIndex,
}: {
  articleTabs: TabsInterface
  selectedIndex?: number
}) => {
  const theme = useTheme()
  const {tab} = useParams()
  const tabs = Object.keys(Object.assign({home: {}}, articleTabs))

  return (
    <Tabs
      isLazy={true}
      index={isNumber(selectedIndex) ? selectedIndex : tab ? tabs.indexOf(tab) : 0}
      isFitted
      mt={5}
      size="sm"
      variant="solid-rounded">
      <StyledTabList>
        <StyledTab theme={theme} px={3} _selected={{bg: theme.colors.blue, color: 'white'}}>
          <Link to="/home">Home</Link>
        </StyledTab>
        {tabs.slice(1).map((tab) => (
          <StyledTab
            theme={theme}
            px={3}
            key={tab}
            _selected={{bg: theme.colors.blue, color: 'white'}}>
            <Link to={`/tabs/${tab}`}>{articleTabs[tab].tabName}</Link>
          </StyledTab>
        ))}
      </StyledTabList>
      {/* <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab} px={0}>
            {tabContent}
          </TabPanel>
        ))}
      </TabPanels> */}
    </Tabs>
  )
}

export default ArticleTabsNavigation
