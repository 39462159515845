import React, {useState, useMemo, useEffect} from 'react';
import {
  Box,
  Flex,
  Divider,
  VStack,
  useTheme,
  TabPanels,
  TabPanel,
  Tabs,
  TabList,
  Tab,
} from '@chakra-ui/react';
import {
  useGetNotifications,
  useReadAllNotification,
  useReadNotification,
} from '../../hooks/useNotifications';
import {filter, map} from 'lodash';
import styled from 'styled-components';
import {TextG10, TextG14, TextA18} from '../typography';
import IconCustom from '../../components/Icon';
import NotificationCard from '../NotificationCard';
import {useNavigate} from 'react-router-dom';
import Modal from '../Modals/Modal';

const TriggerContainer = styled(Flex)`
  // height: 100%;
  // min-width: 75px;
  // justify-content: end;
  // align-items: flex-end;
`;

const NotificationsLauncher = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const getNotifications = useGetNotifications();
  const {mutateAsync} = useReadAllNotification();
  const [notifications, setNotifications] = useState<Array<any>>();
  const [urgentNotifications, setUrgentNotifications] = useState<Array<any>>();
  const [numUnread, setNumUnread] = useState(0);
  const [unreadUrgent, setUnreadUrgent] = useState(false);
  const readNotification = useReadNotification();

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      fontSize: '1.3rem',
      background: theme.colors.lightGrey,
      padding: '0.5rem',
      borderRadius: '100%',
      maxWidth: '36px',
      maxHeight: '36px',
      ...(show && {color: theme.colors.blue}),
    }),
    [theme, show]
  );

  useEffect(() => {
    setShow(false);
  }, [location.href]);

  useEffect(() => {
    //setNotifications(getNotifications.data?.pages[0]?.data?.data?.slice(0, 3));

    const unreadNotifications = filter(getNotifications.data?.pages[0]?.data?.data, {
      read_at: null,
    });
    const unreadUrgent = filter(getNotifications.data?.pages[0]?.data?.data, {
      is_urgent: true,
      read_at: null,
    }).slice(0, 3);

    setNotifications(unreadNotifications.slice(0, 3));
    setNumUnread(unreadNotifications?.length);
    setUrgentNotifications(unreadUrgent);
    setUnreadUrgent(unreadUrgent.length > 0);

    if (unreadUrgent.length > 0) setShow(true);
  }, [getNotifications.data]);

  const header = <TextA18>Notifications</TextA18>;
  const footer = (
    <TextG14
      w="100%"
      align="center"
      color={theme.colors.blue}
      onClick={() => {
        navigate('/notifications');
        mutateAsync();
      }}>
      View All
    </TextG14>
  );

  const modalBody = useMemo(
    () =>
      notifications && (
        <Tabs mt="-12px" isFitted defaultIndex={unreadUrgent ? 1 : 0}>
          <TabList>
            <Tab
              _selected={{
                fontWeight: 500,
                color: theme.colors.blue,
                borderBottomColor: theme.colors.blue,
                borderBottomWidth: '3px',
              }}>
              All
            </Tab>
            <Tab
              _selected={{
                fontWeight: 500,
                color: theme.colors.blue,
                borderBottomColor: theme.colors.blue,
                borderBottomWidth: '3px',
              }}>
              Announcements
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              {notifications && notifications.length ? (
                notifications.map((notification, i) => {
                  return (
                    <VStack key={i}>
                      <NotificationCard {...notification} />
                      <Divider
                        opacity="1"
                        borderColor={theme.colors.lighterGrey}
                        mt={'0 !important'}
                      />
                    </VStack>
                  );
                })
              ) : (
                <>
                  <TextG14 align="center" p={2} color={theme.colors.grey}>
                    You have no unread Notifications
                  </TextG14>
                  <Divider />
                </>
              )}
            </TabPanel>
            <TabPanel p={0}>
              {urgentNotifications && urgentNotifications.length ? (
                urgentNotifications.map((notification, i) => {
                  return (
                    <VStack key={i}>
                      <NotificationCard {...notification} />
                      <Divider
                        opacity="1"
                        borderColor={theme.colors.lighterGrey}
                        mt={'0 !important'}
                      />
                    </VStack>
                  );
                })
              ) : (
                <>
                  <TextG14 align="center" p={2} color={theme.colors.grey}>
                    You have no unread Announcements
                  </TextG14>
                  <Divider />
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      ),
    [notifications, urgentNotifications, unreadUrgent]
  );

  return (
    <>
      <TriggerContainer>
        {numUnread > 0 && (
          <Box position="absolute">
            <Box
              textAlign="center"
              position="relative"
              left="24px"
              bottom="4px"
              borderRadius="full"
              backgroundColor={theme.colors.red}
              w="16px"
              h="16px">
              <TextG10 align="center" color="white">
                {numUnread}
              </TextG10>
            </Box>
          </Box>
        )}
        <IconCustom
          onClick={() => setShow(!show)}
          iconName={show ? 'fi-sr-bell' : 'fi-rr-bell'}
          style={iconStyle}
        />
      </TriggerContainer>
      <Modal
        header={header}
        children={modalBody || <></>}
        footer={footer}
        show={show}
        parentCallback={() => {
          const unreadUrgent = filter(getNotifications.data?.pages[0]?.data?.data, {
            is_urgent: true,
            read_at: null,
          }).slice(0, 3);

          map(unreadUrgent, (urgentNotification) =>
            readNotification.mutateAsync(urgentNotification.id)
          );

          setShow(false);
        }}
        modalPadding={0}
        closeButton
      />
    </>
  );
};

export default NotificationsLauncher;
