import {Divider, Flex, Stack, useTheme} from '@chakra-ui/react';
import React, {useEffect, useMemo, useState} from 'react';
import {TextG12, TextG14} from '../../../components/typography';
import Accordion from './Accordion';
import IconsHeader from './IconsHeader';
import {Offer, OfferType, Place, PlaceType} from '../../../types/article';
import OpeningHours from './OpeningHours';
import {fetchPlaces} from '../../../endpoints/api';
import getPlaceAddress from '../../../utils/address';
import TopicsOfInterest from '../../TopicsOfInterest';
import {BlueButton} from '../../../components/button';
import useGetUserLocation from '../../../hooks/useUserLocation';
import useCheckIn from '../../../hooks/useCheckIn';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {usePlaceTypes} from '../../../hooks/usePlaceTypes';
import {SpinnerLoader} from '../../../components/Loader';
import {filter, get, map, uniqBy} from 'lodash';
import useActiveRedemptions, {
  ActiveRedemption,
  isActiveRedemption,
} from '../../../hooks/useActiveRedemptions';
import ItemCarousel from '../../../components/ItemCarousel';
import ActiveRedemptionCard from '../../../components/Articles/Offers/ActiveRedemptionCard';
import OfferCard from '../../../components/Articles/Offers/OfferCard';
import Icon from '../../../components/Icon';
import useAuth from '../../../hooks/useAuth';

const PlaceAbout = ({
  place,
  placeOffers,
  disableLocation,
  preview = false,
  setSignUpRequiredOpen,
}: {
  place: Place;
  placeOffers: Offer[];
  disableLocation: () => void;
  preview?: boolean;
  setSignUpRequiredOpen?: any;
}) => {
  const [otherPlaces, setOtherPlaces] = React.useState<Place[]>();
  const {user} = useAuth();
  const userLocation = useGetUserLocation();
  const {placeCheckIn} = useCheckIn();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  //const {placeTypesLoading, getPlaceTypeFromId} = usePlaceTypes(!preview);
  const theme = useTheme();
  const action = useMemo(() => searchParams.get('action'), [searchParams]);
  const isLoading = userLocation.isLoading;

  // const placeTypeName = getPlaceTypeFromId(place?.place_type_id)?.display_name;
  // const categories = map(get(place, 'categories'), (category: Place) => category.name);
  // const allTypes = [placeTypeName, ...categories].filter(Boolean).join(', ');
  const placeType = get(place, 'type.name');

  const {activeRedemptions, getActiveRedemption, isActiveOffer} = useActiveRedemptions();

  const [offers, setOffers] = useState<Array<any>>([]);

  useEffect(() => {
    const activeOffers = map(
      filter(
        activeRedemptions,
        (redemption) =>
          redemption.offer?.place_id == place.id && !!redemption.offer.countdown_required
      ),
      (redemption) => redemption.offer
    );
    setOffers((prev) => uniqBy([...placeOffers, ...activeOffers], 'id'));
  }, [placeOffers]);

  useEffect(() => {
    /**
     * TODO: change with 'you will alse like' route
     */
    if (!preview) {
      fetchPlaces({
        page: '1',
        cancelReq: null,
      }).then((res) => setOtherPlaces(res.data.data.filter((p) => p.id !== place.id)));
    }
  }, [place.id, preview]);

  useEffect(() => {
    if (!preview && !userLocation.isLoading && action && action == 'check-in') {
      const qr_code_id_string = searchParams.get('qr_code_id');
      let qr_code_id;
      if (qr_code_id_string) {
        qr_code_id = parseInt(qr_code_id_string);
      }
      handleCheckIn({sdv: !!searchParams.get('sdv'), qr_code_id});
    }
  }, [action, isLoading]);

  const handleCheckIn = async ({sdv, qr_code_id}) => {
    if (userLocation.error && !sdv) {
      disableLocation();
      return;
    }

    if ((!userLocation.data?.latitude || !userLocation.data?.longitude) && !sdv) {
      return;
    }

    const boostedCheckin = offers.filter((offer) => offer.offer_type == OfferType.POINTS_BOOST);
    let offerId;
    if (boostedCheckin.length > 0) {
      offerId = boostedCheckin[0].id;
    }

    placeCheckIn({
      place: place,
      latitude: userLocation?.data?.latitude,
      longitude: userLocation?.data?.longitude,
      isPublic: true,
      offerId: offerId,
      offers: placeOffers,
      sdv: sdv,
      qr_code_id,
    });
  };

  // if (placeTypesLoading && !preview) return <SpinnerLoader />;

  return (
    <>
      <Stack gap=".25rem" textAlign="start" p="1rem">
        <ItemCarousel
          swiperProps={{
            slidesPerGroup: 1,
            slidesPerView: 1,
            items: offers,
            render: (item) => {
              return (
                <>
                  {/* <ActiveRedemptionCard
                    offer={item as Offer}
                    activeRedemption={getActiveRedemption(item as Offer)}
                    hideCard={!isActiveOffer(item as Offer)}
                  /> */}
                  <OfferCard
                    showBookmark={!preview}
                    onClick={
                      preview
                        ? () => {
                            setSignUpRequiredOpen(true);
                          }
                        : undefined
                    }
                    place={place}
                    offer={item as Offer}
                    // hideActive={(item as Offer).countdown_required}
                  />
                </>
              );
            },
          }}
        />
        <IconsHeader
          setSignUpRequiredOpen={setSignUpRequiredOpen}
          preview={preview}
          place={place}
        />
        <BlueButton
          my="12px"
          background={preview ? theme.colors.grey : theme.colors.blue}
          onClick={
            preview
              ? () => {
                  setSignUpRequiredOpen(true);
                }
              : handleCheckIn
          }>
          <TextG12 fontWeight="500">Check-In</TextG12>
        </BlueButton>
        {user?.hasActiveMembership && (
          <BlueButton
            mb="8px"
            border={`1px solid ${theme.colors.blue}`}
            gap="8px"
            justifyContent="center"
            w="100%"
            textAlign={'center'}
            background={preview ? theme.colors.grey : theme.colors.white}
            color={theme.colors.blue}
            onClick={
              preview
                ? () => {
                    setSignUpRequiredOpen(true);
                  }
                : () => {
                    navigate(`/create-meetup?placeId=${place.id}`);
                  }
            }>
            <Icon
              style={{fontSize: '16px', width: '16px', height: '13px', color: theme.colors.blue}}
              iconName="fi-rr-users-medical"
            />
            <TextG12 fontWeight="500">Invite Your Neighbors to Join You</TextG12>
          </BlueButton>
        )}
        {place?.description && (
          <TextG14
            mb="8px"
            color={theme.colors.lightBlack}
            className="inner-content"
            dangerouslySetInnerHTML={{__html: place.description}}
            children={null}></TextG14>
        )}
        {place?.topics_of_interest && place?.topics_of_interest.length && (
          <TopicsOfInterest topicsOfInterest={place?.topics_of_interest} />
        )}
        <Accordion place={place} />
        <Flex gap=".5rem">
          <TextG12 fontWeight="500">Place Type: </TextG12>
          <TextG12>{placeType}</TextG12>
        </Flex>

        {
          // will be re-introduced with endorsements}
          /* <Divider />
        <AvatarGroup /> */
        }
        {place?.business_hours && (
          <>
            {/* <Divider /> */}
            <OpeningHours place={place} />
          </>
        )}
        {place?.street_address && (
          <>
            {/* <Divider /> */}
            <Flex gap=".5rem">
              <TextG12 fontWeight="500">Address: </TextG12>
              <TextG12>{getPlaceAddress(place)}</TextG12>
            </Flex>
          </>
        )}
      </Stack>
    </>
  );
};

export default PlaceAbout;
