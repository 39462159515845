import {Center, Container, Flex, Spinner, useDisclosure, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import Articles from '../../../components/Articles';
import {filter, has, includes, map} from 'lodash';
import {articleTabs} from '../index';
import {Navigate, useSearchParams} from 'react-router-dom';
import useGetUserLocation from '../../../hooks/useUserLocation';
import {placeTypeData, usePlaceTypes} from '../../../hooks/usePlaceTypes';
import {filterPlaceByType} from '../../../endpoints/api';
import SelectPlaces, {MultiValueSelect} from '../../../components/SelectPlaces';
import ArticleModal from '../../../components/Articles/Modal';
import SortPlaces, {SortOptionsValue, SingleValueSelect} from '../../../components/SortPlaces';
import CustomSwitch from '../../../components/CustomSwitch';
import {useInfinitePlacesByType} from '../../../hooks/useFetchPlacesByType';
import store from '../../../state/store';
import {useSelector} from 'react-redux';

const Tab = () => {
  const {tab} = useParams();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const userLocation = useGetUserLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [endpointParams, setEndpointParams] = useState(articleTabs[`${tab}`].endpointParams);
  const [selectedPlaceTypes, setSelectedPlaceTypes] = useState<MultiValueSelect>([]);
  const {placeTypes, getIdsForCategory, placeTypesLoading} = usePlaceTypes();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [typesIds, setTypesIds] = useState<number[]>([]);
  const [sortType, setSortType] = useState<SortOptionsValue>('distance');
  const [openNow, setOpenNow] = useState<string>('open');
  const selectedPlaces = useRef<boolean>(false);
  const communityId = useSelector((state: any) => state.community.community)?.id;

  if (!tab || !(tab in articleTabs)) {
    return <Navigate to="/home" />;
  }

  useEffect(() => {
    setIsLoading(true);
    if (tab === 'place') {
      if (searchParams.has('id')) {
        setIsLoading(false);
        return;
      }
      if (placeTypesLoading) {
        setIsLoading(true);
      } else {
        const typeIds: any[] = [];
        if (searchParams.get('type')) {
          const categories = searchParams.getAll('type') || [];

          map(categories, (category) => {
            typeIds.push(...getIdsForCategory(category));
          });
          setTypesIds(typeIds);

          const selectedTypes: unknown = filter(placeTypeData, (item) => {
            return includes(categories, item.category);
          });

          setSelectedPlaceTypes(selectedTypes as MultiValueSelect);
        } else setSelectedPlaceTypes([]);

        articleTabs[tab].endpoint = filterPlaceByType;
        articleTabs[tab].hook = useInfinitePlacesByType;
        const params = {
          ...(searchParams.get('type') && {typeIds}),
          lat: userLocation?.data?.latitude,
          long: userLocation?.data?.longitude,
          sort: searchParams.get('sort') || 'distance',
        };

        if (!!openNow) {
          params['open'] = openNow;
        }

        setEndpointParams(params);
      }
    } else {
      setIsLoading(false);
      setSelectedPlaceTypes([]);
    }
  }, [tab, placeTypes, searchParams.get('type'), searchParams.get('sort')]);

  useEffect(() => {
    setIsLoading(false);
  }, [endpointParams]);

  useEffect(() => {
    onOpen();
  }, [searchParams.get('id')]);

  useEffect(() => {
    setSortType('distance');
  }, [searchParams.get('type')]);

  useEffect(() => {
    if (tab !== 'place') {
      setOpenNow('open');
      return;
    }
    if (!selectedPlaces.current) return;

    articleTabs[tab].endpoint = filterPlaceByType;
    articleTabs[tab].hook = useInfinitePlacesByType;
    const params = {
      typeIds: typesIds,
      lat: userLocation?.data?.latitude,
      long: userLocation?.data?.longitude,
      sort: sortType,
    };

    if (!!openNow) {
      params['open'] = openNow;
    }

    setEndpointParams(params);
  }, [selectedPlaceTypes, sortType, openNow]);

  if (isLoading)
    return (
      <Center pt={16}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <Container py={4} px={0} pb={0} h="auto">
      {searchParams.has('id') && (
        <ArticleModal
          open={isOpen}
          close={() => {
            searchParams.delete('id');
            searchParams.delete('articleType');
            setSearchParams(searchParams);
            onClose();
          }}
          type={tab}
        />
      )}
      {tab === 'place' ? (
        <>
          <SelectPlaces
            selected={selectedPlaceTypes}
            changeHandler={(e: MultiValueSelect) => {
              const typeIds: number[] = [];
              map(e, (option) => typeIds.push(...getIdsForCategory(option.category)));
              setTypesIds(typeIds);
              setSelectedPlaceTypes(e);
              searchParams.delete('id');
              setSearchParams(searchParams);
              selectedPlaces.current = true;
              if (e.length <= 0) {
                setSortType('distance');
              }
            }}
          />
          {
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              {userLocation?.data && (
                <SortPlaces
                  onChange={(e: SingleValueSelect) => {
                    e?.value && setSortType(e.value);
                    searchParams.delete('id');
                    setSearchParams(searchParams);
                    selectedPlaces.current = true;
                  }}
                />
              )}
              <CustomSwitch
                title={'Open Now'}
                checked={!!openNow}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  selectedPlaces.current = true;
                  if (e.target.checked) {
                    setOpenNow('open');
                    return;
                  }
                  setOpenNow('');
                }}
              />
            </Flex>
          }
        </>
      ) : null}
      {searchParams.get('type') && !endpointParams ? null : (
        <>
          {/* {tab === 'offer' ? <ActiveRedemptionCards /> : null} */}
          <Articles
            type={tab}
            title={articleTabs[tab]?.title}
            subtitle={articleTabs[tab]?.subtitle}
            hook={articleTabs[tab].hook}
            {...(has(articleTabs[tab], 'endpoint') ? {endpoint: articleTabs[tab]?.endpoint} : {})}
            endpointParams={{
              ...endpointParams,
              communityId,
            }}
            // {...(has(articleTabs[tab], 'endpointParams')
            //   ? {endpointParams: articleTabs[tab]?.endpointParams}
            //   : {})}
            {...(has(articleTabs[tab], 'renderArticleType')
              ? {renderArticleType: articleTabs[tab]?.renderArticleType}
              : {})}>
            {articleTabs[tab].tabElement}
          </Articles>
        </>
      )}
    </Container>
  );
};

export default Tab;
