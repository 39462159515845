import {ArticleSortOptions} from '../../hooks/useSortingOptions';
import {Location} from 'react-router-dom';
import {CONTENT_TYPE, FILTER_TYPES} from './enums';
import {FILTERS} from '../../components/GlobalSearch/FilterConfig';
// @ts-ignore
import _, {has} from 'lodash';
import {GlobalFilters} from './types';

export const mapSortOptions = (articleType): ArticleSortOptions[] => {
  const availableArticleSortOptions: ArticleSortOptions[] = [];
  for (const orderBy in articleType) {
    if (articleType.hasOwnProperty(orderBy)) {
      if (typeof articleType[orderBy] === 'object') {
        for (const sortOption in articleType[orderBy]) {
          availableArticleSortOptions.push({
            value: `${orderBy}_${sortOption}`,
            label: articleType[orderBy][sortOption],
            orderBy,
            sort: sortOption,
          });
        }
      }
    }
  }

  return availableArticleSortOptions;
};

export const isDateValid = (dateStr: string) => {
  // @ts-ignore
  return !isNaN(new Date(dateStr));
};

export const castBoolean = (val: string | number | boolean) => val as unknown as boolean;

export const isValidContentType = (type: string): type is CONTENT_TYPE => {
  return Object.values(CONTENT_TYPE).includes(type as CONTENT_TYPE);
};

export const isValidSortOption = ({options, type, by, direction}) => {
  const contentTypeSortOptions = options[type];

  if (!has(contentTypeSortOptions, by)) {
    return false;
  }

  if (!has(contentTypeSortOptions[by], direction)) {
    return false;
  }

  return true;
};

export const contentTypeFromUrl = (location: Location): CONTENT_TYPE | null => {
  if (!location || !location.pathname || !location.pathname.includes('search')) {
    return null;
  }

  const type = location.pathname.split('/').pop() || '';

  if (!isValidContentType(type)) return CONTENT_TYPE.ALL;

  return type;
};

export const parseQueryParam = (key: string, value: string | null, type: CONTENT_TYPE) => {
  const multipleOptionsFilter = Object.keys(FILTERS[type]).filter(
    (key) => FILTERS[type][key].type === FILTER_TYPES.MULTISELECT
  );
  if (multipleOptionsFilter.includes(key)) {
    return value?.split(',');
  }

  if (!value) return null;
  return isNaN(+value) ? value : +value;
};

export const getFilterKeysForContentType = (type: CONTENT_TYPE) => Object.keys(FILTERS[type]);

export const getAppliedFiltersForContentType = (type: CONTENT_TYPE, filters: GlobalFilters) => {
  const contentSpecificFilters = filters?.[type] || [];
  const keys = Object.keys(contentSpecificFilters).filter((filterKey) => {
    if (Array.isArray(contentSpecificFilters[filterKey])) {
      return contentSpecificFilters[filterKey].filter(Boolean).length;
    }
    return !!contentSpecificFilters[filterKey];
  });

  // Convert `obj` to a key/value array
  const asArray = Object.entries(FILTERS[type]);

  const filtered = asArray.filter(([key]) => keys.includes(key));

  // Convert the key/value array back to an object and return
  return Object.fromEntries(filtered);
};

export const getFiltersConfigForPopup = (type: CONTENT_TYPE) => {
  // group filters since some of them should be merged into one filter
  // if they have the same key they should be merged
  return _(Object.keys(FILTERS[type]))
    .groupBy((key) => FILTERS[type][key].key)
    .map((value, key) => ({[value.length === 1 ? value[0] : key]: FILTERS[type][value[0]]}))
    .value()
    .reduce(
      (acc, item) => ({
        ...acc,
        ...item,
      }),
      {}
    );
};

export const getContentTypeQuickFilters = (type: CONTENT_TYPE, filters: GlobalFilters) => {
  return Object.keys(FILTERS[type]).reduce((acc, filterConfigKey) => {
    if (shouldHideFilter(type, filters, filterConfigKey)) return acc;

    return FILTERS[type][filterConfigKey].quick
      ? {...acc, [filterConfigKey]: FILTERS[type][filterConfigKey]}
      : acc;
  }, {});
};

export const shouldHideFilter = (type: CONTENT_TYPE, filters: GlobalFilters, key: string) => {
  const contentSpecificFilters = filters?.[type] || [];
  return Object.keys(FILTERS[type]).some((filterKey) => {
    return FILTERS[type][filterKey]?.hides?.includes(key) && contentSpecificFilters?.[filterKey];
  });
};

export const getFiltersWithAsyncOptions = (type: CONTENT_TYPE): string[] => {
  return Object.keys(FILTERS[type]).reduce(
    (acc, filterConfigKey) =>
      FILTERS[type][filterConfigKey].fetchOptions ? [...acc, filterConfigKey] : acc,
    [] as string[]
  );
};

export const isStandaloneSearchPage = (location: globalThis.Location) =>
  location.pathname.split('/')[1] === 'standalone-search';
