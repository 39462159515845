import {toUpper} from 'lodash';
import dayjs from 'dayjs';

export const getUnixDate = (dateTime: number) => {
  return dayjs(dateTime).local().calendar(null, {sameElse: 'MMM DD [at] h:mm A'});
};

export const getCalenderDate = (dateTime: dayjs.ConfigType) => {
  return dayjs.utc(dateTime).local().calendar(null, {
    sameDay: '[Today at] h:mm A',
    nextDay: '[Tomorrow at] h:mm A',
    nextWeek: 'ddd [at] h:mm A',
    sameElse: 'MMM DD [at] h:mm A',
  });
};

export const getEventDate = (eventStartTime: dayjs.ConfigType, eventEndTime: dayjs.ConfigType) => {
  const isHappeningNow =
    dayjs.utc(eventStartTime).isBefore(dayjs().utc()) &&
    dayjs.utc(eventEndTime).isAfter(dayjs().utc());
  const datePassed = dayjs().utc().isAfter(dayjs.utc(eventEndTime));
  if (isHappeningNow) {
    return 'HAPPENING NOW';
  } else if (datePassed) {
    return 'EVENT DATE HAS PASSED';
  } else {
    return toUpper(getCalenderDate(eventStartTime).replace(/(:00)/g, ''));
  }
};

export const getOfferDate = (offerExpirationTime: dayjs.ConfigType) => {
  return getCalenderDate(offerExpirationTime).replace(/(:00)/g, '');
};

export const isFutureOffer = (offer) => {
  return dayjs.utc(offer?.starts_at).isAfter(dayjs().utc());
};

export const isFutureDate = (date) => {
  return dayjs.utc(date).isAfter(dayjs().utc());
};

export const getPassExpiration = (expiresAt) => {
  return dayjs.utc(expiresAt).local().calendar(null, {
    sameDay: '[Today at] h:mm A',
    nextDay: '[Tomorrow at] h:mm A',
    nextWeek: 'MMM D, YYYY',
    sameElse: 'MMM D, YYYY',
  });
};

export const getTicketDate = (eventStartTime: dayjs.ConfigType, eventEndTime: dayjs.ConfigType) => {
  const isHappeningNow =
    dayjs.utc(eventStartTime).isBefore(dayjs().utc()) &&
    dayjs.utc(eventEndTime).isAfter(dayjs().utc());
  const datePassed = dayjs().utc().isAfter(dayjs.utc(eventEndTime));
  if (isHappeningNow) {
    return 'HAPPENING NOW';
  } else if (datePassed) {
    return 'EVENT DATE HAS PASSED';
  } else {
    return dayjs.utc(eventStartTime).local().calendar(null, {
      sameDay: '[Today at] h:mma',
      nextDay: '[Tomorrow at] h:mma',
      nextWeek: 'dddd, MMMM DD, YYYY, [at] h:mma',
      sameElse: 'dddd, MMMM DD, YYYY, [at] h:mma',
    });
  }
};
