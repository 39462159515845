import {Flex, Image, Box, useTheme, Spacer, HStack} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import DefaultPassImage from '../../assets/images/genericPassImage.png';
import SignautrePassImage from '../../assets/images/signaturePassImage.png';
import ExperiencePassImage from '../../assets/images/experiencePassImage.png';
import Icon from '../../components/Icon';
import {TextG10, TextG14, TextG8} from '../../components/typography';
import {getPassExpiration} from '../../utils/date';
import {passIsExperience, passIsSignature} from '../../utils/membership';

interface PassCardProps {
  pass: any;
  showGetMore?: boolean;
  showArrow?: boolean;
  showNumberOfPasses?: boolean;
  arrowCTA?: JSX.Element;
  numberOfPasses?: number;
  onClick?: () => void;
  expires_at?: string;
  nextExpire?: boolean;
}

const PassCard: React.FC<PassCardProps> = ({
  pass,
  showGetMore = true,
  showArrow = true,
  showNumberOfPasses = false,
  arrowCTA,
  numberOfPasses = 0,
  onClick = () => {},
  expires_at = '',
  nextExpire = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const currentDate = dayjs().utc().local();
  const isWithin7Days = dayjs
    .utc(expires_at)
    .local()
    .isBetween(currentDate.subtract(7, 'day'), currentDate.add(7, 'day'));

  const isExperiencePass = passIsExperience(pass);
  const isSignaturePass = passIsSignature(pass);

  return (
    <Flex
      flexDir="column"
      p="5px 5px 0px 5px"
      w="100%"
      boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.14)"
      border="1px solid #FFC0AE"
      borderRadius="12px"
      onClick={onClick}>
      <Box pos="relative">
        {expires_at && (
          <Box pos="absolute" background="white" p="5px" borderRadius="5px" top="7px" left="9px">
            <TextG8 color={isWithin7Days ? theme.colors.red : theme.colors.darkGrey}>{`${
              nextExpire ? 'Next Expires' : 'Expires'
            } ${getPassExpiration(expires_at)}`}</TextG8>
          </Box>
        )}
        <Image
          src={
            isSignaturePass
              ? SignautrePassImage
              : isExperiencePass
              ? ExperiencePassImage
              : DefaultPassImage
          }
          w="100%"
          h="90px"
          borderRadius="10px"
          objectFit="cover"
        />
      </Box>
      <Flex flexDir="column" p="10px" gap="6px">
        <Flex justifyContent="space-between">
          <HStack gap="20px">
            <TextG14 fontWeight="700">{pass.title}</TextG14>
            <Box
              borderRadius="9px"
              py="2px"
              px="12px"
              background="#E0F3E3"
              border="1px solid white">
              <TextG8 fontWeight="700" color="#329241">
                INCLUDED
              </TextG8>
            </Box>
          </HStack>
          {showNumberOfPasses && (
            <Box py={1} px={2} borderRadius="5px" border="1px solid black">
              <TextG8
                color={numberOfPasses == 0 ? theme.colors.red : theme.colors.darkGrey}
                fontWeight="500">
                {`${numberOfPasses} ${numberOfPasses == 1 ? 'pass' : 'passes'}`}
              </TextG8>
            </Box>
          )}
        </Flex>
        <TextG10 dangerouslySetInnerHTML={{__html: pass.description}}>{}</TextG10>

        <Flex justifyContent="space-between" mt="6px">
          {showGetMore ? (
            <Flex
              zIndex={2}
              gap="6px"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/pass/${pass.id}/purchase`);
              }}>
              <Icon iconName="fi-sr-add" style={{fontSize: '14px', color: theme.colors.blue}} />
              <TextG10 color={theme.colors.blue} fontWeight="500">
                Get more Passes
              </TextG10>
            </Flex>
          ) : (
            <Spacer />
          )}
          <Flex gap="6px">
            {showArrow && arrowCTA && !(showNumberOfPasses && numberOfPasses <= 0)
              ? arrowCTA
              : null}

            {showArrow ? (
              showNumberOfPasses && numberOfPasses <= 0 ? (
                <TextG10 color={theme.colors.red} fontWeight="600" fontStyle="italic">
                  You're out of Passes!
                </TextG10>
              ) : (
                <Icon
                  iconName="fi-rr-arrow-small-right"
                  style={{fontSize: '14px', height: '14px', color: theme.colors.darkGrey}}
                />
              )
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PassCard;
