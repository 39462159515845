import { Flex } from "@chakra-ui/react";
import React from "react";
import { components, MenuProps } from "react-select";
import { TextG10 } from "../../../typography";
import { SearchOptions, GroupedOption } from "..";

export const RecentSearchMenu = (
  props: MenuProps<SearchOptions, true, GroupedOption>
) => {
  const {
    selectProps: {
      toggleMenu,
      inputValue,
      inputCleared,
    },
  } = props;

  if(inputValue === "" && inputCleared) {
    return (
      <components.Menu<SearchOptions, true, GroupedOption> {...props}>
        <Flex padding={"15px 15px 0"} alignItems="center" justifyContent={"space-between"}>
          <TextG10 color="#C4C4C4" fontWeight="500">
            Your recent searches
          </TextG10>
          <i
            onClick={() => toggleMenu && toggleMenu((prev) => !prev)}
            className="fi fi-rr-cross-circle"
            style={{
              color: "#757D84",
              fontSize: "15px",
              height: "16px",
            }}
          ></i>
        </Flex>
        {props.children}
      </components.Menu>
    );
  }

  return <></>;
};
