import {extendTheme} from '@chakra-ui/react';

// Max width for containers and layout
export const maxW = 1344;

const theme = extendTheme({
  styles: {
    global: {
      '.chakra-container': {
        height: '100vh',
      },
      '.inner-content': {
        a: {
          color: '#3FB8FC',
        },
        ol: {
          margin: 'revert',
          padding: 'revert',
        },
        ul: {
          margin: 'revert',
          padding: 'revert',
        },
        iframe: {
          width: '100%',
        },
      },
      'p, p::before, p::after': {
        wordWrap: 'anywhere',
      },
      h1: {
        fontSize: '2.1428571em',
        fontWeight: '800',
        lineHeight: '1.2',
        marginBottom: '.8em',
        marginTop: '.8em',
      },
      h2: {
        fontSize: '1.4285714em',
        fontWeight: '700',
        lineHeight: '1.4',
        marginBottom: '.8em',
        marginTop: '.8em',
      },
      h3: {
        fontSize: '1.2857143em',
        fontWeight: '600',
        lineHeight: '1.5555556',
        marginBottom: '.4em',
        marginTop: '.4em',
      },
      h4: {
        fontSize: 'inherit !important',
        fontWeight: '600',
        lineHeight: '2.428571',
        marginBottom: '.5714286em',
        marginTop: '.5714286em',
      },
      h5: {
        fontSize: 'inherit !important',
        fontWeight: 'inherit !important',
      },
      h6: {
        fontSize: 'inherit !important',
        fontWeight: 'inherit !important',
      },
      blockquote: {
        borderLeft: '2px solid #6d737b',
        marginLeft: '1.5em',
        paddingLeft: '1rem',
        display: 'block',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '40px',
        marginInlineEnd: '40px',
        unicodeBidi: 'isolate',
      },
    },
  },
  colors: {
    darkerWhite: '#F4F4F4',
    lightestGrey: '#C4C4C4',
    lighterGrey: '#E5E5E5',
    lightGrey: '#F4F4F4',
    grey: '#757D84',
    grey2: '#D9D9D9',
    darkerGrey: '#8F8E8B',
    darkGrey: '#272929',
    darkestGrey: '#A0AEC0',
    blue: '#276ACD',
    lightBlue: '#1a87c5',
    lighterBlue: '#3FB8FC',
    blueShade1: '#BCD7FF',
    blueShade2: '#E7F4FF',
    veryLightBlue: '#F4F7F8',
    lightestBlue: '#EDFBFF',
    lightBlack: '#272929',
    skyBlue: '#CCF5FE',
    red: '#F5737F',
    yellow: '#F9CE39',
    orange: '#FFEED2',
    darkYellow: '#F9AC39',
    rose: '#E02F84',
    darkerRose: '#E9397B',
    progress: {500: '#276ACD'},
    purpleProgress: {500: '#A87FFF'},
  },
});

export default theme;
