import React from 'react';
import PostCard from '../PostCard';
import EventCard from '../EventCard';
import PlaceCard from '../PlaceCard';
import OfferCard from '../Offers/OfferCard';
import {ArticleType, Offer} from '../../../types/acrticle';
import PartnerCard from '../PartnerCard';

const HomePage = ({
  article,
  leftTopItem,
  rightTopItem,
  modal,
}: {
  article?: ArticleType;
  leftTopItem?: any;
  rightTopItem?: any;
  modal?: boolean;
}) => {
  if (!article) {
    return <></>;
  }

  //TODO Temporarily until valid prop provided for entity types
  if (article.type === null || typeof article.type === 'object') {
    article.type = 'place';
  }

  switch (article.type?.toLowerCase()) {
    case 'post':
      return (
        <PostCard
          post={article}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          modal={modal ?? false}
        />
      );
    case 'event':
      return (
        <EventCard
          event={article}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          modal={modal ?? false}
        />
      );
    case 'place':
      return (
        <PlaceCard
          place={article}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          modal={modal ?? false}
        />
      );
    case 'offer':
      return (
        <OfferCard
          // hideActive={(article as Offer).countdown_required}
          offer={article}
          rightBottomItem={rightTopItem}
        />
      );
    case 'partner':
      return <PartnerCard partner={article} leftTopItem={leftTopItem} modal={modal ?? false} />;
    default:
      // return <></>
      // TODO: temporary until proper endpoint and type management is introduced for fetching bookmarks
      return (
        <PlaceCard
          place={article}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          modal={modal ?? false}
        />
      );
  }
};

export default HomePage;
