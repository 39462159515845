import {FilterConf} from '../../../utils/GlobalSearch/types'
import {CONTENT_TYPE} from '../../../utils/GlobalSearch/enums'
import EVENT_CONFIG from './Event'
import PLACE_CONFIG from './Place'
import OFFER_CONFIG from './Offer'
import POST_CONFIG from './Post'
import ALL_CONFIG from './All'
import PARTNER_CONFIG from './Partner'
import MEET_UP_CONFIG from './Meet-Up'

export const FILTERS: {[index in CONTENT_TYPE]: Record<string, FilterConf>} = {
  ...EVENT_CONFIG,
  ...PLACE_CONFIG,
  ...OFFER_CONFIG,
  ...POST_CONFIG,
  ...PARTNER_CONFIG,
  ...MEET_UP_CONFIG,
  ...ALL_CONFIG,
}
