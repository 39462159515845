import {Flex} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import {TextA18} from '../../../components/typography';

const Header = ({setShowSummary}) => {
  return (
    <Flex background="white" px={3} py={5} align="center" w="100%">
      <BackButton onClick={() => setShowSummary(false)} />
      <TextA18 fontWeight="700" w="100%" textAlign="center" marginLeft="-18px">
        Order Summary
      </TextA18>
    </Flex>
  );
};

export default Header;
