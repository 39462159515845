import {TextG14} from '../../../typography'
import {Flex, Switch} from '@chakra-ui/react'
import React from 'react'

interface ToggleFilterProps {
  id?: string
  label: string
  value: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ToggleFilter = ({id, label, value, onChange}: ToggleFilterProps) => {
  return (
    <Flex alignItems={'center'} justifyContent={'space-between'} pl={5} pr={4} py={5}>
      <TextG14>{label}</TextG14>
      <Switch id={id} size={'lg'} defaultChecked={value} colorScheme="green" onChange={onChange} />
    </Flex>
  )
}

export default ToggleFilter
