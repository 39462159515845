import React, {useEffect, useState} from 'react';
import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Center,
  useTheme,
} from '@chakra-ui/react';
import {H3, TextG12, TextG14, TextA18, TextA14, TextG10} from '../typography';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {BlueButton} from '../button';
import Icon from '../Icon';
import {usePlaceTypes} from '../../hooks/usePlaceTypes';
import {without} from 'lodash';
import {SpinnerLoader} from '../Loader';
import {getAppName} from '../../utils/app';
import useAuth from '../../hooks/useAuth';
import {useFetchUserTickets} from '../../hooks/useUserTickets';
import {useFetchCalendars} from '../../hooks/useCalendars';
import ExternalLink from '../ExternalLink';
import {isIphone} from '../../utils/deviceCheck';
import useTrackEvent from '../../hooks/useTrackEvent';
import dayjs from "dayjs";

const NavBarContent = ({bottomModalSheetRef, dragHandler}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const {categories, getIconDataForCategory, placeTypesLoading, getIdsForCategory} =
    usePlaceTypes();
  const [accordionIndex, setAccordionIndex] = useState([0, 1]);
  const [searchParams] = useSearchParams();
  const {user} = useAuth();

  const {data, isLoading} = useFetchUserTickets(!!user);
  const {data: calendars, isLoading: isLoadingCalendars} = useFetchCalendars();
  const {addUserEvent} = useTrackEvent();

  useEffect(() => {
    const openQuickLinks = searchParams.get('openQuickLinks');
    if (openQuickLinks) {
      bottomModalSheetRef?.current?.setState(true);
    }
  }, []);

  if (placeTypesLoading || isLoading || isLoadingCalendars || !user)
    return (
      <Center w="100%" minH="180px">
        <SpinnerLoader />
      </Center>
    );

  return (
    <>
      <Accordion index={accordionIndex} pb={4}>
        {/* <TextSeparator>
          <TextG12 fontWeight={"700"} color={theme.colors.rose}>
            OR
          </TextG12>
        </TextSeparator> */}
        <AccordionItem border="none">
          <AccordionButton
            // onClick={() => setAccordionIndex([1, 2])}
            justifyContent="center"
            _expanded={{bg: 'initial'}}>
            <TextA18
              onClick={() => {
                dragHandler(false);
              }}
              fontWeight={'800'}>
              Earn Points
            </TextA18>
          </AccordionButton>
          <AccordionPanel p={0}>
            <Box
              px={4}
              pb={5}
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={'3'}>
              {
                <BlueButton w="220px" borderRadius={'12px'} onClick={() => navigate('/check-in')}>
                  <TextG12>Check In</TextG12>
                </BlueButton>
              }
            </Box>
          </AccordionPanel>
        </AccordionItem>

        {calendars && calendars.length && (
          <Box
            px={4}
            pb={5}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'3'}>
            {
              <ExternalLink
                href={
                  isIphone ? calendars[0].webcal_url : calendars[0].google_url
                }
                onClick={() => {
                  addUserEvent({
                    type: 'click',
                    event_name: 'subscribe_to_calendar_click',
                    event_time: dayjs().format(),
                    data: {
                      calendar_id: calendars[0].id,
                      calendar_identifier: calendars[0].identifier,
                      device_type: isIphone ? 'iphone' : 'android',
                    },
                  });
                }}
                isExternal>
                <BlueButton w="220px" mt="0px" borderRadius="12px">
                  {/* TODO hide button when subscribed and display somewhere else */}
                  <TextG12> {calendars[0].is_user_subscribed ? 'Open Calendar (Subscribed)' : 'Subscribe to Calendar'}</TextG12>
                </BlueButton>
              </ExternalLink>
            }
          </Box>
        )}

        {user?.hasActiveMembership ? (
          <Box
            px={4}
            pb={5}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'3'}>
            {
              <BlueButton
                background={theme.colors.rose}
                w="220px"
                mt="0px"
                borderRadius="12px"
                onClick={() => {
                  navigate(`/membership/${user?.activeMembershipPlan?.id}`);
                  bottomModalSheetRef?.current?.setState(false);
                }}>
                <TextG10>Use Your Exclusive Insider Access</TextG10>
              </BlueButton>
            }
          </Box>
        ) : (
          <Box
            px={4}
            pb={5}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'3'}>
            {
              <BlueButton
                background={theme.colors.rose}
                w="220px"
                mt="0px"
                borderRadius="12px"
                onClick={() => {
                  navigate(`/memberships`);
                  bottomModalSheetRef?.current?.setState(false);
                }}>
                <TextG12>Unlock Insider Access</TextG12>
              </BlueButton>
            }
          </Box>
        )}
        {data?.data && data.data.length > 0 && (
          <Box
            px={4}
            pb={5}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'3'}>
            <BlueButton
              w="220px"
              mt="0px"
              borderRadius="12px"
              onClick={() => {
                navigate(`/my-stuff?tab=Tickets`);
                bottomModalSheetRef?.current?.setState(false);
              }}>
              <TextG12>View My Tickets</TextG12>
            </BlueButton>
          </Box>
        )}
        <AccordionItem border="none">
          <AccordionButton
            // onClick={() => setAccordionIndex([1, 2])}
            justifyContent="center"
            _expanded={{bg: 'initial'}}>
            <H3
              px={6}
              textAlign={'center'}
              fontWeight={'800'}
              onClick={() => {
                dragHandler(false);
              }}>
              {getAppName()} to...
            </H3>
          </AccordionButton>
          <AccordionPanel p={0}>
            {
              <Flex px={4} py={5} flexWrap="wrap" justify="center" rowGap={4} columnGap={2}>
                {without(
                  categories,
                  'Other',
                  'Shopping',
                  'Beauty & Spas',
                  'Activities',
                  'Entertainment'
                ).map((category, i) => {
                  const iconInfo = getIconDataForCategory(category);
                  if (!iconInfo) return;
                  return (
                    <Flex
                      w="48%"
                      id={category}
                      key={i}
                      align="center"
                      justify="center"
                      gap={2}
                      height="35px"
                      py={2}
                      px={4}
                      border="1px solid black"
                      borderRadius="full"
                      onClick={() => {
                        category === 'Events' || category === 'Perks'
                          ? navigate({
                              pathname: `/search/${category === 'Perks' ? 'offer' : 'event'}`,
                              search: location.search,
                            })
                          : navigate(
                              `/search/place?filterTitle=${category}&filterIcon=${
                                iconInfo.icon
                              }&filterColor=${encodeURIComponent(
                                iconInfo.color
                              )}&categories=${encodeURIComponent(
                                getIdsForCategory(category).join(',')
                              )}`
                            );
                        bottomModalSheetRef?.current?.setState(false);
                      }}>
                      <Icon
                        id={category}
                        iconName={iconInfo.icon}
                        style={{color: iconInfo.color, fontSize: '18px'}}
                      />
                      <TextG10 id={category} fontWeight="500">
                        {category}
                      </TextG10>
                    </Flex>
                  );
                })}
              </Flex>
            }
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default NavBarContent;
