export const isExternalLink = (url) => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
};

export type AnchorAttributes = {
  [key: string]: string | undefined;
};

export function createAnchorWithAttributes(attributes: AnchorAttributes): HTMLAnchorElement {
  const anchor = document.createElement('a');

  for (const [name, value] of Object.entries(attributes)) {
    if (value) {
      anchor.setAttribute(name, value);
    }
  }

  return anchor;
}

export function addExternalParam(url: string): string {
  try {
    const urlObj = new URL(url);
    urlObj.searchParams.set('external', 'true');
    return urlObj.toString();
  } catch (error) {
    console.error('Failed to construct URL:', error);
    return url;
  }
}
