import React from 'react';
import ProgressPoints from '../ProgressPoints';

const UserPointProgress = ({user}) => {
  const points: number = user.pointsTotal;

  return <ProgressPoints title={`${points.toLocaleString()} Points`} />;
};

export default UserPointProgress;
