import {fetchSortingOptions} from '../endpoints/api';
import {useQuery} from "@tanstack/react-query";

export type AvailableSortOptions = {
  [key: string]: Record<string, { asc?: string; desc?: string }>;
};

export type ArticleSortOptions = {
  value: string;
  label: string;
  orderBy?: string;
  sort?: string;
};

export const useSortingOptions = () => {
  const { data } = useQuery(['sorting-options'], () => fetchSortingOptions());

  return data?.data;
};