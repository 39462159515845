const YOUTUBE_API_KEY = process.env.YOUTUBE_API_KEY;

export function getYouTubeVideoID(url) {
  // Regular expression to match YouTube video and shorts URLs
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  // Match the URL against the regex
  const match = url.match(youtubeRegex);

  // If there's a match, return the captured video ID, otherwise return null
  return match ? match[1] : null;
}

export function getYouTubeVideoThumbnail(url) {
  if (!url) {
    return undefined;
  }
  const videoId = getYouTubeVideoID(url);
  if (videoId) {
    return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  }

  return undefined;
}

export function getYouTubeVideoDuration(duration) {
  return parseISO8601Duration(duration);
}

// Helper function to parse ISO 8601 duration
function parseISO8601Duration(duration) {
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = duration.match(regex);
  const hours = matches[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
  let seconds = matches[3] ? parseInt(matches[3], 10) : 0;

  if (seconds > 0) {
    seconds -= 1;
  }

  let result = '';
  if (hours > 0) result += `${hours}hr `;
  if (minutes > 0) result += `${minutes}min `;
  if (seconds > 0) result += `${seconds}sec`;

  return result.trim();
}

export function extractDimensions(embedHtml) {
  // Regular expression to match width and height attributes
  const regex = /width="(\d+)"\s+height="(\d+)"/;

  // Match the dimensions using the regex
  const match = embedHtml.match(regex);

  if (match) {
    // Extract width and height from the regex match
    const width = parseInt(match[1], 10);
    const height = parseInt(match[2], 10);

    return {width, height};
  }

  // Return null if no dimensions are found
  return null;
}
