import React, {useCallback, useEffect, useState} from 'react';
import {useTheme, Flex, Link, VStack} from '@chakra-ui/react';
import useBookmark from '../../../hooks/useBookmark';
import {TextG12, TextG14} from '../../../components/typography';
import Icon from '../../../components/Icon';
import getPlaceAddress, {getDirectionsLinkFromEvent} from '../../../utils/address';
import {Event} from '../../../types/article';
import {EventRSVPStatus, useSetAttendance} from '../../../hooks/useEvent';
import {ToastMessage} from '../../../components/toast';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Modal from '../../../components/Modals/Modal';
import {BlueButton} from '../../../components/button';
import {atcb_action} from 'add-to-calendar-button-react';
import {ATCBActionEventConfig} from 'add-to-calendar-button';
import {EventChatIsClosed} from '../../../components/Chat/EventChat';
import dayjs from 'dayjs';
import {sendCalendarEventNativeApp} from '../../../pushService';
import useAuth from '../../../hooks/useAuth';
import ExternalLink from '../../../components/ExternalLink';
import SignUpRequiredWrapper from '../../../components/SignupRequiredModal/SignupRequiredWrapper';

const IconsHeader = ({
  event,
  setSignUpRequiredOpen,
  inviteCode,
}: {
  event: Event;
  setSignUpRequiredOpen: any;
  inviteCode: any;
}) => {
  const [isGoing, setIsGoing] = useState(event.attendance === 'Yes');
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(event, 'event');
  const setAttendance = useSetAttendance();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [promptOpen, setPromptOpen] = useState(false);
  const [eventInviteModalOpen, setEventInviteModalOpen] = useState(false);
  const isRSVPd = !!(
    event?.rsvp_status.status_value &&
    event?.rsvp_status.status_value != EventRSVPStatus.NOT_INTERESTED
  );
  const eventChatClosed = EventChatIsClosed(event);

  const inviteLink = inviteCode
    ? `/meet-up/${event.id}/preview?invite-code=${inviteCode}`
    : `/meet-up/${event.id}/preview`;

  useEffect(() => {
    if (!!searchParams.get('bookmark') && !isBookmarked) {
      handleOnBookmarkClick(null);
    }
  }, [searchParams]);

  const handleChatClicked = () => {
    if (eventChatClosed) {
      ToastMessage({
        status: 'error',
        text: 'This event has ended and the chat is closed.',
      });
    } else if (!isRSVPd) {
      setPromptOpen(true);
    } else navigate(`/event/${event.id}/chat`);
  };


  const handleShareClicked = () => {
    const eventLink = location.origin + inviteLink;
    navigator.clipboard.writeText(eventLink);
    ToastMessage({
      status: 'success',
      text: 'Invite link copied to your clipboard',
    });
  };

  const handleAppleClicked = () => {
    sendCalendarEventNativeApp({
      name: event.title,
      description: event.description,
      startDate: dayjs.utc(event.start_date_time).local().format('YYYY-MM-DDTHH:mm:ss'),
      //startTime: dayjs.utc(event.start_date_time).tz('America/Los_Angeles').format('hh:mm'),
      endDate: dayjs.utc(event.end_date_time).local().format('YYYY-MM-DDTHH:mm:ss'),
      //endTime: dayjs.utc(event.end_date_time).tz('America/Los_Angeles').format('hh:mm'),
      location: event.place
        ? getPlaceAddress(event.place)
        : event.location
        ? event.location
        : `${event.latitude}, ${event.longitude}`,
    });
  };

  const handleGoingClicked = useCallback(async () => {
    if (setAttendance) {
      const res = await setAttendance.mutateAsync({
        eventId: event.id,
        prevStatus: event.rsvp_status?.status_value || EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.GOING,
      });
    }
  }, [setAttendance]);

  useEffect(() => {
    if (
      !!searchParams.get('attendance') &&
      !!!(event.rsvp_status?.status_value == EventRSVPStatus.GOING)
    ) {
      handleGoingClicked();
    }
  }, [searchParams]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  return (
    <Flex w="100%" justify="space-evenly" gap=".5rem" fontWeight={500}>
      {event.place && (
        <ExternalLink href={getDirectionsLinkFromEvent(event)} isExternal>
          <Flex direction="column" align="center">
            <Icon iconName="fi-rr-map-marker" style={iconStyle} />
            <TextG12 color={theme.colors.lightBlue}>Directions</TextG12>
          </Flex>
        </ExternalLink>
      )}

      {/* <RequiresEarlyAccess>
        <Flex direction="column" align="center" onClick={() => setEventInviteModalOpen(true)}>
          <Icon iconName="fi-rr-user-add" style={iconStyle} />
          <TextG10 mt="2px" color={theme.colors.lightBlue}>
            Invite Friends
          </TextG10>
          <EventInviteModal
            event={event}
            show={eventInviteModalOpen}
            setShow={setEventInviteModalOpen}
          />
        </Flex>
      </RequiresEarlyAccess> */}

      <Flex id="share-tooltip" direction="column" align="center" onClick={handleShareClicked}>
        <Icon iconName="fi-rr-share" style={iconStyle} />
        <TextG12 color={theme.colors.lightBlue}>Share</TextG12>
      </Flex>

      <>
        <SignUpRequiredWrapper setSignUpRequiredOpen={setSignUpRequiredOpen}>
          <Flex
            color={isRSVPd && !eventChatClosed ? theme.colors.lightBlue : theme.colors.grey}
            opacity={isRSVPd && !eventChatClosed ? 1 : 0.25}
            direction="column"
            align="center"
            onClick={() => {}}>
            <Icon iconName="fi-rr-comment" style={{fontSize: '1.5rem'}} />
            <TextG12>Meet-Up Chat</TextG12>
          </Flex>
        </SignUpRequiredWrapper>
        {/* <Modal
          isCentered
          parentCallback={() => setPromptOpen(false)}
          header={
            <TextG14 textAlign="center" fontWeight="500">
              Unlock Meet-Up Chat
            </TextG14>
          }
          children={
            <VStack textAlign="center" gap={4} mb={2}>
              <TextG12>Click 'Join Meet-Up' and RSVP to join this meet-up's chat.</TextG12>
              <BlueButton w="100px" onClick={() => setPromptOpen(false)}>
                Ok
              </BlueButton>
            </VStack>
          }
          show={promptOpen}
        /> */}
      </>
      {/* <Flex direction="column" align="center" onClick={handleCalendarClicked}>
        <Icon iconName="fi-rr-calendar" style={iconStyle} />
        <TextG12 color={theme.colors.lightBlue}>Add to Cal</TextG12>
      </Flex> */}
    </Flex>
  );
};

export default IconsHeader;
