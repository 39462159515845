import {useQuery} from '@tanstack/react-query';
import {fetchFeaturedContents} from '../endpoints/api';

interface FetchFeaturedContentsOptions {
  page?: string;
  communityId?: string;
}

export const useGetFeaturedContents = ({page = '1', communityId}: FetchFeaturedContentsOptions) => {
  return useQuery(['featured-contents', {page, communityId}], () =>
    fetchFeaturedContents({page, cancelReq: null, communityId})
  );
};

// export const useInfinitePosts = () => {
//   return useInfiniteQuery(
//     ['infinite-posts'],
//     ({pageParam = 1}) => fetchPosts({page: pageParam, cancelReq: null}),
//     {
//       getNextPageParam: (lastPage) => {
//         if (lastPage.data.current_page !== lastPage.data.last_page) {
//           return lastPage.data.current_page + 1;
//         }
//       },
//     }
//   );
// };

export const useFeaturedContents = ({page, communityId}: FetchFeaturedContentsOptions) => {
  const {data, isLoading} = useGetFeaturedContents({page, communityId});

  return {featuredContents: data?.data.data, isFeaturedContentsLoading: isLoading};
};
