import React, {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Image, Center, Flex, useTheme, VStack, Box, Link} from '@chakra-ui/react';
import styled from 'styled-components';
import Logo from '../../../assets/logos/logo_120x120.png';
import {H2, TextA14, TextG10, TextG12, TextG14} from '../../../components/typography';
import Input from '../../../components/Input';
import {BlueButtonFixedWidth} from '../../../components/button';
import ShowPassword from '../../../assets/images/eye.png';

import useAuth from '../../../hooks/useAuth';
import BackButton from '../../../components/BackButton';
import {PageLoader} from '../../../components/PageLoader';
import {Checkbox} from '../../../components/checkbox';
import {getAppName} from '../../../utils/app';
import ExternalLink from '../../../components/ExternalLink';

const StyledForm = styled.form`
  padding: 0 26px;
  margin-top: 60px;
`;

interface LocalStorageTtl {
  remember: boolean;
  value: string;
  expires: number; // UNIX time
}

const EMAIL = 'email';
const EXPIRATION_TIME = 365 * 24 * 60 * 60 * 1000; // 365 days

function getEmailValue(key: string): string {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    setWithExpiry(key);
    return '';
  }

  const item: LocalStorageTtl = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expires && item.remember) {
    setWithExpiry(key);
    return '';
  }
  return item.value;
}

function ifRemember(key: string): boolean {
  const itemStr = localStorage.getItem(key);
  if (itemStr) {
    const item: LocalStorageTtl = JSON.parse(itemStr);
    return item.remember;
  } else return false;
}

function setWithExpiry(
  key: string,
  remember: boolean = true,
  value: string = '',
  timeToLive: number = 0
): void {
  const now = new Date();

  const item: LocalStorageTtl = {
    remember,
    value,
    ...(!!timeToLive ? {expires: now.getTime() + timeToLive} : {expires: timeToLive}),
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState('');
  const {login} = useAuth();
  const [email, setEmail] = useState(getEmailValue(EMAIL));
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState<boolean>(ifRemember(EMAIL));

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const userName = e.target.email.value;
    const password = e.target.password.value;
    login(userName, password)
      .then((redirect) => {
        const urlRedirect = searchParams.get('redirectTo');
        remember
          ? setWithExpiry(EMAIL, remember, userName, EXPIRATION_TIME)
          : setWithExpiry(EMAIL, remember);
        urlRedirect ? navigate(urlRedirect) : redirect ? navigate(redirect) : '/home';
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.response.data.error || e.response.message || e.message);
      });
  };

  if (isLoading) return <PageLoader />;

  return (
    <Box>
      <Box px="25px" py="40px">
        <Flex>
          <BackButton pos={'absolute'} onClick={() => navigate('/auth')} />
          <Box flex="1">
            <Center>
              <Link href="https://walkabout.app">
                <H2 color="rose">{getAppName()}</H2>
              </Link>
            </Center>
          </Box>
        </Flex>
      </Box>
      <Box mt={6}>
        <VStack spacing="12px">
          <Center>
            <Image src={Logo} width="61px" alt="Logo" />
          </Center>
          <H2>Login</H2>
          <TextA14>Inspiration starts here.</TextA14>
        </VStack>
      </Box>

      <StyledForm onSubmit={handleSubmit}>
        <VStack spacing="26px">
          <Input
            size="sm"
            id="email"
            isRequired
            type="email"
            label="Email Address"
            placeholder=""
            name="email"
            validationStatusIcon={true}
            value={email}
            onFocus={() => setError('')}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            size="sm"
            isRequired
            type={passwordShown ? 'text' : 'password'}
            label="Password"
            placeholder=""
            name="password"
            value={password}
            RightIcon={<Image src={ShowPassword} alt={'Show password'} />}
            RightIconOnClick={() => setPasswordShown(!passwordShown)}
            onFocus={() => setError('')}
            onChange={(e) => setPassword(e.target.value)}
          />
        </VStack>
        <Flex mt={3} justifyContent="space-between" flexDirection="row">
          <Checkbox
            isCircle={false}
            onChange={() => setRemember(!remember)}
            disabled={false}
            checked={remember}
          />
          <TextG10 color={theme.colors.grey} marginLeft="0.75rem" marginRight="auto">
            Remember Me
          </TextG10>
          <TextG10 onClick={() => navigate('/forgot-password')} color={theme.colors.grey}>
            Forgot Password ?
          </TextG10>
        </Flex>
        {error && (
          <Center>
            <TextG12 pt={6} color={'red'}>
              {error}
            </TextG12>
          </Center>
        )}
        <Center>
          <BlueButtonFixedWidth my={8} type="submit">
            Login
          </BlueButtonFixedWidth>
        </Center>
        <Box px={12}>
          <Center gap={8} flexDir="column" color={theme.colors.grey}>
            <TextG12 textAlign="center">
              {`New to Walkabout? `}
              <Link onClick={() => navigate('/soft-check')} color={theme.colors.blue}>
                Sign up now
              </Link>
            </TextG12>
            <TextG10 textAlign="center">
              {'By logging in you agree to our '}
              <ExternalLink
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/terms-of-use/`}
                color={theme.colors.lighterBlue}>
                terms of use
              </ExternalLink>
              {' and '}
              <ExternalLink
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/privacy-policy/`}
                color={theme.colors.lighterBlue}>
                privacy policy
              </ExternalLink>
            </TextG10>
          </Center>
        </Box>
      </StyledForm>
    </Box>
  );
}

export default Login;
