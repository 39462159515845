import {FILTER_TYPES, TAG_TYPE} from '../../../../utils/GlobalSearch/enums';
import {fetchTags} from '../../../../utils/GlobalSearch/fetchOptions';

const POST_CONFIG = {
  post: {
    featured: {
      key: 'featured',
      label: 'Featured',
      type: FILTER_TYPES.TOGGLE,
      quick: false,
    },
    membershipSpecific: {
      key: 'membershipSpecific',
      label: 'Membership Specific',
      type: FILTER_TYPES.TOGGLE,
      quick: false,
      condition: ({ user }) => user?.activeMemberships?.length > 0,
    },
    topics: {
      key: 'tags',
      label: 'Tags',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.TOPICS,
      quick: false,
      fetchOptions: fetchTags,
    },
    goals: {
      key: 'tags',
      label: 'Tags',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.GOALS,
      quick: false,
      fetchOptions: fetchTags,
    },
  },
};

export default POST_CONFIG;
