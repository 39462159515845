import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Flex, Box, useTheme, Image, Button} from '@chakra-ui/react';
import {TextG11, TextG12, TextG14, TextG8} from '../typography';
import useAuth from '../../hooks/useAuth';
import Logo from '../../assets/logos/logo_48x48.png';

const homeQuickLinksData = [
  {
    value: 'events',
    link: '/search/event?walkaboutOfficial=1',
    label: 'Walkabout Events',
    color: '#E02F84',
    icon: Logo,
  },
  {
    value: 'local_events',
    link: '/search/event?walkaboutOfficial=0',
    label: 'Nearby Events',
    color: '#5976BC',
    icon: Logo,
  },
  {
    value: 'places',
    link: '/search/place',
    label: 'Places',
    color: '#92217B',
  },
  {
    value: 'partners',
    link: `/search/partner`,
    label: 'Walkabout Partners',
    color: '#54937C',
  },
  {
    value: 'group_chat',
    link: `/lifestyle/chat`,
    label: 'Group Chat',
    color: '#7D3EEE',
  },
  // {
  //   value: 'offers',
  //   link: `/search/offer`,
  //   label: 'Offers',
  //   color: '#BD60D4',
  // },
  // {
  //   value: 'posts',
  //   link: `/search/post`,
  //   label: 'Posts',
  //   color: '#FF6813',
  // },
  {
    value: 'partners',
    link: `/search/partner`,
    label: 'Partners',
    color: '#FF6813',
  },
];

const HomeQuickLinks = () => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const theme = useTheme();

  return (
    <>
      {
        <Flex
          w="100%"
          flexWrap="wrap"
          justifyContent="center"
          id="quick-links-tooltip"
          // templateRows="repeat(2, 1fr)" // 2 rows
          // templateColumns="repeat(3, 1fr)" // 3 columns
          px={0}
          mx={0}
          rowGap={3}
          columnGap={2.5}
          mb={3}
          position="relative">
          {homeQuickLinksData.map((item, i) => {
            return (
              <Flex
                flexGrow={1}
                flexBasis={1}
                flexDir="column"
                pos="relative"
                minW="102px"
                background="white"
                border="2px solid #E4E8EC"
                boxShadow="0px 1px 2px 0px rgba(121, 121, 141, 0.10), 0px 1px 4px 0px rgba(121, 121, 141, 0.10)"
                key={i}
                textAlign="center"
                align="center"
                justify="center"
                gap={0}
                height="44px"
                py={2}
                px={4}
                borderRadius="10px"
                onClick={() => {
                  navigate(item.link);
                }}>
                <Flex alignItems="center" gap="3px">
                  {/* {item.icon && <Image w="12px" h="12px" src={item.icon} />} */}
                  <TextG11 fontWeight="500" color={item.color}>
                    {item.label}
                  </TextG11>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      }
      <Flex gap="6px">
        <Button
          onClick={() => navigate('/search/offer')}
          h="50px"
          mt="0px !important"
          boxShadow="0px 1px 2px 0px rgba(121, 121, 141, 0.10), 0px 1px 4px 0px rgba(121, 121, 141, 0.10)"
          w="100%"
          background="white"
          color="#E02F84"
          border="2px solid #E4E8EC"
          borderRadius="10px">
          <TextG12 whiteSpace="normal" fontWeight="500">Redeem Your Points for Perks & Rewards</TextG12>
        </Button>
        <Button
          onClick={() => navigate('/search/meet-up')}
          h="50px"
          mt="0px !important"
          boxShadow="0px 1px 2px 0px rgba(121, 121, 141, 0.10), 0px 1px 4px 0px rgba(121, 121, 141, 0.10)"
          w="100%"
          background="white"
          color="#3FB8FC"
          border="2px solid #E4E8EC"
          borderRadius="10px">
          <TextG14 fontWeight="500">Meet-Ups</TextG14>
        </Button>
      </Flex>
    </>
  );
};

export default HomeQuickLinks;
