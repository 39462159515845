import React from 'react';
import {Center, Flex, Image, Spinner, useTheme} from '@chakra-ui/react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Header from './Header';
import BackButton from '../../components/BackButton';
import MeetupHeader from '../../assets/images/meetup-header.png';
import About from './About';
import {useFetchMeetup} from '../../hooks/useMeetUps';
import useAuth from '../../hooks/useAuth';
import {useFetchPlace} from '../../hooks/useFetchPlace';
import useGetUserLocation from '../../hooks/useUserLocation';
import MediaGallery from '../../components/MediaGallery';

const Meetup = ({onClose}: {onClose?: () => void}) => {
  const {meetupId} = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {user} = useAuth();
  const userLocation = useGetUserLocation();

  const fetchMeetup = useFetchMeetup(Number(meetupId || searchParams.get('id')));
  const meetup = fetchMeetup.data?.data;

  const fetchPlace = useFetchPlace({
    id: Number(meetup?.place_id),
    lat: userLocation?.data?.latitude,
    long: userLocation?.data?.longitude,
  });
  const place = fetchPlace.data?.data;

  const isHost = user?.id == meetup?.user.id;

  const navigateBack = React.useCallback(() => {
    if (searchParams.has('id') && onClose) {
      onClose();
      return;
    }
    navigate(-1);
  }, []);

  if (fetchMeetup.isLoading || meetup === undefined) {
    return (
      <Center style={centerCss}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Flex position="relative" height="275px">
        <BackButton onClick={navigateBack} m={'.75rem'} pos="relative" />
        {place?.media || meetup?.place?.default_media ? (
          <MediaGallery media={place?.media || [meetup?.place?.default_media]} />
        ) : (
          <Image
            fallbackStrategy="onError"
            src={MeetupHeader}
            alt="cover image"
            position="absolute"
            width="100%"
            height="100%"
            objectFit="cover"
            zIndex="-1"
            borderBottomRadius="14px"
          />
        )}
      </Flex>

      <Header event={meetup} isHost={isHost} />
      <About event={meetup} isHost={isHost} />
    </>
  );
};

export default Meetup;

const centerCss: React.CSSProperties = {
  minHeight: '100vh',
};
