import React, {useEffect, useState} from 'react';
import {Center, useTheme, Container, Divider, Box} from '@chakra-ui/react';
import {fetchTopics, removeTopic, setTopic} from '../../../../endpoints/api';
import {BlueButtonFixedWidth} from '../../../../components/button';
import RoundedBoxItems from '../../../../components/RoundedBoxItems';
import useAuth from '../../../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {sortBy} from 'lodash';
//import Logo from '../../../../assets/logos/Dock_icon_120x120.png';
import {SpinnerLoader} from '../../../../components/Loader';
import Header from '../Header';

export type Topic = {
  id: number;
  name: string;
  description: string;
  icon: string;
  category_id: number;
};

export interface GroupedTopics {
  id: number;
  name: string;
  description: string;
  topics: Topic[];
}

const Topics = () => {
  const theme = useTheme();
  const {reFetchUserData} = useAuth();
  const {user} = useAuth();
  const [groupedTopics, setGroupedTopics] = useState<Array<GroupedTopics>>([]);
  const [numOfSelectedTopics, setNumOfSelectedTopics] = useState<number>(user?.topics.length || 0);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchTopics().then(({data}) => {
      setGroupedTopics(sortBy(data, 'name'));
      setLoading(false);
    });
    reFetchUserData();
  }, []);

  const handleOnSelect = (topicId, isSelected) => {
    isSelected
      ? setTopic(topicId)
          .then(() => setNumOfSelectedTopics(numOfSelectedTopics + 1))
          .catch(() => alert('Something went wrong'))
      : removeTopic(topicId)
          .then(() => setNumOfSelectedTopics(numOfSelectedTopics - 1))
          .catch(() => alert('Something went wrong'));
  };

  return (
    <Container px={6}>
      <Header
        title={'Tell Us What You Love...'}
        subtitle={
          'We’ll find things nearby that really inspire you. Choose two or three from each category.'
        }
      />
      <Divider my={4} color={theme.colors.lightBlack} />
      {isLoading ? (
        <SpinnerLoader mt={10} />
      ) : (
        <>
          {groupedTopics.map(({name: category, topics}) => (
            <Box key={category} pt={5}>
              <RoundedBoxItems
                title={category}
                elements={topics}
                selected={user?.topics.length ? user?.topics.map((t) => parseInt(t.id)) : []}
                onSelect={handleOnSelect}
              />
            </Box>
          ))}

          <Box py={4}>
            <Center>
              <BlueButtonFixedWidth
                isDisabled={numOfSelectedTopics < 1}
                onClick={() => navigate('/register/welcome')}>
                Continue
              </BlueButtonFixedWidth>
            </Center>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Topics;
