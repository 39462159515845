import React from "react";
import {OpeningHours, Place } from "../../../types/acrticle";
import { OpenHours } from "../../../components/OpenHours";
import useOpenHours from "../../../hooks/useOpenHours";

const OpeningHours: React.FC<{ place: Place }> = (props) => {

  const {placeOpenHours} = useOpenHours(props.place?.business_hours?.weekday_text);


  return (
    <>
      <OpenHours openHours={placeOpenHours} openStatus={props.place?.open_status}  businessStatus={props.place?.business_status}/>
    </>
  );
};

export default OpeningHours;
