import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  appliedIds: [],
  pricePerQty: '0',
  qty: 0,
  total: 0,
  numPointsApplied: undefined,
  amountDue: 0,
  passDiscountAmount: 0,
  cardPaymentAmount: 0,
  willcallName: undefined,
  appliedPass: undefined,
  membershipDiscountPercentage: 0,
  membershipDiscountAmount: 0,
  originalTotal: 0,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setAppliedIds: (state, action) => {
      state.appliedIds = action.payload;
    },
    setQty: (state, action) => {
      state.qty = action.payload;
    },
    setPricePerQty: (state, action) => {
      state.pricePerQty = action.payload;
    },
    setTotal: (state, action) => {
      state.total = Math.max(action.payload, 0);
    },
    setNumPointsApplied: (state, action) => {
      state.numPointsApplied = action.payload;
    },
    setAmountDue: (state, action) => {
      state.amountDue = Math.max(action.payload, 0);
    },
    setPassDiscountAmount: (state, action) => {
      state.passDiscountAmount = action.payload;
    },
    setCardPaymentAmount: (state, action) => {
      state.cardPaymentAmount = action.payload > 0 ? Math.max(action.payload, 1) : action.payload;
    },
    setWillcallName: (state, action) => {
      state.willcallName = action.payload;
    },
    setAppliedPass: (state, action) => {
      state.appliedPass = action.payload;
    },
    setMembershipDiscountPercentage: (state, action) => {
      state.membershipDiscountPercentage = action.payload;
      // Recalculate amount due based on the discount
      const discountAmount = (state.total * state.membershipDiscountPercentage) / 100;
      state.membershipDiscountAmount = Math.max(discountAmount, 0);
      state.amountDue = Math.max(state.amountDue - discountAmount, 0);
      state.originalTotal = state.total;
      state.total = Math.max(state.total - discountAmount, 0);
    },
    reset: (state) => {
      return {
        ...initialState,
        willcallName: state.willcallName,
      };
    },
  },
});

export const {
  setAppliedIds,
  setPricePerQty,
  setQty,
  setTotal,
  setNumPointsApplied,
  setAmountDue,
  setPassDiscountAmount,
  setCardPaymentAmount,
  setWillcallName,
  setAppliedPass,
  setMembershipDiscountPercentage,
  reset,
} = checkoutSlice.actions;

export const CheckoutSliceActions = checkoutSlice.actions;

export default checkoutSlice;
