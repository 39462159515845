import {Center, Spinner, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {useFetchEvent} from '../../hooks/useEvent';
import {
  setAmountDue,
  setQty,
  setTotal,
  reset,
  setPricePerQty,
  setMembershipDiscountPercentage,
} from '../../state/Checkout/CheckoutSlice';
import CheckoutDetails from './CheckoutDetails';
import OrderSummary from './OrderSummary';
import {Event} from '../../types/article';
import {MAX_TICKET_QTY} from '../TicketSelect/TicketQuantityContainer';
import {useFetchPurchasablePasses} from '../../hooks/usePassPurchase';
import {find} from 'lodash';
import {useFetchActivePasses} from '../../hooks/useUserPasses';
import useAuth from '../../hooks/useAuth';

export function parseTicketPrice(ticketPrice) {
  return parseFloat(ticketPrice.replace(/[^\d.-]/g, ''));
}

const CheckoutPage = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [showSummary, setShowSummary] = useState(false);
  const [eventId, setEventId] = useState<string | null>();
  const fetchEvent = useFetchEvent(Number(eventId));
  const event: Event = fetchEvent.data?.data;
  const [passId, setPassId] = useState<string | null>();
  const [stripeLoading, setStripeLoading] = useState(false);

  const {user} = useAuth();
  const discountPerc = user?.activeMembershipPlan?.event_discount || 0;

  const [ticket, setTicket] = useState<any>();
  const {qty} = useSelector((state: any) => state.checkout);

  const fetchPasses = useFetchPurchasablePasses(passId != null);
  const pass = find(fetchPasses.data?.data, (p) => p.id == passId);

  const fetchActivePasses = useFetchActivePasses();
  const userPasses = fetchActivePasses.data?.data;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (event && event?.available_tickets && event?.available_tickets[0]) {
      setTicket({...event.available_tickets[0]});
    }
  }, [event, event?.available_tickets]);

  useEffect(() => {
    if (ticket && ticket?.price) {
      dispatch(setPricePerQty(ticket.price));
    }
  }, [ticket, ticket?.price, dispatch]);

  useEffect(() => {
    if (!!searchParams.get('qty')) {
      dispatch(setQty(Math.min(parseInt(searchParams.get('qty') || '1'), MAX_TICKET_QTY)));
    }

    if (!!searchParams.get('event')) {
      setEventId(searchParams.get('event'));
    }

    if (!!searchParams.get('pass')) {
      setPassId(searchParams.get('pass'));
    }
  }, [searchParams]);

  useEffect(() => {
    if (qty && ticket && ticket?.price) {
      const newTotal = parseFloat(ticket.price.replace(/[^\d.-]/g, '')) * qty;
      dispatch(reset());
      if (!!searchParams.get('qty')) {
        dispatch(setQty(Math.min(parseInt(searchParams.get('qty') || '1'), MAX_TICKET_QTY)));
      }

      dispatch(setTotal(newTotal));
      dispatch(setAmountDue(newTotal));
      dispatch(setMembershipDiscountPercentage(discountPerc));
    }
  }, [qty, ticket, ticket?.price, discountPerc]);

  useEffect(() => {
    if (qty && pass && pass?.amount) {
      const newTotal = parseFloat(pass.amount.replace(/[^\d.-]/g, '')) * qty;
      dispatch(reset());
      if (!!searchParams.get('qty')) {
        dispatch(setQty(Math.min(parseInt(searchParams.get('qty') || '1'), 10)));
      }

      dispatch(setTotal(newTotal));
      dispatch(setAmountDue(newTotal));
    }
  }, [qty, pass, pass?.amount]);

  const isLoading =
    (fetchEvent.isLoading && fetchPasses.isLoading) || stripeLoading || fetchActivePasses.isLoading;

  if (isLoading || (event === undefined && pass === undefined) || (!ticket && !pass))
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <>
      {showSummary ? (
        <OrderSummary
          setShowSummary={setShowSummary}
          ticket={ticket}
          event={event}
          pass={pass}
          setStripeLoading={setStripeLoading}
        />
      ) : (
        <CheckoutDetails
          setShowSummary={setShowSummary}
          ticket={ticket}
          pass={pass}
          event={event}
          userPasses={userPasses}
        />
      )}
    </>
  );
};

export default CheckoutPage;
