import React from 'react';
import Icon from '../../../Icon';

export enum EventChatMenuOption {
  SHARE = 'Share This Event',
  GUIDELINES = 'Community Guidelines',
  EVENT_PAGE = 'Event Page',
}

export const OPTIONS = [
  {
    option: EventChatMenuOption.GUIDELINES,
    renderIcon: (props: any) => <Icon iconName={'fi-rr-pennant'} {...props} />,
    color: 'red',
    text: EventChatMenuOption.GUIDELINES,
  },
  {
    option: EventChatMenuOption.EVENT_PAGE,
    renderIcon: (props: any) => <Icon iconName={'fi-sr-guitar'} {...props} />,
    color: '#5976bc',
    text: EventChatMenuOption.EVENT_PAGE,
  },
  {
    option: EventChatMenuOption.SHARE,
    renderIcon: (props: any) => <Icon iconName={'fi-rr-share'} {...props} />,
    color: 'lighterBlue',
    text: EventChatMenuOption.SHARE,
  },
];
