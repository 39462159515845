import React, {useCallback, useEffect, useState} from 'react';
import {Box, useTheme, Flex, Image, VStack} from '@chakra-ui/react';
import OfferImage from '../../../../assets/images/offerImage.png';
import BookmarkedIcon from '../../../../assets/images/bookmarked.png';
import {TextG10, TextG12, TextG14} from '../../../typography';
import {Offer, OfferType, Place} from '../../../../types/acrticle';
import OfferModal from '../OfferModals';
import useBookmark from '../../../../hooks/useBookmark';
import {getImageURL} from '../../../../constants/imageStorage';
import Countdown from 'react-countdown';
import useActiveRedemptions, {ActiveRedemption} from '../../../../hooks/useActiveRedemptions';
import {getOfferCardColor} from '../OfferCard';
import dayjs from 'dayjs';
import {BookmarkIcon} from '../../../../pages/Bookmarks';
import {getUnixDate} from '../../../../utils/date';
import useOfferModal from '../../../../hooks/useOfferModal';
import Icon from '../../../Icon';
import PromptModal from '../../../Modals/PromptModal';

interface OfferCardProps {
  offer?: Offer;
  activeRedemption?: ActiveRedemption;
  fullCard?: boolean;
  rightBottomItem?: any;
  place?: Place;
  hideCard?: boolean;
  hideNoCountdown?: boolean;
}

const ActiveRedemptionCard = ({
  offer,
  activeRedemption,
  fullCard = false,
  rightBottomItem,
  place,
  hideCard = true,
  hideNoCountdown = true,
}: OfferCardProps) => {
  const [isPromptOpen, setPromptOpen] = useState(false);
  const theme = useTheme();
  const isBookmarked = true;
  const {showOfferModal} = useOfferModal();

  const {removeActiveRedemption} = useActiveRedemptions();

  const renderer = useCallback(({hours, minutes, seconds, completed, formatted}) => {
    if (offer?.offer_type == OfferType.EVENT_REDEMPTION || !offer?.countdown_required) return null;
    return (
      <TextG12>
        {minutes}:{formatted.seconds} Remaining
      </TextG12>
    );
  }, []);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.white,
      fontSize: '1.5rem',
      position: 'absolute',
      top: '10px',
      right: '10px',
    }),
    [theme]
  );

  // const offerTypePoints = ((offer?.offer_type == OfferType.POINTS_BOOST &&
  //   offer?.points_earning !== null) ||
  //   offer?.offer_type !== OfferType.POINTS_BOOST) && (
  //   // <Box
  //   //   py={1}
  //   //   px={1}
  //   //   pos="absolute"
  //   //   top={offer?.offer_type == OfferType.POINTS_BOOST ? 0 : ''}
  //   //   left={offer?.offer_type == OfferType.POINTS_BOOST ? 0 : ''}
  //   //   bottom={offer?.offer_type == OfferType.POINTS_BOOST ? '' : 0}
  //   //   right={offer?.offer_type == OfferType.POINTS_BOOST ? '' : 0}
  //   //   borderTopLeftRadius="10px"
  //   //   borderBottomRightRadius="10px"
  //   //   background={offer?.offer_type == OfferType.POINTS_BOOST ? 'green' : theme.colors.rose}>
  //   //   <Flex>
  //   //     {/* <TextG10
  //   //       fontWeight="700"
  //   //       color={offer?.offer_type == OfferType.POINTS_BOOST ? 'white' : theme.colors.white}>
  //   //       {offer?.offer_type == OfferType.POINTS_BOOST && offer?.points_earning
  //   //         ? `+${offer?.points_earning.toLocaleString()}`
  //   //         : offer?.points_redeem_amount
  //   //         ? offer?.points_redeem_amount.toLocaleString()
  //   //         : ''}
  //   //     </TextG10> */}
  //   //   </Flex>
  //   // </Box>
  // );

  if (hideNoCountdown && !offer?.countdown_required) return null;

  return (
    <>
      {/* {offer && activeRedemption && (
        <OfferModal
          offer={offer}
          showModal={showModal}
          setShowModal={setShowModal}
          //place={offer.place || place}
          //isBookmarked={isBookmarked}
          bookmarkHandler={
            offer.countdown_required
              ? handleOnBookmarkClick
              : () => removeActiveRedemption(activeRedemption)
          }
          activeRedemption={activeRedemption}
        />
      )} */}
      {!hideCard && (
        <Box
          // border={offer?.color ? `2px solid transparent` : `2px solid ${theme.colors.blue}`}
          position="relative"
          px={3}
          py={3}
          borderRadius="7px"
          background={offer?.offer_type && getOfferCardColor(offer.offer_type)} //{offer?.color ? offer.color : theme.colors.white}
          height="125px"
          width="100%"
          onClick={() => offer && activeRedemption && showOfferModal({offer, activeRedemption})}>
          <Flex gap={2} w="100%" h="100%">
            <Box maxW="100px" flex="none" pos="relative">
              <Image
                borderRadius="10px"
                objectFit="cover"
                height="100%"
                width="100%"
                maxW="100px"
                src={offer?.image_url ? getImageURL(offer.image_url) : OfferImage}
              />
              {/* {offerTypePoints} */}
            </Box>
            <VStack
              align="flex-start"
              color={theme.colors.white}
              px={2}
              justifyContent="space-between">
              <TextG14
                overflow="visible !important"
                pr={'15px'}
                wordBreak="break-word"
                fontWeight="700"
                color={theme.colors.white}
                noOfLines={2}>
                {offer?.title}
              </TextG14>
              {offer?.offer_type === OfferType.EVENT_REDEMPTION ? (
                <TextG10 fontWeight="500">
                  {offer?.event?.start_date_time &&
                    dayjs.utc(offer.event.start_date_time).local().format('MMM. D [at] h:mmA')}
                </TextG10>
              ) : (
                <TextG14
                  margin={'0 !important'}
                  wordBreak="break-word"
                  fontWeight="700"
                  noOfLines={2}>
                  Code: {activeRedemption?.voucherCode}
                </TextG14>
              )}

              {activeRedemption && activeRedemption.redemptionDateTime && (
                <TextG10 margin={'0 !important'} noOfLines={1} fontWeight="500">
                  Redeemed: {getUnixDate(Number(activeRedemption.redemptionDateTime))}
                </TextG10>
              )}

              {offer?.offer_type === OfferType.EVENT_REDEMPTION && (
                <TextG12 margin={'0 !important'} fontWeight="700">
                  You're Going!
                </TextG12>
              )}
              {offer?.place && (
                <TextG10 margin={'0 !important'} noOfLines={1} color={theme.colors.white}>
                  {offer.place.name}
                </TextG10>
              )}

              <Countdown
                onComplete={() => {
                  if (activeRedemption) removeActiveRedemption(activeRedemption);
                }}
                date={activeRedemption?.timeRemaining}
                renderer={renderer}
              />
            </VStack>
            {rightBottomItem && rightBottomItem}
          </Flex>
          <Icon
            iconName="fi-rr-cross-circle"
            style={iconStyle}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPromptOpen(true);
            }}
          />
          <PromptModal
            title={`Permanently remove '${activeRedemption?.offer.title}' from your wallet?`}
            declineText={'Keep'}
            acceptText={'Remove'}
            declineCallback={() => setPromptOpen(false)}
            acceptCallback={(e) => {
              activeRedemption && removeActiveRedemption(activeRedemption);
              setPromptOpen(false);
              //window.location.reload();
            }}
            isOpen={isPromptOpen}
          />
          {/* {isBookmarked && (
            <BookmarkIcon
              isBookmarked={isBookmarked}
              pos={'absolute'}
              right={2}
              top={2}
              w={'26px'}
              h={'26px'}
            />
          )} */}
        </Box>
      )}
    </>
  );
};

export default ActiveRedemptionCard;
