import {Box, HStack, useTheme} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {updateUser} from '../../../endpoints/api';
import useAuth from '../../../hooks/useAuth';
import {useUpdateNotificationPreference} from '../../../hooks/useEvent';
import {EventNotificationsModalActions} from '../../../state/EventNotificationsModal/EventNotificationsModalSlice';
import {LabelledCheckboxGrey} from '../../checkbox';
import {TextG10} from '../../typography';

const ChatNotificationsNudge = ({articleId}: {articleId: number}) => {
  const theme = useTheme();
  const [notificationsChecked, setNotificationsChecked] = useState(true);
  const {reFetchUserData} = useAuth();
  const dispatch = useDispatch();
  const {mutateAsync} = useUpdateNotificationPreference();

  const confirmClicked = async () => {
    if (notificationsChecked) {
      await updateUser({email_enabled: true, sms_enabled: true, push_enabled: true});
      reFetchUserData();
    } else {
      mutateAsync({
        eventId: articleId,
        receiveNotifications: false,
      });
    }
  };

  return (
    <HStack
      mx="-5%"
      background={theme.colors.lightGrey}
      py={4}
      px={6}
      justifyContent="space-between">
      <Box
        onClick={() => {
          //setNotificationsChecked((prev) => !prev);
          dispatch(EventNotificationsModalActions.pushEventNotifications({eventId: articleId}));
        }}>
        <LabelledCheckboxGrey
          styled={true}
          isCircle={false}
          label="We’ll notify you about event updates and chat."
          checked={notificationsChecked}
          onChange={(e) => e}
          disabled={false}
        />
      </Box>
      <TextG10 onClick={confirmClicked} fontWeight="500" color={theme.colors.blue}>
        Confirm
      </TextG10>
    </HStack>
  );
};

export default ChatNotificationsNudge;
