import {createSlice} from '@reduxjs/toolkit';
import { EventNotificationsModalProps } from '.';

type SliceAction = {payload: EventNotificationsModalProps; type: string};
export type SliceState = EventNotificationsModalProps[];

const initialState: SliceState = [];

export const EventNotificationsModalSlice = createSlice({
  name: 'eventNotificationsModal',
  initialState,
  reducers: {
    pushEventNotifications: (state: SliceState, action: SliceAction) => {
      state.push({...action.payload});
    },
    popEventNotifications: (state: SliceState) => {
      state.shift();
    },
  },
});

export const EventNotificationsModalActions = EventNotificationsModalSlice.actions;

export default EventNotificationsModalSlice;
