import {configureStore} from '@reduxjs/toolkit';
import {BottomSheetNotificationSlice} from './BottomSheetNotification/BottomSheetNotificationSlice';
import {ModalNotificationSlice} from './ModalNotification/ModalNotificationSlice';
import {AlertPopupSlice} from './AlertPopup/AlertPopupSlice';
import {RSVPModalPopupSlice} from './RSVPModalPopup/RSVPModalPopupSlice';
import CommunitiesSlice from './Communities/CommunitiesSlice';
import EventNotificationsModalSlice from './EventNotificationsModal/EventNotificationsModalSlice';
import NotificationReminderPopupSlice from './NotificationReminderPopup/NotificationReminderPopupSlice';
import CheckoutSlice from './Checkout/CheckoutSlice';

const reducer = {
  bottomSheetNotifications: BottomSheetNotificationSlice.reducer,
  modalNotifications: ModalNotificationSlice.reducer,
  alertPopup: AlertPopupSlice.reducer,
  rsvpModalPopup: RSVPModalPopupSlice.reducer,
  eventNotificationsModal: EventNotificationsModalSlice.reducer,
  community: CommunitiesSlice.reducer,
  notificationReminder: NotificationReminderPopupSlice.reducer,
  checkout: CheckoutSlice.reducer,
};

const store = configureStore({
  reducer,
});

export default store;
