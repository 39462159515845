import {Box, Center, Flex, HStack, Image, Link, VStack} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Logo from '../../../assets/logos/Download_logo.png';
import profile1 from '../../../assets/images/profile-landing-1.png';
import profile2 from '../../../assets/images/profile-landing-2.png';
import profile3 from '../../../assets/images/profile-landing-3.png';
import AppStoreImage from '../../../assets/images/app-store-btn.png';
import PlayStoreImage from '../../../assets/images/play-store-btn.png';
import {TextG14, TextG24} from '../../../components/typography';
import { fetchNumOfRegisteredUsers } from '../../../endpoints/api';
import { APPLE_STORE_LINK, GOOGLE_STORE_LINK } from '../../../constants/appStores';
import ExternalLink from '../../../components/ExternalLink';

const DesktopDownload = () => {
  const [numOfRegisteredUsers, setNumOfRegisteredUsers] = useState(0);

  useEffect(() => {
    fetchNumOfRegisteredUsers().then(({data: {count}}) => setNumOfRegisteredUsers(count));
  }, []);

  return (
    <Center h="100%" px="70px">
      <VStack>
        <Center mb="25px">
          <Image src={Logo} width="91px" alt="Logo" />
        </Center>
        <TextG24 mb="20px !important" textAlign="center" fontWeight="700">
          Install the App & Get Started!
        </TextG24>
        <HStack mt="0px !important">
          <Box display="inline-flex">
            <Image src={profile1} alt="Profile 1" />
            <Image ml="-26px" src={profile2} alt="Profile 2" />
            <Image ml="-26px" src={profile3} alt="Profile 3" />
          </Box>
          <TextG14 textAlign={'center'}>{`${numOfRegisteredUsers || 0}+ locals`}</TextG14>
        </HStack>
        <Flex mt="65px !important" gap="22px" flexDir="column">
          <ExternalLink href={APPLE_STORE_LINK} isExternal>
            <Image h="90px" w="100%" src={AppStoreImage} alt={'App Store'} />
          </ExternalLink>
          <ExternalLink href={GOOGLE_STORE_LINK} isExternal>
            <Image h="90px" w="100%" src={PlayStoreImage} alt={'Play Store'} />
          </ExternalLink>
        </Flex>
      </VStack>
    </Center>
  );
};

export default DesktopDownload;
