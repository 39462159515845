import {Box, Flex, Image, useTheme, Spacer, LinkOverlay, ScaleFade, HStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import React, {useState, useMemo, useEffect} from 'react';
import {TextA14, TextG12, TextG10} from '../typography';
import {BlueButton} from '../button';
import styled, {css} from 'styled-components';
import dayjs from 'dayjs';
import {useReadNotification} from '../../hooks/useNotifications';
import Logo from '../../assets/logos/logo_120x120.png';
import {AnchorAttributes, createAnchorWithAttributes, isExternalLink} from '../../utils/externalLink';
import {registerNotificationActionUrlClick} from '../../endpoints/api';

export interface NotificationCardProps {
  id: number;
  publish_at: string;
  created_at: string;
  read_at?: string;
  type?: string;
  avatar?: string;
  data: {
    title?: string;
    message?: string;
    image?: string;
    children?: JSX.Element;
    action_title?: string;
    action_url?: string;
  };
}

const StyledNotificationCard = styled(Box)`
  border-radius: 0px;
`;

const NotificationCardContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`;

const ImageContainer = styled(Flex)`
  flex: 1 1 30%;
  max-width: fit-content;
`;

const ContentContainer = styled(Flex)`
  flex: 1 1 100%;
  flex-direction: column;
  gap: 5px;
  list-style-position: inside;
`;

const UnreadIndicator = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transition: all 0.25s ease;
  ${(props) =>
    props.theme &&
    props.theme.colors &&
    css`
      background: ${props.theme.colors.blue};
    `}
`;

const NotificationCard = ({
  id,
  publish_at,
  created_at,
  read_at,
  type,
  avatar,
  data: {image, title, message, action_url, action_title},
}: NotificationCardProps) => {
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: function (number, withoutSuffix) {
        return withoutSuffix ? 'just now' : 'a few seconds';
      },
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mth',
      MM: '%dmth',
      y: '1y',
      yy: '%dy',
    },
  });

  const theme = useTheme();
  const [isRead, setIsRead] = useState(read_at !== null);
  const timeAgo = useMemo(() => dayjs(publish_at).fromNow(true), [publish_at]);
  const {mutateAsync} = useReadNotification();
  const navigate = useNavigate();
  const notificationImage = avatar || image || Logo;

  const handleActionUrlClick = () => {
    setTimeout(() => {
      if (isExternalLink(action_url)) {
        const attributes: AnchorAttributes = {
          href: action_url,
          target: '_blank',
          id: 'actionUrl',
        };
        const anchor = createAnchorWithAttributes(attributes);
        window.document.body.appendChild(anchor);
        let link = document.getElementById('actionUrl');
        link?.click();
      } else if (action_url) window.location.href = action_url;
    }, 0);
    registerNotificationActionUrlClick(id)
      .then((data) => true)
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setIsRead(read_at !== null);
  }, [read_at]);

  return (
    <Box minW="100%" minH="100%">
      <StyledNotificationCard pl={2} pr={4} py={3} bg={isRead ? 'transparent' : theme.colors.lightestBlue}>
        {title || message ? (
          <NotificationCardContainer gap={4}>
            <Flex gap={3} w="100%">
              <ScaleFade style={{alignSelf: 'start', marginTop: '26px'}} in={!isRead} initialScale={1}>
                <UnreadIndicator ml={!isRead ? 0 : '-8px'} theme={theme} />
              </ScaleFade>
              <ImageContainer>
                <Image borderRadius="100%" h="40px" minW="40px" src={notificationImage} alt="Notification" />
              </ImageContainer>
              <ContentContainer
                onClick={() => {
                  if (!isRead) {
                    setIsRead(true);
                    mutateAsync(id);
                  }

                  if (location.pathname !== '/notifications') {
                    navigate('/notifications');
                  }
                }}>
                <Flex align="center">
                  {title && <TextA14 fontWeight="bold">{title}</TextA14>}
                  <Spacer />
                </Flex>
                {message && (
                  <TextG12 className="inner-content" dangerouslySetInnerHTML={{__html: message}}>
                    {}
                  </TextG12>
                )}
              </ContentContainer>
            </Flex>
            <HStack w="100%" pl="75px">
              {action_title && (
                <>
                  <BlueButton
                    h="25px"
                    w="130px"
                    fontSize="12px"
                    onClick={() => {
                      if (!isRead) {
                        setIsRead(true);
                        mutateAsync(id);
                      }
                      if (action_url) {
                        handleActionUrlClick();
                      }
                    }}>
                    {action_title}
                  </BlueButton>
                  <Spacer />
                </>
              )}
              {!isRead && (
                <TextG10
                  onClick={() => {
                    if (!isRead) {
                      setIsRead(true);
                      mutateAsync(id);
                    }
                  }}
                  fontWeight="bold"
                  background="white"
                  color={theme.colors.darkGrey}
                  px="10px"
                  py="5px"
                  ms="0px !important"
                  borderRadius="14px"
                  border={`1px solid ${theme.colors.grey}`}>
                  Mark Read
                </TextG10>
              )}
              <Spacer />
              {timeAgo && <TextG10 ms="0px !important" color={theme.colors.grey}>{timeAgo}</TextG10>}
            </HStack>
          </NotificationCardContainer>
        ) : (
          <></>
        )}
      </StyledNotificationCard>
    </Box>
  );
};

export default NotificationCard;
