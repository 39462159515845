import {
  ModalOverlay,
  ModalContent,
  ModalContentProps,
  Modal,
  ModalProps,
  ModalBody,
  ModalBodyProps,
  Flex,
  ModalCloseButton,
  VStack,
} from '@chakra-ui/react';
import React, {ReactNode} from 'react';
import {WhiteButton, BlueButton} from '../../button';
import {TextG10, TextG12, TextG14} from '../../typography';

type PromptModalProps = {
  declineText?: string;
  declineCallback?: (e: any) => void;
  acceptText?: string;
  acceptCallback?: (e: any) => void;
  title: string;
  description?: string | JSX.Element;
  isOpen: boolean;
  onClose?: () => void;
  style?: {
    Modal?: ModalProps;
    ModalContent?: ModalContentProps;
    ModalBody?: ModalBodyProps;
  };
  buttonWidth?: string;
};

const PromptModal = ({
  declineText,
  declineCallback,
  acceptCallback,
  acceptText,
  title,
  description,
  isOpen,
  onClose,
  style,
  buttonWidth = '100px',
}: PromptModalProps) => {
  const handleAccept = (e) => {
    acceptCallback && acceptCallback(e);
  };

  const handleDecline = (e) => {
    declineCallback && declineCallback(e);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose && onClose();
      }}
      isCentered
      {...style?.Modal}>
      <ModalOverlay />
      <ModalContent w="90%" px={4} pt={6} pb={3} h="auto" {...style?.ModalContent}>
        {onClose && <ModalCloseButton background={'transparent'} top={0} right={0} />}
        <ModalBody p={0} textAlign={'center'} {...style?.ModalBody}>
          <VStack>
            <TextG14>{title}</TextG14>
            {description && <TextG12>{description}</TextG12>}
          </VStack>
          <Flex justifyContent={'space-evenly'}>
            {declineText && (
              <WhiteButton
                h={'30px'}
                w={buttonWidth}
                borderRadius={'19px'}
                marginTop={'3'}
                marginBottom={'3'}
                color={'#757D84'}
                border={`1px solid #757D84`}
                onClick={handleDecline}>
                <TextG10 py={3}>{declineText}</TextG10>
              </WhiteButton>
            )}
            {acceptText && (
              <BlueButton
                h={'30px'}
                w={buttonWidth}
                borderRadius={'19px'}
                marginTop={'3'}
                onClick={handleAccept}>
                <TextG10 py={3}>{acceptText}</TextG10>
              </BlueButton>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PromptModal;
