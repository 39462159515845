import React from "react";
import {
  Accordion as ChkrAccordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { TextG10 } from "../../../components/typography";
import Icon from "../../../components/Icon";
import {Place} from "../../../types/article";

const Accordion: React.FC<{ place: Place }> = (props) => {
  const headerItems = React.useMemo(
    () => props.place.attributes.slice(0, 2),
    [props.place.attributes]
  );
  const bodyItems = React.useMemo(
    () => props.place.attributes.slice(2),
    [props.place.attributes]
  );

  if(!headerItems.length && !bodyItems.length) {
    return <></>;
  }

  return (
    <ChkrAccordion allowToggle>
      <AccordionItem>
        <AccordionButton position="relative">
          <Grid templateColumns="repeat(2, 1fr)" gap=".5rem" w="100%">
            {headerItems.map((item) => (
              <GridItem key={item.id}>
                <Flex alignItems="center" gap=".5rem" h="100%">
                  <Icon iconName={item.icon || 'fi-rr-check'} />
                  <TextG10>{item.title}</TextG10>
                </Flex>
              </GridItem>
            ))}
          </Grid>
          <AccordionIcon position="absolute" right=".5rem" />
        </AccordionButton>
        <AccordionPanel>
          <Grid templateColumns="repeat(2, 1fr)" gap=".5rem">
            {bodyItems.map((item) => (
              <GridItem key={item.id}>
                <Flex alignItems="center" gap=".5rem" h="100%">
                  <Icon iconName={item.icon || 'fi-rr-check'} />
                  <TextG10>{item.title}</TextG10>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </ChkrAccordion>
  );
};

export default Accordion;
