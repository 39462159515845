import React from 'react';
import {TextG12} from '../typography';
import {Flex} from '@chakra-ui/react';
import Select, {SingleValue} from 'react-select';
import {useSearchParams} from 'react-router-dom';
import {find} from 'lodash';

export type SortOptionsValue = 'ai' | 'default' | 'distance' | 'tags';

type SortOptions = {
  value: SortOptionsValue;
  label: string;
};

const sortOptions: SortOptions[] = [
  {value: 'distance', label: 'Distance only'},
  {value: 'default', label: 'Distance + Interests '},
  {value: 'tags', label: 'My Interests Only'},
  {value: 'ai', label: 'Unexplored by me'},
];

const sortSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '14px',
    fontWeight: state.isSelected ? 'bold' : '500',
    color: state.isSelected ? 'black' : '#757D84',
    padding: '5px 10px',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '0',
    fontWeight: 'bold',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0',
    color: 'black',
    fontSize: '2px',
    width: '16px',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99,
    minWidth: '150px',
  }),
};

export type SingleValueSelect = SingleValue<{
  value: SortOptionsValue;
}>;

const SortPlaces = ({onChange}: {onChange: (e: SingleValueSelect) => void}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortOption = searchParams.get('sort') || '';
  const defaultSort: any = find(sortOptions, {value: sortOption}) || sortOptions[0];

  return (
    <Flex alignItems={'center'} gap={'5px'} marginBottom={'5px'}>
      <TextG12>Sort By: </TextG12>
      <Select
        defaultValue={defaultSort}
        onChange={onChange}
        isSearchable={false}
        options={sortOptions}
        styles={sortSelectStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </Flex>
  );
};

export default SortPlaces;
