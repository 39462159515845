import {Container, Center, Input, Flex, Box, InputGroup, InputRightElement} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {BlueButton} from '../../../../components/button';
import {TextG12, TextG14} from '../../../../components/typography';
import {fetchUserHandleAvailable, updateUser} from '../../../../endpoints/api';
import {StyledCheckCircleFilled} from '../../../../constants/eventOptions';
import Icon from '../../../../components/Icon';
import Header from '../Header';
import {uniqueNamesGenerator, colors, NumberDictionary} from 'unique-names-generator';
import useAuth from '../../../../hooks/useAuth';
import animals from '../../../../constants/animals';

const Username = () => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const numberDictionary = NumberDictionary.generate({min: 100, max: 999});
  const [userName, setUserName] = useState<string>(
    user?.handle ||
      uniqueNamesGenerator({
        dictionaries: [colors, animals, numberDictionary],
        length: 3,
        separator: '',
        style: 'capital',
      })
  );
  const [inputFocused, setInputFocused] = useState(false);

  const [userNameError, setUserNameError] = useState<string>('initial'); // Set to have initial err so that endpoint is actually checked before this is set to empty

  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get('membershipPreview');

  useEffect(() => {
    if (userName.length > 2 && userName !== user?.handle) {
      fetchUserHandleAvailable(userName)
        .then((res) => {
          setUserNameError('');
        })
        .catch((e) => setUserNameError(e.message));
    } else {
      setUserNameError('initial');
    }
  }, [userName]);

  return (
    <Container px={6}>
      <Header
        onBackClick={() =>
          navigate(
            isPreview
              ? '/register/goals-interests?membershipPreview=true'
              : '/register/goals-interests'
          )
        }
        title={`Create Your Nickname`}
        subtitle={
          <span>
            People on Walkabout will see this name when you’re chatting with them and going to
            events together.
            <br />
            <br />
            Use the nickname we picked out for you, or create your own.
          </span>
        }
      />
      <Center mt={10} mb={6}>
        <Flex
          flexDir="column"
          w="100%"
          mb={userNameError == 'initial' || userName.length <= 2 ? '25px' : '0px'}>
          <InputGroup>
            <Input
              maxLength={40}
              onBlur={() => setInputFocused(false)}
              onFocus={() => setInputFocused(true)}
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              py={6}
              pr={20}
              variant="filled"
              style={{borderRadius: '0px'}}
              placeholder="Enter a nickname"
            />
            {!inputFocused && (
              <InputRightElement mr={4} w={14} h={14} pointerEvents="none">
                <TextG12 color="blue">Change</TextG12>
              </InputRightElement>
            )}
          </InputGroup>
          {userName.length > 2 && !userNameError ? (
            <Flex gap={1} mt="5px">
              <StyledCheckCircleFilled
                style={{
                  fontSize: '18px',
                  height: '18px',
                  width: '18px',
                  color: 'green',
                  fill: 'green',
                }}
              />
              <TextG14 color="green">Available</TextG14>
            </Flex>
          ) : userName !== user?.handle && userNameError && userNameError != 'initial' ? (
            <Flex gap={1} mt="5px">
              <Icon
                iconName={'fi fi-rr-cross-circle'}
                style={{
                  fontSize: '18px',
                  height: '18px',
                  width: '18px',
                  color: 'red',
                  fill: 'red',
                }}
              />
              <TextG14 color="red">{userNameError}</TextG14>
            </Flex>
          ) : null}
        </Flex>
      </Center>
      <Center>
        <BlueButton
          isDisabled={
            userName !== user?.handle &&
            (userName.length <= 2 || (userNameError && userNameError != 'initial'))
          }
          w="60%"
          onClick={() => {
            updateUser({handle: userName})
              .then(() =>
                navigate(
                  isPreview
                    ? '/register/profile-pic?membershipPreview=true'
                    : '/register/profile-pic'
                )
              )
              .catch((e) => setUserNameError(e.message));
          }}>
          Continue
        </BlueButton>
      </Center>
    </Container>
  );
};

export default Username;
