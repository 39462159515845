import {Box} from '@chakra-ui/react';
import React from 'react';

const RemoveClick = () => {
  return (
    <Box
      pos="absolute"
      top="0"
      left="0"
      minH="100vh"
      minW="100vw"
      background="transparent"
      zIndex="1550"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    />
  );
};

export default RemoveClick;
