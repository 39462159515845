import {fetchPurchasablePasses, purchasePass} from '../endpoints/api';
import {useQuery} from '@tanstack/react-query';

export function useFetchPurchasablePasses(enabled: boolean = true) {
  return useQuery(['purchasable_passes'], () => fetchPurchasablePasses(), {enabled: enabled});
}

export function useFetchPassStripeURL(
  passId: string,
  quantity: number,
  successUrl: string,
  cancelUrl: string,
  userId?: number,
  enabled: boolean = true
) {
  return useQuery(
    ['pass_stripe', passId, userId, quantity],
    () => purchasePass({passId, quantity, successUrl, cancelUrl}),
    {
      enabled: enabled && typeof passId === 'number' && typeof userId === 'number',
      //   cacheTime: 0, // Don't save in cache because stripe URLs should be generated everytime
    }
  );
}
