import React from 'react';
import {deviceDetected} from '../../../utils/deviceCheck';
import {APPLE_STORE_LINK, GOOGLE_STORE_LINK} from '../../../constants/appStores';

const AppRedirect = () => {
  let link = APPLE_STORE_LINK;
  if (deviceDetected() == 'android' || deviceDetected() == 'android-pwa') link = GOOGLE_STORE_LINK;

  window.location.href = link;

  return <></>;
};

export default AppRedirect;
