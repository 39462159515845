import {Link as ChakraLink, LinkProps} from '@chakra-ui/react';
import React from 'react';
import {useMemo} from 'react';
import {addExternalParam} from '../../utils/externalLink';

const ExternalLink = (props: LinkProps) => {
  const {href, ...rest} = props;

  // Modify href if it's external
  const modifiedHref = useMemo(() => {
    return href ? addExternalParam(href) : href;
  }, [href]);

  return <ChakraLink isExternal={true} href={modifiedHref} {...rest} />;
};

export default ExternalLink;
