import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {SortOptionsValue} from '../components/SortPlaces';
import {filterPlaceByType} from '../endpoints/api';

interface FetchPlacesByTypeOptions {
  typeIds: string[];
  page?: string;
  lat?: number;
  long?: number;
  sort?: SortOptionsValue;
  open?: string;
  communityId?: string;
  enabled?: boolean;
}

export const useInfinitePlacesByType = ({
  typeIds,
  page,
  lat,
  long,
  sort = 'default',
  open,
  communityId,
  enabled = true,
}: FetchPlacesByTypeOptions) => {
  return useInfiniteQuery(
    ['infinite-placesByType', {typeIds, page, sort, open, communityId}],
    ({pageParam = 1}) =>
      filterPlaceByType({page: pageParam, typeIds, lat, long, sort, open, communityId}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      enabled: enabled,
    }
  );
};

export const useFetchPlacesByType = ({
  typeIds,
  page = '1',
  lat,
  long,
  sort = 'default',
  open,
  communityId,
}: FetchPlacesByTypeOptions) => {
  return useQuery({
    queryKey: ['placesByType', {page, typeIds, sort, open, communityId}],
    queryFn: () => filterPlaceByType({page, typeIds, lat, long, sort, open, communityId}),
    enabled: !!typeIds && typeIds.length > 0,
  });
};
