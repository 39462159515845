import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LabelledCheckboxGrey} from '../../components/checkbox';
import Icon from '../../components/Icon';
import {TextG12, TextG14} from '../../components/typography';
import {EventRSVPStatus, useFetchEventRSVPStats, useSetAttendance} from '../../hooks/useEvent';
import {ArticleType} from '../../types/article';

type Props = {
  onClose: (e) => void;
  event: ArticleType;
};

const ConvinceMeToGoModal = ({onClose, event}: Props) => {
  const navigate = useNavigate();
  const {mutateAsync} = useSetAttendance();
  const [isDontShowChecked, setIsDontShowChecked] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(true);
  const {data} = useFetchEventRSVPStats(Number(event.id));
  const rsvpStats = data?.data.rsvp_stats;

  const onClickRedirectToEventChat = () => {
    navigate(`/event/${event.id}/chat`);
  };

  useEffect(() => {
    //If the modal is open, immediate set status as Interested
    if (isOpenModel) {
      const showDispatchRSVPModalPopupActions = false;
      mutateAsync({
        showDispatchRSVPModalPopupActions,
        eventId: event.id,
        prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.INTERESTED,
      });
      setIsOpenModel(false);
    }
  }, []);

  return (
    <Modal isCentered isOpen={true} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent style={{margin: '35px'}}>
        <ModalHeader textAlign="center">
          <VStack>
            <i className="fi fi-sr-glass-cheers" style={{fontSize: '2rem'}}></i>
            <h2 style={{color: '#E02F84', fontFamily: 'Gordita', fontSize: '1.5rem'}}>Get Ready</h2>
          </VStack>
        </ModalHeader>
        <ModalBody align-item={'center'}>
          <VStack spacing={4} align="center">
            <Box maxH="60px" textAlign="center">
              <TextG14 sx={{color: '#757D84', fontWeight: '500'}}>
                You've just taken the first step to having a great experience in your community.
              </TextG14>
            </Box>
            <Box h="40px" width="100%">
              <Button size="md" height="53px" width="100%">
                <TextG14>You've Unlocked</TextG14>
              </Button>
            </Box>
            <Box h="40px" style={{marginTop: '18px', marginBottom: '10px'}} textAlign="center">
              <p style={{fontSize: '14px'}}>
                <span style={{fontWeight: 'bold', fontSize: '18px', marginRight: '4px'}}>
                  &bull;
                </span>
                Event Chat
              </p>
              <p style={{fontSize: '14px'}}>
                <span style={{fontWeight: 'bold', fontSize: '18px', marginRight: '4px'}}>
                  &bull;
                </span>
                Reminders About This Event
              </p>
            </Box>
            <Divider orientation="horizontal" />
            <Box h="40px" textAlign="center">
              <TextG12 sx={{color: '#757D84', fontWeight: '400'}}>
                We've updated your RSVP to "I'm interested", and hope to see you there!
              </TextG12>
            </Box>
            <Box h="40px" textAlign="center" width="100%">
              <Button
                colorScheme="messenger"
                id="rsvp-tooltip"
                borderRadius={'2.5rem'}
                justifyContent={'space-between'}
                alignItems={'center'}
                textAlign="center"
                px={3}
                width="100%"
                onClick={onClickRedirectToEventChat}>
                <TextG14 sx={{flexGrow: 1, color: 'white'}}>
                  Chat now with {rsvpStats?.INTERESTED + rsvpStats?.GOING + rsvpStats?.NOT_GOING}{' '}
                  others
                </TextG14>
              </Button>
            </Box>
            <Box h="40px" width="100%">
              <Button
                id="rsvp-tooltip"
                borderRadius={'2.5rem'}
                justifyContent={'space-between'}
                alignItems={'center'}
                textAlign="start"
                px={3}
                width="100%"
                style={{backgroundColor: '#EAFAFF'}}>
                <Icon
                  iconName={'fi fi-sr-star'}
                  style={{color: '#3FB8FC'}}
                  height={'18px'}
                  width={'18px'}
                />
                <TextG14 sx={{flexGrow: 1, color: 'black', marginLeft: '0.7rem'}}>
                  I'm interested
                </TextG14>
              </Button>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="center" style={{paddingTop: '3px'}}>
          <VStack spacing={3} align="center" width="100%">
            <Box>
              <LabelledCheckboxGrey
                isCircle={false}
                label="Don't show this again"
                checked={isDontShowChecked}
                onChange={(e) => setIsDontShowChecked(!isDontShowChecked)}
                disabled={false}
              />
            </Box>
            <Box marginTop="1rem !important" alignItems="center">
              <Button
                colorScheme="whiteAlpha"
                id="rsvp-tooltip"
                backgroundColor="white"
                borderRadius={'2.5rem'}
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                px={3}
                height="10px"
                onClick={(e) => onClose(isDontShowChecked)}>
                <TextG12 sx={{flexGrow: 1, marginLeft: '.5rem'}} color="#757D84">
                  Close
                </TextG12>
              </Button>
            </Box>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConvinceMeToGoModal;
