import React, {useRef, useState, useEffect, useMemo} from 'react';
import {
  Image,
  Center,
  VStack,
  useTheme,
  HStack,
  Link,
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input as ChkrInput,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import styled, {css} from 'styled-components';
import {find, forOwn} from 'lodash';
import {isValidPhoneNumber} from 'react-phone-number-input';
import Logo from '../../../../assets/logos/logo_120x120.png';
import {register, validateEmailAndInviteCode} from '../../../../endpoints/api';
import {H2, H3, TextG10, TextG12, TextG14} from '../../../../components/typography';
import {BlueButton} from '../../../../components/button';
import ShowPassword from '../../../../assets/images/eye.png';
import useAuth from '../../../../hooks/useAuth';
import InputPhoneNumber, {AvailableFunctions} from '../../../../components/InputPhoneNumber';
import {PageLoader} from '../../../../components/PageLoader';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import BackButton from '../../../../components/BackButton';
import successIcon from '../../../../assets/images/success.png';
import StateSelect from '../../../../components/StateSelect';
import {Address, State} from '../../../../types/address';
import {useAddUserAddress, useFetchAddressTypes} from '../../../../hooks/useUserAddress';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import ExternalLink from '../../../../components/ExternalLink';

const ZIP_LENGTH = 5;

interface RegisterFormState {
  name: string;
  surname: string;
  zip: string;
  code: string;
  codeAddress?: Address;
  isFenton?: boolean;
  ni?: string;
  qrCodeId?: string;
}

const StyledForm = styled.form`
  padding: 28px 24px;
`;

const StyledPhoneInput = styled(InputPhoneNumber)`
  height: 45px;
  margin-left: -5px;
  .PhoneInputInput {
    margin-left: 5px;

    ${(props) =>
      props.theme &&
      props.theme.colors &&
      css`
        ::placeholder {
          color: ${props.theme.colors.lightestGrey};
        }
      `};
  }
`;

export const VERIFICATION_CODE_LENGTH = 6;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}]|\:;"'<,>.?\/])[a-zA-Z\d~`!@#$%^&*()_\-+={[}]|\:;"'<,>.?\/]{8,}$/;

type FormData = {
  name?: string;
  surname?: string;
  address?: string;
  phone?: string;
  email?: string;
  password?: string;
  address2?: string;
  city?: string;
  state?: State;
  zip?: string;
};

type FieldError = {[index: string]: string};

function RegisterForm() {
  const theme = useTheme();
  const {login} = useAuth();
  const location = useLocation();
  const state = location.state as RegisterFormState;
  const navigate = useNavigate();

  const [data, setData] = useState<FormData>({
    ...(state && state.name && {name: state.name}),
    ...(state && state.surname && {surname: state.surname}),
    ...(state && state.zip && {zip: state.zip}),
  });

  const [errors, setErrors] = useState<FieldError>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [termsAndPolicy, setTermsAndPolicy] = useState(true);
  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get('membershipPreview');

  //const [showModal, setShowModal] = useState(false);
  //const [skipAddress, setSkipAddress] = useState(false);

  const fetchAddressTypes = useFetchAddressTypes();
  const addressTypes = fetchAddressTypes.data?.data;
  const addUserAddress = useAddUserAddress();

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.grey,
      fontSize: '14px',
    }),
    [theme]
  );

  const stateSelectStyles = useMemo(() => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: `1px solid ${theme.colors.lighterGrey};`,
        borderRadius: '12px',
        minWidth: '100px',
        minHeight: '45px',
        fontSize: '12px',
      }),
      singleValue: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: '12px',
        fontFamily: 'Gordita',
      }),
      loadingIndicator: (baseStyles, state) => ({
        ...baseStyles,
        display: 'none',
      }),
      menuList: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: '10px',
        fontFamily: 'Gordita',
      }),
    };
  }, [theme]);

  const setField = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const setFieldErrors = (field, error) => {
    setErrors({
      ...errors,
      [field]: error,
    });
  };

  const phoneNumberInputRef = useRef<AvailableFunctions>(null);

  const handleEmailChange = (e) => {
    setField('email', e.target.value);
    setFieldErrors('email', '');
    setIsEmailVerified(false);
  };

  const handlePhoneNumberChange = (e) => {
    if (e) {
      // If phone number was verified before then now we have to resend code.
      if (isPhoneVerified) phoneNumberInputRef?.current?.sendResendCode(true);
      setField('phone', e);
      // Since value is changed, then it's not verified anymore.
      setIsPhoneVerified(false);
      isValidPhoneNumber(e)
        ? setFieldErrors('phone', '')
        : setFieldErrors('phone', 'Phone number should be 10 digits.');
    }
  };

  const handlePasswordChange = (e) => {
    setField('password', e.target.value);
    PASSWORD_REGEX.test(e.target.value)
      ? setFieldErrors('password', '')
      : setFieldErrors(
          'password',
          'Password requires minimum 8 characters using a combination of uppercase, lowercase, numbers and symbols'
        );
  };

  const handleRegister = (e) => {
    if (e) e.preventDefault();

    if (!isEmailVerified) {
      validateEmailAndInviteCode(data.email?.toLowerCase(), state.code)
        .then((response) => {
          setIsSubmitted(true);
          if (response.status === 200) {
            setIsEmailVerified(true);

            // 1. Phone number verification
            if (!errors?.phone && !isPhoneVerified && phoneNumberInputRef.current) {
              phoneNumberInputRef.current?.sendVerifyCodeRequest();
            }
          }

          if (response.status === 400) {
            setIsEmailVerified(false);
            setFieldErrors('email', response.data.message);
          }
        })
        .catch(({response}) => {
          setIsSubmitted(true);
          setIsEmailVerified(false);
          setFieldErrors('email', response.data.message);
        });

      setIsSubmitted(false);
      return;
    }

    if (data.zip && data.zip.length > 0 && data.zip.length < ZIP_LENGTH) {
      setFieldErrors('zip', 'Zip must be at least 5 numbers');
      return;
    } else {
      setFieldErrors('zip', '');
    }

    // 1. Phone number verification
    if (errors?.phone || !isPhoneVerified) {
      phoneNumberInputRef.current?.sendVerifyCodeRequest();
      return;
    }

    // if (
    //   !skipAddress &&
    //   !state.codeAddress &&
    //   (!data.address || !data.state || !data.zip || !data.city)
    // ) {
    //   setIsSubmitted(false);
    //   setShowModal(true);
    //   return;
    // }

    setIsLoading(true);

    // 2. If phone number verified, trigger registration
    const params = {
      name: data.name,
      surname: data.surname,
      email: data.email?.toLowerCase() || '',
      phone: data.phone,
      password: data.password || '',
      // address: data.address,
      // address2: data.address2,
      // state: data.state,
      // city: data.city,
      // zip: data.zip,
      invite_code: state.code,
      tou_pp_accepted: termsAndPolicy,
      ni: state.ni,
      qr_code_id: state.qrCodeId,
    };

    register(params)
      .then((res) => {
        login(params.email, params.password).then(() => {
          if (!state.codeAddress && data.address && data.state && data.zip) {
            const type_id = find(addressTypes, {name: 'HOME'})?.id;
            const userId = res.data.id;
            const address: Address = {
              type_id,
              street_address: data.address,
              state_id: data.state.id,
              unit: data.address2,
              city: data.city,
              zip_code: data.zip,
            };
            addUserAddress.mutateAsync({userId: userId, address});
          }
          // nextStep();
          navigate(
            isPreview
              ? '/register/goals-interests?membershipPreview=true'
              : '/register/goals-interests'
          );
        });
      })
      .catch((e) => {
        const {data: response} = e.response;
        if (response?.errors && Object.keys(response?.errors).length) {
          const listOfErrors = {};
          forOwn(response.errors, (fieldErrors, key) => {
            fieldErrors.map((error) => (listOfErrors[key] = error));
          });
          setErrors((prevState) => ({...prevState, ...listOfErrors}));
        } else {
          setFieldErrors('message', e.message);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPhoneVerified) {
      handleRegister(null);
    }
  }, [isPhoneVerified]);

  useEffect(() => {
    if (state && state.zip) {
      return;
    }
    if (!state || (!state.code && !state.qrCodeId)) {
      navigate('/soft-check');
    }
  }, []);

  if (isLoading) return <PageLoader />;

  return (
    <>
      {/* <Modal isCentered isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalHeader pb={0}>
            <TextA18>Address Missing</TextA18>
          </ModalHeader>
          <ModalBody>
            <VStack>
              <Box>
                <TextG12>
                  You might miss out on special perks and personalized Offers from Places nearby
                </TextG12>
              </Box>
              <VStack textAlign="center" py={2} w="100%">
                <BlueButton w="100%" onClick={() => setShowModal(false)}>
                  Add Address
                </BlueButton>
                <TextG12
                  w="100%"
                  onClick={(e) => {
                    setSkipAddress(true);
                    handleRegister(e);
                  }}
                  textDecoration="underline">
                  Skip For Now
                </TextG12>
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <Box px="25px" pt="20px">
        <Flex>
          <BackButton pos={'absolute'} onClick={() => navigate(-1)} />
        </Flex>
      </Box>
      <VStack spacing={1} textAlign="center">
        <Center>
          <Image src={Logo} width="61px" alt="Logo" />
        </Center>
        <H3 mt={'12px !important'}>You’re Almost In! Let’s Get You Set Up</H3>
        <TextG14 px="25px" color={theme.colors.lightBlack}>
          Create your profile to start connecting earning local perks, and getting exclusive
          Invites.
        </TextG14>
      </VStack>
      <StyledForm autocomplete="off" onSubmit={handleRegister}>
        <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}} />
        <VStack spacing="25px" mb="20px">
          <HStack spacing="12px">
            <VStack alignItems="left">
              <TextG10 fontWeight="500">First Name</TextG10>
              <InputGroup ml="-5px !important" h="45px">
                <InputLeftElement
                  top="3px"
                  fontSize="12px"
                  pointerEvents="none"
                  children={<i className="fi fi-rr-user" />}
                />
                <TextG12>
                  <ChkrInput
                    h="45px"
                    focusBorderColor={theme.colors.lightestGrey}
                    _placeholder={{color: `${theme.colors.lightestGrey}`}}
                    fontSize="12px"
                    //placeholder="First Name"
                    pl={9}
                    border={`1px solid ${theme.colors.lighterGrey};`}
                    borderRadius="12px"
                    size="sm"
                    id="first-name"
                    isRequired
                    name="first_name"
                    autoComplete="given-name"
                    minLength={2}
                    value={data?.name || ''}
                    onChange={(e) => setField('name', e.target.value)}
                  />
                </TextG12>
              </InputGroup>
            </VStack>
            <VStack alignItems="left">
              <TextG10 fontWeight="500">Last Name</TextG10>
              <InputGroup ml="-5px !important" h="45px">
                <InputLeftElement
                  top="3px"
                  fontSize="12px"
                  pointerEvents="none"
                  children={<i className="fi fi-rr-user" />}
                />
                <TextG12>
                  <ChkrInput
                    h="45px"
                    focusBorderColor={theme.colors.lightestGrey}
                    _placeholder={{color: `${theme.colors.lightestGrey}`}}
                    fontSize="12px"
                    //placeholder="Last Name"
                    pl={9}
                    border={`1px solid ${theme.colors.lighterGrey};`}
                    borderRadius="12px"
                    size="sm"
                    id="last-name"
                    isRequired
                    name="last_name"
                    autoComplete="family-name"
                    minLength={2}
                    value={data?.surname || ''}
                    onChange={(e) => setField('surname', e.target.value)}
                  />
                </TextG12>
              </InputGroup>
            </VStack>
          </HStack>
          <VStack alignItems="left" w="100%">
            <TextG10 fontWeight="500">Email Address</TextG10>
            <InputGroup ml="-5px !important" h="45px" display="block">
              <InputLeftElement
                top="4px"
                fontSize="12px"
                pointerEvents="none"
                children={<i className="fi fi-rr-envelope" />}
              />
              <TextG12>
                <ChkrInput
                  h="45px"
                  focusBorderColor={theme.colors.lightestGrey}
                  _placeholder={{color: `${theme.colors.lightestGrey}`}}
                  fontSize="12px"
                  //placeholder="Email Address"
                  pl={9}
                  border={`1px solid ${theme.colors.lighterGrey};`}
                  borderRadius="12px"
                  size="sm"
                  id="email"
                  isRequired
                  type="email"
                  autoComplete="email"
                  name="email"
                  value={data?.email || ''}
                  onChange={handleEmailChange}
                />
              </TextG12>
              {isEmailVerified && (
                <InputRightElement
                  h="full"
                  className="InputLeft"
                  pointerEvents="none"
                  children={<Image src={successIcon} alt="Success" />}
                />
              )}
            </InputGroup>
          </VStack>
          <VStack alignItems="left" w="100%">
            <HStack>
              <TextG10 fontWeight="500">Password</TextG10>
              <Tooltip
                anchorId="password-tooltip"
                title="Password requirements"
                description="Password requires minimum 8 characters using a combination of uppercase, lowercase, numbers and symbols"
              />
            </HStack>
            <InputGroup ml="-5px !important" h="45px" display="block">
              <InputLeftElement
                top="4px"
                fontSize="12px"
                pointerEvents="none"
                children={<i className="fi fi-rr-lock" />}
              />
              <TextG12>
                <ChkrInput
                  h="45px"
                  focusBorderColor={theme.colors.lightestGrey}
                  _placeholder={{color: `${theme.colors.lightestGrey}`}}
                  fontSize="12px"
                  placeholder="Choose a strong password"
                  pl={9}
                  border={`1px solid ${theme.colors.lighterGrey};`}
                  borderRadius="12px"
                  size="sm"
                  isRequired
                  autoComplete="new-password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={data?.password || ''}
                  onChange={handlePasswordChange}
                />
              </TextG12>
              <InputRightElement
                h="full"
                className="InputRight"
                onClick={() => setShowPassword(!showPassword)}
                children={<Image src={ShowPassword} alt={'Show password'} />}
              />
              )
            </InputGroup>
          </VStack>
          <VStack alignItems="left" w="100%">
            <TextG10 fontWeight="500">Phone Number</TextG10>
            <StyledPhoneInput
              showLabel={false}
              roundBorder
              theme={theme}
              fontSize="12px"
              //placeholder={'Enter Phone Number'}
              isRequired={true}
              value={data?.phone || ''}
              ref={phoneNumberInputRef}
              onSuccess={() => {
                setIsPhoneVerified(true);
                setFieldErrors('phone', '');

                // this won't work because isPhoneVerified is still false at this point until the next render and
                // continueBtnRef is always null because the component needs to use forwardRef to work
                // used useEffect instead
                ///continueBtnRef.current && continueBtnRef.current.click();
              }}
              onChange={handlePhoneNumberChange}
              onError={(error) => setFieldErrors('phone', error)}
              {...(isPhoneVerified && {validationStatusIcon: true})}
            />
          </VStack>
          {state && !state.codeAddress && (
            <VStack alignItems="left" w="100%">
              <HStack>
                <TextG10 fontWeight="500">Home Address</TextG10>
                <Tooltip
                  anchorId="address-tooltip"
                  title="Why do you ask for this?"
                  description="We prioritize events, invites, and perks based on where you actually live—so you get access to things that truly matter to you. No random suggestions, just hyper-local experiences tailored for you."
                />
              </HStack>
              <InputGroup ml="-5px !important" h="45px" display="block">
                <InputLeftElement
                  top="4px"
                  fontSize="12px"
                  pointerEvents="none"
                  children={<i className="fi fi-rr-home" />}
                />
                <TextG12>
                  <ChkrInput
                    h="45px"
                    focusBorderColor={theme.colors.lightestGrey}
                    _placeholder={{color: `${theme.colors.lightestGrey}`}}
                    fontSize="12px"
                    placeholder="Street Address"
                    isRequired
                    pl={9}
                    border={`1px solid ${theme.colors.lighterGrey};`}
                    borderRadius="12px"
                    autoComplete={'address-line1'}
                    id="address-line1"
                    name="address"
                    onChange={(e) => setField('address', e.target.value)}
                    value={data?.address || ''}
                  />
                </TextG12>
              </InputGroup>
              <InputGroup ml="-5px !important" h="45px" display="block">
                <InputLeftElement
                  top="4px"
                  fontSize="12px"
                  pointerEvents="none"
                  children={<i className="fi fi-rr-home" />}
                />
                <TextG12>
                  <ChkrInput
                    h="45px"
                    focusBorderColor={theme.colors.lightestGrey}
                    _placeholder={{color: `${theme.colors.lightestGrey}`}}
                    fontSize="12px"
                    placeholder="Unit Number"
                    pl={9}
                    border={`1px solid ${theme.colors.lighterGrey};`}
                    borderRadius="12px"
                    autoComplete={'address-line2'}
                    id="address-line2"
                    name="address2"
                    onChange={(e) => setField('address2', e.target.value)}
                    value={data?.address2 || ''}
                  />
                </TextG12>
              </InputGroup>
              <HStack ml="-5px !important" h="45px">
                <TextG12>
                  <ChkrInput
                    h="45px"
                    focusBorderColor={theme.colors.lightestGrey}
                    _placeholder={{color: `${theme.colors.lightestGrey}`}}
                    fontSize="12px"
                    placeholder="City"
                    border={`1px solid ${theme.colors.lighterGrey};`}
                    borderRadius="12px"
                    autoComplete={'address-level2'}
                    isRequired
                    id="city"
                    name="city"
                    onChange={(e) => setField('city', e.target.value)}
                    value={data?.city || ''}
                  />
                </TextG12>
                <StateSelect
                  isRequired
                  styles={stateSelectStyles}
                  changeHandler={(e) => setField('state', e)}
                  value={data?.state}
                />
                <TextG12>
                  <ChkrInput
                    type="number"
                    h="45px"
                    focusBorderColor={theme.colors.lightestGrey}
                    _placeholder={{color: `${theme.colors.lightestGrey}`}}
                    isRequired
                    fontSize="12px"
                    placeholder="Zip Code"
                    border={`1px solid ${theme.colors.lighterGrey};`}
                    borderRadius="12px"
                    autoComplete={'postal-code'}
                    id="zip"
                    name="zip"
                    onChange={(e) => {
                      if (e.target.value.length > ZIP_LENGTH) return;
                      setField('zip', e.target.value);
                    }}
                    value={data?.zip || ''}
                  />
                </TextG12>
              </HStack>
            </VStack>
          )}
          {errors &&
            Object.values(errors).map((error, index) => {
              if (error) {
                return (
                  <Center key={index} mt="10px !important">
                    <TextG12 color={theme.colors.rose}>{error}</TextG12>
                  </Center>
                );
              }
            })}
        </VStack>
        <Center>
          <BlueButton
            w="100%"
            borderRadius="7px"
            ml="-5px !important"
            disabled={Object.keys(errors).filter((key) => !!errors[key]).length || !termsAndPolicy}
            _hover={{background: theme.colors.blue}}
            _active={{background: theme.colors.blue}}
            type="submit">
            Next: Personalize Your Walkabout
          </BlueButton>
        </Center>
        <Center>
          <HStack spacing={4} pt={4} px={10}>
            <TextG10 textAlign="center" color={theme.colors.grey}>
              {'By creating an account, you agree to our '}
              <ExternalLink
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/terms-of-use/`}
                color={theme.colors.lighterBlue}>
                terms of use
              </ExternalLink>
              {' and '}
              <ExternalLink
                isExternal
                href={`${process.env.CONDITIONS_WEB_PAGE_URL}/privacy-policy/`}
                color={theme.colors.lighterBlue}>
                privacy policy.
              </ExternalLink>
            </TextG10>
          </HStack>
        </Center>
      </StyledForm>
    </>
  );
}

export default RegisterForm;
