import React, {useEffect, useMemo, useRef} from 'react';
import {Flex, HStack, Spacer, useTheme, Image, Box} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../typography';
import Card from '../../Card';
import {generatePath, Link, useParams} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Place} from '../../../types/article';
import {calculateAndGetDistance, getDistanceString} from '../../../utils/distance';
import {usePlaceTypes} from '../../../hooks/usePlaceTypes';
import styled from 'styled-components';
import CardInterestTags from '../CardInterestTags';
import {useGeneralSettings} from '../../../hooks/useGeneralSettings';
import {BlueButton} from '../../button';
import {useNavigate} from 'react-router-dom';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import {useOffers} from '../../../hooks/useOffers';
import useGetUserLocation from '../../../hooks/useUserLocation';
import OffersCardBanner from '../../OffersCardBanner';
import useAuth from '../../../hooks/useAuth';
import PremiumPlaceCard from './PremiumPlaceCard';
import NonPremiumPlaceCard from './NonPremiumPlaceCard';
import {storeScrollPositionInSession} from '../../../utils/scroll';

const PlaceCard = ({
  place,
  leftTopItem,
  rightTopItem,
  showEndorsements,
  fullCard = true,
  height,
  modal,
  hideCheckin = false,
  isFeatured = false,
  isSlide = false,
  isPreview = false,
}: {
  place?: Place;
  leftTopItem?: any;
  rightTopItem?: any;
  showEndorsements?: boolean;
  fullCard?: boolean;
  height?: string;
  modal?: boolean;
  hideCheckin?: boolean;
  isFeatured?: boolean;
  isSlide?: boolean;
  isPreview?: boolean;
}) => {
  if (!place) return null;
  const theme = useTheme();
  const {placeTypesLoading, getPlaceTypeFromId} = usePlaceTypes(!isPreview);
  const placeTypeName = getPlaceTypeFromId(place?.place_type_id)?.display_name;
  const {isLoading, getSetting} = useGeneralSettings(!isPreview);
  const navigate = useNavigate();
  const {pathname} = useParams();
  const isProduction = process.env.NODE_ENV === 'production';
  const isPartner = place.partner || place.partner_id;
  let userLocation;
  if (!isPreview) {
    userLocation = useGetUserLocation();
  }
  const cardChildContent = useRef<HTMLDivElement>(null);
  const {user} = useAuth();
  const distance = useMemo(() => {
    if (place && userLocation) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(place.latitude),
          longitude: Number(place.longitude),
        }
      );
    }
    return 0;
  }, [userLocation?.data, place]);

  const [ref, {wasEverVisible, isVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();

  useEffect(() => {
    if (wasEverVisible && place?.id.toString()) {
      addVisibleArticle({id: place.id.toString(), type: 'place'});
    }
  }, [wasEverVisible]);

  const image = useMemo(() => {
    if (place) {
      return place.image_url;
    }
  }, [place]);

  const fetchOffers = useOffers({
    articleType: 'placeId',
    articleId: place.id.toString(),
    enabled: !isPreview && isVisible,
  });

  const activeOffers = useMemo(() => {
    if (fetchOffers && fetchOffers.offers && fetchOffers.offers.length) {
      return <OffersCardBanner fullCard={fullCard} offers={fetchOffers.offers} />;
    }
  }, [fetchOffers, theme]);

  const endorsementCount = place.endorsements_approved_count;

  //if (placeTypesLoading || isLoading) return null;

  if (!isFeatured) {
    if (place.is_premium)
      return (
        <PremiumPlaceCard
          place={place}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          isSlide={isSlide}
          modal={modal}
          isPreview={isPreview}
        />
      );
    else
      return (
        <NonPremiumPlaceCard
          place={place}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          isSlide={isSlide}
          modal={modal}
          isPreview={isPreview}
        />
      );
  }
  return (
    <Link
      style={{width: '100%'}}
      to={
        isPreview
          ? ''
          : modal
          ? {pathname, search: `?id=${place.id.toString()}&articleType=place`}
          : generatePath(ROUTES.PLACE, {
              placeId: place.id.toString(),
            })
      }
      onClick={() => storeScrollPositionInSession()}
      ref={ref}>
      <Card
        article={place}
        overlayBackgroundColor={isFeatured ? '#2729295C' : ''}
        overlayHeight={
          isFeatured && cardChildContent?.current?.clientHeight
            ? `${cardChildContent.current.clientHeight + 24}px`
            : ''
        }
        borderRadius={isFeatured ? '0px' : '12px'}
        height={height}
        coverImage={image}
        leftTopItem={!fullCard && activeOffers ? activeOffers : leftTopItem}
        rightTopItem={rightTopItem}
        childPaddingX={!fullCard ? 3 : 4}>
        <Box ref={cardChildContent}>
          <Flex py={1} alignItems="center" textAlign="center">
            {placeTypeName && (
              <TextG10 color={theme.colors.white} noOfLines={!fullCard ? 1 : null}>
                {placeTypeName}
              </TextG10>
            )}
            {fullCard && activeOffers}
          </Flex>
          <HStack>
            <TextG14
              isTruncated="&hellip;"
              noOfLines={null}
              fontWeight={500}
              color={theme.colors.white}
              // minHeight={!fullCard ? '40px' : null}
            >
              {place.name || place.title}
            </TextG14>
            <Spacer />
            {!hideCheckin && fullCard && userLocation.data ? (
              distance < getSetting('checkin_distance') ? (
                <BlueButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/place/${place.id}?action=check-in`);
                  }}
                  height="24px"
                  fontSize="12px"
                  alignSelf="center"
                  minW="80px">
                  Check-In
                </BlueButton>
              ) : (
                <TextG10 sx={{wordWrap: 'normal !important'}} color={theme.colors.white}>
                  {getDistanceString(distance)}
                </TextG10>
              )
            ) : null}
          </HStack>
          <Flex mt={1} justifyContent="space-between" align="center">
            {!isFeatured && place.topics_of_interest && place.topics_of_interest.length > 0 ? (
              <CardInterestTags
                autoScroll={true}
                tagsVisible={true}
                onlyShowMatched={!fullCard}
                topicsOfInterest={place.topics_of_interest}
              />
            ) : null}
            <Spacer />
            {!isProduction && endorsementCount > 0 && (
              <HStack gap={1} align="center" height={'1.25rem'}>
                <i
                  style={{color: '#87beff', width: '1rem', height: '1.25rem'}}
                  className="fi fi-rr-arrow-alt-circle-up"
                />
                <TextG10 textAlign="center" margin="0px !important" color="white">
                  {endorsementCount}
                </TextG10>
              </HStack>
            )}
          </Flex>
        </Box>
      </Card>
    </Link>
  );
};

PlaceCard.defaultProps = {
  showEndorsements: true,
};

export default PlaceCard;
