import {Box, Skeleton} from '@chakra-ui/react';
import React, {useCallback, useEffect, useState} from 'react';
import GuidedBottomSheet from './GuidedBottomSheet';
import GuidedVideoModal from './GuidedVideoModal';
import VideoPreview from './VideoPreview';
import {useCompleteGuidedVideo, useGuidedVideos} from '../../hooks/useGuidedVideos';
import {find, sortBy} from 'lodash';
import GuidedJourneyHeader from './GuidedJourneyHeader';
import {ProgressState} from './WidgetVideoPlayer';
import {useNavigate, useSearchParams} from 'react-router-dom';

export interface VideoTask {
  id: number;
  title: string;
  description: string;
  url: string;
  thumbnail?: string;
  position: number;
  created_at: string;
  updated_at: string;
  completed?: boolean;
}

const GuidedExperienceWidget = () => {
  const {data, isLoading} = useGuidedVideos();
  const navigate = useNavigate();

  const [watched, setWatched] = useState<Array<VideoTask>>([]);
  const [unwatched, setUnwatched] = useState<Array<VideoTask>>([]);
  const [taskList, setTaskList] = useState<Array<VideoTask>>([]);
  const [listLoaded, setListLoaded] = useState(false);
  const [searchParams] = useSearchParams();

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoModalUrl, setVideoModalUrl] = useState<string | undefined>();
  const [currentVideoTask, setCurrentVideoTask] = useState<VideoTask | undefined>();
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const completedGuidedVideo = useCompleteGuidedVideo();
  const numCompleted = watched.length || 0;
  const percCompleted = (numCompleted / taskList.length) * 100;

  const [videoCompletedPlaying, setVideoCompletedPlaying] = useState(false);
  const [currentVideoMarkedComplete, setCurrentVideoMarkedComplete] = useState(false);

  useEffect(() => {
    if (!!searchParams.get('videoId')) {
      const videoId = Number(searchParams.get('videoId'));
      const videoTask = find(taskList, {id: videoId});

      if (videoTask && videoTask.url) {
        setVideoModalUrl(videoTask.url);
        setShowVideoModal(true);
        setVideoCompletedPlaying(false);
        setCurrentVideoMarkedComplete(false);

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('videoId');
        navigate(`?${newSearchParams.toString()}`, {replace: true});
      }
    }
  }, [searchParams.get('videoId'), taskList]);

  useEffect(() => {
    if (!!searchParams.get('continueJourney')) {
      if (unwatched && unwatched.length > 0) {
        setVideoModalUrl(unwatched[0].url);
        setShowVideoModal(true);
        setVideoCompletedPlaying(false);
        setCurrentVideoMarkedComplete(false);

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('continueJourney');
        navigate(`?${newSearchParams.toString()}`, {replace: true});
      } else if (watched && watched.length > 0) {
        setVideoModalUrl(watched[watched.length - 1].url);
        setShowVideoModal(true);
        setVideoCompletedPlaying(false);
        setCurrentVideoMarkedComplete(false);

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('continueJourney');
        navigate(`?${newSearchParams.toString()}`, {replace: true});
      }
    }
  }, [searchParams.get('continueJourney'), unwatched, watched]);

  const getNextVideo = useCallback(
    (currentUrl) => {
      const sortedUnwatched = [...unwatched].sort((a, b) => a.position - b.position);
      const sortedWatched = [...watched].sort((a, b) => a.position - b.position);

      // Find the current video in the combined list (watched + unwatched)
      const combined = [...sortedWatched, ...sortedUnwatched];
      const currentIndex = combined.findIndex((video) => video.url === currentUrl);
      const currentPosition = currentIndex !== -1 ? combined[currentIndex].position : -1;

      // Find the first unwatched video with a position greater than the current position
      const nextUnwatched = sortedUnwatched.find((video) => video.position > currentPosition);

      if (nextUnwatched) {
        return nextUnwatched;
      }

      // If no next unwatched video, find the next watched video with a position greater than the current position
      const nextWatched = sortedWatched.find((video) => {
        return video.position > currentPosition;
      });

      return nextWatched
        ? nextWatched
        : sortedWatched && sortedWatched.length > 0
        ? sortedWatched[0]
        : null;
    },
    [watched, unwatched]
  );

  useEffect(() => {
    if (data?.data) {
      let watchedVideos: VideoTask[] = [];
      let unwatchedVideos: VideoTask[] = [];

      if (Array.isArray(data?.data?.watched)) {
        watchedVideos = data?.data?.watched;
      } else {
        watchedVideos = Object.values(data?.data.watched);
      }

      if (Array.isArray(data?.data?.unwatched)) {
        unwatchedVideos = data?.data?.unwatched;
      } else {
        unwatchedVideos = Object.values(data?.data.unwatched);
      }

      setWatched(watchedVideos);
      setUnwatched(sortBy(unwatchedVideos, 'position'));

      setTaskList([
        ...watchedVideos.map((item) => ({...item, completed: true})),
        ...unwatchedVideos.map((item) => ({...item, completed: false})),
      ]);

      setListLoaded(true);
    }
  }, [data?.data]);

  const handleBottomSheetClick = (option) => {
    setVideoModalUrl(option.url);
    setShowVideoModal(true);
    setBottomSheetOpen(false);
  };

  useEffect(() => {
    let newTask;
    newTask = unwatched?.find((item) => item.url === videoModalUrl);

    if (!newTask) newTask = watched?.find((item) => item.url === videoModalUrl);

    setCurrentVideoTask(newTask);
    setVideoCompletedPlaying(false);
    setCurrentVideoMarkedComplete(false);
  }, [videoModalUrl]);

  if (isLoading || !listLoaded) {
    return <Skeleton w="100%" h="320px" borderRadius="8px" my={6} />;
  }

  const onVideoEnd = () => {
    if (currentVideoTask && !currentVideoMarkedComplete) {
      completedGuidedVideo.mutateAsync({videoId: currentVideoTask.id});
    }

    if (videoModalUrl) {
      const nextVideo = getNextVideo(currentVideoTask?.url);

      if (!nextVideo) {
        setShowVideoModal(false);
      }
    }

    setVideoCompletedPlaying(true);
    setCurrentVideoMarkedComplete(false);
  };

  //Track video progress and mark video completed if > 90% and api hasn't already been fired
  const onVideoProgress = (progress: ProgressState) => {
    let millisecondsLeft = 0;
    if (progress.played > 0) {
      const duration = progress.playedSeconds / progress.played;
      millisecondsLeft = (duration - progress.playedSeconds) * 1000;
    }

    if (currentVideoTask && progress.played >= 0.9 && !currentVideoMarkedComplete) {
      setCurrentVideoMarkedComplete(true);
      // delay firing it so the user can see the progress bar move after the video ends actually ends
      // but the progress is still counted if they close it early
      setTimeout(() => {
        completedGuidedVideo.mutateAsync({videoId: currentVideoTask.id});
      }, millisecondsLeft);
    }
  };

  return (
    <Box
      borderRadius="8px"
      background="#FFF"
      boxShadow="0px 0px 30px 0px rgba(0, 0, 0, 0.15)"
      px={4}
      py={4}
      mt={6}>
      <GuidedJourneyHeader setBottomSheetOpen={setBottomSheetOpen} />
      {unwatched[0] && (
        <Box
          onClick={() => {
            setVideoModalUrl(unwatched[0]?.url);
            setShowVideoModal(true);
          }}>
          <VideoPreview
            task={unwatched[0]}
            taskNum={unwatched[0]?.position}
            totalTasks={(unwatched?.length || 0) + (watched?.length || 0)}
            url={unwatched[0]?.url}
            thumbnail={unwatched[0]?.thumbnail}
          />
        </Box>
      )}

      {/* <Flex
        justifyContent="space-between"
        mt="12px"
        mb="6px"
        px={2}
        alignItems="flex-end"
        gap="5px"
        w="100%">
        {unwatched[1] ? (
          <Box
            w="100%"
            onClick={(e) => {
              setVideoModalUrl(unwatched[1].url);
              setShowVideoModal(true);
            }}>
            <NextVideoData
              url={unwatched[1].url}
              thumbnail={unwatched[1].thumbnail}
              task={unwatched[1]}
              rightComponent={
                <Icon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setBottomSheetOpen(true);
                  }}
                  iconName="fi-br-list"
                  style={{fontSize: '16px', marginTop: '2px'}}
                />
              }
            />
          </Box>
        ) : (
          <Icon
            onClick={() => setBottomSheetOpen(true)}
            iconName="fi-br-list"
            style={{fontSize: '16px', marginTop: '2px'}}
          />
        )}
      </Flex> */}

      {videoModalUrl && (
        <GuidedVideoModal
          task={currentVideoTask}
          url={videoModalUrl}
          show={showVideoModal}
          setShow={setShowVideoModal}
          setModalUrl={setVideoModalUrl}
          onVideoEnd={onVideoEnd}
          onVideoProgress={onVideoProgress}
          getNextVideo={getNextVideo}
          videoCompletedPlaying={videoCompletedPlaying}
          setVideoCompletedPlaying={setVideoCompletedPlaying}
          taskNum={currentVideoTask?.position}
          totalTasks={(unwatched?.length || 0) + (watched?.length || 0)}
          setBottomSheetOpen={setBottomSheetOpen}
          bottomSheetOpen={bottomSheetOpen}
        />
      )}
      <GuidedBottomSheet
        taskList={taskList}
        numCompleted={numCompleted}
        onClick={handleBottomSheetClick}
        openModal={bottomSheetOpen}
        onModalClose={() => setBottomSheetOpen(false)}
      />
    </Box>
  );
};

export default GuidedExperienceWidget;
