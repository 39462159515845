import {Box, useTheme} from '@chakra-ui/react';
import React from 'react';
import TicketDetails from './TicketDetails';

const TicketDetailsContainer = ({event, ticket}) => {
  const theme = useTheme();
  return (
    <Box w="100%" px={4} background={theme.colors.veryLightBlue} margin="0px !important">
      <TicketDetails event={event} ticket={ticket} />
    </Box>
  );
};

export default TicketDetailsContainer;
