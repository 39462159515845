import {Center, Flex, Image} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import Header from './Header';
import Details from './Details';
import WelcomeImage from '../../assets/images/welcome-image.png'; //'../../../../assets/images/welcome-image.png';
import {BlueButton} from '../../components/button';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {fetchMembershipStripeLink} from '../../endpoints/api';
import {ToastMessage} from '../../components/toast';

const GiftSubscription = () => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const [searchParams] = useSearchParams();
  const [promoCode, setPromoCode] = useState('');

  useEffect(() => {
    if (!!searchParams.get('p')) {
      const p = searchParams.get('p');
      if (p) setPromoCode(p);
    }
  }, [searchParams]);

  return (
    <Flex flexDir="column" gap="24px" m="12px 16px 16px 20px" pos="relative" h="100%">
      <Header />
      <Center>
        <Image borderRadius="8px" h="100%" src={WelcomeImage} width="100%" alt="Welcome" />
      </Center>
      <Details />
      <Center>
        <BlueButton
          pos="absolute"
          bottom="40px"
          onClick={() => {
            if (user) {
              fetchMembershipStripeLink({
                membershipId: Number(2),
                cLink: `${location.origin}/gift?p=${promoCode}`,
                promoCode,
              })
                .then((ret) => {
                  if (ret.data.link) {
                    window.location.href = ret.data.link;
                  }
                })
                .catch((error) => {
                  const errString = error.response.data.error;

                  ToastMessage({
                    status: 'error',
                    text:
                      errString ||
                      'Something went wrong while generating your membership link. Please contact support.',
                  });
                });
            } else {
              navigate(`/register?p=${promoCode}`, {
                state: {},
              });
            }
          }}>
          Give Me The Membership!
        </BlueButton>
      </Center>
    </Flex>
  );
};

export default GiftSubscription;
