import {useQuery} from '@tanstack/react-query';
import {fetchArticle, fetchPublicPost} from '../endpoints/api';

interface FetchPostOptions {
  postId: number | null;
}

export const useFetchPost = ({postId}: FetchPostOptions) => {
  return useQuery(
    ['post', postId],
    () => {
      if (postId) return fetchArticle({id: postId});
    },
    {
      enabled: typeof postId === 'number',
    }
  );
};

export const usePost = ({postId}: FetchPostOptions) => {
  const fetchPost = useFetchPost({postId});

  return {post: fetchPost.data?.data, postIsLoading: fetchPost.isLoading};
};

export const usePublicPost = (postUuid: string) => {
  return useQuery(
    ['public_post', postUuid],
    () => {
      if (postUuid) return fetchPublicPost(postUuid);
    },
    {
      enabled: typeof postUuid === 'string',
    }
  );
};
