import {Box, Image, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useFetchEvent} from '../../../hooks/useEvent';
import {TextG10} from '../../typography';
import RoseLogo from '../../../assets/logos/logo_rose2.png';

const RoleBadge = ({commentUser, eventId}) => {
  const theme = useTheme();

  const fetchEvent = useFetchEvent(Number(eventId));
  const event = fetchEvent.data?.data;

  if (!event || !eventId) return null;

  if (commentUser.is_walkabout_employee) {
    return <Image src={RoseLogo} w="12px" h="12px" />;
  }

  const roleList = event.personnel.map((user) => user.id);

  if (roleList && roleList.includes(commentUser.id)) {
    return (
      <Box background={theme.colors.rose} borderRadius="12px" px={2}>
        <TextG10 color="white">Event Owner</TextG10>
      </Box>
    );
  }

  return null;
};

export default RoleBadge;
