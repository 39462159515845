import React, {useMemo} from 'react';
import {Box, VStack, useTheme, Link, Flex, Image} from '@chakra-ui/react';
import {Offer, Event} from '../../../../../types/acrticle';
import {TextG10, TextG12, H2, TextG14} from '../../../../typography';
import {Place} from '../../../../../types/acrticle';
import {getDirectionsLinkFromPlace} from '../../../../../utils/address';
import {BlueButton} from '../../../../button';
import {useGeneralSettings} from '../../../../../hooks/useGeneralSettings';
import {getOfferDate} from '../../../../../utils/date';
import {calculateAndGetDistance, getDistanceString} from '../../../../../utils/distance';
import useGetUserLocation from '../../../../../hooks/useUserLocation';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Icon from '../../../../Icon';
import {AnchorAttributes, createAnchorWithAttributes} from '../../../../../utils/externalLink';
import HTMLReactParser from 'html-react-parser';
import {storeScrollPositionInSession} from '../../../../../utils/scroll';

const PointBoostModalContent = ({
  offer,
  event,
  place,
  isBookmarked,
  bookmarkHandler,
  setShowModal,
  isFutureOffer,
  datePassed,
  isPreview = false,
}: {
  offer: Offer;
  event?: Event;
  place: Place;
  isBookmarked: boolean;
  bookmarkHandler: (e: any) => Promise<string | number | undefined> | undefined;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isFutureOffer: boolean;
  datePassed: boolean;
  isPreview?: boolean;
}) => {
  const theme = useTheme();
  const {getSetting} = useGeneralSettings(!isPreview);
  const userLocation = useGetUserLocation(!isPreview);
  const {pathname} = useLocation();
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const unlocksByEventCheckIn = event && event.unlocks_offer_id === offer.id;
  const unlockedByEventCheckIn = event && event.unlocked_offer?.offer_id === offer.id;

  const distance = useMemo(() => {
    if (offer?.place || place) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(offer?.place?.latitude || place?.latitude),
          longitude: Number(offer?.place?.longitude || place?.longitude),
        }
      );
    }
    return 0;
  }, [userLocation, place, offer.place]);

  const learnMoreUrl = (place: Place, event?: Event) => {
    if (event && (unlockedByEventCheckIn || unlocksByEventCheckIn)) {
      return `/event/${event?.id || offer.event_id}`;
    } else {
      return `/place/${place?.id || offer.place_id}`;
    }
  };

  return (
    <>
      <Flex
        fontWeight="700"
        color="white"
        py={1}
        px={2}
        background="green"
        display="block"
        borderRadius="10px"
        mt="-30px !important"
        flexDir="column"
        align="center">
        <TextG12 fontWeight="700">Check-in and earn</TextG12>
        <Box>
          <TextG12>{`+${offer?.points_earning?.toLocaleString()} points`}</TextG12>
        </Box>
      </Flex>

      {isFutureOffer ? (
        <Flex py="2px" px={4} align="center" gap="4px">
          <Icon
            iconName="fi-rr-clock-nine"
            style={{
              color: theme.colors.blue,
              marginBottom: '0.25rem',
              fontSize: '18px',
              width: '18px',
              height: '18px',
            }}
          />
          <TextG12
            lineHeight="14px"
            color={theme.colors.blue}
            fontWeight="700"
            isTruncated="&hellip;"
            noOfLines={1}>
            AVAILABLE {getOfferDate(offer.starts_at)?.toUpperCase()}
          </TextG12>
        </Flex>
      ) : (
        <TextG10
          lineHeight="10px"
          py="2px"
          color={theme.colors.lightestGrey}
          px={4}
          fontWeight="500"
          isTruncated="&hellip;"
          noOfLines={1}>
          {`${datePassed ? 'EXPIRED ON' : 'EXPIRES'} ${getOfferDate(
            offer?.ends_at
          )?.toUpperCase()}`}
        </TextG10>
      )}
      <TextG10
        lineHeight="10px"
        mt="0 !important"
        color={theme.colors.lightestGrey}
        fontWeight="400">
        Limit {offer?.total_use_limit_per_user}x per person
      </TextG10>
      {offer.summary && (
        <Box
          pt={2}
          pb={2}
          px={2}
          textAlign="center"
          border={`1px solid ${theme.colors.lightGrey}`}
          borderRadius="7px">
          <TextG12 color={theme.colors.grey} align="center" maxW="284px" fontWeight="500">
            {HTMLReactParser(offer?.summary)}{' '}
            <Link
              textDecoration={'none'}
              color={'#276ACD'}
              href={learnMoreUrl(place, event)}
              onClick={() => storeScrollPositionInSession()}>
              Learn more
            </Link>
          </TextG12>
        </Box>
      )}
      <VStack>
        {isFutureOffer ? (
          !isBookmarked && (
            <BlueButton
              w="180px"
              cursor="none !important;"
              _focus={{boxShadow: 'none'}}
              _hover={{boxShadow: 'none'}}
              _active={{boxShadow: 'none'}}
              onClick={(e) => {
                setShowModal(false);
                bookmarkHandler(e);
              }}
              fontWeight="400"
              color={theme.colors.white}>
              Remind Me
            </BlueButton>
          )
        ) : getSetting('checkin_distance') && distance < getSetting('checkin_distance') && place ? (
          <BlueButton
            onClick={() => {
              storeScrollPositionInSession();
              if (searchParams.has('id')) {
                navigate(`${pathname}?id=${searchParams.get('id')}&action=check-in`);
              } else {
                navigate(`/place/${place.id}?action=check-in`);
              }

              setShowModal(false);
            }}
            w="180px">
            <TextG12 color={theme.colors.white} fontWeight="500">
              Check-In
            </TextG12>
          </BlueButton>
        ) : (
          <BlueButton
            w="180px"
            onClick={() => {
              storeScrollPositionInSession();
              let url = getDirectionsLinkFromPlace(place || offer?.place);
              const attributes: AnchorAttributes = {
                href: url,
                target: '_blank',
                id: 'getDirections',
              };
              const anchor = createAnchorWithAttributes(attributes);
              window.document.body.appendChild(anchor);
              let link = document.getElementById('getDirections');
              link?.click();
              location.href = `/place/${place?.id || offer?.place_id}`;
            }}>
            <TextG12 color={theme.colors.white} fontWeight="500">
              Go there now!
            </TextG12>
          </BlueButton>
        )}
      </VStack>
    </>
  );
};

export default PointBoostModalContent;
