import {
  Flex,
  useTheme,
  Box,
  HStack,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
  Button,
} from '@chakra-ui/react';
import React, {useEffect, useMemo, useState} from 'react';
import {H2, TextG10, TextG12} from '../../../components/typography';
import useGetUserLocation from '../../../hooks/useUserLocation';
import DotSeparator from '../../../components/DotSeparator';
import MeetupRSVPButton from './MeetupRSVPButton';
import IconsHeader from './IconHeader';
import {getDistanceString, calculateAndGetDistance} from '../../../utils/distance';
import {getEventDate} from '../../../utils/date';
import UserAvatar from '../../../components/UserAvatar';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useFetchEventRSVPStats} from '../../../hooks/useEvent';
import EditableControls from '../../../components/EditableControls';
import {useUpdateMeetUp} from '../../../hooks/useMeetUps';
import {BlueButton, WhiteButton} from '../../../components/button';
import Icon from '../../../components/Icon';
import MeetupBroadcastButton from './MeetupBroadcastButton';
import PlaceSelectModal from '../../CreateMeetup/PlaceSelectModal';
import {Place} from '../../../types/article';
import {SpinnerLoader} from '../../../components/Loader';
import {EventChatIsClosed} from '../../../components/Chat/EventChat';
import DateTimeEditor from './DateTimeEditor';
import useCountdown from '../../../hooks/useCountdown';
import useCheckIn from '../../../hooks/useCheckIn';

const Header: React.FC<{event; isHost}> = ({event, isHost}) => {
  const theme = useTheme();
  const userLocation = useGetUserLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {data} = useFetchEventRSVPStats(event.id || searchParams.get('id'));
  const rsvp_stats = data?.data.rsvp_stats;
  const usersGoing = rsvp_stats?.GOING;
  const numOfHearts = event.number_of_hearts;
  const {mutateAsync} = useUpdateMeetUp();
  const [broadModalOpen, setBroadcastModalOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<Place>(event.place);
  const [showPlaceModal, setShowPlaceModal] = useState(false);
  const [placeLoading, setPlaceLoading] = useState(false);
  const [placeName, setPlaceName] = useState(event.place?.name);

  const {startPassed, endPassed, canCheckIn} = useCountdown(event);
  const {eventCheckIn} = useCheckIn();
  const action = useMemo(() => searchParams.get('action'), [searchParams]);
  const isLoading = userLocation.isLoading;

  const handleCheckIn = ({sdv, qr_code_id}) => {
    if (event.has_checked_in) {
      return;
    }

    if ((!userLocation.data?.latitude || !userLocation.data?.longitude) && !sdv) {
      return;
    }

    eventCheckIn({
      event: event,
      eventOffers: [],
      activeRedemptions: [],
      latitude: userLocation?.data?.latitude,
      longitude: userLocation?.data?.longitude,
      isPublic: true,
      sdv: sdv,
      qr_code_id,
    });
  };

  useEffect(() => {
    if (!userLocation.isLoading && action && action == 'check-in') {
      const qr_code_id_string = searchParams.get('qr_code_id');
      let qr_code_id;
      if (qr_code_id_string) {
        qr_code_id = parseInt(qr_code_id_string);
      }
      handleCheckIn({sdv: !!searchParams.get('sdv'), qr_code_id});
    }
  }, [action, isLoading]);

  useEffect(() => {
    if (selectedPlace && selectedPlace !== event.place) {
      const updatePlace = async () => {
        setPlaceLoading(true);
        setPlaceName(selectedPlace?.name);
        try {
          await mutateAsync({
            ...event,
            title: event.title,
            place_id: selectedPlace.id,
            eventId: event.id,
          });
        } finally {
          setPlaceLoading(false);
        }
      };

      updatePlace();
    }
  }, [selectedPlace]);

  const eventDate = getEventDate(event.start_date_time, event.end_date_time);
  const eventChatClosed = EventChatIsClosed(event);

  return (
    <Flex
      position="relative"
      top="0"
      width="90%"
      marginLeft="5%"
      transform="auto"
      translateY="-35%"
      marginBottom={isHost ? '-10rem' : '-8rem'}
      backgroundColor="white"
      boxShadow={`0 0 12px ${theme.colors.grey}`}
      borderRadius=".5rem"
      p="0.75rem"
      flexDirection="column"
      gap="12px"
      pb="calc(1.25rem + 48px)">
      <Flex direction="column" gap="8px">
        {isHost ? (
          <DateTimeEditor event={event} />
        ) : (
          <TextG10
            fontWeight="500"
            color={theme.colors.grey}
            maxW="150px"
            isTruncated
            noOfLines={1}>
            {eventDate}
          </TextG10>
        )}

        {isHost && !eventChatClosed ? (
          <Editable
            mt={2}
            onSubmit={(value) => {
              mutateAsync({
                ...event,
                title: value,
                place_id: event.place?.id,
                eventId: event.id,
              });
            }}
            pos="relative"
            w="100%"
            fontSize="24px"
            lineHeight="28px"
            fontFamily="Aileron"
            fontWeight="900"
            defaultValue={event.title}
            isPreviewFocusable={false}
            selectAllOnFocus={false}>
            <EditablePreview py="0" />
            <EditableInput />
            <EditableControls top="0px" />
          </Editable>
        ) : (
          <H2>{event.title}</H2>
        )}
      </Flex>

      {event.place && (
        <Flex direction="row" align="center">
          {isHost ? (
            placeLoading ? (
              <Flex w="100%" justify="center">
                <SpinnerLoader />
              </Flex>
            ) : (
              <Flex w="100%" justifyContent="space-between">
                <TextG12
                  onClick={() => navigate(`/place/${event.place.id}`)}
                  color={theme.colors.blue}
                  width="max-content">
                  {placeName}
                </TextG12>
                {!eventChatClosed && (
                  <Button px={4} h={6} minH={6}>
                    <TextG12 onClick={() => setShowPlaceModal(true)}>Edit</TextG12>
                    <PlaceSelectModal
                      showModal={showPlaceModal}
                      setShowModal={setShowPlaceModal}
                      showButton={false}
                      selectedPlace={selectedPlace}
                      setSelectedPlace={setSelectedPlace}
                    />
                  </Button>
                )}
              </Flex>
            )
          ) : (
            <TextG12
              onClick={() => navigate(`/place/${event.place.id}`)}
              color={theme.colors.blue}
              width="max-content">
              {event.place.name}
            </TextG12>
          )}

          {userLocation.data?.latitude &&
            userLocation.data.longitude &&
            event.place?.latitude &&
            event.place?.longitude && (
              <>
                <DotSeparator width="3px" height="3px" color={theme.colors.grey} />
                <TextG12 color={theme.colors.grey} whiteSpace="nowrap">
                  {getDistanceString(
                    calculateAndGetDistance(
                      {
                        latitude: userLocation.data?.latitude,
                        longitude: userLocation.data?.longitude,
                      },
                      {
                        latitude: Number(event.place.latitude),
                        longitude: Number(event.place.longitude),
                      }
                    )
                  )}
                </TextG12>
              </>
            )}
        </Flex>
      )}
      {usersGoing > 0 && (
        <TextG12 whiteSpace="nowrap" color={theme.colors.grey}>{`${usersGoing} Going`}</TextG12>
      )}
      {numOfHearts > 0 && (
        <TextG12
          whiteSpace="nowrap"
          color={theme.colors.grey}>{`❤️ ${numOfHearts} People Appreciated This`}</TextG12>
      )}

      {!isHost && (
        <WhiteButton
          id="checkin-tooltip"
          onClick={handleCheckIn}
          w="100%"
          flexGrow={1}
          borderRadius={'2.5rem'}
          _hover={{background: ''}}
          color={!canCheckIn ? theme.colors.lightestGrey : theme.colors.white}
          background={
            !canCheckIn
              ? theme.colors.lighterGrey
              : event.has_checked_in
              ? 'green'
              : theme.colors.blue
          }
          isDisabled={endPassed}>
          {event.has_checked_in ? (
            <Flex gap="6px" style={{color: 'white'}}>
              <Icon iconName="fi-sr-check" style={{marginTop: '2px'}} />
              <TextG12 fontWeight="500">Checked-In</TextG12>
            </Flex>
          ) : (
            <TextG12 fontWeight="500">Check-In</TextG12>
          )}
        </WhiteButton>
      )}
      {!isHost && <MeetupRSVPButton event={event} />}
      {isHost && <MeetupBroadcastButton event={event} />}
      <IconsHeader event={event} />
      <Box
        display="flex"
        justifyContent="center"
        borderBottomRadius=".5rem"
        w="100%"
        left={0}
        bottom={0}
        pos="absolute"
        py="10px"
        background={theme.colors.lightBlack}>
        <HStack>
          <UserAvatar size="xs" user={event?.user} />
          <TextG12 fontWeight="500" color="white">
            {isHost
              ? `You’re hosting this Meet-Up`
              : `${event.user?.handle} is hosting this Meet-Up`}
          </TextG12>
        </HStack>
      </Box>
    </Flex>
  );
};

export default Header;
