import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Center, HStack, VStack, useTheme} from '@chakra-ui/react';
import {H1, H2, TextG14} from '../../components/typography';
import Modal from '../../components/Modals/Modal';
import {BlueButton, CustomButton, WhiteButton} from '../../components/button';
import {NotificationReminderPopupActions} from './NotificationReminderPopupSlice';
import {openSettings} from '../../pushService';

const NotificationsReminderPopup = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const notificationReminder = useSelector(
    (state: {notificationReminder: {show: boolean}}) => state.notificationReminder,
  );

  const closeModal = () => {
    dispatch(
      NotificationReminderPopupActions.setShow({
        show: false,
      }),
    );
  };

  return (
    <Modal
      isCentered={true}
      closeOnOverlayClick={false}
      show={notificationReminder.show}
      closeButton={false}
    >
      <VStack pb={4} spacing={4}>
        <p
          style={{
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: 1.3,
            textTransform: 'uppercase',
          }}
        >
          Stay Connected with Walkabout!
        </p>
        <TextG14>
          To ensure you get the most out of Walkabout, we recommend enabling notifications. This
          way, you won’t miss out on important updates, exclusive offers, and reminders that enhance
          your experience.
        </TextG14>
        <HStack width='full' justifyContent='space-between'>
          <CustomButton
            background={theme.colors.blue}
            color='white'
            width='full'
            onClick={() => {
              closeModal();
              openSettings();
            }}
          >
            Go to settings
          </CustomButton>
          <CustomButton
            onClick={() => {
              closeModal();
            }}
            variant='outline'
            background={theme.colors.white}
            color={theme.colors.blue}
            width='full'
          >
            Not Now
          </CustomButton>
        </HStack>
      </VStack>
    </Modal>
  );
};

export default NotificationsReminderPopup;
