import React from 'react';
import {Flex, useTheme, Image} from '@chakra-ui/react';
import {H2} from '../../components/typography';
import Logo from '../../assets/logos/logo_120x120.png';

const Header = () => {
  const theme = useTheme();

  return (
    <Flex flexDir="column" alignItems="center">
      <Image
        position="relative"
        margin="0 !important"
        mr="auto !important"
        ml="auto !important"
        paddingInline={0}
        src={Logo}
        width="61px"
        alt="Logo"
      />
      <H2 color={theme.colors.lightBlack}>You've got a TRUE Friend!</H2>
    </Flex>
  );
};

export default Header;
