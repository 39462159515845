import React from "react";

interface IconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  iconName?: string;
  centered?: boolean;
  width?: string;
  height?: string;
}

const Icon: React.FC<IconProps> = ({ iconName, centered, ...props }) => {
  const style = React.useMemo(() => {
    if (centered)
      return {
        ...props.style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    return props.style;
  }, [props.style]);

  const updatedStyle = {
    ...style,
    lineHeight: '100%',
    ...(props.style?.width && { width: props.style?.width }),
    ...(props.style?.height && { height: props.style?.height }),
  }
  return <i {...props} style={updatedStyle} className={`${iconName}`} />;
};

export default Icon;
