import React from 'react';
import {Flex} from '@chakra-ui/react';
import {TopicOfInterest} from '../../types/article';
import ArticleInterestTags from './ArticleInterestTags';

const TopicsOfInterest: React.FC<{topicsOfInterest: TopicOfInterest[]}> = ({topicsOfInterest}) => {
  return (
    <Flex align="center" justifyContent="space-between">
      <ArticleInterestTags interestTags={topicsOfInterest} />
    </Flex>
  );
};

export default TopicsOfInterest;
